import styled from 'styled-components';
import { Paragraph, Label, CellText } from '@increasecard/typed-components';
import { useTranslation } from 'react-i18next';
import { getCustomerName } from '../../../utils/utils';
import { Customer } from '../../../types/Customer';
import { Row } from '../../../components/common/Row';

const DetailsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: baseline;
  column-gap: 16px;
  row-gap: 16px;
  ${Label} {
    color: var(--color-gray-grayBold);
  }
`;

export interface CustomerDetailsProps {
  customer: Customer;
}

export function CustomerDetails({
  customer,
}: CustomerDetailsProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <div>
      <Paragraph className="name">
        <strong>{getCustomerName(customer)}</strong>
      </Paragraph>
      <Row margin="0" flexWrap="wrap" alignItems="baseline" gap="40px">
        <Paragraph>{customer.email}</Paragraph>
        <DetailsContainer>
          <Label>{t('data.customer.tax_id')}</Label>
          <CellText>{customer.tax_id}</CellText>
          <Label>{t('data.customer.phone')}</Label>
          <CellText>{customer.billing_info.phone}</CellText>
          <Label>{t('common.country')}</Label>
          <CellText>
            {customer.billing_info.country &&
              t(`data.countries.${customer.billing_info.country}`)}
          </CellText>
          <Label>{t('common.address')}</Label>
          <CellText>
            <div>{customer.billing_info.address_line_1}</div>
            <div>{customer.billing_info.address_line_2}</div>
          </CellText>
        </DetailsContainer>
      </Row>
    </div>
  );
}
