import { ReactNode } from 'react';
import styled from 'styled-components';
import { AccountBrandingSettings } from '../../../types';
import { useCheckoutAccount } from '../AccountContext';
import { BrandLogo } from '../BrandLogo';
import { ContactLabel } from '../components/ContactLabel';

const Layout = styled.div<{ colors: AccountBrandingSettings['colors'] }>`
  display: grid;
  grid-template-rows: auto 1fr auto;
  padding: 0 16px;
  .portal-header {
    min-height: 100px;
    padding: 16px 0;
    border-bottom: 2px solid ${({ colors }) => colors.primary};
    margin-bottom: 32px;
  }
  .portal-footer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 0;
    min-height: 100px;
    border-top: 2px solid ${({ colors }) => colors.primary};
  }
  .mobile-fullWidth {
    width: 100%;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    .mobile-fullWidth {
      width: max-content;
    }
  }
`;

export function PortalLayout({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const { branding_settings } = useCheckoutAccount();
  return (
    <Layout
      colors={branding_settings.colors}
      className="grid-start-desktop-3 grid-span-desktop-8"
    >
      <div className="portal-header">
        <BrandLogo
          brandingSettings={branding_settings}
          position="center"
          logoStyle={{ margin: 0 }}
        />
      </div>
      {children}
      <div className="portal-footer">
        <ContactLabel
          brandingSettings={branding_settings}
          justifyContent="center"
        />
        <BrandLogo
          brandingSettings={branding_settings}
          position="center"
          logoStyle={{ margin: 0 }}
        />
      </div>
    </Layout>
  );
}
