import {
  ButtonMenu as TCButtonMenu,
  ButtonMenuItem,
} from '@increasecard/typed-components';
import { CreditNoteModal } from '../invoice/components/CreditNoteModal';
import { Payment } from '../../types/Payment';
import { callIfFunction } from '../../utils/utils';
import { RetryPaymentModal } from './RetryPaymentModal';
import { usePaymentActions } from './usePaymentActions';

export interface PaymentActionsMenuProps {
  label: string;
  payment: Payment;
  id?: string;
  context: string;
}

export function PaymentActionsMenu({
  label,
  payment,
  id,
  context,
}: PaymentActionsMenuProps): JSX.Element {
  const { actions, retryModal, refundModal } = usePaymentActions(context, [
    'view_details',
  ]);
  return (
    <>
      <TCButtonMenu text={label} id={id}>
        {({ toggleShowMenu }) =>
          actions.map(({ label, disabled, cb }) => {
            const stringLabel = callIfFunction<string>(label, payment);
            const isDisabled = callIfFunction<boolean>(disabled, payment);
            return (
              <ButtonMenuItem
                key={stringLabel}
                disabled={isDisabled}
                onClick={() => {
                  cb(payment);
                  toggleShowMenu();
                }}
              >
                {stringLabel}
              </ButtonMenuItem>
            );
          })
        }
      </TCButtonMenu>
      <RetryPaymentModal
        visible={retryModal.isOpen}
        invoices={retryModal.data}
        onClose={retryModal.close}
      />
      <CreditNoteModal
        visible={refundModal.isOpen}
        onClose={refundModal.close}
        payment={refundModal.data}
      />
    </>
  );
}
