import {
  Paragraph,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeaderIconBox,
  H3,
} from '@increasecard/typed-components';
import { HelpIcon } from '@increasecard/icons';
import { useTranslation, Trans } from 'react-i18next';

export interface HelpModalProps {
  visible: boolean;
  onClose: () => void;
  titleKey?: string;
  descriptionKey: string;
  noteKey?: string;
}

export function HelpModal({
  visible,
  onClose,
  titleKey,
  descriptionKey,
  noteKey,
}: HelpModalProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <Modal
      visible={visible}
      width="600"
      onClose={onClose}
      onCancel={() => {
        return;
      }}
      onOk={() => {
        return;
      }}
    >
      <ModalHeaderIconBox
        colorPalette="pay"
        icon={<HelpIcon height="48" width="48" />}
      />
      <ModalContent align="center">
        {titleKey && (
          <H3>
            <Trans t={t} i18nKey={titleKey}></Trans>
          </H3>
        )}
        <Paragraph style={{ marginTop: '16px', marginBottom: '16px' }}>
          <Trans t={t} i18nKey={descriptionKey}></Trans>
        </Paragraph>
        {noteKey && (
          <Paragraph>
            <Trans t={t} i18nKey={noteKey}></Trans>
          </Paragraph>
        )}
      </ModalContent>
      <ModalFooter />
    </Modal>
  );
}
