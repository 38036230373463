import { LabelProps } from 'recharts';
import {
  PaymentStatusSharePoint,
  RetryPerformance,
  SubscriptionByStatus,
  SubscriptionMetricPoint,
} from '../../types/Metrics';
import { SubscriptionStatus } from '../../types/Subscription';
import { formatWithLocale } from '../../utils/dateUtils';
import { capitalize } from '../../utils/utils';

export function formatMonthlySubscription(
  item: SubscriptionMetricPoint
): SubscriptionMetricPoint {
  return {
    ...item,
    time: capitalize(formatWithLocale(item.time, 'MMM yyyy')),
  };
}

export interface PaymentStatusSharePercentage {
  time: string;
  open: number;
  failed: number;
  reversed: number;
  processing: number;
  approved: number;
  total: number;
}
const getPercent = (amount: number, total: number) => {
  return total > 0 ? Number(((Number(amount) * 100) / total).toFixed(2)) : 0;
};

export function formatMonthInfo(
  data: PaymentStatusSharePoint
): PaymentStatusSharePercentage {
  let total = 0;

  const {
    time,
    total_open_amount,
    total_failed_amount,
    total_reversed_amount,
    total_processing_amount,
    total_approved_amount,
  } = Object.fromEntries(
    Object.entries(data).map(([key, value]) => {
      if (key === 'time') return [key, value];
      const parsedValue = parseFloat(value);
      total += parsedValue;
      return [key, parsedValue];
    })
  );

  const timeFormatted = formatWithLocale(time, 'MMM yyyy');

  return {
    time: capitalize(timeFormatted),
    open: getPercent(total_open_amount, total),
    failed: getPercent(total_failed_amount, total),
    reversed: getPercent(total_reversed_amount, total),
    processing: getPercent(total_processing_amount, total),
    approved: getPercent(total_approved_amount, total),
    total,
  };
}

export const CHART_TOOLTIP_STYLE = {
  shared: false,
  wrapperStyle: {
    outline: 'none',
  },
  contentStyle: {
    fontSize: '13px', // CellText
    border: '1px solid #d8d8d8', // Gray.medium
    borderRadius: '4px',
  },
  itemStyle: {
    color: '#182026', // Gray.black
  },
};

export function getYAxisLabel(value: string): LabelProps {
  return {
    value,
    angle: -90,
    position: 'center',
    fontSize: 13,
    fontWeight: 'bold',
    dx: -30,
  };
}

export function formatRetryPerformance(
  item: RetryPerformance
): RetryPerformance {
  return {
    ...item,
    time: capitalize(formatWithLocale(item.time, 'MMM yyyy')),
  };
}

type SubscriptionsEvolutionPoint = {
  time: string;
  currency: string;
  totalAmount: number;
  totalCount: number;
} & Partial<Record<SubscriptionStatus, number>>;

export function formatSubscriptionsEvolution(
  data: SubscriptionByStatus
): SubscriptionsEvolutionPoint {
  const newItem: SubscriptionsEvolutionPoint = {
    time: '',
    currency: '',
    totalAmount: 0,
    totalCount: 0,
  };
  let k: keyof SubscriptionByStatus;
  for (k in data) {
    if (Object.prototype.hasOwnProperty.call(data, k)) {
      if (k === 'time') {
        newItem[k] = capitalize(formatWithLocale(data[k], 'MMM yyyy'));
      } else if (k === 'currency') {
        newItem[k] = data[k];
      } else if (k === 'active') {
        newItem.totalAmount += data[k]?.amount || 0;
        newItem.totalCount += data[k]?.count || 0;
        newItem[k] = data[k]?.count || 0;
      } else {
        newItem.totalCount += data[k]?.count || 0;
        newItem[k] = data[k]?.count || 0;
      }
    }
  }
  let key: keyof SubscriptionsEvolutionPoint;
  for (key in newItem) {
    if (Object.prototype.hasOwnProperty.call(newItem, key)) {
      if (
        key === 'time' ||
        key === 'currency' ||
        key === 'totalAmount' ||
        key === 'totalCount'
      ) {
        continue;
      }
      newItem[key] = getPercent(newItem[key] || 0, newItem.totalCount);
    }
  }
  return newItem;
}
