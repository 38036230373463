import { staging } from './staging';

// Use staging as a base config
export const playground = {
  ...staging,
  urls: {
    api: {
      platform: 'https://gateway.playground.increase.app/platform',
      pay: 'https://gateway.playground.increase.app/pay',
    },
    platform: 'https://platform.playground.increase.app',
    auth: 'https://auth.playground.increase.app',
    pay: 'https://pay.playground.increase.app',
    landing: 'https://playground.increase.app',
  },
};
