import { Link } from '@increasecard/typed-components';
import { Column } from '../../../components/common/Column';
import { IconButton } from '../../../components/common/IconButton';
import { Row } from '../../../components/common/Row';
import { Subscription } from '../../../types';
import { ConfirmModal } from '../../../components/common/ConfirmModal';
import { useModal } from '../../../hooks/useModal';
import { useUpdateSubscription } from '../subscriptionQueries';
import { AutorenewIcon } from '@increasecard/icons';
import { ApiErrorMessage } from '../../../components/ErrorMessage';
import { formatCurrency } from '../../../utils/currencyUtils';

export interface DesynchedAmountProps {
  subscription: Subscription;
}

export function DesynchedAmount({
  subscription,
}: DesynchedAmountProps): JSX.Element | null {
  const modal = useModal();
  const { mutate, error, isLoading, isSuccess, isIdle } =
    useUpdateSubscription();

  const syncSusbcriptionAmount = () => {
    mutate({ id: subscription.id, amount: 0 });
  };

  if (!subscription) return null;

  return (
    <>
      {!subscription.amount_synced_with_plan && (
        <Column margin="0" alignItems="center">
          <Row margin="0">
            <IconButton iconType="warning" height="16px" width="16px" />
            Precio establecido manualmente
          </Row>
          <Link style={{ color: 'inherit' }} as="button" onClick={modal.open}>
            Sincronizar con plan
          </Link>
        </Column>
      )}
      <ConfirmModal
        header="Sincronizar precio con plan"
        confirmLabel="Sincronizar"
        onConfirm={!error && !isSuccess ? syncSusbcriptionAmount : undefined}
        onCancel={() => modal.close()}
        cancelLabel={error || isSuccess ? 'Cerrar' : 'Cancelar'}
        visible={modal.isOpen}
        isLoading={isLoading}
        headerIcon={<AutorenewIcon height="48" width="48" />}
      >
        {(isIdle || isLoading) && (
          <>
            El precio de esta suscripción está establecido manualmente.
            <br /> ¿Desea sincronizarlo con el precio base del plan (
            {formatCurrency(
              subscription.plan.amount,
              subscription.plan.currency
            )}
            ) ?
          </>
        )}
        {isSuccess &&
          'El precio de la suscripción se sincronizó correctamente.'}
        {error && <ApiErrorMessage error={error} />}
      </ConfirmModal>
    </>
  );
}
