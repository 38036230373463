import { useTranslation } from 'react-i18next';
import { InvoicesList } from '../invoice/InvoicesList';
import { PaymentsList } from './PaymentsList';
import { ReservationsList } from '../reservations/ReservationsList';
import { VerticalNav } from '../../components/common/VerticalNav';
import {
  PayAccessControl,
  PayAuthorizations,
} from '../authorizations/PayAccessControl';
import { ImportInvoicesScreen } from '../invoice/ImportInvoicesScreen';
import { DebtInvoicesList } from '../invoice/DebtInvoiceList';

export function PaymentsScreen(): JSX.Element {
  const { t } = useTranslation();

  const SCREEN_ELEMENTS = [
    {
      path: '',
      title: t('common.invoices'),
      content: (
        <PayAccessControl allowed={PayAuthorizations.viewInvoices}>
          <InvoicesList context="invoice screen" />
        </PayAccessControl>
      ),
    },
    {
      path: '/payments',
      title: t('common.payment_orders'),
      content: (
        <PayAccessControl allowed={PayAuthorizations.viewPayments}>
          <PaymentsList />
        </PayAccessControl>
      ),
    },
    {
      path: '/reservations',
      title: t('common.reservations'),
      content: (
        <PayAccessControl allowed={PayAuthorizations.viewPayments}>
          <ReservationsList />
        </PayAccessControl>
      ),
    },
    {
      path: '/debt-invoices',
      title: t('common.debt_management'),
      content: (
        <PayAccessControl allowed={PayAuthorizations.viewInvoices}>
          <DebtInvoicesList context="debt-invoice screen" />
        </PayAccessControl>
      ),
    },
    {
      path: '/import',
      title: t('screens.import.generic_link'),
      content: (
        <PayAccessControl allowed={PayAuthorizations.viewPayments}>
          <ImportInvoicesScreen />
        </PayAccessControl>
      ),
    },
  ];

  return <VerticalNav elements={SCREEN_ELEMENTS} />;
}
