import styled from 'styled-components';
import {
  Button,
  Grid,
  H2,
  H3,
  Paragraph,
  Spinner,
} from '@increasecard/typed-components';
import { FrontPlan as Plan } from '../../../types/Plan';
import {
  useTranslationObject,
  Trans,
} from '../../../hooks/useTranslationObject';
import { formatDate } from '../../../utils/dateUtils';
import {
  ApiErrorMessage,
  ErrorMessage,
} from '../../../components/ErrorMessage';
import { formatCurrency } from '../../../utils/currencyUtils';
import { ConfirmModal } from '../../../components/common/ConfirmModal';
import { useModal } from '../../../hooks/useModal';
import { AutorenewIcon } from '@increasecard/icons';
import { useLastCycleStats, usePlanBulkRetry } from '../planQueries';

const Wrapper = styled.div`
  background-color: var(--color-pay-light);
  border: 1px solid var(--color-pay-regular);
  border-radius: 9px;
  padding: 1rem;
`;

export interface PlanBulkRetryProps {
  plan: Plan;
}

const TRANSLATIONS_KEY = 'components.plan_bulk_retry';
const UNEXPECTED_API_ERROR_KEY = 'api.errors.unexpected_error';

export function PlanBulkRetry({ plan }: PlanBulkRetryProps): JSX.Element {
  const { translations, t } = useTranslationObject(TRANSLATIONS_KEY);
  const confirmModal = useModal();
  const {
    data: stats,
    isLoading,
    error: statsError,
  } = useLastCycleStats(plan.id);

  const bulkRetry = usePlanBulkRetry();
  const paymentsCount = stats?.uncollectible_invoices_stats.count || -1;
  const failedAmount = stats
    ? formatCurrency(stats.uncollectible_invoices_stats.sum, plan.currency)
    : -1;
  const hasPaymentsToRetry = stats && paymentsCount > 0;
  const lastUpdate = formatDate(new Date(), 'dd/MM/yyyy');

  function onRetryClick() {
    confirmModal.open();
  }

  function onConfirmRetry() {
    bulkRetry.mutate(plan.id);
    confirmModal.close();
  }

  const parseRetryError = (error: unknown) => {
    if (!error) return t(UNEXPECTED_API_ERROR_KEY);
    const retryApiError = error as Metadata;
    if (retryApiError.code === 'validation_errors') {
      return t(
        [
          `api.errors.plan_bulk_retry.${retryApiError.message}`,
          'api.errors.unexpected_error_message',
        ],
        {
          message: retryApiError.message,
        }
      );
    }
    return t(UNEXPECTED_API_ERROR_KEY);
  };

  return (
    <>
      <Wrapper>
        <Grid>
          <H2>{translations.title}</H2>
          <br />
          {isLoading && <Spinner size={16} />}
          <ApiErrorMessage error={statsError} />
          {hasPaymentsToRetry && (
            <>
              <Paragraph>
                <Trans
                  t={t}
                  i18nKey={`${TRANSLATIONS_KEY}.failed_payments`}
                  values={{
                    payments_count: paymentsCount,
                    amount: failedAmount,
                    updated_at: lastUpdate,
                  }}
                />
              </Paragraph>
              <br />
              {bulkRetry.isLoading ? (
                <Spinner size={16} />
              ) : (
                <Button disabled={!hasPaymentsToRetry} onClick={onRetryClick}>
                  {translations.retry_now}
                </Button>
              )}
              {bulkRetry.error && (
                <ErrorMessage>{parseRetryError(bulkRetry.error)}</ErrorMessage>
              )}
            </>
          )}
          {!isLoading && !hasPaymentsToRetry && (
            <Paragraph>{translations.no_failed_payments}</Paragraph>
          )}
        </Grid>
      </Wrapper>
      {hasPaymentsToRetry && (
        <ConfirmModal
          headerIcon={<AutorenewIcon height="48" width="48" />}
          message={
            <H3>
              {t(`${TRANSLATIONS_KEY}.confirm_message`, {
                payments_count: paymentsCount,
                currency: plan.currency,
                amount: failedAmount,
              })}
            </H3>
          }
          confirmLabel={translations.retry_now}
          cancelLabel={t('common.cancel')}
          onConfirm={onConfirmRetry}
          onCancel={confirmModal.close}
          visible={confirmModal.isOpen}
        />
      )}
    </>
  );
}
