import { useQuery, UseQueryResult } from '@tanstack/react-query';
import {
  FetchSummaryMetrics,
  fetchSummaryMetrics,
  fetchPaymentStatusShareByMonth,
  fetchMonthlySubscriptions,
  fetchRetryPerformanceChart,
  fetchSubscriptionsByStatus,
} from '../../services/MetricsService';
import {
  RetryPerformance,
  SubscriptionMetricPoint,
  SummaryMetrics,
} from '../../types/Metrics';
import { NewApiError } from '../../types/NewApiError';
import { SubscriptionStatus } from '../../types/Subscription';
import {
  formatMonthInfo,
  formatMonthlySubscription,
  formatRetryPerformance,
  formatSubscriptionsEvolution,
  PaymentStatusSharePercentage,
} from './helpers';

export type UseBigNumbers = UseQueryResult<SummaryMetrics, NewApiError>;

export const useSummaryMetrics = (
  filters: FetchSummaryMetrics
): UseBigNumbers => {
  return useQuery(
    ['summary_metrics', filters],
    () => fetchSummaryMetrics(filters),
    // Add placeholder data to be able to iterate through it right away
    {
      placeholderData: {
        mrr_amount: '0',
        total_active_subscriptions: '0',
        total_invoiced: '0',
        total_payments_approved: '0',
        approved_over_total_invoices: '0',
      },
    }
  );
};

export const usePaymentStatusShareByMonth = (
  filters: FetchSummaryMetrics
): UseQueryResult<PaymentStatusSharePercentage[], NewApiError> => {
  return useQuery(
    ['payments_status_share_by_month', filters],
    () => fetchPaymentStatusShareByMonth(filters.currency),
    {
      select: (data) => {
        return data.data.map(formatMonthInfo);
      },
      staleTime: Infinity,
    }
  );
};

export const useMonthlyCreatedSubscriptions = (): UseQueryResult<
  SubscriptionMetricPoint[],
  NewApiError
> => {
  return useQuery(
    ['subscriptions_created_by_month'],
    () => fetchMonthlySubscriptions(),
    {
      select: (data) => {
        return data.data.map(formatMonthlySubscription);
      },
      staleTime: Infinity,
    }
  );
};

export const useRetryPerformanceChart = (
  filters: FetchSummaryMetrics
): UseQueryResult<RetryPerformance[], NewApiError> => {
  return useQuery(
    ['payments_approved_by_retries', filters],
    () => fetchRetryPerformanceChart(filters.currency),
    {
      select: (data) => {
        return data.data.map(formatRetryPerformance);
      },
      staleTime: Infinity,
    }
  );
};

export type SubscriptionsEvolutionPoint = {
  time: string;
  currency: string;
  totalAmount: number;
  totalCount: number;
} & Partial<Record<SubscriptionStatus, number>>;

export const useSubscriptionsByStatus = (
  filters: FetchSummaryMetrics
): UseQueryResult<SubscriptionsEvolutionPoint[], NewApiError> => {
  return useQuery(
    ['subscriptions_by_status', filters],
    () => fetchSubscriptionsByStatus(filters.currency),
    {
      select: (data) => {
        return data.map(formatSubscriptionsEvolution);
      },
      staleTime: Infinity,
    }
  );
};
