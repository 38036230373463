import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Header } from '../../components/common/Header';
import { PayAuthorizations } from '../authorizations/usePayAuthorizations';
import { InvoiceTable } from './InvoiceTable';
import { InvoiceFilters, useInvoiceFilters } from './components/InvoiceFilters';

interface DebtInvoiceListProps {
  context: string;
}

export function DebtInvoicesList({
  context,
}: DebtInvoiceListProps): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const [filters] = useInvoiceFilters();

  const handleNewInvoice = () => {
    history.push('/invoices/debt-invoices/new');
  };

  // render
  return (
    <>
      <Header
        title={t('common.debt_management')}
        primaryButton={{
          onClick: handleNewInvoice,
          text: t('common.manage_debt'),
          allowedAuthorization: PayAuthorizations.createInvoice,
        }}
      />
      <InvoiceFilters omit={['kind']} />
      <InvoiceTable
        filters={{ ...filters, kind: 'debt_recovery' }}
        context={context}
      />
    </>
  );
}
