import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { RequiredString, RequiredPercentage } from '../../../schemas/common';

type TaxFormValues = Record<
  'country' | 'name' | 'external_id' | 'description' | 'amount',
  string
>;

export function TaxSchema(t: TFunction): Yup.SchemaOf<Partial<TaxFormValues>> {
  return Yup.object({
    country: Yup.string(),
    name: RequiredString(),
    external_id: Yup.string(),
    description: Yup.string(),
    amount: RequiredPercentage(t),
  });
}
