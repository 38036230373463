import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid } from '@increasecard/typed-components';
import { useModal } from '../../hooks/useModal';
import { SubscriptionSuccessModal } from './SubscriptionSuccessModal';
import { SubscriptionForm, SubscriptionFormValues } from './SubscriptionForm';
import { Header } from '../../components/common/Header';
import { splitExtrasByType } from '../../utils/extrasUtils';
import { useCreateSusbcription } from './subscriptionQueries';
import { useQueryParams } from '../../hooks/useQueryParams';
import { ScreenSeparator } from '../../components/common/ScreenSeparator';
import { GoBackButton } from '../../components/common/GoBackButton';
import { SubscriptionPaymentStep } from './SubscriptionPaymentStep';
import { Breadcrumbs } from './components/Breadcrumbs';
import { Subscription } from '../../types';
import { SubscriptionCustomerStep } from './SubscriptionCustomerStep';

const WizardSteps = {
  customer: 'customer',
  subscription: 'subscription',
  payment: 'payment',
};

const breadcrumSteps = [
  { name: 'Cliente', value: 'customer' },
  { name: 'Plan', value: 'subscription' },
  { name: 'Medio de pago', value: 'payment' },
];

const prefix = 'screens.new_subscription';

export function NewSubscriptionScreen(): JSX.Element {
  const { t } = useTranslation();
  const successModal = useModal();
  const history = useHistory();
  const { customer_id } = useQueryParams();
  const [currentStep, setCurrentStep] = useState(
    customer_id ? WizardSteps.subscription : WizardSteps.customer
  );
  const [customerId, setCustomerId] = useState<string>(customer_id);
  const subscription = useCreateSusbcription();

  const handleCreateSubscription = (values: SubscriptionFormValues) => {
    const { subscriptionExtras, shareLink, ...rest } = values;
    subscription.mutate(
      {
        ...rest,
        // When a user removes the input contents, the event returns an empty string.
        // so we force it to 0
        trial_period_days: rest.trial_period_days || 0,
        ...splitExtrasByType(subscriptionExtras),
      },
      {
        onSuccess: (subscription: Subscription) =>
          shareLink
            ? successModal.open(subscription)
            : setCurrentStep(WizardSteps.payment),
      }
    );
  };

  const goToSubscriptionsList = () => {
    history.replace('/subscriptions');
  };

  return (
    <>
      {currentStep === WizardSteps.customer && <GoBackButton />}
      {currentStep === WizardSteps.subscription && (
        <GoBackButton onClick={() => setCurrentStep(WizardSteps.customer)} />
      )}
      <Header
        title={t(`${prefix}.title`)}
        description={t(`${prefix}.subtitle`)}
      />
      <Breadcrumbs steps={breadcrumSteps} stepActive={currentStep} />
      <ScreenSeparator margin="1rem 0 2rem 0" />
      <Grid rowGap={2}>
        {currentStep === WizardSteps.customer && (
          <SubscriptionCustomerStep
            customerId={customerId}
            onCancel={goToSubscriptionsList}
            onNextStep={() => setCurrentStep(WizardSteps.subscription)}
            onCustomer={setCustomerId}
          />
        )}
        {currentStep === WizardSteps.subscription && (
          <SubscriptionForm
            isEditing={false}
            customerId={customerId}
            onSubmit={handleCreateSubscription}
            onCancel={goToSubscriptionsList}
            error={subscription.error}
            isLoading={subscription.isLoading}
          />
        )}
        {currentStep === WizardSteps.payment && subscription.data && (
          <SubscriptionPaymentStep subscription={subscription.data} />
        )}
      </Grid>
      {/* This only works because useModal sets default data as any :/ */}
      <SubscriptionSuccessModal
        onClose={goToSubscriptionsList}
        visible={successModal.isOpen}
        subscription={successModal.data}
        isEditing={false}
      />
    </>
  );
}
