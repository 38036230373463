import { useCallback } from 'react';
import { PaymentMethod } from './components/PaymentMethod';
import { EndCheckoutSummary } from './components/EndCheckoutSummary';
import { CheckoutSummary } from './components/CheckoutSummary';
import { SummaryFooter } from './components/SummaryFooter';
import { BrandLogo } from './BrandLogo';
import { FullPageSpinner } from '../../components/common/FullPageSpinner';
import { CheckoutLayout, EndCheckoutLayout } from './Layout';
import { useLanguage } from '../../hooks/useLanguage';
import { redirectTo } from '../../utils/utils';
import { ProcessingPaymentModal } from './components/ProcessingPaymentModal';
import { useCheckoutParams } from './useCheckoutParams';
import { useErrorUserData } from './useErrorUserData';
import { usePaySubscription, useSubscriptionById } from './checkoutQueries';
import { CheckoutData } from './useCheckout';
import { InvalidCheckoutLink } from './InvalidCheckoutLink';
import { canChangePaymentMethod } from './checkoutUtils';
import { useCheckoutAccount } from './AccountContext';

export function SubscriptionCheckoutScreen(): JSX.Element {
  const { subscriptionId } = useCheckoutParams();
  const account = useCheckoutAccount();
  const subscription = useSubscriptionById(subscriptionId);
  const payment = usePaySubscription();

  useErrorUserData({
    subscription: subscription.data,
  });
  useLanguage(subscription.data?.customer.billing_info.country);

  const paySubscription = useCallback(
    (paymentData) => {
      if (subscription.data) {
        payment.mutate(
          {
            subscriptionId: subscription.data.id,
            customerId: subscription.data.customer.id,
            paymentData,
          },
          {
            onSuccess: (payment) => {
              if (payment.payment?.external_resource_url) {
                redirectTo(payment.payment.external_resource_url);
              }
            },
          }
        );
      }
    },
    [payment, subscription.data]
  );

  if (!subscription.data) return <FullPageSpinner />;

  const subscriptionIsInvalid =
    !subscription.data.may_change_payment_method ||
    !canChangePaymentMethod(subscription.data);

  if (subscriptionIsInvalid) return <InvalidCheckoutLink account={account} />;

  const brandingSettings = account.branding_settings;
  const layoutPosition = payment.isSuccess ? 'center' : 'left';
  const isFirstPaymentMethod = !subscription.data.payment_method;

  // payment type will always show the next charge for the payment method the customer is entering.
  const summaryType = 'payment';

  if (payment.isSuccess) {
    return (
      <EndCheckoutLayout brandingSettings={brandingSettings}>
        <EndCheckoutSummary
          subscription={payment.data.subscriptionPaid}
          simpleSummary={!isFirstPaymentMethod}
        />
      </EndCheckoutLayout>
    );
  }

  return (
    <>
      <CheckoutLayout
        brandingSettings={brandingSettings}
        leftContext={
          <>
            <BrandLogo
              brandingSettings={brandingSettings}
              position={layoutPosition}
            />
            <PaymentMethod
              checkoutData={
                {
                  plan: subscription.data.plan,
                } as CheckoutData
              }
              subscription={subscription.data}
              onNext={paySubscription}
              account={account}
              customerId={subscription.data.customer.id}
              processing={payment.isLoading}
              brandingSettings={brandingSettings}
              errorObj={payment.error}
            />
          </>
        }
        rightContext={
          <CheckoutSummary
            subscription={subscription.data}
            brandingSettings={brandingSettings}
            summaryType={summaryType}
          />
        }
        footerContext={
          <SummaryFooter
            subscription={subscription.data}
            summaryType={summaryType}
          />
        }
      />
      <ProcessingPaymentModal visible={payment.isLoading} />
    </>
  );
}
