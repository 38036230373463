import * as Yup from 'yup';
import { SubscriptionFormValues } from '../SubscriptionForm';

export function SubscriptionSchema(): Yup.SchemaOf<
  Omit<SubscriptionFormValues, 'customer' | 'plan'>
> {
  return Yup.object({
    plan_id: Yup.string().uuid().defined(),
    customer_id: Yup.string().uuid().defined(),
    quantity: Yup.number().nullable(),
    amount: Yup.number().defined(),
    start_date: Yup.string().optional(),
    end_date: Yup.string().optional(),
    next_billing_date: Yup.string().optional(),
    trial_period_days: Yup.number().min(0).optional(),
    subscriptionExtras: Yup.array(),
    external_id: Yup.string().nullable().optional(),
    payment_method_id: Yup.string().uuid().optional(),
    tags: Yup.array(),
    shareLink: Yup.boolean(),
  });
}
