import { environment } from './config';
import { parseQueryString } from './utils/apiUtils';

const flags: Metadata = parseQueryString(window.location.search);
export const featureFlags = {
  enableTracking: flags.enableTracking && environment === 'sandbox',
  enableDebtInvoiceWarn: environment === 'staging',
  enableSoftDelete: flags.enableSoftDelete || environment === 'staging',
  verticalMenuIcon: !!flags.verticalMenuIcon,
  mpForm: !!flags.mpForm,
};
