import { useTranslation } from 'react-i18next';
import { CellText } from '@increasecard/typed-components';
import { CheckoutData } from '../../useCheckout';
import { CheckoutSubscription } from '../../../../types/Subscription';
import { formatISODate } from '../../../../utils/dateUtils';
import { SubscriptionAmountToCharge } from '../SubscriptionAmountToCharge';
import { SummaryTable } from './SummaryTable';
import { compareAsc, parseISO } from 'date-fns';

export interface PaymentSummaryProps {
  subscription: CheckoutSubscription;
  checkoutData?: CheckoutData;
}

export function PaymentSummary({
  checkoutData,
  subscription,
}: PaymentSummaryProps): JSX.Element | null {
  const { t } = useTranslation();

  const plan = subscription?.plan || checkoutData?.plan;
  if (!plan) return null;

  const today = new Date();
  const payDate =
    compareAsc(today, parseISO(subscription?.next_billing_date)) >= 0
      ? today.toISOString()
      : subscription?.next_billing_date;

  return (
    <div style={{ marginTop: '1.5rem', width: '100%' }}>
      <CellText>
        {t('screens.customer_checkout.next_billing_date', {
          date: formatISODate(payDate),
        })}
      </CellText>
      <CellText>
        <SubscriptionAmountToCharge subscriptionId={subscription?.id} />
      </CellText>
      <SummaryTable
        checkoutData={checkoutData}
        subscription={subscription}
        summaryType="payment"
      />
    </div>
  );
}
