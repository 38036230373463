import { useEffect } from 'react';
import * as config from '../../config/module';
import { environment as configEnvironment } from '../../config';
import { usePayUser } from '../../hooks/usePayUser';

export function RedirectToSandbox(): null {
  const user = usePayUser();
  useEffect(() => {
    const setRedirect = () => {
      if (!user) {
        return;
      }
      if (!user.paySubscription) {
        // all users MUST have a PAY subscription
        window.location.replace(config[configEnvironment].urls.platform);
        return;
      }
      if (
        configEnvironment === 'production' &&
        user.paySubscription.state === 'sandbox'
      ) {
        window.location.replace(config.sandbox.urls.pay);
        return;
      }
    };
    setRedirect();
  }, [user]);

  return null;
}
