import styled from 'styled-components';

export const Skeleton = styled.span<{
  height?: string;
  width?: string;
  speed?: string;
  opacity?: string;
}>`
  height: ${({ height = '1.2rem' }) => height};
  width: ${({ width = 'auto' }) => width};
  display: block;
  background-color: var(--color-gray-grayLight);
  transform: scale(1, 0.6);
  margin-top: 0;
  border-radius: 4px;
  margin-bottom: 0;
  transform-origin: 0 60%;
  overflow: hidden;
  position: relative;
  opacity: ${({ opacity = '0.8' }) => opacity};
  &:empty:before {
    content: '\\00a0';
  }
  &::after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    position: absolute;
    animation: wave ${({ speed = '2s' }) => speed} linear infinite;
    transform: translateX(-100%);
    background: linear-gradient(
      90deg,
      transparent,
      var(--color-gray-grayMedium),
      transparent
    );
  }
  @keyframes wave {
    0% {
      transform: 'translateX(-100%)';
    }

    100% {
      transform: translateX(100%);
    }
  }
`;
