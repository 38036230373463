import { useState, useCallback, ChangeEvent } from 'react';

type InputProps = {
  name: string;
  type: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

export type UseRadioButtons = [string, InputProps, (value: string) => void];

export const useRadioButtons = (
  name: string,
  initialValue: string
): UseRadioButtons => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const changeValue = useCallback(
    (value) => {
      setValue(value);
    },
    [setValue]
  );

  const inputProps = {
    name,
    type: 'radio',
    onChange: handleChange,
  };

  return [value, inputProps, changeValue];
};
