import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApiErrorMessage } from '../../components/ErrorMessage';
import { capitalize } from '../../utils/utils';
import { getPaymentStatus } from '../../utils/paymentUtils';
import { Constants } from '../../constants';
import { CreditNoteModal } from '../invoice/components/CreditNoteModal';
import {
  DataTable,
  ColumnDefinition,
  RowDefinition,
} from '../../components/common/DataTable/DataTable';
import { usePayments } from './paymentQueries';
import { RetryPaymentModal } from './RetryPaymentModal';
import { usePaymentActions } from './usePaymentActions';
import { Payment } from '../../types/Payment';

export interface PaymentsTableProps {
  filters: Metadata;
  omitFields?: string[];
  header?: string;
  trackingContext: string;
}

export function PaymentsTable({
  filters,
  omitFields = [],
  header,
  trackingContext,
}: PaymentsTableProps): JSX.Element {
  const { t } = useTranslation();

  const [page, setPage] = useState(1);
  const { data, error, isLoading, refetch } = usePayments({
    page,
    per_page: Constants.LARGE_PAGE_SIZE,
    ...filters,
  });

  const { actions, goToDetails, retryModal, refundModal } =
    usePaymentActions(trackingContext);

  const availableColumns: ColumnDefinition[] = [
    {
      field: 'created_at',
      type: 'date',
      headerName: t('common.created'),
    },
    {
      field: 'invoice.customer',
      type: 'customer',
      headerName: t('common.customer'),
    },
    {
      field: 'payment_method',
      type: 'payment_method',
      headerName: t('common.method'),
    },
    {
      field: 'status',
      type: 'tag',
      headerName: t('common.status'),
      tagType: 'payment',
      tagLabel: (payment: RowDefinition) =>
        t(`data.payment.status.${getPaymentStatus(payment)}`),
      valueGetter: getPaymentStatus,
    },
    {
      field: 'description',
      type: 'string',
      headerName: t('common.detail'),
      valueGetter: (payment: RowDefinition) => capitalize(payment.description),
    },
    {
      field: 'events[0].created_at',
      type: 'date',
      headerName: t('common.last_update'),
    },
    {
      field: 'amount',
      type: 'currency',
      headerName: t('common.amount'),
      rightAligned: true,
    },
  ];

  const tableColumns = availableColumns.filter(
    (cfg) => !omitFields.includes(cfg.field)
  );

  const loadPage = (pageNumber: number) => setPage(pageNumber);

  const onErrorMessageClick = () => refetch();

  // error
  if (error) {
    return <ApiErrorMessage error={error} onClick={onErrorMessageClick} />;
  }

  const hasData = data?.data ? data?.data.length > 0 : false;

  // render
  return (
    <>
      <DataTable
        tableProps={{ hasData }}
        columns={tableColumns}
        pageSize={Constants.LARGE_PAGE_SIZE}
        isLoading={isLoading}
        rows={data?.data}
        menuActions={actions}
        rowHeight="double"
        rowBorder
        paginatorProps={{
          pages: data?.total_pages,
          selected: Number(data?.current_page),
          onSelectPage: loadPage,
        }}
        tableHeader={header}
        onRowClick={(payment: Payment) =>
          goToDetails(payment.id, 'list row click')
        }
      />
      <RetryPaymentModal
        visible={retryModal.isOpen}
        invoices={retryModal.data}
        onClose={retryModal.close}
      />
      <CreditNoteModal
        visible={refundModal.isOpen}
        onClose={refundModal.close}
        payment={refundModal.data}
      />
    </>
  );
}
