import { Formik } from 'formik';
import { useTranslation, Trans } from 'react-i18next';
import {
  Button,
  Switch,
  Grid,
  Paragraph,
  H3,
  Caption,
  InlineText,
  Link,
} from '@increasecard/typed-components';
import APP_CONFIG from '../../config';
import { GridForm } from '../../components/common/GridForm';
import { environment } from '../../config';
import { RetriesConfig } from './RetriesConfig';
import {
  InputNumberField,
  InputTextField,
  SelectField,
} from '../../components/common/FormikFields';
import { MercadopagoSchema } from './CollectMethodsSchema';
import { useCreateOrUpdateCollectMethod } from './collectMethodQueries';
import { ApiErrorMessage } from '../../components/ErrorMessage';
import { Row } from '../../components/common/Row';
import { PanelFormConfig } from './collectMethodsFormConfig/PanelConfig';
import { ChangeEvent } from 'react';
import { LoadingButton } from '../../components/common/LoadingButton';
import { list2NameValue } from '../../utils/utils';
import { COUNTRIES } from '../../localizations';
import { CollectMethod } from '../../types';
import { featureFlags } from '../../featureFlags';

export interface MPConfigPanelProps {
  config: PanelFormConfig;
  onClose: VoidFunction;
}

const { ARGENTINA, PERU, COLOMBIA, CHILE } = COUNTRIES;

export function MPConfigPanel({
  config,
  onClose,
}: MPConfigPanelProps): JSX.Element {
  const { t } = useTranslation();
  const isCreating = config.status === undefined; // If a status is not set, asume create mode.
  const isDisabled = config.status === 'disabled';
  const isProductionEnv = environment === 'production';
  const translations: Metadata = t('screens.collect_methods.mercadopago', {
    returnObjects: true,
  });
  const createOrUpdate = useCreateOrUpdateCollectMethod();

  const countryOptions = list2NameValue(
    [ARGENTINA, PERU, COLOMBIA, CHILE],
    'data.countries',
    'common.select'
  );

  const handleOpenMPAuthPage = (cmId: string, country: string) => {
    const url = APP_CONFIG.collectMethods.mercadopago[
      country
    ].integrationUrl.replace(
      '{{COLLECT_METHOD_ID}}',
      cmId || config.initialValues.id
    );
    window.open(url);
  };

  const formikProps = {
    initialValues: config.initialValues,
    enableReinitialize: true,
    validationSchema: MercadopagoSchema(t),
    onSubmit: (values: PanelFormConfig['initialValues']) => {
      createOrUpdate.mutate(
        {
          type: config.type,
          name: config.name,
          ...values,
        },
        {
          onSuccess: (cm: CollectMethod) => {
            handleOpenMPAuthPage(cm.id, cm.country);
          },
        }
      );
    },
  };

  if (!isProductionEnv && !featureFlags.mpForm) {
    return (
      <Paragraph>
        <Trans
          t={t}
          i18nKey={`screens.collect_methods.mercadopago.info.${environment}`}
          components={[
            <Link
              key="1"
              href="https://api.whatsapp.com/send/?phone=541150365046&text&app_absent=0"
              target="_blank"
            />,
          ]}
        />
      </Paragraph>
    );
  }

  return (
    <>
      <Formik {...formikProps}>
        {(formik) => (
          <GridForm>
            <Grid rowGap={2}>
              {config.initialValues.metadata?.nickname && (
                <Paragraph>
                  {t('common.linked_up_account')}
                  <InlineText inline weight="bold">
                    {config.initialValues.metadata?.nickname}
                  </InlineText>
                </Paragraph>
              )}
              <InputTextField
                id="name"
                label={t('common.name')}
                className="grid-span-desktop-6"
                disabled={isDisabled}
              />
              <SelectField
                id="country"
                className="grid-span-desktop-6"
                label={t('common.country')}
                options={countryOptions}
                disabled={isDisabled}
              />
              <H3>
                {t(
                  'screens.collect_methods.mercadopago.offline_payments_management'
                )}
              </H3>
              <Switch
                id="metadata.offline_payments_enabled"
                label={translations.offline_payments}
                checked={formik.values.metadata.offline_payments_enabled}
                onChange={(...e: ChangeEvent<HTMLInputElement>[]) =>
                  formik.handleChange(e[0])
                }
                disabled={isDisabled}
              />
              <div className="grid-span-desktop-6">
                <InputNumberField
                  id="metadata.expiration_days"
                  label={translations.expiration_days}
                  min="3"
                  max="30"
                  step="1"
                  disabled={
                    isDisabled ||
                    !formik.values.metadata.offline_payments_enabled
                  }
                />
                <Caption>{translations.expiration_days_disclaimer}</Caption>
              </div>
            </Grid>
            <RetriesConfig disabled={isDisabled} />
            <ApiErrorMessage error={createOrUpdate.error} />
            <Row margin="0" flexGap="1rem" justifyContent="center">
              <Button onClick={onClose} buttonType="invisible" type="button">
                {t('common.close')}
              </Button>
              <LoadingButton
                className="submit"
                type="submit"
                disabled={!formik.dirty || !formik.isValid}
                isLoading={createOrUpdate.isLoading}
              >
                {isCreating ? t('common.link_up') : t('common.save')}
              </LoadingButton>
              {!isCreating && (
                <Button
                  onClick={() =>
                    handleOpenMPAuthPage(
                      formik.values.id as string,
                      formik.values.country
                    )
                  }
                >
                  {t('common.re_link')}
                </Button>
              )}
            </Row>
          </GridForm>
        )}
      </Formik>
    </>
  );
}
