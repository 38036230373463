import { ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const publicQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60,
      refetchOnReconnect: true,
      retry: (failureCount: number, error: Metadata) => {
        return (
          (error?.message || error) === 'Network Error' && failureCount <= 2
        );
      },
    },
  },
});
const privateQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      retry: false,
    },
  },
});

// Currently the Public provider is being used for public and pivate api calls.
// This is because private routes are nested inside public routes and having 2 context
// could be prone to bugs.
export function PrivateQueryProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  return (
    <QueryClientProvider client={privateQueryClient}>
      {children}
    </QueryClientProvider>
  );
}

export function PublicQueryProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  return (
    <QueryClientProvider client={publicQueryClient}>
      {children}
    </QueryClientProvider>
  );
}
