import { useEffect, useState } from 'react';
import { Modal } from '@increasecard/typed-components';
import { createReport } from '../../../services/ReportsService';
import { WaitForReportStep } from './WaitForReportStep';
import { ErrorStep } from './ErrorStep';

export interface ExportModalProps {
  path: string;
  isOpen: boolean;
  onClose: () => void;
  pathFilters: Record<string, unknown>;
}

export function ExportModal({
  path,
  isOpen,
  onClose,
  pathFilters,
}: ExportModalProps): JSX.Element {
  const [step, setStep] = useState(0);
  const [reportId, setReportId] = useState<string | null>(null);

  useEffect(() => {
    createReport(path, pathFilters).then((report) => setReportId(report.id));
  }, [path, pathFilters, setReportId]);

  const nextStep = () => {
    setStep(step + 1);
  };

  const handleClose = () => {
    setStep(0);
    onClose && onClose();
  };

  const steps = [
    <>
      <WaitForReportStep
        onSuccess={(link) => {
          window.location.href = link;
          handleClose();
        }}
        onError={() => {
          nextStep();
        }}
        reportId={reportId}
      />
    </>,
    <>
      <ErrorStep onClose={handleClose} />
    </>,
  ];

  return (
    <Modal
      onClose={handleClose}
      visible={isOpen}
      onOk={() => {
        return;
      }}
      onCancel={() => {
        return;
      }}
    >
      {steps[step]}
    </Modal>
  );
}
