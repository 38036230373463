import { Country, Currency } from './types/common';

export {
  IDENTIFICATION_NUMBER_TYPES,
  PAYMENTS_OS_ID_NUMBER_TYPES,
} from './identificationNumberTypes';

// Country codes are ISO Alpha-3. See https://www.iban.com/country-codes
export const COUNTRIES: Record<string, Country> = {
  ARGENTINA: 'ARG',
  PERU: 'PER',
  COLOMBIA: 'COL',
  CHILE: 'CHL',
  URUGUAY: 'URY',
  ECUADOR: 'ECU',
  PARAGUAY: 'PRY',
  COSTA_RICA: 'CRI',
  PANAMA: 'PAN',
  MEXICO: 'MEX',
  BRASIL: 'BRA',
};
export const ALL_COUNTRIES = Object.values(COUNTRIES);

// Currency codes are ISO 4217. See https://www.iban.com/currency-codes
export const CURRENCIES: Record<string, Currency> = {
  ARS: 'ARS',
  USD: 'USD',
  PEN: 'PEN',
  COP: 'COP',
  CLP: 'CLP',
  DOP: 'DOP',
  UYU: 'UYU',
  PYG: 'PYG',
  CRC: 'CRC',
  PAB: 'PAB',
  MXN: 'MXN',
  BRL: 'BRL',
};
export const ALL_CURRENCIES = Object.values(CURRENCIES);

export interface CountryConfig {
  language: string;
  langCode: string;
  alpha2Code: string;
  currencies: Currency[];
}

// Language codes are ISO 639-1. See https://www.andiamo.co.uk/resources/iso-language-codes/
export const COUNTRIES_CONFIG: Record<Country, CountryConfig> = {
  ARG: {
    language: 'es-AR',
    langCode: 'es',
    alpha2Code: 'AR',
    currencies: [CURRENCIES.ARS, CURRENCIES.USD],
  },
  PER: {
    language: 'es-PE',
    langCode: 'es',
    alpha2Code: 'PE',
    currencies: [CURRENCIES.PEN, CURRENCIES.USD],
  },
  COL: {
    language: 'es-CO',
    langCode: 'es',
    alpha2Code: 'CO',
    currencies: [CURRENCIES.COP, CURRENCIES.USD],
  },
  CHL: {
    language: 'es-CL',
    langCode: 'es',
    alpha2Code: 'CL',
    currencies: [CURRENCIES.CLP, CURRENCIES.USD],
  },
  URY: {
    language: 'es-UY',
    langCode: 'es',
    alpha2Code: 'UY',
    currencies: [CURRENCIES.UYU, CURRENCIES.USD],
  },
  ECU: {
    language: 'es-EC',
    langCode: 'es',
    alpha2Code: 'EC',
    currencies: [CURRENCIES.USD],
  },
  PRY: {
    language: 'es-PY',
    langCode: 'es',
    alpha2Code: 'PY',
    currencies: [CURRENCIES.PYG, CURRENCIES.USD],
  },
  CRI: {
    language: 'es-CR',
    langCode: 'es',
    alpha2Code: 'CR',
    currencies: [CURRENCIES.CRC, CURRENCIES.USD],
  },
  PAN: {
    language: 'es-PA',
    langCode: 'es',
    alpha2Code: 'PA',
    currencies: [CURRENCIES.PAB, CURRENCIES.USD],
  },
  MEX: {
    language: 'es-MX',
    langCode: 'es',
    alpha2Code: 'MX',
    currencies: [CURRENCIES.MXN, CURRENCIES.USD],
  },
  BRA: {
    language: 'pt-BR',
    langCode: 'pt',
    alpha2Code: 'BR',
    currencies: [CURRENCIES.BRL, CURRENCIES.USD],
  },
};

export const PLATFORM_COUNTRIES: Record<string, Country | undefined> = {
  argentina: COUNTRIES.ARGENTINA,
  peru: COUNTRIES.PERU,
  colombia: COUNTRIES.COLOMBIA,
  chile: COUNTRIES.CHILE,
  uruguay: COUNTRIES.URUGUAY,
  ecuador: COUNTRIES.ECUADOR,
  paraguay: COUNTRIES.PARAGUAY,
  costa_rica: COUNTRIES.COSTA_RICA,
  panama: COUNTRIES.PANAMA,
};
