import styled from 'styled-components';

export const RoundedBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid var(--color-gray-grayMedium);
  background-color: var(--color-gray-white);
`;

export const PlanItemContainer = styled.div`
  width: 100%;
  min-height: 105px;
  display: grid;
  grid-template-areas:
    'name name frequency'
    'price price price';
  grid-template-columns: 1fr 1fr auto;
  gap: 8px;
  align-items: center;
  &:not(:first-child) {
    border-top: 1px solid var(--color-gray-grayMedium);
    padding-top: 8px;
  }
  .plan-name {
    grid-area: name;
    display: flex;
    gap: 8px;
  }
  .plan-price {
    grid-area: price;
    text-align: right;
    align-self: start;
  }
  .cycle-unit {
    grid-area: frequency;
    justify-self: center;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-areas: 'name frequency price';
    grid-template-columns: 1fr minmax(70px, 1fr) 180px;
    min-height: 74px;
  }
`;
