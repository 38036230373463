import { useEffect } from 'react';
import { useLocale } from '@increasecard/increase-core';
import i18n from './i18n';

export function BootUp(): null {
  const locale = useLocale();
  // const user = useUser();

  // useEffect(() => {
  //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //   const zE = (window as any).zE;
  //   if (zE && user !== null) {
  //     zE(() => zE.identify({ name: user.firstName, email: user.email }));
  //   }
  // }, [user]);

  // Sets logged user language
  useEffect(() => {
    if (locale) i18n.changeLanguage(locale);
  }, [locale]);

  return null;
}
