import { ReactNode } from 'react';
import styled from 'styled-components';
import { MethodIcon } from './PaymentMethodLabel';
import { HelpIcon } from '../common/HelpIcon';

const Wrapper = styled.div`
  position: relative;
  line-height: 0;
`;

const MethodIconWrapper = styled.div`
  position: absolute;
  top: 28px;
  right: 10px;
  pointer-events: none;
`;

export interface PaymentMethodInputDecoratorProps {
  children: ReactNode;
  className?: string;
  cardBrand?: string;
  type: string;
  showMethodIcon?: boolean;
  showHelpIcon?: boolean;
  onHelpIconClick?: () => void;
}

export function PaymentMethodInputDecorator({
  children,
  className,
  cardBrand,
  type,
  showMethodIcon,
  showHelpIcon,
  onHelpIconClick = () => {
    return;
  },
}: PaymentMethodInputDecoratorProps): JSX.Element {
  return (
    <Wrapper className={className}>
      {children}
      {showMethodIcon && (
        <MethodIconWrapper>
          <MethodIcon type={type} paymentBrand={cardBrand} />
        </MethodIconWrapper>
      )}
      {showHelpIcon && (
        <HelpIcon
          position={{ top: '-6px', right: '-2px' }}
          onClick={onHelpIconClick}
        />
      )}
    </Wrapper>
  );
}
