import { H1 } from '@increasecard/typed-components';
import styled, { css } from 'styled-components';
import { ScreenSeparator } from './ScreenSeparator';
import { Box } from './Box';

const greenVariant = css`
  color: var(--color-increase-light);
  background-color: var(--color-pay-dark30);
  ${H1}, strong {
    color: var(--color-gray-white);
  }
`;

const alertVariant = css`
  border-color: var(--color-coralAlert-regular);
  color: var(--color-coralAlert-regular);
  background-color: var(--color-gray-white);
`;

export const RoundedCard = styled(Box)<{
  variant?: 'green' | 'white' | 'alert';
  centered?: boolean;
  gap?: string;
}>`
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => (gap ? gap : '1rem')};
  align-items: ${({ centered }) => (centered ? 'center' : 'flex-start')};
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-gray-grayMedium);
  background-color: var(--color-gray-white);
  overflow-wrap: anywhere;
  ${({ variant }) => {
    if (variant === 'green') {
      return greenVariant;
    } else if (variant === 'alert') {
      return alertVariant;
    }
  }}

  ${ScreenSeparator} {
    opacity: 0.5;
  }
`;
