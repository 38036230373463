import { useState } from 'react';
import { ErrorMessage } from '../../components/ErrorMessage';
import { useTranslation } from 'react-i18next';
import { formatDate, parseDate, getNextYear } from '../../utils/dateUtils';
import { DateInput, Checkbox, InputText } from '@increasecard/typed-components';
import { Box } from '../../components/common/Box';
import { Subscription } from '../../types/Subscription';
import { parseISO, startOfTomorrow, startOfToday, addDays } from 'date-fns/esm';
import { FrontPlan } from '../../types/Plan';
import { useValidateSubscriptionDateRange } from './subscriptionDateValidator';
import { canChangeBillingDate } from '../../utils/subscriptionUtils';
import { BillingDateInfo } from './components/BillingDateInfo';

export type ScheduleChangeEvent = {
  isValid: boolean;
  startDate: string;
  billingDate: string;
  endDate?: string;
};

export interface SubscriptionSchedulerProps {
  onChange: (e: ScheduleChangeEvent) => void;
  subscription?: Subscription;
  plan?: FrontPlan;
}

export function SubscriptionScheduler({
  onChange,
  subscription,
  plan,
}: SubscriptionSchedulerProps): JSX.Element {
  const { t } = useTranslation();
  const nextYear = getNextYear();
  const { activate_at, cancel_at, next_billing_date } = subscription || {};
  const [start, setStart] = useState(
    activate_at ? parseDate(activate_at) : new Date()
  );
  const [end, setEnd] = useState(cancel_at ? parseDate(cancel_at) : undefined);
  const [billingDate, setBillingDate] = useState(
    next_billing_date ? parseISO(next_billing_date) : undefined
  );
  const [renewForever, setRenewForever] = useState(!subscription?.cancel_at);
  const [errorMessage, setErrorMessage] = useState('');

  const _canChangeBillingDate = subscription
    ? canChangeBillingDate(subscription)
    : true;

  // Derived state
  const canUpdateStartDate =
    !subscription || subscription.status === 'scheduled';
  const minDate = subscription ? startOfTomorrow() : startOfToday();
  const minEndDate = addDays(start || minDate, 1);
  const endDateLabel = plan?.duration_cycles ? (
    <InputText
      label={t('common.duration')}
      disabled
      style={{ width: '200px' }}
      value={`${plan.duration_cycles} ${t(
        `data.plan.cycle_units.${plan.cycle_unit}_cycles`
      )}`}
    />
  ) : null;

  const validate = useValidateSubscriptionDateRange();

  const toggleRenewForever = () => {
    if (renewForever) {
      setRenewForever(false);
      setEnd(nextYear); // Set next year as default end date
    }
    if (!renewForever) {
      setRenewForever(true);
      setEnd(undefined);
    }
  };

  const handleDateChange = (dateId: string) => async (date: Date) => {
    let newStartDate = start;
    let newEndDate = end;
    let newBillingDate = billingDate;
    if (dateId === 'startDate') {
      setStart(date);
      newStartDate = date;
    } else if (dateId === 'endDate') {
      setEnd(date);
      newEndDate = date;
    } else if (dateId === 'billingDate') {
      setBillingDate(date);
      newBillingDate = date;
    }

    const { isValid, message } = await validate({
      subscriptionId: subscription?.id,
      startDate: canUpdateStartDate ? newStartDate : undefined,
      endDate: renewForever ? undefined : newEndDate,
      billingDate: _canChangeBillingDate ? newBillingDate : undefined,
      minDate,
    });

    setErrorMessage(message);
    onChange({
      isValid,
      startDate: formatDate(newStartDate),
      billingDate: formatDate(newBillingDate),
      ...(renewForever ? {} : { endDate: formatDate(newEndDate) }),
    });
  };

  return (
    <Box display="flex" flexDirection="column" flexWrap="wrap" gap="4px">
      <Box display="flex" gap="1rem" flexWrap="wrap" alignItems="center">
        <DateInput
          disabled={!canUpdateStartDate}
          id="startDate"
          label={t('screens.new_subscription.start')}
          onChange={handleDateChange('startDate')}
          value={start}
          datepickerProps={{ minDate }}
        />
        <DateInput
          id="billingDate"
          label={t('common.next_bill')}
          onChange={handleDateChange('billingDate')}
          value={billingDate}
          datepickerProps={{ minDate }}
          disabled={!subscription || !_canChangeBillingDate}
        />
        {endDateLabel || (
          <>
            <div style={{ marginTop: '1rem' }}>
              <Checkbox
                checked={renewForever}
                onChange={toggleRenewForever}
                id="renewForever"
                label={t('screens.new_subscription.renew')}
                size="small"
              />
            </div>
            <DateInput
              id="endDate"
              label={t('common.end_subscription')}
              onChange={handleDateChange('endDate')}
              value={end}
              disabled={renewForever}
              datepickerProps={{ minDate: minEndDate }}
            />
          </>
        )}
      </Box>
      {errorMessage ? (
        <ErrorMessage>{errorMessage}</ErrorMessage>
      ) : (
        <BillingDateInfo
          subscriptionId={subscription?.id}
          newBillingDate={billingDate}
        />
      )}
    </Box>
  );
}
