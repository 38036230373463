import { ReactNode } from 'react';
import {
  Grid,
  Paragraph,
  H2,
  InputFile,
  Link,
} from '@increasecard/typed-components';
import { useTranslation, Trans } from 'react-i18next';
import { ImportType } from '../../types/ImportFile';

const ALLOWED_FILES = ['.csv', 'text/csv'];

export interface ImportInputProps {
  isLoading: boolean;
  description?: string | ReactNode;
  importType: ImportType;
  title?: string;
  onChange: (file: File | null) => void;
  templateUrl?: string;
  helpCenterUrl?: string;
  selectedFile?: File | null;
}

export function ImportInput({
  isLoading,
  description,
  importType,
  title,
  onChange,
  templateUrl,
  helpCenterUrl,
  selectedFile,
}: ImportInputProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Grid>
      {description && (
        <>
          <Paragraph>{description}</Paragraph>
          <br />
        </>
      )}
      {title && <H2 style={{ marginBottom: '1rem' }}>{title}</H2>}
      {templateUrl ? (
        <Paragraph>
          <Trans
            t={t}
            i18nKey="screens.import.description"
            values={{ name: t(`common.${importType}s`) }}
            components={[<Link key="1" href={templateUrl} />]}
          />
        </Paragraph>
      ) : null}
      {helpCenterUrl ? (
        <Paragraph>
          <Trans
            t={t}
            i18nKey="screens.import.help_center_link"
            components={[<Link key="1" href={helpCenterUrl} target="_blank" />]}
          />
        </Paragraph>
      ) : null}
      <InputFile
        data-testid="import-input"
        className="span-desktop-4"
        innerLabel={t('common.examine')}
        noFilesText={t('common.search_import_file')}
        style={{ maxWidth: '335px', marginTop: '1.5rem' }}
        onChange={onChange}
        accept={ALLOWED_FILES}
        disabled={isLoading}
        selectedFileText={selectedFile?.name}
      />
    </Grid>
  );
}
