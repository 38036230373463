import {
  ButtonMenu as TCButtonMenu,
  ButtonMenuItem,
} from '@increasecard/typed-components';
import { NameValue } from '../../types/common';

export interface ButtonMenuProps {
  onSelect: (selected: string) => void;
  options: NameValue[];
  id: string;
  label: string;
}

export function ButtonMenu({
  label,
  onSelect,
  options,
  id,
}: ButtonMenuProps): JSX.Element {
  return (
    <TCButtonMenu text={label} id={id} type="button">
      {({ toggleShowMenu }) =>
        options.map(({ name, value }) => (
          <ButtonMenuItem
            key={value}
            onClick={() => {
              onSelect(value);
              toggleShowMenu();
            }}
          >
            {name}
          </ButtonMenuItem>
        ))
      }
    </TCButtonMenu>
  );
}
