import {
  H2,
  Table,
  TableHeader,
  TableWrapper,
} from '@increasecard/typed-components';
import { useTranslation } from 'react-i18next';
import { FrontPlan } from '../../../types/Plan';
import { getChargeDateMessage } from '../../../utils/planUtils';

export interface OtherDetailsProps {
  plan: FrontPlan;
}

export function OtherDetails({ plan }: OtherDetailsProps): JSX.Element {
  const { t } = useTranslation();

  const config = [
    {
      label: t('common.duration_cycles'),
      value: plan.duration_cycles || 'No tiene',
    },
    {
      label: t('screens.edit_plan.charge_time_label'),
      value: getChargeDateMessage(plan),
    },
    {
      label: t('screens.new_plan.proportional_charge_title'),
      value: t(
        `screens.new_plan.${plan.charge_proportional || 'free_proportional'}`
      ),
    },
    {
      label: t('data.plan.trial_period'),
      value: plan.trial_period_days
        ? t('screens.edit_plan.trial_period', {
            days: plan.trial_period_days,
          })
        : t('screens.edit_plan.no_trial_period'),
    },
    {
      label: t('screens.edit_plan.subscription_approval_type'),
      value: plan.automatic_subscription_request_approval
        ? t(`screens.edit_plan.automatic_approval`)
        : t(`screens.edit_plan.manual_approval`),
    },
    {
      label: t('data.plan.multiple_title'),
      value: plan.allow_multiple_subscriptions_per_customer ? 'si' : 'no',
    },
    {
      label: t('common.external_id'),
      value: plan.external_id || '-',
    },
    {
      label: t('common.success_page'),
      value: plan.success_url || '-',
    },
  ];

  return (
    <TableWrapper>
      <TableHeader>
        <H2>Otros detalles</H2>
      </TableHeader>
      <Table zebra>
        <tbody>
          {config.map((row) => {
            return (
              <tr key={row.label}>
                <td>{row.label}</td>
                <td>{row.value}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </TableWrapper>
  );
}
