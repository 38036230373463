import { MonthlySubscriptionsChart } from './MonthlySubscriptionsChart';
import { TotalAmountStackChart } from './TotalAmountStackChart';
import { BigNumbers } from './BigNumbers';
import { Grid, H1 } from '@increasecard/typed-components';
import { MetricsFilters } from './MetricsFilters';
import { ScreenSeparator } from '../../components/common/ScreenSeparator';
import { TransactionAmountChart } from './TransactionAmountChart';
import { RetryPerformanceChart } from './RetryPerformanceChart';
import { SubscriptionsByStatus } from './SubscriptionsByStatusChart';
import { TotalSubscriptionAmountChart } from './TotalSubscriptionAmountChart';

function SubscriptionSection() {
  return (
    <>
      <H1>Suscripciones</H1>
      <MonthlySubscriptionsChart />
      <SubscriptionsByStatus />
      <TotalSubscriptionAmountChart />
    </>
  );
}

function PaymentsSection() {
  return (
    <>
      <H1>Pagos</H1>
      <RetryPerformanceChart />
      <TransactionAmountChart />
      <TotalAmountStackChart />
    </>
  );
}

export function SummaryScreen(): JSX.Element {
  return (
    <Grid rowGap={3}>
      <MetricsFilters />
      <BigNumbers />
      <ScreenSeparator margin="2rem 0 0" />
      <SubscriptionSection />
      <ScreenSeparator margin="2rem 0 0" />
      <PaymentsSection />
    </Grid>
  );
}
