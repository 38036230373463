import {
  Button,
  Checkbox,
  H2,
  InlineText,
  typography,
} from '@increasecard/typed-components';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Column } from '../../../components/common/Column';
import { Paginator } from '../../../components/common/Paginator';
import { Row } from '../../../components/common/Row';
import { CheckoutInvoice } from '../../../types';
import { formatCurrency } from '../../../utils/currencyUtils';
import { formatISODate } from '../../../utils/dateUtils';
import { useCheckoutAccount } from '../AccountContext';
import { InvoiceStatusTag } from './DebtInvoiceTag';
import { usePublishedDebt } from './debtPortalQueries';
import { isInvoicePayable } from './helpers';
import { RoundedBoxRow } from './RoundedBoxRow';
import { useDebtPortalStore } from './useDebtPortalStore';

export interface DebtListProps {
  onSelectPage: (page: number) => void;
  page: number;
}

export const InvoiceItem = styled.div`
  width: 100%;
  display: grid;
  grid-template-areas:
    'date status check'
    'description amount check';
  grid-template-columns: auto auto min-content;
  gap: 8px;
  padding: 8px 0;
  align-items: center;
  ${typography('cell')}
  border-top: 1px solid var(--color-gray-grayMedium);
  .invoice-date {
    grid-area: date;
  }
  .invoice-amount {
    grid-area: amount;
    text-align: right;
  }
  .invoice-desc {
    grid-area: description;
  }
  .invoice-status {
    grid-area: status;
    justify-self: end;
  }
  .checkbox {
    grid-area: check;
    justify-self: end;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-areas: 'date description status amount check';
    grid-template-columns:
      auto minmax(70px, 1fr) auto minmax(150px, min-content)
      auto;
    min-height: 40px;
  }
`;

export function DebtList(): JSX.Element | null {
  const { t } = useTranslation();
  const { branding_settings } = useCheckoutAccount();
  const [page, setPage] = useState(1);
  const {
    searchValue,
    searchField,
    goToSearch,
    goToPayment,
    select,
    unselect,
    isSelected,
    selectedInvoices,
  } = useDebtPortalStore();
  const { data } = usePublishedDebt({
    [searchField]: searchValue,
    page,
  });

  const fieldLabel = branding_settings.searchable_fields.find(
    ({ field }) => field === searchField
  )?.label;

  const handleSelect = (
    e: ChangeEvent<HTMLInputElement>,
    invoice: CheckoutInvoice
  ) => {
    if (e.target.checked) {
      select(invoice);
    } else {
      unselect(invoice);
    }
  };

  if (!data) return null;

  return (
    <Column margin="0" gap="32px">
      <RoundedBoxRow>
        <InlineText>
          <strong>{fieldLabel}:</strong> {searchValue}
        </InlineText>
        <Button variant="invisible" onClick={() => goToSearch()}>
          Volver a consultar
        </Button>
      </RoundedBoxRow>
      <div>
        <H2>Facturas emitidas</H2>
        <Column>
          {data.data.map((invoice) => {
            return (
              <InvoiceItem key={invoice.id}>
                <span className="invoice-date">
                  {formatISODate(invoice.created_at)}
                </span>
                <span className="invoice-desc">
                  {invoice.items[0]?.description}
                </span>
                <InvoiceStatusTag
                  invoice={invoice}
                  className="invoice-status"
                />
                <span className="invoice-amount">
                  {formatCurrency(invoice.amount, invoice.currency)}
                </span>
                <Checkbox
                  id={invoice.id}
                  label=""
                  className="checkbox"
                  checked={isSelected(invoice)}
                  onChange={(e) => handleSelect(e, invoice)}
                  disabled={!isInvoicePayable(invoice)}
                />
              </InvoiceItem>
            );
          })}
        </Column>
        <Paginator
          selected={page}
          pages={data.total_pages}
          onSelectPage={setPage}
        />
      </div>
      <Row margin="0" justifyContent="end">
        <Button
          disabled={selectedInvoices.length === 0}
          onClick={() => goToPayment()}
        >
          {t('common.pay')}
        </Button>
      </Row>
    </Column>
  );
}
