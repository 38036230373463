import { H2, TableWrapper } from '@increasecard/typed-components';
import { useTranslation } from 'react-i18next';
import { Column } from '../../../components/common/Column';
import { TagList } from '../../../components/common/TagList';

export interface SubscriptionTagsProps {
  tags?: string[];
}
export function SubscriptionTags({
  tags,
}: SubscriptionTagsProps): JSX.Element | null {
  const { t } = useTranslation();
  if (!tags || tags.length === 0) return null;
  return (
    <Column as={TableWrapper} margin="0" gap="1rem">
      <H2>{t('common.tags')}</H2>
      <TagList tags={tags}></TagList>
    </Column>
  );
}
