import { useCheckoutBootUp } from './useCheckoutBootUp';
import { SubscriptionCheckoutScreen } from './SubscriptionCheckoutScreen';
import { CustomerCheckoutScreen } from './CustomerCheckoutScreen';
import { useCheckoutParams } from './useCheckoutParams';
import { AccountProvider } from './AccountContext';

export function CheckoutScreen(): JSX.Element {
  const { subscriptionId } = useCheckoutParams();
  useCheckoutBootUp();

  return (
    <AccountProvider>
      {subscriptionId ? (
        <SubscriptionCheckoutScreen />
      ) : (
        <CustomerCheckoutScreen />
      )}
    </AccountProvider>
  );
}
