import {
  NativeSelect,
  NativeSelectProps,
} from '@increasecard/typed-components';
import { useTranslation } from 'react-i18next';
import { useCheckoutAccount } from '../AccountContext';

export function SearchFieldSelector(
  props: Partial<NativeSelectProps>
): JSX.Element {
  const { branding_settings } = useCheckoutAccount();
  const { t } = useTranslation();

  return (
    <NativeSelect {...props} label="Campo de busqueda">
      <option key="first" value="">
        {t('common.choose_option')}
      </option>
      {branding_settings.searchable_fields.map((opt, i) => (
        <option key={i} value={opt.field}>
          {opt.label}
        </option>
      ))}
    </NativeSelect>
  );
}
