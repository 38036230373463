import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  CellText,
  FloatingMenu,
  Label,
  TagProps,
} from '@increasecard/typed-components';
import { Tag, tagTypeColors } from './common/Tag';
import { PaymentMethodLabel } from './paymentMethods/PaymentMethodLabel';
import { getPaymentStatus } from '../utils/paymentUtils';
import { Payment, PaymentStatus } from '../types/Payment';
import { formatISODate } from '../utils/dateUtils';
import { RoundedCard } from './common/RoundedCard';
import { callIfFunction } from '../utils/utils';
import { formatCurrency } from '../utils/currencyUtils';

const paymentColors = tagTypeColors.payment.status;

export const Attempt = styled.div<Pick<TagProps, 'colors'>>`
  z-index: 1;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: solid 1px transparent;
  margin: 0 3px;
  &:hover {
    border: solid 2px #182026;
  }
  border: 1px solid
    ${({ colors, theme }) =>
      callIfFunction(colors.border, theme) ||
      callIfFunction(colors.background, theme)};
  color: ${({ colors, theme }) =>
    callIfFunction(colors.foreground, theme) || theme.colorsNew.gray.white};
  background-color: ${({ colors, theme }) =>
    callIfFunction(colors.background, theme)};
`;

const DetailBox = styled(RoundedCard)`
  width: 263px;
  padding: 8px;
  box-shadow: ${({ theme }) => theme.shadows.elevateCast};
  gap: 8px;

  ${Label} {
    color: var(--color-gray-grayBold);
  }
`;

const DetailRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export interface PaymentAttemptProps {
  payment: Payment;
  onClick?: VoidFunction;
}

export function PaymentAttempt({
  payment,
  onClick,
}: PaymentAttemptProps): JSX.Element {
  const paymentStatus = getPaymentStatus(payment) as PaymentStatus;
  return (
    <FloatingMenu
      style={{ zIndex: 1 }}
      renderTrigger={(triggerProps, { toggleShowMenu }) => {
        return (
          <Attempt
            {...triggerProps}
            as={Link}
            to={`/payments/${payment.id}/detail`}
            colors={paymentColors[paymentStatus]}
            onMouseEnter={toggleShowMenu}
            onMouseLeave={toggleShowMenu}
            onClick={onClick}
          />
        );
      }}
    >
      {() => <AttemptDetail payment={payment} />}
    </FloatingMenu>
  );
}
// TODO: Check if we can use DetailsBox directly
function AttemptDetail({ payment }: PaymentAttemptProps) {
  const { t } = useTranslation();
  const paymentStatus = getPaymentStatus(payment);
  return (
    <DetailBox>
      <DetailRow>
        <Label>{t('common.status')}</Label>
        <Tag type={`payment.status.${paymentStatus}`}>
          {t(`data.payment.status.${paymentStatus}`)}
        </Tag>
      </DetailRow>
      <DetailRow>
        <Label>{t('common.last_update')}</Label>
        <CellText>{formatISODate(payment.created_at)}</CellText>
      </DetailRow>
      <DetailRow>
        <Label>{t('data.payment.paid_at')}</Label>
        <CellText>
          {payment.paid_at ? formatISODate(payment.paid_at) : '-'}
        </CellText>
      </DetailRow>
      <DetailRow>
        <Label>{t('data.payment.method')}</Label>
        <CellText>
          <PaymentMethodLabel paymentMethod={payment.payment_method} />
        </CellText>
      </DetailRow>
      <DetailRow>
        <Label>{t('common.amount')}</Label>
        <CellText>{formatCurrency(payment.amount, payment.currency)}</CellText>
      </DetailRow>
    </DetailBox>
  );
}
