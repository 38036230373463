import { CellText, H2, InputNumber } from '@increasecard/typed-components';
import { ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TieredPlanBreakdown } from '../../../components/common/TieredPlanBreakdown';
import { TiersTable } from '../../../components/common/TiersTable';
import { ApiErrorMessage } from '../../../components/ErrorMessage';
import {
  calculatePlanAmount,
  getPlanInputType,
} from '../../../utils/planUtils';
import { usePlanById } from '../checkoutQueries';
import { CheckoutData, CheckoutDataSetters } from '../useCheckout';
import { PlanPrice } from './PlanPrice';
import { PlanItemContainer, RoundedBox } from './planStyles';

export interface PlanDetailProps {
  productId: string;
  planId: string;
  checkoutData: CheckoutData;
  dataSetters: CheckoutDataSetters;
}

const AnimatedBox = styled(RoundedBox)`
  transition: all 500ms linear;
`;

export function PlanDetail({
  productId,
  planId,
  checkoutData,
  dataSetters,
}: PlanDetailProps): JSX.Element | null {
  const { data: plan, isLoading, error } = usePlanById(productId, planId);
  const { t } = useTranslation();
  const planNeedsQuantity = getPlanInputType(plan) === 'quantity';

  // TODO: The plan should be already available for everyone.
  // Or at least, make every component get the plan from the query
  useEffect(() => {
    if (!checkoutData.plan && plan) {
      dataSetters.setPlan(plan);
      if (planNeedsQuantity) {
        dataSetters.setPlanQuantity(1);
      }
    }
  }, [checkoutData.plan, dataSetters, plan, planNeedsQuantity]);

  const handleQuantityChange = (e: ChangeEvent<HTMLInputElement>) => {
    dataSetters.setPlanQuantity(e.target.value ? Number(e.target.value) : null);
  };

  // TODO: show skeleton while loading
  if (!plan || isLoading) return null;

  const { details } = calculatePlanAmount(plan, checkoutData.planQuantity || 0);

  if (error) return <ApiErrorMessage error={error} />;

  return (
    <AnimatedBox className="span-desktop-11">
      <PlanItemContainer>
        <H2 className="plan-name">{plan.name}</H2>
        <CellText className="cycle-unit">
          {t(`data.plan.cycle_units.${plan.cycle_unit}`)}
        </CellText>
        <PlanPrice plan={plan} />
      </PlanItemContainer>
      {planNeedsQuantity && (
        <InputNumber
          label={t('common.quantity_units')}
          id="quantity"
          value={checkoutData.planQuantity}
          onChange={handleQuantityChange}
          min={1}
        />
      )}
      <TieredPlanBreakdown details={details} currency={plan.currency} />
      <TiersTable tiers={plan.tiers} currency={plan.currency} />
    </AnimatedBox>
  );
}
