import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Constants } from '../../../constants';
import { getShortText, nativeUniqBy } from '../../../utils/utils';
import {
  getChargeDateMessage,
  getPlanDescription,
} from '../../../utils/planUtils';
import {
  DataTable,
  ColumnDefinition,
  RowDefinition,
} from '../../../components/common/DataTable/DataTable';
import { ApiErrorMessage } from '../../../components/ErrorMessage';
import { usePlans } from '../planQueries';
import { PlanFilters, usePlanFilters } from './PlanFilters';
import { QRGeneratorModal } from '../../../components/common/QRGeneratorModal';
import { FrontPlan } from '../../../types';
import { usePlanActions } from './usePlanActions';
import { PlanActionsMenu } from './PlanActionsMenu';
import { ChangePriceModal } from './ChangePriceModal';

export function PlansList(): JSX.Element {
  const { t } = useTranslation();
  const [selectedPlans, setSelected] = useState<FrontPlan[]>([]);
  const { actions, goToDetails, priceModal, qrModal, changeStatusError } =
    usePlanActions('plan screen');
  const [page, setPage] = useState(1);
  const [filters] = usePlanFilters();
  const { data, refetch, error, isLoading, isFetching } = usePlans({
    ...filters,
    page,
    per_page: Constants.MEDIUM_PAGE_SIZE,
  });

  const loadPage = setPage;

  const handleSelect = (rows: FrontPlan[], checked: boolean) => {
    if (checked) {
      setSelected(nativeUniqBy([...selectedPlans, ...rows], ({ id }) => id));
    } else {
      const newCoso = selectedPlans.filter(
        (rs: FrontPlan) => !rows.find((r) => r.id === rs.id)
      );
      setSelected(newCoso);
    }
  };

  const goToPlanDetails = (plan: RowDefinition) => {
    goToDetails(plan.id, 'list row click');
  };

  const getTableHeader = () => {
    let header = `Resultados: ${
      isFetching ? 'cargando' : data?.total_elements
    }`;
    if (selectedPlans.length > 0) {
      header += ` (${t('common.selected', {
        context: 'male',
        count: selectedPlans.length,
      })})`;
    }
    return header;
  };

  if (error) {
    return <ApiErrorMessage error={error} onClick={() => refetch()} />;
  }

  const hasData = data?.data ? data?.data.length > 0 : false;

  const tableColumns: ColumnDefinition[] = [
    {
      field: 'product.name',
      headerName: t('common.product'),
    },
    {
      field: 'name',
      headerName: t('common.name'),
      valueGetter: (plan: RowDefinition) => getShortText(plan.name),
    },
    {
      field: 'country',
      headerName: t('common.country'),
      valueGetter: (plan: RowDefinition) =>
        plan.country && t(`data.countries.${plan.country}`),
    },
    {
      field: 'plan',
      headerName: t('screens.new_plan.type'),
      valueGetter: (plan: RowDefinition) => getPlanDescription(plan),
    },
    {
      field: 'cycle',
      headerName: t('data.plan.cycle'),
      valueGetter: (plan: RowDefinition) =>
        t(`data.plan.cycle_units.${plan.cycle_unit}`) +
        (plan.start_day ? `, ${getChargeDateMessage(plan).toLowerCase()}` : ''),
    },
    {
      type: 'tag',
      field: 'status',
      headerName: t('common.status'),
      tagType: 'plan',
      tagLabel: (plan: RowDefinition) => t(`common.${plan.status}`),
    },
  ];

  return (
    <>
      <PlanFilters />
      <ApiErrorMessage error={changeStatusError} />
      <QRGeneratorModal
        visible={qrModal.isOpen}
        onClose={qrModal.close}
        value={qrModal.data?.checkout_link || ''}
        title={qrModal.data?.name || ''}
      />
      <ChangePriceModal
        visible={priceModal.isOpen}
        plans={priceModal.data}
        onClose={priceModal.close}
        onExited={() => priceModal.setData(null)}
      />
      <DataTable
        tableHeader={getTableHeader()}
        tableHeaderButtons={
          <PlanActionsMenu
            text="Acciones masivas"
            disabled={!hasData}
            plans={selectedPlans}
            onCleanUp={() => setSelected([])}
          />
        }
        tableProps={{ zebra: true, hasData }}
        rowHeight="double"
        columns={tableColumns}
        pageSize={Constants.LARGE_PAGE_SIZE}
        isLoading={!hasData && isLoading}
        rows={data?.data}
        menuActions={actions}
        onSelect={handleSelect}
        selectedRows={selectedPlans}
        paginatorProps={{
          pages: data?.total_pages,
          selected: Number(data?.current_page),
          onSelectPage: loadPage,
        }}
        onRowClick={goToPlanDetails}
      />
    </>
  );
}
