import {
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { Constants } from '../../constants';
import {
  fetchImportedFiles,
  FetchImportParams,
  uploadCsvFile,
  UploadCsvFileParams,
} from '../../services/ImportService';
import { PaginatedData } from '../../types/common';
import { ImportedFile } from '../../types/ImportFile';
import { NewApiError } from '../../types/NewApiError';
import { useMutationWithInvalidation } from '../../utils/queryUtils';

export function useImportedFiles<T = PaginatedData<ImportedFile>>(
  params: FetchImportParams,
  options?: UseQueryOptions<
    PaginatedData<ImportedFile>,
    NewApiError,
    T,
    Metadata[] // This is the query key type
  >
): UseQueryResult<T, NewApiError> {
  const defaultParams = {
    per_page: Constants.SMALL_LIST_PAGE_SIZE,
    ...params,
  };

  return useQuery(
    ['importedFiles', defaultParams],
    () => fetchImportedFiles(defaultParams),
    {
      ...options,
      keepPreviousData: true,
    }
  );
}

export const useUploadCsvFile = (): UseMutationResult<
  ImportedFile,
  NewApiError,
  UploadCsvFileParams
> => {
  return useMutationWithInvalidation(uploadCsvFile, ['importedFiles']);
};
