import { ComafiSchema } from '../CollectMethodsSchema';
import { TFunction } from 'i18next';
import { getDefaultValues, PanelFormConfig } from './PanelConfig';
import { ComafiCollectMethod } from '../../../types/CollectMethod';

export function comafiPanelConfig(
  translate: TFunction,
  collectMethod?: ComafiCollectMethod
): PanelFormConfig {
  const formData = {
    country: 'ARG',
    name: 'Banco Comafi',
    metadata: {
      cuit: '',
      beneficiary: '',
      account_number: '',
      id_size: '',
      company_code: '',
    },
  };

  const initialValues = getDefaultValues(formData, collectMethod);
  const transBase = 'screens.collect_methods.comafi';
  return {
    type: 'comafi',
    name: 'Banco Comafi',
    description: translate(`${transBase}.description`),
    initialValues,
    status: collectMethod?.status,
    inputs: [
      {
        label: translate(`${transBase}.cuit`),
        id: 'metadata.cuit',
        type: 'text',
      },
      {
        label: translate(`${transBase}.beneficiary`),
        id: 'metadata.beneficiary',
        type: 'text',
      },
      {
        label: translate(`${transBase}.account_number`),
        id: 'metadata.account_number',
        type: 'text',
      },
      {
        label: translate(`${transBase}.id_size`),
        id: 'metadata.id_size',
        type: 'text',
      },
      {
        label: translate(`${transBase}.company_code`),
        id: 'metadata.company_code',
        type: 'text',
      },
    ],
    validationSchema: () => ComafiSchema(translate),
  };
}
