import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SubscriptionForm, SubscriptionFormValues } from './SubscriptionForm';
import { Header } from '../../components/common/Header';
import {
  UpdateSubscriptionParams,
  useSubscriptionById,
  useUpdateSubscription,
} from './subscriptionQueries';
import { useModal } from '../../hooks/useModal';
import { splitExtrasByType } from '../../utils/extrasUtils';
import { SubscriptionSuccessModal } from './SubscriptionSuccessModal';
import { FullPageSpinner } from '../../components/common/FullPageSpinner';
import { GoBackButton } from '../../components/common/GoBackButton';
import { ScreenSeparator } from '../../components/common/ScreenSeparator';

export function EditSubscriptionScreen(): JSX.Element {
  const { t } = useTranslation();
  const successModal = useModal();
  const history = useHistory();
  const { subscription_id } = useParams<{ subscription_id: string }>();
  const { isLoading: subscriptionIsLoading, data: subscriptionToUpdate } =
    useSubscriptionById(subscription_id);
  const subMutation = useUpdateSubscription();

  const handleSubmit = (values: SubscriptionFormValues) => {
    const {
      subscriptionExtras,
      payment_method_id,
      quantity,
      amount,
      tags,
      start_date,
      end_date,
      next_billing_date,
      external_id,
    } = values;
    if (subscriptionToUpdate) {
      const extrasByType = splitExtrasByType(subscriptionExtras);
      // Sent current state of extras. API resolves the rest.
      const changes: UpdateSubscriptionParams = {
        id: subscriptionToUpdate.id,
        ...extrasByType,
      };
      // In wich case can we update this field?.
      // We dont have

      if (subscriptionToUpdate.quantity !== quantity) {
        changes.quantity = quantity;
      }

      if (Number(subscriptionToUpdate.amount) !== amount) {
        changes.amount = amount;
      }
      if (subscriptionToUpdate.tags.length !== tags.length) {
        changes.tags = tags;
      }

      if (start_date && subscriptionToUpdate.activate_at !== start_date) {
        changes.start_date = start_date;
      }

      if (end_date && subscriptionToUpdate.cancel_at !== end_date) {
        changes.end_date = end_date;
      }

      if (
        next_billing_date &&
        subscriptionToUpdate.next_billing_date !== next_billing_date
      ) {
        changes.next_billing_date = next_billing_date;
      }

      if (subscriptionToUpdate.external_id !== external_id) {
        changes.external_id = external_id;
      }

      if (
        payment_method_id &&
        subscriptionToUpdate.payment_method?.id !== payment_method_id
      ) {
        changes.payment_method_id = payment_method_id;
      }

      subMutation.mutate(changes, {
        onSuccess: successModal.open,
      });
    }
  };

  const goToSubscriptionsList = () => {
    history.replace('detail');
  };

  return (
    <>
      <GoBackButton />
      <Header title={t('screens.new_subscription.update')} />
      <ScreenSeparator margin="0rem 0 2rem 0" />
      {subscriptionIsLoading ? (
        <FullPageSpinner height="50vh" size={32} />
      ) : (
        <SubscriptionForm
          subscription={subscriptionToUpdate}
          isEditing={true}
          onSubmit={handleSubmit}
          onCancel={goToSubscriptionsList}
          error={subMutation.error}
          isLoading={subMutation.isLoading}
        />
      )}
      <SubscriptionSuccessModal
        onClose={goToSubscriptionsList}
        visible={successModal.isOpen}
        subscription={successModal.data}
        isEditing={true}
      />
    </>
  );
}
