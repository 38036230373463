import { TFunction } from 'i18next';
import { MercadopagoConnectCollectMethod } from '../../../types/CollectMethod';
import { getDefaultValues, PanelFormConfig } from './PanelConfig';

export function MPPanelConfig(
  translate: TFunction,
  collectMethod?: MercadopagoConnectCollectMethod
): PanelFormConfig {
  const initialValues = getDefaultValues(
    {
      metadata: {
        offline_payments_enabled: false,
        expiration_days: 30,
      },
    },
    collectMethod
  );
  // Dummy data to comply with PanelFormConfig type shape.
  // Until we make sense of this panel/panelConfig relationship.
  return {
    type: 'mercadopago_connect',
    description: '',
    name: 'Mercadopago',
    inputs: [],
    status: collectMethod?.status,
    initialValues,
    validationSchema: undefined,
  };
}
