import { useEffect, useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Spinner, H1, H2, Button, Grid } from '@increasecard/typed-components';
import { InputWithDescription } from '../../components/common/InputWithDescription';
import { ApiErrorMessage } from '../../components/ErrorMessage';
import { getTotal, getSubtotal, extrasToInvoiceItems } from './invoiceUtils';
import { hasCollectMethods } from '../../utils/collectMethodsUtils';
import { getCountryConfig } from '../../utils/countryUtils';
import { ScreenSeparator } from '../../components/common/ScreenSeparator';
import { NewInvoiceItems, NewInvoiceItem } from './NewInvoiceItems';
import { ExtrasAggregator } from '../extras/ExtrasAggregator';
import { InvoiceProcessModal } from './components/InvoiceProcessModal';
import { PaymentMethodPathSelector } from '../../components/paymentMethods/PaymentMethodPathSelector';
import { Row } from '../../components/common/Row';
import { CustomerSelector } from '../customers/CustomerSelector';
import { useValidation } from '../../hooks/useValidation';
import { CountryAndCurrencySelector } from '../../components/common/CountryAndCurrencySelector';
import { CollectMethodSelector } from '../../components/common/CollectMethodSelector';
import { Currency, Extra } from '../../types/common';
import { GroupedCollectMethods } from '../../types/CollectMethod';
import { InvoiceSchema } from './InvoiceSchema';
import { useCustomerById } from '../../globalQueries/Queries';
import { Customer } from '../../types/Customer';
import { useCreateInvoice } from './invoiceQueries';
import { useDefaultCollectMethods } from '../../hooks/useCollectMethodsOptions';

export interface InvoiceFormProps {
  customerId: string;
  onCancel?: VoidFunction;
}

export function InvoiceForm({
  customerId,
  onCancel,
}: InvoiceFormProps): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const [referenceId, setReferenceId] = useState('');
  const [items, setItems] = useState<NewInvoiceItem[]>([]);
  const [paymentMethodId, setPaymentMethodId] = useState<string>();
  const [extras, setExtras] = useState<Extra[]>([]);
  const [successUrl, setSuccessUrl] = useState<string>();
  const [collectMethods, setCollectMethods] = useState<GroupedCollectMethods>();
  const [currency, setCurrency] = useState<Currency>();
  const invoiceSchema = InvoiceSchema(t);
  const customer = useCustomerById(customerId);
  const defaultCollectMethods = useDefaultCollectMethods();
  const { mutate, reset, isLoading, error, data, isSuccess } =
    useCreateInvoice();

  // TODO: use the InvoiceSchema to validate the whole form
  const successUrlValidation = useValidation({
    value: successUrl,
    schema: 'url',
  });

  const isSubmitDisabled =
    !customer.data ||
    isLoading ||
    !successUrlValidation.isValid ||
    !hasCollectMethods(collectMethods);
  const country = customer?.data?.billing_info?.country || 'ARG'; // TODO: Remove defaults when billing info Pay-854 gets deployed
  const { language } = getCountryConfig(country);
  const subtotal = getSubtotal(items);

  useEffect(() => {
    const customerCurrency = customer.data?.billing_info?.currency;
    setCurrency(customerCurrency);
    setCollectMethods(defaultCollectMethods.data);
  }, [customer.data?.billing_info?.currency, defaultCollectMethods.data]);

  const handleSubmit = () => {
    // This guard is needed because we don't have a way of tell typescript
    // that currency and customer will not be undefined when submmit ocurrs.
    if (!currency || !customer.data) return;

    // We can't create an invoice with extras, so we convert them to invoice items
    const extrasItems = extrasToInvoiceItems(extras, subtotal, currency);
    const invoice = {
      payment_method_id: paymentMethodId,
      customer_id: customer.data?.id,
      amount: getTotal(items, extras),
      currency: currency,
      external_reference: referenceId,
      items: [...items, ...extrasItems],
      success_url: successUrl,
      collect_methods: collectMethods,
    };

    if (invoiceSchema.isValidSync(invoice)) {
      mutate(invoice);
    }
  };

  const handleCustomerSelect = (customer: Customer) => {
    history.push(`/invoices/new?customer_id=${customer.id}`);
  };
  const handleCancel = () => (onCancel ? onCancel() : history.goBack());

  const goToList = () => history.push('/invoices');

  const handleCurrencyChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setCurrency(e.target.value as Currency);
    setCollectMethods(undefined);
  };
  const handleCollectMethodChange = (
    _key: string,
    value: GroupedCollectMethods
  ) => {
    setCollectMethods(value);
  };
  const handlePathSelectorChange = (value: string | undefined) => {
    setPaymentMethodId(value);
  };

  return (
    <>
      <Grid rowGap={2}>
        {customer.isLoading || defaultCollectMethods.isLoading ? (
          <Spinner size={16} />
        ) : (
          <>
            <CustomerSelector
              initialCustomerId={customerId}
              onChange={handleCustomerSelect}
            />
            {customer.data && currency && (
              <>
                <H1 style={{ marginTop: '20px' }}>
                  {t('screens.new_invoice.detail')}
                </H1>
                <NewInvoiceItems
                  onChange={setItems}
                  currency={currency}
                  language={language}
                />
                <ExtrasAggregator
                  align="end"
                  subtotal={subtotal}
                  items={extras}
                  onListChange={setExtras}
                  country={country}
                  currency={currency}
                />
                <CountryAndCurrencySelector
                  onChange={handleCurrencyChange}
                  mode="currencyOnly"
                  country={country}
                  currency={currency}
                  countrySelectClass="span-desktop-3 grid-start-desktop-1"
                  currencySelectClass="span-desktop-3 grid-start-desktop-5"
                />
                <CollectMethodSelector
                  country={country}
                  currency={currency}
                  onChange={handleCollectMethodChange}
                  collectMethods={collectMethods}
                />
                <H2 style={{ marginTop: '2rem' }}>
                  {t('common.payment_method')}
                </H2>
                <PaymentMethodPathSelector
                  customerId={customerId}
                  onChange={handlePathSelectorChange}
                  groupedCollectMethods={collectMethods}
                  translationsId="screens.new_invoice"
                  paymentReference={{
                    reference_id: customerId,
                    reference_type: 'customer',
                  }}
                />
                <ScreenSeparator margin="40px 0 0 0" />
                <InputWithDescription
                  title={t('common.external_id')}
                  value={referenceId}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setReferenceId(e.target.value)
                  }
                  description={t('screens.new_invoice.reference_id_desc')}
                />
                <ScreenSeparator margin="40px 0 0 0" />
                <InputWithDescription
                  id="success_url"
                  title={t('common.success_page')}
                  value={successUrl}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setSuccessUrl(e.target.value)
                  }
                  placeholder={t('common.enter_url')}
                  description={t('screens.new_invoice.success_url_desc')}
                  errorMessage={successUrlValidation.errorMessage}
                />
              </>
            )}
          </>
        )}
        <ApiErrorMessage error={error} />
        <Row marginTop="2rem" flexGap="1rem">
          <Button
            buttonType="primary"
            disabled={isSubmitDisabled}
            onClick={handleSubmit}
          >
            {t(
              `screens.new_invoice.${paymentMethodId ? 'charge_now' : 'create'}`
            )}
          </Button>
          <Button key="cancel" onClick={handleCancel} buttonType="invisible">
            {t('common.cancel')}
          </Button>
        </Row>
      </Grid>
      <InvoiceProcessModal
        onOk={goToList}
        onClose={reset}
        visible={isLoading || isSuccess}
        invoice={data}
        isProcessing={isLoading}
        error={error}
      />
    </>
  );
}
