import { Button } from '@increasecard/typed-components';
import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Constants } from '../../constants';
import { useModal } from '../../hooks/useModal';
import { ImportType } from '../../types/ImportFile';
import {
  ColumnDefinition,
  DataTable,
  RowDefinition,
} from '../common/DataTable/DataTable';
import { ImportErrorsModal } from './ImportErrorsModal';
import { useImportedFiles } from './importQueries';

export interface ImportsTableProps {
  type: ImportType;
}

export function ImportsTable({ type }: ImportsTableProps): JSX.Element {
  const [page, setPage] = useState(1);
  const errorModal = useModal();
  const importedFiles = useImportedFiles({ page, type });
  const { t } = useTranslation();

  const tableColumns: ColumnDefinition[] = [
    {
      field: 'name',
      headerName: t('common.file_name'),
    },
    {
      field: 'created_at',
      type: 'date',
      headerName: t('common.created_at'),
    },
    {
      field: 'total_lines',
      rightAligned: true,
      headerName: 'Lineas totales',
      valueGetter: (row: RowDefinition) => row.total_lines - 1,
    },
    {
      field: 'processed_lines',
      rightAligned: true,
      headerName: 'Lineas procesadas',
      valueGetter: (row: RowDefinition) => row.processed_lines - 1,
    },
    {
      field: 'processing_errors',
      headerName: 'Lineas con error',
      rightAligned: true,
      valueGetter: (row: RowDefinition) =>
        row.processing_errors?.line_errors?.length,
    },
    {
      field: 'processing_errors',
      headerName: 'Lineas con éxito',
      rightAligned: true,
      valueGetter: (row: RowDefinition) => {
        const errorCount = row.processing_errors?.line_errors?.length || 0;
        return row.processed_lines - errorCount - 1;
      },
    },
    {
      field: 'status',
      type: 'tag',
      headerName: t('common.status'),
      tagType: 'bulkFile',
      tagLabel: (row: RowDefinition) =>
        t(`screens.import.statuses.${row.status}`),
    },
    {
      field: 'action',
      headerName: '',
      // eslint-disable-next-line react/display-name
      valueGetter: (row: RowDefinition) => {
        return (
          !isEmpty(row.processing_errors) && (
            <Button
              buttonType="outline"
              size="small"
              onClick={() => errorModal.open(row.processing_errors)}
            >
              {t('screens.import.see_errors')}
            </Button>
          )
        );
      },
    },
  ];

  const hasData = importedFiles.data?.data
    ? importedFiles.data?.data.length > 0
    : false;

  return (
    <div style={{ marginTop: '2rem' }}>
      <DataTable
        tableHeader="Archivos subidos"
        tableProps={{ zebra: true, hasData }}
        columns={tableColumns}
        pageSize={Constants.SMALL_LIST_PAGE_SIZE}
        isLoading={importedFiles.isLoading}
        rows={importedFiles.data?.data || null}
        paginatorProps={{
          pages: importedFiles.data?.total_pages,
          selected: Number(importedFiles.data?.current_page),
          onSelectPage: setPage,
        }}
      />
      <ImportErrorsModal
        errors={errorModal.data}
        onClose={errorModal.close}
        visible={errorModal.isOpen}
      />
    </div>
  );
}
