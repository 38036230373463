import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@increasecard/typed-components';
import { PlusSignIcon } from '@increasecard/icons';
import { CustomerPaymentMethods } from './CustomerPaymentMethods';
import { GroupedCollectMethods } from '../../types/CollectMethod';
import { PaymentMethod, PaymentReference } from '../../types/PaymentMethod';
import { ErrorMessage } from '../ErrorMessage';
import { hasCollectMethods } from '../../utils/collectMethodsUtils';
import { PathSwitcher } from '../common/PathSwitcher';
import { Column } from '../common/Column';
import { PaymentMethodCreator } from './PaymentMethodCreator';

export interface PaymentMethodPathSelectorProps {
  customerId: string;
  onChange: (paymentMethod: string | undefined) => void;
  translationsId: string;
  selectedPaymentMethodId?: string;
  groupedCollectMethods: GroupedCollectMethods | undefined;
  paymentReference: PaymentReference;
}

export function PaymentMethodPathSelector({
  customerId,
  onChange,
  translationsId,
  selectedPaymentMethodId,
  groupedCollectMethods,
  paymentReference,
}: PaymentMethodPathSelectorProps): JSX.Element | null {
  const { t } = useTranslation();

  const [sendPaymentLink, setSendPaymentLink] = useState<boolean>(
    !selectedPaymentMethodId
  );
  const [showPaymentMethod, setShowPaymentMethod] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    selectedPaymentMethodId
  );

  const onPaymentMethodSelected = (paymentMethod: PaymentMethod | null) => {
    setSelectedPaymentMethod(paymentMethod?.id);
    onChange(paymentMethod?.id);
  };

  const handleSendPaymentLink = (newState: boolean) => {
    setSendPaymentLink(newState);
    if (newState === true) {
      onPaymentMethodSelected(null);
    }
  };

  const toggleShowPaymentForm = (show: boolean) => {
    setShowPaymentMethod(show);
  };

  return (
    <Column margin="0" gap="1rem">
      <PathSwitcher
        optionATitle={t(`${translationsId}.use_payment_method_title`)}
        optionBTitle={t(`${translationsId}.send_payment_method_title`)}
        optionADescription={t(`${translationsId}.use_payment_method_desc`)}
        optionBDescription={t(`${translationsId}.send_payment_method_desc`)}
        value={sendPaymentLink}
        onChange={handleSendPaymentLink}
      />
      {sendPaymentLink === false ? (
        <>
          {hasCollectMethods(groupedCollectMethods) ? (
            <>
              <CustomerPaymentMethods
                customerId={customerId}
                onChange={onPaymentMethodSelected}
                selectedPaymentMethodId={selectedPaymentMethod}
                groupedCollectMethods={groupedCollectMethods}
              />
              {showPaymentMethod ? (
                <PaymentMethodCreator
                  onCancel={() => toggleShowPaymentForm(false)}
                  onSuccess={onPaymentMethodSelected}
                  customerId={customerId}
                  groupedCollectMethods={groupedCollectMethods}
                  paymentReference={paymentReference}
                />
              ) : (
                <Button
                  className="grid-span-desktop-12"
                  onClick={() => toggleShowPaymentForm(true)}
                  buttonType="invisible"
                  icon={<PlusSignIcon />}
                  size="small"
                  data-testid="add-payment-method"
                >
                  {t(`common.add_payment_method`)}
                </Button>
              )}
            </>
          ) : (
            <ErrorMessage>
              {t(`${translationsId}.select_collect_method`)}
            </ErrorMessage>
          )}
        </>
      ) : null}
    </Column>
  );
}
