import {
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { createPlan } from '../../services/PlanService';
import {
  createProduct,
  fetchProducts,
  updateProduct,
} from '../../services/ProductService';
import { PaginatedData } from '../../types/common';
import { NewApiError } from '../../types/NewApiError';
import { FrontPlan, PlanCreationParams } from '../../types/Plan';
import {
  Product,
  ProductCreationParams,
  ProductQueryParams,
  ProductUpdateParams,
} from '../../types/Product';
import {
  queryKeyFactory,
  useMutationWithInvalidation,
} from '../../utils/queryUtils';

export const productQueryKeys = queryKeyFactory('product');

export const useProducts = <T = PaginatedData<Product>>(
  params: ProductQueryParams,
  options?: Pick<
    UseQueryOptions<PaginatedData<Product>, NewApiError, T>,
    'select'
  >
): UseQueryResult<T, NewApiError> => {
  return useQuery({
    queryKey: productQueryKeys.list(params),
    queryFn: () => fetchProducts(params),
    enabled: !!params.page,
    keepPreviousData: true,
    select: options?.select,
  });
};

export interface ProductPlanCreationParmas extends ProductCreationParams {
  plan: PlanCreationParams;
}

export const useCreateProductAndPlan = (): UseMutationResult<
  FrontPlan,
  NewApiError,
  ProductPlanCreationParmas
> => {
  return useMutationWithInvalidation(
    async ({ name, plan }: ProductPlanCreationParmas) => {
      const { id } = await createProduct({ name });
      const newPlan = await createPlan({ ...plan, product_id: id });
      return newPlan;
    },
    productQueryKeys.lists()
  );
};

export const useUpdateProduct = (): UseMutationResult<
  Product,
  NewApiError,
  ProductUpdateParams
> => {
  return useMutationWithInvalidation(updateProduct, productQueryKeys.lists());
};
