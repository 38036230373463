import {
  AdditionalCost,
  Currency,
  Extra,
  Discount,
  OneTimeCost,
  Tax,
  InvoiceItemParams,
} from '../../types';

import {
  applyExtra,
  applyExtras,
  splitExtrasByType,
} from '../../utils/extrasUtils';

type Amount = { amount: string };

export function getSubtotal(items: Amount[]): number {
  return items.reduce((acc, item) => acc + parseFloat(item.amount), 0);
}

export function getTotal(items: Amount[], extras: Extra[]): string {
  const subtotal = getSubtotal(items);
  const { total: invoiceTotal } = applyExtras(extras, subtotal);
  return (invoiceTotal < 0 ? 0 : invoiceTotal).toFixed(2);
}

export function extrasToInvoiceItems(
  extras: Extra[],
  substotal: number,
  defaultCurrency?: Currency
): InvoiceItemParams[] {
  const { discounts, taxes, one_time_costs, additional_costs } =
    splitExtrasByType(extras);
  let base = substotal;

  const getInvoiceItem = (
    description: string,
    currency: Currency,
    amount: number
  ) => ({
    description,
    currency: currency || defaultCurrency,
    quantity: '1',
    unit_price: amount.toString(),
    amount: amount.toString(),
  });

  return [
    ...discounts.map((discount: Discount) => {
      const itemAmount = applyExtra(discount, base);
      base = base - itemAmount;
      // FIXME(dbelay)[PAY-1584]: use negative amount for discounts
      return getInvoiceItem(discount.name, discount.currency, -itemAmount);
    }),
    ...one_time_costs.map((otc: OneTimeCost) => {
      const itemAmount = applyExtra(otc, base);
      base = base + itemAmount;
      return getInvoiceItem(otc.name, otc.currency, itemAmount);
    }),
    ...additional_costs.map((ac: AdditionalCost) => {
      const itemAmount = applyExtra(ac, base);
      base = base + itemAmount;
      return getInvoiceItem(ac.name, ac.currency, itemAmount);
    }),
    ...taxes.map((tax: Tax) => {
      const itemAmount = applyExtra(tax, base);
      base = base - itemAmount;
      return getInvoiceItem(tax.name, tax.currency, itemAmount);
    }),
  ];
}
