/* eslint-disable react/prop-types */
import { ChangeEvent } from 'react';
import { useField } from 'formik';
import {
  InputText,
  InputNumber,
  InputPassword,
  InputTextProps,
  InputBaseProps,
  InputNumberProps,
  CheckboxProps,
  Checkbox,
} from '@increasecard/typed-components';
import { Select, SelectProps } from './SystemSelect';
import NumericFormat, { NumberFormatValues } from 'react-number-format';
export interface InputTextFieldProps extends InputTextProps {
  isNumeric?: boolean;
}

export function InputTextField({
  isNumeric,
  ...props
}: InputTextFieldProps): JSX.Element {
  const [field, meta] = useField(props.id);
  const handleNumericChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!/[\D,]/.test(e.target.value)) {
      field.onChange(e);
    }
  };
  return (
    <InputText
      {...field}
      onChange={isNumeric ? handleNumericChange : field.onChange}
      {...props}
      errorMessage={meta.touched && meta.error}
    />
  );
}

export function InputPercentageField(props: InputTextFieldProps): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ onChange, ...field }, meta, helpers] = useField(props.id);
  return (
    <NumericFormat
      {...field}
      {...props}
      allowNegative={false}
      thousandSeparator="."
      decimalSeparator=","
      allowedDecimalSeparators={[',', '.']}
      customInput={InputText}
      id="amount"
      value={Number(field.value) * 100}
      onValueChange={({ floatValue }: NumberFormatValues) => {
        const value =
          typeof floatValue === 'number'
            ? (floatValue / 100).toFixed(4)
            : floatValue;
        helpers.setValue(value);
      }}
      errorMessage={meta.touched && meta.error}
    />
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function InputNumberField(props: InputNumberProps): JSX.Element {
  const [field, meta] = useField(props.id);

  return (
    <InputNumber
      autoComplete="off"
      min="0"
      step="0.01"
      {...field}
      {...props}
      errorMessage={meta.touched && meta.error}
    />
  );
}

export function InputPasswordField(props: InputBaseProps): JSX.Element {
  const [field, meta] = useField(props.id);

  return (
    <InputPassword
      autoComplete="off"
      {...field}
      {...props}
      errorMessage={meta.touched && meta.error}
    />
  );
}
// makes id not optional
export function SelectField(props: SelectProps & { id: string }): JSX.Element {
  const [field, meta] = useField(props.id);
  return (
    <Select
      autoComplete="off"
      {...field}
      errorMessage={meta.touched ? meta.error : ''}
      {...props}
    />
  );
}

export function CheckboxField(
  props: CheckboxProps & { id: string }
): JSX.Element {
  const [field] = useField({ name: props.id, type: 'checkbox' });

  return <Checkbox {...field} {...props} />;
}
