import styled from 'styled-components';
import { ReactNode } from 'react';
import { Tag } from '@increasecard/typed-components';
import { FilterMenu } from './FilterMenu';
import { FiltersData } from './types';
import { useTranslation } from 'react-i18next';

export interface FilterTagsProps {
  onClose: () => void;
  children: ReactNode;
  filterState: FiltersData;
  onChange: (filters: Metadata) => void;
  field: keyof FiltersData;
}

const defaultColors = {
  foreground: 'var(--color-primaryAction-regular)',
  border: 'var(--color-gray-subtleBorder)',
  background: 'var(--color-gray-grayLight)',
};

const activeColors = {
  foreground: 'var(--color-gray-white)',
  border: 'var(--color-primaryAction-dark)',
  background: 'var(--color-primaryAction-dark)',
};

const ClickableTag = styled(Tag)`
  cursor: pointer;
`;

export function FilterTag({
  onClose,
  children,
  field,
}: FilterTagsProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <FilterMenu
      field={field}
      renderTrigger={(props, { toggleShowMenu, showMenu }) => (
        <ClickableTag
          role="button"
          {...props}
          colors={showMenu ? activeColors : defaultColors}
          onClose={onClose}
          onClick={toggleShowMenu}
        >
          <span
            style={{
              color: showMenu
                ? 'currentColor'
                : 'var(--color-gray-blackMedium)',
            }}
          >
            {t(`data.filters.${field}`)}
          </span>
          : {children}
        </ClickableTag>
      )}
    />
  );
}
