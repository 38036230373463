import { useEffect, useMemo } from 'react';
import { useOnTopNotification } from '@increasecard/increase-core';
import { Link } from '@increasecard/typed-components';
import { environment, productionUrl } from './config';
import { useSelfOnboarding } from './features/selfOnboarding/useSelfOnboarding';
import { OnTopNotification } from '@increasecard/increase-core/dist/contexts/OnTopNotificationContext';

const StagingNotification: OnTopNotification = {
  children: (
    <>
      Este es un entorno de pruebas. Los cobros no serán procesados.{' '}
      <Link href={productionUrl}>Ir a producción</Link>
    </>
  ),
  variant: 'alert',
};

export function Notifications(): JSX.Element | null {
  const { setOnTopNotification } = useOnTopNotification();
  const {
    showSandboxNotification,
    SandboxNotification,
    SandboxNotificationHack,
  } = useSelfOnboarding();

  const notificationsByEnv = useMemo(
    () => ({
      staging: StagingNotification,
      playground: StagingNotification,
      sandbox: showSandboxNotification ? SandboxNotification : undefined,
      production: undefined,
    }),
    [SandboxNotification, showSandboxNotification]
  );

  useEffect(() => {
    const notification = notificationsByEnv[environment];
    if (notification === undefined) return;

    setOnTopNotification(notification);
  }, [notificationsByEnv, setOnTopNotification]);

  return showSandboxNotification ? <SandboxNotificationHack /> : null;
}
