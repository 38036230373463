import { Paragraph } from '@increasecard/typed-components';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useInterval } from '../../../hooks/useInterval';
import { redirectTo } from '../../../utils/utils';

export interface RedirectTimerProps {
  redirectUrl: string;
  timeBeforeRedirectInSec?: number;
}

export function RedirectTimer({
  redirectUrl,
  timeBeforeRedirectInSec = 5,
}: RedirectTimerProps): JSX.Element {
  const { t } = useTranslation();
  const [counter, setCounter] = useState(timeBeforeRedirectInSec);
  useInterval(() => setCounter(counter - 1), counter === 0 ? null : 1000);

  useEffect(() => {
    if (counter === 0) {
      redirectTo(redirectUrl);
    }
  }, [counter, redirectUrl, timeBeforeRedirectInSec]);

  return (
    <Paragraph>
      <Trans
        t={t}
        i18nKey="screens.customer_checkout.redirect_message"
        values={{
          counter,
        }}
      />
    </Paragraph>
  );
}
