import { DateRangeInput } from '@increasecard/typed-components';
import { useMetricsFilters } from './useMetricsFilters';
import { Currency, NameValue } from '../../types/common';
import { ApiFilterSelector } from '../../components/FilterSelectors/ApiFilterSelector';
import { Row } from '../../components/common/Row';

export function MetricsFilters(): JSX.Element {
  const [filters, setFilters] = useMetricsFilters();

  const handleCurrencyChange = (currency: NameValue) => {
    setFilters({ currency: currency.value as Currency });
  };

  const handleDateChange = (dates: Date[]) => {
    setFilters({ createdAtFrom: dates[0], createdAtTo: dates[1] });
  };

  return (
    <Row gap="16px" margin="0">
      <DateRangeInput
        datepickerProps={{ maxDate: new Date() }}
        style={{ width: '100%', minWidth: '250px' }}
        onChange={handleDateChange}
        id="date"
        label="Rango de fechas"
        labelHidden={false}
        value={[filters.createdAtFrom, filters.createdAtTo]}
      />
      <ApiFilterSelector
        style={{ width: '200px' }}
        label="Moneda"
        filterValuesPath="currencies"
        value={filters.currency as string}
        onChange={handleCurrencyChange}
      />
    </Row>
  );
}
