import { useTranslation } from 'react-i18next';
import { Header } from '../../../components/common/Header';
import { ImportType } from '../../../types/ImportFile';
import { ImportsTable } from '../../../components/imports/ImportsTable';
import { useState } from 'react';
import { ImportWizard } from './ImportWizard';
import { useImport } from '../../../components/imports/useImport';
import { ImportNotificationStatus } from '../../../components/imports/ImportNotificationStatus';

export function ImportVariableSubscriptionsScreen(): JSX.Element {
  const { t } = useTranslation();
  const [showWizard, setShowWizard] = useState(false);
  const { isProcessing, jobInfo } = useImport(
    ImportType.SubscriptionUpdateAmount
  );

  const returnToTable = () => setShowWizard(false);

  return showWizard ? (
    <ImportWizard onExit={returnToTable} />
  ) : (
    <>
      <Header
        title={t('screens.import_variable_subscriptions.title')}
        primaryButton={{
          text: t('screens.import_variable_subscriptions.new_import'),
          onClick: () => setShowWizard(true),
          disabled: isProcessing,
        }}
      />
      {jobInfo && (
        <ImportNotificationStatus
          importData={jobInfo}
          type={ImportType.SubscriptionUpdateAmount}
        />
      )}
      <ImportsTable type={ImportType.SubscriptionUpdateAmount} />
    </>
  );
}
