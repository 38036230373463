import {
  Button,
  CellText,
  InlineText,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalHeaderIconBox,
  TextArea,
} from '@increasecard/typed-components';
import { CancelIcon, MonetizationOn, AutorenewIcon } from '@increasecard/icons';
import { ActionPayload } from '../useInvoiceActions';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '../../../components/common/LoadingButton';
import { useBulkAction } from '../invoiceQueries';
import { ChangeEvent, useState } from 'react';
import { Invoice, InvoiceBulkAction, NewApiError } from '../../../types';
import { formatCurrency } from '../../../utils/currencyUtils';
import { Currency } from '@increasecard/increase-core/dist/types/Currency';
import { Box } from '../../../components/common/Box';

const noop = () => {
  return;
};
export interface InvoiceActionsModalProps {
  visible: boolean;
  onClose: VoidFunction;
  onExited?: VoidFunction;
  onSuccess?: VoidFunction;
  actionPayload: ActionPayload | null;
}

function HeaderIcon({ type }: { type: InvoiceBulkAction }) {
  switch (type) {
    case 'cancel':
      return <CancelIcon height="48" width="48" />;
    case 'retry':
      return <AutorenewIcon height="48" width="48" />;
    case 'manual_paid':
    case 'payment_method_request':
      return <MonetizationOn height="48" width="48" />;
  }
}

const sumarizeByCurrency = (invoices: Invoice[]) => {
  const totals: Metadata = {};
  invoices.forEach(({ currency, amount }) => {
    if (currency in totals) {
      totals[currency] += Number(amount);
    } else {
      totals[currency] = Number(amount);
    }
  });
  return totals;
};

function Message({
  actionPayload,
  onMessage,
}: {
  actionPayload: ActionPayload;
  onMessage: (s: string) => void;
}): JSX.Element {
  const { t } = useTranslation();
  const { invoices, type } = actionPayload;
  const totals = sumarizeByCurrency(actionPayload.invoices);
  const baseTransPath = `screens.invoices.${actionPayload.type}`;
  const resultTransPath =
    actionPayload?.type === 'payment_method_request'
      ? `${baseTransPath}.body_msg`
      : `${baseTransPath}_msg`;

  const handleMessage = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onMessage(e.target.value);
  };

  return (
    <Box display="flex" flexDirection="column" gap="12px">
      {t(resultTransPath, {
        count: invoices.length,
        actionType: type,
      })}
      <div>
        <InlineText weight="bold">
          Monto total de facturas por moneda:
        </InlineText>
        {Object.entries(totals).map((entry) => {
          return (
            <CellText key={entry[0]}>
              {formatCurrency(entry[1] as number, entry[0] as Currency)}
            </CellText>
          );
        })}
      </div>
      {actionPayload.type === 'manual_paid' ? (
        <TextArea
          label="Motivo"
          style={{ textAlign: 'left', marginTop: '16px' }}
          onChange={handleMessage}
        />
      ) : null}
    </Box>
  );
}

function Result({
  actionPayload,
  error,
  isSuccess,
}: {
  actionPayload: ActionPayload;
  isSuccess: boolean;
  error: NewApiError | null;
}): JSX.Element | null {
  const { t } = useTranslation();
  const baseTransPath = 'screens.invoices';
  const resultTransPath =
    actionPayload?.type === 'payment_method_request'
      ? `${baseTransPath}.${actionPayload.type}`
      : `${baseTransPath}`;
  if (error) {
    return t(`${resultTransPath}.error_msg`, {
      count: error.metadata.invoices.length,
      total: actionPayload.invoices.length,
    });
  }
  if (isSuccess) {
    return t(`${resultTransPath}.success_msg`, {
      count: actionPayload.invoices.length,
    });
  }
  return null;
}

export function InvoiceActionsModal({
  visible,
  onClose,
  onExited,
  onSuccess,
  actionPayload,
}: InvoiceActionsModalProps): JSX.Element | null {
  const { t } = useTranslation();
  const [message, setMessage] = useState<string>();
  const { mutate, reset, isLoading, isSuccess, isError, error } =
    useBulkAction();

  const baseTransPath = 'screens.invoices';
  const titleTransPath =
    actionPayload?.type === 'payment_method_request'
      ? `${baseTransPath}.${actionPayload.type}.title`
      : `${baseTransPath}.${actionPayload?.type}`;

  const handleCancel = () => {
    reset();
    onClose();
  };

  const handleConfirm = () => {
    if (actionPayload) {
      mutate(
        {
          bulk_action: actionPayload.type,
          invoice_ids: actionPayload.invoices.map(({ id }) => id),
          message,
        },
        { onSuccess: onSuccess }
      );
    }
  };

  return (
    <Modal
      align="center"
      onClose={onClose}
      onOk={noop}
      onCancel={noop}
      visible={visible}
      transitionProps={{
        onExited,
      }}
      width="570"
    >
      {actionPayload ? (
        <>
          <ModalHeaderIconBox
            colorPalette="pay"
            icon={<HeaderIcon type={actionPayload.type} />}
          />
          <ModalHeader align="center">
            {t(titleTransPath, {
              count: actionPayload.invoices.length,
              actionType: actionPayload.type,
            })}
          </ModalHeader>
          <ModalContent align="center">
            {!isError && !isSuccess && (
              <Message actionPayload={actionPayload} onMessage={setMessage} />
            )}
            <Result
              actionPayload={actionPayload}
              error={error}
              isSuccess={isSuccess}
            />
          </ModalContent>
        </>
      ) : null}
      <ModalFooter align="center">
        {error || isSuccess ? (
          <Button onClick={handleCancel} buttonType="primary">
            {t('common.close')}
          </Button>
        ) : (
          <>
            <Button
              data-testid="cancel-button"
              onClick={handleCancel}
              buttonType="invisible"
              disabled={isLoading}
            >
              {t('common.cancel')}
            </Button>
            <LoadingButton
              data-testid="confirm-button"
              onClick={handleConfirm}
              isLoading={isLoading}
            >
              {t(titleTransPath, {
                count: actionPayload?.invoices.length,
              })}
            </LoadingButton>
          </>
        )}
      </ModalFooter>
    </Modal>
  );
}
