import { Grid, PathSelector } from '@increasecard/typed-components';
import { CSSProperties } from 'react';

export interface PathSwitcherProps {
  name?: string;
  optionATitle: string;
  optionBTitle: string;
  optionADescription?: string;
  optionBDescription?: string;
  value: boolean | undefined;
  onChange: (value: boolean) => void;
  style?: CSSProperties;
}

export function PathSwitcher({
  optionATitle,
  optionBTitle,
  optionADescription,
  optionBDescription,
  value,
  onChange,
  style,
}: PathSwitcherProps): JSX.Element {
  return (
    <Grid style={style}>
      <PathSelector
        id="option_a"
        className="span-desktop-6 span-mobile-1"
        title={optionATitle}
        checked={!!value === false}
        onClick={() => onChange(!value)}
        style={{ cursor: 'pointer' }}
      >
        {optionADescription}
      </PathSelector>
      <PathSelector
        id="option_b"
        className="span-desktop-6 span-mobile-1"
        title={optionBTitle}
        checked={!!value === true}
        onClick={() => onChange(!value)}
        style={{ cursor: 'pointer' }}
      >
        {optionBDescription}
      </PathSelector>
    </Grid>
  );
}
