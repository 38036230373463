import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import {
  createCustomer,
  fetchSubscriptionAmountToCharge,
  fetchCustomerById,
  fetchPlans,
  findCustomer,
  createPaymentMethod,
  fetchInvoiceById,
  PaymentMethodCreateParams,
  fetchAccount,
  fetchSubscriptionById,
  createSubscriptionRequest,
  fetchCollectMethods,
  fetchSubscriptionSummary,
  SubscriptionSummaryParams,
  checkCoupon,
  fetchPlanById,
} from '../../services/CheckoutService';
import {
  CheckoutAccount,
  GroupedCollectMethods,
  Customer,
  CheckoutInvoice,
  NewApiError,
  CheckoutPayment,
  PaymentMethod,
  CheckoutPlan,
  AmountToCharge,
  CheckoutSubscription,
  SummaryRow,
  PaginationConfig,
  PaginatedData,
} from '../../types';
import { findPayment } from './checkoutUtils';
import { planFromApi } from '../../utils/planUtils';
import { useCheckoutParams } from './useCheckoutParams';
import { CustomerFormValues } from '../../schemas/CustomerSchema';
import { getPaginatedQueriesData } from '../../utils/queryUtils';

const staleTime = Infinity;

export const useAccountById = (
  accountId: string
): UseQueryResult<CheckoutAccount, NewApiError> => {
  return useQuery(['account', accountId], () => fetchAccount(accountId), {
    staleTime,
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useCreateCustomer = (): UseMutationResult<
  Customer,
  NewApiError,
  CustomerFormValues
> => {
  const { accountId: account_id } = useCheckoutParams();
  return useMutation((values: CustomerFormValues) =>
    createCustomer({ account_id, ...values })
  );
};

export const useCustomerById = (
  customerId: string
): UseQueryResult<Customer, NewApiError> => {
  return useQuery(
    ['customer', customerId],
    () => fetchCustomerById(customerId),
    {
      enabled: !!customerId,
    }
  );
};

export const useFindCustomer = (
  data: Pick<Customer, 'email' | 'tax_id'> | null
): UseQueryResult<Customer, NewApiError> => {
  const { accountId: account_id } = useCheckoutParams();
  const params = data && { account_id, ...data };
  return useQuery(['customer', params], () => findCustomer(params), {
    enabled: !!data,
    staleTime,
  });
};

interface UsePlansByProductOptions {
  onSuccess?: (data: CheckoutPlan[]) => void;
  planId?: string;
}

export const usePlanById = (
  productId: string,
  planId: string
): UseQueryResult<CheckoutPlan, NewApiError> => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ['plan', 'detail', productId, planId],
    queryFn: () => fetchPlanById(productId, planId),
    enabled: !!productId,
    initialData: () => {
      return getPaginatedQueriesData<CheckoutPlan>(queryClient, [
        'plan',
        'list',
      ])?.find((c) => c.id === planId);
    },
  });
};

export const usePlans = (
  params: PaginationConfig & { product_id: string }
): UseQueryResult<PaginatedData<CheckoutPlan>, NewApiError> => {
  return useQuery({
    queryKey: ['plan', 'list', params],
    queryFn: () => fetchPlans(params, params.product_id),
    enabled: !!params.product_id,
    keepPreviousData: true,
  });
};

export const usePlansByProduct = (
  productId: string,
  { onSuccess, planId }: UsePlansByProductOptions
): UseQueryResult<CheckoutPlan[], NewApiError> => {
  return useQuery(
    ['plans', productId],
    async () => {
      // eslint-disable-next-line prefer-const
      let { total_pages, data: planList } = await fetchPlans(
        { per_page: 40 },
        productId
      );

      if (total_pages > 1) {
        const planRequests = [];
        // Start in second page to reuse the first request
        for (let p = 2; p <= total_pages; p++) {
          planRequests.push(fetchPlans({ per_page: 40, page: p }, productId));
        }
        const responses = await Promise.all(planRequests);
        responses.forEach(({ data }) => {
          planList = [...planList, ...data];
        });
      }
      return planList.map(planFromApi);
    },
    {
      enabled: !!productId,
      staleTime,
      onSuccess,
      select: (data: CheckoutPlan[]) => {
        if (planId) {
          return data.filter((plan) => plan.id === planId);
        }
        return data;
      },
    }
  );
};

// Invoice
export const useInvoiceById = (
  invoiceId: string
): UseQueryResult<CheckoutInvoice, NewApiError> => {
  return useQuery(['invoice', invoiceId], () => fetchInvoiceById(invoiceId), {
    enabled: !!invoiceId,
  });
};

interface UsePayInvoiceResult {
  paymentMethod: PaymentMethod;
  payment: CheckoutPayment | undefined;
}

interface UsePayInvoiceParams {
  invoiceId: string;
  paymentData: PaymentMethodCreateParams;
}

export const usePayInvoice = (): UseMutationResult<
  UsePayInvoiceResult,
  NewApiError,
  UsePayInvoiceParams
> => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ invoiceId, paymentData }) => {
      const paymentMethod = await createPaymentMethod(paymentData);
      const invoice = await fetchInvoiceById(invoiceId);
      const payment = invoice.payments.find(
        (p) => p.payment_method.id === paymentMethod.id
      );
      return {
        paymentMethod,
        payment,
      };
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['invoice']);
      },
    }
  );
};

// Subscription
export const useSubscriptionById = (
  id: string
): UseQueryResult<CheckoutSubscription> => {
  return useQuery(['subscription', id], () => fetchSubscriptionById(id), {
    enabled: !!id,
    staleTime,
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useCreateSubscription = () => {
  return useMutation(createSubscriptionRequest);
};

interface UsePaySubscriptionResult {
  subscriptionPaid: CheckoutSubscription;
  paymentMethod: PaymentMethod;
  payment: CheckoutPayment | undefined;
}

interface UsePaySubscriptionParams {
  customerId: string;
  subscriptionId: string;
  paymentData: PaymentMethodCreateParams;
}

export const usePaySubscription = (): UseMutationResult<
  UsePaySubscriptionResult,
  NewApiError,
  UsePaySubscriptionParams
> => {
  return useMutation(async ({ customerId, subscriptionId, paymentData }) => {
    const paymentMethod = await createPaymentMethod({
      ...paymentData,
      customer_id: customerId,
      reference_id: subscriptionId,
      reference_type: 'subscription',
    });
    // If payment didn't fail fetch the subscription with the new status
    const subscriptionPaid = await fetchSubscriptionById(subscriptionId);
    const payment =
      subscriptionPaid.status !== 'pending_approval'
        ? findPayment(subscriptionPaid, paymentMethod.id)
        : undefined;

    return {
      subscriptionPaid,
      paymentMethod,
      payment,
    };
  });
};

export const useSubscriptionAmountToCharge = (
  subscriptionId: string
): UseQueryResult<AmountToCharge> => {
  return useQuery(
    ['subscriptionAmountToCharge', subscriptionId],
    () => fetchSubscriptionAmountToCharge(subscriptionId),
    {
      enabled: !!subscriptionId,
    }
  );
};

// Customer

export const useCreatePaymentMethod = (): UseMutationResult<
  PaymentMethod,
  NewApiError,
  PaymentMethodCreateParams
> => {
  return useMutation(createPaymentMethod);
};

export const useCollectMethods = (
  accountId: string,
  customerId: string
): UseQueryResult<GroupedCollectMethods, NewApiError> => {
  return useQuery(
    ['customer', accountId, customerId],
    () =>
      fetchCollectMethods({
        account_id: accountId,
        customer_id: customerId,
        grouped: true,
        status: 'enabled',
      }),
    {
      enabled: !!accountId || !!customerId,
      staleTime,
    }
  );
};

export const useSummary = (
  params: SubscriptionSummaryParams
): UseQueryResult<SummaryRow[], NewApiError> => {
  return useQuery(
    ['subscriptions/summary', params],
    () => fetchSubscriptionSummary(params),
    {
      enabled: !!params.accountId,
      staleTime,
      keepPreviousData: true,
    }
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useCheckCoupon = () => {
  return useMutation(checkCoupon);
};
