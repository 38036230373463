import {
  useAuth,
  AuthStates,
  UserSubscription,
  User,
} from '@increasecard/increase-core';
import { productName } from '../config';

function getSubscription(subscriptions: UserSubscription[]) {
  return subscriptions.find(
    (subscription) =>
      subscription.product.name === productName &&
      subscription.state !== 'canceled'
  );
}

export interface UsePayUser extends User {
  paySubscription: UserSubscription | undefined;
}

export function usePayUser(): UsePayUser | null {
  // require authentication (redirect to login)
  const auth = useAuth({ requireAuth: true });
  if (auth.state !== AuthStates.Authenticated) return null;

  const user = auth.user;
  const paySubscription = getSubscription(user.subscriptions);
  return Object.assign({}, user, { paySubscription });
}
