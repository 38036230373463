import { Button, Grid, H2 } from '@increasecard/typed-components';
import { FormikProvider, useFormik } from 'formik';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { InputNumberField } from '../../components/common/FormikFields';
import { InputWithDescription } from '../../components/common/InputWithDescription';
import { RouteLeavingModal } from '../../components/common/RouteLeavingModal';
import { Row } from '../../components/common/Row';
import { ScreenSeparator } from '../../components/common/ScreenSeparator';
import { ApiErrorMessage } from '../../components/ErrorMessage';
import { useCustomerById } from '../../globalQueries/Queries';
import { Extra } from '../../types/common';
import { NewApiError } from '../../types/NewApiError';
import { FrontPlan } from '../../types/Plan';
import { Subscription } from '../../types/Subscription';
import {
  isAdditionalCost,
  isCoupon,
  isDiscount,
  mergeExtras,
  sortExtras,
} from '../../utils/extrasUtils';
import { CustomerSelector } from '../customers/CustomerSelector';
import { PlanConfigPayload, PlanSelection } from './components/PlanSelection';
import { SubscriptionSchema } from './components/subscriptionSchema';
import { TagInput } from './components/TagInput/TagInput';
import {
  SubscriptionScheduler,
  ScheduleChangeEvent,
} from './SubscriptionScheduler';
import { LoadingButton } from '../../components/common/LoadingButton';
import { useModal } from '../../hooks/useModal';
import { ConfirmModal } from '../../components/common/ConfirmModal';
import { WarningCircleIcon } from '@increasecard/icons';
import { PathSwitcher } from '../../components/common/PathSwitcher';

export interface SubscriptionFormProps {
  onSubmit: (values: SubscriptionFormValues) => void;
  onCancel?: VoidFunction;
  isEditing: boolean;
  subscription?: Subscription;
  customerId?: string;
  error: NewApiError | null;
  isLoading: boolean;
}

const DEFAULT_VALUES: SubscriptionFormValues = {
  plan: undefined,
  plan_id: '',
  customer_id: '',
  quantity: 1,
  amount: 0,
  subscriptionExtras: [],
  tags: [],
  trial_period_days: 0,
  shareLink: false,
};

export interface PlanWithExtras extends FrontPlan {
  extras: Extra[];
}

export interface SubscriptionFormValues {
  plan: PlanWithExtras | undefined;
  plan_id: string;
  customer_id: string;
  quantity?: number | null;
  amount: number;
  start_date?: string;
  end_date?: string;
  next_billing_date?: string;
  trial_period_days?: number;
  subscriptionExtras: Extra[];
  external_id?: string | null;
  payment_method_id?: string;
  tags: string[];
  shareLink?: boolean;
}

const splitExtrasByPlan = (
  extrasFromSubscription: Extra[],
  extrasFromPlan: Extra[]
) => {
  const planExtraIds = extrasFromPlan.map(({ id }) => id);
  const planExtras = [];
  const subscriptionExtras = [];
  for (const extra of extrasFromSubscription) {
    if (planExtraIds.includes(extra.id)) {
      planExtras.push(extra);
    } else {
      if (isDiscount(extra) || isAdditionalCost(extra)) {
        extra.cycle_amount = extra.cycle_amounts_left ?? extra.cycle_amount;
      }
      subscriptionExtras.push(extra);
    }
  }
  return { planExtras, subscriptionExtras };
};

const getInitialValues = (
  subscription?: Subscription,
  customer_id?: string
) => {
  if (subscription) {
    const {
      plan,
      customer,
      quantity,
      amount,
      external_id,
      payment_method,
      tags,
    } = subscription;

    const { planExtras, subscriptionExtras } = splitExtrasByPlan(
      mergeExtras(subscription),
      mergeExtras(plan)
    );
    return {
      ...DEFAULT_VALUES,
      // This does not seem like a good solution since prevents to update the cyles_left on a discount.
      // on subscrition edition => remove extras that come from the plan to avoid duplicates
      subscriptionExtras,
      plan: { ...plan, extras: planExtras },
      plan_id: plan.id,
      customer_id: customer.id,
      quantity,
      amount: Number(amount),
      trial_period_days: subscription.plan.trial_period_days,
      external_id,
      payment_method_id: payment_method?.id,
      tags,
    };
  } else if (customer_id) {
    return {
      ...DEFAULT_VALUES,
      customer_id,
    };
  } else {
    return DEFAULT_VALUES;
  }
};

const translationsId = 'screens.new_subscription';

export function SubscriptionForm({
  onSubmit,
  onCancel,
  isEditing,
  subscription,
  customerId,
  error,
  isLoading,
}: SubscriptionFormProps): JSX.Element {
  const { t } = useTranslation();
  const couponModal = useModal();
  const formik = useFormik({
    initialValues: getInitialValues(subscription, customerId),
    enableReinitialize: true,
    onSubmit,
    validationSchema: SubscriptionSchema(),
  });
  const customerQuery = useCustomerById(formik.values.customer_id);
  const currentCustomer = isEditing
    ? subscription?.customer
    : customerQuery.data;

  const handlePlanConfig = (payload: PlanConfigPayload) => {
    if (payload.name === 'plan') {
      if (payload.value) {
        formik.setFieldValue('plan', {
          ...payload.value,
          extras: mergeExtras(payload.value),
        });
        if (payload.value.type === 'variableflat') {
          formik.setFieldValue('amount', Number(payload.value.amount));
        }
        formik.setFieldValue('plan_id', payload.value.id);
        formik.setFieldValue(
          'trial_period_days',
          payload.value.trial_period_days
        );
      } else {
        formik.setFieldValue('plan', payload.value);
        formik.setFieldValue('plan_id', '*');
        formik.setFieldValue(
          'trial_period_days',
          DEFAULT_VALUES.trial_period_days
        );
      }
    } else if (payload.name === 'extra') {
      if (isCoupon(payload.value)) {
        couponModal.open();
      } else {
        formik.setFieldValue(
          'subscriptionExtras',
          [...(formik.values.subscriptionExtras || []), payload.value].sort(
            sortExtras
          )
        );
      }
    } else {
      formik.setFieldValue(payload.name, payload.value);
    }
  };

  const invoicesGeneratedCount = subscription?.invoices_generated_count;

  return (
    <FormikProvider value={formik}>
      <Grid as="form" rowGap={2} onSubmit={formik.handleSubmit}>
        <CustomerSelector
          initialCustomerId={formik.values.customer_id}
          onChange={({ id }) => {
            formik.setFieldValue('customer_id', id);
          }}
          changeButtonHidden={isEditing}
        />
        {currentCustomer ? (
          <>
            <PlanSelection
              plan={formik.values.plan}
              product={subscription?.product}
              amount={formik.values.amount}
              quantity={formik.values.quantity}
              isEditing={isEditing}
              subscriptionExtras={formik.values.subscriptionExtras}
              onChange={handlePlanConfig}
              invoicesGeneratedCount={invoicesGeneratedCount}
            />
            <ScreenSeparator margin="2rem 0 0 0" />
            <H2>{t('screens.new_subscription.schedule')}</H2>
            <SubscriptionScheduler
              plan={formik.values.plan}
              subscription={subscription}
              onChange={(e: ScheduleChangeEvent) => {
                if (!e.isValid) {
                  // Here the field with error does not matter.
                  // This is just to tell formik that the form is invalid.
                  formik.setFieldError('next_billing_date', 'invalid date');
                } else {
                  // This is temporal until SubscriptionScheduler becomes a controlled component
                  formik.setFieldValue('start_date', e.startDate);
                  formik.setFieldValue('next_billing_date', e.billingDate);
                  formik.setFieldValue('end_date', e.endDate);
                }
              }}
            />
            {!isEditing && (
              <>
                <ScreenSeparator margin="2rem 0 0 0" />
                <H2>{t('data.plan.trial_period_days')}</H2>
                <InputNumberField
                  className="span-desktop-2"
                  id="trial_period_days"
                  min={0}
                  step={1}
                />
              </>
            )}
            <ScreenSeparator margin="2rem 0 0 0" />
            <InputWithDescription
              title={t('common.external_id')}
              value={formik.values.external_id}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                formik.setFieldValue('external_id', e.target.value)
              }
              description={t('screens.new_invoice.reference_id_desc')}
            />
            <ScreenSeparator margin="2rem 0 0 0" />
            <TagInput
              tags={formik.values.tags}
              onListChange={(tags) => formik.setFieldValue('tags', tags)}
            />
            {!isEditing && (
              <>
                <H2 style={{ marginTop: '2rem' }}>
                  {t('common.payment_method')}
                </H2>
                <PathSwitcher
                  optionATitle={t(`${translationsId}.use_payment_method_title`)}
                  optionBTitle={t(
                    `${translationsId}.send_payment_method_title`
                  )}
                  optionADescription={t(
                    `${translationsId}.use_payment_method_desc`
                  )}
                  optionBDescription={t(
                    `${translationsId}.send_payment_method_desc`
                  )}
                  value={formik.values.shareLink}
                  onChange={(value) => formik.setFieldValue('shareLink', value)}
                />
              </>
            )}
          </>
        ) : null}
        {error && <ApiErrorMessage error={error} />}
        <Row marginTop="2rem" gap="1rem">
          <LoadingButton
            variant="primary"
            disabled={!formik.isValid || isLoading}
            isLoading={isLoading}
            type="submit"
          >
            {isEditing
              ? t('screens.new_subscription.update')
              : formik.values.shareLink
                ? t('screens.new_subscription.create')
                : t('screens.new_subscription.create_and_continue')}
          </LoadingButton>
          <Button onClick={onCancel} variant="invisible">
            {t('common.cancel')}
          </Button>
        </Row>
      </Grid>
      <RouteLeavingModal
        active={formik.dirty && formik.submitCount === 0}
        headerText={t('screens.new_subscription.leaving_title')}
        description={t('screens.new_subscription.leaving_description')}
        cancelLabel={t('screens.new_subscription.leaving_cancel')}
        okLabel={t('screens.new_subscription.leaving_continue')}
      />
      <ConfirmModal
        visible={couponModal.isOpen}
        headerIcon={<WarningCircleIcon width="48px" height="48px" />}
        header="Editar subscripción"
        message="No se pueden agregar cupones manualmente a una suscripción"
        onConfirm={couponModal.close}
      />
    </FormikProvider>
  );
}
