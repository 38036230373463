import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalHeaderIconBox,
  Paragraph,
} from '@increasecard/typed-components';
import { MonetizationOn } from '@increasecard/icons';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '../../../components/common/LoadingButton';
import { FrontPlan } from '../../../types';
import { useChangePlanPrice } from '../planQueries';
import { Row } from '../../../components/common/Row';
import {
  InputNumberField,
  InputPercentageField,
  SelectField,
} from '../../../components/common/FormikFields';
import { list2NameValue } from '../../../utils/utils';
import { AMOUNT_TYPES } from '../../../enums';
import { Formik } from 'formik';
import { UpdatePlanPricesParams } from '../../../services/PlanService';

const initialValues: Omit<UpdatePlanPricesParams, 'plan_ids'> = {
  amount_type: 'flat',
  amount: '0',
};

const getPlansCurrencies = (plans: FrontPlan[] | null) => {
  if (!plans) return [];
  const currencies = new Set();
  for (const plan of plans) {
    if (!currencies.has(plan.currency)) {
      currencies.add(plan.currency);
    }
  }
  return [...currencies];
};

const noop = () => {
  return;
};
export interface InvoiceActionsModalProps {
  visible: boolean;
  onClose: VoidFunction;
  onExited?: VoidFunction;
  onSuccess?: VoidFunction;
  plans: FrontPlan[] | null;
}

export function ChangePriceModal({
  visible,
  onClose,
  onExited,
  onSuccess,
  plans,
}: InvoiceActionsModalProps): JSX.Element | null {
  const { t } = useTranslation();
  const plansCurrencies = getPlansCurrencies(plans); // hasMultiCurrency(value.plans)
  const hasMultipleCurrencies = plansCurrencies.length > 1;
  const OPTIONS = list2NameValue(
    hasMultipleCurrencies ? [AMOUNT_TYPES.FLAT] : Object.values(AMOUNT_TYPES),
    'data.extras.types'
  );
  const { mutate, isLoading, isSuccess, error, reset } = useChangePlanPrice();

  const handleCancel = () => {
    onClose();
    reset();
  };

  const handleSubmit = (values: typeof initialValues) => {
    if (plans) {
      mutate(
        {
          plan_ids: plans.map(({ id }) => id),
          ...values,
        },
        { onSuccess }
      );
    }
  };

  return (
    <Modal
      align="center"
      onClose={onClose}
      onOk={noop}
      onCancel={noop}
      visible={visible}
      transitionProps={{
        onExited,
      }}
      width="570"
    >
      {plans ? (
        <>
          <ModalHeaderIconBox
            colorPalette="pay"
            icon={<MonetizationOn height="48" width="48" />}
          />
          <ModalHeader align="center">
            {t('screens.plans.change_price')}
          </ModalHeader>
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ values, handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <ModalContent align="center">
                    {!isSuccess && !error && (
                      <Row
                        gap="16px"
                        width="70%"
                        margin="16px auto"
                        textAlign="left"
                      >
                        <SelectField
                          id="amount_type"
                          options={OPTIONS}
                          label={t('common.type')}
                        />
                        {values.amount_type === 'flat' ? (
                          <InputNumberField
                            id="amount"
                            label={t('common.amount')}
                          />
                        ) : (
                          <InputPercentageField
                            id="amount"
                            autoComplete="off"
                            placeholder={t('common.percent_sign')}
                            label={t('common.percentage')}
                          />
                        )}
                      </Row>
                    )}
                    {error &&
                      t(`screens.plans.error_msg`, {
                        count: error.metadata.plans.length,
                        total: plans.length,
                      })}
                    {isSuccess &&
                      t(`screens.plans.update_plans_success`, {
                        count: plans.length,
                      })}
                    {hasMultipleCurrencies && (
                      <Paragraph>
                        Los planes seleccionados tienen más de una moneda, por
                        lo que solo se podrá aumentar por monto fijo
                      </Paragraph>
                    )}
                  </ModalContent>
                  <ModalFooter align="center">
                    {error || isSuccess ? (
                      <Button
                        onClick={handleCancel}
                        buttonType="primary"
                        type="button"
                      >
                        {t('common.close')}
                      </Button>
                    ) : (
                      <>
                        <Button
                          data-testid="cancel-button"
                          onClick={handleCancel}
                          buttonType="invisible"
                          disabled={isLoading}
                          type="button"
                        >
                          {t('common.cancel')}
                        </Button>
                        <LoadingButton
                          data-testid="confirm-button"
                          isLoading={isLoading}
                          type="submit"
                        >
                          {t('screens.plans.update_plans', {
                            count: plans?.length,
                          })}
                        </LoadingButton>
                      </>
                    )}
                  </ModalFooter>
                </form>
              );
            }}
          </Formik>
        </>
      ) : null}
    </Modal>
  );
}
