import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useModal } from '../../hooks/useModal';
import { ConfirmModal } from '../../components/common/ConfirmModal';
import { H1 } from '@increasecard/typed-components';
import { CustomerForm, CustomerFormValues } from './CustomerForm';
import { MdiCheckCircleIcon } from '@increasecard/icons';
import { useCreateCustomer } from './customerQueries';
import { useUIEventTracker } from '../../hooks/useUIEventTracker';

export function NewCustomerScreen(): JSX.Element {
  const { t } = useTranslation();
  const successModal = useModal();
  const history = useHistory();
  const newCustomer = useCreateCustomer();
  const eventTracker = useUIEventTracker('customer screen');

  const handleSubmit = (formData: CustomerFormValues) => {
    eventTracker('create_customer');
    newCustomer.mutate(formData, { onSuccess: () => successModal.open() });
  };

  const gotToCustomersScreen = () => {
    history.replace('/customers');
  };

  return (
    <>
      <H1 style={{ marginBottom: '3rem' }}>
        {t(`screens.customer_form.create_title`)}
      </H1>
      <CustomerForm
        submitText={t('screens.customer_form.create_title')}
        onSubmit={handleSubmit}
        onCancel={gotToCustomersScreen}
        isSubmitting={newCustomer.isLoading}
        error={newCustomer.error}
      />
      <ConfirmModal
        headerIcon={<MdiCheckCircleIcon width="48" height="48" />}
        visible={successModal.isOpen}
        header={t('screens.customer_form.success_create')}
        confirmLabel={t('common.accept')}
        onConfirm={gotToCustomersScreen}
        onClose={gotToCustomersScreen}
      />
    </>
  );
}
