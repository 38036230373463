import get from 'lodash/get';
import { useFilters } from '../../globalQueries/Queries';
import { ApiFilters, NameValue } from '../../types/common';
import { Paths } from '../../types/utility';
import { list2NameValue } from '../../utils/utils';

export interface UseApiFilters {
  options: NameValue[];
  isFetching: boolean;
}

export type FilterValuesPath = Paths<ApiFilters, 2>;

// TODO: We should change spelling in api response or frontend translations
const SINGULAR_MAP: Metadata = {
  subscriptions: 'subscription',
  plans: 'plan',
  discounts: 'discount',
  taxes: 'tax',
  invoices: 'invoice',
  payment_methods: 'payment_method',
  payments: 'payment',
  reservations: 'reservation',
  collect_methods: 'collect_method',
};

export function useApiFilters(
  filterValuesPath: FilterValuesPath,
  allSelectedOption?: boolean
): UseApiFilters {
  const [entity, field = ''] = filterValuesPath.split('.');
  const { data, isFetching } = useFilters();

  const getFieldKey = () => {
    const fieldKey = field ? `.${field.replace('statuses', 'status')}` : '';
    return `data.${SINGULAR_MAP[entity] || entity}${fieldKey}`;
  };

  const options = data
    ? list2NameValue(
        get(data, filterValuesPath),
        getFieldKey(), // extract key and make it singular
        allSelectedOption ? `common.all_${field || entity}` : undefined
      )
    : [];

  return { options, isFetching };
}
