import axios from 'axios';

const s3Client = axios.create({
  transformRequest: [
    (data) => {
      if (data && data.get && data.get('x-amz-credential')) {
        return data;
      }
      return JSON.stringify(data);
    },
  ],
  headers: {
    Accept: 'application/json',
  },
});

export async function uploadToS3(
  file: File,
  uploadInfo: Record<string, string>,
  onProgress?: (percent: number) => void
): Promise<string> {
  const formData = new FormData();
  Object.entries(uploadInfo).forEach(([key, value]) => {
    if (key !== 'url') {
      formData.append(key, value);
    }
  });
  formData.append('file', file);
  await s3Client.post(uploadInfo.url, formData, {
    headers: {
      'Content-Type': uploadInfo['Content-Type'],
      'Content-Disposition': `attachment; filename=${encodeURIComponent(
        file.name
      )}`,
    },
    onUploadProgress: (progressEvent) => {
      if (onProgress) {
        onProgress(
          Math.floor((progressEvent.loaded * 100) / progressEvent.total)
        );
      }
    },
  });
  return uploadInfo.key;
}
