import { CSSProperties } from 'react';
import styled from 'styled-components';
import { AccountBrandingSettings } from '../../types/Account';

const Logo = styled.div<{
  src: string;
  position: CSSProperties['backgroundPosition'];
}>`
  background-image: url(${({ src }) => src});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: ${({ position }) => position};
  width: 100%;
  height: 50px;
  margin-bottom: 2rem;
`;

export interface BrandLogoProps {
  brandingSettings?: AccountBrandingSettings;
  logoStyle?: CSSProperties;
  position?: CSSProperties['backgroundPosition'];
}

export function BrandLogo({
  brandingSettings,
  logoStyle = {},
  position = 'left',
}: BrandLogoProps): JSX.Element | null {
  if (!brandingSettings?.logo_url) return null;
  return (
    <Logo
      src={brandingSettings.logo_url}
      style={logoStyle}
      position={position}
    />
  );
}
