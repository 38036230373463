import { SVGProps, useState, FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ModalHeader,
  ModalContent,
  Spinner as SpinnerBadTypings,
  SpinnerProps,
} from '@increasecard/typed-components';
import {
  createDownloadLink,
  getReport,
} from '../../../services/ReportsService';
import { useInterval } from '../../../hooks/useInterval';

const Spinner = SpinnerBadTypings as FC<SpinnerProps & SVGProps<SVGSVGElement>>;

const CHECK_INTERVAL_MS = 500;
const REPORT_READY_STATUS = 'stored';

export interface WaitForReportStepProps {
  reportId: string | null;
  onSuccess: (download_link: string) => void;
  onError: (error: string) => void;
}

export function WaitForReportStep({
  reportId,
  onSuccess,
  onError,
}: WaitForReportStepProps): JSX.Element {
  const [reportReady, setReportReady] = useState(false);

  const checkReport = async () => {
    if (reportId === null || reportReady) return;

    const report = await getReport(reportId);
    if (report.status === REPORT_READY_STATUS) {
      setReportReady(true);
      const response = await createDownloadLink(reportId);
      onSuccess(response.data);
    }
    if (['failed_storing', 'failed_processing'].includes(report.status)) {
      onError(report.status);
    }
  };

  useInterval(checkReport, reportReady ? null : CHECK_INTERVAL_MS);

  const { t } = useTranslation();

  return (
    <>
      <ModalHeader align="center">
        {t('screens.export_modal.wait_for_report_step.title')}
      </ModalHeader>
      <ModalContent align="center">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div>
            {t('screens.export_modal.wait_for_report_step.description')}
          </div>
          <Spinner size={32} style={{ marginTop: '8px' }} />
        </div>
      </ModalContent>
    </>
  );
}
