import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalContent,
  Button,
} from '@increasecard/typed-components';
import { Row } from '../../../components/common/Row';
import { Customer } from '../../../types/Customer';

const MODAL_WIDTH = '569';

export interface CustomerExistsModalProps {
  isOpen: boolean;
  customer: Customer | undefined;
  onConfirm: (event: MouseEvent<HTMLButtonElement>) => void;
  onCancel: (event: MouseEvent<HTMLButtonElement>) => void;
}

export function CustomerExistsModal({
  isOpen,
  customer,
  onConfirm,
  onCancel,
}: CustomerExistsModalProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <Modal
      align="center"
      onClose={onCancel}
      visible={isOpen}
      width={MODAL_WIDTH}
      shadeClosable={false}
      onCancel={() => {
        return;
      }}
      onOk={() => {
        return;
      }}
    >
      <ModalHeader align="center">
        {t('screens.customer_checkout.modal_customer_exists.title', {
          name: customer?.first_name,
        })}
      </ModalHeader>
      <ModalContent align="center">
        {t('screens.customer_checkout.modal_customer_exists.message')}
      </ModalContent>
      <ModalFooter align="center">
        <Row flexWrap="wrap-reverse" justifyContent="center">
          <Button buttonType="invisible" onClick={onCancel}>
            {t('screens.customer_checkout.modal_customer_exists.create')}
          </Button>
          <Button buttonType="primary" onClick={onConfirm}>
            {t('screens.customer_checkout.modal_customer_exists.continue')}
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  );
}
