import {
  UseMutationResult,
  useQuery,
  UseQueryResult,
  useQueryClient,
} from '@tanstack/react-query';
import {
  setInvoiceAsPaid,
  SetAsPaidParams,
  fetchInvoices,
  cancelInvoice,
  createInvoice,
  retryInvoicePayment,
  fetchInvoiceById,
  bulkActions,
  updateInvoice,
} from '../../services/InvoiceService';
import { NewApiError } from '../../types/NewApiError';
import { PaginatedData, PaginationConfig } from '../../types/common';
import { Invoice } from '../../types/Invoice';
import {
  queryKeyFactory,
  useMutationWithInvalidation,
  getPaginatedQueriesData,
} from '../../utils/queryUtils';
import { InvoiceFormValues } from './InvoiceSchema';

export const queryKeys = queryKeyFactory('invoice');
export interface InvoiceFilter extends PaginationConfig {
  customer_id?: string;
  subscription_id?: string;
  status?: string;
}

export const useInvoices = (
  params: InvoiceFilter
): UseQueryResult<PaginatedData<Invoice>, NewApiError> => {
  return useQuery({
    queryKey: queryKeys.list([params]),
    queryFn: () => fetchInvoices(params),
    enabled: !!params,
    keepPreviousData: true,
  });
};

export const useSetInvoiceAsPaid = (): UseMutationResult<
  void,
  NewApiError,
  SetAsPaidParams
> => {
  return useMutationWithInvalidation(setInvoiceAsPaid, queryKeys.all);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useCancelInvoice = () => {
  return useMutationWithInvalidation(cancelInvoice, queryKeys.all);
};

export const useCreateInvoice = (): UseMutationResult<
  Invoice,
  NewApiError,
  InvoiceFormValues
> => {
  return useMutationWithInvalidation(createInvoice, queryKeys.all);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useRetryInvoicePayment() {
  return useMutationWithInvalidation(retryInvoicePayment, queryKeys.all);
}

export function useInvoiceById(
  id: string
): UseQueryResult<Invoice, NewApiError> {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: queryKeys.detail(id),
    queryFn: () => fetchInvoiceById(id),
    enabled: !!id,
    initialData: () => {
      return getPaginatedQueriesData<Invoice>(queryClient, [
        'invoice',
        'list',
      ])?.find((data) => {
        return data.id === id;
      });
    },
  });
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useUpdateInvoice() {
  return useMutationWithInvalidation(updateInvoice, queryKeys.all);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useBulkAction() {
  return useMutationWithInvalidation(bulkActions, queryKeys.all);
}
