import { useTranslation } from 'react-i18next';
import {
  ColumnDefinition,
  DataTable,
  MenuActionDefinition,
  RowDefinition,
} from '../../components/common/DataTable/DataTable';
import { ApiErrorMessage } from '../../components/ErrorMessage';
import { Constants } from '../../constants';
import { useCollectMethods } from '../../globalQueries/Queries';
import { useModal } from '../../hooks/useModal';
import { CollectMethod, MercadopagoGatewaytCollectMethod } from '../../types';
import { CollectMethodsLogos } from '../../utils/collectMethodsUtils';
import { EditCollectMethodModal } from './EditCollectMethodModal';
import { ChangeCmStatusModal } from './ChangeCMStatusModal';

export function CollectMethodsTable(): JSX.Element {
  const { t } = useTranslation();
  const { isLoading, error, data, refetch } = useCollectMethods();
  const configModal = useModal(false);
  const statusModal = useModal(false);

  if (error) {
    return <ApiErrorMessage error={error} onClick={() => refetch()} />;
  }

  const hasData = data ? data.length > 0 : false;

  const tableColumns: ColumnDefinition[] = [
    {
      field: 'type',
      headerName: t('common.processor'),
      // eslint-disable-next-line react/display-name
      valueGetter: (
        cm: Exclude<CollectMethod, MercadopagoGatewaytCollectMethod>
      ) => {
        const Logo = CollectMethodsLogos[cm.type];
        return <Logo height="40px" />;
      },
    },
    {
      field: 'name',
      headerName: t('common.name'),
    },
    {
      field: 'country',
      headerName: t('common.country'),
      valueGetter: (cm: RowDefinition) =>
        cm.country && t(`data.countries.${cm.country}`),
    },
    {
      field: 'max_payment_retries',
      headerName: t('screens.collect_methods.automatic_retries'),
      valueGetter: ({
        retry_period_unit,
        max_payment_retries,
        retry_period_time,
      }) => {
        const transConfig = {
          retry_period_unit: retry_period_unit.slice(0, -1), // Depluralize units
          retry_period_time,
          max_payment_retries,
        };
        return t('screens.collect_methods.retries_description', transConfig);
      },
    },
    {
      type: 'tag',
      field: 'status',
      headerName: t('common.status'),
      tagType: 'plan',
      tagLabel: (cm: RowDefinition) => t(`common.${cm.status}`),
    },
  ];

  const menuActions: MenuActionDefinition[] = [
    {
      name: 'config_cm',
      label: t('common.config'),
      cb: (cm: CollectMethod) => configModal.open(cm),
    },
    {
      name: 'change_cm_status',
      label: (cm: CollectMethod) =>
        cm.status !== 'disabled' ? t('common.disable') : t('common.enable'),
      cb: (cm: CollectMethod) => statusModal.open(cm),
    },
  ];

  return (
    <>
      <DataTable
        tableProps={{ zebra: true, hasData }}
        rowHeight="double"
        columns={tableColumns}
        pageSize={Constants.LARGE_PAGE_SIZE}
        isLoading={!hasData && isLoading}
        rows={data}
        menuActions={menuActions}
      />
      <EditCollectMethodModal
        visible={configModal.isOpen}
        onExited={() => configModal.setData(null)}
        collectMethod={configModal.data}
        onClose={configModal.close}
      />
      <ChangeCmStatusModal
        visible={statusModal.isOpen}
        collectMethod={statusModal.data}
        onClose={statusModal.close}
      />
    </>
  );
}
