import { Button, Grid, H2 } from '@increasecard/typed-components';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CollectMethodSelector } from '../../components/common/CollectMethodSelector';
import { InputWithDescription } from '../../components/common/InputWithDescription';
import { PaymentMethodPathSelector } from '../../components/paymentMethods/PaymentMethodPathSelector';
import { Row } from '../../components/common/Row';
import { ScreenSeparator } from '../../components/common/ScreenSeparator';
import { ErrorMessage } from '../../components/ErrorMessage';
import { useScrollToTopOnMount } from '../../hooks/useScrollToTopOnMount';
import { arrayDifference } from '../../utils/utils';
import { hasCollectMethods } from '../../utils/collectMethodsUtils';
import { CustomerSelector } from '../customers/CustomerSelector';
import { DebtItems } from './DebtItems';
import {
  Country,
  Currency,
  Customer,
  DebtInvoiceParams,
  DebtItem,
  Extra,
  GroupedCollectMethods,
} from '../../types';
import { ExtrasAggregator } from '../extras/ExtrasAggregator';
import { extrasToInvoiceItems } from '../invoice/invoiceUtils';

const INITIAL_VAlUES: DebtInvoiceParams = {
  invoice_ids: [],
  external_reference: '',
  success_url: '',
  payment_method_id: undefined,
  collect_methods: undefined,
  items: [],
};

const noop = () => null;

export interface DebtInvoiceFormProps {
  debtItems: DebtItem[];
  customer: Customer;
  currency?: Currency;
  country: Country;
  referenceId?: string;
  collectMethods?: GroupedCollectMethods;
  onSubmit: (params: DebtInvoiceParams) => void;
  onCancel: VoidFunction;
  isSubmitting: boolean;
}

function cmDiff(cm1: GroupedCollectMethods, cm2: GroupedCollectMethods) {
  const arr1 = Object.values(cm1)
    .flat()
    .map((i) => i.id);
  const arr2 = Object.values(cm2)
    .flat()
    .map((i) => i.id);
  return arrayDifference(arr1, arr2);
}

export function DebtInvoiceForm({
  debtItems,
  customer,
  currency,
  country,
  collectMethods,
  onSubmit,
  onCancel,
  isSubmitting,
}: DebtInvoiceFormProps): JSX.Element {
  useScrollToTopOnMount();
  const [invoiceItems, setInvoiceItems] = useState<DebtItem[]>(debtItems);
  const [extras, setExtras] = useState<Extra[]>([]);
  const [invoiceValues, setInvoiceValue] = useState<DebtInvoiceParams>({
    ...INITIAL_VAlUES,
    collect_methods: collectMethods,
  });
  const { t } = useTranslation();
  const isValid = hasCollectMethods(invoiceValues.collect_methods);
  const cmDifference = collectMethods
    ? cmDiff(
        collectMethods,
        invoiceValues.collect_methods || { card: [], cbu: [], ticket: [] }
      )
    : [];
  const subtotal = invoiceItems.reduce(
    (prev, curr) => prev + Number(curr.amount),
    0
  );

  const handleSubmit = () => {
    if (isValid) {
      onSubmit({
        ...invoiceValues,
        items: extrasToInvoiceItems(extras, subtotal, currency),
        invoice_ids: invoiceItems.map(({ id }) => id),
      });
    }
  };

  const handleChange = (id: string, values: Metadata) => {
    setInvoiceValue((prev: DebtInvoiceParams) => ({
      ...prev,
      [id]: values,
    }));
  };

  return (
    <Grid rowGap={2}>
      <CustomerSelector
        onChange={noop}
        initialCustomerId={customer.id}
        changeButtonHidden={true}
      />
      <DebtItems
        debtItems={debtItems}
        selectedItems={invoiceItems}
        onChange={setInvoiceItems}
      />
      <ExtrasAggregator
        align="end"
        country={country}
        currency={currency || 'ARS'}
        subtotal={subtotal}
        onListChange={setExtras}
        items={extras}
        allowedExtras={['discount', 'additional_cost', 'tax']}
      />
      <CollectMethodSelector
        onChange={handleChange}
        country={country}
        currency={currency}
        collectMethods={invoiceValues.collect_methods}
      />
      {cmDifference.length > 0 ? (
        <ErrorMessage>
          Los metodos de cobro que sean diferentes a los de la suscripción no
          podrán ser usados por tu cliente para cobrar la subscripción del
          momento del pago de la deuda en adelante
        </ErrorMessage>
      ) : null}
      <H2 style={{ marginTop: '2rem' }}>{t('common.payment_method')}</H2>
      <PaymentMethodPathSelector
        customerId={customer.id}
        onChange={(payment_method_id) =>
          handleChange('payment_method_id', payment_method_id)
        }
        groupedCollectMethods={invoiceValues.collect_methods}
        translationsId="screens.new_invoice"
        paymentReference={{
          reference_id: customer.id,
          reference_type: 'customer',
        }}
      />
      <ScreenSeparator margin="40px 0 0 0" />
      <InputWithDescription
        title={t('common.external_id')}
        value={invoiceValues.external_reference}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          handleChange('external_reference', e.target.value)
        }
        description={t('screens.new_invoice.reference_id_desc')}
      />
      <ScreenSeparator margin="40px 0 0 0" />
      <InputWithDescription
        id="success_url"
        title={t('common.success_page')}
        value={invoiceValues.success_url}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          handleChange('success_url', e.target.value)
        }
        placeholder={t('common.enter_url')}
        description={t('screens.new_invoice.success_url_desc')}
      />
      <Row marginTop="2rem" flexGap="1rem">
        <Button
          buttonType="primary"
          onClick={handleSubmit}
          disabled={!isValid || isSubmitting}
        >
          {t(
            `screens.new_invoice.${
              invoiceValues.payment_method_id ? 'charge_now' : 'create'
            }`
          )}
        </Button>
        <Button
          key="cancel"
          onClick={onCancel}
          buttonType="invisible"
          disabled={isSubmitting}
        >
          {t('common.cancel')}
        </Button>
      </Row>
    </Grid>
  );
}
