import { useTranslation } from 'react-i18next';
import { Button, Switch, H2, Caption } from '@increasecard/typed-components';
import { ScreenSeparator } from '../../components/common/ScreenSeparator';
import { Row } from '../../components/common/Row';
import { useUpdateAccountSetting } from '../../globalQueries/Queries';
import { ChangeEvent, useState } from 'react';
import { Account } from '../../types';
import { LoadingButton } from '../../components/common/LoadingButton';
import { Column } from '../../components/common/Column';

export interface AdvancedConfigProps {
  account: Account;
}

export function AdvancedConfig({ account }: AdvancedConfigProps): JSX.Element {
  const { t } = useTranslation();
  const { isLoading, mutate } = useUpdateAccountSetting();
  const [config, setConfig] = useState({
    allow_insufficient_funds: account.allow_insufficient_funds,
    validate_cbu_with_payment: account.validate_cbu_with_payment,
    validate_with_uncollectible_invoices:
      account.validate_with_uncollectible_invoices,
    allow_active_subscriptions_without_payment:
      account.allow_active_subscriptions_without_payment,
  });

  const configChanged =
    config.allow_insufficient_funds !== account.allow_insufficient_funds ||
    config.validate_cbu_with_payment !== account.validate_cbu_with_payment ||
    config.validate_with_uncollectible_invoices !==
      account.validate_with_uncollectible_invoices ||
    config.allow_active_subscriptions_without_payment !==
      account.allow_active_subscriptions_without_payment;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setConfig((oldConfig) => ({
      ...oldConfig,
      [e.target.id]: e.target.checked,
    }));
  };

  const handleCancel = () => setConfig(account);

  const handleSave = () => mutate(config);

  return (
    <>
      <ScreenSeparator />
      <H2>Configuración avanzada</H2>
      <Column gap="1rem">
        <Column margin="0">
          <Switch
            label="Aceptar tarjetas con falta de fondos como validas"
            id="allow_insufficient_funds"
            checked={config.allow_insufficient_funds}
            onChange={handleChange}
          />
          <Caption style={{ marginLeft: '45px' }}>
            No disponible para Decidir
          </Caption>
        </Column>
        <Switch
          label="Validar CBUs con el primer pago"
          id="validate_cbu_with_payment"
          checked={config.validate_cbu_with_payment}
          onChange={handleChange}
        />
        <Switch
          label="Validar nuevos métodos de pago con facturas incobrables"
          id="validate_with_uncollectible_invoices"
          checked={config.validate_with_uncollectible_invoices}
          onChange={handleChange}
        />
        <Switch
          label="Permitir activación de suscripciones sin primera factura paga"
          id="allow_active_subscriptions_without_payment"
          checked={config.allow_active_subscriptions_without_payment}
          onChange={handleChange}
        />
      </Column>
      <Row gap="1rem" marginTop="2rem">
        <LoadingButton
          onClick={handleSave}
          isLoading={isLoading}
          disabled={!configChanged}
        >
          {t('common.save')}
        </LoadingButton>
        {configChanged && (
          <Button variant="invisible" onClick={handleCancel}>
            {t('common.cancel')}
          </Button>
        )}
      </Row>
    </>
  );
}
