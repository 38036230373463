import { useTranslation } from 'react-i18next';
import { BusinessSettings } from './BusinessSettings';
import { CollectMethodsSettings } from '../collectMethods/CollectMethodsSettings';
import { IntegrationsSettings } from './IntegrationsSettings';
import { NotificationsSettings } from './NotificationsSettings';
import { CustomerNotificationsSettings } from './CustomerNotificationsSettings';
import { VerticalNav } from '../../components/common/VerticalNav';
import {
  PayAccessControl,
  PayAuthorizations,
} from '../authorizations/PayAccessControl';

export function SettingsScreen(): JSX.Element {
  const { t } = useTranslation();

  const SCREEN_ELEMENTS = [
    {
      title: t('screens.settings.menu.my_business'),
      path: '',
      content: (
        <PayAccessControl allowed={PayAuthorizations.manageBusiness}>
          <BusinessSettings />
        </PayAccessControl>
      ),
    },
    {
      title: t('screens.settings.menu.collect_methods'),
      path: '/collect-methods',
      content: (
        <PayAccessControl allowed={PayAuthorizations.manageCollectMethods}>
          <CollectMethodsSettings />
        </PayAccessControl>
      ),
    },
    {
      title: t('screens.settings.menu.integrations'),
      path: '/integrations',
      content: (
        <PayAccessControl allowed={PayAuthorizations.manageIntegrations}>
          <IntegrationsSettings />
        </PayAccessControl>
      ),
    },
    {
      title: t('screens.settings.menu.my_notifications'),
      path: '/notifications',
      content: (
        <PayAccessControl allowed={PayAuthorizations.manageNotifications}>
          <NotificationsSettings />
        </PayAccessControl>
      ),
    },
    {
      title: t('screens.settings.menu.customer_notifications'),
      path: '/customer-notifications',
      content: (
        <PayAccessControl allowed={PayAuthorizations.manageNotifications}>
          <CustomerNotificationsSettings />
        </PayAccessControl>
      ),
    },
  ];

  return <VerticalNav elements={SCREEN_ELEMENTS} />;
}
