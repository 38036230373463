import * as config from './config/module';

function environmentFromEnv(): config.Environment | undefined {
  if (import.meta.env.VITE_ENV === undefined) return;
  if (
    ['staging', 'production', 'sandbox', 'playground'].includes(
      import.meta.env.VITE_ENV
    )
  ) {
    return import.meta.env.VITE_ENV as config.Environment;
  }
}

export const environment: config.Environment =
  environmentFromEnv() || 'staging';

export const productName = 'pay';
export const productionUrl = 'https://pay.increase.app';

// Quick fix to type config object
// TODO: make the config type more specific
export default config[environment] as Metadata;
