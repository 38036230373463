import { useEffect, useMemo } from 'react';
import config from '../../../../config';
import md5 from 'md5';

export const loadSecurityScript = (deviceSessionId: string): Promise<void> => {
  // This script is returning HTM. The documentation is not clear about this,
  // but we may need to load this in an Iframe
  // http://developers.payulatam.com/latam/es/docs/integrations.html
  return new Promise((resolve, reject) => {
    const securityScript = document.createElement('script');
    securityScript.src = config.collectMethods.payments_os.payuSecurity.replace(
      '{{deviceSessionId}}',
      deviceSessionId
    );
    securityScript.async = true;
    securityScript.onload = () => resolve();
    securityScript.onerror = reject;
    document.body.appendChild(securityScript);
  });
};

export function usePayUFingerprint(customerId: string): string {
  const customerHash = useMemo(
    () => md5(`${customerId}${Date.now()}`),
    [customerId]
  );

  useEffect(() => {
    if (customerHash) {
      try {
        loadSecurityScript(customerHash);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('PayU security code load failed');
      }
    }
  }, [customerHash]);

  return customerHash;
}
