import i18n from '../i18n';
import { addUrlProtocol } from './utils';
import { getCountryConfig } from './countryUtils';
import { formatCurrency } from './currencyUtils';
import { entityExtrasFromApi } from './extrasUtils';
import {
  CheckoutPlan,
  FrontPlan,
  PlanCreationParams,
  PlanTier,
} from '../types';

const { t } = i18n;

export const PLAN_TYPES = {
  FLAT: 'flat',
  USAGE: 'usage',
  VARIABLEFLAT: 'variableflat',
  GRADUATED: 'graduated',
  VOLUME: 'volume',
};

export function getPlanDescription(plan: FrontPlan, locale?: string): string {
  const language = locale || getCountryConfig(plan.country).language;
  switch (plan.type) {
    case 'flat':
    case 'usage':
      return `${formatCurrency(plan.amount, plan.currency, language)} ${t(
        'data.plan.amount_type.' + plan.type
      )}`;
    case 'graduated':
    case 'volume': {
      const amountTypeLabel = t(
        `data.plan.amount_type.${plan.tiers[0].amount_type}`
      );
      return `${t(`data.plan.types.${plan.type}`)} - Desde ${formatCurrency(
        plan.tiers[0].amount,
        plan.currency,
        language
      )} ${amountTypeLabel}`;
    }
    case 'variableflat':
      return Number(plan.amount) > 0
        ? t('data.plan.amount_type.variableflat_base', {
            amount: formatCurrency(plan.amount, plan.currency, language),
          })
        : t('data.plan.amount_type.variableflat');
  }
}

export function newPlanToAPI<T extends Partial<PlanCreationParams>>(
  plan: T
): T {
  const newPlan = { ...plan };

  if (newPlan.tiers && newPlan.tiers.length > 0) {
    newPlan.amount = '1'; // Backend bug
  }
  if (newPlan.success_url) {
    newPlan.success_url = addUrlProtocol(newPlan.success_url);
  }
  return newPlan;
}

export function planFromApi<T extends CheckoutPlan>(plan: T): T {
  const newPlan = { ...plan };
  if (newPlan.collect_methods?.card) {
    newPlan.collect_methods.card = newPlan.collect_methods.card.filter(
      (cm) => cm.status === 'enabled'
    );
  }

  plan = entityExtrasFromApi(plan);

  return plan;
}

const isFlatTier = (tier: PlanTier) => tier.amount_type === 'flat';

function getTierDetail(tier: PlanTier, quantity: number): PlanCalcDetail {
  return {
    ...tier,
    maximum: isFinite(tier.maximum || Infinity) ? tier.maximum : '∞',
    tierTotal: isFlatTier(tier)
      ? Number(tier.amount)
      : Number(tier.amount) * quantity,
    tierQuantity: quantity,
  };
}

export type PlanCalcDetail = {
  maximum: string | number | undefined;
  minimum: number;
  amount: number;
  amount_type: PlanTier['amount_type'];
  tierTotal: number;
  tierQuantity: number;
};

function calcGraduated(tiers: PlanTier[], quantity: number) {
  let result = 0;
  const details: PlanCalcDetail[] = [];
  for (const currentTier of tiers) {
    // Quantity is outside tier range.
    if (currentTier.minimum > quantity) break;
    const maximum = currentTier.maximum || Infinity;
    // Get the range amount of the tier
    const currentQuantity =
      quantity <= maximum
        ? quantity - currentTier.minimum + 1
        : maximum - currentTier.minimum + 1;
    const tierDetails = getTierDetail(currentTier, currentQuantity);
    result += tierDetails.tierTotal;
    details.push(tierDetails);
  }
  return { result, details };
}

function calcVolume(tiers: PlanTier[], quantity: number) {
  const tierMatch = tiers.find(
    (t) => quantity <= (t.maximum || Infinity) && quantity >= t.minimum
  );
  if (!tierMatch) return 0;
  return isFlatTier(tierMatch)
    ? tierMatch.amount
    : Number(tierMatch.amount) * quantity;
}

export function calculatePlanAmount(
  plan: FrontPlan | CheckoutPlan,
  quantity = 0,
  amount = 0
): { result: number; details: PlanCalcDetail[] | undefined } {
  quantity = Number(quantity);
  if (Number.isNaN(quantity)) {
    return { result: 0, details: undefined };
  }
  switch (plan.type) {
    case PLAN_TYPES.VARIABLEFLAT:
      return {
        result: amount > 0 ? amount : Number(plan.amount),
        details: undefined,
      };
    case PLAN_TYPES.FLAT:
      return { result: Number(plan.amount), details: undefined };
    case PLAN_TYPES.USAGE:
      return { result: Number(plan.amount) * quantity, details: undefined };
    case PLAN_TYPES.GRADUATED:
      return calcGraduated(plan.tiers, quantity);
    case PLAN_TYPES.VOLUME:
      return { result: calcVolume(plan.tiers, quantity), details: undefined };
    default:
      return { result: 0, details: undefined };
  }
}

export function getPlanInputType(
  plan: FrontPlan | CheckoutPlan | undefined | null
): 'amount' | 'none' | 'quantity' {
  if (!plan) return 'none';
  if (plan.type === PLAN_TYPES.VARIABLEFLAT) {
    // ask for amount
    return 'amount';
  } else if (plan.type === PLAN_TYPES.FLAT) {
    // ask nothing
    return 'none';
  } else {
    return 'quantity';
  }
}

const getMonthName = (monthNumber: string) => {
  const formatter = new Intl.DateTimeFormat('es', { month: 'long' });
  return formatter.format(new Date(2023, Number(monthNumber) - 1, 1));
};

export const getChargeDateMessage = (plan: FrontPlan): string => {
  let message = '-';
  if (plan.start_month) {
    message = t('screens.edit_plan.day_month_hour_charge', {
      day: plan.start_day,
      monthName: getMonthName(plan.start_month),
    });
  } else if (plan.start_day) {
    message = t('screens.edit_plan.day_hour_charge', {
      day: plan.start_day,
    });
  }
  if (plan.start_hour) {
    message += ` a las ${plan.start_hour}:00`;
  }
  return message;
};
