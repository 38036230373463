import i18n from '../i18n';
import { Constants } from '../constants';
import { NameValue, StatusChangeEvent } from '../types';

export function loadScript(src: string, id: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.id = id;
    script.async = true;
    script.onload = () => resolve();
    script.onerror = reject;
    document.body.appendChild(script);
  });
}

export function capitalize(string: string | undefined | null): string {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function isWindows(): boolean {
  return /Windows|Win/i.test(navigator.userAgent);
}

export function callIfFunction<T>(func: unknown, ...rest: unknown[]): T {
  return typeof func === 'function' ? func.apply(func, rest) : func;
}

export function inIframe(): boolean {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function sendMessage(type: string, payload?: Metadata): void {
  if (inIframe()) {
    window.parent.postMessage(
      {
        type,
        payload,
      },
      '*'
    );
  } else {
    // eslint-disable-next-line no-console
    console.warn(
      "We are not loaded inside an iframe. PostMessage won't have any effect"
    );
  }
}

export function redirectTo(url: string): void {
  window.location.href = url;
}

export function arrayDifference<T>(
  array1: T[],
  array2: T[],
  key?: keyof T
): T[] {
  if (key) {
    return array2.filter(
      (element2) => !array1.some((element1) => element1[key] === element2[key])
    );
  } else {
    return array2.filter((element2) => !array1.includes(element2));
  }
}

// { name, id } ...to... { name, value }
export function objectsList2NameValueList(
  list: Metadata[],
  allValuesId?: string,
  nameKey = 'name',
  valueKey = 'id',
  nameFunction?: (item: Metadata) => string
): NameValue[] {
  if (!list) {
    return [];
  }

  const nameValue = list.map((item) => ({
    name: nameFunction ? nameFunction(item) : item[nameKey],
    value: item[valueKey],
  }));

  if (allValuesId) {
    nameValue.unshift({
      name: i18n.t(allValuesId),
      value: '*',
    });
  }
  return nameValue;
}

export function list2NameValue(
  list: string[],
  itemTranslatePrefix: string,
  allValuesId?: string
): NameValue[] {
  if (!list) {
    return [];
  }
  const nameValue = [];
  if (allValuesId) {
    nameValue.push({
      name: i18n.t(allValuesId),
      value: '*',
    });
  }
  list.forEach((item) => {
    nameValue.push({
      name: i18n.t(`${itemTranslatePrefix}.${item}`),
      value: item,
    });
  });
  return nameValue;
}

/** Returns text up to LIMIT and add ellipsis at the end. */
export const getShortText = (
  text: string,
  LIMIT = Constants.CELL_TEXT_MAX_SIZE_CHARS
): string => {
  if (!text) return '';
  const ELLIPSIS = '...';
  const shortenString = (str: string) => str.substring(0, LIMIT) + ELLIPSIS;
  const textExceedLimit = text.length > LIMIT;
  return textExceedLimit ? shortenString(text) : text;
};

/** Prepends 'https://"' if url doesn't start with 'http://"' or 'https://"' */
export function addUrlProtocol(url: string): string {
  const HTTPS_PROTOCOL = 'https://';
  const HTTP_PROTOCOL = 'http://';

  if (!url) return '';

  if (url.startsWith(HTTP_PROTOCOL) || url.startsWith(HTTPS_PROTOCOL)) {
    return url;
  }

  return HTTPS_PROTOCOL + url;
}

type IDObject = { id: string };

/** Compares object ids of originalList vs newList
 *  returns true if are different, otherwise false */
export function areListsDifferent(
  originalList: IDObject[],
  newList: IDObject[]
): boolean {
  // TODO: refactor to use arrayDifference or remove completely
  const getIds = (list: IDObject[]) => list.map((element) => element.id);
  const originalIds = getIds(originalList);
  const newIds = getIds(newList);

  return (
    originalList.length !== newList.length ||
    originalIds.some((id) => !newIds.includes(id))
  );
}

export function numberCompactFormat(value: number): string {
  return new Intl.NumberFormat(navigator.language, {
    notation: 'compact',
    compactDisplay: 'short',
  }).format(value);
}

export function getCustomerName<
  T extends { first_name?: string; last_name?: string; email: string },
>(value: T, options = { getEmailWhenNameIsEmpty: true }): string {
  const { getEmailWhenNameIsEmpty } = options;
  const customerName = [value.first_name, value.last_name].join(' ').trim();
  if (!customerName && getEmailWhenNameIsEmpty) return value.email;
  return customerName;
}

export function getStatusChangeEvents(
  events: StatusChangeEvent[]
): StatusChangeEvent[] {
  if (!events || events.length < 1) {
    return events;
  }
  // filter events whose status from and to are equal and there is no message
  return events.filter((event: StatusChangeEvent) => !!event.message);
}

export function toArray<T>(value: T): T | T[] {
  return Array.isArray(value) ? value : [value];
}

export function nativeUniqBy<T>(array: T[], iteratee: (i: T) => string): T[] {
  const seen = new Set();
  return array.filter((item) => {
    const key = iteratee(item);
    if (!seen.has(key)) {
      seen.add(key);
      return true;
    }
    return false;
  });
}
