import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { DateInput, Grid } from '@increasecard/typed-components';
import { ALL_COUNTRIES } from '../../../localizations';
import { getCountryConfig } from '../../../utils/countryUtils';
import { list2NameValue } from '../../../utils/utils';
import { DiscountSchema } from './DiscountSchema';
import {
  InputTextField,
  InputNumberField,
  SelectField,
  CheckboxField,
} from '../../../components/common/FormikFields';
import { Discount } from '../../../types/Discount';
import { Country } from '../../../types/common';
import { ScreenSeparator } from '../../../components/common/ScreenSeparator';
import {
  AmountTypeConfig,
  DurationConfig,
  FooterButtons,
} from '../FormSections';

export type DiscountFormValues = Partial<Discount>;

export interface DiscountFormProps {
  initialValues: DiscountFormValues;
  onSubmit: (otc: DiscountFormValues) => void;
  onCancel: () => void;
  isLoading: boolean;
  editMode?: boolean;
}
const MIN_DATE = new Date();
export function DiscountForm({
  initialValues,
  onSubmit,
  onCancel,
  editMode = false,
  isLoading,
}: DiscountFormProps): JSX.Element {
  const { t } = useTranslation();
  // All this definitions should be outside the component
  // But it seems that the translation file is not loaded
  // before component execution.
  const COUNTRY_OPTIONS = list2NameValue(ALL_COUNTRIES, 'data.countries');

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={DiscountSchema(t)}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, setFieldValue, values }) => (
        <Grid as="form" onSubmit={handleSubmit} rowGap={2}>
          <InputTextField
            className="span-desktop-9"
            id="name"
            autoComplete="off"
            label={values.is_coupon ? t('common.coupon') : t('common.name')}
            disabled={editMode}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFieldValue(
                'name',
                values.is_coupon ? e.target.value.toUpperCase() : e.target.value
              )
            }
          />
          <div className="span-desktop-3" style={{ paddingTop: '24px' }}>
            <CheckboxField
              id="is_coupon"
              label="Es cupón"
              disabled={editMode}
            />
          </div>
          <InputTextField
            as="textarea"
            id="description"
            height="100px"
            autoComplete="off"
            placeholder={t('common.placeholders.description')}
            label={t('common.description')}
          />
          <InputTextField
            className="span-desktop-6"
            id="external_id"
            autoComplete="off"
            placeholder={t('common.optional')}
            label={t('common.external_id')}
          />
          <SelectField
            id="country"
            className="span-desktop-6"
            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
              const value = e.target.value as Country;
              setFieldValue(e.target.name, value);
              const { currencies } = getCountryConfig(value);
              setFieldValue('currency', currencies[0]);
            }}
            options={COUNTRY_OPTIONS}
            label={t('common.country')}
            disabled={editMode}
          />
          <ScreenSeparator margin="0" />
          {values.is_coupon ? (
            <>
              <DateInput
                id="valid_from_at"
                label={t('common.valid_from')}
                className="span-desktop-4"
                datepickerProps={{ minDate: MIN_DATE }}
                value={values.valid_from_at}
                emptyMessage={editMode ? 'Sin fecha de inicio' : 'Hoy'}
                onChange={(newDate) => {
                  setFieldValue('valid_from_at', newDate.toISOString());
                }}
              />
              <DateInput
                id="valid_until_at"
                label={t('common.valid_until')}
                className="span-desktop-4"
                value={values.valid_until_at}
                emptyMessage="Sin vencimiento"
                onChange={(newDate) => {
                  setFieldValue('valid_until_at', newDate.toISOString());
                }}
              />
              <InputNumberField
                className="span-desktop-2"
                id="total_coupons"
                label={t('common.quota')}
                placeholder="Sin límite de cupo"
                step="1"
                min="1"
                disabled={values.total_coupons === null}
              />
              <div className="span-desktop-2" style={{ paddingTop: '28px' }}>
                <CheckboxField
                  size="small"
                  id="total_coupons_check"
                  label="Sin límite"
                  checked={values.total_coupons === null}
                  onChange={(e) =>
                    e.target.checked
                      ? setFieldValue('total_coupons', null)
                      : setFieldValue('total_coupons', 1)
                  }
                />
              </div>
            </>
          ) : null}
          <AmountTypeConfig editMode={editMode} />
          <DurationConfig editMode={editMode} />
          <FooterButtons onCancel={onCancel} isLoading={isLoading} />
        </Grid>
      )}
    </Formik>
  );
}
