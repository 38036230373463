import { Currency } from '../types/common';
import {
  SummaryMetrics,
  PaymentStatusShareMetrics,
  SubscriptionMetrics,
  SubscriptionByStatus,
  RetryPerformanceMetrics,
} from '../types/Metrics';
import { apiFront } from './api';

export interface FetchSummaryMetrics {
  createdAtFrom?: Date;
  createdAtTo?: Date;
  currency?: Currency | null;
}

export async function fetchSummaryMetrics({
  createdAtFrom,
  createdAtTo,
  currency,
}: FetchSummaryMetrics): Promise<SummaryMetrics> {
  const response = await apiFront.get('metrics/payments', {
    params: {
      created_at_from: createdAtFrom,
      created_at_to: createdAtTo,
      currency: currency,
    },
  });
  return response.data;
}

export async function fetchMonthlySubscriptions(): Promise<SubscriptionMetrics> {
  const response = await apiFront.get('metrics/subscriptions');
  return response.data;
}

export async function fetchPaymentStatusShareByMonth(
  currency?: Currency | null
): Promise<PaymentStatusShareMetrics> {
  const response = await apiFront.get(
    'metrics/payments_status_share_by_month',
    {
      params: { currency },
    }
  );
  // Sometimes some items comes nested.
  response.data.data = response.data.data.flat();
  return response.data;
}

export async function fetchRetryPerformanceChart(
  currency?: Currency | null
): Promise<RetryPerformanceMetrics> {
  const response = await apiFront.get('metrics/payments_approved_by_retries', {
    params: { currency },
  });
  return response.data;
}

export async function fetchSubscriptionsByStatus(
  currency?: Currency | null
): Promise<SubscriptionByStatus[]> {
  const response = await apiFront.get('metrics/subscriptions_by_status', {
    params: {
      currency,
    },
  });
  return response.data;
}
