import { SupervielleSchema } from '../CollectMethodsSchema';
import { TFunction } from 'i18next';
import { getDefaultValues, PanelFormConfig } from './PanelConfig';
import { SupervielleCollectMethod } from '../../../types/CollectMethod';

export function superviellePanelConfig(
  translate: TFunction,
  collectMethod?: SupervielleCollectMethod
): PanelFormConfig {
  const formData = {
    name: 'Banco Supervielle',
    country: 'ARG',
    metadata: {
      cuit: '',
      id_size: '',
      company_code: '',
    },
  };

  const initialValues = getDefaultValues(formData, collectMethod);
  const transBase = 'screens.collect_methods.supervielle';
  return {
    type: 'supervielle',
    name: 'Banco Supervielle',
    description: translate(`${transBase}.description`),
    initialValues,
    status: collectMethod?.status,
    inputs: [
      {
        label: translate(`${transBase}.cuit`),
        id: 'metadata.cuit',
        type: 'text',
      },
      {
        label: translate(`${transBase}.id_size`),
        id: 'metadata.id_size',
        type: 'text',
      },
      {
        label: translate(`${transBase}.company_code`),
        id: 'metadata.company_code',
        type: 'text',
      },
    ],
    validationSchema: () => SupervielleSchema(translate),
  };
}
