import {
  dLocal as Dlocal,
  MercadoPagoLogo,
  PayU,
  Supervielle,
} from '@increasecard/icons';
import {
  CardCollectMethod,
  CbuCollectMethod,
  CollectMethod,
  GroupedCollectMethods,
  TicketCollectMethod,
} from '../types/CollectMethod';
import { PaymentMethod } from '../types/PaymentMethod';
import comafiLogo from '../components/common/assets/comafi-logo.png';
import decidirLogo from '../components/common/assets/decidir-logo.png';

export function isCard(cm: CollectMethod): cm is CardCollectMethod {
  return cm.payment_categories.includes('card');
}

export function isCbu(cm: CollectMethod): cm is CbuCollectMethod {
  return cm.payment_categories.includes('cbu');
}

export function isTicket(cm: CollectMethod): cm is TicketCollectMethod {
  return cm.payment_categories.includes('ticket');
}

export function isDefault(cm: CollectMethod): boolean {
  return cm.default;
}

export function groupCollectMethods(
  cmList: CollectMethod[]
): GroupedCollectMethods {
  return {
    card: cmList.filter(isCard),
    cbu: cmList.filter(isCbu),
    ticket: cmList.filter(isTicket),
  };
}
export function hasCollectMethods(
  groupedCM: GroupedCollectMethods | undefined
): groupedCM is GroupedCollectMethods {
  if (!groupedCM) {
    return false;
  }

  return Object.values(groupedCM).flat().length > 0;
}

export function filterPaymentMethodsByCollectMethods(
  groupedCollectMethods: GroupedCollectMethods | undefined,
  paymentMethods: PaymentMethod[]
): PaymentMethod[] {
  if (!groupedCollectMethods) {
    return paymentMethods;
  }
  const collectMethodIds = Object.values(groupedCollectMethods)
    .flat(1)
    .map((cm) => cm.id);

  return paymentMethods.filter((paymentMethod) =>
    collectMethodIds.includes(paymentMethod.collect_method_id)
  );
}

export function getCollectMethodsNames(
  groupedCollectMethods: GroupedCollectMethods | undefined
): string {
  if (!groupedCollectMethods) {
    return '';
  }

  return Object.values(groupedCollectMethods)
    .flat()
    .map((cm) => cm.name)
    .join(', ');
}

function ComafiLogo(): JSX.Element {
  // Import result is the URL of your image
  return <img src={comafiLogo} width="160px" alt="Logo" />;
}

function DecidirLogo(): JSX.Element {
  // Import result is the URL of your image
  return <img src={decidirLogo} height="30px" alt="Logo" />;
}

function SupervielleLogo(): JSX.Element {
  // Import result is the URL of your image
  return <Supervielle height="40px" />;
}

function DlocalLogo(): JSX.Element {
  // Import result is the URL of your image
  return <Dlocal height="30px" />;
}

function Mercadopago(): JSX.Element {
  // Import result is the URL of your image
  return <MercadoPagoLogo height="30px" />;
}

export const CollectMethodsLogos: Record<
  Exclude<CollectMethod['type'], 'mercadopago'>,
  React.ComponentType<React.SVGProps<SVGSVGElement>>
> = {
  comafi: ComafiLogo,
  supervielle: SupervielleLogo,
  decidir: DecidirLogo,
  dlocal: DlocalLogo,
  dlocal_cross: DlocalLogo,
  mercadopago_connect: Mercadopago, // Agregator
  payments_os: PayU,
};
