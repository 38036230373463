import { ChangeEvent, useState } from 'react';
import { InputSearch } from '@increasecard/typed-components';
import { Constants } from '../../../constants';
import { Row } from '../../../components/common/Row';
import { useTranslation } from 'react-i18next';
import { ApiErrorMessage } from '../../../components/ErrorMessage';
import {
  ColumnDefinition,
  DataTable,
} from '../../../components/common/DataTable/DataTable';
import { useCustomers } from '../customerQueries';
import { Customer } from '../../../types/Customer';
import { useCustomerActions } from '../useCustomerActions';
import { ExportButton } from '../../../components/common/ExportButton';
import { ConfirmDeleteModal } from './ConfirmDeleteModal';

export function CustomersList(): JSX.Element {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const { isLoading, data, error } = useCustomers({
    page,
    search: searchValue,
    per_page: Constants.LARGE_PAGE_SIZE,
  });

  const handleCustomerSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value;
    setSearchValue(searchValue);
  };
  const { actions, goToDetails, deleteModal } =
    useCustomerActions('customer screen');

  // error
  if (error) {
    return <ApiErrorMessage error={error} />;
  }

  const hasData = data?.data ? data?.data.length > 0 : false;
  const tableColumns: ColumnDefinition[] = [
    {
      field: '', // Left empty to use the entire object as the field
      type: 'customer',
      headerName: t('common.customer'),
    },
    {
      field: 'tax_id',
      headerName: t('common.tax_or_id'),
    },
    {
      field: 'billing_info.phone',
      headerName: t('common.phone'),
    },
    {
      field: 'payment_methods[0]',
      type: 'payment_method',
      headerName: t('common.method'),
    },
    {
      field: 'created_at',
      headerName: t('common.created_at'),
      type: 'date',
    },
  ];

  // render
  return (
    <>
      <Row justifyContent="space-between" alignItems="flex-end">
        <InputSearch
          width="300px"
          id="customer_id"
          label={t('common.search')}
          onChange={handleCustomerSearchChange}
          placeholder={t('screens.customers.search_customer')}
        />
        <ExportButton path="customers" pathFilters={{ search: searchValue }} />
      </Row>
      <DataTable
        tableProps={{ zebra: true, hasData }}
        rowHeight="double"
        columns={tableColumns}
        pageSize={Constants.LARGE_PAGE_SIZE}
        isLoading={isLoading}
        rows={data?.data}
        onRowClick={(customer: Customer) => {
          goToDetails(customer.id, 'list row click');
        }}
        menuActions={actions}
        paginatorProps={{
          pages: data?.total_pages,
          selected: Number(data?.current_page),
          onSelectPage: setPage,
        }}
      />
      <ConfirmDeleteModal
        visible={deleteModal.isOpen}
        customer={deleteModal.data}
        onClose={deleteModal.close}
      />
    </>
  );
}
