import { Trans, useTranslation } from 'react-i18next';
import { Import } from '../../components/imports/Import';
import { ImportType } from '../../types/ImportFile';

export function ImportSubscriptionsScreen(): JSX.Element {
  const { t } = useTranslation();

  const name = {
    name: t('common.subscriptions'),
  };
  const description = (
    <Trans t={t} i18nKey="screens.import_subscriptions.description" />
  );

  return (
    <Import
      type={ImportType.Subscription}
      title={t('screens.import.title', name)}
      importButtonText={t('screens.import.import', name)}
      templateUrl="/upload_templates/subscriptions_template.csv"
      helpCenterUrl="https://www.notion.so/increase-app/C-mo-cargar-suscripciones-de-forma-masiva-d6ee9c402f9a466c95480edb129ff2f2"
      description={description}
    />
  );
}
