import { useTranslation } from 'react-i18next';
import { DetailsBox, RowDefinition } from '../../components/common/DetailsBox';
import { Customer } from '../../types/Customer';

interface BillingInfoDetailProps {
  customer: Customer;
}

export function BillingInfoDetail({
  customer,
}: BillingInfoDetailProps): JSX.Element {
  const { t } = useTranslation();
  const detailRows: RowDefinition<Customer>[] = [
    {
      label: t('common.country'),
      type: 'string',
      field: 'billing_info.country',
      valueGetter: () => t(`data.countries.${customer.billing_info.country}`),
    },
    {
      label: t('common.tax_id'),
      type: 'string',
      field: 'tax_id',
      valueGetter: () => customer.tax_id,
    },
    {
      label: t('common.created_at'),
      type: 'date',
      field: 'created_at',
    },
    {
      label: t('common.external_id'),
      type: 'string',
      field: 'external_id',
    },
    {
      label: t('screens.customer_form.invoice_data'),
      type: 'string',
      field: 'billing_info',
      valueGetter: () => {
        return `${customer.billing_info.address_line_1 ?? ''} ${
          customer.billing_info.state ?? ''
        } ${
          customer.billing_info.country
            ? t(`data.countries.${customer.billing_info.country}`)
            : ''
        } ${customer.billing_info.phone ?? ''}`;
      },
    },
  ];
  return (
    <DetailsBox
      className="grid-span-desktop-7 grid-span-mobile-12"
      title={t('common.details')}
      data={customer}
      rowsConfig={detailRows}
    />
  );
}
