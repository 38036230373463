import { H2, TableWrapper } from '@increasecard/typed-components';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '../../components/ErrorMessage';
import { HelpButton } from '../../components/common/HelpButton';
import { Row } from '../../components/common/Row';
import { FullPageSpinner } from '../../components/common/FullPageSpinner';
import { CHART_TOOLTIP_STYLE, getYAxisLabel } from './helpers';
import { useMonthlyCreatedSubscriptions } from './summaryQueries';

const LINE_COLOR = '#00485b';

export function MonthlySubscriptionsChart(): JSX.Element {
  const { t } = useTranslation();
  const {
    error,
    isLoading,
    data: monthlySubscriptions,
    refetch,
  } = useMonthlyCreatedSubscriptions();

  const translationKey = 'metrics.monthlySubscriptions';

  const yAxisLabel = getYAxisLabel(t(`${translationKey}.yAxis`));
  const title = t(`${translationKey}.title`);
  return (
    <div>
      <Row alignItems="center" gap="0.5rem" margin="0 0 0.5rem">
        <H2>{title}</H2>
        <HelpButton
          titleKey={title}
          descriptionKey={`${translationKey}.help.description`}
          noteKey={`${translationKey}.help.note`}
        />
      </Row>
      <TableWrapper>
        {error && (
          <ErrorMessage onClick={() => refetch}>
            {t('api.errors.unexpected_error')}
          </ErrorMessage>
        )}
        {isLoading && <FullPageSpinner height="270px" />}

        {monthlySubscriptions && (
          <ResponsiveContainer height={270}>
            <LineChart data={monthlySubscriptions}>
              <XAxis
                dataKey="time"
                padding={{ left: 30, right: 30 }}
                tick={{ dy: 8, fontSize: 13 }}
              />
              <YAxis tick={{ fontSize: 13 }} label={yAxisLabel} />
              <Tooltip
                {...CHART_TOOLTIP_STYLE}
                isAnimationActive={false}
                active={true}
                formatter={(value: string) => [
                  value,
                  t('common.total') as string,
                ]}
              />
              <Line
                dataKey="value"
                stroke={LINE_COLOR}
                strokeWidth={2}
                dot={{ stroke: LINE_COLOR, strokeWidth: 4, r: 5 }}
                activeDot={{ stroke: LINE_COLOR, strokeWidth: 4, r: 4 }}
                isAnimationActive={false}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore: recharts type definitions do not support label prop ¯\_(ツ)_/¯
                label={{
                  fontSize: 13,
                  fontWeight: 'bold',
                  dy: -16,
                }}
              />
              <CartesianGrid strokeDasharray="3 5" vertical={false} />
            </LineChart>
          </ResponsiveContainer>
        )}
      </TableWrapper>
    </div>
  );
}
