import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { FullPageSpinner } from '../../components/common/FullPageSpinner';
import { GoBackButton } from '../../components/common/GoBackButton';
import { Header } from '../../components/common/Header';
import { ScreenSeparator } from '../../components/common/ScreenSeparator';
import { ApiErrorMessage } from '../../components/ErrorMessage';
import { useScrollToTopOnMount } from '../../hooks/useScrollToTopOnMount';
import { DebtInvoiceParams } from '../../types';
import { InvoiceProcessModal } from '../invoice/components/InvoiceProcessModal';
import { useSubscriptionById } from '../subscription/subscriptionQueries';
import { DebtInvoiceForm } from './DebtInvoiceForm';
import { useCreateDebtInvoice, useDebtItems } from './debtQueries';

export function DebtInvoiceScreen(): JSX.Element {
  useScrollToTopOnMount();
  const { goBack } = useHistory();
  const { subscription_id } = useParams<{ subscription_id: string }>();
  const debtItems = useDebtItems({ subscriptionId: subscription_id });
  const {
    data: subscription,
    isLoading,
    error,
  } = useSubscriptionById(subscription_id);
  const debtInvoice = useCreateDebtInvoice();
  const { t } = useTranslation();

  if (debtItems.isLoading || !debtItems.data || isLoading || !subscription)
    return <FullPageSpinner />;

  if (error || debtItems.error)
    return <ApiErrorMessage error={error || debtItems.error} />;

  const handleSubmit = (debtInvoiceValues: DebtInvoiceParams) => {
    debtInvoice.mutate({ ...debtInvoiceValues, subscription_id });
  };

  return (
    <>
      <GoBackButton />
      <Header
        title={t('screens.debt_invoice.title')}
        description={t('screens.debt_invoice.subscription_desc')}
      />
      <ScreenSeparator />
      <DebtInvoiceForm
        onSubmit={handleSubmit}
        onCancel={goBack}
        customer={subscription.customer}
        collectMethods={subscription.plan.collect_methods}
        country={subscription.plan.country}
        currency={subscription.plan.currency}
        debtItems={debtItems.data}
        isSubmitting={debtInvoice.isLoading}
      />
      <InvoiceProcessModal
        onClose={debtInvoice.reset}
        onOk={goBack}
        visible={
          debtInvoice.isLoading || debtInvoice.isSuccess || debtInvoice.isError
        }
        invoice={debtInvoice.data}
        isProcessing={debtInvoice.isLoading}
        error={debtInvoice.error}
      />
    </>
  );
}
