import { H2, Paragraph } from '@increasecard/typed-components';
import { useTranslation } from 'react-i18next';
import { ExportButton } from '../../../components/common/ExportButton';
import { Column } from '../../../components/common/Column';

const subscriptionFilter = {
  report_type: 'subscription_variable_plans',
  report_format: 'csv',
};

const prefix = 'screens.import_variable_subscriptions';

export function ExportStep(): JSX.Element {
  const { t } = useTranslation();
  return (
    <Column gap="16px">
      <H2 style={{ marginBottom: '0.5rem' }}>{t(`${prefix}.step_1_title`)}</H2>
      <ExportButton
        buttonType="outline"
        showIcon={false}
        path="subscriptions"
        pathFilters={subscriptionFilter}
      />
      <Paragraph>{t(`${prefix}.step_1_desc`)}</Paragraph>
    </Column>
  );
}
