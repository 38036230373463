import { useState, useEffect } from 'react';
import { Constants } from '../../../constants';
import { COUNTRIES } from '../../../localizations';
import {
  getIdentificationTypes,
  getInstallments,
  getPaymentMethod,
  clearSession,
  createToken,
  getAllPaymentMethods,
} from './MpApiClient';
import { cardBrandsIcons } from '../../../utils/paymentUtils';
import { reportError } from '../../../utils/errorTracker';
import { useUnsupportedPaymentMethods } from '../useUnsupportedPaymentMethods';
import { NewApiError } from '../../../types';

const MP_PROCESSING_MODES = {
  AGGREGATOR: 'aggregator',
  GATEWAY: 'gateway',
};
const COMMON_MP_CARDS = ['visa', 'mastercard', 'amex', 'diners'];
const AGGREGATOR_SUPPORTED_CARDS = {
  [COUNTRIES.ARGENTINA]: [
    ...COMMON_MP_CARDS,
    'cabal',
    'nativa',
    'tarjetashopping',
    'argencard',
    'cmr',
    'cencosud',
    'cordobesa',
  ],
  [COUNTRIES.PERU]: COMMON_MP_CARDS,
  [COUNTRIES.CHILE]: [...COMMON_MP_CARDS, 'cmr', 'magna', 'presto'],
  [COUNTRIES.COLOMBIA]: [...COMMON_MP_CARDS, 'codensa', 'debvisa', 'debmaster'],
};

export const PAYMENT_TYPE_ID = {
  DEBIT_CARD: 'debit_card',
  CREDIT_CARD: 'credit_card',
  TICKET: 'ticket',
  ATM: 'atm',
  ACCOUNT_MONEY: 'account_money',
};

const getProcessingMode = (collectMethodType) => {
  const { AGGREGATOR, GATEWAY } = MP_PROCESSING_MODES;
  return collectMethodType ===
    Constants.CollectMethods.CARD.MERCADOPAGO_AGGREGATOR
    ? AGGREGATOR
    : GATEWAY;
};

const getMPError = (MPError) => {
  if (
    MPError?.cause &&
    Array.isArray(MPError.cause) &&
    MPError.cause.length > 0
  ) {
    return NewApiError.fromAPI({
      code: MPError.cause[0].code,
      message: MPError.cause[0].message,
    });
  } else {
    reportError('MercadoPago unexpected error format', MPError);
    return NewApiError.fromAPI({
      code: 'unexpected_error',
      message: 'unexpected_error',
    });
  }
};

export const useIdentificationTypes = () => {
  const [idTypes, setIdTypes] = useState(null);

  useEffect(() => {
    async function fetchIdentificationTypes() {
      const idTypes = await getIdentificationTypes();
      setIdTypes(idTypes);
    }

    fetchIdentificationTypes();
  }, []);

  return idTypes;
};

/** useUserCardInfo
 *  Returns:
 *  - processingMode -> "gateway" or "aggregator" depending on collectMethods
 *  - agreement -> "gateway" processing mode returns this.
 */
export const useUserCardInfo = (bin, collectMethods, isUniquePayment) => {
  const [error, setError] = useState(null);
  const [paymentMethodDetail, setPaymentMethodDetail] = useState({});
  const processingMode = getProcessingMode(collectMethods.type);
  const { isPaymentMethodSupported } = useUnsupportedPaymentMethods({
    collectMethodType:
      processingMode === MP_PROCESSING_MODES.AGGREGATOR
        ? 'CollectMethods::MercadopagoConnect'
        : 'CollectMethods::Card::Mercadopago',
    country: collectMethods.country,
    isUniquePayment,
  });

  useEffect(() => {
    const reset = () => {
      setPaymentMethodDetail({});
      setError(null);
    };

    if (bin.length < 6) reset();

    if (bin.length === 6) {
      const isSupported = isPaymentMethodSupported(bin);
      if (!isSupported.supported) {
        setError(isSupported.error);
      }
      Promise.all([getInstallments(bin), getPaymentMethod(bin)])
        .then((values) => {
          const [installments, paymentMethodDetails] = values;
          setPaymentMethodDetail(paymentMethodDetails[0]);
          // get all merchants
          const merchants = installments.filter(
            (m) => m.processing_mode === processingMode
          );

          if (merchants.length === 0) {
            reset();
          }
        })
        .catch((error) => {
          if (error.status && error.status === 404) {
            setError('unsupported_card_brand');
          } else {
            setError('unexpected_error');
          }
        });
    }
  }, [bin, collectMethods, processingMode, isPaymentMethodSupported]);
  return { processingMode, paymentMethodDetail, error };
};

export const createCardToken = (
  form,
  processingMode,
  collectMethods,
  onError
) => {
  const { AGGREGATOR } = MP_PROCESSING_MODES;

  var extraData;
  if (processingMode === AGGREGATOR) {
    extraData = {
      collect_method_id: collectMethods.id,
    };
  }

  // Re generating a token throws an error, even for new credit card numbers.
  // For now clearing the session is the only solution.
  clearSession();
  return createToken(form)
    .then((data) => {
      onError(null);
      return {
        token: data.id,
        cardholder_name: data.cardholder ? data.cardholder.name : '',
        expiration_year: data.expiration_year,
        expiration_month: data.expiration_month,
        first_six_digits: data.first_six_digits,
        last_four_digits: data.last_four_digits,
        type: Constants.PaymentMethods.CARD,
        ...extraData,
      };
    })
    .catch((error) => onError(getMPError(error)));
};

export const getAggregatorSupportedCardsIcons = (countryCode) => {
  return AGGREGATOR_SUPPORTED_CARDS[countryCode]
    .map((brand) => cardBrandsIcons[brand])
    .filter((value) => value);
};

export const useGetSupportedCards = (collectMethods) => {
  const processingMode = getProcessingMode(collectMethods.type);
  if (processingMode === MP_PROCESSING_MODES.AGGREGATOR)
    return getAggregatorSupportedCardsIcons(collectMethods.country);

  return null;
};

export const useGetPaymentMethodsByType = (type) => {
  const [paymentMethodsByType, setPaymentMethodsByType] = useState([]);
  useEffect(() => {
    async function fetchPaymentMethods() {
      const paymentMethods = await getAllPaymentMethods();
      setPaymentMethodsByType(
        paymentMethods.filter((pm) => pm.payment_type_id === type)
      );
    }
    fetchPaymentMethods();
  }, [type]);

  return paymentMethodsByType;
};
