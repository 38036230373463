import {
  Modal,
  ModalHeader,
  ModalHeaderIconBox,
} from '@increasecard/typed-components';
import {
  CancelIcon,
  PauseCircleIcon,
  PlayCircleIcon,
  MdiCheckCircleIcon,
  MonetizationOn,
} from '@increasecard/icons';
import { useTranslation } from 'react-i18next';
import { Subscription } from '../../../types/Subscription';
import { PauseModalContent } from './PauseModalContent';
import { CancelModalContent } from './CancelModalContent';
import { ActivationModalContent } from './ActivationModalContent';
import { BillingDateModalContent } from './BillingDateModalContent';
import { ApproveModalContent } from './ApproveModalContent';
import { RejectModalContent } from './RejectModalContent';
import { PaymentMethodRequestModalContent } from './PaymentMethodRequestModalContent';

const noop = () => {
  return;
};
export type SubscriptionActionTypes =
  | 'approve'
  | 'reject'
  | 'pause'
  | 'cancel'
  | 'activate'
  | 'change_billing_date'
  | 'ask_payment_method';

export interface ActionsModalProps {
  visible: boolean;
  onClose: VoidFunction;
  onExited?: VoidFunction;
  onSuccess?: VoidFunction;
  subscription: Subscription[] | undefined;
  actionType: SubscriptionActionTypes | undefined;
}

function HeaderIcon({ type }: { type: ActionsModalProps['actionType'] }) {
  switch (type) {
    case 'pause':
      return <PauseCircleIcon height="48" width="48" />;
    case 'activate':
      return <PlayCircleIcon height="48" width="48" />;
    case 'cancel':
    case 'reject':
      return <CancelIcon height="48" width="48" />;
    case 'change_billing_date':
      return <MonetizationOn height="48" width="48" />;
    case 'ask_payment_method':
    case 'approve':
      return <MdiCheckCircleIcon height="48" width="48" />;
    default:
      return null;
  }
}

export function ActionsModal({
  visible,
  onClose,
  onExited,
  onSuccess,
  subscription,
  actionType,
}: ActionsModalProps): JSX.Element | null {
  const { t } = useTranslation();
  const getContent = () => {
    if (!subscription || !actionType) return null;
    switch (actionType) {
      case 'approve':
        return (
          <ApproveModalContent
            subscription={subscription}
            onClose={onClose}
            onSuccess={onSuccess}
          />
        );
      case 'reject':
        return (
          <RejectModalContent
            subscription={subscription}
            onClose={onClose}
            onSuccess={onSuccess}
          />
        );
      case 'pause':
        return (
          <PauseModalContent
            subscription={subscription}
            onClose={onClose}
            onSuccess={onSuccess}
          />
        );
      case 'cancel':
        return (
          <CancelModalContent
            subscription={subscription}
            onClose={onClose}
            onSuccess={onSuccess}
          />
        );
      case 'activate':
        return (
          <ActivationModalContent
            subscription={subscription}
            onClose={onClose}
            onSuccess={onSuccess}
          />
        );
      case 'change_billing_date':
        return (
          <BillingDateModalContent
            subscription={subscription}
            onClose={onClose}
            onSuccess={onSuccess}
          />
        );
      case 'ask_payment_method':
        return (
          <PaymentMethodRequestModalContent
            subscription={subscription}
            onClose={onClose}
            onSuccess={onSuccess}
          />
        );
      default:
        return 'No content for action type';
    }
  };
  return (
    <Modal
      align="center"
      onClose={onClose}
      onOk={noop}
      onCancel={noop}
      visible={visible}
      transitionProps={{
        onExited,
      }}
      width="569"
    >
      <ModalHeaderIconBox
        colorPalette="pay"
        icon={<HeaderIcon type={actionType} />}
      />
      <ModalHeader align="center">
        {t(`screens.subscriptions.${actionType}.title`, {
          count: subscription ? subscription.length : 1,
        })}
      </ModalHeader>
      {getContent()}
    </Modal>
  );
}
