import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Button } from '@increasecard/typed-components';
import { ScreenSeparator } from '../../components/common/ScreenSeparator';
import { GoBackButton } from '../../components/common/GoBackButton';
import { Header } from '../../components/common/Header';
import { Row } from '../../components/common/Row';
import { CustomerSelector } from '../customers/CustomerSelector';
import { useHistory } from 'react-router';
import { Customer } from '../../types';

export function NewDebtInvoiceScreen(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const [customer, setCustomer] = useState<Customer>();

  const handleCustomerSelect = () => {
    history.push(`/customers/${customer?.id}/new-debt-invoice`);
  };

  return (
    <>
      <GoBackButton />
      <Header
        title={t('screens.new_invoice.title')}
        description={t('screens.new_invoice.description')}
      />
      <ScreenSeparator />
      <Grid rowGap={2}>
        <CustomerSelector
          titleHidden={true}
          initialCustomerId={customer?.id}
          onChange={(customer) => setCustomer(customer)}
        />
        <Row marginTop="2rem" gap="1rem" alignItems="center">
          <Button disabled={!customer} onClick={() => handleCustomerSelect()}>
            {t('common.continue')}
          </Button>
          <GoBackButton hideIcon>{t('common.cancel')}</GoBackButton>
        </Row>
      </Grid>
    </>
  );
}
