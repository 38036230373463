import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { useParams } from 'react-router';
import {
  fetchPublishedDebt,
  createGroupedInvoice,
  CreateGroupedInvoiceParams,
  cancelGroupedInvoice,
} from '../../../services/CheckoutService';
import { CheckoutInvoice, NewApiError, PaginatedData } from '../../../types';
import { useRecaptcha } from './ReCaptchaContext';

export function usePublishedDebt(
  params?: Metadata
): UseQueryResult<PaginatedData<CheckoutInvoice>, NewApiError> {
  const recaptcha = useRecaptcha();
  const { account_id } = useParams<{ account_id: string }>();
  return useQuery({
    queryKey: ['invoice', 'list', account_id, params],
    queryFn: async () => {
      if (params) {
        const captchaToken = await recaptcha.executeAsync();
        return fetchPublishedDebt({
          ...params,
          captchaToken,
          account_id,
        });
      }
    },
    keepPreviousData: true,
    enabled: !!params,
  });
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function usePublishedDebtPost() {
  const recaptcha = useRecaptcha();
  const { account_id } = useParams<{ account_id: string }>();
  const queryClient = useQueryClient();

  return useMutation(
    async (variables: Metadata) => {
      const captchaToken = await recaptcha.executeAsync();
      return fetchPublishedDebt({
        ...variables,
        captchaToken,
        account_id,
      });
    },
    {
      onSuccess: (data, variables) => {
        queryClient.setQueryData(
          ['invoice', 'list', account_id, variables],
          data
        );
      },
    }
  );
}

export const useCreateGroupedInvoice = (): UseMutationResult<
  CheckoutInvoice,
  NewApiError,
  CreateGroupedInvoiceParams
> => {
  return useMutation(createGroupedInvoice);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useCancelGroupedInvoice = () => {
  return useMutation(cancelGroupedInvoice);
};
