import styled, { CSSProp } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { H2, H3 } from '@increasecard/typed-components';
import { CheckoutSummaryTable } from './CheckoutSummaryTable';
import { getProductPlanNameSummary } from '../checkoutUtils';
import { AccountBrandingSettings } from '../../../types/Account';
import { Country, Currency } from '../../../types/common';
import { CheckoutInvoice } from '../../../types/Invoice';
import { CheckoutSubscription } from '../../../types/Subscription';
import { CheckoutData, CheckoutDataSetters } from '../useCheckout';
import { SubscriptionSummary } from './summary/SubscriptionSummary';
import { PaymentSummary } from './summary/PaymentSummary';

const StyledH2 = styled(H2)`
  margin-bottom: 1.5rem;
  font-weight: initial;
  color: var(--color-gray-blackSLight);
`;

type BaseProps = {
  checkoutData?: CheckoutData;
  brandingSettings?: AccountBrandingSettings;
  wrapperStyle?: CSSProp;
  defaultCountry?: Country;
  defaultCurrency?: Currency;
  summaryHidden?: boolean;
};

type CheckoutSummaryLegacy = BaseProps & {
  invoice: CheckoutInvoice;
  summaryType: 'invoice';
};

type CheckoutSummaryEmpty = BaseProps & {
  summaryHidden: true;
  summaryType: 'empty';
};

type CheckoutSummaryNew = BaseProps & {
  subscription: CheckoutSubscription;
  summaryType: 'subscription' | 'payment';
  dataSetters?: CheckoutDataSetters;
};

export type CheckoutSummaryProps =
  | CheckoutSummaryNew
  | CheckoutSummaryLegacy
  | CheckoutSummaryEmpty;

export function CheckoutSummary({
  checkoutData,
  summaryHidden,
  ...rest
}: CheckoutSummaryProps): JSX.Element | null {
  const { t } = useTranslation();

  const productPlanName = getProductPlanNameSummary({
    plan: checkoutData?.plan,
    // Typescript cant check discriminated unions in object whose values were destructured
    subscription:
      rest.summaryType === 'subscription' || rest.summaryType === 'payment'
        ? rest.subscription
        : undefined,
  });

  const getSummary = () => {
    switch (rest.summaryType) {
      case 'subscription':
        return (
          <SubscriptionSummary
            checkoutData={checkoutData}
            dataSetters={rest.dataSetters}
            subscription={rest.subscription}
          />
        );
      case 'payment':
        return (
          <PaymentSummary
            checkoutData={checkoutData}
            subscription={rest.subscription}
          />
        );
      case 'invoice':
        return <CheckoutSummaryTable invoice={rest.invoice} />;
    }
  };

  return summaryHidden ? null : (
    <>
      <StyledH2>
        {t(`screens.customer_checkout.${rest.summaryType}_summary`)}
      </StyledH2>
      <H3 weight="bold">{productPlanName}</H3>
      {getSummary()}
    </>
  );
}
