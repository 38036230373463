import { Grid } from '@increasecard/typed-components';
import styled from 'styled-components';
import { BackgroundOrnament } from '../../components/common/BackgroundOrnament';
import { BodyStyles } from '../../components/common/BodyStyles';
import { BrandLogo } from './BrandLogo';
import { OnlyDesktop, OnlyMobile } from '../../hooks/useResponsive';
import { AccountBrandingSettings } from '../../types';
import { ContactLabel } from './components/ContactLabel';

const Layout = styled.div`
  padding: 1rem;
  .mobile-fullWidth {
    width: 100%;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    .mobile-fullWidth {
      width: max-content;
    }
    min-height: 100vh;
    padding: 2rem 0 2rem 0;
  }
`;
const SummaryLayout = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  padding: 16px 16px 8px 16px;
  background-color: white;
  box-shadow: ${({ theme }) => theme.shadows.elevateCast};
  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 2rem;
  }
`;

export const EndSummaryLayout = styled(SummaryLayout)`
  grid-template-rows: auto 1fr auto;
`;

export interface CheckoutLayoutProps {
  leftContext: JSX.Element;
  rightContext: JSX.Element | null;
  footerContext?: JSX.Element | null;
  brandingSettings?: AccountBrandingSettings;
}

export function CheckoutLayout({
  leftContext,
  rightContext,
  footerContext = null,
  brandingSettings,
}: CheckoutLayoutProps): JSX.Element {
  return (
    <Grid style={{ height: '100%' }}>
      <BodyStyles />
      <OnlyDesktop>
        <BackgroundOrnament
          brandingSettings={brandingSettings}
          scale="big"
          variant="float"
          position="right"
          offsetX="20%"
          offsetY="-20px"
        />
      </OnlyDesktop>
      <Layout className="grid-span-desktop-7 grid-start-desktop-2 grid-span-mobile-2">
        {leftContext}
      </Layout>
      <OnlyMobile>
        <BackgroundOrnament
          brandingSettings={brandingSettings}
          variant="inline"
          scale="small"
          position="right"
        />
      </OnlyMobile>
      <OnlyDesktop>
        <SummaryLayout className="grid-span-desktop-4 grid-start-desktop-9">
          <div>{rightContext}</div>
          <ContactLabel brandingSettings={brandingSettings} />
        </SummaryLayout>
      </OnlyDesktop>
      <OnlyMobile>{footerContext}</OnlyMobile>
    </Grid>
  );
}

export function EndCheckoutLayout({
  children,
  brandingSettings,
}: {
  children: JSX.Element | null;
  brandingSettings?: AccountBrandingSettings;
}): JSX.Element {
  return (
    <Grid style={{ height: '100vh' }}>
      <BodyStyles />
      <EndSummaryLayout className="grid-span-desktop-4 grid-start-desktop-5">
        <BrandLogo position="center" />
        <div className="content">{children}</div>
        <ContactLabel
          brandingSettings={brandingSettings}
          justifyContent="center"
        />
      </EndSummaryLayout>
      <OnlyDesktop>
        <BackgroundOrnament
          brandingSettings={brandingSettings}
          scale="small"
          variant="float"
          position="right"
          offsetX="35%"
          offsetY="40%"
        />
      </OnlyDesktop>
    </Grid>
  );
}
