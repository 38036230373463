import {
  MenuButton,
  MenuButtonProps,
  MenuItem,
} from '@increasecard/typed-components';
import { featureFlags } from '../../featureFlags';
import { callIfFunction } from '../../utils/utils';
import { Tooltip } from './Tooltip';

export interface MenuProps {
  row: Metadata;
  menuActions: MenuAction[];
  buttonProps?: Partial<MenuButtonProps>;
  hideDisabledOptions?: boolean;
}

export type MenuAction<T = string> = {
  name?: T; // For identification and filtering only;
  label: StringOrFunctionString;
  cb: (rowData: Metadata) => void;
  disabled?: (rowData: Metadata) => boolean;
  tooltipLabel?: string;
  variant?: 'alert' | 'normal';
};

export function Menu({
  row,
  menuActions,
  buttonProps,
  hideDisabledOptions,
}: MenuProps): JSX.Element {
  return (
    <MenuButton {...buttonProps} verticalIcon={featureFlags.verticalMenuIcon}>
      {({ toggleShowMenu }) =>
        menuActions.map(
          ({ tooltipLabel, disabled, label, cb, ...rest }, index) => {
            const stringLabel = callIfFunction<string>(label, row);
            const isDisabled = disabled ? disabled(row) : false;
            if (hideDisabledOptions && isDisabled) {
              return null;
            } else if (tooltipLabel) {
              return (
                <Tooltip
                  on={['click']}
                  key={`${stringLabel}-${index}`}
                  content={tooltipLabel}
                  onHide={toggleShowMenu}
                  timeout={1000}
                  trigger={() => (
                    <div>
                      <MenuItem
                        onClick={() => {
                          cb(row);
                        }}
                        disabled={isDisabled}
                        {...rest}
                      >
                        {stringLabel}
                      </MenuItem>
                    </div>
                  )}
                />
              );
            } else {
              return (
                <MenuItem
                  key={stringLabel}
                  onClick={() => {
                    toggleShowMenu();
                    cb(row);
                  }}
                  disabled={disabled ? disabled(row) : false}
                  {...rest}
                >
                  {stringLabel}
                </MenuItem>
              );
            }
          }
        )
      }
    </MenuButton>
  );
}
