import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { Constants } from '../../../constants';
import {
  createDiscount,
  fetchDiscountById,
  fetchDiscounts,
  updateDiscount,
} from '../../../services/ExtrasService';
import { Country, Currency, PaginatedData } from '../../../types/common';
import { Discount } from '../../../types/Discount';
import { NewApiError } from '../../../types/NewApiError';
import {
  getPaginatedQueriesData,
  useMutationWithInvalidation,
} from '../../../utils/queryUtils';

interface ExtrasFilters {
  search?: string;
  country: Country;
  currency: Currency;
}

export function useDiscounts(
  page = 1,
  filters?: ExtrasFilters
): UseQueryResult<PaginatedData<Discount>, NewApiError> {
  const params = {
    page,
    per_page: Constants.SMALL_LIST_PAGE_SIZE,
    ...filters,
    is_positive_amount: false,
  };
  return useQuery({
    queryKey: ['discount', 'list', params],
    queryFn: () => fetchDiscounts(params),
    enabled: !!page,
    keepPreviousData: true,
  });
}

export function useDiscountById(
  id: string
): UseQueryResult<Discount, NewApiError> {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ['discount', 'detail', id],
    queryFn: () => fetchDiscountById(id),
    enabled: !!id,
    placeholderData: () => {
      return getPaginatedQueriesData<Discount>(queryClient, [
        'discount',
        'list',
      ])?.find((c) => c.id === id);
    },
  });
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useCreateDiscount() {
  return useMutationWithInvalidation(createDiscount, ['discount', 'list']);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useUpdateDiscount() {
  return useMutationWithInvalidation(updateDiscount, ['discount', 'list']);
}
