import { MercadopagoForm } from './mercadopago/MercadopagoForm';
import { DlocalForm } from './dlocal/DlocalForm';
import { DecidirForm } from './decidir/DecidirForm';
import { PaymentsOSForm } from './paymentsos/PaymentsOSForm';
import { CBUForm } from './cbu/CBUForm';
import { MercadopagoTicketForm } from './mercadopago/MercadopagoTicketForm';
import { PaymentFormBase } from './types';
import { CollectMethod } from '../../types';

export type PaymentMethodFormProps = PaymentFormBase<CollectMethod>;

export function PaymentMethodForm(
  props: PaymentMethodFormProps
): JSX.Element | null {
  switch (props.collectMethods.type) {
    case 'mercadopago_connect':
      if (props.collectMethods.metadata.offline_payments_enabled) {
        return (
          <MercadopagoTicketForm
            {...props}
            collectMethods={props.collectMethods}
          />
        );
      }
      return (
        <MercadopagoForm {...props} collectMethods={props.collectMethods} />
      );
    case 'dlocal':
    case 'dlocal_cross':
      return <DlocalForm {...props} collectMethods={props.collectMethods} />;
    case 'decidir':
      return <DecidirForm {...props} collectMethods={props.collectMethods} />;
    case 'payments_os':
      return (
        <PaymentsOSForm {...props} collectMethods={props.collectMethods} />
      );
    case 'comafi':
    case 'supervielle':
      return <CBUForm {...props} collectMethods={props.collectMethods} />;
    default:
      return null;
  }
}
