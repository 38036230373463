import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ProductsList } from '../../components/listing/ProductsList';
import { PlansList } from './components/PlansList';
import { Header } from '../../components/common/Header';
import { PayAuthorizations } from '../authorizations/PayAuthorizations';
import { VerticalNav } from '../../components/common/VerticalNav';

export function ProductsPlansScreen(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();

  const handleNewProduct = () => {
    history.push('/products/new');
  };

  const handleNewPlan = () => {
    history.push('/plans/new');
  };

  const SCREEN_ELEMENTS = [
    {
      path: '/plans',
      title: t('screens.plans.title'),
      absolute: true,
      content: (
        <>
          <Header
            title={t('screens.plans.title')}
            primaryButton={{
              text: t('common.new_plan'),
              onClick: handleNewPlan,
              allowedAuthorization: PayAuthorizations.createEditProductPlan,
            }}
          />
          <PlansList />
        </>
      ),
    },
    {
      path: '/products',
      title: t('screens.products.title'),
      absolute: true,
      content: (
        <>
          <Header
            title={t('screens.products.title')}
            primaryButton={{
              text: t('common.new_product'),
              onClick: handleNewProduct,
              allowedAuthorization: PayAuthorizations.createEditProductPlan,
            }}
          />
          <ProductsList />
        </>
      ),
    },
  ];

  return <VerticalNav elements={SCREEN_ELEMENTS} />;
}
