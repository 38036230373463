import { useEffect } from 'react';
import { changeFaviconUrl } from './checkoutUtils';
import { useAccountById } from './checkoutQueries';
import { useCheckoutParams } from './useCheckoutParams';

export function useChangeFavicon(): void {
  const { accountId } = useCheckoutParams();
  const account = useAccountById(accountId);

  useEffect(() => {
    if (
      account.data &&
      account.data.branding_settings &&
      account.data.branding_settings.icon_url
    ) {
      changeFaviconUrl(account.data.branding_settings.icon_url);
    }
  }, [account.data]);
}
