import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { POSField } from './paymentsosApiClient';

export interface UsePOSField {
  field: POSField;
  type: 'cardNumber' | 'expiration' | 'cvv';
  onChange: (e: FieldEvent) => void;
  onBlur: (e: FieldEvent) => void;
}

export type FieldEvent = {
  type: 'cardNumber' | 'expiration' | 'cvv';
  brand?: string;
  error?: string;
  bin?: string;
  valid: boolean;
};

const ERROR_FIELDS = {
  cardNumber: 'pan',
  expiration: 'expiry',
  cvv: 'cvv',
};

const ERROR_TYPES = {
  'Card number did not pass luhn validation': 'invalid_card_number',
  'Card expiration date have passed': 'expiration_date',
  'Card cvv is in the wrong length': 'invalid_cvv',
};

export function usePOSField({ field, type, onChange, onBlur }: UsePOSField): {
  id: string;
} {
  const { t } = useTranslation();
  useEffect(() => {
    const buildCustomEvent = (event: Metadata, withErrors = true) => {
      const newEvent: FieldEvent = {
        brand: event.brand,
        type,
        bin: event.bin,
        valid: event.complete && !event.empty,
      };
      if (withErrors && event.error) {
        const errorField = ERROR_FIELDS[type as keyof typeof ERROR_FIELDS];
        const errorKey = event.error[errorField];
        newEvent.error = ERROR_TYPES[errorKey as keyof typeof ERROR_TYPES];
      }
      return newEvent;
    };
    field.on('change', (event) => {
      onChange && onChange(buildCustomEvent(event, false));
    });
    field.on('blur', (event) => {
      if (onBlur) onBlur(buildCustomEvent(event));
    });
  }, [field, onBlur, onChange, t, type]);

  useEffect(() => {
    if (!field.container) {
      field.mount(`#${type}`);
    }
    return () => {
      field.destroy();
    };
  }, [field, type]);

  return { id: type };
}
