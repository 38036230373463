import styled from 'styled-components';
import {
  Paragraph,
  Spinner,
  H2,
  Button,
  Checkbox,
  Grid,
  Link,
} from '@increasecard/typed-components';
import { Header } from '../../components/common/Header';
import { useTranslationObject, Trans } from '../../hooks/useTranslationObject';
import { Box } from '../../components/common/Box';
import { Row } from '../../components/common/Row';
import { ErrorMessage } from '../../components/ErrorMessage';
import { FloatingNotification } from '../../components/common/FloatingNotification';
import { useCustomerNotificationSettings } from './useCustomerNotificationSettings';
import { useHistory } from 'react-router-dom';
import { useModal } from '../../hooks/useModal';
import { NotificationSampleModal } from './NotificationSampleModal';
import { capitalize } from '../../utils/utils';
import { CustomerNotificationSettings } from '../../types/CustomerNotificationSettings';

const ROOT_TRANSLATION_KEY = 'screens.notifications';

const StyledRow = styled(Row)`
  .action-link {
    margin: 0 5px;
    display: none;
  }
  &:hover .action-link {
    display: block;
  }
`;

export function CustomerNotificationsSettings(): JSX.Element {
  const { translations, t } = useTranslationObject(ROOT_TRANSLATION_KEY);
  const {
    fetch,
    settings,
    contact_email,
    toggleSetting,
    save,
    cancel,
    showCancel,
    fetchError,
    showSaveSuccess,
    saveError,
  } = useCustomerNotificationSettings();
  const actionModal = useModal();
  const history = useHistory();

  const gotoBusinessSettings = () => {
    history.push('/settings');
  };

  const handleDataModal = (type: string, title: string) => {
    actionModal.open({ title, type });
  };

  return (
    <>
      <Header title={translations.title_customers} />
      {!settings && !fetchError && <Spinner size={16} />}
      {fetchError !== null && (
        <ErrorMessage onClick={fetch}>{t(fetchError)}</ErrorMessage>
      )}
      {showSaveSuccess && (
        <FloatingNotification
          bodyText={t('notifications.save_success')}
          hideTimeout={3000}
          type="success"
        />
      )}
      {saveError && (
        <FloatingNotification
          bodyText={t(`errors.api.${saveError.code}`)}
          hideTimeout={3000}
          type="error"
        />
      )}
      {settings && (
        <Grid rowGap={2}>
          <Paragraph className="span-desktop-9">
            <Trans
              t={t}
              i18nKey={`${ROOT_TRANSLATION_KEY}.info_contact_email`}
              values={{
                contact_email:
                  contact_email !== null
                    ? contact_email
                    : translations.no_contact_email,
              }}
            />
            <Link onClick={gotoBusinessSettings}>
              {translations.update_contact_email}
            </Link>
          </Paragraph>
          <Row marginTop="0" marginBottom="1rem" className="span-desktop-9">
            <Paragraph>{translations.info_config_customers}</Paragraph>
          </Row>
          <H2>{t('common.payment_orders')}</H2>
          <Box marginLeft="1rem">
            {Object.keys(settings.payments).map((eventName: string) => {
              const id = `payments.${eventName}`;
              const label = translations.customers_payment_events[eventName];
              return (
                <StyledRow key={id} alignItems="center">
                  <Checkbox
                    id={id}
                    name={id}
                    label={label}
                    checked={
                      settings.payments[
                        eventName as keyof CustomerNotificationSettings['payments']
                      ]
                    }
                    onChange={() => toggleSetting(id)}
                    size="small"
                  />
                  <Link
                    className="action-link"
                    small={true}
                    onClick={() => handleDataModal(id, label)}
                  >
                    Enviar Ejemplo
                  </Link>
                </StyledRow>
              );
            })}
          </Box>
          <H2>{capitalize(t('common.subscriptions'))}</H2>
          <Box marginLeft="1rem">
            {translations.subscriptions_events.map(
              ({ event, label }: Metadata) => {
                if (!(event in settings.subscriptions)) {
                  return null;
                }
                const id = `subscriptions.${event}`;
                return (
                  <StyledRow key={id} alignItems="center">
                    <Checkbox
                      id={id}
                      name={id}
                      label={label}
                      checked={
                        settings.subscriptions[
                          event as keyof CustomerNotificationSettings['subscriptions']
                        ]
                      }
                      onChange={() => toggleSetting(id)}
                      size="small"
                    />
                    <Link
                      className="action-link"
                      small={true}
                      onClick={() => handleDataModal(id, label)}
                    >
                      Enviar Ejemplo
                    </Link>
                  </StyledRow>
                );
              }
            )}
          </Box>
          <Row flexGap="1rem" marginTop="2rem">
            <Button onClick={save} disabled={!showCancel}>
              {t('common.save')}
            </Button>
            {showCancel && (
              <Button buttonType="invisible" onClick={cancel}>
                {t('common.cancel')}
              </Button>
            )}
          </Row>

          <NotificationSampleModal
            data={actionModal.data}
            onClose={actionModal.close}
            visible={actionModal.isOpen}
          />
        </Grid>
      )}
    </>
  );
}
