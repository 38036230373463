import { useEffect } from 'react';
import { useChangeFavicon } from './useChangeFavicon';

export function useCheckoutBootUp(): void {
  useChangeFavicon();
  useEffect(() => {
    // Disable autozoom on ios devices
    const meta = document.querySelector<HTMLMetaElement>(
      'meta[name="viewport"]'
    );
    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    if (meta && isIOS) {
      meta.content = meta.content + ', maximum-scale=1';
    }
  }, []);
}
