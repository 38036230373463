import { H1, H2, Spinner } from '@increasecard/typed-components';
import { useTranslation, Trans } from 'react-i18next';
import { RoundedCard } from '../../../components/common/RoundedCard';
import { ScreenSeparator } from '../../../components/common/ScreenSeparator';
import { ApiErrorMessage } from '../../../components/ErrorMessage';
import { FrontPlan as Plan } from '../../../types/Plan';
import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer } from 'recharts';
import { formatCurrency } from '../../../utils/currencyUtils';
import { usePlanStats } from '../planQueries';
import { NewApiError } from '../../../types';

interface PlanStatsProps {
  plan: Plan;
}

const COLORS = {
  draft: '#c196d6',
  open: '#3b5fac',
  paid: 'var(--color-increase-light30)',
  refunded: '#eeee90',
  canceled: 'var(--color-gray-black)',
  uncollectible: 'var(--color-coral-dark)',
  force_retrying: '#51D1F6',
};

export function PlanStats({ plan }: PlanStatsProps): JSX.Element {
  const { t } = useTranslation();
  const queries = usePlanStats(plan.id);
  const [activeSubscriptions, accumulatedAmount, collectivity] = queries;

  const collectibilityData = collectivity.data
    ? Object.entries(collectivity.data).map(([key, value]) => {
        return { name: t(`data.invoice.status.${key}`), value, colorKey: key };
      })
    : [];

  const someCollectibilityData = collectibilityData.some((stat) => {
    return stat.value !== 0;
  });

  const fetchError = queries.find((q) => q.isError)?.error as NewApiError;

  return (
    <>
      <ApiErrorMessage error={fetchError} />
      <RoundedCard
        className="grid-span-desktop-5 grid-span-mobile-12"
        variant="green"
        centered
        gap="0"
      >
        <H2 weight="400">
          <Trans
            t={t}
            i18nKey={'screens.plan_stats.quantity_active_subscriptions'}
          ></Trans>
        </H2>
        {activeSubscriptions.data ? (
          <H1>{activeSubscriptions.data.count}</H1>
        ) : (
          <Spinner size={16} />
        )}
        <ScreenSeparator />
        <H2 weight="400">
          <Trans
            t={t}
            i18nKey={'screens.plan_stats.accumulated_amount'}
          ></Trans>
        </H2>
        <div style={{ marginBottom: 30 }}>
          {accumulatedAmount.data ? (
            <H1>
              {formatCurrency(accumulatedAmount.data.amount, plan.currency)}
            </H1>
          ) : (
            <Spinner size={16} />
          )}
        </div>
        <H2 weight="400">
          <Trans t={t} i18nKey={'screens.plan_stats.collectibility'}></Trans>
        </H2>
        <ResponsiveContainer
          width="95%"
          height={someCollectibilityData ? 200 : 'auto'}
        >
          {someCollectibilityData ? (
            <PieChart width={400} height={400}>
              <Pie
                data={collectibilityData}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
              >
                {collectibilityData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[entry.colorKey as keyof typeof COLORS]}
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          ) : (
            <H2 weight="400">{t('screens.plan_stats.without_stats')}</H2>
          )}
        </ResponsiveContainer>
      </RoundedCard>
    </>
  );
}
