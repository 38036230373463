import { PaginatedData, PaginationConfig } from '../types/common';
import { Reservation } from '../types/Reservation';
import { getQueryString } from '../utils/apiUtils';
import { apiFront } from './api';

export interface FetchReservationsParams extends PaginationConfig {
  created_at_from?: Date;
  created_at_to?: Date;
  customer_id?: string;
  status?: string;
}

export async function fetchReservations(
  params: FetchReservationsParams
): Promise<PaginatedData<Reservation>> {
  const qs = getQueryString(params);
  const response = await apiFront.get(`reservations${qs}`);
  return response.data;
}
