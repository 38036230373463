import {
  entityExtrasFromApi,
  entityExtrasToApi,
  applyExtras,
  isExtraActive,
} from './extrasUtils';
import { calculatePlanAmount } from './planUtils';
import { CheckoutPlan, FrontPlan } from '../types/Plan';
import { Extra, GroupedExtras } from '../types/common';
import { Subscription } from '../types/Subscription';
import { formatCurrency } from './currencyUtils';

export function getSubtotal(
  plan: CheckoutPlan | FrontPlan,
  quantity: number,
  amount: number
): string {
  return formatCurrency(
    calculatePlanAmount(plan, quantity, amount).result,
    plan.currency
  );
}

export function getTotal(
  plan: CheckoutPlan | FrontPlan,
  quantity: number,
  amount: number,
  extras: Extra[],
  cyclesPast: number | undefined
): string {
  const subtotal = calculatePlanAmount(plan, quantity, amount).result;
  const activeExtras = extras.filter((extra) =>
    isExtraActive(extra, cyclesPast)
  );

  const { total: amountWithExtras } = applyExtras(activeExtras, subtotal);
  const validAmount = amountWithExtras < 0 ? 0 : amountWithExtras;
  return formatCurrency(validAmount, plan.currency);
}

export function canChangeBillingDate({ status }: Subscription): boolean {
  return ['active', 'trial', 'grace_period', 'scheduled'].includes(status);
}

export function isActivable({ status }: Subscription): boolean {
  return ['paused'].includes(status);
}
export function isPausable({ status }: Subscription): boolean {
  return ['active', 'scheduled', 'trial'].includes(status);
}
export function isInactionable(subscription: Subscription): boolean {
  return isFinal(subscription) || isFinalPending(subscription);
}
export function isFinal({ status }: Subscription): boolean {
  return ['canceled', 'finished'].includes(status);
}

export function isFinalPending({ status }: Subscription): boolean {
  return ['pending_canceled', 'pending_finished'].includes(status);
}

export function isApprovable({ status }: Subscription): boolean {
  return ['pending_approval', 'request_rejected'].includes(status);
}

export function isRejectable({ status }: Subscription): boolean {
  return ['pending_approval'].includes(status);
}

export function subscriptionFromApi<
  T extends GroupedExtras & { plan: FrontPlan | CheckoutPlan },
>(subscription: T): T {
  subscription.plan = entityExtrasFromApi(subscription.plan);
  subscription.plan.collect_methods.card =
    subscription.plan.collect_methods.card.filter(
      (cm) => cm.status === 'enabled'
    );
  return entityExtrasFromApi<T>(subscription);
}

export function subscriptionToApi<
  T extends GroupedExtras & { plan: FrontPlan | CheckoutPlan },
>(subscription: T): T {
  subscription.plan = entityExtrasToApi(subscription.plan);

  return entityExtrasToApi<T>(subscription);
}
