import { useCountry as useCountryLibrary } from '@increasecard/increase-core';
import { Country } from '../types/common';
import { countryFromPlatform } from '../utils/countryUtils';

// This is a wrapper to useCountry() from increase-core
// returns country using pay convention
export function useCountry(): Country | null {
  const country = useCountryLibrary();
  if (country === null) return null;

  return countryFromPlatform(country);
}
