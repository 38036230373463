import { IconButton, IconButtonProps } from './IconButton';
import { Tooltip } from './Tooltip';

type HelpTooltipProps = {
  iconColor?: 'gray' | 'white';
  content: string;
  timeout?: number;
  iconType?: IconButtonProps['iconType'];
  width?: IconButtonProps['width'];
  height?: IconButtonProps['height'];
};

export function IconButtonTooltip({
  content,
  iconType = 'help',
  width,
  height,
}: HelpTooltipProps): JSX.Element {
  return (
    <Tooltip
      content={content}
      trigger={<IconButton iconType={iconType} width={width} height={height} />}
    />
  );
}
