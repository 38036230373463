import { createContext, ReactNode, useContext } from 'react';
import { FullPageSpinner } from '../../components/common/FullPageSpinner';
import { ApiErrorMessage } from '../../components/ErrorMessage';
import { CheckoutAccount } from '../../types';
import { useAccountById } from './checkoutQueries';
import { useCheckoutParams } from './useCheckoutParams';

const AccountContext = createContext<CheckoutAccount | null>(null);

export interface AccountProviderProps {
  children: ReactNode;
}

export function AccountProvider({
  children,
}: AccountProviderProps): JSX.Element {
  const { accountId } = useCheckoutParams();
  const account = useAccountById(accountId);

  if (account.isError) {
    return <ApiErrorMessage error={account.error} />;
  }

  if (!account.data || account.isLoading) {
    return <FullPageSpinner />;
  }

  return (
    <AccountContext.Provider value={account.data}>
      {children}
    </AccountContext.Provider>
  );
}

export function useCheckoutAccount(): CheckoutAccount {
  const currentAccount = useContext(AccountContext);
  if (!currentAccount) {
    throw new Error('AccountContext: No value provided');
  }
  return currentAccount;
}
