import { Grid } from '@increasecard/typed-components';
import { AccountProvider } from '../AccountContext';
import { DebtList } from './DebtList';
import { PortalLayout } from './DebtPortalLayout';
import { PaymentScreen } from './PaymentScreen';
import { RecaptchaProvider } from './ReCaptchaContext';
import { SearchScreen } from './SearchScreen';
import { useDebtPortalStore } from './useDebtPortalStore';

export function DebtPortalScreen(): JSX.Element {
  const step = useDebtPortalStore((state) => state.step);
  return (
    <Grid
      style={{
        minHeight: '100vh',
        backgroundColor: 'var(--color-gray-grayLight)',
      }}
    >
      <RecaptchaProvider>
        <AccountProvider>
          <PortalLayout>
            {step === 'search' && <SearchScreen />}
            {step === 'list' && <DebtList />}
            {step === 'payment' && <PaymentScreen />}
          </PortalLayout>
        </AccountProvider>
      </RecaptchaProvider>
    </Grid>
  );
}
