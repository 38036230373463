import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../../utils/currencyUtils';
import { getCheckoutSummary } from '../checkoutUtils';
import { Table, H3 } from '@increasecard/typed-components';
import { Currency as CurrencyType } from '../../../types/common';
import { CheckoutInvoice, InvoiceItem } from '../../../types/Invoice';

export interface PlanDetail {
  maximum: number;
  minimum: number;
  tierQuantity: number;
  tierTotal: number;
}

const StyledTable = styled(Table)`
  color: var(--color-gray-blackSLight);
  & Th {
    height: 16px;
  }
`;

const TotalWrapper = styled.div`
  padding: 16px 8px;
  display: flex;
  justify-content: space-between;
`;

export interface CheckoutSummaryTableProps {
  invoice: CheckoutInvoice;
}

export function CheckoutSummaryTable({
  invoice,
}: CheckoutSummaryTableProps): JSX.Element {
  const { t } = useTranslation();

  const { total, lineItems, language, currency } = getCheckoutSummary(invoice);

  const renderCurrency = (value: number, currency: CurrencyType) =>
    formatCurrency(value, currency, language);

  return (
    <div style={{ width: '100%' }}>
      <StyledTable>
        <tbody>
          {lineItems &&
            lineItems
              .sort((invoiceItemA: InvoiceItem, invoiceItemB: InvoiceItem) => {
                // FIXME(dbelay)[PAY-1584]: Workaround to avoid showing discounts (negative numbers) first in the list
                // sort by item amount descendent
                return (
                  parseFloat(invoiceItemB.amount) -
                  parseFloat(invoiceItemA.amount)
                );
              })
              .map((item: InvoiceItem) => (
                <tr key={item.id}>
                  <td>{item.description}</td>
                  <td>{item.quantity}</td>
                  <td className="rightAligned">
                    {renderCurrency(parseFloat(item.amount), item.currency)}
                  </td>
                </tr>
              ))}
        </tbody>
      </StyledTable>
      {currency && total > 0 && (
        <TotalWrapper>
          <H3 weight="bold">{t('common.total')}</H3>
          <H3 weight="bold">{renderCurrency(total, currency)}</H3>
        </TotalWrapper>
      )}
    </div>
  );
}
