import { useParams } from 'react-router-dom';
import { PaymentDetail } from './PaymentDetail';
import { usePaymentById } from './paymentQueries';
import { FullPageSpinner } from '../../components/common/FullPageSpinner';
import { ApiErrorMessage } from '../../components/ErrorMessage';

export function PaymentDetailScreen(): JSX.Element | null {
  const { payment_id } = useParams<{ payment_id: string }>();
  const payment = usePaymentById(payment_id);

  if (payment.isError) {
    return (
      <ApiErrorMessage
        error={payment.error}
        onClick={() => payment.refetch()}
      />
    );
  }

  if (payment.isLoading || !payment.data) {
    return <FullPageSpinner height="50vh" size={32} />;
  }

  return <PaymentDetail payment={payment.data} />;
}
