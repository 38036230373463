import { AmountType, Currency as CurrencyType } from '../types/common';

export function formatPercentage(percentage: number): string {
  const options = {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  };
  const numberFormatter = new Intl.NumberFormat('es', options);
  return numberFormatter.format(percentage);
}

// TODO: The percentage formating should be done once when api returns data.
// TODO: make function type generic
export function formatCurrencyType(
  type: AmountType,
  value: number,
  currency?: CurrencyType,
  language?: string
): string {
  if (type === 'flat') {
    return formatCurrency(value, currency, language);
  } else if (type === 'percentage') {
    return formatPercentage(value);
  }
  return value.toString();
}

function toNumber<T>(value: T): number {
  // Casting Number([2]) will return 2 (╯°□°)╯︵ ┻━┻
  if (!value || Array.isArray(value)) {
    return 0;
  }
  if (typeof value === 'number') {
    return value;
  }
  const parsed = Number(value);
  if (isNaN(parsed)) {
    return 0;
  }
  return parsed;
}

// TODO: Move this function to typed-components or increase-core
// This has the same functionality as the Currency component from
// typed-components
export function formatCurrency(
  value: string | number | undefined,
  currency?: CurrencyType,
  language = navigator.language
): string {
  const parsedValue = toNumber(value);
  if (!currency) return parsedValue.toString();
  const options = {
    minimumFractionDigits: 2,
    style: 'currency',
    currencyDisplay: 'code',
    currency,
  };
  const numberFormatter = new Intl.NumberFormat(language, options);
  return numberFormatter.format(parsedValue);
}
