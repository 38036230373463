import { GATEWAYS, useLoadGateway } from '../GatewayHOCs';
import { useGetPaymentMethodsByType } from './MpApiUtils';
import { TicketPaymentSelector } from '../TicketPaymentSelector';
import { Spinner } from '@increasecard/typed-components';

export function MercadopagoTicketForm(props) {
  const collectMethod = props.collectMethods;

  const { gatewayLoaded } = useLoadGateway(GATEWAYS.MERCADOPAGO, {
    countryCode: collectMethod.country,
  });

  const paymentMethods = useGetPaymentMethodsByType('ticket');

  const handleSubmit = (ticketType) => {
    props.onSubmit({
      type: 'mercadopago_ticket',
      payment_method_id: ticketType,
      collect_method_id: collectMethod.id,
    });
  };

  if (!gatewayLoaded) return <Spinner size={24} />;

  return (
    <TicketPaymentSelector
      {...props}
      paymentMethods={paymentMethods}
      onSubmit={handleSubmit}
      expirationDays={collectMethod.metadata.expiration_days}
    />
  );
}
