import { Spinner } from '@increasecard/typed-components';
import styled from 'styled-components';

const SpinnerContainer = styled.div<FullPageSpinnerProps>`
  ${({ absolute }) => absolute && 'position: absolute;'}
  ${({ height = '100%' }) => `height: ${height};`}
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;

export interface FullPageSpinnerProps {
  absolute?: boolean;
  height?: string;
  size?: number;
}

export function FullPageSpinner({
  absolute,
  height,
  size = 64,
}: FullPageSpinnerProps): JSX.Element {
  return (
    <SpinnerContainer absolute={absolute} height={height}>
      <Spinner size={size} />
    </SpinnerContainer>
  );
}
