import { InlineText, Label, H2 } from '@increasecard/typed-components';
import get from 'lodash/get';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { formatISODate } from '../../utils/dateUtils';
import { callIfFunction } from '../../utils/utils';
import { RoundedCard } from './RoundedCard';
import { Tag } from './Tag';
import { Row } from './Row';

const DetailsWrapper = styled(RoundedCard)<{ displayGrid?: boolean }>`
  ${({ displayGrid }) => displayGrid && 'display: grid;'};
  .row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .row-label {
    flex-basis: 30%;
    color: var(--color-gray-grayBold);
  }
  .row-value {
    display: flex;
    flex-basis: 70%;
  }
  .row-value.left {
    justify-content: start;
  }
  .row-value.right {
    justify-content: end;
  }
  .title {
    grid-column: span 2;
  }
`;

type BaseRow = {
  label: string;
  field: string;
};

type StringRow<T> = BaseRow & {
  type: 'string';
  fontWeigth?: 'bold' | 'normal';
  valueGetter?: (row: T) => ReactNode;
};

type TagRow<T> = BaseRow & {
  type: 'tag';
  tagType: string;
  tagLabel: StringOrFunctionString;
  valueGetter?: (row: T) => ReactNode;
};

type DateRow = BaseRow & {
  type: 'date';
  fontWeigth?: 'bold' | 'normal';
};

type CustomRow<T> = BaseRow & {
  type: 'custom';
  valueGetter: (row: T) => ReactNode;
};

export type RowDefinition<T = Metadata> =
  | StringRow<T>
  | DateRow
  | TagRow<T>
  | CustomRow<T>;
export interface DetailRowProps {
  //TODO(eedv): Look to improve this type. Maybe using Metadata without generics
  config: RowDefinition<Metadata>;
  data: Metadata;
  valueAlignment?: 'left' | 'center' | 'right';
}

export function DetailRow({
  config,
  data,
  valueAlignment,
}: DetailRowProps): JSX.Element {
  const getValueContent = () => {
    switch (config.type) {
      case 'tag':
        return (
          <Tag
            type={`${config.tagType}.status.${
              config.valueGetter
                ? config.valueGetter(data)
                : get(data, config.field)
            }`}
          >
            {callIfFunction(config.tagLabel, data)}
          </Tag>
        );
      case 'date':
        return (
          <InlineText weight={config.fontWeigth}>
            {formatISODate(get(data, config.field), undefined, '-')}
          </InlineText>
        );
      case 'string':
        return (
          <InlineText weight={config.fontWeigth}>
            {config.valueGetter
              ? config.valueGetter(data)
              : get(data, config.field)}
          </InlineText>
        );
      case 'custom':
        return config.valueGetter(data);
      default:
        return;
    }
  };
  return (
    <div className="row">
      <Label className="row-label">{config.label}:</Label>
      <div className={`row-value ${valueAlignment || ''}`}>
        {getValueContent()}
      </div>
    </div>
  );
}

export interface DetailsBoxProps {
  data: Metadata;
  rowsConfig: RowDefinition[];
  title: string;
  className?: string;
  valueAlignment?: 'left' | 'center' | 'right';
  showAsGrid?: boolean;
  icon?: ReactNode;
}

export function DetailsBox({
  data,
  title,
  rowsConfig,
  className,
  valueAlignment,
  showAsGrid,
  icon,
}: DetailsBoxProps): JSX.Element {
  return (
    <DetailsWrapper className={className} displayGrid={showAsGrid}>
      {icon ? (
        <Row alignItems="center" justifyContent="space-between" margin="0">
          <H2 className="title">{title}</H2>
          {icon}
        </Row>
      ) : (
        <H2 className="title">{title}</H2>
      )}

      {rowsConfig.map((rowConfig) => {
        return (
          <DetailRow
            key={rowConfig.field}
            config={rowConfig}
            data={data}
            valueAlignment={valueAlignment}
          />
        );
      })}
    </DetailsWrapper>
  );
}
