import { useEffect } from 'react';
import { Customer } from '../../types/Customer';
import { CheckoutInvoice } from '../../types/Invoice';
import { CheckoutSubscription } from '../../types/Subscription';
import * as ErrorTracker from '../../utils/errorTracker';
import { PartialCustomerFormValues } from '../../schemas/CustomerSchema';
interface ErrorReportUser {
  id: string;
  email: string;
}
const DefaultErrorReportUser: ErrorReportUser = {
  id: 'unknown',
  email: 'unknown',
};

export interface UseErrorUserData {
  subscription?: CheckoutSubscription | null;
  customerInfo?: PartialCustomerFormValues | null;
  customer?: Customer | null;
  customerId?: string;
  invoice?: CheckoutInvoice | null;
}

export function useErrorUserData({
  customer,
  subscription,
  invoice,
  customerInfo,
  customerId,
}: UseErrorUserData): void {
  useEffect(() => {
    function resolveUser(): ErrorReportUser {
      if (customer) {
        return { id: customer.id, email: customer.email };
      }
      if (subscription) {
        return {
          id: subscription.customer.id,
          email: subscription.customer.email,
        };
      }
      if (invoice) {
        return { id: invoice.customer.id, email: invoice.customer.email };
      }
      if (customerInfo) {
        return {
          id: customerId ? customerId : DefaultErrorReportUser.id,
          email: customerInfo.email,
        };
      }

      return DefaultErrorReportUser;
    }
    const reportUser = resolveUser();
    ErrorTracker.setUserData({
      customerId: reportUser.id,
      customerEmail: reportUser.email,
    });
  }, [customerId, customerInfo, subscription, customer, invoice]);
}
