import { useTranslation } from 'react-i18next';
import { formatCurrencyType } from '../../utils/currencyUtils';
import { Constants } from '../../constants';
import {
  getExtraLanguage,
  getExtraCurrency,
  mergeExtras,
  isExtraActive,
} from '../../utils/extrasUtils';
import { DataTable } from '../../components/common/DataTable/DataTable';
import { ColumnDefinition } from '../../components/common/DataTable/DataTable';
import { Extra } from '../../types/common';
import { OneTimeCost } from '../../types/OneTimeCost';
import { Discount } from '../../types/Discount';
import { Tax } from '../../types/Tax';
import { AdditionalCost } from '../../types/AdditionalCost';

export interface MergedExtraListProps {
  entityWithExtras: {
    additional_costs: AdditionalCost[];
    one_time_costs: OneTimeCost[];
    discounts: Discount[];
    taxes: Tax[];
  };
  cyclesPast?: number;
}

export function MergedExtrasList({
  entityWithExtras,
  cyclesPast,
}: MergedExtraListProps): JSX.Element {
  const extraList = mergeExtras(entityWithExtras);
  const { t } = useTranslation();
  const tableColumns: ColumnDefinition[] = [
    {
      field: 'name',
      headerName: t('common.name'),
    },
    {
      field: 'extraType',
      headerName: t('common.type'),
      valueGetter: (row: Extra) => t(`common.${row.extraType}`),
    },
    {
      field: 'description',
      headerName: t('common.description'),
    },
    {
      field: 'amount',
      headerName: t('common.amount'),
      rightAligned: true,
      valueGetter: (row: Extra) =>
        formatCurrencyType(
          row.type,
          parseFloat(row.amount),
          getExtraCurrency(row),
          getExtraLanguage(row)
        ),
    },
    {
      field: 'duration',
      headerName:
        cyclesPast === undefined
          ? t('common.periods')
          : t('common.cycles_left'),
      rightAligned: true,
      valueGetter: (row: Extra) => {
        if (row.extraType === 'tax') {
          return 'Por siempre';
        } else if (row.extraType === 'one_time_cost') {
          return Number(cyclesPast) > 0 ? '0' : '1';
        } else {
          return row.duration === 'periodic'
            ? cyclesPast
              ? row.cycle_amounts_left
              : row.cycle_amount
            : 'Por siempre';
        }
      },
    },
    {
      type: 'tag',
      field: 'status',
      headerName: '',
      tagType: 'plan',
      rightAligned: true,
      valueGetter: (row: Extra) =>
        isExtraActive(row, cyclesPast) ? 'enabled' : 'disabled',
      tagLabel: (row: Extra) =>
        isExtraActive(row, cyclesPast) ? 'Vigente' : 'No vigente',
    },
  ];

  return (
    <DataTable
      tableHeader={t('menu.extras')}
      tableProps={{ hasData: !!extraList.length }}
      columns={tableColumns}
      pageSize={Constants.SMALL_LIST_PAGE_SIZE}
      rows={extraList}
    />
  );
}
