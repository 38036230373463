import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonProps } from '@increasecard/typed-components';

export interface RouterLinkProps extends ButtonProps {
  children: ReactNode;
  to?: string;
}

export function RouterLink({
  children = 'Volver',
  to,
  ...buttonProps
}: RouterLinkProps): JSX.Element {
  if (to) {
    return (
      <Button as={Link} buttonType="invisible" to={to} {...buttonProps}>
        {children}
      </Button>
    );
  }
  return (
    <Button buttonType="invisible" {...buttonProps}>
      {children}
    </Button>
  );
}
