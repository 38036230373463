import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { NewApiError } from '../../types/NewApiError';
import {
  PauseSubscriptionParams,
  MassiveActionParams,
  pauseSubscriptions,
  cancelSubscriptions,
  ActivateSubscriptionParams,
  activateSubscriptions,
  ValidateNextBillingDateParams,
  validateNextBillingDate,
  changeBillingDate,
  ChangeBillingDateParams,
  approveSubscriptions,
  rejectSubscriptions,
  fetchSubscriptions,
  paymentMethodsRequest,
  createSubscription,
  updateSubscription,
  fetchSubscriptionById,
  CancelSubscriptionParams,
  NBDValidation,
  fetchEvents,
} from '../../services/SubscriptionService';
import { fetchTags } from '../../services/AccountService';
import { PaginatedData } from '../../types/common';
import { Subscription } from '../../types/Subscription';
import { Constants } from '../../constants';
import {
  getPaginatedQueriesData,
  queryKeyFactory,
  useMutationWithInvalidation,
} from '../../utils/queryUtils';
import { Discount } from '../../types/Discount';
import { OneTimeCost } from '../../types/OneTimeCost';
import { Tax } from '../../types/Tax';
import { AdditionalCost } from '../../types/AdditionalCost';
import { StatusChangeEvent } from '../../types';

export interface SubscriptionFilters {
  products?: string[];
  status?: string[];
  plans?: string[];
  customers?: string[];
  next_billing_date_from?: Date;
  next_billing_date_to?: Date;
  tags?: string[];
  payment_method_status?: string;
}

export const queryKeys = queryKeyFactory('subscription');

export const useSubscriptions = (
  page = 1,
  filters?: SubscriptionFilters
): UseQueryResult<PaginatedData<Subscription>, NewApiError> => {
  const params = {
    page,
    per_page: Constants.MAX_PAGE_SIZE,
    ...filters,
  };
  return useQuery({
    queryKey: queryKeys.list(params),
    queryFn: () => fetchSubscriptions(params),
    enabled: !!params,
    keepPreviousData: true,
  });
};

export const useSubscriptionById = (
  id: string
): UseQueryResult<Subscription, NewApiError> => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: queryKeys.detail(id),
    queryFn: () => fetchSubscriptionById(id),
    enabled: !!id,
    // This onSuccess will be deprecated in react-query v5
    // so should get rid of it.
    onSuccess: (subscription: Subscription) =>
      queryClient.setQueryData(
        ['customer', subscription.customer.id],
        subscription.customer
      ),
    placeholderData: () => {
      return getPaginatedQueriesData<Subscription>(
        queryClient,
        queryKeys.lists()
      )?.find((c) => c.id === id);
    },
  });
};

// TODO: massive actions mutations should invalidate queries onSettled
export const usePauseSubcriptions = (): UseMutationResult<
  void | NewApiError,
  NewApiError,
  PauseSubscriptionParams
> => {
  return useMutationWithInvalidation(pauseSubscriptions, queryKeys.all);
};

export const useCancelSubscriptions = (): UseMutationResult<
  void | NewApiError,
  NewApiError,
  CancelSubscriptionParams
> => {
  return useMutationWithInvalidation(cancelSubscriptions, queryKeys.all);
};

export const useActivateSubscriptions = (): UseMutationResult<
  void | NewApiError,
  NewApiError,
  ActivateSubscriptionParams
> => {
  return useMutationWithInvalidation(activateSubscriptions, queryKeys.all);
};

export const useApproveSubscriptions = (): UseMutationResult<
  void | NewApiError,
  NewApiError,
  MassiveActionParams
> => {
  return useMutationWithInvalidation(approveSubscriptions, queryKeys.all);
};

export const useRejectSubscriptions = (): UseMutationResult<
  void | NewApiError,
  NewApiError,
  MassiveActionParams
> => {
  return useMutationWithInvalidation(rejectSubscriptions, queryKeys.all);
};

export const usePaymentMethodsRequest = (): UseMutationResult<
  void | NewApiError,
  NewApiError,
  MassiveActionParams
> => {
  return useMutation(paymentMethodsRequest);
};

export const useValidateNextBillingDate = ({
  subscriptionId,
  newBillingDate,
}: Partial<ValidateNextBillingDateParams>): UseQueryResult<
  NBDValidation,
  NewApiError
> => {
  return useQuery({
    queryKey: [subscriptionId, newBillingDate],
    queryFn: () => validateNextBillingDate({ subscriptionId, newBillingDate }),
    enabled: !!(subscriptionId && newBillingDate),
  });
};

export const useChangeBillingDate = (): UseMutationResult<
  void | NewApiError,
  NewApiError,
  ChangeBillingDateParams
> => {
  return useMutationWithInvalidation(changeBillingDate, queryKeys.all);
};

export const useSearchTags = (
  search: string
): UseQueryResult<string[], NewApiError> => {
  return useQuery({
    queryKey: ['tags', search],
    queryFn: () => fetchTags(search),
    // Allow empty strings
    enabled: search !== undefined || search !== null,
    cacheTime: 60 * 1000,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};

export interface CreateSubscriptionParams {
  plan_id: string;
  customer_id: string;
  quantity?: number | null;
  amount: number;
  trial_period_days?: number;
  start_date?: string;
  end_date?: string;
  next_billing_date?: string;
  discounts?: Discount[];
  taxes?: Tax[];
  one_time_costs?: OneTimeCost[];
  additional_costs?: AdditionalCost[];
  external_id?: string | null;
  payment_method_id?: string | null;
  tags?: string[];
}

export const useCreateSusbcription = (): UseMutationResult<
  Subscription,
  NewApiError,
  CreateSubscriptionParams
> => {
  return useMutationWithInvalidation<CreateSubscriptionParams, Subscription>(
    createSubscription,
    queryKeys.lists()
  );
};

export interface UpdateSubscriptionParams
  extends Partial<Omit<CreateSubscriptionParams, 'plan_id' | 'customer_id'>> {
  id: string;
}

export const useUpdateSubscription = (): UseMutationResult<
  Subscription,
  NewApiError,
  UpdateSubscriptionParams
> => {
  return useMutationWithInvalidation<UpdateSubscriptionParams, Subscription>(
    updateSubscription,
    // TODO: invalidate list and only the id of the subscription updated
    queryKeys.all
  );
};

export const useSubscriptionEvents = (
  subscriptionId: string,
  page: number
): UseQueryResult<PaginatedData<StatusChangeEvent>, NewApiError> => {
  return useQuery({
    queryKey: ['subscription', 'events', subscriptionId, page],
    queryFn: () => fetchEvents(subscriptionId, page),
    enabled: !!subscriptionId,
  });
};
