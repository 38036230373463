import { ModalContent, Paragraph } from '@increasecard/typed-components';
import { useTranslation } from 'react-i18next';
import { Subscription } from '../../../types/Subscription';
import { usePaymentMethodsRequest } from '../subscriptionQueries';
import { MassiveActionResults } from './MassiveActionResults';
import { MassiveActionFooter } from './MassiveActionsFooter';

interface PaymentMethodRequestMessageProps {
  subscription: Subscription[];
}

function PaymentMethodRequestMessage({
  subscription,
}: PaymentMethodRequestMessageProps) {
  const { t } = useTranslation();

  return (
    <div style={{ marginBottom: '1rem' }}>
      <Paragraph>
        {t('screens.subscriptions.ask_payment_method.info')}
      </Paragraph>
      <br />
      <Paragraph>
        {t('screens.subscriptions.ask_payment_method.subtitle', {
          count: subscription.length,
        })}
      </Paragraph>
    </div>
  );
}

interface PaymentMethodRequestProps {
  onClose: VoidFunction;
  onSuccess?: VoidFunction;
  subscription: Subscription[];
}

export function PaymentMethodRequestModalContent({
  onClose,
  onSuccess,
  subscription,
}: PaymentMethodRequestProps): JSX.Element | null {
  const { t } = useTranslation();
  const { mutate, error, isError, isSuccess, isLoading } =
    usePaymentMethodsRequest();

  const handleChange = () => {
    mutate(
      {
        subscriptions: subscription,
      },
      { onSuccess }
    );
  };

  if (!subscription) return null;
  return (
    <>
      <ModalContent align="center">
        {!isError && !isSuccess && (
          <PaymentMethodRequestMessage subscription={subscription} />
        )}
        <MassiveActionResults
          subscriptions={subscription}
          error={error}
          isSuccess={isSuccess}
          actionType="ask_payment_method"
        />
      </ModalContent>
      <MassiveActionFooter
        onClose={onClose}
        onConfirm={handleChange}
        isError={isError}
        isSuccess={isSuccess}
        isLoading={isLoading}
        confirmLabel={t('screens.subscriptions.ask_payment_method.confirm', {
          count: subscription.length,
        })}
      />
    </>
  );
}
