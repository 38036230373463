import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { Constants } from '../../../constants';
import {
  createDiscount,
  fetchDiscountById,
  fetchDiscounts,
  updateDiscount,
} from '../../../services/ExtrasService';
import { AdditionalCost } from '../../../types/AdditionalCost';
import { Country, Currency, PaginatedData } from '../../../types/common';
import { NewApiError } from '../../../types/NewApiError';
import {
  getPaginatedQueriesData,
  useMutationWithInvalidation,
} from '../../../utils/queryUtils';

interface ExtrasFilters {
  search?: string;
  country: Country;
  currency: Currency;
}

export function useAditionalCosts(
  page = 1,
  filters?: ExtrasFilters
): UseQueryResult<PaginatedData<AdditionalCost>, NewApiError> {
  const params = {
    page,
    per_page: Constants.SMALL_LIST_PAGE_SIZE,
    ...filters,
    is_positive_amount: true,
  };
  return useQuery({
    queryKey: ['additional_cost', 'list', params],
    queryFn: () => fetchDiscounts(params),
    enabled: !!page,
    keepPreviousData: true,
  });
}

export function useAditionalCostById(
  id: string
): UseQueryResult<AdditionalCost, NewApiError> {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ['additional_cost', 'detail', id],
    // TODO: make a decition, will be treat it as a discount or as additional_cost?
    queryFn: () => fetchDiscountById(id) as unknown as AdditionalCost,
    enabled: !!id,
    placeholderData: () => {
      return getPaginatedQueriesData<AdditionalCost>(queryClient, [
        'additional_cost',
        'list',
      ])?.find((c) => c.id === id);
    },
  });
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useCreateAditionalCost() {
  return useMutationWithInvalidation(
    (additionalCost: Partial<AdditionalCost>) => {
      return createDiscount({ ...additionalCost, is_positive_amount: true });
    },
    ['additional_cost', 'list']
  );
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useUpdateAditionalCost() {
  return useMutationWithInvalidation(updateDiscount, [
    'additional_cost',
    'list',
  ]);
}
