import {
  Modal,
  ModalHeader,
  ModalContent,
  Button,
  ModalHeaderIconBox,
} from '@increasecard/typed-components';
import { PlusSignIcon } from '@increasecard/icons';
import { useTranslation } from 'react-i18next';
import { NewExtraModal } from './NewExtraModal';
import { ScreenSeparator } from '../../components/common/ScreenSeparator';
import { useModal } from '../../hooks/useModal';
import { Country, Currency, Extra, ExtraType } from '../../types/common';
import { ExtraModalIcon } from './ExtraModalIcon';
import {
  ACModalList,
  DiscountModalList,
  OTCModalList,
  TaxModalList,
} from './ExtrasModalList';

const EXTRAS_MODAL_WIDTH = '452';

export interface ExtrasModalProps {
  extraSelected: ExtraType | null;
  onClose: VoidFunction;
  onSelect: (extra: Extra) => void;
  country: Country;
  currency: Currency;
  visible: boolean;
}

export function ExtrasModal({
  extraSelected,
  onClose,
  onSelect,
  country,
  currency,
  visible,
}: ExtrasModalProps): JSX.Element {
  const newExtraModal = useModal();
  const { t } = useTranslation();
  const openNewExtraModal = () => newExtraModal.open();
  const closeNewExtraModal = () => newExtraModal.close();

  const onExtraClick = (extra: Extra) => onSelect(extra);

  const getList = () => {
    switch (extraSelected) {
      case 'discount':
        return (
          <DiscountModalList
            onSelect={onExtraClick}
            country={country}
            currency={currency}
          />
        );
        break;
      case 'tax':
        return (
          <TaxModalList
            onSelect={onExtraClick}
            country={country}
            currency={currency}
          />
        );
        break;
      case 'one_time_cost':
        return (
          <OTCModalList
            onSelect={onExtraClick}
            country={country}
            currency={currency}
          />
        );
        break;
      case 'additional_cost':
        return (
          <ACModalList
            onSelect={onExtraClick}
            country={country}
            currency={currency}
          />
        );
      default:
        break;
    }
  };

  return (
    <Modal
      align="center"
      onClose={onClose}
      visible={visible}
      width={EXTRAS_MODAL_WIDTH}
      onCancel={() => !!0}
      onOk={() => !!0}
    >
      <ModalHeaderIconBox
        colorPalette="pay"
        icon={<ExtraModalIcon extraType={extraSelected} />}
      />

      <ModalHeader>
        {t(`common.${extraSelected}`, {
          title: t('common.tax'),
        })}
      </ModalHeader>
      <ModalContent scrollable>
        {getList()}

        <ScreenSeparator margin="1.5rem 0" />
        <Button
          buttonType="invisible"
          icon={<PlusSignIcon />}
          onClick={openNewExtraModal}
        >
          {t('common.create_title', { entity: t(`common.${extraSelected}`) })}
        </Button>
        <NewExtraModal
          visible={newExtraModal.isOpen}
          onClose={closeNewExtraModal}
          extraType={extraSelected}
        />
      </ModalContent>
    </Modal>
  );
}
