import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CogIcon } from '@increasecard/icons';
import {
  Button,
  Label,
  Modal,
  ModalContent,
  ModalHeaderIconBox,
  ModalFooter,
  Paragraph,
} from '@increasecard/typed-components';
import { CollectMethod } from '../../types';
import { CollectMethodsLogos } from '../../utils/collectMethodsUtils';
import { Column } from '../../components/common/Column';
import { CollectMethodForm } from './CollectMethodForm';
import { useCreateOrUpdateCollectMethod } from './collectMethodQueries';
import { PanelFormConfig } from './collectMethodsFormConfig/PanelConfig';

const MODAL_WIDTH = '600';

const IconButton = styled(Button).attrs({ buttonType: 'invisible' })`
  height: auto;
  width: auto;
  padding: 0.5rem;
  svg {
    margin: 0;
    height: inherit;
    width: inherit;
    fill: inherit;
  }
`;

const ButtonGrid = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr;
  align-items: baseline;
`;

type CollectMethodType = CollectMethod['type'];

export interface NewCollectMethodModalProps {
  onClose: () => void;
  visible?: boolean;
}

export function NewCollectMethodModal({
  onClose,
  visible,
}: NewCollectMethodModalProps): JSX.Element {
  const { t } = useTranslation();
  const [cmType, setCMType] = useState<CollectMethodType>();
  const { mutate, error, isLoading, isSuccess } =
    useCreateOrUpdateCollectMethod();

  const handleSubmit = (
    newValues: Partial<PanelFormConfig['initialValues']>
  ) => {
    mutate({
      ...newValues,
      type: cmType,
    });
  };

  const handleClose = () => {
    setCMType(undefined);
    onClose();
  };

  let content;
  if (cmType) {
    if (isSuccess) {
      content = (
        <Paragraph>
          {t('common.success_create', { entity: t('common.collect_method') })}
        </Paragraph>
      );
    } else {
      content = (
        <CollectMethodForm
          type={cmType}
          onClose={handleClose}
          onSubmit={handleSubmit}
          error={error}
          isLoading={isLoading}
        />
      );
    }
  } else {
    content = (
      <ButtonGrid>
        {Object.entries(CollectMethodsLogos).map(([type, Logo], i) => {
          return (
            <Column margin="0" gap="0.25rem" alignItems="center" key={i}>
              <IconButton onClick={() => setCMType(type as CollectMethodType)}>
                <Logo height="40px" />
              </IconButton>
              <Label>{t(`data.collect_method.types.${type}`)}</Label>
            </Column>
          );
        })}
      </ButtonGrid>
    );
  }

  return (
    <Modal
      shadeClosable={false}
      align="center"
      onClose={handleClose}
      visible={visible || false}
      width={MODAL_WIDTH}
      onCancel={() => {
        return;
      }}
      onOk={() => {
        return;
      }}
    >
      <ModalHeaderIconBox
        colorPalette="pay"
        icon={<CogIcon width="48px" height="48px" />}
      />
      <ModalContent>{content}</ModalContent>
      {!cmType || isSuccess ? (
        <ModalFooter align="center">
          <Button buttonType="invisible" onClick={handleClose}>
            {t('common.close')}
          </Button>
        </ModalFooter>
      ) : null}
    </Modal>
  );
}
