import { UseMutationResult } from '@tanstack/react-query';
import {
  DefaultCMParams,
  createOrUpdateCollectMethod,
  setDefaultCollectMethods,
} from '../../services/CollectMethodService';
import { CollectMethod } from '../../types/CollectMethod';
import { NewApiError } from '../../types/NewApiError';
import { useMutationWithInvalidation } from '../../utils/queryUtils';

export const useCreateOrUpdateCollectMethod = (): UseMutationResult<
  CollectMethod,
  NewApiError,
  Metadata
> => {
  return useMutationWithInvalidation(createOrUpdateCollectMethod, [
    'collect_methods',
  ]);
};

export const useUpdateDefaultCollectMethods = (): UseMutationResult<
  DefaultCMParams,
  NewApiError,
  DefaultCMParams
> => {
  return useMutationWithInvalidation(setDefaultCollectMethods, [
    'collect_methods',
  ]);
};
