import { MouseEvent } from 'react';
import { H1, Button } from '@increasecard/typed-components';
import { useTranslation } from 'react-i18next';
import { ResponsiveGrid } from '../../../components/common/ResponsiveGrid';
import { CheckoutData, CheckoutDataSetters } from '../useCheckout';
import { CheckoutPlan } from '../../../types/Plan';
import { useCheckoutParams } from '../useCheckoutParams';
import { CustomerSubscription } from './CustomerSubscription';
import { PlanDetailList } from './PlanDetailList';
import { usePlans } from '../checkoutQueries';
import { PlanDetail } from './PlanDetail';
import { getPlanInputType } from '../../../utils/planUtils';

export interface PlanSelectionProps {
  checkoutData: CheckoutData;
  dataSetters: CheckoutDataSetters;
  onNext: (event: MouseEvent<HTMLButtonElement>) => void;
  processing: boolean;
}

export function PlanSelection({
  checkoutData,
  dataSetters,
  onNext,
  processing,
}: PlanSelectionProps): JSX.Element {
  const { t } = useTranslation();
  const { planId, productId } = useCheckoutParams();
  const { data } = usePlans({ page: 1, per_page: 5, product_id: productId });

  const selectedPlanId = checkoutData.plan?.id;
  const preSelectedPlanId = planId;
  const planNeedsQuantity = getPlanInputType(checkoutData.plan) === 'quantity';
  const continueDisabled =
    processing ||
    !selectedPlanId ||
    (planNeedsQuantity && !checkoutData.planQuantity);

  const handleSelectPlan = (plan: CheckoutPlan) => {
    // set new selected plan
    dataSetters.setPlan(plan);
    // reset previous values
    dataSetters.setCoupon(null);
    dataSetters.setPlanQuantity(null);
  };

  const resetSelection = () => {
    dataSetters.setCoupon(null);
    dataSetters.setPlanQuantity(null);
    dataSetters.setPlan(null);
  };

  return (
    <>
      <ResponsiveGrid rowGap={2}>
        <H1 className="grid-start-desktop-1 grid-span-desktop-8">
          Suscribite a {data?.data[0]?.product.name}
        </H1>
        {selectedPlanId && !preSelectedPlanId && (
          <Button
            className="grid-start-desktop-10 grid-span-desktop-2 mobile-fullWidth grid-justify-end"
            buttonType="outline"
            onClick={resetSelection}
          >
            {t('screens.customer_checkout.choose_another')}
          </Button>
        )}
        {selectedPlanId || preSelectedPlanId ? (
          <PlanDetail
            productId={productId}
            planId={selectedPlanId || preSelectedPlanId}
            checkoutData={checkoutData}
            dataSetters={dataSetters}
          />
        ) : (
          <PlanDetailList
            productId={productId}
            onSelect={handleSelectPlan}
            selectedPlanId={selectedPlanId}
          />
        )}
        <CustomerSubscription
          customer={checkoutData.customer}
          planId={selectedPlanId}
        />
        <Button
          className="grid-span-desktop-12 grid-span-mobile-2 mobile-fullWidth"
          disabled={continueDisabled}
          buttonType="primary"
          onClick={onNext}
        >
          {t('common.continue')}
        </Button>
      </ResponsiveGrid>
    </>
  );
}
