import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { LabelBox } from '../../../components/common/LabelBox';
import { H2, H3 } from '@increasecard/typed-components';
import { SubscriptionSummary } from './summary/SubscriptionSummary';
import { getProductPlanNameSummary } from '../checkoutUtils';
import { CheckoutSubscription } from '../../../types/Subscription';
import { formatISODate } from '../../../utils/dateUtils';
import { Constants } from '../../../constants';
import { RedirectTimer } from './RedirectTimer';
import { findPayment } from '../checkoutUtils';
import { CheckoutInvoice } from '../../../types/Invoice';

const StyledH2 = styled(H2)`
  margin: 1rem 0;
  font-weight: initial;
  color: var(--color-gray-blackSLight);
`;

export interface EndCheckoutSummaryProps {
  subscription: CheckoutSubscription;
  simpleSummary?: boolean;
}

export function EndCheckoutSummary({
  subscription,
  simpleSummary,
}: EndCheckoutSummaryProps): JSX.Element {
  const { t } = useTranslation();
  // This shold never happend, but we will keep it until confirmation.
  if (subscription === null) {
    throw new Error('Cannot end checkout without a subscription');
  }

  const successUrl = subscription.plan.success_url;

  const getSuccessMessage = (subscription: CheckoutSubscription) => {
    const { status, payment_method } = subscription;
    const paymentIsCBU =
      payment_method?.data.type === Constants.PaymentMethods.CBU;
    const isNotFirstPayment = subscription.invoices.some(
      (invoice: CheckoutInvoice) => invoice.status === 'paid'
    );
    if (paymentIsCBU && isNotFirstPayment) {
      return t('screens.customer_checkout.success_message.change_payment');
    }

    const activeAndPaidTranslationId = paymentIsCBU
      ? 'screens.customer_checkout.success_message.active_and_paid_cbu'
      : 'screens.customer_checkout.success_message.active_and_paid';

    switch (status) {
      case 'pending_approval':
        return t('screens.customer_checkout.success_message.pending');
      case 'active':
        return t(activeAndPaidTranslationId);
      case 'scheduled':
        return t(activeAndPaidTranslationId);
      case 'pending': {
        const currentPayment = findPayment(subscription, payment_method?.id);
        if (currentPayment?.status === 'processing') {
          return t(
            'screens.customer_checkout.success_message.pending_and_processing'
          );
        }
        return t('screens.customer_checkout.success_message.pending');
      }
      case 'trial':
        return t('screens.customer_checkout.success_message.active_and_trial', {
          next_billing_date: formatISODate(subscription.next_billing_date),
        });
      case 'pending_payment':
        return t('screens.customer_checkout.success_message.pending_payment');
      default:
        return '';
    }
  };

  const productPlanName = getProductPlanNameSummary({
    subscription,
  });

  return (
    <>
      <LabelBox
        variant={subscription.status === 'pending' ? 'pending' : 'success'}
        title={t('common.thanks')}
        description={getSuccessMessage(subscription)}
      />
      <StyledH2>{t('screens.customer_checkout.subscription_summary')}</StyledH2>
      {!simpleSummary && <H3 weight="bold">{productPlanName}</H3>}
      <SubscriptionSummary
        subscription={subscription}
        simpleSummary={simpleSummary}
      />
      {successUrl && <RedirectTimer redirectUrl={successUrl} />}
    </>
  );
}
