import {
  SyntheticEvent,
  MouseEventHandler,
  EventHandler,
  KeyboardEvent,
  ReactNode,
} from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { UniversalPortal } from '@increasecard/typed-components';
import { CloseIcon } from '@increasecard/icons';

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10000;
  overflow: hidden;
`;

const Shade = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  .modal-enter & {
    opacity: 0;
  }
  .modal-enter-active & {
    opacity: 1;
    transition: opacity 200ms ease-out;
  }
  .modal-exit & {
    opacity: 1;
  }
  .modal-exit-active & {
    opacity: 0;
    transition: opacity 200ms ease-out;
  }
`;

const ModalBody = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 0.5rem;
  transform: translate(0%, 0%);
  width: 100%;
  background-color: white;
  color: var(--color-gray-black);
  .modal-enter & {
    opacity: 0;
    transform: translate(0%, 100%);
  }
  .modal-enter-active & {
    opacity: 1;
    transform: translate(0%, 0%);
    transition:
      opacity 200ms ease-out,
      transform 200ms ease-out;
  }
  .modal-exit & {
    opacity: 1;
    transform: translate(0%, 0%);
  }
  .modal-exit-active & {
    opacity: 0;
    transform: translate(0%, 100%);
    transition:
      opacity 200ms ease-out,
      transform 200ms ease-out;
  }
`;

const IconButton = styled.button`
  position: absolute;
  top: -30px;
  right: 20px;
  & svg {
    fill: white;
  }
`;

interface CloseButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

function CloseButton({ onClick }: CloseButtonProps) {
  return (
    <IconButton onClick={onClick}>
      <CloseIcon height="24" width="24" />
    </IconButton>
  );
}

export interface ModalDrawerProps {
  children: ReactNode;
  visible?: boolean;
  onClose?: EventHandler<SyntheticEvent<HTMLElement>>;
}

/** Simplified version of typed-components's Modal that renders its children as a drawer */
export function ModalDrawer({
  children,
  visible,
  onClose,
}: ModalDrawerProps): JSX.Element {
  const handleShadeClick = (e: SyntheticEvent<HTMLElement>) =>
    onClose && onClose(e);

  const handleEscKey = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Escape') onClose && onClose(e);
  };

  return (
    <UniversalPortal selector="body">
      <CSSTransition
        classNames="modal"
        in={visible}
        mountOnEnter
        unmountOnExit
        timeout={200}
      >
        <ModalWrapper onClick={handleShadeClick} onKeyUp={handleEscKey}>
          <Shade />
          <ModalBody onClick={(e) => e.stopPropagation()}>
            <CloseButton onClick={onClose} />
            {children}
          </ModalBody>
        </ModalWrapper>
      </CSSTransition>
    </UniversalPortal>
  );
}
