import { create } from 'zustand';
import { CheckoutInvoice } from '../../../types';

export interface DebtPortalStore {
  captchaToken: string | null;
  searchValue: string;
  searchField: string;
  step: 'search' | 'list' | 'payment';
  selectedInvoices: CheckoutInvoice[];
  select: (invoice: CheckoutInvoice) => void;
  unselect: (invoice: CheckoutInvoice) => void;
  isSelected: (invoice: CheckoutInvoice) => boolean;
  setSearchField: (newField: string) => void;
  setSearchValue: (newValue: string) => void;
  isValid: () => boolean;
  goToList: () => void;
  goToSearch: () => void;
  goToPayment: () => void;
}

export const useDebtPortalStore = create<DebtPortalStore>()((set, get) => ({
  captchaToken: null,
  searchValue: '',
  searchField: '',
  step: 'search',
  selectedInvoices: [],
  select: (invoice: CheckoutInvoice) =>
    set((state) => ({
      selectedInvoices: [...state.selectedInvoices, invoice],
    })),
  unselect: (invoice: CheckoutInvoice) =>
    set((state) => ({
      selectedInvoices: state.selectedInvoices.filter(
        ({ id }) => id !== invoice.id
      ),
    })),
  isSelected: (invoice: CheckoutInvoice) =>
    get().selectedInvoices.some(({ id }) => id === invoice.id),
  setSearchField: (newField: string) => set({ searchField: newField }),
  setSearchValue: (newValue: string) => set({ searchValue: newValue }),
  isValid: () => !!(get().searchField && get().searchValue),
  goToList: () => set({ step: 'list', selectedInvoices: [] }),
  goToSearch: () =>
    set({
      step: 'search',
      searchField: '',
      searchValue: '',
      selectedInvoices: [],
    }),
  goToPayment: () => set({ step: 'payment' }),
}));
