import { useTranslation } from 'react-i18next';
import { Header } from '../../components/common/Header';
import { CollectMethodsTable } from './CollectMethodsTable';
import { useModal } from '../../hooks/useModal';
import { NewCollectMethodModal } from './NewCollectMethodModal';
import { AdvancedConfig } from './AdvancedConfig';
import { useAccount } from '../../globalQueries/Queries';
import { FullPageSpinner } from '../../components/common/FullPageSpinner';
import { DefaultCollectMethodsConfig } from './DefaultCollectMethodsConfig';

export function CollectMethodsSettings(): JSX.Element {
  const { t } = useTranslation();
  const account = useAccount();
  const createModal = useModal();
  return (
    <>
      <Header
        title={t('screens.settings.menu.collect_methods')}
        primaryButton={{
          onClick: () => createModal.open(),
          text: 'Nuevo metodo de cobro',
        }}
      />
      <CollectMethodsTable />
      <NewCollectMethodModal
        visible={createModal.isOpen}
        onClose={createModal.close}
      />
      <DefaultCollectMethodsConfig />
      {account.data ? (
        <AdvancedConfig account={account.data} />
      ) : (
        <FullPageSpinner />
      )}
    </>
  );
}
