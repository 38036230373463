export const production = {
  urls: {
    api: {
      platform: 'https://gateway.increase.app/platform',
      pay: 'https://gateway.increase.app/pay',
    },
    platform: 'https://platform.increase.app',
    auth: 'https://auth.increase.app',
    pay: 'https://pay.increase.app',
    landing: 'https://increase.app',
  },
  collectMethods: {
    mercadopago: {
      ARG: {
        PK: 'APP_USR-e952a1fa-67c6-4a65-a470-7e88284dad33',
        integrationUrl:
          'https://auth.mercadopago.com.ar/authorization?client_id=8309189038596948&response_type=code&platform_id=mp&state=collect_method_id={{COLLECT_METHOD_ID}}&redirect_uri=https://gateway.increase.app/pay/integrations/mercadopago/callback',
      },
      PER: {
        PK: 'APP_USR-db9541ca-f48c-47b3-bfdc-fcf5a36570a6',
        integrationUrl:
          'https://auth.mercadopago.com.pe/authorization?client_id=7880262259493647&response_type=code&platform_id=mp&state=collect_method_id={{COLLECT_METHOD_ID}}&redirect_uri=https://gateway.increase.app/pay/integrations/mercadopago/callback',
      },
      COL: {
        PK: 'APP_USR-3c06e0f3-a885-4edb-a471-958bc133b4e9',
        integrationUrl:
          'https://auth.mercadopago.com.co/authorization?client_id=6290364447784205&response_type=code&platform_id=mp&state=collect_method_id={{COLLECT_METHOD_ID}}&redirect_uri=https://gateway.increase.app/pay/integrations/mercadopago/callback',
      },
      CHL: {
        PK: 'APP_USR-03cfad19-2131-4cbf-b1ac-c1f6d6c94488',
        integrationUrl:
          'https://auth.mercadopago.cl/authorization?client_id=6433310211304480&response_type=code&platform_id=mp&state=collect_method_id={{COLLECT_METHOD_ID}}&redirect_uri=https://gateway.increase.app/pay/integrations/mercadopago/callback',
      },
    },
    dlocal: {
      URL: 'https://js.dlocal.com/',
      PK: 'efba0f53-252d-4da2-806c-3cb694a1e2d8',
    },
    decidir: {
      sdkUrl: 'https://live.decidir.com/static/v2.5/decidir.js',
      apiUrl: 'https://live.decidir.com/api/v2',
    },
    payments_os: {
      sdkUrl: 'https://js.paymentsos.com/v3/latest/secure-fields.min.js',
      payuSecurity:
        'https://maf.pagosonline.net/ws/fp/tags.js?id={{deviceSessionId}}80200',
    },
  },
  eventTracking: {
    enabled: true,
    mixpanelToken: 'e177dab63126395e646fc9ab7d9fb1d8',
    debug: false,
  },
  errorTracking: {
    enabled: true,
    rollbarToken: '33f4dd86315842fda658d7a419a6af07',
  },
  useAuthorizations: true,
  reCaptchaSiteKey: '6LfRL1opAAAAACqwk3SXQ4mP5AC8IdIt-DF3L-XS',
};
