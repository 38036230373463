import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { Constants } from '../../../constants';
import {
  createOneTimeCost,
  fetchOneTimeCostById,
  fetchOneTimeCosts,
  updateOneTimeCost,
} from '../../../services/ExtrasService';
import { Country, Currency, PaginatedData } from '../../../types/common';
import { NewApiError } from '../../../types/NewApiError';
import { OneTimeCost } from '../../../types/OneTimeCost';
import {
  getPaginatedQueriesData,
  useMutationWithInvalidation,
} from '../../../utils/queryUtils';

interface ExtrasFilters {
  search?: string;
  country: Country;
  currency: Currency;
}

export function useOneTimeCosts(
  page = 1,
  filters?: ExtrasFilters
): UseQueryResult<PaginatedData<OneTimeCost>, NewApiError> {
  const params = {
    page,
    per_page: Constants.SMALL_LIST_PAGE_SIZE,
    ...filters,
  };
  return useQuery({
    queryKey: ['one_time_cost', 'list', params],
    queryFn: () => fetchOneTimeCosts(params),
    enabled: !!page,
    keepPreviousData: true,
  });
}

export function useOneTimeCostById(
  id: string
): UseQueryResult<OneTimeCost, NewApiError> {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ['one_time_cost', 'detail', id],
    queryFn: () => fetchOneTimeCostById(id),
    enabled: !!id,
    placeholderData: () => {
      return getPaginatedQueriesData<OneTimeCost>(queryClient, [
        'one_time_cost',
        'list',
      ])?.find((c) => c.id === id);
    },
  });
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useCreateOneTimeCost() {
  return useMutationWithInvalidation(createOneTimeCost, [
    'one_time_cost',
    'list',
  ]);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useUpdateOneTimeCost() {
  return useMutationWithInvalidation(updateOneTimeCost, [
    'one_time_cost',
    'list',
  ]);
}
