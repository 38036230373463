import { Table, Checkbox } from '@increasecard/typed-components';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from '../../components/common/Column';
import { DebtItem } from '../../types/Invoice';
import { formatCurrency } from '../../utils/currencyUtils';
import { formatISODate } from '../../utils/dateUtils';
export interface DebtItemsProps {
  debtItems: DebtItem[];
  selectedItems: DebtItem[];
  onChange: (value: DebtItem[]) => void;
}

export function DebtItems({
  debtItems,
  selectedItems,
  onChange,
}: DebtItemsProps): JSX.Element {
  const { t } = useTranslation();

  const isSelected = (itemId: DebtItem) => {
    return !!selectedItems?.find(({ id }) => id === itemId.id);
  };

  const handleItemsChange =
    (debtItem: DebtItem) => (e: ChangeEvent<HTMLInputElement>) => {
      let newItems = [];
      if (e.target.checked) {
        newItems = [...selectedItems, debtItem];
      } else {
        newItems = selectedItems.filter(({ id }) => id !== debtItem.id);
      }
      onChange(newItems);
    };

  const toggleSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      onChange(debtItems);
    } else {
      onChange([]);
    }
  };

  return (
    <Column>
      <Table zebra fixedLayout>
        <thead>
          <tr>
            <th style={{ width: '40px' }}>
              <Checkbox
                id="selectAll"
                label="seleccionar todos"
                labelHidden
                onChange={toggleSelectAll}
                checked={selectedItems.length === debtItems.length}
              />
            </th>
            <th id="description">{t('common.description')}</th>
            <th id="price" className="rightAligned">
              {t('common.amount')}
            </th>
          </tr>
        </thead>
        <tbody>
          {debtItems?.map((debtItem) => (
            <tr key={debtItem.id}>
              <td>
                <Checkbox
                  id={debtItem.id}
                  label={`seleccionar ${debtItem.description}`}
                  labelHidden
                  onChange={handleItemsChange(debtItem)}
                  checked={isSelected(debtItem)}
                />
              </td>
              <td>{`${debtItem.description} - ${formatISODate(
                debtItem.created_at
              )}`}</td>
              <td className="rightAligned">
                {formatCurrency(debtItem.amount, debtItem.currency)}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Column>
  );
}
