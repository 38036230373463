import {
  CellText,
  InlineText,
  RadioButton,
} from '@increasecard/typed-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Paginator } from '../../../components/common/Paginator';
import { ApiErrorMessage } from '../../../components/ErrorMessage';
import { CheckoutPlan } from '../../../types';
import { usePlans } from '../checkoutQueries';
import { PlanPrice } from './PlanPrice';
import { PlanItemContainer, RoundedBox } from './planStyles';

export interface PlanDetailListProps {
  productId: string;
  onSelect: (plan: CheckoutPlan) => void;
  selectedPlanId?: string;
}

export function PlanDetailList({
  productId,
  selectedPlanId,
  onSelect,
}: PlanDetailListProps): JSX.Element | null {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const { data, isLoading, error } = usePlans({
    product_id: productId,
    per_page: 4,
    page,
  });

  if (!data || isLoading) return null;
  if (error) return <ApiErrorMessage error={error} />;
  return (
    <RoundedBox className="span-desktop-11">
      {data.data.map((plan) => (
        <PlanItemContainer key={plan.id}>
          <div className="plan-name">
            <RadioButton
              name="increase-pay-plan"
              value={plan.id}
              checked={plan.id === selectedPlanId}
              onChange={() => onSelect(plan)}
              label=""
            />
            <InlineText weight="bold">{plan.name}</InlineText>
          </div>
          <CellText className="cycle-unit">
            {t(`data.plan.cycle_units.${plan.cycle_unit}`)}
          </CellText>
          <PlanPrice plan={plan} />
        </PlanItemContainer>
      ))}
      {Number(data.total_pages) > 1 && (
        <Paginator
          pages={Number(data.total_pages)}
          selected={page}
          onSelectPage={setPage}
        />
      )}
    </RoundedBox>
  );
}
