import {
  ModalContent,
  ModalHeaderIconBox,
  Modal,
  Button,
} from '@increasecard/typed-components';
import { WarningTriangleIcon } from '@increasecard/icons';
import { useTranslation } from 'react-i18next';
import { ImportProcessingErrors } from '../../types/ImportFile';
import { ProcessingErrorsTable } from './ImportErrorsTable';
import { ErrorMessage } from '../ErrorMessage';

export interface HelpModalProps {
  visible: boolean;
  onClose: () => void;
  errors: ImportProcessingErrors;
}

const noop = () => {
  return;
};

export function ImportErrorsModal({
  visible,
  onClose,
  errors,
}: HelpModalProps): JSX.Element | null {
  const { t } = useTranslation();

  const hasLineErrors = errors?.line_errors.length > 0;
  const hasSingleError = errors?.error.code;

  return (
    <Modal
      visible={visible}
      width="800"
      onClose={onClose}
      onCancel={noop}
      onOk={noop}
    >
      <ModalHeaderIconBox
        colorPalette="pay"
        icon={<WarningTriangleIcon height="48" width="48" />}
      />
      <ModalContent align="center">
        {hasSingleError && (
          <ErrorMessage>
            {t(`screens.import.general_error.${errors.error.code}`)}
          </ErrorMessage>
        )}
        {hasLineErrors && (
          <ProcessingErrorsTable lineErrors={errors.line_errors} />
        )}
        <Button onClick={onClose}>{t('common.close')}</Button>
      </ModalContent>
    </Modal>
  );
}
