import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Link as TCLink } from '@increasecard/typed-components';
import { Subscription } from '../../types/Subscription';
import { formatISODate } from '../../utils/dateUtils';
import { DetailsBox, RowDefinition } from '../../components/common/DetailsBox';
import {
  isFinal,
  isFinalPending,
  isInactionable,
} from '../../utils/subscriptionUtils';
import { compareAsc, parseISO } from 'date-fns';
import { useCollectMethodById } from '../../globalQueries/Queries';

export interface SubscriptionDetailsProps {
  subscription: Subscription;
  className: string;
}
export function SubscriptionDetails({
  subscription,
  className,
}: SubscriptionDetailsProps): JSX.Element {
  const { t } = useTranslation();
  const { data: collectMethod } = useCollectMethodById(
    subscription.payment_method?.collect_method_id
  );
  const getCurrentPeriod = () => {
    const { next_billing_date, cancel_at, canceled_at, invoices } =
      subscription;
    const lastInvoice = invoices && invoices.length > 0 && invoices[0];
    let nextDate = isFinal(subscription)
      ? canceled_at
      : isFinalPending(subscription)
        ? cancel_at
        : next_billing_date;

    const today = new Date();
    nextDate =
      compareAsc(today, parseISO(next_billing_date)) >= 0
        ? today.toISOString()
        : next_billing_date;

    return lastInvoice
      ? `${formatISODate(lastInvoice.created_at)} al ${formatISODate(nextDate)}`
      : '-';
  };

  const periodLabel = isInactionable(subscription)
    ? 'ultimo período'
    : 'periodo actual';

  const detailRows: RowDefinition<Subscription>[] = [
    {
      label: t('common.id'),
      type: 'string',
      field: 'id',
    },
    {
      label: t('data.plan.pricing_model'),
      type: 'string',
      field: 'plan.type',
      valueGetter: () =>
        subscription.plan.type &&
        t(`data.plan.types.${subscription.plan.type}`),
    },
    {
      label: t('common.duration_cycles'),
      type: 'string',
      field: 'plan.duration_cycles',
      valueGetter: () =>
        subscription.plan.duration_cycles
          ? subscription.plan.duration_cycles
          : 'No definidos',
    },
    {
      label: t('common.created_at'),
      type: 'date',
      field: 'created_at',
    },
    {
      label: periodLabel,
      type: 'string',
      field: 'current_period', // This field does not exists.
      valueGetter: getCurrentPeriod,
    },
    {
      label: t('common.status'),
      type: 'tag',
      field: 'status',
      tagType: 'subscription',
      tagLabel: t(`data.subscription.status.${subscription.status}`),
    },
    {
      label: t('common.collect_method'),
      type: 'string',
      field: 'payment_method.collect_method_id', // This field does not exists.
      valueGetter: () => collectMethod?.name || '-',
    },
    {
      label: t('common.plan'),
      type: 'string',
      field: 'plan.name', // This field does not exists.
      valueGetter: () => (
        <TCLink as={Link} to={`/plans/${subscription.plan.id}/detail`}>
          {subscription.plan.name}
        </TCLink>
      ),
    },
  ];

  return (
    <DetailsBox
      className={className}
      title={t('common.details')}
      data={subscription}
      rowsConfig={detailRows}
    />
  );
}
