import { ExtraType } from '../../types';
import { useAditionalCostById } from './aditionalCosts/aditionalCostQueries';
import { useDiscountById } from './discounts/discountQueries';
import { useOneTimeCostById } from './oneTimeCosts/oneTimeCostQueries';
import { useTaxById } from './taxes/taxQueries';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useExtraByType(type: ExtraType, id: string) {
  const discount = useDiscountById(type === 'discount' ? id : '');
  const additionalCost = useAditionalCostById(
    type === 'additional_cost' ? id : ''
  );
  const tax = useTaxById(type === 'tax' ? id : '');
  const oneTimeCost = useOneTimeCostById(type === 'one_time_cost' ? id : '');

  if (type === 'discount') {
    return discount;
  }
  if (type === 'additional_cost') {
    return additionalCost;
  }
  if (type === 'tax') {
    return tax;
  }
  return oneTimeCost;
}
