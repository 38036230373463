import { useEffect } from 'react';
import { useEventTracker, useAppcues } from '@increasecard/increase-core';
import { useLocation } from 'react-router';

export function useNavigationTracking(): void {
  const location = useLocation();
  const tracker = useEventTracker();

  useEffect(() => {
    if (location) {
      // Replaces an uuid with a fixed value
      const path = location.pathname.replace(
        /[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}/,
        'entity_id'
      );
      tracker.track('navigation', { path });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tracker, location?.pathname]);

  useAppcues('pay', (location || window.location).pathname);
}
