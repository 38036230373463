import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeaderIconBox,
  H3,
  Spinner,
} from '@increasecard/typed-components';
import { useTranslation } from 'react-i18next';
import { LockIcon } from '@increasecard/icons';
import styled from 'styled-components';

const NULL_FUNC = () => null;

const SpinnerContainer = styled.div`
  margin-top: 32px;
`;

interface ProcessingPaymentModalProps {
  visible: boolean;
}

export function ProcessingPaymentModal({
  visible,
}: ProcessingPaymentModalProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <Modal
      visible={visible}
      width="500"
      onCancel={NULL_FUNC}
      onOk={NULL_FUNC}
      hideCloseButton={true}
      onClose={NULL_FUNC}
    >
      <ModalHeaderIconBox
        colorPalette="pay"
        icon={<LockIcon height="48" width="48" />}
      />
      <ModalContent align="center">
        <H3>{t('screens.modal_processing_payment.message')}</H3>
        <SpinnerContainer>
          <Spinner size={48} />
        </SpinnerContainer>
      </ModalContent>
      <ModalFooter />
    </Modal>
  );
}
