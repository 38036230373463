import { useQuery, UseQueryResult } from '@tanstack/react-query';
import {
  fetchReservations,
  FetchReservationsParams,
} from '../../services/ReservationService';
import { PaginatedData } from '../../types/common';
import { NewApiError } from '../../types/NewApiError';
import { Reservation } from '../../types/Reservation';
import { queryKeyFactory } from '../../utils/queryUtils';

const queryKeys = queryKeyFactory('reservation');

export function useReservations(
  params: FetchReservationsParams
): UseQueryResult<PaginatedData<Reservation>, NewApiError> {
  return useQuery({
    queryKey: queryKeys.list(params),
    queryFn: () => fetchReservations(params),
    enabled: !!params.page,
    keepPreviousData: true,
  });
}
