import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePlans } from '../../features/plan/planQueries';
import useDebounce from '../../hooks/useDebounce';
import { NameValue } from '../../types/common';
import { FrontPlan } from '../../types/Plan';
import { objectsList2NameValueList } from '../../utils/utils';
import { Selector, SelectorMultiple } from '../common/Selectors';
import { SelectorMultipleProps, SelectorProps } from './types';

const defaultFilter = { status: 'enabled' };

export interface PlanSelectorMultipleProps extends SelectorMultipleProps {
  productId?: string;
}

export function PlanSelectorMultiple({
  onChange,
  value,
  productId,
  ...rest
}: PlanSelectorMultipleProps): JSX.Element {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>();
  const debouncedSearch = useDebounce(search, 300);
  const { data, isLoading } = usePlans({
    page: 1,
    search: debouncedSearch,
    product_id: productId,
    ...defaultFilter,
  });
  const options = data
    ? objectsList2NameValueList(data.data, undefined, 'name', 'id')
    : [];

  return (
    <SelectorMultiple
      {...rest}
      id="plan_id"
      disabled={isLoading}
      onChange={onChange}
      onSearch={setSearch}
      options={options}
      value={value}
      label={t('common.plan')}
    />
  );
}

export interface PlanSelectorProps extends SelectorProps<FrontPlan> {
  productId?: string;
}

export function PlanSelector({
  onChange,
  value,
  disabled,
  getFullItem,
  productId,
  ...rest
}: PlanSelectorProps): JSX.Element {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>();
  const debouncedSearch = useDebounce(search, 300);
  const { data, isLoading } = usePlans({
    page: 1,
    search: debouncedSearch,
    product_id: productId,
  });
  const options = data
    ? objectsList2NameValueList(data.data, undefined, 'name', 'id')
    : [];
  // const options =
  //   nameValue.length > 0
  //     ? [{ name: t('common.all_plans'), value: '*' }, ...nameValue]
  //     : nameValue;

  const handleChange = (_: string, option: NameValue) => {
    if (getFullItem) {
      const plan = data?.data.find((p) => p.id === _) as FrontPlan;
      onChange(option, plan);
    } else {
      onChange(option);
    }
  };

  return (
    <Selector
      {...rest}
      id="plan_id"
      disabled={disabled || isLoading}
      onChange={handleChange}
      onSearch={setSearch}
      options={options}
      value={value}
      label={t('common.plan')}
    />
  );
}
