import {
  ButtonMenu as TCButtonMenu,
  ButtonMenuItem,
} from '@increasecard/typed-components';
import { usePlanActions } from './usePlanActions';
import { callIfFunction } from '../../../utils/utils';
import { QRGeneratorModal } from '../../../components/common/QRGeneratorModal';
import { FrontPlan } from '../../../types';
import { ChangePriceModal } from './ChangePriceModal';

interface PlanActionsMenuProps {
  plans: FrontPlan[];
  text: string;
  disabled?: boolean;
  onCleanUp?: VoidFunction;
}

export function PlanActionsMenu({
  text,
  plans,
  disabled,
  onCleanUp,
}: PlanActionsMenuProps): JSX.Element {
  const { actions, priceModal, qrModal } = usePlanActions('plan screen', [
    'view_details',
    'edit',
    'copy_checkout_link',
    'generate_qr',
    'toggle_plan_status',
  ]);

  return (
    <>
      <TCButtonMenu text={text} disabled={disabled}>
        {({ toggleShowMenu }) =>
          actions.map(({ label, cb, disabled }) => {
            const stringLabel = callIfFunction<string>(label, plans);
            const isDisabled =
              plans.length === 1 && disabled && disabled(plans[0]);

            return (
              <ButtonMenuItem
                onClick={() => {
                  plans.length > 0 && cb(plans);
                  toggleShowMenu();
                }}
                key={stringLabel}
                disabled={isDisabled}
              >
                {stringLabel}
              </ButtonMenuItem>
            );
          })
        }
      </TCButtonMenu>
      <ChangePriceModal
        visible={priceModal.isOpen}
        plans={priceModal.data}
        onClose={priceModal.close}
        onExited={() => priceModal.setData(null)}
        onSuccess={onCleanUp}
      />
      <QRGeneratorModal
        visible={qrModal.isOpen}
        onClose={qrModal.close}
        value={qrModal.data?.checkout_link || ''}
        title={qrModal.data?.name || ''}
      />
    </>
  );
}
