import { useTranslation } from 'react-i18next';
import { H2, Paragraph, Grid } from '@increasecard/typed-components';
import { ApiErrorMessage } from '../../../components/ErrorMessage';
import { DiscountForm, DiscountFormValues } from './DiscountForm';
import { Discount } from '../../../types/Discount';
import { useUpdateDiscount } from './discountQueries';
export interface EditDiscountScreenProps {
  onClose: () => void;
  discount: Discount;
}

export function EditDiscountScreen({
  onClose,
  discount,
}: EditDiscountScreenProps): JSX.Element {
  const { t } = useTranslation();
  const { isLoading, isSuccess, error, mutate } = useUpdateDiscount();

  const handleSubmit = (discountValues: DiscountFormValues) => {
    mutate({ id: discount.id, ...discountValues });
  };

  return (
    <Grid rowGap={2}>
      <H2>{t('common.edit_title', { entity: t('common.discount') })}</H2>
      {isSuccess ? (
        <Paragraph>
          {t('common.success_edit', { entity: t('common.discount') })}
        </Paragraph>
      ) : (
        <DiscountForm
          initialValues={discount}
          onCancel={onClose}
          onSubmit={handleSubmit}
          isLoading={isLoading}
          editMode={true}
        />
      )}
      <ApiErrorMessage error={error} />
    </Grid>
  );
}
