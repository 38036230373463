import { Wizard, useWizard } from 'react-use-wizard';
import { ExportStep } from './ExportStep';
import { ImportStep } from './ImportStep';
import { Row } from '../../../components/common/Row';
import { Button } from '@increasecard/typed-components';
import styled from 'styled-components';

export interface ImportWizardProps {}

const Wrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
`;

export function ImportWizard({
  onExit,
}: {
  onExit: VoidFunction;
}): JSX.Element {
  return (
    <Wrapper>
      <Wizard footer={<WizardFooter onExit={onExit} />}>
        <ExportStep />
        <ImportStep onExit={onExit} />
      </Wizard>
    </Wrapper>
  );
}

function WizardFooter({ onExit }: { onExit: VoidFunction }) {
  const w = useWizard();
  return !w.isLastStep ? (
    <Row gap="1rem" justifyContent="right">
      <Button onClick={w.isFirstStep ? onExit : w.previousStep}>Atras</Button>
      <Button onClick={w.nextStep}>Siguiente</Button>
    </Row>
  ) : null;
}
