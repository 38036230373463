import styled from 'styled-components';
import { Grid } from '@increasecard/typed-components';

export interface ResponsiveGridProps {
  desktopMaxWidth?: string;
  margin?: string;
}

export const ResponsiveGrid = styled(Grid)<ResponsiveGridProps>`
  width: 100%;
  margin: ${({ margin }) => margin || 0};
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: ${({ desktopMaxWidth }) =>
      desktopMaxWidth ? desktopMaxWidth : '100%'};
  }
`;
