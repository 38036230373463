import { ChangeEvent, FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Spinner } from '@increasecard/typed-components';
import { Row } from '../common/Row';
import { OnlyDesktop, OnlyMobile } from '../../hooks/useResponsive';
import { ApiErrorMessage } from '../ErrorMessage';
import { AcceptTermsCheckbox } from '../common/AcceptTermsCheckbox';
import { NewApiError } from '../../types/NewApiError';

export interface PaymentFormFooterProps {
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onCancel?: () => void;
  isSubmitting: boolean;
  submitText?: string;
  cancelText?: string;
  className: string;
  showCardDisclaimer?: boolean;
  termsAndConditionsUrl?: string | null;
  onAcceptChange?: (accept: boolean) => void;
  error?: NewApiError | null;
  onSetDefaultPaymentMethod?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export function PaymentFormFooter({
  isSubmitting,
  onSubmit,
  submitText,
  onCancel,
  cancelText,
  className,
  termsAndConditionsUrl,
  onSetDefaultPaymentMethod,
  error,
}: PaymentFormFooterProps): JSX.Element {
  const { t } = useTranslation();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [acceptTermsError, setAcceptTermsError] = useState('');

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    if (termsAndConditionsUrl && !termsAccepted) {
      setAcceptTermsError('Debe haceptar los términos y condiciones');
      return;
    }
    onSubmit(e);
  };
  return (
    <>
      {onSetDefaultPaymentMethod && (
        <Checkbox
          id="set_as_default"
          label="Utilizar este medio de pago para futuros cobros de esta suscripción"
          onChange={onSetDefaultPaymentMethod}
        />
      )}
      <AcceptTermsCheckbox
        termsAndConditionsUrl={termsAndConditionsUrl}
        onAcceptChange={setTermsAccepted}
        errorMessage={acceptTermsError}
      />
      <Row
        className={className}
        gap="1rem"
        flexWrap="wrap"
        alignItems="center"
        margin="0"
      >
        <Button
          className="mobile-fullWidth"
          disabled={isSubmitting}
          onClick={handleSubmit}
        >
          {submitText}
        </Button>
        {onCancel && (
          <Button
            className="mobile-fullWidth"
            disabled={isSubmitting}
            buttonType="invisible"
            onClick={onCancel}
          >
            {cancelText || t('common.cancel')}
          </Button>
        )}
        <OnlyDesktop>{isSubmitting ? <Spinner size={16} /> : null}</OnlyDesktop>
      </Row>
      <OnlyMobile>
        {error ? <ApiErrorMessage error={error} /> : null}
      </OnlyMobile>
    </>
  );
}
