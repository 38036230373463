import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { BrandingConfig } from '../../globalQueries/Queries';
import { URL, Email } from '../../schemas/common';

type BrandingSettingsPartialForm = Pick<
  BrandingConfig,
  | 'customer_success_url'
  | 'terms_and_conditions'
  | 'contact_email'
  | 'searchable_fields'
>;

export function BrandingSettingsPartialSchema(
  t: TFunction
): Yup.SchemaOf<BrandingSettingsPartialForm> {
  return Yup.object({
    customer_success_url: URL(t).nullable(),
    terms_and_conditions: URL(t).nullable(),
    contact_email: Email().nullable(),
    searchable_fields: Yup.array().test({
      name: 'searchable_fields',
      message: 'Los campos no pueden repetirse',
      test(list: BrandingConfig['searchable_fields'] = []) {
        return list.length === new Set(list.map(({ field }) => field)).size;
      },
    }),
    collect_method_id: Yup.string().nullable(),
  });
}
