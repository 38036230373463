import { Constants } from '../../../constants';
import { CollectMethod, DecidirCollectMethod } from '../../../types';
import { cardBrandsIcons } from '../../../utils/paymentUtils';

// Some names were changed to match the names in paymentUtils
// Also, we don't have all the brands icons
export const supportedCards = [
  'wishgift',
  'favacard',
  'naranja',
  'debvisa',
  'coopeplus',
  'nevada',
  'nativa',
  'cencosud',
  'carrefour',
  'pymenación',
  'bbps',
  'qida',
  'grupar',
  'patagonia365',
  'clubdía',
  'tuya',
  'laanónima',
  'crediguia',
  'sol',
  'cabal',
  'musicred',
  'credimas',
  'discover',
  'diners',
  'tarjetashopping',
  'amex',
  'visa',
  'debmaster',
  'mastercard',
  'debmaster',
  'maestro',
];

export const supportedCardsIcons = supportedCards
  // We dont have icons for every brand, so this coersion is not accurate
  .map((brand) => cardBrandsIcons[brand as keyof typeof cardBrandsIcons])
  .filter(Boolean);

export function isDecidir(
  collectMethod: CollectMethod
): collectMethod is DecidirCollectMethod {
  return collectMethod.type === Constants.CollectMethods.CARD.DECIDIR;
}
