import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { list2NameValue } from '../../utils/utils';
import { Country, Currency, Extra, ExtraType } from '../../types/common';
import { ExtrasModal } from './ExtrasModal';
import { ButtonMenu } from '../../components/common/ButtonMenu';

const EXTRAS_TYPES: ExtraType[] = [
  'tax',
  'discount',
  'one_time_cost',
  'additional_cost',
];

export interface ExtrasPickerButtonProps {
  onSelect: (item: Extra) => void;
  currency: Currency;
  country: Country;
  allowedExtras?: ExtraType[];
}

export function ExtrasPickerButton({
  onSelect,
  currency,
  country,
  allowedExtras = EXTRAS_TYPES,
}: ExtrasPickerButtonProps): JSX.Element {
  const { t } = useTranslation();
  const [extraSelected, setExtraSelected] = useState<ExtraType | null>(null);

  const closeExtrasModal = () => setExtraSelected(null);

  const onExtrasModalSelect = (item: Extra) => {
    closeExtrasModal();
    onSelect(item);
  };

  const onExtrasSelect = (selected: string) => {
    setExtraSelected(selected as ExtraType);
  };

  return (
    <>
      <ButtonMenu
        id="add_extra"
        onSelect={onExtrasSelect}
        options={list2NameValue(allowedExtras, 'common')}
        label={t('common.add')}
      />
      <ExtrasModal
        extraSelected={extraSelected}
        onClose={closeExtrasModal}
        onSelect={onExtrasModalSelect}
        country={country}
        currency={currency}
        visible={!!extraSelected}
      />
    </>
  );
}
