import {
  NativeSelect,
  NativeSelectProps,
} from '@increasecard/typed-components';
import { NameValue } from '../../types/common';
export interface SelectProps extends NativeSelectProps {
  options: NameValue[];
}
export function Select({
  options,
  children,
  ...rest
}: SelectProps): JSX.Element {
  return (
    <NativeSelect {...rest}>
      {children}
      {options.map((opt, i) => (
        <option key={i} value={opt.value}>
          {opt.name}
        </option>
      ))}
    </NativeSelect>
  );
}
