import { useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { getShortText } from '../../../utils/utils';
import { formatCurrencyType } from '../../../utils/currencyUtils';
import { Constants } from '../../../constants';
import { getExtraLanguage, getExtraCurrency } from '../../../utils/extrasUtils';
import { useModal } from '../../../hooks/useModal';
import { EditExtraModal } from '../EditExtraModal';
import { DataTable } from '../../../components/common/DataTable/DataTable';
import {
  ColumnDefinition,
  MenuActionDefinition,
  RowDefinition,
} from '../../../components/common/DataTable/DataTable';
import { usePayAuthorizations } from '../../authorizations/usePayAuthorizations';
import { useDiscounts } from './discountQueries';
import { Discount } from '../../../types';

export function DiscountsList(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const [page, setPage] = useState(1);
  const { isLoading, data } = useDiscounts(page);
  const editModal = useModal();
  const { canCreateEditExtra } = usePayAuthorizations();

  const loadPage = setPage;

  const goToDetail = (id: string) => {
    history.push(`/extras/discount/${id}/detail`);
  };

  const hasData = data?.data ? data?.data.length > 0 : false;

  const tableColumns: ColumnDefinition[] = [
    {
      field: 'name',
      headerName: t('common.discount'),
      valueGetter: (row: RowDefinition) => getShortText(row.name),
    },
    {
      field: 'type',
      headerName: t('common.type'),
      valueGetter: (row: RowDefinition) =>
        row.is_coupon ? t('common.coupon') : t('common.discount'),
    },
    {
      field: 'description',
      headerName: t('common.description'),
      valueGetter: (row: RowDefinition) => getShortText(row.description),
    },
    {
      field: 'country',
      headerName: t('common.country'),
      valueGetter: (row: RowDefinition) =>
        row.country && t(`data.countries.${row.country}`),
    },
    {
      field: 'duration',
      headerName: t('common.duration'),
      valueGetter: (row: RowDefinition) =>
        t(`data.extras.durations.${row.duration}`),
    },
    {
      type: 'tag',
      field: 'available',
      headerName: 'Vigencia',
      tagType: 'discount',
      tagLabel: (row: RowDefinition) =>
        row.available
          ? t('common.discount_valid')
          : t('common.discount_not_valid'),
      valueGetter: (row: RowDefinition) =>
        row.available ? 'enabled' : 'disabled',
    },
    {
      field: 'amount',
      headerName: t('common.amount'),
      rightAligned: true,
      valueGetter: (row: RowDefinition) =>
        formatCurrencyType(
          row.type,
          parseFloat(row.amount),
          getExtraCurrency(row),
          getExtraLanguage(row)
        ),
    },
  ];

  const menuActions: MenuActionDefinition[] = [
    {
      label: t('common.edit'),
      cb: (discount: RowDefinition) => editModal.open(discount),
      disabled: () => !canCreateEditExtra(),
    },
  ];

  return (
    <>
      <DataTable
        tableProps={{ zebra: true, hasData }}
        columns={tableColumns}
        pageSize={Constants.SMALL_LIST_PAGE_SIZE}
        isLoading={isLoading}
        rows={data?.data}
        menuActions={menuActions}
        onRowClick={(discount: Discount) => goToDetail(discount.id)}
        paginatorProps={{
          pages: data?.total_pages,
          selected: Number(data?.current_page),
          onSelectPage: loadPage,
        }}
      />
      <EditExtraModal
        onClose={editModal.close}
        visible={editModal.isOpen}
        extra={editModal.data}
      />
    </>
  );
}
