import { MouseEvent, ChangeEvent, useEffect } from 'react';
import { useFormik, FormikProvider } from 'formik';
import {
  H1,
  Paragraph,
  Button,
  H2,
  NativeSelect,
} from '@increasecard/typed-components';
import { useTranslation } from 'react-i18next';
import { ScreenSeparator } from '../../../components/common/ScreenSeparator';
import { ResponsiveGrid } from '../../../components/common/ResponsiveGrid';
import { InputTextField } from '../../../components/common/FormikFields';
import { OnlyDesktop } from '../../../hooks/useResponsive';
import { CheckoutAccount } from '../../../types/Account';
import { useCheckoutCountryStates } from '../../../hooks/useCountryStates';
import { CheckoutData } from '../useCheckout';
import {
  CustomerFormValues,
  CustomerSchema,
} from '../../../schemas/CustomerSchema';
import { CountrySelect } from '../../../components/common/CountrySelect';
import { useCreateCustomer, useFindCustomer } from '../checkoutQueries';
import { LoadingButton } from '../../../components/common/LoadingButton';
import { ApiErrorMessage } from '../../../components/ErrorMessage';
import { CustomerExistsModal } from './CustomerExistsModal';
import { useModal } from '../../../hooks/useModal';

const INITIAL_VALUES: CustomerFormValues = {
  billing_info: {
    address_line_1: '',
    address_line_2: '',
    city: '',
    country: '',
    zip_code: '',
    state: '',
    state_iso_code: '',
    phone: '',
  },
  first_name: '',
  last_name: '',
  tax_id: '',
  email: '',
};

export interface BillingInfoProps {
  checkoutData: CheckoutData;
  processing: boolean;
  onNext: (values: CustomerFormValues) => void;
  onBack: (event: MouseEvent<HTMLButtonElement>) => void;
  account: CheckoutAccount;
}

export function BillingInfo({
  processing,
  onNext,
  onBack,
}: BillingInfoProps): JSX.Element {
  const { t } = useTranslation();
  const customerExistsModal = useModal();
  const { isLoading, error, mutate: createCustomer } = useCreateCustomer();
  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema: CustomerSchema(t, true),
    onSubmit: (values) => {
      /* eslint-disable @typescript-eslint/no-use-before-define */
      if (customerFound) {
        onNext(customerFound);
        /* eslint-enable @typescript-eslint/no-use-before-define */
      } else {
        createCustomer(values, {
          onSuccess: onNext,
        });
      }
    },
  });
  const { email, tax_id } = formik.values;
  const { data: customerFound } = useFindCustomer(
    email && tax_id ? { tax_id, email } : null
  );
  useEffect(() => {
    if (customerFound) {
      customerExistsModal.open();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerFound]);

  const { countryStates } = useCheckoutCountryStates(
    formik.values.billing_info.country
  );

  const handleStateCodeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    formik.setFieldValue(
      'state',
      e.target.options[e.target.selectedIndex].text
    );
    formik.handleChange(e);
  };

  const handleCountryChange = (e: ChangeEvent<HTMLSelectElement>) => {
    formik.handleChange(e);
    formik.setFieldValue('state', '');
    formik.setFieldValue('state_iso_code', '');
  };

  const handleUseCustomerFound = () => {
    customerExistsModal.close();
    formik.setValues({ ...formik.values, ...customerFound });
  };

  const handleCreateNewCustomer = () => {
    customerExistsModal.close();
    const { first_name, last_name } = formik.values;
    const newValues = { ...INITIAL_VALUES, first_name, last_name };
    formik.setValues(newValues);
  };

  return (
    <>
      <H1>{t('screens.customer_checkout.billing_info_title')}</H1>
      <Paragraph>
        {t('screens.customer_checkout.billing_info_subtitle')}
      </Paragraph>
      <FormikProvider value={formik}>
        <ResponsiveGrid
          as="form"
          onSubmit={formik.handleSubmit}
          rowGap={4}
          desktopMaxWidth="600px"
          margin="2rem 0 0 0"
        >
          <InputTextField
            className="span-desktop-6"
            label={t('data.customer.first_name')}
            id="first_name"
            required
          />
          <InputTextField
            className="span-desktop-6"
            label={t('data.customer.last_name')}
            id="last_name"
            required
          />
          <InputTextField
            className="span-desktop-6"
            label={t('data.customer.email')}
            id="email"
            required
          />
          <InputTextField
            className="span-desktop-6"
            label={t('data.customer.tax_id')}
            id="tax_id"
            required
          />
          <ScreenSeparator margin="0" className="span-desktop-12" />
          <H2 className="span-desktop-12">
            {t('data.customer.invoice_address')}
          </H2>
          <CountrySelect
            id="billing_info.country"
            className="grid-span-desktop-6"
            onChange={handleCountryChange}
            value={formik.values.billing_info.country}
            required
          />
          {countryStates.length > 0 ? (
            <NativeSelect
              id="billing_info.state_iso_code"
              className="grid-span-desktop-3"
              onChange={handleStateCodeChange}
              value={formik.values.billing_info.state_iso_code}
              label={t('data.billing.state')}
              required
            >
              <option key="first" value="">
                {t('data.billing.state_placeholder')}
              </option>
              {countryStates.map((opt, i) => (
                <option key={i} value={opt.iso_code}>
                  {opt.name}
                </option>
              ))}
            </NativeSelect>
          ) : (
            <InputTextField
              className="grid-span-desktop-3"
              id="billing_info.state"
              label={t('data.billing.state')}
              required
            />
          )}
          <InputTextField
            className="span-desktop-3"
            id="billing_info.city"
            label={t('data.billing.city')}
            required
          />
          <InputTextField
            className="span-desktop-6"
            id="billing_info.address_line_1"
            placeholder={t('data.billing.billing_address_placeholder')}
            label={t('common.address')}
            required
          />
          <InputTextField
            className="span-desktop-2"
            id="billing_info.zip_code"
            label={t('data.billing.zip_code')}
            required
          />
          <InputTextField
            className="span-desktop-4"
            style={{ justifyContent: 'flex-end' }}
            id="billing_info.phone"
            type="tel"
            label={t('data.billing.phone')}
            isNumeric
            required
          />
          <ApiErrorMessage error={error} />
          <div>
            <LoadingButton
              className="mobile-fullWidth"
              type="submit"
              buttonType="primary"
              isLoading={isLoading || processing}
            >
              {t('common.continue')}
            </LoadingButton>
            <OnlyDesktop>
              <Button
                buttonType="invisible"
                type="button"
                onClick={onBack}
                style={{ marginLeft: '1rem' }}
              >
                {t('common.back')}
              </Button>
            </OnlyDesktop>
          </div>
        </ResponsiveGrid>
        <CustomerExistsModal
          isOpen={customerExistsModal.isOpen}
          customer={customerFound}
          onConfirm={handleUseCustomerFound}
          onCancel={handleCreateNewCustomer}
        />
      </FormikProvider>
    </>
  );
}
