import { Button } from '@increasecard/typed-components';
import { useFormikContext } from 'formik';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  InputNumberField,
  InputPercentageField,
  SelectField,
} from '../../components/common/FormikFields';
import { Row } from '../../components/common/Row';
import { useCurrencyOptions } from '../../hooks/useCurrencyOptions';
import { AdditionalCost } from '../../types/AdditionalCost';
import { Extra, NameValue } from '../../types/common';
import { Discount } from '../../types/Discount';
import { list2NameValue } from '../../utils/utils';
import { AMOUNT_TYPES, DURATIONS } from '../../enums';

export interface FooterButtonsProps {
  onCancel: VoidFunction;
  isLoading: boolean;
}

export function FooterButtons({
  onCancel,
  isLoading,
}: FooterButtonsProps): JSX.Element {
  const { t } = useTranslation();
  const { dirty, isValid, isSubmitting } = useFormikContext();
  return (
    <Row marginBottom="0" gap="16px" justifyContent="center">
      <Button onClick={onCancel} buttonType="invisible" type="button">
        {t('common.cancel')}
      </Button>
      <Button
        type="submit"
        disabled={!(dirty && isValid) || isSubmitting || isLoading}
      >
        {t(`common.${isSubmitting ? 'saving' : 'save'}`)}
      </Button>
    </Row>
  );
}

export function AmountTypeConfig({
  editMode,
}: {
  editMode: boolean;
}): JSX.Element {
  const { t } = useTranslation();
  const { setFieldValue, values } = useFormikContext<Extra>();
  const currencyOptions: NameValue[] = useCurrencyOptions(values.country);
  const OPTIONS = list2NameValue(
    Object.values(AMOUNT_TYPES),
    'data.extras.types'
  );

  return (
    <>
      <SelectField
        id="type"
        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
          setFieldValue(e.target.id, e.target.value);
          const currency =
            e.target.value === AMOUNT_TYPES.PERCENTAGE
              ? null
              : currencyOptions[0].value;
          setFieldValue('currency', currency);
        }}
        options={OPTIONS}
        className="span-desktop-4"
        label={t('common.type')}
        disabled={editMode}
      />
      {values.type === AMOUNT_TYPES.FLAT ? (
        <>
          <SelectField
            id="currency"
            options={currencyOptions}
            className="span-desktop-4"
            label={t('common.currency')}
            disabled={editMode}
          />
          <InputNumberField
            className="span-desktop-4"
            id="amount"
            label={t('common.amount')}
          />
        </>
      ) : (
        <InputPercentageField
          className="span-desktop-4"
          id="amount"
          autoComplete="off"
          placeholder={t('common.percent_sign')}
          label={t('common.percentage')}
        />
      )}
    </>
  );
}

export function DurationConfig({
  editMode,
}: {
  editMode: boolean;
}): JSX.Element {
  const { t } = useTranslation();
  const { setFieldValue, values } = useFormikContext<
    Discount | AdditionalCost
  >();
  const DURATION_OPTIONS = list2NameValue(
    Object.values(DURATIONS),
    'data.extras.durations'
  );
  return (
    <>
      <SelectField
        id="duration"
        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
          if (e.target.value === DURATIONS.FOREVER) {
            setFieldValue('cycle_amount', 0);
          }
          setFieldValue('duration', e.target.value);
        }}
        options={DURATION_OPTIONS}
        className="grid-start-desktop-1 span-desktop-4"
        label={t('common.duration')}
        disabled={editMode}
      />
      <InputNumberField
        className="span-desktop-4"
        id="cycle_amount"
        textAlign="right"
        placeholder={t('common.percent_sign')}
        label={t('common.quantity')}
        step="1"
        disabled={editMode || values.duration === DURATIONS.FOREVER}
      />
    </>
  );
}
