import { useCallback, useState, useEffect } from 'react';
import {
  getCustomerNotificationSettings,
  updateCustomerNotificationSettings,
} from '../../services/AccountService';
import { useUser } from '@increasecard/increase-core';
import { CustomerNotificationSettings } from '../../types/CustomerNotificationSettings';
import set from 'lodash/set';
import get from 'lodash/get';
import { NewApiError } from '../../types/NewApiError';
import { useAccount } from '../../globalQueries/Queries';

export function useCustomerNotificationSettings(): {
  fetch: () => void;
  settings: CustomerNotificationSettings | null;
  contact_email: string | null;
  toggleSetting: (id: string) => void;
  save: () => void;
  cancel: () => void;
  showCancel: boolean;
  fetchError: string | null;
  showSaveSuccess: boolean;
  saveError: NewApiError | null;
} {
  const user = useUser();
  const account = useAccount();
  const [settings, setSettings] = useState<CustomerNotificationSettings | null>(
    null
  );
  const [showCancel, setShowCancel] = useState(false);
  const [fetchError, setFetchError] = useState<string | null>(null);
  const [showSaveSuccess, setShowSaveSuccess] = useState<boolean>(false);
  const [saveError, setSaveError] = useState<NewApiError | null>(null);

  const fetch = useCallback(() => {
    if (!user) return;
    // get settings from api
    getCustomerNotificationSettings(user?.accountId)
      .then((response) => setSettings(response))
      .catch(() => {
        setFetchError('api.errors.unexpected_error');
      });
  }, [user]);

  const toggleSetting = (id: string) => {
    if (!settings) return;
    // copy settings
    const newSettings = { ...settings };
    // get current value
    const currentValue = get(newSettings, id);
    // toggle value
    set(newSettings, id, !currentValue);
    // save local state
    setSettings(newSettings);
    setShowCancel(true);
  };

  const resetSaveStatus = () => {
    setShowSaveSuccess(false);
    setSaveError(null);
  };

  const save = () => {
    if (!user) return;
    if (!settings) return;
    resetSaveStatus();
    setShowCancel(false);
    // update settings (api)
    updateCustomerNotificationSettings(user.accountId, settings)
      .then((response) => {
        setSettings(response);
        setShowSaveSuccess(true);
        setSaveError(null);
      })
      .catch((error) => {
        setShowSaveSuccess(false);
        setSaveError(error);
        setShowCancel(true);
      });
  };

  const cancel = () => {
    fetch();
    setShowCancel(false);
  };

  useEffect(() => {
    // fetch customer notification settings
    fetch();
  }, [fetch]);

  return {
    fetch,
    settings,
    contact_email: account.data?.branding_settings?.contact_email || null,
    toggleSetting,
    save,
    cancel,
    showCancel,
    fetchError,
    showSaveSuccess,
    saveError,
  };
}
