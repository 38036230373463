import { apiCheckout } from './api';
import { getQueryString, paymentErrorToApiError } from '../utils/apiUtils';
import { Customer } from '../types/Customer';
import { PaymentMethod, PaymentMethodContext } from '../types/PaymentMethod';
import { CollectMethod } from '../types/CollectMethod';
import { CheckoutInvoice, Invoice } from '../types/Invoice';
import { PaginatedData } from '../types/common';
import { CheckoutPlan } from '../types/Plan';
import {
  CheckoutSubscription,
  AmountToCharge,
  SummaryRow,
} from '../types/Subscription';
import { CheckoutAccount } from '../types/Account';
import { subscriptionFromApi } from '../utils/subscriptionUtils';
import { entityExtrasFromApi } from '../utils/extrasUtils';
import { CustomerFormValues } from '../schemas/CustomerSchema';

// Customer endpoints
export async function fetchCustomerById(id: string): Promise<Customer> {
  const response = await apiCheckout.get(`customers/${id}`);
  return response.data;
}

export interface PaymentMethodCreateParams extends Metadata {
  customer_id: string;
  reference_id: string;
  reference_type: PaymentMethodContext;
  binary_mode?: boolean;
}

export async function createPaymentMethod(
  paymentData: PaymentMethodCreateParams
): Promise<PaymentMethod> {
  const response = await apiCheckout.post(`payment_methods`, paymentData);
  if (response.data.data.error) {
    //const { rejection_code, rejection_description } = response.data.data.error;
    return Promise.reject(paymentErrorToApiError(response.data.data.error));
  }
  return response.data;
}

export interface FindCustomerParams extends Pick<Customer, 'email' | 'tax_id'> {
  account_id: string;
}

export async function findCustomer(
  data: FindCustomerParams | null
): Promise<Customer> {
  const queryString = getQueryString(data);
  const response = await apiCheckout.get(`customers${queryString}`);
  return response.data;
}

export interface CreateCustomerParams extends CustomerFormValues {
  account_id: string;
}

export async function createCustomer(
  customerData: CreateCustomerParams
): Promise<Customer> {
  const response = await apiCheckout.post(`customers`, customerData);
  return response.data;
}

export async function fetchCollectMethods(
  data: Metadata
): Promise<CollectMethod[]> {
  const queryString = getQueryString(data);
  const response = await apiCheckout.get(`collect_methods${queryString}`);
  return response.data;
}

// Invoice

export function fetchInvoiceById(id: string): Promise<CheckoutInvoice> {
  return apiCheckout.get(`invoices/${id}`).then((response) => response.data);
}

export interface CreatePaymentParams {
  invoice_id: string;
  payment_method_id: string;
}

export function createPayment(params: CreatePaymentParams): Promise<Invoice> {
  return apiCheckout
    .post(`invoices/${params.invoice_id}/payments/single_payment`, params)
    .then((response) => response.data);
}

// Plans
export async function fetchPlans(
  pagination: Partial<PaginatedData<CheckoutPlan>>,
  productId: string
): Promise<PaginatedData<CheckoutPlan>> {
  const params = getQueryString(pagination);
  const response = await apiCheckout.get(
    `products/${productId}/plans${params}`
  );
  // Only transform extras from plans
  response.data.data = entityExtrasFromApi<CheckoutPlan>(response.data.data);
  return response.data;
}

export async function fetchPlanById(
  productId: string,
  planId: string
): Promise<CheckoutPlan> {
  const response = await apiCheckout.get(
    `products/${productId}/plans/${planId}`
  );
  // Only transform extras from plans
  response.data = entityExtrasFromApi<CheckoutPlan>(response.data);
  return response.data;
}

// Subscription

export async function fetchSubscriptionById(
  id: string
): Promise<CheckoutSubscription> {
  const response = await apiCheckout.get(`subscriptions/${id}`);
  return subscriptionFromApi(response.data);
}

export type SubscriptionRequestParams = {
  customer_id: string;
  plan_id: string;
  quantity: string;
  payment_method_id: string;
  coupon_name?: string | null;
};

export async function createSubscriptionRequest(
  subscriptionData: SubscriptionRequestParams
): Promise<CheckoutSubscription> {
  const response = await apiCheckout.post(
    `subscription_requests`,
    subscriptionData
  );
  return subscriptionFromApi(response.data);
}

export async function updateSubscription(
  id: string,
  data: CheckoutSubscription
): Promise<CheckoutSubscription> {
  const response = await apiCheckout.patch(`subscriptions/${id}`, data);
  return subscriptionFromApi(response.data);
}

export async function fetchSubscriptionAmountToCharge(
  id: string
): Promise<AmountToCharge> {
  const response = await apiCheckout.get(
    `subscriptions/${id}/amount_to_charge_on_add_payment_method`
  );
  return response.data;
}

export type SubscriptionSummaryParams = {
  planId?: string;
  subscriptionId?: string;
  accountId: string;
  summaryType: 'subscription' | 'payment';
  quantity?: number | null;
  couponName?: string | null;
};

export async function fetchSubscriptionSummary({
  planId,
  subscriptionId,
  accountId,
  summaryType,
  quantity,
  couponName,
}: SubscriptionSummaryParams): Promise<SummaryRow[]> {
  const response = await apiCheckout.get(`subscriptions/summary/`, {
    params: {
      plan_id: planId,
      subscription_id: subscriptionId,
      account_id: accountId,
      summary_type: summaryType,
      quantity,
      coupon_name: couponName,
    },
  });
  return response.data;
}

// Account
export async function fetchAccount(id: string): Promise<CheckoutAccount> {
  const response = await apiCheckout.get<CheckoutAccount>(`accounts/${id}`);
  return response.data;
}

export interface CheckCouponParams {
  couponName: string;
  planId: string;
}

export async function checkCoupon({
  couponName,
  planId,
}: CheckCouponParams): Promise<{ applicable: boolean; reason: string }> {
  const response = await apiCheckout.get(
    `coupons/applicable?plan_id=${planId}&coupon_name=${couponName}`
  );
  return response.data;
}

export async function fetchPublishedDebt(
  params: Metadata
): Promise<PaginatedData<CheckoutInvoice>> {
  const { captchaToken, ...queryParams } = params;
  const response = await apiCheckout.get('invoices', {
    params: queryParams,
    headers: {
      'X-RECAPTCHA-TOKEN': captchaToken,
    },
  });
  return response.data;
}

export interface CreateGroupedInvoiceParams {
  customer_id: string;
  invoice_ids: string[];
  collect_methods: { card: string[] };
}

export async function createGroupedInvoice(
  params: CreateGroupedInvoiceParams
): Promise<CheckoutInvoice> {
  const response = await apiCheckout.post('invoices/debt_invoice', params);
  return response.data;
}

export async function cancelGroupedInvoice(id: string): Promise<Invoice> {
  const response = await apiCheckout.patch(
    `invoices/debt_invoice/${id}/cancel`
  );
  return response.data;
}
