import { Button, InputText } from '@increasecard/typed-components';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from '../../../components/common/Column';
import { LabelError } from '../../../components/common/LabelError';
import { Row } from '../../../components/common/Row';
import { CheckoutPlan } from '../../../types/Plan';
import { useCheckCoupon } from '../checkoutQueries';

export interface CouponCheckerProps {
  plan: CheckoutPlan | undefined | null;
  onCouponChange: (couponName: string | null) => void;
}

const hasCouponDiscounts = (plan: CheckoutPlan) =>
  plan.discounts.some((d) => d.is_coupon);

export function CouponChecker({
  plan,
  onCouponChange,
}: CouponCheckerProps): JSX.Element | null {
  const { t } = useTranslation();
  const [couponName, setCouponName] = useState('');
  const [isApplied, setIsApplied] = useState(false);
  const checker = useCheckCoupon();

  if (!plan || !hasCouponDiscounts(plan)) return null;

  const handleCouponChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCouponName(e.target.value);
  };

  const handleCheckCoupon = () => {
    if (couponName.length > 3) {
      checker.mutate(
        { couponName, planId: plan.id },
        {
          onSuccess: (data) => {
            if (data.applicable) {
              onCouponChange(couponName);
              setIsApplied(true);
            }
          },
        }
      );
    }
  };

  const handleRemoveCoupon = () => {
    setCouponName('');
    onCouponChange(null);
    setIsApplied(false);
  };

  return (
    <Column gap="4px">
      <Row margin="0" gap="8px" alignItems="end">
        <InputText
          value={couponName}
          onChange={handleCouponChange}
          id="coupon_code"
          label="Cupón de descuento"
          disabled={checker.isLoading || isApplied}
        />
        {isApplied ? (
          <Button buttonType="outline" onClick={handleRemoveCoupon}>
            {t('common.delete')}
          </Button>
        ) : (
          <Button
            buttonType="outline"
            onClick={handleCheckCoupon}
            disabled={checker.isLoading}
          >
            {t('common.apply')}
          </Button>
        )}
      </Row>
      {checker.data?.reason && (
        <LabelError>
          {t(`api.coupon_validation.${checker.data.reason}`)}
        </LabelError>
      )}
    </Column>
  );
}
