import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { NewPlanForm } from './components/NewPlanForm';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from '../../hooks/useQueryParams';
import { useModal } from '../../hooks/useModal';
import { ConfirmModal } from '../../components/common/ConfirmModal';
import { MdiCheckCircleIcon } from '@increasecard/icons';
import { useCreatePlan, usePlanById, useUpdatePlan } from './planQueries';

import { ProductSelection } from './components/ProductSelection';
import { H1, Paragraph } from '@increasecard/typed-components';
import { FullPageSpinner } from '../../components/common/FullPageSpinner';
import { ScreenSeparator } from '../../components/common/ScreenSeparator';

export function NewPlanScreen(): JSX.Element {
  const history = useHistory();
  const { t } = useTranslation();
  const { plan_id } = useParams<{ plan_id?: string }>();
  const { name: productName, product_id } = useQueryParams();
  const createPlan = useCreatePlan();
  const updatePlan = useUpdatePlan();
  const { data: plan, isInitialLoading: planLoading } = usePlanById(plan_id);
  const successModal = useModal();
  const [selectedProduct, setSelectedProduct] = useState<string>(product_id);
  const editMode = plan_id !== undefined ? !product_id : false;
  // Use the plan from the list if available.
  const error = editMode ? updatePlan.error : createPlan.error;
  const submitting = editMode ? updatePlan.isLoading : createPlan.isLoading;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (planData: any) => {
    if (editMode) {
      planData.plan_id = plan_id;
      updatePlan.mutate(planData, {
        onSuccess: () => successModal.open(),
      });
    } else {
      planData.product_id = selectedProduct;
      createPlan.mutate(planData, {
        onSuccess: () => successModal.open(),
      });
    }
  };

  const handleCloseSuccessModal = () => {
    history.push('/plans');
  };

  const planToEdit = plan;

  const renderHeader = () => {
    const headerText = editMode
      ? t('screens.new_plan.edit_title')
      : productName
        ? t('screens.new_plan.title', { product: productName })
        : t('screens.new_plan.title_new_prod');
    const description = editMode
      ? t('screens.new_plan.edit_subtitle')
      : t('screens.new_plan.subtitle');
    return (
      <div className="grid-span-desktop-12 grid-span-mobile-2">
        <H1>{headerText}</H1>
        <Paragraph>{description}</Paragraph>
      </div>
    );
  };

  if (planLoading) {
    return (
      <>
        {renderHeader()}
        <FullPageSpinner />
      </>
    );
  }

  return (
    <>
      {!editMode && (
        <ProductSelection
          onChange={setSelectedProduct}
          currentProductId={selectedProduct}
        />
      )}
      {renderHeader()}
      <ScreenSeparator margin="1rem 0 3rem 0" />
      <NewPlanForm
        productName={productName}
        onSubmit={onSubmit}
        onCancel={handleCloseSuccessModal}
        submitting={submitting}
        plan={planToEdit}
        editMode={editMode}
        fetching={planLoading}
        error={error}
      />
      <ConfirmModal
        headerIcon={<MdiCheckCircleIcon width="48" height="48" />}
        visible={successModal.isOpen}
        header={t(`screens.new_plan.success_${editMode ? 'edit' : 'create'}`)}
        confirmLabel={t('common.accept')}
        onConfirm={handleCloseSuccessModal}
        onClose={handleCloseSuccessModal}
      />
    </>
  );
}
