import { CheckoutSummary } from './components/CheckoutSummary';
import { SummaryFooter } from './components/SummaryFooter';
import { redirectTo } from '../../utils/utils';
import { useCheckout } from './useCheckout';
import { useLanguage } from '../../hooks/useLanguage';
import { BrandLogo } from './BrandLogo';
import { CheckoutLayout, EndCheckoutLayout } from './Layout';
import { ApiErrorMessage } from '../../components/ErrorMessage';
import { useCreateSubscription, usePaySubscription } from './checkoutQueries';
import { EndCheckoutSummary } from './components/EndCheckoutSummary';
import { useCheckoutAccount } from './AccountContext';

export function CustomerCheckoutScreen() {
  const {
    checkoutData,
    dataSetters,
    navigation: { StepComponent, currentStep, ...navigation },
    STEP_INDEX,
  } = useCheckout();
  const account = useCheckoutAccount();
  const subscriptionMutation = useCreateSubscription();
  const paymentMutation = usePaySubscription();
  // Derived state
  // Use the correct subscription: created or paid to each step
  const currentSubscription =
    (currentStep === STEP_INDEX.payment && subscriptionMutation.data) ||
    (currentStep === STEP_INDEX.end && paymentMutation.data?.subscriptionPaid);
  useLanguage(checkoutData.plan?.country);

  const createSubscription = (customer) => {
    subscriptionMutation.mutate(
      {
        customer_id: customer.id,
        plan_id: checkoutData.plan.id,
        quantity: checkoutData.planQuantity,
        coupon_name: checkoutData.coupon,
      },
      {
        onSuccess: navigation.gotoPayment,
      }
    );
  };

  const paySubscription = (paymentData) => {
    paymentMutation.mutate(
      {
        subscriptionId: subscriptionMutation.data.id,
        customerId: subscriptionMutation.data.customer.id,
        paymentData,
      },
      {
        onSuccess: (payment) => {
          // If payment is of ticket type, redirect to external_resource_url
          if (payment && payment.external_resource_url) {
            redirectTo(payment.external_resource_url);
          } else {
            navigation.gotoSummary();
          }
        },
      }
    );
  };

  const onNextStep = (data) => {
    switch (currentStep) {
      case STEP_INDEX.plan:
        navigation.gotoBilling();
        return;
      case STEP_INDEX.billing:
        createSubscription(data);
        return;
      case STEP_INDEX.payment:
        paySubscription(data);
        return;
      default:
        return;
    }
  };

  const isProcessing = () => {
    return (
      (currentStep === STEP_INDEX.billing && subscriptionMutation.isLoading) ||
      (currentStep === STEP_INDEX.payment && paymentMutation.isLoading)
    );
  };

  const brandingSettings = account.branding_settings;
  const layoutPosition = currentStep === STEP_INDEX.end ? 'center' : 'left';

  const leftContext = (
    <div>
      <BrandLogo
        brandingSettings={brandingSettings}
        position={layoutPosition}
      />
      <StepComponent
        account={account}
        brandingSettings={brandingSettings}
        checkoutData={checkoutData}
        processing={isProcessing()}
        onNext={onNextStep}
        onBack={navigation.gotoPrevStep}
        dataSetters={dataSetters}
        customerId={currentSubscription?.customer?.id}
        subscription={currentSubscription}
        errorObj={paymentMutation.error}
        simpleSummary={STEP_INDEX.end ?? true}
      />
      <ApiErrorMessage error={subscriptionMutation.error} />
    </div>
  );

  // Summary is not yet calculated when there is no subscription.
  const summaryType =
    currentStep === STEP_INDEX.payment ? 'payment' : 'subscription';

  // We need to hide the entire summary in billing step because
  // we can't hide only the CouponChecker
  const showSummary = [STEP_INDEX.customer, STEP_INDEX.billing].includes(
    currentStep
  );

  if (currentStep === STEP_INDEX.end) {
    return (
      <EndCheckoutLayout brandingSettings={brandingSettings}>
        <EndCheckoutSummary subscription={currentSubscription} />
      </EndCheckoutLayout>
    );
  }

  return (
    <CheckoutLayout
      leftContext={leftContext}
      brandingSettings={brandingSettings}
      rightContext={
        currentStep !== STEP_INDEX.end && (
          <CheckoutSummary
            checkoutData={checkoutData}
            dataSetters={dataSetters}
            summaryHidden={showSummary}
            brandingSettings={brandingSettings}
            subscription={currentSubscription}
            summaryType={summaryType}
          />
        )
      }
      footerContext={
        <>
          {currentStep !== STEP_INDEX.end &&
            currentStep !== STEP_INDEX.customer && (
              <SummaryFooter
                subscription={currentSubscription}
                dataSetters={dataSetters}
                checkoutData={checkoutData}
                summaryHidden={currentStep === STEP_INDEX.customer}
                summaryType={summaryType}
              />
            )}
        </>
      }
    ></CheckoutLayout>
  );
}
