/* eslint-disable @typescript-eslint/no-unused-vars */
import styled, { DefaultTheme } from 'styled-components';
import get from 'lodash/get';
import { CSSProperties, ReactNode, ComponentType } from 'react';

export interface GetStylePropsArguments<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component extends string | ComponentType<any>,
> extends CSSProperties {
  as?: Component;
  children?: ReactNode;
  theme?: DefaultTheme;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getStyleProps = <Component extends string | ComponentType<any>>({
  as,
  children,
  theme,
  ...rawStyleProps
}: GetStylePropsArguments<Component>) => {
  return Object.fromEntries(
    Object.entries(rawStyleProps).map(([key, value]) => {
      if (key.toLowerCase().includes('color')) {
        return [key, get(theme, value)];
      }
      return [key, value];
    })
  );
};

export const Box = styled.div<CSSProperties>(getStyleProps);
