import { PaginatedData } from '../types/common';
import {
  Product,
  ProductCreationParams,
  ProductQueryParams,
  ProductUpdateParams,
} from '../types/Product';
import { apiFront } from './api';

export async function fetchProducts(
  params: ProductQueryParams
): Promise<PaginatedData<Product>> {
  const response = await apiFront.get(`/products`, {
    params,
  });
  return response.data;
}

export async function createProduct(
  params: ProductCreationParams
): Promise<Product> {
  const response = await apiFront.post(`/products`, params);
  return response.data;
}

export async function updateProduct({
  id,
  name,
}: ProductUpdateParams): Promise<Product> {
  const response = await apiFront.put(`/products/${id}`, { name });
  return response.data;
}
