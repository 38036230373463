import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { H1, Paragraph, Grid } from '@increasecard/typed-components';
import { getShopName } from '../checkoutUtils';
import { PaymentMethods } from '../../../components/paymentMethods/PaymentMethods';
import { BrandLogo } from '../BrandLogo';
import { LabelBox } from '../../../components/common/LabelBox';
import { PaymentMethodLabel } from '../../../components/paymentMethods/PaymentMethodLabel';
import { CheckoutSummary } from './CheckoutSummary';
import { CheckoutLayout, EndCheckoutLayout } from '../Layout';
import { Constants } from '../../../constants';
import { RedirectTimer } from './RedirectTimer';
import { getQueryString } from '../../../utils/apiUtils';
import { useCreatePaymentMethod } from '../checkoutQueries';
import {
  CheckoutAccount,
  Customer,
  GroupedCollectMethods,
} from '../../../types';
import { getCustomerName } from '../../../utils/utils';

const Wrapper = styled.div`
  & > ${Paragraph} {
    margin-bottom: 10px;
    margin-top: 0.5rem;
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const PaymentWrapper = styled.div`
  width: 100%;
  color: var(--color-gray-blackSLight);
  justify-content: space-between;
  margin-top: 1.5rem;
  display: flex;
`;

export interface CustomerPaymentMethodProps {
  account: CheckoutAccount;
  customer: Customer;
  groupedCollectMethods: GroupedCollectMethods;
}

export function CustomerPaymentMethod({
  account,
  customer,
  groupedCollectMethods,
}: CustomerPaymentMethodProps): JSX.Element {
  const { t } = useTranslation();
  const createPaymentMethod = useCreatePaymentMethod();
  const elementsPosition = createPaymentMethod.isSuccess ? 'center' : 'left';
  const brandingSettings = account?.branding_settings;
  const successUrl = brandingSettings?.customer_success_url;

  const buildSuccessUrl = () => {
    if (createPaymentMethod.isSuccess) {
      const { collect_method_id, id } = createPaymentMethod.data;
      return `${successUrl}${getQueryString({
        payment_method_id: id,
        customer_id: customer.id,
        collect_method_id,
      })}`;
    }
    return '';
  };

  const handleSubmit = (data: Metadata) => {
    createPaymentMethod.mutate({
      ...data,
      customer_id: customer.id,
      reference_id: customer.id,
      reference_type: Constants.PaymentMethodContext.CUSTOMER,
    });
  };

  const leftContext = (
    <Wrapper style={{ textAlign: elementsPosition }}>
      <BrandLogo
        position={elementsPosition}
        brandingSettings={brandingSettings}
      />

      <H1>{t('screens.customer_payment_method.title')}</H1>
      <Paragraph>
        <Trans
          i18nKey="screens.customer_payment_method.description"
          values={{
            customerName: getCustomerName(customer),
            company: getShopName(account),
          }}
        />
      </Paragraph>
      <Grid rowGap={2}>
        <div className="span-desktop-12">
          <PaymentMethods
            onNext={handleSubmit}
            processing={createPaymentMethod.isLoading}
            submitText={t('screens.customer_payment_method.add')}
            customerId={customer.id}
            country={customer.billing_info.country}
            groupedCollectMethods={groupedCollectMethods}
            errorObj={createPaymentMethod.error}
          />
        </div>
      </Grid>
    </Wrapper>
  );

  if (createPaymentMethod.isSuccess) {
    return (
      <EndCheckoutLayout brandingSettings={brandingSettings}>
        <>
          <LabelWrapper>
            <LabelBox
              variant="success"
              title={t('common.thanks')}
              description={t('screens.customer_payment_method.success')}
            />
          </LabelWrapper>
          <PaymentWrapper>
            <div>
              {createPaymentMethod.data.data.type ===
              Constants.PaymentMethods.CBU
                ? t(`data.payment.methods.cbu`)
                : t(`data.payment.methods.card`)}
            </div>
            <PaymentMethodLabel paymentMethod={createPaymentMethod.data} />
          </PaymentWrapper>
          {successUrl && <RedirectTimer redirectUrl={buildSuccessUrl()} />}
        </>
      </EndCheckoutLayout>
    );
  }
  return (
    <CheckoutLayout
      brandingSettings={brandingSettings}
      leftContext={leftContext}
      rightContext={
        !createPaymentMethod.data ? (
          <CheckoutSummary
            brandingSettings={brandingSettings}
            defaultCountry={customer.billing_info.country}
            defaultCurrency={customer.billing_info.currency}
            summaryHidden={true}
            summaryType="empty"
          />
        ) : null
      }
    />
  );
}
