import { useEffect, useState } from 'react';
import { Location } from 'history';
import { Prompt, useHistory } from 'react-router-dom';
import { useModal } from '../../hooks/useModal';
import { ConfirmModal } from './ConfirmModal';
import { WarningTriangleIcon } from '@increasecard/icons';

export interface RouteLeavingModalProps {
  active: boolean;
  headerText: string;
  description: string;
  cancelLabel: string;
  okLabel: string;
}

/** Prevents router navigation using a Modal to decide if continue or leave the current route.
 *
 *  It's using <Prompt /> from React Router, for more info see docs: https://reactrouter.com/core/api/Prompt */
export function RouteLeavingModal({
  active = true,
  headerText,
  description,
  cancelLabel,
  okLabel,
}: RouteLeavingModalProps): JSX.Element {
  const history = useHistory();
  const leavingModal = useModal();
  const [nextPathname, setNextPathname] = useState<string | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const closeModal = () => {
    leavingModal.close();
  };

  const handleNavigationChange = (nextLocation: Location<unknown>) => {
    if (!confirmedNavigation) {
      leavingModal.open();
      setNextPathname(nextLocation.pathname);
      return false;
    }
    return true;
  };

  const handleNavigationConfirmation = () => {
    closeModal();
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && nextPathname) {
      // Leave the current route
      history.push(nextPathname);
    }
  }, [confirmedNavigation, nextPathname, history]);

  return (
    <>
      <Prompt when={active} message={handleNavigationChange} />
      <ConfirmModal
        headerIcon={<WarningTriangleIcon height="48" width="48" />}
        header={headerText}
        message={description}
        cancelLabel={cancelLabel}
        confirmLabel={okLabel}
        onConfirm={closeModal}
        onCancel={handleNavigationConfirmation}
        onClose={closeModal}
        visible={leavingModal.isOpen}
      />
    </>
  );
}
