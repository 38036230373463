import { endOfToday, startOfMonth, subMonths } from 'date-fns';
import { FilterControl } from '../../components/FilterTagsButton/Filters';
import {
  FilterableFields,
  FilterFormConfig,
} from '../../components/FilterTagsButton/types';
import { useUrlState } from '../../hooks/useUrlState';

const DEFAULT_FILTER_VALUES = {
  created_at_from: startOfMonth(subMonths(new Date(), 1)),
  created_at_to: endOfToday(),
  customer_id: { name: 'Todos los clientes', value: '*' },
  status: { name: 'Todos los estados', value: '*' },
};

const DATE_FIELDS: FilterableFields[] = ['created_at_from', 'created_at_to'];

const filterConfig: FilterFormConfig[] = [
  {
    type: 'date_range',
    field: 'created_at_from',
    fieldTo: 'created_at_to',
    label: 'common.created_at',
  },
  {
    type: 'custom',
    field: 'customer_id',
  },
  {
    type: 'api_filter',
    field: 'status',
    filterValuesPath: 'reservations.statuses',
    label: 'common.status',
  },
];

export function ReservationsFilters(): JSX.Element {
  return (
    <FilterControl
      defaultValues={DEFAULT_FILTER_VALUES}
      config={filterConfig}
      storageKey="pay_reservations_filters"
      dateFields={DATE_FIELDS}
    />
  );
}
// This hook is suposed to be consumed by components outside of FilterProvider
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useReservationsFilters<FilterType>() {
  return useUrlState<Partial<FilterType>>({}, DATE_FIELDS);
}
