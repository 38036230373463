import { Grid, Spinner } from '@increasecard/typed-components';
import { useParams } from 'react-router-dom';
import { PlanDetail } from './components/PlanDetail';
import { PlanStats } from './components/PlanStats';
import { PlanBulkRetry } from './components/PlanBulkRetry';
import { GoBackButton } from '../../components/common/GoBackButton';
import { usePlanById } from './planQueries';
import { PencilIcon } from '@increasecard/icons';
import { useTranslation } from 'react-i18next';
import { RouterLink } from '../../components/common/RouterLink';
import { Row } from '../../components/common/Row';
import { MergedExtrasList } from '../extras/MergedExtrasList';
import { OtherDetails } from './components/OtherDetails';
import { DetailHeader } from '../../components/common/DetailHeader';

export function PlanDetailScreen(): JSX.Element {
  const { t } = useTranslation();
  const { plan_id } = useParams<{ plan_id: string }>();
  const { data: plan, isLoading } = usePlanById(plan_id);

  if (isLoading || !plan) return <Spinner size={64} />;

  return (
    <Grid rowGap={3}>
      <Row margin="0" justifyContent="space-between">
        <GoBackButton />
        <RouterLink to={`/plans/${plan_id}/edit`} icon={<PencilIcon />}>
          {t('common.edit')}
        </RouterLink>
      </Row>
      <DetailHeader
        title={`${t('common.plan')}: ${plan.name}`}
        subTitle={plan.id}
      />
      <PlanStats plan={plan} />
      <PlanDetail plan={plan} />
      <PlanBulkRetry plan={plan} />
      <MergedExtrasList entityWithExtras={plan} />
      <OtherDetails plan={plan} />
    </Grid>
  );
}
