import {
  FloatingMenu,
  FloatingMenuProps,
} from '@increasecard/typed-components';
import { FiltersForm } from './FiltersForm';
import { useFiltersState } from './FilterContext';
import { FilterableFields } from './types';

export interface FilterMenuProps {
  renderTrigger: FloatingMenuProps['renderTrigger'];
  field?: FilterableFields;
}

export function FilterMenu({
  renderTrigger,
  field,
}: FilterMenuProps): JSX.Element {
  const { setFilters, filterState, config } = useFiltersState();
  const fieldConfig = field
    ? config.filter((f) => {
        if (f.type === 'date_range') {
          return f.field === field || f.fieldTo === field;
        }
        return f.field === field;
      })
    : config;
  return (
    <FloatingMenu
      closeOnClickOutside
      onClickOutside={() => setFilters(filterState)}
      onmountOnHide={true}
      renderTrigger={renderTrigger}
    >
      {({ toggleShowMenu }) => (
        <FiltersForm
          config={fieldConfig}
          onSubmit={(newFilterValues) => {
            toggleShowMenu();
            setFilters(newFilterValues);
          }}
          value={filterState}
        />
      )}
    </FloatingMenu>
  );
}
