import isDate from 'lodash/isDate';
import { Row } from '../common/Row';
import { NameValue } from '../../types/common';
import { formatDate } from '../../utils/dateUtils';
import { FilterTag } from './FilterTag';
import { useFiltersState } from './FilterContext';
import { FilterableFields, FiltersData } from './types';

const getRenderableFilter = (value?: Date | NameValue[] | NameValue) => {
  if (Array.isArray(value)) {
    return value.map((item: NameValue) => item.name).join(', ');
  }
  if (isDate(value)) {
    return formatDate(value, 'dd/MM/yyy');
  }
  if (value?.name) {
    return value.name;
  }
  return value;
};

export interface FilterTagsProps {
  filterState: FiltersData;
  onChange: (m: Metadata) => void;
}

export function FilterTags(): JSX.Element {
  const { filterState, setFilters, deleteFilter } = useFiltersState();
  const activeFilters = Object.entries(filterState);

  return (
    <Row gap="0.5rem" flexWrap="wrap" alignItems="center" marginTop="0.5rem">
      {activeFilters.map(([key, value]) => {
        const renderableValue = getRenderableFilter(value);
        return renderableValue ? (
          <FilterTag
            key={key}
            field={key as FilterableFields}
            filterState={filterState}
            onChange={setFilters}
            onClose={() => deleteFilter(key as FilterableFields)}
          >
            {renderableValue}
          </FilterTag>
        ) : null;
      })}
    </Row>
  );
}
