import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { fetchSubscriptionDebt } from '../../services/SubscriptionService';
import { fetchDebtItems as fetchCustomerDebt } from '../../services/CustomersService';
import { DebtItem } from '../../types/Invoice';
import { NewApiError } from '../../types/NewApiError';
import {
  queryKeyFactory,
  useMutationWithInvalidation,
} from '../../utils/queryUtils';
import { queryKeys as subscriptionQueryKeys } from '../subscription/subscriptionQueries';
import { queryKeys as invoiceQueriesKeys } from '../invoice/invoiceQueries';
import { createDebtInvoice } from '../../services/InvoiceService';

const queryKeys = queryKeyFactory('debtinvoice');

export const useDebtItems = ({
  subscriptionId,
  customerId,
}: {
  subscriptionId?: string;
  customerId?: string;
}): UseQueryResult<DebtItem[], NewApiError> => {
  let queryFn;
  if (subscriptionId) {
    queryFn = () => fetchSubscriptionDebt(subscriptionId);
  } else if (customerId) {
    queryFn = () => fetchCustomerDebt(customerId);
  }
  const id = subscriptionId || customerId;
  return useQuery({
    queryKey: queryKeys.list(id),
    queryFn,
    enabled: !!id,
    select: filterMajorityByCurrency,
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useCreateDebtInvoice = () => {
  return useMutationWithInvalidation(
    createDebtInvoice,
    subscriptionQueryKeys.details(),
    invoiceQueriesKeys.lists()
  );
};

function filterMajorityByCurrency(arr: DebtItem[]): DebtItem[] {
  const currencyGroups: Record<string, DebtItem[]> = {};
  // Group items by currency
  for (const item of arr) {
    const currency = item.currency;

    if (!currencyGroups[currency]) {
      currencyGroups[currency] = [];
    }

    currencyGroups[currency].push(item);
  }
  // Find the group with the most items
  let maxGroup: DebtItem[] | null = null;
  let maxItemCount = 0;

  for (const currency in currencyGroups) {
    const itemCount = currencyGroups[currency].length;

    if (itemCount > maxItemCount) {
      maxItemCount = itemCount;
      maxGroup = currencyGroups[currency];
    }
  }
  return maxGroup || [];
}
