import { useState, CSSProperties, ChangeEvent, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  H2,
  H3,
  InputText,
  Button,
  Paragraph,
  Switch,
  Grid,
} from '@increasecard/typed-components';
import { AutorenewIcon, CancelIcon } from '@increasecard/icons';
import { ScreenSeparator } from '../../components/common/ScreenSeparator';
import { Column } from '../../components/common/Column';
import { Row } from '../../components/common/Row';
import { FloatingNotification } from '../../components/common/FloatingNotification';
import { useModal } from '../../hooks/useModal';
import { ConfirmModal } from '../../components/common/ConfirmModal';
import { Header } from '../../components/common/Header';
import { WarningButton } from '../../components/common/WarningButton';
import { useValidation } from '../../hooks/useValidation';
import {
  useAccount,
  useGenerateApiKey,
  useUpdateHooks,
} from '../../globalQueries/Queries';
import { AccountNotifications } from '../../types/Account';
import { CopyButton } from '../../components/common/CopyButton';
import { LoadingButton } from '../../components/common/LoadingButton';

const CustomGrid = styled(Grid)<{ alignItems: CSSProperties['alignItems'] }>`
  margin: 1rem 0;
  align-items: ${({ alignItems }) => alignItems || 'start'};
`;

const HOOK_NOTIFICATIONS = {
  PAYMENTS: 'payments',
  SUBSCRIPTIONS: 'subscriptions',
};

export function IntegrationsSettings(): JSX.Element {
  const { t } = useTranslation();
  const confirmModal = useModal();

  // local state
  const [secretApiKey, setSecretApiKey] = useState('');
  const [hookUrl, setHookUrl] = useState('');
  const [hookNotifications, setHookNotifications] =
    useState<AccountNotifications>([]);
  const updateSettings = useUpdateHooks();
  const generateApiKey = useGenerateApiKey();
  const account = useAccount();

  const hookUrlValidation = useValidation({ value: hookUrl, schema: 'url' });

  const translations: Metadata = t('screens.integrations', {
    returnObjects: true,
  });

  const isCopyDisabled = secretApiKey.includes('*');

  // This is not ideal, but will work for now.
  useEffect(() => {
    if (account.data) {
      const { notifications, events_hooks_url, secret_api_key } = account.data;
      setHookNotifications(notifications);
      setHookUrl(events_hooks_url || '');
      if (secret_api_key) {
        setSecretApiKey('**********************************');
      } else if (typeof secret_api_key === 'string') {
        setSecretApiKey(secret_api_key);
      }
    }
  }, [account.data]);

  const notificationsChangeHandle = (...e: ChangeEvent<HTMLInputElement>[]) => {
    // Temporary fix until Switch onChange type is fixed
    const { name, checked } = e[0].target;
    if (checked) {
      setHookNotifications([
        ...hookNotifications,
        name,
      ] as AccountNotifications);
    } else {
      setHookNotifications(hookNotifications.filter((n) => n !== name));
    }
  };

  const generateAPIKeyHandle = () => {
    if (secretApiKey) {
      confirmModal.open();
    } else {
      generateApiKey.mutate(null, {
        onSuccess: ({ secret_api_key }) => setSecretApiKey(secret_api_key),
      });
    }
  };

  const confirmGenerateHandle = () => {
    generateApiKey.mutate(null, {
      onSuccess: ({ secret_api_key }) => {
        setSecretApiKey(secret_api_key);
        confirmModal.close();
      },
    });
    confirmModal.close();
  };

  const handleHookUrlChange = (e: ChangeEvent<HTMLInputElement>) => {
    setHookUrl(e.target.value);
  };

  const handleSaveHooks = () => {
    if (!hookUrlValidation.isValid) return;
    updateSettings.mutate({
      events_hooks_url: hookUrl,
      notifications: hookNotifications,
    });
  };

  const clearHookUrlHandle = () => {
    setHookUrl('');
  };

  const handleResetHookConfig = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { notifications, events_hooks_url } = account.data!;
    setHookNotifications(notifications);
    setHookUrl(events_hooks_url || '');
  };

  return (
    <>
      <Header
        title={t('screens.settings.menu.integrations')}
        description={translations.description}
      />
      <ScreenSeparator margin="2rem 0 0.5rem" />
      <H2>{translations.increase_api_key}</H2>
      <CustomGrid alignItems="end">
        <InputText
          value={secretApiKey}
          className="grid-span-desktop-4"
          label={translations.token}
          readOnly
        />
        <Row
          className="grid-span-desktop-3"
          marginTop="0"
          marginBottom="0"
          flexGap="1rem"
        >
          {!isCopyDisabled && <CopyButton value={secretApiKey} />}
          <Button
            onClick={generateAPIKeyHandle}
            buttonType="invisible"
            icon={<AutorenewIcon />}
          >
            {secretApiKey ? translations.renew_key : translations.generate_key}
          </Button>
        </Row>
      </CustomGrid>
      <ScreenSeparator margin="4rem 0 0.5rem" />
      <H2>{translations.webhooks}</H2>
      <Paragraph>{translations.webhooks_description}</Paragraph>
      <CustomGrid alignItems="end">
        <InputText
          label={t('common.url')}
          className="grid-span-desktop-4"
          value={hookUrl}
          onChange={handleHookUrlChange}
          errorMessage={hookUrlValidation.errorMessage}
        />
        <WarningButton
          icon={<CancelIcon />}
          className="grid-span-desktop-2"
          onClick={clearHookUrlHandle}
        >
          {translations.clean_hook}
        </WarningButton>
      </CustomGrid>
      <H3>{translations.events}</H3>
      <Column flexGap="1rem">
        <Switch
          label={t('common.payments')}
          id={HOOK_NOTIFICATIONS.PAYMENTS}
          name={HOOK_NOTIFICATIONS.PAYMENTS}
          checked={hookNotifications.includes('payments')}
          onChange={notificationsChangeHandle}
        />
        <Switch
          label={t('common.subscriptions')}
          id={HOOK_NOTIFICATIONS.SUBSCRIPTIONS}
          name={HOOK_NOTIFICATIONS.SUBSCRIPTIONS}
          checked={hookNotifications.includes('subscriptions')}
          onChange={notificationsChangeHandle}
        />
      </Column>
      <Row flexGap="1rem" marginTop="2rem">
        <LoadingButton
          isLoading={updateSettings.isLoading}
          disabled={!hookUrlValidation.isValid}
          onClick={handleSaveHooks}
        >
          {t('common.save')}
        </LoadingButton>
        <Button buttonType="invisible" onClick={handleResetHookConfig}>
          {t('common.cancel')}
        </Button>
      </Row>
      <ConfirmModal
        headerIcon={<AutorenewIcon width="48" height="48" />}
        visible={confirmModal.isOpen}
        header={translations.confirm_modal.header}
        message={translations.confirm_modal.body}
        confirmLabel={translations.confirm_modal.confirm}
        onConfirm={confirmGenerateHandle}
        onClose={confirmModal.close}
      />
      {updateSettings.isSuccess && (
        <FloatingNotification
          bodyText={t('notifications.save_success')}
          hideTimeout={3000}
          type="success"
        />
      )}
      {updateSettings.isError && (
        <FloatingNotification
          bodyText={t(`api.errors.${updateSettings.error.code}`)}
          hideTimeout={3000}
          type="error"
        />
      )}
    </>
  );
}
