import { CSSProperties, useState } from 'react';
import styled from 'styled-components';
import {
  FloatingNotification as Notification,
  FloatingProps,
} from '@increasecard/typed-components';
import { useTimeout } from '../../hooks/useTimeout';
import { CloseIcon } from '@increasecard/icons';

export type Position = Pick<CSSProperties, 'top' | 'left' | 'right' | 'bottom'>;

const Container = styled.div<{
  position: Position;
}>`
  position: absolute;
  ${({ position }) => position}
`;

export interface FloatingNotificationProps extends FloatingProps {
  position?: Position;
  hideTimeout: number | null;
}

export function FloatingNotification({
  position = { top: '0.5rem', right: '0.5rem' },
  hideTimeout = null,
  ...rest
}: FloatingNotificationProps): JSX.Element | null {
  const [hide, setHide] = useState(false);
  useTimeout(() => {
    setHide(true);
  }, hideTimeout);

  const closeHandle = () => setHide(true);
  if (hide) return null;
  return (
    <Container position={position}>
      <Notification
        {...rest}
        onCancel={closeHandle}
        cancelText={(<CloseIcon />) as unknown as string}
      />
    </Container>
  );
}
