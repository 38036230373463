import { ChangeEvent, useEffect } from 'react';
import styled, { css } from 'styled-components';
import {
  Table,
  InputNumber,
  InputText,
  MenuButton,
  MenuItem,
  Button,
} from '@increasecard/typed-components';
import { PlusSignIcon } from '@increasecard/icons';
import { formatCurrencyType } from '../../utils/currencyUtils';
import { useList } from '../../hooks/useList';
import { InputCurrency } from '../../components/common/InputCurrency';
import { useTranslation } from 'react-i18next';
import { Currency } from '../../types/common';
import { InvoiceItem } from '../../types/Invoice';

// TODO: Refactor, use <ArrayField /> with formik + yup to create dynamic form

const flexStyle = css`
  display: flex;
  align-items: initial;
`;

const StyledInputText = styled(InputText)`
  ${flexStyle}
`;
const StyledCurrency = styled(InputCurrency)`
  ${flexStyle}
`;
const StyledInputNumber = styled(InputNumber)`
  ${flexStyle}
`;

const calculateAmount = (quantity?: string, price?: number) =>
  (price || 0) * parseFloat(quantity || '0');

export type NewInvoiceItem = Pick<
  InvoiceItem,
  'description' | 'quantity' | 'amount' | 'currency' | 'id'
> & { unit_price?: number };

export interface NewInvoiceItemsProps {
  onChange: (list: NewInvoiceItem[]) => void;
  currency: Currency;
  language: string;
}

export function NewInvoiceItems({
  onChange,
  currency,
  language,
}: NewInvoiceItemsProps): JSX.Element {
  const initialItem: Omit<NewInvoiceItem, 'id'> = {
    description: '',
    quantity: '1',
    unit_price: 0,
    amount: '',
    currency,
  };
  const itemList = useList({ initialState: [initialItem] });

  const { t } = useTranslation();
  const itemActions = [
    {
      label: t('common.delete'),
      cb: (item: NewInvoiceItem) => itemList.remove(item.id),
    },
  ];

  const onQuantityChange =
    (item: NewInvoiceItem) =>
    ({ target }: ChangeEvent<HTMLInputElement>) =>
      itemList.update(item.id, {
        quantityTouched: true,
        quantity: target.value,
        amount: calculateAmount(target.value, item.unit_price),
      });

  const onDescriptionChange =
    (item: NewInvoiceItem) =>
    ({ target }: ChangeEvent<HTMLInputElement>) =>
      itemList.update(item.id, {
        descriptionTouched: true,
        description: target.value,
      });

  const onUnitPriceChange = (item: NewInvoiceItem) => (newPrice: number) =>
    itemList.update(item.id, {
      unitPriceTouched: true,
      unit_price: newPrice,
      amount: calculateAmount(item.quantity, newPrice),
    });

  const onAddItem = () => {
    itemList.add({
      ...initialItem,
    });
  };

  useEffect(() => {
    // since currency is selected after item creation,
    // Make sure all items have the currency selected
    onChange(itemList.list.map((item) => ({ ...item, currency })));
  }, [itemList.list, currency, onChange]);

  return (
    <>
      <Table zebra>
        <thead>
          <tr>
            <th id="invoice_description">{t('common.description')}</th>
            <th id="invoice_quantity">{t('common.quantity')}</th>
            <th id="invoice_price">{t('common.unit_price')}</th>

            <th>{t('common.subtotal')}</th>
            <th className="rightAligned"></th>
          </tr>
        </thead>
        <tbody>
          {itemList.list.map((item) => (
            <tr key={item.id}>
              <td>
                <StyledInputText
                  aria-labelledby="invoice_description"
                  className="grid-span-desktop-6"
                  id="description"
                  width="100%"
                  errorMessage={
                    item.descriptionTouched && !item.description
                      ? t('validation.required')
                      : null
                  }
                  autoComplete="off"
                  value={item.description}
                  onChange={onDescriptionChange(item)}
                  textAlign="left"
                  placeholder={t('screens.new_invoice.add_description')}
                />
              </td>
              <td>
                <StyledInputNumber
                  aria-labelledby="invoice_quantity"
                  type="number"
                  id="quantity"
                  errorMessage={
                    item.quantityTouched && !item.quantity
                      ? t('validation.required')
                      : null
                  }
                  value={item.quantity}
                  onChange={onQuantityChange(item)}
                  min={1}
                />
              </td>
              <td>
                <StyledCurrency
                  aria-labelledby="invoice_price"
                  className="grid-span-desktop-4"
                  id="unit_price"
                  errorMessage={
                    item.unitPriceTouched && !item.unit_price
                      ? t('validation.required')
                      : null
                  }
                  value={item.unit_price}
                  onChange={onUnitPriceChange(item)}
                  label={''}
                />
              </td>

              <td className="rightAligned">
                {formatCurrencyType('flat', item.amount, currency, language)}
              </td>
              <td className="rightAligned">
                <MenuButton>
                  {() =>
                    itemActions.map(({ label, cb }) => (
                      <MenuItem onClick={() => cb(item)} key={label}>
                        {label}
                      </MenuItem>
                    ))
                  }
                </MenuButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Button
        className="grid-span-desktop-3"
        onClick={onAddItem}
        buttonType="invisible"
        icon={<PlusSignIcon />}
        size="small"
      >
        {t('screens.new_invoice.add_item')}
      </Button>
    </>
  );
}
