import { CellText, H2, TableWrapper } from '@increasecard/typed-components';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { DefaultTheme, ThemeContext } from 'styled-components';
import { FullPageSpinner } from '../../components/common/FullPageSpinner';
import { HelpButton } from '../../components/common/HelpButton';
import { Row } from '../../components/common/Row';
import { ErrorMessage } from '../../components/ErrorMessage';
import { Retries } from '../../types/Metrics';
import { formatCurrency } from '../../utils/currencyUtils';
import { numberCompactFormat } from '../../utils/utils';
import { CHART_TOOLTIP_STYLE, getYAxisLabel } from './helpers';
import { useRetryPerformanceChart } from './summaryQueries';
import { useMetricsFilters } from './useMetricsFilters';

function getRetriesColors({ colorsNew }: DefaultTheme) {
  const retriesColors: Record<Retries, string> = {
    total_approved: colorsNew.pay.dark,
    approved_attempt_1: colorsNew.pay.regular,
    approved_attempt_2: colorsNew.pay.light50,
    approved_attempt_3_or_more: colorsNew.pay.light70,
  };
  return retriesColors;
}

const retries: Retries[] = [
  'total_approved',
  'approved_attempt_1',
  'approved_attempt_2',
  'approved_attempt_3_or_more',
];

interface RetryPerformanceProps {
  className?: string;
}

export function RetryPerformanceChart({
  className,
}: RetryPerformanceProps): JSX.Element {
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);
  const [filters] = useMetricsFilters();
  const { data, error, isSuccess, isLoading, refetch } =
    useRetryPerformanceChart(filters);
  const translationKey = 'metrics.retryPerformanceChart';
  const yAxisLabel = getYAxisLabel(t(`${translationKey}.yAxis`));
  const colors = getRetriesColors(themeContext);
  return (
    <div>
      <Row alignItems="center" gap="0.5rem" margin="0 0 0.5rem">
        <H2>{t(`${translationKey}.title`)}</H2>
        <HelpButton
          titleKey={`${translationKey}.title`}
          descriptionKey={`${translationKey}.help.description`}
          noteKey={`${translationKey}.help.note`}
        />
      </Row>
      <TableWrapper className={className}>
        {error && (
          <ErrorMessage onClick={() => refetch()}>
            {t('api.errors.unexpected_error')}
          </ErrorMessage>
        )}
        {isLoading && <FullPageSpinner height="270px" />}
        {isSuccess && data && (
          <ResponsiveContainer height={270}>
            <BarChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <XAxis
                dataKey="time"
                padding={{ left: 30, right: 30 }}
                tick={{ dy: 8, fontSize: 13 }}
                tickSize={5}
              />
              <YAxis
                tickFormatter={numberCompactFormat}
                tick={{ fontSize: 13 }}
                label={yAxisLabel}
              />
              <Tooltip
                {...CHART_TOOLTIP_STYLE}
                formatter={(value: string, name: string) => [
                  formatCurrency(parseFloat(value), filters.currency || 'ARS'),
                  t(`metrics.common.retries.${name}`) as string,
                ]}
              />
              {retries.map((retry) => {
                const fillColor = colors[retry];
                return (
                  <Bar
                    isAnimationActive={false}
                    legendType="none"
                    dataKey={retry}
                    key={retry}
                    stackId="a"
                    fill={fillColor}
                  />
                );
              })}
              <Legend
                iconType="circle"
                wrapperStyle={{ paddingTop: '1rem' }}
                formatter={(value) => (
                  <CellText inline style={{ color: 'black' }}>
                    {t(`metrics.common.retries.${value}`)}
                  </CellText>
                )}
              />
            </BarChart>
          </ResponsiveContainer>
        )}
      </TableWrapper>
    </div>
  );
}
