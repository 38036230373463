import { ChangeEvent, useState } from 'react';
import {
  ModalContent,
  Paragraph,
  RadioButton,
  InputNumber,
  CellText,
} from '@increasecard/typed-components';
import { useTranslation } from 'react-i18next';
import { useRadioButtons } from '../../../hooks/useRadioButtons';
import { Subscription } from '../../../types/Subscription';
import { Column } from '../../../components/common/Column';
import { MassiveActionResults } from './MassiveActionResults';
import { usePauseSubcriptions } from '../subscriptionQueries';
import { MassiveActionFooter } from './MassiveActionsFooter';

export interface PauseModalContentProps {
  onClose: VoidFunction;
  onSuccess?: VoidFunction;
  subscription: Subscription[];
}

interface PauseMessageProps {
  subscription: Subscription[];
}

function PauseMessage({ subscription }: PauseMessageProps) {
  const { t } = useTranslation();
  return (
    <div style={{ marginBottom: '1rem' }}>
      <Paragraph>
        {t('screens.subscriptions.pause.subtitle', {
          count: subscription.length,
        })}
      </Paragraph>
      {subscription.length === 1 ? (
        <Paragraph weight="bold">
          {t('screens.subscriptions.pause.subtitle2', {
            product: subscription[0].product.name,
            plan: subscription[0].plan.name,
          })}
        </Paragraph>
      ) : null}
    </div>
  );
}

export function PauseModalContent({
  onClose,
  onSuccess,
  subscription,
}: PauseModalContentProps): JSX.Element | null {
  const { t } = useTranslation();
  const [pausePeriodType, periodInputProps] = useRadioButtons(
    'pausePeriodType',
    'n'
  );
  const [periodsAmount, setPeriodsAmount] = useState(1);

  const { mutate, error, isError, isSuccess, isLoading } =
    usePauseSubcriptions();

  const handlePause = () =>
    mutate(
      {
        subscriptions: subscription,
        periods: pausePeriodType === 'n' ? periodsAmount : undefined,
      },
      { onSuccess }
    );

  const onPeriodsAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (Number.isNaN(value)) {
      return;
    }
    setPeriodsAmount(Number(value));
  };

  if (!subscription) return null;

  return (
    <>
      <ModalContent align="center">
        {!isError && !isSuccess && (
          <>
            <PauseMessage subscription={subscription} />
            <RadioButton
              checked={pausePeriodType === 'n'}
              label={t('common.periods_quantity')}
              value="n"
              {...periodInputProps}
            />
            <Column marginLeft="1.75rem" textAlign="left">
              <CellText>
                {t('screens.subscriptions.pause.period_info')}
              </CellText>
              <InputNumber
                id="periods_amount"
                autoComplete="off"
                value={periodsAmount}
                onChange={onPeriodsAmountChange}
                disabled={pausePeriodType !== 'n'}
                placeholder="1"
                size="2"
                min={1}
                max={99}
              />
            </Column>
            <RadioButton
              checked={pausePeriodType === 'infinite'}
              label={t('common.indefinitely')}
              value="infinite"
              {...periodInputProps}
            />
          </>
        )}
        <MassiveActionResults
          subscriptions={subscription}
          error={error}
          isSuccess={isSuccess}
          actionType="pause"
        />
      </ModalContent>
      <MassiveActionFooter
        onClose={onClose}
        onConfirm={handlePause}
        isLoading={isLoading}
        isError={isError}
        isSuccess={isSuccess}
        confirmLabel={t('screens.subscriptions.pause.confirm', {
          count: subscription.length,
        })}
      />
    </>
  );
}
