import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { FullPageSpinner } from '../../components/common/FullPageSpinner';
import { useModal } from '../../hooks/useModal';
import { ConfirmModal } from '../../components/common/ConfirmModal';
import { H1 } from '@increasecard/typed-components';
import { ApiErrorMessage } from '../../components/ErrorMessage';
import { filterUnchangedKeys } from '../../utils/apiUtils';
import { CustomerForm, CustomerFormValues } from './CustomerForm';
import { MdiCheckCircleIcon } from '@increasecard/icons';
import { useCustomerById } from '../../globalQueries/Queries';
import { useUpdateCustomer } from './customerQueries';

export function EditCustomerScreen(): JSX.Element {
  const { t } = useTranslation();
  const successModal = useModal();
  const { customer_id: customerId } = useParams<{ customer_id: string }>();
  const history = useHistory();

  const customer = useCustomerById(customerId);
  const updateCustomer = useUpdateCustomer();

  const handleSubmit = (customerData: CustomerFormValues) => {
    updateCustomer.mutate(
      {
        id: customerId,
        ...filterUnchangedKeys(customerData, customer.data),
      },
      { onSuccess: () => successModal.open() }
    );
  };

  const gotToCustomersScreen = () => {
    history.replace('/customers');
  };

  if (customer.error) return <ApiErrorMessage error={customer.error} />;

  if (customer.isLoading || !customer.data) return <FullPageSpinner />;

  return (
    <>
      <H1 style={{ marginBottom: '3rem' }}>
        {t('screens.customer_form.update_title', {
          name: `${customer.data?.first_name} ${customer.data?.last_name}`,
        })}
      </H1>
      <CustomerForm
        initialValue={customer.data}
        submitText={t('common.save')}
        onSubmit={handleSubmit}
        onCancel={gotToCustomersScreen}
        isSubmitting={updateCustomer.isLoading}
        error={updateCustomer.error}
      />
      <ConfirmModal
        headerIcon={<MdiCheckCircleIcon width="48" height="48" />}
        visible={successModal.isOpen}
        header={t(`screens.customer_form.success_edit`)}
        confirmLabel={t('common.accept')}
        onConfirm={gotToCustomersScreen}
        onClose={gotToCustomersScreen}
      />
    </>
  );
}
