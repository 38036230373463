import { apiFront } from './api';
import { UserNotificationSettings } from '../types/UserNotificationSettings';

export async function getNotificationSettings(
  userId: string
): Promise<UserNotificationSettings> {
  const response = await apiFront.get(`users/${userId}/notification_settings`);
  return response.data;
}

export async function updateNotificationSettings(
  userId: string,
  settings: UserNotificationSettings
): Promise<UserNotificationSettings> {
  const response = await apiFront.put(
    `users/${userId}/notification_settings`,
    settings
  );
  return response.data;
}
