import { FilterControl } from '../../../components/FilterTagsButton/Filters';
import { FilterFormConfig } from '../../../components/FilterTagsButton/types';
import { useUrlState } from '../../../hooks/useUrlState';

const DEFAULT_FILTER_VALUES = {
  product_id: { name: 'Todos los productos', value: '*' },
  status: { name: 'Todos los estados', value: '*' },
  type: { name: 'Todos los tipos', value: '*' },
  country: undefined,
  cycle_unit: undefined,
  search: undefined,
};

const filterConfig: FilterFormConfig[] = [
  {
    type: 'search',
    field: 'search',
    label: 'common.search',
  },
  {
    type: 'custom',
    field: 'product_id',
  },
  {
    type: 'api_filter',
    field: 'status',
    filterValuesPath: 'plans.statuses',
    label: 'common.status',
  },
  {
    type: 'api_filter',
    field: 'country',
    filterValuesPath: 'countries',
    label: 'common.country',
  },
  {
    type: 'api_filter',
    field: 'type',
    filterValuesPath: 'plans.types',
    label: 'common.type',
  },
  {
    type: 'api_filter',
    field: 'cycle_unit',
    filterValuesPath: 'plans.cycle_units',
    label: 'data.plan.cycle',
  },
];

export function PlanFilters(): JSX.Element {
  return (
    <FilterControl
      defaultValues={DEFAULT_FILTER_VALUES}
      config={filterConfig}
      storageKey="pay_plan_filters"
    />
  );
}

// This hook is suposed to be consumed by components outside of FilterProvider
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function usePlanFilters<FilterType>() {
  return useUrlState<Partial<FilterType>>({});
}
