import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';
import { Box } from './Box';

const flexMixin = css<{ childrenFlex?: CSSProperties['flex'] }>`
  & > * {
    flex: ${({ childrenFlex }) => childrenFlex};
  }
`;

const gapMixin = css<{
  flexGap: CSSProperties['marginRight'];
}>`
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    & > *:not(:last-child) {
      margin-right: ${({ flexGap }) => flexGap};
      margin-bottom: 0;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    & > *:not(:last-child) {
      margin-bottom: ${({ flexGap }) => flexGap};
      margin-right: 0;
    }
  }
`;

const CustomBox = styled(Box)<{
  flexGap: CSSProperties['marginRight'];
  childrenFlex?: CSSProperties['flex'];
}>`
  ${({ childrenFlex }) => childrenFlex && flexMixin};
  ${({ flexGap }) => flexGap && gapMixin};
`;

export type RowProps = typeof CustomBox.arguments;

export function Row({ children, ...rest }: RowProps): JSX.Element {
  return (
    <CustomBox
      display="flex"
      flexDirection="row"
      width="100%"
      margin="1rem 0"
      {...rest}
    >
      {children}
    </CustomBox>
  );
}
