import { Header } from '../../components/common/Header';
import { useTranslationObject, Trans } from '../../hooks/useTranslationObject';
import {
  Paragraph,
  Spinner,
  H2,
  Switch,
  Button,
  Checkbox,
  Grid,
  CellText,
} from '@increasecard/typed-components';
import { Box } from '../../components/common/Box';
import { Row } from '../../components/common/Row';
import { ProgressBox } from '../../components/common/ProgressBox';
import { ErrorMessage } from '../../components/ErrorMessage';
import { FloatingNotification } from '../../components/common/FloatingNotification';
import { useNotificationSettings } from './useNotificationSettings';
import { UserNotificationSettings } from '../../types/UserNotificationSettings';
import { capitalize } from '../../utils/utils';
import { ScreenSeparator } from '../../components/common/ScreenSeparator';
import styled from 'styled-components';

const SummaryDescription = styled(CellText)`
  color: var(--color-gray-blackSLight);
  margin-left: 2.7rem;
`;

const ROOT_TRANSLATION_KEY = 'screens.notifications';

export function NotificationsSettings(): JSX.Element {
  const { translations, t } = useTranslationObject(ROOT_TRANSLATION_KEY);
  const {
    fetch,
    settings,
    user,
    toggleSetting,
    save,
    cancel,
    showCancel,
    fetchError,
    showSaveSuccess,
    saveError,
  } = useNotificationSettings();

  return (
    <>
      <Header
        title={translations.title_merchants}
        description={
          <Trans
            t={t}
            i18nKey={`${ROOT_TRANSLATION_KEY}.subtitle`}
            values={{
              email: user?.email,
            }}
          ></Trans>
        }
      />
      <ScreenSeparator margin="16px 0 32px 0" />
      {!settings && !fetchError && <Spinner size={16} />}
      {fetchError !== null && (
        <ErrorMessage onClick={fetch}>{t(fetchError)}</ErrorMessage>
      )}
      {showSaveSuccess && (
        <FloatingNotification
          bodyText={t('notifications.save_success')}
          hideTimeout={3000}
          type="success"
        />
      )}
      {saveError && (
        <FloatingNotification
          bodyText={t(`api.errors.${saveError.code}`)}
          hideTimeout={3000}
          type="error"
        />
      )}
      {settings && (
        <Grid rowGap={2}>
          <H2>{translations.notifications_usage}</H2>
          <Paragraph>
            <Trans
              t={t}
              i18nKey={`${ROOT_TRANSLATION_KEY}.info_mail_quota`}
              values={{
                quota: settings.max_emails_per_month,
              }}
            ></Trans>
          </Paragraph>
          <ProgressBox
            max={settings.max_emails_per_month}
            value={settings.emails_sent_current_month}
            message={t(`${ROOT_TRANSLATION_KEY}.emails_sent`, {
              n: settings.emails_sent_current_month,
            })}
          />
          <ScreenSeparator margin="16px 0 16px 0" />
          <H2>{t('common.payment_orders')}</H2>
          <Grid rowGap={1}>
            <Switch
              checked={settings.payments.daily_summary}
              onChange={() => toggleSetting('payments.daily_summary')}
              id="payments.daily_summary"
              name="payments.daily_summary"
              label={translations.daily_summary_label}
              size="normal"
            />
            <SummaryDescription>
              {t('screens.notifications.daily_summary_description', {
                entity: t('common.payments'),
              })}
            </SummaryDescription>
          </Grid>
          <div>
            <Paragraph>{translations.event_selection}</Paragraph>
            <Box marginLeft="2.7rem">
              {Object.keys(settings.payments)
                .filter(
                  (eventName: string) =>
                    !['event_summary', 'daily_summary'].includes(eventName)
                )
                .map((eventName) => {
                  const id = `payments.${eventName}`;
                  const label = translations.merchant_payment_events[eventName];
                  return (
                    <Row key={id} alignItems="flex-start">
                      <Checkbox
                        id={id}
                        name={id}
                        label={label}
                        checked={
                          settings.payments[
                            eventName as keyof UserNotificationSettings['payments']
                          ]
                        }
                        onChange={() => toggleSetting(id)}
                        size="small"
                      />
                    </Row>
                  );
                })}
            </Box>
          </div>
          <H2>{capitalize(t('common.subscriptions'))}</H2>
          <Grid rowGap={1}>
            <Switch
              checked={settings.subscriptions.daily_summary}
              onChange={() => toggleSetting('subscriptions.daily_summary')}
              id="subscriptions.daily_summary"
              name="subscriptions.daily_summary"
              label={translations.daily_summary_label}
              size="normal"
            />
            <SummaryDescription>
              {t('screens.notifications.daily_summary_description', {
                entity: t('common.subscriptions'),
              })}
            </SummaryDescription>
          </Grid>
          <div>
            <Paragraph>{translations.event_selection}</Paragraph>
            <Box marginLeft="2.7rem">
              {translations.subscriptions_events.map(
                ({ event, label }: Metadata) => {
                  if (!(event in settings.subscriptions)) {
                    return null;
                  }
                  const id = `subscriptions.${event}`;
                  return (
                    <Row key={id} alignItems="flex-start">
                      <Checkbox
                        id={id}
                        name={id}
                        label={label}
                        checked={
                          settings.subscriptions[
                            event as keyof UserNotificationSettings['subscriptions']
                          ]
                        }
                        onChange={() => toggleSetting(id)}
                        size="small"
                      />
                    </Row>
                  );
                }
              )}
            </Box>
          </div>
          <Row flexGap="1rem" marginTop="2rem">
            <Button onClick={save} disabled={!showCancel}>
              {t('common.save')}
            </Button>
            {showCancel && (
              <Button buttonType="invisible" onClick={cancel}>
                {t('common.cancel')}
              </Button>
            )}
          </Row>
        </Grid>
      )}
    </>
  );
}
