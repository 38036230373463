import { CogIcon } from '@increasecard/icons';
import {
  Modal,
  ModalContent,
  ModalHeaderIconBox,
} from '@increasecard/typed-components';
import { CollectMethod } from '../../types';
import { CollectMethodForm } from './CollectMethodForm';
import { useCreateOrUpdateCollectMethod } from './collectMethodQueries';
import { PanelFormConfig } from './collectMethodsFormConfig/PanelConfig';

const MODAL_WIDTH = '600';

export interface EditCollectMethodModalProps {
  onClose: () => void;
  onExited: () => void;
  visible?: boolean;
  collectMethod: CollectMethod | null;
}

export function EditCollectMethodModal({
  onClose,
  onExited,
  visible,
  collectMethod,
}: EditCollectMethodModalProps): JSX.Element {
  const { mutate, error, isLoading } = useCreateOrUpdateCollectMethod();

  const handleSubmit = (values: Partial<PanelFormConfig['initialValues']>) => {
    if (collectMethod) {
      mutate({
        ...values,
        type: collectMethod.type,
      });
    }
  };
  return (
    <Modal
      shadeClosable={false}
      align="center"
      onClose={onClose}
      visible={visible || false}
      transitionProps={{
        onExited,
      }}
      width={MODAL_WIDTH}
      onCancel={() => {
        return;
      }}
      onOk={() => {
        return;
      }}
    >
      {collectMethod && (
        <ModalHeaderIconBox
          colorPalette="pay"
          icon={<CogIcon width="48px" height="48px" />}
        />
      )}
      <ModalContent>
        {collectMethod && (
          <CollectMethodForm
            type={collectMethod.type}
            collectMethod={collectMethod}
            onClose={onClose}
            onSubmit={handleSubmit}
            error={error}
            isLoading={isLoading}
          />
        )}
      </ModalContent>
    </Modal>
  );
}
