import { FilterControl } from '../../../components/FilterTagsButton/Filters';
import {
  FilterFormConfig,
  FilterableFields,
  FilterUrlState,
} from '../../../components/FilterTagsButton/types';
import { useUrlState } from '../../../hooks/useUrlState';

const DEFAULT_FILTER_VALUES = {
  customer_id: { name: 'Todos los clientes', value: '*' },
  status: { name: 'Todos los estados', value: '*' },
  kind: { name: 'Todos los tipos', value: '*' },
  currency: { name: 'Todas las divisas', value: '*' },
};

const filterConfig: FilterFormConfig[] = [
  {
    type: 'custom',
    field: 'customer_id',
  },
  {
    type: 'api_filter',
    field: 'status',
    filterValuesPath: 'invoices.statuses',
    label: 'common.status',
  },
  {
    type: 'api_filter',
    field: 'kind',
    filterValuesPath: 'invoices.kinds',
    label: 'common.type',
  },
  {
    type: 'api_filter',
    field: 'currency',
    filterValuesPath: 'currencies',
    label: 'common.currency',
  },
];

export function InvoiceFilters({
  omit,
}: {
  omit?: FilterableFields[];
}): JSX.Element {
  const config = omit
    ? filterConfig.filter((f) => !omit.includes(f.field))
    : filterConfig;
  const defaultValues = omit
    ? Object.fromEntries(
        Object.entries(DEFAULT_FILTER_VALUES).filter(
          ([key]) => !omit?.includes(key as FilterableFields)
        )
      )
    : DEFAULT_FILTER_VALUES;
  return (
    <FilterControl
      defaultValues={defaultValues}
      config={config}
      storageKey="pay_invoice_filters"
    />
  );
}

// This hook is suposed to be consumed by components outside of FilterProvider
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useInvoiceFilters() {
  return useUrlState<Partial<FilterUrlState>>({});
}
