import { InputText, InputTextProps } from '@increasecard/typed-components';
import { useTranslation } from 'react-i18next';
import NumericFormat, { NumberFormatValues } from 'react-number-format';

export interface InputCurrencyProps
  extends Omit<InputTextProps, 'label' | 'autocomplete' | 'step' | 'onChange'> {
  onChange: (value: number) => void;
}

export function InputCurrency({
  value,
  onChange,
  ...props
}: InputCurrencyProps): JSX.Element {
  const { t } = useTranslation();

  const handleAmountChange = ({ floatValue }: NumberFormatValues) => {
    onChange(floatValue || 0);
  };
  return (
    <NumericFormat
      thousandSeparator="."
      decimalSeparator=","
      allowedDecimalSeparators={[',', '.']}
      customInput={InputText}
      id="amount"
      label={t('common.amount')}
      value={value === 0 || !value ? null : Number(value)}
      defaultValue={0}
      onValueChange={handleAmountChange}
      {...props}
    />
  );
}
