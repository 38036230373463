import { Grid, H3 } from '@increasecard/typed-components';
import range from 'lodash/range';
import { useTranslation } from 'react-i18next';
import { SelectField } from '../../components/common/FormikFields';

const MAX_PAYMENT_RETRIES_VALUES = [
  {
    name: 'Sin reintentos',
    value: '0',
  },
  ...range(1, 11).map((item) => {
    return { name: item.toString(), value: item.toString() };
  }),
];

const RETRY_PERIOD_TIME_VALUES = range(1, 100).map((item) => {
  return { name: item.toString(), value: item.toString() };
});

const RETRY_PERIOD_UNIT_VALUES = [
  { name: 'Minuto', value: 'minutes' },
  { name: 'Hora', value: 'hours' },
  { name: 'Día', value: 'days' },
];

export function RetriesConfig({
  disabled,
}: {
  disabled?: boolean;
}): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      <H3>{t('screens.collect_methods.fail_payments_management')}</H3>
      <Grid rowGap={2}>
        <SelectField
          id="max_payment_retries"
          options={MAX_PAYMENT_RETRIES_VALUES}
          label={t('screens.collect_methods.max_payment_retries')}
          className="grid-span-desktop-4"
          disabled={disabled}
        />
        <SelectField
          id="retry_period_time"
          options={RETRY_PERIOD_TIME_VALUES}
          label={t('screens.collect_methods.retry_period_time')}
          className="grid-span-desktop-4"
          disabled={disabled}
        />
        <SelectField
          id="retry_period_unit"
          options={RETRY_PERIOD_UNIT_VALUES}
          label={t('screens.collect_methods.retry_period_unit')}
          className="grid-span-desktop-4"
          disabled={disabled}
        />
      </Grid>
    </>
  );
}
