import { CellText } from '@increasecard/typed-components';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from '../../../components/common/Column';
import { Select } from '../../../components/common/SystemSelect';
import { PLAN_TYPES } from '../../../utils/planUtils';
import { list2NameValue } from '../../../utils/utils';

export interface PlanTypeSelectorProps {
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  value: string;
  disabled?: boolean;
}

export function PlanTypeSelector({
  onChange,
  value,
  disabled,
}: PlanTypeSelectorProps): JSX.Element {
  const { t } = useTranslation();
  const TYPE_OPTIONS = list2NameValue(
    Object.values(PLAN_TYPES),
    'data.plan.types'
  );
  return (
    <Column margin="0" gap="0.5rem" className="span-desktop-3">
      <Select
        id="type"
        onChange={onChange}
        options={TYPE_OPTIONS}
        value={value}
        label={t('data.plan.pricing_model')}
        disabled={disabled}
      />
      {value ? (
        <CellText>{t(`screens.new_plan.plan_descriptions.${value}`)}</CellText>
      ) : null}
    </Column>
  );
}
