import { Country } from './types/common';

type idType = {
  name: string;
  id: string;
};

type IdentificationTypes = Partial<Record<Country, idType[]>>;

export const IDENTIFICATION_NUMBER_TYPES: IdentificationTypes = {
  ARG: [
    {
      id: 'DNI',
      name: 'DNI',
    },
    {
      id: 'CI',
      name: 'Cédula',
    },
    {
      id: 'LC',
      name: 'L.C.',
    },
    {
      id: 'LE',
      name: 'L.E.',
    },
    {
      id: 'Otro',
      name: 'Otro',
    },
  ],
  COL: [
    {
      id: 'CC',
      name: 'C.C.',
    },
    {
      id: 'CE',
      name: 'C.E.',
    },
    {
      id: 'NIT',
      name: 'NIT',
    },
    {
      id: 'Otro',
      name: 'Otro',
    },
  ],
  PER: [
    {
      id: 'DNI',
      name: 'DNI',
    },
    {
      id: 'C.E',
      name: 'C.E',
    },
    {
      id: 'RUC',
      name: 'RUC',
    },
    {
      id: 'Otro',
      name: 'Otro',
    },
  ],
  CHL: [
    {
      id: 'RUT',
      name: 'RUT',
    },
    {
      id: 'Otro',
      name: 'Otro',
    },
  ],
};

// This could be merged in the same object, or maybe a getter function
export const PAYMENTS_OS_ID_NUMBER_TYPES = Object.fromEntries(
  Object.entries(IDENTIFICATION_NUMBER_TYPES).map(([key, idTypes]) => {
    const parsedIdTypes = idTypes?.map((idType: idType) => {
      return {
        id: idType.id.replace(/\./g, ''),
        name: idType.name.replace(/\./g, ''),
      };
    });
    return [key, parsedIdTypes];
  })
);
