import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Grid, H1 } from '@increasecard/typed-components';
import { ErrorMessage } from '../../components/ErrorMessage';
import { useSummaryMetrics } from './summaryQueries';
import { BigNumberBox } from './BigNumberBox';
import { useMetricsFilters } from './useMetricsFilters';
import { SummaryMetrics } from '../../types/Metrics';

const MetricsContainer = styled.div`
  background-color: var(--color-pay-light);
  border: 1px solid var(--color-pay-regular);
  width: 100%;
  border-radius: 9px;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 6px;
  padding: 8px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
`;

function formatValue(key: keyof SummaryMetrics, value: string) {
  if (key === 'total_active_subscriptions') {
    return value;
  }
  const newAmount = parseFloat(value);
  if (key === 'approved_over_total_invoices') {
    return `% ${(newAmount * 100).toFixed(1)}`;
  }
  if (newAmount >= 1000000) {
    return `$ ${(newAmount / 1000000).toFixed(2)} M`;
  }
  return `$ ${Math.round(newAmount).toFixed(0)}`;
}

export function BigNumbers(): JSX.Element {
  const { t } = useTranslation();
  const [filters] = useMetricsFilters();
  const metrics = useSummaryMetrics(filters);

  // TODO(santi698): Improve error screen
  if (metrics.status === 'error') {
    return (
      <Grid rowGap={2}>
        <H1>{t('metrics.title')}</H1>
        <ErrorMessage>{t('api.errors.unexpected_error')}</ErrorMessage>
      </Grid>
    );
  }

  return (
    <Grid rowGap={2}>
      <H1>{t('metrics.title')}</H1>
      <MetricsContainer>
        {metrics.data &&
          Object.entries(metrics.data).map(([key, value]) => (
            <BigNumberBox
              name={key}
              value={formatValue(key as keyof SummaryMetrics, value as string)}
              isLoading={metrics.isPlaceholderData}
              key={key}
            />
          ))}
      </MetricsContainer>
    </Grid>
  );
}
