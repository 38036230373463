export const AMOUNT_TYPES = {
  FLAT: 'flat',
  PERCENTAGE: 'percentage',
};

export const DURATIONS = {
  PERIODIC: 'periodic',
  FOREVER: 'forever',
};

export const CYCLE_UNITS = ['daily', 'weekly', 'monthly', 'yearly'];
