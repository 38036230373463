import {
  H2,
  Table,
  TableWrapper,
  Paragraph,
  Link as TCLink,
} from '@increasecard/typed-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Invoice,
  InvoiceableType,
  InvoiceItem,
  SimulatedInvoice,
} from '../types/Invoice';
import { formatCurrency } from '../utils/currencyUtils';
import { TableSkeleton } from './common/DataTable/TableSkeleton';

export interface InvoiceDetailProps {
  invoice: Invoice | SimulatedInvoice;
  title?: string;
  subtitle?: string;
  productName?: string;
}
// TODO: convert invoiceable_types to ExtraTypes
const invoiceExtraTypes = ['tax', 'discount', 'onetimecost', 'additionalcost'];

const mainItemsFilter = ({ invoiceable_type }: InvoiceItem) =>
  !invoiceable_type ||
  !invoiceExtraTypes.includes(invoiceable_type.toLowerCase());

// This should recieve an ExtraType, but the invoiceable_type is spelled different and does not match
const summarizeExtras = (
  invoice: Invoice | SimulatedInvoice,
  type: InvoiceableType | 'AdditionalCost'
) => {
  return invoice.invoice_items
    .filter(({ invoiceable_type, amount }: InvoiceItem) => {
      if (type === 'AdditionalCost') {
        return invoiceable_type === 'Discount' && Number(amount) > 0;
      }
      if (type === 'Discount') {
        return invoiceable_type === 'Discount' && Number(amount) < 0;
      }
      // return Number(amount) < 0;
      return invoiceable_type === type;
    })
    .reduce((acc, invoiceItem) => {
      return acc + Number(invoiceItem.amount);
    }, 0);
};

export function InvoiceDetail({
  invoice,
  title,
  subtitle,
  productName,
}: InvoiceDetailProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <TableWrapper>
      <H2>{title || t('common.invoice')}</H2>
      {subtitle && <Paragraph>{subtitle}</Paragraph>}
      <Table>
        <thead>
          <tr>
            <th>{t('common.description')}</th>
            <th className="rightAligned">{t('common.quantity')}</th>
            <th className="rightAligned">{t('common.unit_price')}</th>
            <th className="rightAligned">{t('common.amount')}</th>
          </tr>
        </thead>
        {invoice ? (
          <InvoiceBody invoice={invoice} productName={productName} />
        ) : (
          <TableSkeleton columnsCount={4} rowsCount={6} />
        )}
      </Table>
    </TableWrapper>
  );
}

function InvoiceBody({
  invoice,
  productName,
}: Pick<InvoiceDetailProps, 'invoice' | 'productName'>) {
  const { t } = useTranslation();
  // This are the items that aren't extras. Could be custom or subscription
  const mainItems = invoice.invoice_items.filter(mainItemsFilter);
  const subtotal = mainItems.reduce((acc, invoiceItem) => {
    return acc + Number(invoiceItem.amount);
  }, 0);

  const discounts = summarizeExtras(invoice, 'Discount');

  const additionalCosts = summarizeExtras(invoice, 'AdditionalCost');

  const taxes = summarizeExtras(invoice, 'Tax');

  const OTC = summarizeExtras(invoice, 'OneTimeCost');

  const invoiceIsCustom = invoice.kind !== 'subscription';
  return (
    <tbody>
      {mainItems
        .sort((invoiceItemA, invoiceItemB) => {
          // The follow comment does not seem to apply any more
          // FIXME(dbelay)[PAY-1584]: Workaround to avoid showing discounts (negative numbers) first in the list
          // sort by item amount descendent
          return Number(invoiceItemB.amount) - Number(invoiceItemA.amount);
        })
        .map((invoiceItem, key) => {
          // invoiceItem.currency could be null, like a tax, because it's a percentage that was converted to a fixed amount with no currency
          const currency = invoiceItem.currency || invoice.currency;
          // FIXME(dbelay): Workaround to avoid showing quantity 0 on subscription items
          const itemQuantity = Math.max(Number(invoiceItem.quantity || 0), 1);
          const description = invoiceIsCustom
            ? invoiceItem.description
            : `${
                productName ||
                invoiceItem.product_name ||
                invoiceItem.denormalized_product_name
              } - ${invoiceItem.description}`;
          return (
            <tr key={key} className="border">
              <td>
                {invoiceItem.invoiceable_type === 'Invoice' ? (
                  <TCLink
                    as={Link}
                    to={`/invoices/${invoiceItem.invoiceable_id}/detail`}
                  >
                    {description}
                  </TCLink>
                ) : (
                  description
                )}
              </td>
              <td className="rightAligned">{itemQuantity}</td>
              <td className="rightAligned">
                {formatCurrency(invoiceItem.unit_price, currency)}
              </td>
              <td className="rightAligned">
                <strong>{formatCurrency(invoiceItem.amount, currency)}</strong>
              </td>
            </tr>
          );
        })}
      {
        // FIXME(dbelay)[PAY-1584]: Workaround for "custom" invoice items, we don't know yet if an item is an extra...
        // "subscription" invoices items: we DO know if an item is an extra
        !invoiceIsCustom && (
          <>
            <tr>
              <td colSpan={2} />
              <td className="rightAligned">{t('common.subtotal')}</td>
              <td className="rightAligned">
                <strong>{formatCurrency(subtotal, invoice.currency)}</strong>
              </td>
            </tr>
            <tr>
              <td colSpan={2} />
              <td className="rightAligned">
                {t('screens.extras.discounts_title')}
              </td>
              <td className="rightAligned">
                {formatCurrency(discounts, invoice.currency)}
              </td>
            </tr>
            <tr>
              <td colSpan={2} />
              <td className="rightAligned">{t('common.additional_cost')}</td>
              <td className="rightAligned">
                {formatCurrency(additionalCosts, invoice.currency)}
              </td>
            </tr>
            <tr>
              <td colSpan={2} />
              <td className="rightAligned">{t('common.one_time_cost')}</td>
              <td className="rightAligned">
                {formatCurrency(OTC, invoice.currency)}
              </td>
            </tr>
            <tr className="border">
              <td colSpan={2} />
              <td className="rightAligned">{t('common.taxes_title')}</td>
              <td className="rightAligned">
                {formatCurrency(taxes, invoice.currency)}
              </td>
            </tr>
          </>
        )
      }
      <tr>
        <td colSpan={2} />
        <td className="rightAligned">
          <strong>{t('common.total')}</strong>
        </td>
        <td className="rightAligned">
          <strong>{formatCurrency(invoice.amount, invoice.currency)}</strong>
        </td>
      </tr>
    </tbody>
  );
}
