import {
  BankPaymentMethodIcon,
  MastercardPaymentMethodIcon,
  VisaPaymentMethodIcon,
  MercadoPagoPaymentMethodIcon,
  CabalPaymentMethodIcon,
  CreditCardPaymentMethodIcon,
  NaranjaPaymentMethodIcon,
  MaestroPaymentMethodIcon,
  DinersPaymentMethodIcon,
  TarjetaShoppingPaymentMethodIcon,
  NativaPaymentMethodIcon,
  AmexPaymentMethodIcon,
  ArgencardPaymentMethodIcon,
  CmrFalabellaPaymentMethodIcon,
  CabalDebitoPaymentMethodIcon,
  CencosudPaymentMethodIcon,
  CordobesaPaymentMethodIcon,
  VisaDebitoPaymentMethodIcon,
  Amex,
  Argencard,
  Cmr,
  Cabal,
  Cencosud,
  Diners,
  Maestro,
  MasterCard,
  MercadoPago,
  Naranja,
  Nativa,
  Visa,
  ProvinciaNetPaymentMethodIcon,
  RapipagoPaymentMethodIcon,
  CobroExpressPaymentMethodIcon,
  PagoFacilPaymentMethodIcon,
  CargaVirtualPaymentMethodIcon,
  CashPaymentMethodIcon,
} from '@increasecard/icons';
import { Constants } from '../constants';
import { Payment, PaymentMethod } from '../types';

const { CARD } = Constants.CollectMethods;
const { CARD: LEGACY_CARD, CBU, TICKET } = Constants.PaymentMethods;

/** Payment method ids are based on MP ids
 *  and change per country. See:
 *  https://www.mercadopago.com.ar/developers/es/guides/localization/payment-methods/ */
export const cardBrandsIcons = {
  mastercard: MastercardPaymentMethodIcon,
  visa: VisaPaymentMethodIcon,
  amex: AmexPaymentMethodIcon,
  mercadopago: MercadoPagoPaymentMethodIcon,
  cabal: CabalPaymentMethodIcon,
  creditcard: CreditCardPaymentMethodIcon,
  nativa: NativaPaymentMethodIcon,
  tarjetashopping: TarjetaShoppingPaymentMethodIcon,
  diners: DinersPaymentMethodIcon,
  maestro: MaestroPaymentMethodIcon,
  naranja: NaranjaPaymentMethodIcon,
  master: MastercardPaymentMethodIcon,
  argencard: ArgencardPaymentMethodIcon,
  cmr: CmrFalabellaPaymentMethodIcon,
  debcabal: CabalDebitoPaymentMethodIcon,
  cencosud: CencosudPaymentMethodIcon,
  cordobesa: CordobesaPaymentMethodIcon,
  debvisa: VisaDebitoPaymentMethodIcon,
  debmaster: MastercardPaymentMethodIcon,
  /* TODO: Remove when backend return ids instead of names! */
  'american express': AmexPaymentMethodIcon,
  'diners club international': DinersPaymentMethodIcon,
  'tarjeta naranja': NaranjaPaymentMethodIcon,
  'tarjeta nativa': NativaPaymentMethodIcon,
  'tarjeta shopping': TarjetaShoppingPaymentMethodIcon,
  'tarjeta cencosud': CencosudPaymentMethodIcon,
  'tarjeta cmr mastercard': CmrFalabellaPaymentMethodIcon,
  'visa debit': VisaDebitoPaymentMethodIcon,
  'visa débito': VisaDebitoPaymentMethodIcon,
  'mastercard debit': MastercardPaymentMethodIcon,
  'mastercard débito': MastercardPaymentMethodIcon,
  'cabal debit': CabalDebitoPaymentMethodIcon,
  'cabal débito': CabalDebitoPaymentMethodIcon,
};

export const cardBanksIcons = {
  mastercard: MasterCard,
  visa: Visa,
  amex: Amex,
  'american-express': Amex,
  mercadopago: MercadoPago,
  cabal: Cabal,
  nativa: Nativa,
  diners: Diners,
  maestro: Maestro,
  naranja: Naranja,
  master: MasterCard,
  argencard: Argencard,
  cmr: Cmr,
  debcabal: Cabal,
  cencosud: Cencosud,
  debvisa: Visa,
  debmaster: MasterCard,
};

export const ticketBrandIcons = {
  pagofacil: PagoFacilPaymentMethodIcon,
  bapropagos: ProvinciaNetPaymentMethodIcon,
  rapipago: RapipagoPaymentMethodIcon,
  cobroexpress: CobroExpressPaymentMethodIcon,
  cargavirtual: CargaVirtualPaymentMethodIcon,
  cash: CashPaymentMethodIcon,
};

export const CbuBankIcon = BankPaymentMethodIcon;

export function isCard(methodType: PaymentMethod['data']['type']): boolean {
  // 'card' == 'MP'. Any other name is equivalent to its collect method type
  return Object.values(CARD).concat(LEGACY_CARD).includes(methodType);
}

export function isCBU(methodType: PaymentMethod['data']['type']): boolean {
  return methodType === CBU;
}

export function getPaymentType(
  methodType: PaymentMethod['data']['type']
): string {
  if (isCBU(methodType)) {
    return CBU;
  }
  if (isCard(methodType)) {
    return LEGACY_CARD;
  }
  if (methodType === TICKET) {
    return TICKET;
  }
  return methodType;
}

export function getPaymentStatus(payment: Payment): string {
  const { status, refund_status } = payment;
  const refundIsUnrequested = refund_status === 'unrequested';

  return refundIsUnrequested ? status : `refund_${refund_status}`;
}
