import { ChangeEvent, Fragment } from 'react';
import styled from 'styled-components';
import { InputText, InputNumber } from '@increasecard/typed-components';
import { CloseIcon } from '@increasecard/icons';
import { useTranslation } from 'react-i18next';
import { LinkButton } from './common/LinkButton';
import { InputCurrency } from './common/InputCurrency';
import { PlanTier } from '../types/Plan';
import { HelpButton } from './common/HelpButton';
import { Select } from './common/SystemSelect';

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 10px;
  grid-auto-flow: row;
  > * {
    justify-content: flex-end;
  }
  .from {
    grid-column: 1 / span 2;
  }
  .until {
    grid-column: 3 / span 2;
  }
  .amount {
    grid-column: 5 / span 2;
  }
  .amountType {
    grid-column: 7 / span 3;
  }
  .close-button {
    grid-column: 10 / span 1;
  }
  .add-button {
    grid-column: 1 / span 10;
  }
`;

export interface PricingModelInputProps {
  type: 'flat' | 'usage' | 'graduated' | 'variableflat';
  values: PlanTier[];
  onChange: (param: PlanTier[] | number[]) => void;
  onBlur: () => void;
  onAddTier: () => void;
  onRemoveTier: (tierIndex: number) => void;
  className: string;
  amount: number;
}

export function PricingModelInput({
  type,
  values,
  onChange,
  onBlur,
  onAddTier,
  onRemoveTier,
  className,
  amount,
}: PricingModelInputProps): JSX.Element {
  const { t } = useTranslation();

  const getUpdatedTiers = (inputId: string, newValue: number | string) => {
    const [tierIndex, tierProp] = inputId.split('.');
    return values.map((tier, i) => {
      if (i === Number(tierIndex)) {
        return { ...tier, [tierProp]: newValue };
      }
      return tier;
    });
  };

  const handleAmountChange = (id?: string) => (amount: number) => {
    if (type === 'flat' || type === 'usage' || type === 'variableflat') {
      onChange([amount]);
    } else if (id) {
      onChange(getUpdatedTiers(id, amount));
    }
  };

  const onMinMaxChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(getUpdatedTiers(event.target.id, Number(event.target.value)));
  };

  const handleAmountTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newTierws = getUpdatedTiers(event.target.id, event.target.value);
    onChange(newTierws);
  };

  const errorMessageHandler = () => {
    if (amount <= 0 && type !== 'variableflat') {
      return t(`validation.only_positive_integers`);
    }
  };

  if (type === 'variableflat')
    return (
      <InputCurrency
        className="span-desktop-1 grid-start-desktop-1"
        id="amount_per_unit"
        label={
          <>
            {t(`data.plan.amount_variableflat`)}
            <HelpButton
              titleKey="data.plan.variableflat_modal.title"
              descriptionKey="data.plan.variableflat_modal.description"
            />
          </>
        }
        value={amount}
        onChange={handleAmountChange()}
        min={0}
        errorMessage={errorMessageHandler()}
      />
    );

  if (type === 'usage') {
    return (
      <InputCurrency
        className="span-desktop-1 grid-start-desktop-1"
        id="amount_per_unit"
        label={t(`data.plan.amount_per_unit`)}
        value={amount}
        onChange={handleAmountChange()}
        min={0}
        errorMessage={errorMessageHandler()}
      />
    );
  }

  if (type === 'flat') {
    return (
      <InputCurrency
        className="span-desktop-1 grid-start-desktop-1"
        id="amount"
        label={t(`data.plan.amount`)}
        value={amount}
        onChange={handleAmountChange()}
        min={0}
        errorMessage={errorMessageHandler()}
      />
    );
  }

  // tiers
  return (
    <GridWrapper className={className}>
      {values &&
        values.map((tier, i) => {
          const min = tier.minimum;
          const max = tier.maximum === undefined ? tier.maximum : tier.maximum;
          const amount = tier.amount;
          const amountId = `${i}.amount`;
          const amountTypeId = `${i}.amount_type`;
          const showLabel = i === 0;
          return (
            <Fragment key={i}>
              <InputNumber
                className="from"
                label={showLabel && t('data.plan.from_unit')}
                id={i + '.minimum'}
                disabled
                value={min}
                min={1}
                onChange={onMinMaxChange}
              />
              {i === values.length - 1 ? (
                <InputText
                  className="until"
                  label={showLabel && t('data.plan.to_unit')}
                  id={i + '.maximum'}
                  placeholder="∞"
                  value={undefined}
                  disabled
                />
              ) : (
                <InputNumber
                  className="until"
                  label={showLabel && t('data.plan.to_unit')}
                  id={i + '.maximum'}
                  value={max}
                  min={1}
                  onBlur={onBlur}
                  onChange={onMinMaxChange}
                />
              )}
              <InputCurrency
                className="amount"
                id={amountId}
                label={showLabel && t('data.plan.amount')}
                value={amount}
                onChange={handleAmountChange(amountId)}
                min={0}
                errorMessage={
                  amount <= 0 && t('validation.only_positive_integers')
                }
              />
              <Select
                id={amountTypeId}
                className="amountType"
                label={showLabel && 'tipo'}
                value={tier.amount_type}
                onChange={handleAmountTypeChange}
                options={[
                  { name: 'Fijo', value: 'flat' },
                  { name: 'Por unidad', value: 'per_unit' },
                ]}
              />
              {i >= 1 && (
                <button
                  className="close-button"
                  onClick={() => onRemoveTier(i)}
                >
                  <CloseIcon />
                </button>
              )}
            </Fragment>
          );
        })}
      <LinkButton onClick={onAddTier} className="add-button">
        Agregar otro nivel
      </LinkButton>
    </GridWrapper>
  );
}
