import { useTranslation } from 'react-i18next';
import { FrontPlan as Plan } from '../../../types/Plan';
import {
  DetailsBox,
  RowDefinition,
} from '../../../components/common/DetailsBox';
import { formatCurrency } from '../../../utils/currencyUtils';

export interface PlanDetailProps {
  plan: Plan;
}

export function PlanDetail({ plan }: PlanDetailProps): JSX.Element {
  const { t } = useTranslation();

  const detailRows: RowDefinition<Plan>[] = [
    {
      label: t('common.product'),
      type: 'string',
      field: 'product.name',
    },
    {
      label: t('common.created_at'),
      type: 'date',
      field: 'created_at',
    },
    {
      label: t('common.status'),
      type: 'tag',
      field: 'status',
      tagType: 'plan',
      tagLabel: t(`data.plan.status.${plan.status}`),
    },
    {
      label: t('screens.edit_plan.charge_after_period'),
      type: 'string',
      field: 'charge_after_period',
      valueGetter: ({ charge_after_period }) =>
        charge_after_period
          ? t(`screens.new_plan.charge_period_end_title`)
          : t(`screens.new_plan.charge_period_start_title`),
    },
    {
      label: t('data.plan.cycle_unit'),
      type: 'string',
      field: 'cycle_unit',
      valueGetter: ({ cycle_unit }) => t(`data.plan.cycle_units.${cycle_unit}`),
    },
    {
      label: t('data.plan.pricing_model'),
      type: 'string',
      field: 'type',
      valueGetter: ({ type }) => t(`data.plan.types.${type}`),
    },
    {
      label: t('data.plan.amount'),
      type: 'custom',
      field: 'amount',
      valueGetter: ({ amount, currency }) => formatCurrency(amount, currency),
    },
    {
      label: t('common.country'),
      type: 'string',
      field: 'country',
      valueGetter: ({ country }) => t(`data.countries.${country}`),
    },
    {
      label: t('common.collect_methods'),
      type: 'string',
      field: 'collect_methods',
      valueGetter: ({ collect_methods }) =>
        Object.values(collect_methods)
          .flat(1)
          .map((cm) => cm.name)
          .join(', '),
    },
  ];

  return (
    <DetailsBox
      className="grid-span-desktop-7 grid-span-mobile-12"
      title={t('common.details')}
      data={plan}
      rowsConfig={detailRows}
    />
  );
}
