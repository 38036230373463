import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from '../../components/common/Header';
import { ApiErrorMessage } from '../../components/ErrorMessage';
import { capitalize } from '../../utils/utils';
import { Constants } from '../../constants';
import {
  DataTable,
  ColumnDefinition,
  RowDefinition,
} from '../../components/common/DataTable/DataTable';
import { useReservations } from './reservationQueries';
import {
  ReservationsFilters,
  useReservationsFilters,
} from './ReservationFilters';

export function ReservationsList(): JSX.Element {
  const { t } = useTranslation();

  // state
  const [filters] = useReservationsFilters();
  const [page, setPage] = useState(1);
  const { data, error, isLoading, refetch } = useReservations({
    page,
    per_page: Constants.LARGE_PAGE_SIZE,
    ...filters,
  });

  const tableColumns: ColumnDefinition[] = [
    {
      field: 'created_at',
      type: 'date',
      headerName: t('common.created'),
    },
    {
      field: 'customer',
      type: 'customer',
      headerName: t('common.customer'),
    },
    {
      field: 'payment_method',
      type: 'payment_method',
      headerName: t('common.method'),
    },
    {
      field: 'status',
      type: 'tag',
      headerName: t('common.status'),
      tagType: 'reservation',
      tagLabel: (reservation: RowDefinition) =>
        t(`data.reservation.status.${reservation.status}`),
    },
    {
      field: 'description',
      type: 'string',
      headerName: t('common.detail'),
      valueGetter: (reservation: RowDefinition) =>
        capitalize(reservation.rejection_description),
    },
    {
      field: 'updated_at',
      type: 'date',
      headerName: t('common.last_update'),
    },
    {
      field: 'amount',
      type: 'currency',
      headerName: t('common.amount'),
      rightAligned: true,
    },
  ];

  const loadPage = (pageNumber: number) => setPage(pageNumber);

  const onErrorMessageClick = () => refetch();

  // error
  if (error) {
    return <ApiErrorMessage error={error} onClick={onErrorMessageClick} />;
  }

  const hasData = data?.data ? data?.data.length > 0 : false;

  // render
  return (
    <>
      <Header title={t('common.reservations')} />
      <ReservationsFilters />
      <DataTable
        tableProps={{ hasData }}
        columns={tableColumns}
        pageSize={Constants.LARGE_PAGE_SIZE}
        isLoading={isLoading}
        rows={data?.data}
        rowHeight="double"
        rowBorder
        paginatorProps={{
          pages: data?.total_pages,
          selected: Number(data?.current_page),
          onSelectPage: loadPage,
        }}
      />
    </>
  );
}
