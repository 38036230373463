import { CSSProperties } from 'react';
import styled from 'styled-components';
import { HelpIcon as Icon } from '@increasecard/icons';

const Wrapper = styled.div<{
  position: Pick<CSSProperties, 'top' | 'right' | 'bottom' | 'left'>;
}>`
  position: absolute;
  ${({ position }) => position}
  padding: 4px;
`;

export const StyledHelpIcon = styled(Icon)<{
  color?: string;
  width?: string;
  height?: string;
}>`
  cursor: pointer;
  width: ${({ width = '1.2em' }) => width};
  height: ${({ height = '1.2em' }) => height};
  fill: ${({ theme, color = 'gray' }) =>
    color === 'gray'
      ? `${theme.colorsNew.gray.grayMedium}`
      : `${theme.colorsNew.product.light}`};
  &:hover {
    fill: ${({ theme, color = 'gray' }) =>
      color === 'gray'
        ? `${theme.colorsNew.increase.light50}`
        : `${theme.colorsNew.gray.white}`};
  }
`;

export type HelpIconProps = typeof Wrapper.arguments;

export function HelpIcon(props: HelpIconProps): JSX.Element {
  return (
    <Wrapper {...props}>
      <StyledHelpIcon />
    </Wrapper>
  );
}
