import { PayuSchema } from '../CollectMethodsSchema';
import { COUNTRIES } from '../../../localizations';
import { list2NameValue } from '../../../utils/utils';
import { getDefaultValues, PanelFormConfig } from './PanelConfig';
import { TFunction } from 'i18next';
import { PayUCollectMethod } from '../../../types/CollectMethod';

const { ARGENTINA, PERU, COLOMBIA, CHILE } = COUNTRIES;

export function payuPanelConfig(
  translate: TFunction,
  collectMethod?: PayUCollectMethod
): PanelFormConfig {
  const countryOptions = list2NameValue(
    [ARGENTINA, PERU, COLOMBIA, CHILE],
    'data.countries',
    'common.select'
  );

  const formData = {
    name: 'PayU Latam',
    metadata: {
      provider_name: 'PayULatam',
      provider_access: {
        account_id: '',
        merchant_id: '',
        api_login: '',
        api_key: '',
      },
    },
  };

  const initialValues = getDefaultValues(formData, collectMethod);
  const transBase = 'screens.collect_methods.payu';

  const disableSetupData =
    collectMethod?.status === 'enabled' &&
    !collectMethod.metadata.provider_access.pending;

  const showPendingMessage =
    collectMethod?.status === 'disabled' &&
    collectMethod.metadata.provider_access.pending;

  // If collect method is enabled, dont validate it since it cant be modified.
  // This is a hack for when api does not return api_login or api_key.
  const schema = disableSetupData ? undefined : () => PayuSchema(translate);
  return {
    type: 'payments_os',
    name: collectMethod?.name || 'PayU Latam',
    description: translate(`${transBase}.description`),
    initialValues,
    status: collectMethod?.status,
    inputs: [
      {
        label: translate(`${transBase}.merchant_id`),
        id: 'metadata.provider_access.merchant_id',
        type: 'text',
        disabled: disableSetupData,
      },
      {
        label: translate(`${transBase}.account_id`),
        id: 'metadata.provider_access.account_id',
        type: 'text',
        disabled: disableSetupData,
      },
      {
        label: translate(`${transBase}.api_login`),
        id: 'metadata.provider_access.api_login',
        type: 'text',
        tooltipContent: translate('screens.collect_methods.secret_fields'),
        required: !disableSetupData,
        disabled: disableSetupData,
      },
      {
        label: translate(`${transBase}.api_key`),
        id: 'metadata.provider_access.api_key',
        type: 'text',
        tooltipContent: translate('screens.collect_methods.secret_fields'),
        required: !disableSetupData,
        disabled: disableSetupData,
      },
      {
        label: translate(`common.country`),
        id: 'country',
        type: 'select',
        options: countryOptions,
        disabled: disableSetupData,
      },
    ],
    pendingMessage: translate(`${transBase}.config_pending_message`),
    showPendingMessage,
    validationSchema: schema,
  };
}
