import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link, OnTopNotification } from '@increasecard/typed-components';
import { Trans } from 'react-i18next';
import { useSubscriptions } from './subscriptionQueries';

export function SubscriptionRequestNotification(): JSX.Element | null {
  const [show, setShow] = useState(true);
  const subscriptions = useSubscriptions(1, { status: ['pending_approval'] });

  return subscriptions.data && subscriptions.data.data.length > 0 && show ? (
    <OnTopNotification
      variant="alert"
      onClose={() => setShow(false)}
      style={{ zIndex: 2, position: 'relative' }}
    >
      <Trans
        i18nKey="notifications.subscriptions_pending_approval"
        values={{
          count: subscriptions.data.data.length,
        }}
        components={{
          cta: (
            <Link
              as={RouterLink}
              to={`/subscriptions?status=pending_approval`}
              onClick={() => setShow(false)}
            />
          ),
        }}
      />
    </OnTopNotification>
  ) : null;
}
