import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { RequiredString, URL } from '../../schemas/common';
import {
  CollectMethod,
  GroupedCollectMethods,
} from '../../types/CollectMethod';

interface InvoiceItem {
  description: string;
  amount: string;
  quantity: string;
  unit_price: string;
  currency: string;
}

export interface InvoiceFormValues {
  amount: string;
  payment_method_id?: string;
  customer_id: string;
  currency: string;
  external_reference?: string;
  items: InvoiceItem[];
  success_url?: string;
  collect_methods: Partial<GroupedCollectMethods>;
}

const getItemSchema = (): Yup.SchemaOf<InvoiceItem> => {
  return Yup.object({
    description: RequiredString().defined(),
    amount: RequiredString().defined(),
    currency: RequiredString().defined(),
    quantity: RequiredString().defined(),
    unit_price: RequiredString().defined(),
  });
};

const isFilled = (
  fieldA: CollectMethod[] | undefined,
  fieldB: CollectMethod[] | undefined
) => {
  return (fieldA && fieldA.length > 0) || (fieldB && fieldB.length > 0);
};

export function InvoiceSchema(t: TFunction): Yup.SchemaOf<InvoiceFormValues> {
  return Yup.object({
    amount: RequiredString().defined(),
    payment_method_id: Yup.string(),
    customer_id: RequiredString().defined(),
    currency: RequiredString().defined(),
    external_reference: Yup.string(),
    items: Yup.array().of(getItemSchema()).min(1),
    success_url: URL(t),
    collect_methods: Yup.object().shape(
      {
        card: Yup.array().when(['cbu', 'ticket'], {
          is: isFilled,
          then: Yup.array().min(0),
          otherwise: Yup.array().min(1),
        }),
        cbu: Yup.array().when(['card', 'ticket'], {
          is: isFilled,
          then: Yup.array().min(0),
          otherwise: Yup.array().min(1),
        }),
        ticket: Yup.array().when(['cbu', 'card'], {
          is: isFilled,
          then: Yup.array().min(0),
          otherwise: Yup.array().min(1),
        }),
      },
      [
        ['cbu', 'ticket'],
        ['card', 'ticket'],
        ['cbu', 'card'],
      ]
    ),
  });
}
