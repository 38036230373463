import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Constants } from '../../../constants';
import { CBUSchema } from './CBUSchema';
import { PaymentMethodInfoBox } from '../PaymentMethodInfoBox';
import { ResponsiveGrid } from '../../common/ResponsiveGrid';
import { PaymentMethodInputDecorator } from '../PaymentMethodInputDecorator';
import { OnlyDesktop, useResponsive } from '../../../hooks/useResponsive';
import { InputTextField } from '../../common/FormikFields';
import {
  ComafiCollectMethod,
  SupervielleCollectMethod,
} from '../../../types/CollectMethod';
import { PaymentFormFooter } from '../PaymentFormFooter';

import { PaymentFormBase } from '../types';

const INITIAL_VALUES = {
  number: '',
  set_as_default: false,
};

export interface CBUFormValues {
  number: string;
  collect_method_id: string | null;
  type: typeof Constants.PaymentMethods.CBU;
  set_as_default?: boolean;
}

export type CBUFormProps = PaymentFormBase<
  SupervielleCollectMethod | ComafiCollectMethod,
  CBUFormValues
>;

export function CBUForm({
  onSubmit,
  onCancel,
  processing,
  collectMethods,
  submitText,
  cancelText,
  termsAndConditionsUrl,
  errorObj,
  showSetAsDefaultPM,
}: CBUFormProps): JSX.Element {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  const handleSubmit = (values: typeof INITIAL_VALUES) => {
    onSubmit({
      ...values,
      type: Constants.PaymentMethods.CBU,
      collect_method_id: collectMethods.id,
    });
  };

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      validationSchema={CBUSchema(t)}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <>
          <ResponsiveGrid as="form" rowGap={2} desktopMaxWidth="335px">
            <PaymentMethodInputDecorator
              className="grid-span-desktop-12"
              type={Constants.PaymentMethods.CBU}
              showMethodIcon={isMobile}
            >
              <InputTextField
                placeholder={t('data.payment_method.cbu_placeholder')}
                label={t('data.payment_method.cbu')}
                id="number"
                maxLength={22}
                autoComplete="off"
                isNumeric
                required
              />
            </PaymentMethodInputDecorator>
            <PaymentFormFooter
              className="grid-span-desktop-12"
              isSubmitting={processing}
              onSubmit={formik.handleSubmit}
              submitText={submitText}
              onCancel={onCancel}
              cancelText={cancelText}
              termsAndConditionsUrl={termsAndConditionsUrl}
              error={errorObj}
              onSetDefaultPaymentMethod={
                showSetAsDefaultPM ? formik.handleChange : undefined
              }
            />
          </ResponsiveGrid>
          <OnlyDesktop>
            <PaymentMethodInfoBox
              type="cbu"
              data={{ cbu: formik.values.number }}
              errorObj={errorObj}
            />
          </OnlyDesktop>
        </>
      )}
    </Formik>
  );
}
