import { ReactNode } from 'react';
import { HelpIcon, WarningTriangleIcon } from '@increasecard/icons';
import { useModal } from '../../hooks/useModal';
import { IconButton } from './IconButton';
import { ConfirmModal } from './ConfirmModal';
import { useTranslation } from 'react-i18next';
export interface IconButtonModalProps {
  width?: string;
  height?: string;
  iconType: 'warning' | 'help';
  children: ReactNode;
}

export function IconButtonModal({
  width,
  height,
  iconType,
  children,
}: IconButtonModalProps): JSX.Element {
  const iconButtonModal = useModal();
  const { t } = useTranslation();
  return (
    <>
      <IconButton
        onClick={iconButtonModal.open}
        width={width}
        height={height}
        iconType={iconType}
      />
      <ConfirmModal
        visible={iconButtonModal.isOpen}
        onClose={iconButtonModal.close}
        onConfirm={iconButtonModal.close}
        confirmLabel={t('common.close')}
        colorPalette={iconType === 'warning' ? 'count' : 'pay'}
        headerIcon={
          iconType === 'warning' ? (
            <WarningTriangleIcon color="white" height="48" width="48" />
          ) : (
            <HelpIcon color="white" height="48" width="48" />
          )
        }
      >
        {children}
      </ConfirmModal>
    </>
  );
}
