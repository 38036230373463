import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  H2,
  Table,
  TableWrapper,
  CellText,
} from '@increasecard/typed-components';
import { Subscription } from '../../types/Subscription';
import { formatISODate } from '../../utils/dateUtils';
import { Paginator } from '../../components/common/Paginator';
import { ApiErrorMessage } from '../../components/ErrorMessage';
import { useSubscriptionEvents } from './subscriptionQueries';

export interface SubscriptionEventsProps {
  subscription: Subscription;
}

export function SubscriptionEvents({
  subscription,
}: SubscriptionEventsProps): JSX.Element {
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = useState(1);
  // const [events, setEvents] = useState<PaginatedData<StatusChangeEvent>>();
  const events = useSubscriptionEvents(subscription.id, pageNumber);

  return (
    <TableWrapper>
      <H2>{t('common.events')}</H2>
      <ApiErrorMessage error={events.error} />
      {events.data?.data.length && (
        <>
          <Table>
            <tbody>
              {events.data.data.map((event, key) => {
                return (
                  <tr key={key} className="border">
                    <td>
                      <CellText>{event.message}</CellText>
                    </td>
                    <td className="rightAligned">
                      {formatISODate(event.created_at)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Paginator
            pages={events.data?.total_pages || 0}
            selected={parseInt(events.data?.current_page) || 1}
            onSelectPage={(page: number) => setPageNumber(page)}
          />
        </>
      )}
      {events.data?.data.length === 0 && (
        <CellText>{t('components.subscription_events.no_events')}</CellText>
      )}
    </TableWrapper>
  );
}
