/* eslint-disable react/prop-types */
import { FieldInputWrapper } from '../FieldInputWrapper';
import { usePOSField, UsePOSField } from './usePOSField';

export interface PaymentsOSFieldProps extends UsePOSField {
  label: string;
  className: string;
  errorMessage?: string;
}

export function PaymentsOSField({
  field,
  type,
  label,
  className,
  errorMessage,
  onChange,
  onBlur,
}: PaymentsOSFieldProps): JSX.Element {
  usePOSField({
    field,
    type,
    onChange,
    onBlur,
  });
  return (
    <FieldInputWrapper
      id={type}
      className={className}
      label={label}
      errorMessage={errorMessage}
      internalClassPrefix="POSElement"
    />
  );
}
