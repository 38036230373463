import { apiFront } from './api';
import { uploadToS3 } from './apiStorage';
import { CustomerNotificationSettings } from '../types/CustomerNotificationSettings';
import { Account, AccountBrandingSettings } from '../types/Account';

export async function fetchAccount(): Promise<Account> {
  const response = await apiFront.get('accounts/me');
  response.data.notifications = Object.entries(response.data.notifications)
    .filter(([_key, value]) => value)
    .map(([key]) => key);
  return response.data;
}

export type UpdateBrandingSettingsParams = Partial<
  Omit<AccountBrandingSettings, 'id' | 'logo_url' | 'icon_url'>
> & {
  logo_path?: string | null;
  icon_path?: string | null;
};

export async function createBrandingSettings(
  brandingSettings: UpdateBrandingSettingsParams
): Promise<AccountBrandingSettings> {
  const response = await apiFront.post(
    'accounts/branding_settings',
    brandingSettings
  );
  return response.data;
}

export async function updateBrandingSettings(
  brandingSettings: UpdateBrandingSettingsParams
): Promise<AccountBrandingSettings> {
  const response = await apiFront.put(
    'accounts/branding_settings',
    brandingSettings
  );
  return response.data;
}

export async function uploadBrandingImages(
  file: File,
  type: 'logo' | 'icon'
): Promise<string> {
  const { data: uploadInfo } = await apiFront.post(
    `accounts/branding_settings/upload_${type}_link`,
    {
      mime_type: file.type,
    }
  );
  return uploadToS3(file, uploadInfo);
}

export async function generateApiKey(): Promise<
  Pick<Account, 'secret_api_key'>
> {
  const response = await apiFront.post('accounts/secret_api_key');
  return response.data;
}

export type UpdateHooksParams = Pick<
  Account,
  'notifications' | 'events_hooks_url'
>;

export async function updateHooks(data: UpdateHooksParams): Promise<void> {
  const response = await apiFront.post('accounts/webhook', data);
  return response.data;
}

export async function getCustomerNotificationSettings(
  accountId: string
): Promise<CustomerNotificationSettings> {
  const response = await apiFront.get(
    `accounts/${accountId}/notification_settings`
  );
  return response.data;
}

export async function updateCustomerNotificationSettings(
  accountId: string,
  settings: CustomerNotificationSettings
): Promise<CustomerNotificationSettings> {
  const response = await apiFront.put(
    `accounts/${accountId}/notification_settings`,
    settings
  );
  return response.data;
}

export async function fetchTags(search: string): Promise<string[]> {
  const response = await apiFront.get(`accounts/tags?search=${search}`);
  return response.data;
}

export type UpdateAccountSettingsParam = Pick<
  Account,
  'allow_insufficient_funds' | 'validate_cbu_with_payment'
>;

export async function updateAccountSettings(
  settings: UpdateAccountSettingsParam
): Promise<Account> {
  const response = await apiFront.post('accounts/settings', settings);
  return response.data;
}

export type NotificationSampleParam = {
  type: string;
  email: string;
};

export async function sendNotificationSample(
  info: NotificationSampleParam
): Promise<void> {
  const response = await apiFront.post(`accounts/send_mail_example`, info);
  return response.data;
}
