import styled from 'styled-components';
import { TableWrapper, Caption } from '@increasecard/typed-components';
import { Box } from './Box';

export const ProgressContainer = styled.div`
  width: 100%;
  height: 4px;
  margin: 0px 0.5rem;
  background: var(--color-pay-light70);
`;
export const Progress = styled.div<{ percentage: number }>`
  width: ${({ percentage }) => `${percentage}%`};
  height: 4px;
  background: var(--color-pay-regular);
`;
export const StyledCaption = styled(Caption)`
  color: var(--color-gray-blackSLight);
`;

function getPercentage(value: number, max: number) {
  if (value < 1) return 0;
  if (value > max || max === 0) return 100;
  return parseInt(((value * 100) / max).toFixed(0));
}
export interface RangeSliderProps {
  max: number;
  value: number;
  message: string;
}
export function ProgressBox({
  max,
  value,
  message,
}: RangeSliderProps): JSX.Element {
  return (
    <TableWrapper style={{ padding: '0.5rem', maxWidth: '785px' }}>
      <Box display="flex" align-items="center">
        <StyledCaption>0</StyledCaption>
        <ProgressContainer>
          <Progress percentage={getPercentage(value, max)} />
        </ProgressContainer>
        <StyledCaption>{max}</StyledCaption>
      </Box>
      <StyledCaption>{message}</StyledCaption>
    </TableWrapper>
  );
}
