import { InputNumber, Switch } from '@increasecard/typed-components';
import { ChangeEvent, Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from '../../../components/common/SystemSelect';
import { FrontPlan } from '../../../types';
import { list2NameValue } from '../../../utils/utils';

export interface GracePeriodProps {
  dispatch: Dispatch<{ type: string; payload: number | string | null }>;
  plan: FrontPlan;
}

const availableStatuses = ['paused_automatically', 'canceled'];

export function GracePeriod({ dispatch, plan }: GracePeriodProps): JSX.Element {
  const { t } = useTranslation();
  const options = list2NameValue(availableStatuses, 'data.subscription.status');
  const isDisabled = !plan.grace_period_setting;

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    dispatch({ type: e.target.name, payload: e.target.value });
  };

  const handleDisable = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      dispatch({ type: 'grace_period_setting', payload: 1 });
    } else {
      dispatch({ type: 'grace_period_setting', payload: null });
      dispatch({ type: 'status_after_grace_period', payload: null });
    }
  };

  return (
    <>
      <Switch
        id="enable_grace_period"
        label="Habilitar período de gracia"
        checked={!isDisabled}
        onChange={handleDisable}
      />
      <InputNumber
        id="grace_period_setting"
        className="span-desktop-1"
        disabled={isDisabled}
        onChange={handleChange}
        value={plan.grace_period_setting}
        min="1"
        label={t('screens.new_plan.grace_period_setting')}
      />
      <Select
        id="status_after_grace_period"
        label={t('screens.new_plan.status_after_grace_period')}
        options={options}
        disabled={isDisabled}
        onChange={handleChange}
        value={plan.status_after_grace_period}
        className="span-desktop-3"
      />
    </>
  );
}
