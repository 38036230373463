import { apiFront } from './api';
import {
  ActiveSubscriptions,
  AccumulatedAmount,
  Collectibility,
  LastCycleStats,
  FrontPlan,
  PlanCreationParams,
  PlanUpdateParams,
} from '../types/Plan';
import { getQueryString } from '../utils/apiUtils';
import { AmountType, PaginatedData } from '../types/common';
import { planFromApi, newPlanToAPI } from '../utils/planUtils';

// TODO: narrow this params type to the params that accepts
export async function fetchPlans(
  params: Metadata
): Promise<PaginatedData<FrontPlan>> {
  const qs = getQueryString(params);
  const response = await apiFront.get(`/plans${qs}`);
  response.data.data = response.data.data.map(planFromApi);
  return response.data;
}

export async function fetchPlanById(
  id: string | undefined
): Promise<FrontPlan> {
  const response = await apiFront.get(`/plans/${id}`);
  response.data = planFromApi(response.data);
  return response.data;
}

export async function createPlan(
  params: PlanCreationParams
): Promise<FrontPlan> {
  const response = await apiFront.post(`/plans/`, newPlanToAPI(params));
  return response.data;
}

export async function updatePlan(params: PlanUpdateParams): Promise<FrontPlan> {
  const response = await apiFront.patch(
    `/plans/${params.plan_id}`,
    newPlanToAPI(params)
  );
  return response.data;
}

export interface ChangePlanStatusParams {
  id: string;
  action: 'enable' | 'disable';
}

export async function changePlanStatus({
  id,
  action,
}: ChangePlanStatusParams): Promise<void> {
  const response = await apiFront.put(`plans/${id}/${action}`);
  return response.data;
}

export function getActiveSubscriptions(
  planId: string
): Promise<ActiveSubscriptions> {
  return apiFront
    .get(`subscriptions/stats?status=active&plan_id=${planId}`)
    .then((response) => response.data);
}

export function getAcumulatedAmount(
  planId: string
): Promise<AccumulatedAmount> {
  return apiFront
    .get(`plans/${planId}/invoices/last_30_days_amount`)
    .then((response) => response.data);
}

//plans/plan_id/invoices/last_30_days_by_status
export function getCollectibility(planId: string): Promise<Collectibility> {
  return apiFront
    .get(`plans/${planId}/invoices/last_30_days_by_status`)
    .then((response) => response.data);
}

export function fetchLastCycleStats(planId: string): Promise<LastCycleStats> {
  return apiFront
    .get(`plans/${planId}/last_cycle_stats`)
    .then((response) => response.data);
}

export function lastCycleBulkRetry(planId: string): Promise<LastCycleStats> {
  return apiFront
    .post(`plans/${planId}/last_cycle_bulk_retry`)
    .then((response) => response.data);
}

export interface SimulateNextBillingDateParams {
  planId: string;
  startDay: number | string;
  startMonth?: number | string;
}

export async function simulateNextBillingDate({
  planId,
  startDay,
  startMonth,
}: SimulateNextBillingDateParams): Promise<{ date: string }> {
  const response = await apiFront.get(
    `plans/${planId}/simulate_next_billing_date${getQueryString({
      start_day: startDay,
      start_month: startMonth,
    })}`
  );
  return response.data;
}

export interface UpdatePlanPricesParams {
  plan_ids: string[];
  amount: number | string;
  amount_type: AmountType;
}

export async function updatePrices(
  params: UpdatePlanPricesParams
): Promise<undefined> {
  const response = await apiFront.post(`plans/bulk_action`, params);
  return response.data;
}
