import { CogIcon } from '@increasecard/icons';
import { ConfirmModal } from '../../components/common/ConfirmModal';
import { CollectMethod } from '../../types';
import { useCreateOrUpdateCollectMethod } from './collectMethodQueries';
import { ApiErrorMessage } from '../../components/ErrorMessage';

export interface ChangeCmStatusModalProps {
  visible: boolean;
  collectMethod: CollectMethod | null;
  onClose: VoidFunction;
}

export function ChangeCmStatusModal({
  visible,
  collectMethod,
  onClose,
}: ChangeCmStatusModalProps): JSX.Element {
  const updateCM = useCreateOrUpdateCollectMethod();
  const newStatus =
    collectMethod?.status === 'enabled' ? 'disabled' : 'enabled';

  const handleStatusChange = () => {
    if (collectMethod) {
      updateCM.mutate(
        {
          id: collectMethod.id,
          status: newStatus,
        },
        {
          onSuccess: () => onClose(),
        }
      );
    }
  };

  const warning =
    newStatus === 'disabled' &&
    'Al deshabilitar el método de cobro, ya no se procesarán pagos a través de él.';

  const confirm = newStatus === 'disabled' ? 'deshabilitar' : 'habilitar';
  return (
    <ConfirmModal
      visible={visible}
      onCancel={onClose}
      onConfirm={handleStatusChange}
      confirmLabel="Aceptar"
      isLoading={updateCM.isLoading}
      headerIcon={<CogIcon width="48px" height="48px" />}
    >
      {warning}
      <br />
      <br />
      <strong>¿Está seguro de {confirm} el método de cobro?</strong>
      <br />
      <br />
      <ApiErrorMessage error={updateCM.error} centered />
    </ConfirmModal>
  );
}
