import { Trans, useTranslation } from 'react-i18next';
import {
  Button,
  H2,
  H3,
  InputFile,
  Paragraph,
} from '@increasecard/typed-components';
import { useImport } from '../../../components/imports/useImport';
import { ImportType } from '../../../types';
import { FileProcessing } from '../../../components/imports/FileProcessing';
import { Column } from '../../../components/common/Column';
import { LoadingButton } from '../../../components/common/LoadingButton';
import Papa from 'papaparse';
import { useState } from 'react';
import { ErrorMessage } from '../../../components/ErrorMessage';
import styled from 'styled-components';
import { Row } from '../../../components/common/Row';
import { useWizard } from 'react-use-wizard';

const requiredHeaders = [
  'Identificador suscripcion',
  'Monto de la suscripcion',
  'Fecha proximo Cobro',
];
const prefix = 'screens.import_variable_subscriptions';

const List = styled.ul`
  list-style-type: initial;
  margin: initial;
  padding-inline-start: 20px;
`;

export function ImportStep({ onExit }: { onExit: VoidFunction }): JSX.Element {
  const { t } = useTranslation();
  const {
    selectedFile,
    selectFile,
    uploadFile,
    jobInfo,
    isUploading,
    isProcessing,
  } = useImport(ImportType.SubscriptionUpdateAmount, onExit);
  const [error, setError] = useState<string>();
  const w = useWizard();

  const checkRequiredHeaders = (headers: string[]) => {
    return requiredHeaders.filter((rq) => !headers.includes(rq));
  };

  const handleUpload = () => {
    uploadFile();
  };

  const handleFileChange = (file: File | null) => {
    selectFile(file);
    let hasInvalidAmount = false;
    if (file) {
      Papa.parse<string[]>(file, {
        header: true,
        transform: (value, field) => {
          if (!hasInvalidAmount && field === 'Monto de la suscripcion') {
            hasInvalidAmount = isNaN(Number(value));
          }
          return value;
        },
        complete: (result) => {
          const lackingHeaders = checkRequiredHeaders(result.meta.fields!);
          let errorMessage = '';
          if (lackingHeaders.length > 0) {
            errorMessage = `Faltan columnas requeridas: ${lackingHeaders.join(',')}`;
          }
          if (hasInvalidAmount) {
            errorMessage = `${errorMessage}
              La columna "Monto de la suscripcion" tiene valores invalidos`;
          }
          if (errorMessage) {
            setError(errorMessage);
          }
        },
      });
    }
  };

  const isLoading = isUploading || isProcessing;

  return (
    <>
      <div>
        <H2 style={{ marginBottom: '1.5rem' }}>
          <Trans t={t} i18nKey={`${prefix}.step_2_title`} />
        </H2>
        <Column gap="16px">
          <Paragraph>{t(`${prefix}.step_2_desc`)}</Paragraph>
          <H3>Información actualizable:</H3>
          <List>
            <li>Monto de la suscripción</li>
            <li>Fecha de próximo cobro</li>
          </List>
          <H3>Columnas requeridas:</H3>
          <List>
            <li>Identificador suscripción</li>
            <li>Monto de la suscripción</li>
            <li>Fecha próximo cobro</li>
          </List>
          <H3>💡Tips:</H3>
          <List>
            <li>
              Los nombres de las columnas requeridas tienen que estar tal cual
              los descargas del archivo original
            </li>
            <li>La fecha siempre debe respetar el formato AAAA/MM/DD</li>
            <li>
              El monto debe expresarse separando sus decimales con un punto (.),
              por ejemplo, $1000.10 para expresar la cantidad de mil pesos con
              10 centavos
            </li>
          </List>
          <InputFile
            data-testid="import-input"
            innerLabel={t('common.examine')}
            noFilesText={t('common.search_import_file')}
            style={{ maxWidth: '335px', marginTop: '1.5rem' }}
            onChange={handleFileChange}
            accept={['.csv', 'text/csv']}
            disabled={isLoading}
            selectedFileText={selectedFile?.name}
          />
          {error && <ErrorMessage>{error}</ErrorMessage>}
          {}
        </Column>
        {isProcessing && jobInfo && <FileProcessing jobInfo={jobInfo} />}
      </div>
      <Row gap="1rem" justifyContent="right">
        <Button onClick={w.previousStep}>Atras</Button>
        <LoadingButton
          onClick={handleUpload}
          isLoading={isLoading}
          disabled={!selectedFile}
        >
          {t(`${prefix}.update_amounts`)}
        </LoadingButton>
      </Row>
    </>
  );
}
