/* eslint-disable react/prop-types */
import styled from 'styled-components';
import { WarningTriangleIcon, HelpIcon } from '@increasecard/icons';
import { forwardRef } from 'react';

export interface IconButtonProps {
  iconType: 'warning' | 'help';
  width?: string;
  height?: string;
  onClick?: () => void;
}

const Button = styled.button`
  padding: 0;
  margin: 0 5px;
  position: relative;
`;

const StyledHelpIcon = styled(HelpIcon)<{
  color?: string;
  width?: string;
  height?: string;
}>`
  display: flex; /* This style removes a space that appears at the bottom */
  cursor: pointer;
  width: ${({ width = '12px' }) => width};
  height: ${({ height = '12px' }) => height};
  fill: ${({ theme, color = 'gray' }) =>
    color === 'gray'
      ? `${theme.colorsNew.gray.grayMedium}`
      : `${theme.colorsNew.product.light}`};
  &:hover {
    fill: ${({ theme, color = 'gray' }) =>
      color === 'gray'
        ? `${theme.colorsNew.increase.light50}`
        : `${theme.colorsNew.gray.white}`};
  }
`;

const StyledWarningIcon = styled(WarningTriangleIcon)<{
  color?: string;
  width?: string;
  height?: string;
}>`
  display: flex; /* This style removes a space that appears at the bottom */
  cursor: pointer;
  width: ${({ width = '12px' }) => width};
  height: ${({ height = '12px' }) => height};
  fill: ${({ theme }) => `${theme.colorsNew.count.regular}`};
  &:hover {
    fill: ${({ theme }) => `${theme.colorsNew.count.dark50}`};
  }
`;

function Icon({ width, height, iconType }: IconButtonProps): JSX.Element {
  switch (iconType) {
    case 'warning':
      return <StyledWarningIcon width={width} height={height} />;
    case 'help':
      return <StyledHelpIcon width={width} height={height} />;
  }
}

// eslint-disable-next-line react/display-name
export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (props, ref) => (
    <Button ref={ref} {...props} type="button">
      <Icon
        width={props.width}
        height={props.height}
        iconType={props.iconType}
      />
    </Button>
  )
);
