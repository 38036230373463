import { Button, InlineText } from '@increasecard/typed-components';
import { useTranslation } from 'react-i18next';
import { Column } from '../../../components/common/Column';
import { LabelBox } from '../../../components/common/LabelBox';
import { PaymentMethods } from '../../../components/paymentMethods/PaymentMethods';
import { formatCurrency } from '../../../utils/currencyUtils';
import { RoundedBoxRow } from './RoundedBoxRow';

import { usePortalPayment } from './usePortalPayment';
import { useCheckoutAccount } from '../AccountContext';
import { useCollectMethods } from '../checkoutQueries';
import { FullPageSpinner } from '../../../components/common/FullPageSpinner';

export function PaymentScreen(): JSX.Element {
  const { t } = useTranslation();
  const { branding_settings, id } = useCheckoutAccount();
  const { amountToPay, defaultInvoice, ...payment } = usePortalPayment();
  const { customer, currency } = defaultInvoice;
  const collectMethods = useCollectMethods(id, customer.id);

  if (collectMethods.isLoading || !collectMethods.data) {
    return <FullPageSpinner />;
  }

  // Render collect methods configured as portal's default
  const cm = {
    card: collectMethods.data.card.filter(
      (cm) => cm.id === branding_settings.collect_method_id
    ),
    cbu: [],
    ticket: [],
  };

  const handlePayInvoice = (paymentData: Metadata) => {
    payment.pay(paymentData);
  };

  if (payment.isSuccess) {
    return (
      <Column alignItems="center" gap="32px">
        <LabelBox
          variant={'success'}
          title={t('common.thanks')}
          description={t('screens.collect_portal.payment_success')}
        />
        <Button onClick={payment.cancel}>
          {t('screens.collect_portal.back')}
        </Button>
      </Column>
    );
  }

  return (
    <Column>
      <RoundedBoxRow>
        <InlineText>{t('screens.collect_portal.amount_to_pay')}</InlineText>
        <InlineText weight="bold">
          {formatCurrency(amountToPay, currency)}
        </InlineText>
      </RoundedBoxRow>
      <div data-testid="payment-method-form">
        <PaymentMethods
          country={customer.billing_info.country}
          processing={payment.isLoading}
          onNext={handlePayInvoice}
          onCancel={payment.cancel}
          submitText={t('common.pay')}
          customerId={customer.id}
          groupedCollectMethods={cm}
          isUniquePayment={true}
          errorObj={payment.error}
        />
      </div>
    </Column>
  );
}
