import { ReactNode } from 'react';
import get from 'lodash/get';
import { Tag as TCTag } from '@increasecard/typed-components';

export const tagTypeColors = {
  subscription: {
    status: {
      pending_approval: {
        background: 'var(--color-count-light30)',
        foreground: 'var(--color-gray-blackMedium)',
      },
      request_rejected: {
        background: 'var(--color-gray-grayMedium)',
        foreground: 'var(--color-gray-blackMedium)',
      },
      pending: {
        background: 'var(--color-count-light30)',
        foreground: 'var(--color-gray-blackMedium)',
      },
      pending_payment: {
        background: 'var(--color-count-light30)',
        foreground: 'var(--color-gray-blackMedium)',
      },
      scheduled: {
        background: 'var(--color-pay-light50)',
        foreground: 'var(--color-gray-blackLight)',
      },
      paused: {
        background: 'var(--color-pay-light50)',
        foreground: 'var(--color-gray-blackLight)',
      },
      paused_automatically: {
        background: 'var(--color-pay-light50)',
        foreground: 'var(--color-gray-blackLight)',
      },
      trial: {
        background: 'var(--color-increase-regular)',
        foreground: 'var(--color-gray-white)',
      },
      active: {
        background: 'var(--color-increase-regular)',
        foreground: 'var(--color-gray-grayLight)',
      },
      grace_period: {
        background: 'var(--color-coral-light)',
        foreground: 'var(--color-gray-blackMedium)',
      },
      pending_canceled: {
        background: 'var(--color-gray-black)',
      },
      canceled: {
        background: 'var(--color-gray-black)',
      },

      pending_finished: {
        background: 'var(--color-gray-black)',
      },
      finished: {
        background: 'var(--color-gray-black)',
      },
    },
  },
  invoice: {
    status: {
      draft: {
        background: 'var(--color-gray-grayMedium)',
        foreground: 'var(--color-gray-blackMedium)',
      },
      open: {
        background: 'var(--color-pay-light)',
        foreground: 'var(--color-pay-dark)',
        border: 'var(--color-gray-subtleBorder)',
      },
      paid: {
        background: 'var(--color-increase-regular)',
      },
      refunded: {
        background: 'var(--color-gray-grayMedium)',
        foreground: 'var(--color-gray-black)',
        border: 'var(--color-gray-subtleBorder)',
      },
      canceled: {
        background: 'var(--color-pay-dark)',
      },
      uncollectible: {
        background: 'var(--color-gray-blackLight)',
      },
      retries_pending: {
        background: 'var(--color-pay-light)',
        foreground: 'var(--color-pay-dark)',
        border: 'var(--color-gray-subtleBorder)',
      },
      refund_in_process: {
        background: 'var(--color-pay-light30)',
      },
    },
  },
  payment: {
    status: {
      open: {
        background: 'var(--color-pay-light)',
        foreground: 'var(--color-pay-dark)',
        border: 'var(--color-gray-subtleBorder)',
      },
      processing: {
        background: 'var(--color-pay-light30)',
      },
      approved: {
        background: 'var(--color-pay-dark30)',
        border: 'var(--color-gray-subtleBorder)',
      },
      failed: {
        background: 'var(--color-coral-regular)',
      },
      reversed: {
        background: 'var(--color-pay-dark)',
      },
      refund_processing: {
        background: 'var(--color-pay-light30)',
      },
      refund_approved: {
        background: 'var(--color-gray-grayMedium)',
        foreground: 'var(--color-gray-black)',
        border: 'var(--color-gray-subtleBorder)',
      },
      refund_failed: {
        background: 'var(--color-count-light30)',
        foreground: 'var(--color-gray-blackMedium)',
      },
    },
  },
  partner: {
    status: {
      disabled: {
        background: 'var(--color-gray-grayMedium)',
        foreground: 'var(--color-gray-blackMedium)',
      },
      checking: {
        background: 'var(--color-pay-light50)',
      },
      enabled: {
        background: 'var(--color-pay-dark30)',
      },
      error: {
        background: 'var(--color-coralAlert-regular)',
      },
    },
  },
  bulkFile: {
    status: {
      upload: {
        background: 'var(--color-gray-grayMedium)',
        foreground: 'var(--color-gray-blackMedium)',
      },
      uploading: {
        background: 'var(--color-gray-grayMedium)',
        foreground: 'var(--color-gray-blackMedium)',
      },
      uploaded: {
        background: 'var(--color-gray-grayBold)',
        foreground: 'var(--color-gray-blackMedium)',
      },
      upload_failed: {
        background: 'var(--color-coral-regular)',
      },
      processing: {
        background: 'var(--color-pay-light)',
      },
      processed: {
        background: 'var(--color-pay-dark30)',
      },
      processed_with_errors: {
        background: 'var(--color-count-light30)',
        foreground: 'var(--color-gray-blackMedium)',
      },
      failed: {
        background: 'var(--color-coral-regular)',
      },
    },
  },
  plan: {
    status: {
      disabled: {
        background: 'var(--color-gray-grayMedium)',
        foreground: 'var(--color-gray-blackMedium)',
      },
      enabled: {
        background: 'var(--color-pay-dark30)',
      },
    },
  },
  discount: {
    status: {
      disabled: {
        background: 'var(--color-gray-grayMedium)',
        foreground: 'var(--color-gray-blackMedium)',
      },
      enabled: {
        background: 'var(--color-pay-dark30)',
      },
    },
  },
  reservation: {
    status: {
      open: {
        background: 'var(--color-pay-light)',
        foreground: 'var(--color-pay-dark)',
        border: 'var(--color-gray-subtleBorder)',
      },
      processing: {
        background: 'var(--color-pay-light30)',
      },
      approved: {
        background: 'var(--color-pay-dark30)',
        border: 'var(--color-gray-subtleBorder)',
      },
      failed: {
        background: 'var(--color-coral-regular)',
      },
      refunded: {
        background: 'var(--color-pay-dark30)',
        border: 'var(--color-gray-subtleBorder)',
      },
      failed_refund: {
        background: 'var(--color-coral-regular)',
      },
    },
  },
};

export interface TagProps {
  children: ReactNode;
  type: string;
  className?: string;
}

export function Tag({ children, type, className }: TagProps): JSX.Element {
  return (
    <TCTag className={className} colors={get(tagTypeColors, type)}>
      {children}
    </TCTag>
  );
}
