import styled from 'styled-components';

export const TabGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  a {
    text-decoration: none;
  }
`;

export const TabGroupWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 56px;
`;
