import { CustomersList } from './components/CustomersList';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Header } from '../../components/common/Header';
import { PayAuthorizations } from '../authorizations/PayAuthorizations';

export function CustomersScreen(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();

  const handleNewClick = () => {
    history.push('/customers/new');
  };

  const handleImportClick = () => {
    history.push('/customers/import');
  };
  // render
  return (
    <>
      <Header
        title={t('screens.customers.title')}
        primaryButton={{
          text: t('common.new_customer'),
          onClick: handleNewClick,
          allowedAuthorization: PayAuthorizations.createEditCustomer,
        }}
        outlineButton={{
          text: t('screens.import.title', {
            name: t('common.customers'),
          }),
          onClick: handleImportClick,
          allowedAuthorization: PayAuthorizations.createBulkCustomers,
        }}
      />
      <CustomersList />
    </>
  );
}
