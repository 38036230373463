import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Grid } from '@increasecard/typed-components';
import { TaxSchema } from './TaxSchema';
import { ALL_COUNTRIES } from '../../../localizations';
import { list2NameValue } from '../../../utils/utils';
import {
  InputPercentageField,
  InputTextField,
  SelectField,
} from '../../../components/common/FormikFields';
import { Tax } from '../../../types/Tax';
import { FooterButtons } from '../FormSections';

export type TaxFormValues = Partial<Tax>;

export interface NewTaxScreenProps {
  initialValues: TaxFormValues;
  onCancel: () => void;
  onSubmit: (tax: TaxFormValues) => void;
  editMode?: boolean;
  isLoading: boolean;
}

export function TaxForm({
  initialValues,
  onSubmit,
  onCancel,
  editMode = false,
  isLoading,
}: NewTaxScreenProps): JSX.Element {
  const { t } = useTranslation();

  const COUNTRY_OPTIONS = list2NameValue(ALL_COUNTRIES, 'data.countries');

  const formikProps = {
    initialValues,
    validationSchema: TaxSchema(t),
    onSubmit,
  };

  return (
    <Formik {...formikProps}>
      {(formik) => (
        <Grid as="form" onSubmit={formik.handleSubmit} rowGap={2}>
          <InputTextField
            className="span-desktop-6"
            id="name"
            autoComplete="off"
            label={t('common.name')}
          />
          <InputTextField
            className="span-desktop-6"
            id="external_id"
            autoComplete="off"
            placeholder={t('common.optional')}
            label={t('common.id')}
          />
          <InputTextField
            as="textarea"
            id="description"
            height="100px"
            autoComplete="off"
            placeholder={t('common.placeholders.description')}
            label={t('common.description')}
          />
          <SelectField
            id="country"
            className="span-desktop-6"
            options={COUNTRY_OPTIONS}
            label={t('common.country')}
            disabled={editMode}
          />
          <InputPercentageField
            className="span-desktop-6"
            id="amount"
            autoComplete="off"
            textAlign="right"
            label={`${t('common.rate')} ${t('common.percent_sign')}`}
            placeholder={t('common.placeholders.rate')}
          />
          <FooterButtons onCancel={onCancel} isLoading={isLoading} />
        </Grid>
      )}
    </Formik>
  );
}
