import { CheckIcon } from '@increasecard/icons';
import {
  H2,
  Paragraph,
  InputText,
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeaderIconBox,
} from '@increasecard/typed-components';
import { Column } from '../common/Column';
import { ChangeEvent, useState } from 'react';
import { Customer } from '../../types/Customer';
import { useValidation } from '../../hooks/useValidation';
import { useTranslationObject } from '../../hooks/useTranslationObject';
import { CheckoutType } from './useShareCheckoutLink';
import { ApiErrorMessage } from '../ErrorMessage';
import { useUpdateCustomer } from '../../features/customers/customerQueries';
export interface ConfirmPhoneModalProps {
  visible: boolean;
  onConfirm: (phoneNumber: string) => void;
  onCancel: () => void;
  checkoutType: CheckoutType;
  customer: Customer;
}
const noop = () => {
  return;
};
export function ConfirmPhoneModal({
  visible,
  onConfirm,
  onCancel,
  checkoutType,
  customer,
}: ConfirmPhoneModalProps): JSX.Element {
  const { translations, t } = useTranslationObject(
    'components.checkout_link_sharing_widget'
  );
  const updateCustomer = useUpdateCustomer();
  const [phoneNumber, setPhoneNumber] = useState<string>(
    customer.billing_info.phone
  );
  const validator = useValidation({
    value: phoneNumber,
    schema: 'onlyNumbersRequired',
  });

  const handleConfirmation = () => {
    if (!validator.isValid) return;
    // update customer with entered phone number
    updateCustomer.mutate(
      {
        id: customer.id,
        billing_info: { phone: phoneNumber },
      },
      {
        onSuccess: () => onConfirm(phoneNumber),
      }
    );
  };

  const title = t('components.checkout_link_sharing_widget.title', {
    checkoutType: t(`common.${checkoutType}`).toLowerCase(),
  });

  const handlePhoneNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(e.target.value);
  };

  return (
    <>
      <Modal
        align="center"
        onClose={onCancel}
        visible={visible}
        onCancel={noop}
        onOk={noop}
      >
        <ModalHeaderIconBox
          colorPalette="pay"
          icon={<CheckIcon width="48px" height="48px" />}
        />
        <ModalContent align="center">
          <Column flexGap="1rem">
            <H2>{title}</H2>
            <Paragraph>{translations.description_1}</Paragraph>
            <Paragraph>{translations.description_2}</Paragraph>
            <InputText
              onChange={handlePhoneNumberChange}
              value={phoneNumber}
              label={t('common.phone')}
              style={{ textAlign: 'initial' }}
              errorMessage={validator.errorMessage}
              required
            />
            <ApiErrorMessage error={updateCustomer.error} />
          </Column>
        </ModalContent>
        <ModalFooter align="center">
          <Button
            key="confirm"
            onClick={handleConfirmation}
            disabled={!validator.isValid || updateCustomer.isLoading}
          >
            {translations.send}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
