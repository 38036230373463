import { WarningCircleIcon } from '@increasecard/icons';
import { H2, CellText } from '@increasecard/typed-components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { BackgroundOrnament } from '../../components/common/BackgroundOrnament';
import { BrandLogo } from './BrandLogo';
import { Column } from '../../components/common/Column';
import { CheckoutAccount } from '../../types/Account';
import { ContactLabel } from './components/ContactLabel';

export interface InvalidCheckoutLinkProps {
  account: CheckoutAccount;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 1.5rem 2.5rem 1rem 2.5rem;
  border-radius: 9px;
  text-align: center;
  box-shadow: ${({ theme }) => theme.shadows.pressed};
  & .warning-icon {
    fill: var(--color-gray-blackLight);
    width: 32px;
    height: 32px;
  }
`;

export function InvalidCheckoutLink({
  account: { branding_settings },
}: InvalidCheckoutLinkProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <Column marginTop="4rem" alignItems="center" gap="24px">
      <BrandLogo position="center" brandingSettings={branding_settings} />
      <Wrapper>
        <WarningCircleIcon className="warning-icon" />
        <H2>{t('screens.invoice_payment.invalid_payment_link')}</H2>
        <CellText>
          {t('screens.invoice_payment.invalid_or_expired_link')}
        </CellText>
        <ContactLabel brandingSettings={branding_settings} />
      </Wrapper>
      <BackgroundOrnament
        variant="float"
        brandingSettings={branding_settings}
      />
    </Column>
  );
}
