import { Button, ButtonProps, Spinner } from '@increasecard/typed-components';
import styled from 'styled-components';

export interface LoadingButtonProps extends ButtonProps {
  isLoading: boolean;
}

const CustomButton = styled(Button)`
  transition: width 2s ease;
  svg .path {
    stroke: currentcolor;
  }
`;

export function LoadingButton({
  isLoading,
  children,
  disabled,
  ...props
}: LoadingButtonProps): JSX.Element {
  return (
    <CustomButton
      {...props}
      disabled={disabled || isLoading}
      icon={isLoading ? <Spinner size={16} /> : null}
    >
      {children}
    </CustomButton>
  );
}
