import { DecidirSchema } from '../CollectMethodsSchema';
import { TFunction } from 'i18next';
import { getDefaultValues, PanelFormConfig } from './PanelConfig';
import { DecidirCollectMethod } from '../../../types/CollectMethod';

export function decidirPanelConfig(
  translate: TFunction,
  collectMethod?: DecidirCollectMethod
): PanelFormConfig {
  const formData = {
    country: 'ARG',
    name: 'Decidir',
    metadata: {
      site_id: '',
      public_api_key: '',
      private_api_key: '',
      site_id_cvv: '',
      public_api_key_cvv: '',
      private_api_key_cvv: '',
    },
  };

  const initialValues = getDefaultValues(formData, collectMethod);
  const transBase = 'screens.collect_methods.decidir';

  // If collect method is enabled, dont validate it since it cant be modified.
  // This is a hack for when api does not return api_login or api_key.
  const validationSchema = collectMethod ? undefined : () => DecidirSchema();

  return {
    type: 'decidir',
    name: 'Decidir',
    description: translate(`${transBase}.description`),
    initialValues,
    status: collectMethod?.status,
    inputs: [
      {
        label: translate(`${transBase}.site_id`),
        id: 'metadata.site_id',
        type: 'text',
      },
      {
        label: translate(`${transBase}.cvv_site_id`),
        id: 'metadata.site_id_cvv',
        type: 'text',
      },
      {
        label: translate(`${transBase}.public_api_key`),
        id: 'metadata.public_api_key',
        type: 'text',
      },
      {
        label: translate(`${transBase}.cvv_public_api_key`),
        id: 'metadata.public_api_key_cvv',
        type: 'text',
      },
      {
        label: translate(`${transBase}.private_api_key`),
        id: 'metadata.private_api_key',
        tooltipContent: translate('screens.collect_methods.secret_fields'),
        required: !collectMethod,
        type: 'text',
      },
      {
        label: translate(`${transBase}.cvv_private_api_key`),
        id: 'metadata.private_api_key_cvv',
        type: 'text',
        tooltipContent: translate('screens.collect_methods.secret_fields'),
        required: !collectMethod,
      },
    ],
    validationSchema,
  };
}
