import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { Constants } from '../../../constants';
import {
  createTax,
  fetchTaxById,
  fetchTaxes,
  updateTax,
} from '../../../services/ExtrasService';
import { Country, PaginatedData } from '../../../types/common';
import { NewApiError } from '../../../types/NewApiError';
import { Tax } from '../../../types/Tax';
import {
  getPaginatedQueriesData,
  useMutationWithInvalidation,
} from '../../../utils/queryUtils';

interface ExtrasFilters {
  search?: string;
  country: Country;
}

export function useTaxes(
  page = 1,
  filters?: ExtrasFilters
): UseQueryResult<PaginatedData<Tax>, NewApiError> {
  const params = {
    page,
    per_page: Constants.SMALL_LIST_PAGE_SIZE,
    ...filters,
  };
  return useQuery({
    queryKey: ['tax', 'list', params],
    queryFn: () => fetchTaxes(params),
    enabled: !!page,
    keepPreviousData: true,
  });
}

export function useTaxById(id: string): UseQueryResult<Tax, NewApiError> {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ['tax', 'detail', id],
    queryFn: () => fetchTaxById(id),
    enabled: !!id,
    placeholderData: () => {
      return getPaginatedQueriesData<Tax>(queryClient, ['tax', 'list'])?.find(
        (c) => c.id === id
      );
    },
  });
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useCreateTax() {
  return useMutationWithInvalidation(createTax, ['tax', 'list']);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useUpdateTax() {
  return useMutationWithInvalidation(updateTax, ['tax', 'list']);
}
