import { useCallback, useState, useEffect } from 'react';
import { useUser, User } from '@increasecard/increase-core';
import {
  getNotificationSettings,
  updateNotificationSettings,
} from '../../services/UserService';
import { UserNotificationSettings } from '../../types/UserNotificationSettings';
import set from 'lodash/set';
import get from 'lodash/get';
import { NewApiError } from '../../types/NewApiError';

export function useNotificationSettings(): {
  fetch: () => void;
  settings: UserNotificationSettings | null;
  user: User | null;
  toggleSetting: (id: string) => void;
  save: () => void;
  cancel: () => void;
  showCancel: boolean;
  fetchError: string | null;
  showSaveSuccess: boolean;
  saveError: NewApiError | null;
} {
  const user = useUser();
  const [settings, setSettings] = useState<UserNotificationSettings | null>(
    null
  );
  const [showCancel, setShowCancel] = useState(false);
  const [fetchError, setFetchError] = useState<string | null>(null);
  const [showSaveSuccess, setShowSaveSuccess] = useState<boolean>(false);
  const [saveError, setSaveError] = useState<NewApiError | null>(null);

  const fetch = useCallback(() => {
    if (!user) return;
    // get settings from api
    getNotificationSettings(user?.id)
      .then((response) => setSettings(response))
      .catch(() => {
        setFetchError('api.errors.unexpected_error');
      });
  }, [user]);

  const toggleSetting = (id: string) => {
    if (!settings) return;
    // copy settings
    const newSettings = { ...settings };
    // get current value
    const currentValue = get(newSettings, id);
    // toggle value
    set(newSettings, id, !currentValue);
    // save local state
    setSettings(newSettings);
    setShowCancel(true);
  };

  const resetSaveStatus = () => {
    setShowSaveSuccess(false);
    setSaveError(null);
  };

  const save = () => {
    if (!user) return;
    if (!settings) return;
    resetSaveStatus();
    setShowCancel(false);
    // update settings (api)
    updateNotificationSettings(user.id, settings)
      .then((response) => {
        setSettings(response);
        setShowSaveSuccess(true);
        setSaveError(null);
      })
      .catch((error) => {
        setShowSaveSuccess(false);
        setSaveError(error);
        setShowCancel(true);
      });
  };

  const cancel = () => {
    fetch();
    setShowCancel(false);
  };

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    fetch,
    settings,
    user,
    toggleSetting,
    save,
    cancel,
    showCancel,
    fetchError,
    showSaveSuccess,
    saveError,
  };
}
