import styled from 'styled-components';
import { Button, ButtonProps } from '@increasecard/typed-components';
import { DeleteIcon } from '@increasecard/icons';

const IconButton = styled(Button)`
  svg {
    fill: var(--color-coralAlert-dark);
    margin: 0;
  }
`;
export type DeleteButtonProps = Omit<ButtonProps, 'icon' | 'children'>;

export function DeleteButton(props: DeleteButtonProps): JSX.Element {
  return (
    <IconButton
      icon={<DeleteIcon />}
      size="small"
      variant="invisible"
      {...props}
      type="button"
    />
  );
}
