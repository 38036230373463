import {
  useRef,
  useState,
  useCallback,
  useReducer,
  FormEvent,
  ChangeEvent,
  Reducer,
} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { InputText, Spinner } from '@increasecard/typed-components';
import { ResponsiveGrid } from '../../common/ResponsiveGrid';
import { OnlyDesktop, useResponsive } from '../../../hooks/useResponsive';
import { DlocalField } from './DlocalField';
import { Constants } from '../../../constants';
import { getCountryConfig } from '../../../utils/countryUtils';
import { getCardBrandByCode } from './dlocalUtils';
import { PaymentFormFooter } from '../PaymentFormFooter';
import { PaymentMethodInfoBox } from '../PaymentMethodInfoBox';
import { NewApiError } from '../../../types/NewApiError';
import {
  DlocalCollectMethod,
  DlocalCrossCollectMethod,
} from '../../../types/CollectMethod';
import { PaymentFormBase } from '../types';
import { GATEWAYS, useLoadGateway } from '../GatewayHOCs';

const baseInputStyle = {
  fontSize: '13px',
  fontFamily: '"Inter var", Inter, sans-serif',
  lineHeight: '16px',
  letterSpacing: '-0.003rem',
  fontSmoothing: 'antialiased',
  fontWeight: '500',
  color: '#182026',
  '::placeholder': {
    color: '#b1b1b1',
  },
  iconStyle: 'solid',
};

const Container = styled.div`
  width: 100%;
  display: flex;
  gap: 1.5rem;
  position: relative;
  flex-wrap: wrap;
  margin: 1rem 0;
  .hide-while-loading {
    opacity: 0.4;
  }
`;

const createDlocalField = (
  fieldsInstance: DlocalFieldsIstance,
  fieldType: unknown,
  options: Metadata
) => {
  return fieldsInstance.create(fieldType, {
    ...options,
    style: {
      base: baseInputStyle,
    },
  });
};

export type DlocalFormProps = PaymentFormBase<
  DlocalCollectMethod | DlocalCrossCollectMethod,
  Metadata
>;

type FieldsData = {
  isReady: boolean;
  isValid: boolean;
};

interface FieldState {
  pan: FieldsData;
  expiration: FieldsData;
  cvv: FieldsData;
}

type Action =
  | {
      type: 'setValid';
      isValid: boolean;
      field: 'pan' | 'expiration' | 'cvv';
    }
  | {
      type: 'setReady';
      isReady: boolean;
      field: 'pan' | 'expiration' | 'cvv';
    };

const reducer: Reducer<FieldState, Action> = (state, action) => {
  switch (action.type) {
    case 'setValid':
      return {
        ...state,
        [action.field]: { ...state[action.field], isValid: action.isValid },
      };
    case 'setReady':
      return {
        ...state,
        [action.field]: { ...state[action.field], isReady: action.isReady },
      };
    default:
      return state;
  }
};

const initialState = {
  pan: {
    isValid: false,
    isReady: false,
  },
  expiration: {
    isValid: false,
    isReady: false,
  },
  cvv: {
    isValid: false,
    isReady: false,
  },
};

function DLForm({
  onSubmit,
  onCancel,
  processing,
  submitText,
  cancelText,
  collectMethods,
  termsAndConditionsUrl,
  errorObj,
  showSetAsDefaultPM,
  country,
}: DlocalFormProps): JSX.Element {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const [fieldData, dispatchFieldData] = useReducer(reducer, initialState);
  const [cardHolderName, setCardHolderName] = useState('');
  const [isDefaultPM, setIsDefaultPM] = useState(false);
  const [cardBrand, setCardBrand] = useState('');
  const [dlocalError, setDlocalError] = useState<NewApiError | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const translations = t('data.payment_method', {
    returnObjects: true,
  }) as Metadata;

  const isDlocalReady = Object.values(fieldData).every((f) => f.isReady);

  const { langCode, alpha2Code } = getCountryConfig(country);

  const fieldsInstance = useRef(
    window.dlocalInstance.fields({
      fonts: [
        {
          cssSrc: 'https://rsms.me/inter/inter-ui.css',
        },
      ],
      locale: langCode,
      country: alpha2Code,
    })
  );
  const pan = useRef(
    createDlocalField(fieldsInstance.current, 'pan', {
      placeholder: translations.card_placeholder,
    })
  );
  const expiration = useRef(
    createDlocalField(fieldsInstance.current, 'expiration', {
      placeholder: translations.exp_date_placeholder,
    })
  );
  const cvv = useRef(
    createDlocalField(fieldsInstance.current, 'cvv', {
      placeholder: translations.cvv_cvc_placeholder,
    })
  );

  const fieldReadyHandle = useCallback((e) => {
    dispatchFieldData({
      type: 'setReady',
      field: e.type,
      isReady: e.isReady,
    });
  }, []);

  const fieldsBlurHandle = useCallback((e) => {
    dispatchFieldData({
      type: 'setValid',
      field: e.type,
      isValid: !e.error,
    });
  }, []);

  const handleFieldChange = useCallback((event) => {
    setCardBrand(event.brand);
  }, []);

  const handleCardHolderNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCardHolderName(e.target.value);
  };

  const handleSetAsDefaultChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsDefaultPM(e.target.checked);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    // If card holder name wasn't filled the submit is canceled and
    // the browswer validations shows up
    if (!cardHolderName) {
      return;
    }
    e.preventDefault();
    if (Object.values(fieldData).every((f) => f.isValid)) {
      setIsSubmitting(true);
      const createTokens = Promise.all([
        window.dlocalInstance.createToken(pan.current, {
          name: cardHolderName,
        }),
        window.dlocalInstance.createToken(cvv.current, {
          name: cardHolderName,
        }),
        window.dlocalInstance.getBinInformation(pan.current),
      ]);

      try {
        const [cardResult, cvvResult, binInfo] = await createTokens;

        setDlocalError(null);

        const dlocalCollectMethod = collectMethods;
        onSubmit({
          ...binInfo,
          type: Constants.CollectMethods.CARD.DLOCAL,
          brand: getCardBrandByCode(binInfo.brand),
          // brand is a 2 letters code based on the card brand name
          // we might need a dic to map them to the full name
          payment_method_id: binInfo.brand,
          token: cardResult.token,
          cvv_token: cvvResult.token,
          collect_method_id: dlocalCollectMethod.id,
          set_as_default: isDefaultPM,
        });
      } catch (error: Metadata) {
        // TODO: improve error treatment
        setDlocalError(
          NewApiError.fromAPI({
            code: 'DLOCA_ERROR',
            // The 2 structures are possible
            message: error.message || error.error?.message,
          })
        );
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const expirationDateLabel = isMobile
    ? translations.expiration_date_long
    : translations.expiration_date;
  const securityCodeLabel = isMobile
    ? translations.security_code_cvv_cvc
    : translations.cvv_cvc;

  const infoBoxData = {
    supportedCards: null,
    cardNumber: '',
    name: cardHolderName,
    expirationDate: '',
    cardBrandId: cardBrand,
  };

  return (
    <Container className="outer-wrapper">
      <ResponsiveGrid
        as="form"
        rowGap={2}
        desktopMaxWidth="335px"
        className={!isDlocalReady ? 'hide-while-loading' : ''}
      >
        <DlocalField
          field={pan.current}
          onReady={fieldReadyHandle}
          onBlur={fieldsBlurHandle}
          onChange={handleFieldChange}
          type="pan"
          label={t('data.payment_method.card_number')}
          className="grid-span-desktop-8"
        />
        <DlocalField
          field={expiration.current}
          onReady={fieldReadyHandle}
          onBlur={fieldsBlurHandle}
          onChange={handleFieldChange}
          type="expiration"
          label={expirationDateLabel}
          className="grid-span-desktop-4"
        />
        <InputText
          id="name"
          label={translations.name_on_card}
          placeholder={t('data.payment_method.cardholder_name_placeholder')}
          onChange={handleCardHolderNameChange}
          required
          autoComplete="name"
          className="grid-span-desktop-8"
        />
        <DlocalField
          field={cvv.current}
          onReady={fieldReadyHandle}
          onBlur={fieldsBlurHandle}
          onChange={handleFieldChange}
          type="cvv"
          label={securityCodeLabel}
          className="grid-span-desktop-4"
        />
        <PaymentFormFooter
          className="grid-span-desktop-12"
          isSubmitting={isSubmitting || processing}
          onSubmit={handleSubmit}
          submitText={submitText}
          onCancel={onCancel}
          cancelText={cancelText}
          termsAndConditionsUrl={termsAndConditionsUrl}
          showCardDisclaimer={true}
          error={errorObj || dlocalError}
          onSetDefaultPaymentMethod={
            showSetAsDefaultPM ? handleSetAsDefaultChange : undefined
          }
        />
      </ResponsiveGrid>
      <OnlyDesktop>
        <PaymentMethodInfoBox
          type="card"
          data={infoBoxData}
          errorObj={errorObj || dlocalError}
        />
      </OnlyDesktop>
    </Container>
  );
}

export function DlocalForm(props: DlocalFormProps): JSX.Element {
  const collectMethod = props.collectMethods;
  const { gatewayLoaded } = useLoadGateway(GATEWAYS.DLOCAL, {
    apiKey: collectMethod.api_key,
  });
  return !gatewayLoaded ? <Spinner size={24} /> : <DLForm {...props} />;
}
