import { OnTopNotification } from '@increasecard/typed-components';
import { useTranslation } from 'react-i18next';
import { ImportedFile, ImportType } from '../../types/ImportFile';
import { formatISODate, getDuration } from '../../utils/dateUtils';
import { useLocalStorage } from '../../hooks/useLocalStorage';

export function ImportNotificationStatus({
  importData,
  type,
}: {
  importData: ImportedFile;
  onClose?: VoidFunction;
  type: ImportType;
}): JSX.Element | null {
  const { t } = useTranslation();
  const [showImportNotification, setImportNotification] = useLocalStorage(
    'showImportNotification',
    false
  );
  const getSuccessMessage = () => {
    if (importData) {
      const finishDate = formatISODate(importData.updated_at);
      const duration = getDuration(
        importData.created_at,
        importData.updated_at
      );
      return t('screens.import.success_title', {
        name: importData.name,
        duration,
        date: finishDate,
      });
    }
    return '';
  };

  const hide = () => {
    setImportNotification(false);
  };

  if (
    showImportNotification &&
    importData.type === type &&
    (importData.status === 'processed' || importData.status === 'failed')
  ) {
    return (
      <>
        <OnTopNotification
          variant="success"
          style={{ marginTop: '2rem' }}
          onClose={hide}
        >
          {getSuccessMessage()}
        </OnTopNotification>
        {importData.type === type &&
          importData.processing_errors.line_errors?.length > 0 && (
            <OnTopNotification
              variant="error"
              style={{ margin: '1rem 0' }}
              onClose={hide}
            >
              {t('screens.import.error_notification_description', {
                name: importData.name,
                lines_number: importData.processing_errors.line_errors?.length,
                total: importData.total_lines - 1,
              })}
            </OnTopNotification>
          )}
      </>
    );
  }

  return null;
}
