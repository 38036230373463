import { parseISO } from 'date-fns';
import { useHistory } from 'react-router';
import { getQueryString } from '../utils/apiUtils';
import { useQueryParams } from './useQueryParams';

function parseDates(obj: Metadata, dateFields: string[]) {
  for (let i = 0; i < dateFields.length; i++) {
    const field = dateFields[i];
    if (obj[field]) {
      obj[field] = parseISO(obj[dateFields[i]]);
    }
  }
  return obj;
}

export type UseUrlState<T> = [
  state: T,
  setState: (partialState: Partial<T>) => void,
];

export function useUrlState<T = Metadata>(
  initialState: T,
  dateFields?: string[]
): UseUrlState<T> {
  const history = useHistory();
  const searchParams = useQueryParams();
  const urlState = dateFields
    ? parseDates(searchParams, dateFields)
    : searchParams;

  const setUrlState = (partialState: Partial<T>) => {
    // Decode to not show escaped characters in the url.
    const qs = decodeURIComponent(
      getQueryString({ ...urlState, ...partialState })
    );
    const pathname = history.location.pathname;
    history.push({ pathname, search: qs });
  };
  return [{ ...initialState, ...urlState }, setUrlState];
}
