import { Button, H1 } from '@increasecard/typed-components';
import { PathSwitcher } from '../../components/common/PathSwitcher';
import { CustomerSelector } from '../customers/CustomerSelector';
import { Row } from '../../components/common/Row';
import { CustomerForm, CustomerFormValues } from '../customers/CustomerForm';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUIEventTracker } from '../../hooks/useUIEventTracker';
import { useCreateCustomer } from '../customers/customerQueries';

export interface SubscriptionCustomerStepProps {
  customerId?: string;
  onNextStep: VoidFunction;
  onCancel: VoidFunction;
  onCustomer: (customerId: string) => void;
}

const prefix = 'screens.new_subscription';

export function SubscriptionCustomerStep({
  customerId,
  onNextStep,
  onCancel,
  onCustomer,
}: SubscriptionCustomerStepProps): JSX.Element {
  const { t } = useTranslation();
  const [searchCustomer, setSearchCustomer] = useState(false);
  const trackEvent = useUIEventTracker('subscription wizard');
  const newCustomer = useCreateCustomer();

  const switchPath = (value: boolean) => {
    setSearchCustomer(value);
    if (!value) onCustomer('');
  };

  const handleCreateCustomer = (values: CustomerFormValues) => {
    trackEvent('create_customer');
    newCustomer.mutate(values, {
      onSuccess: (customer) => {
        onCustomer(customer.id);
        onNextStep();
      },
    });
  };
  return (
    <>
      <H1>{t('common.customer')}</H1>
      <PathSwitcher
        style={{ marginBottom: '24px' }}
        optionATitle={t(`${prefix}.customer_option_a`)}
        optionBTitle={t(`${prefix}.customer_option_b`)}
        optionADescription={t(`${prefix}.customer_option_a_desc`)}
        optionBDescription={t(`${prefix}.customer_option_b_desc`)}
        value={searchCustomer}
        onChange={switchPath}
      />
      {searchCustomer ? (
        <>
          <CustomerSelector
            titleHidden={true}
            initialCustomerId={customerId}
            onChange={(customer) => {
              onCustomer(customer.id);
            }}
          />
          <Row marginTop="2rem" gap="1rem" alignItems="center">
            <Button
              buttonType="primary"
              disabled={!customerId}
              onClick={onNextStep}
            >
              {t('common.continue')}
            </Button>
            <Button key="cancel" onClick={onCancel} buttonType="invisible">
              {t('common.cancel')}
            </Button>
          </Row>
        </>
      ) : (
        <CustomerForm
          submitText={t(`${prefix}.create_customer_and_continue`)}
          onSubmit={handleCreateCustomer}
          onCancel={onCancel}
          isSubmitting={newCustomer.isLoading}
          error={newCustomer.error}
        />
      )}
    </>
  );
}
