import { apiFront } from './api';
import { StatusChangeEvent } from '../types/StatusChangeEvent';
import { PaginatedData } from '../types/common';
import { NewApiError } from '../types/NewApiError';
import { Subscription } from '../types/Subscription';
import { getQueryString } from '../utils/apiUtils';
import { DebtItem } from '../types/Invoice';
import { subscriptionFromApi } from '../utils/subscriptionUtils';
import { entityExtrasToApi } from '../utils/extrasUtils';

export async function fetchEvents(
  subscriptionId: string,
  pageNumber = 1
): Promise<PaginatedData<StatusChangeEvent>> {
  const response = await apiFront.get(
    `subscriptions/${subscriptionId}/events?page=${pageNumber}&per_page=8`
  );
  // This condition is only needed because api returns an empty array
  // instead of a paginated resource when there isn't any events.
  if (!response.data || response.data.data.length < 1) {
    return {
      data: [],
      total_elements: 0,
      total_pages: 0,
      current_page: '0',
      per_page: 8,
      page: 0,
    };
  }
  // filter events with no message
  response.data.data = response.data.data.filter(
    (event: StatusChangeEvent) => !!event.message
  );
  return response.data;
}

export interface MassiveActionParams {
  subscriptions: Subscription[];
}
export interface PauseSubscriptionParams extends MassiveActionParams {
  periods?: number;
}
export interface CancelSubscriptionParams extends MassiveActionParams {
  stopRetries?: boolean;
}

export async function pauseSubscriptions(
  params: PauseSubscriptionParams
): Promise<void | NewApiError> {
  const response = await apiFront.post(`subscriptions/pause`, {
    subscription_ids: params.subscriptions.map((s) => s.id),
    periods: params.periods,
  });
  return response.data;
}

export async function cancelSubscriptions(
  params: CancelSubscriptionParams
): Promise<void | NewApiError> {
  const response = await apiFront.post(`subscriptions/cancel`, {
    subscription_ids: params.subscriptions.map((s) => s.id),
    stop_retries: params.stopRetries,
  });
  return response.data;
}
export interface ActivateSubscriptionParams extends MassiveActionParams {
  startDate?: Date;
}

export async function activateSubscriptions(
  params: ActivateSubscriptionParams
): Promise<void | NewApiError> {
  const response = await apiFront.post(`subscriptions/activate`, {
    subscription_ids: params.subscriptions.map((s) => s.id),
    start_date: params.startDate,
  });
  return response.data;
}

export async function approveSubscriptions(
  params: MassiveActionParams
): Promise<void | NewApiError> {
  const response = await apiFront.post(`subscriptions/approve`, {
    subscription_ids: params.subscriptions.map((s) => s.id),
  });
  return response.data;
}

export async function rejectSubscriptions(
  params: MassiveActionParams
): Promise<void | NewApiError> {
  const response = await apiFront.post(`subscriptions/reject`, {
    subscription_ids: params.subscriptions.map((s) => s.id),
  });
  return response.data;
}

export async function paymentMethodsRequest(
  params: MassiveActionParams
): Promise<void | NewApiError> {
  const response = await apiFront.post(`subscriptions/payment_method_request`, {
    subscription_ids: params.subscriptions.map((s) => s.id),
  });
  return response.data;
}

export interface ValidateNextBillingDateParams {
  subscriptionId?: string;
  newBillingDate?: Date;
}

export interface NBDValidation {
  days_diff: number;
  alert: 'ok' | 'warn_soon' | 'warn_late';
  relative_to: 'last_invoice' | 'today';
}

export async function validateNextBillingDate({
  subscriptionId,
  newBillingDate,
}: ValidateNextBillingDateParams): Promise<NBDValidation> {
  const response = await apiFront.get(
    `subscriptions/${subscriptionId}/validate_next_billing_date?next_billing_date=${newBillingDate?.toISOString()}`
  );
  return response.data;
}

export interface ChangeBillingDateParams extends MassiveActionParams {
  billingDate: Date;
}

export async function changeBillingDate(
  params: ChangeBillingDateParams
): Promise<void | NewApiError> {
  const response = await apiFront.put(
    `subscriptions/alter_next_billing_dates`,
    {
      subscription_ids: params.subscriptions.map((s) => s.id),
      next_billing_date: params.billingDate,
    }
  );
  return response.data;
}

export async function fetchSubscriptions(
  params?: Metadata
): Promise<PaginatedData<Subscription>> {
  const queryString = getQueryString(params);
  const response = await apiFront.get(`subscriptions${queryString}`);
  return response.data;
}

export async function fetchSubscriptionById(id: string): Promise<Subscription> {
  const response = await apiFront.get(`subscriptions/${id}`);
  return subscriptionFromApi(response.data);
}

export async function createSubscription(
  params: Metadata
): Promise<Subscription> {
  const response = await apiFront.post(
    'subscriptions',
    entityExtrasToApi(params)
  );
  return response.data;
}

export async function updateSubscription({
  id,
  ...params
}: Metadata): Promise<Subscription> {
  const response = await apiFront.patch(
    `subscriptions/${id}`,
    entityExtrasToApi(params)
  );
  return response.data;
}

export async function fetchSubscriptionDebt(
  subscriptionId: string
): Promise<DebtItem[]> {
  const response = await apiFront.get(
    `subscriptions/${subscriptionId}/uncollectible_invoices`
  );
  return response.data.data;
}
