import { useTranslation } from 'react-i18next';
import { Header } from '../../components/common/Header';
import { PaymentsTable } from './PaymentsTable';
import { PaymentsFilters, usePaymentsFilters } from './PaymentsFilters';

export function PaymentsList(): JSX.Element {
  const { t } = useTranslation();
  const [filters] = usePaymentsFilters();

  // render
  return (
    <>
      <Header title={t('common.payment_orders')} />
      <PaymentsFilters filters={filters} />
      <PaymentsTable filters={filters} trackingContext="payment screen" />
    </>
  );
}
