import { useContext } from 'react';
import { H2, TableWrapper } from '@increasecard/typed-components';
import {
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line,
  CartesianGrid,
} from 'recharts';
import { ErrorMessage } from '../../components/ErrorMessage';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import { FullPageSpinner } from '../../components/common/FullPageSpinner';
import { HelpButton } from '../../components/common/HelpButton';
import { useSubscriptionsByStatus } from './summaryQueries';
import { useMetricsFilters } from './useMetricsFilters';
import { numberCompactFormat } from '../../utils/utils';
import { Row } from '../../components/common/Row';
import { formatCurrency } from '../../utils/currencyUtils';
import { CHART_TOOLTIP_STYLE, getYAxisLabel } from './helpers';

interface TotalSubscriptionAmountChartProps {
  className?: string;
}

export function TotalSubscriptionAmountChart({
  className,
}: TotalSubscriptionAmountChartProps): JSX.Element {
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);
  const [filters] = useMetricsFilters();
  const {
    data: formattedData,
    error,
    isSuccess,
    isLoading,
    refetch,
  } = useSubscriptionsByStatus(filters);

  const translationKey = 'metrics.totalSubscriptionAmount';

  return (
    <div>
      <Row alignItems="center" gap="0.5rem" margin="0 0 0.5rem">
        <H2>{t(`${translationKey}.title`)}</H2>
        <HelpButton
          titleKey={`${translationKey}.title`}
          descriptionKey={`${translationKey}.help.description`}
          noteKey={`${translationKey}.help.note`}
        />
      </Row>
      <TableWrapper className={className}>
        {error && (
          <ErrorMessage onClick={() => refetch()}>
            {t('api.errors.unexpected_error')}
          </ErrorMessage>
        )}
        {isLoading && <FullPageSpinner height="270px" />}
        {isSuccess && (
          <ResponsiveContainer height={270}>
            <LineChart data={formattedData}>
              <XAxis
                dataKey="time"
                padding={{ left: 30, right: 30 }}
                tick={{ dy: 8, fontSize: 13 }}
                tickSize={5}
              />
              <YAxis
                type="number"
                tickFormatter={numberCompactFormat}
                tick={{ fontSize: 13 }}
                label={getYAxisLabel(t(`${translationKey}.yAxis`))}
              />
              <CartesianGrid strokeDasharray="3 5" vertical={false} />
              <Tooltip
                {...CHART_TOOLTIP_STYLE}
                active={true}
                formatter={(value: string) => [
                  formatCurrency(parseFloat(value), filters.currency || 'ARS'),
                  t('common.total') as string,
                ]}
              />
              <Line
                isAnimationActive={false}
                dataKey="totalAmount"
                stroke={themeContext.colorsNew.pay.dark}
                strokeWidth={2}
                dot={{
                  stroke: themeContext.colorsNew.pay.dark,
                  strokeWidth: 4,
                  r: 5,
                }}
                activeDot={{
                  stroke: themeContext.colorsNew.pay.dark,
                  strokeWidth: 4,
                  r: 4,
                }}
              />
            </LineChart>
          </ResponsiveContainer>
        )}
      </TableWrapper>
    </div>
  );
}
