import {
  NativeSelect,
  NativeSelectProps,
} from '@increasecard/typed-components';
import { useTranslation } from 'react-i18next';
import { ALL_COUNTRIES } from '../../localizations';
import { list2NameValue } from '../../utils/utils';

export type CountrySelectProps = Omit<NativeSelectProps, 'label'>;

export function CountrySelect(props: CountrySelectProps): JSX.Element {
  const { t } = useTranslation();
  const COUNTRY_OPTIONS = list2NameValue(ALL_COUNTRIES, 'data.countries');
  return (
    <NativeSelect {...props} label={t('common.country')}>
      <option key="first" value="">
        {t('data.billing.country_placeholder')}
      </option>
      {COUNTRY_OPTIONS.map((opt, i) => (
        <option key={i} value={opt.value}>
          {opt.name}
        </option>
      ))}
    </NativeSelect>
  );
}
