import { H1, Link, Paragraph } from '@increasecard/typed-components';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { BackgroundOrnament } from '../../components/common/BackgroundOrnament';
import { AuthorizationList, useUser } from '@increasecard/increase-core';

const Wrapper = styled.div`
  width: 100%;
`;

const TextContainer = styled.div`
  margin-top: 100px;
  margin-left: 16px;
  max-width: 540px;
  display: grid;
  row-gap: 12px;
`;

interface AccessDeniedProps {
  allowed: AuthorizationList;
}

export function AccessDenied({ allowed }: AccessDeniedProps): JSX.Element {
  const user = useUser();
  const { t } = useTranslation();
  const theme = useTheme();
  const brandingSettings = {
    colors: {
      primary: theme.colorsNew.product.dark,
      secondary: theme.colorsNew.product.accent,
    },
  };
  return (
    <Wrapper>
      <TextContainer>
        <H1>
          {t('components.access_denied_screen.title', {
            firstName: user?.firstName,
            lastName: user?.lastName,
          })}
        </H1>
        <Paragraph>
          {t('components.access_denied_screen.access_denied', {
            allowed: allowed[0],
          })}
        </Paragraph>
        <Paragraph>
          {t('components.access_denied_screen.access_denied_info')}
          <Link
            href="https://developers.increase.app/docs/accesos-por-rol-pay"
            target="blank"
          >
            aquí.
          </Link>
        </Paragraph>
      </TextContainer>
      <BackgroundOrnament variant="float" brandingSettings={brandingSettings} />
    </Wrapper>
  );
}
