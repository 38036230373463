import { useParams } from 'react-router-dom';
import { InvoiceDetail } from './InvoiceDetail';
import { useInvoiceById } from './invoiceQueries';
import { FullPageSpinner } from '../../components/common/FullPageSpinner';
import { ApiErrorMessage } from '../../components/ErrorMessage';

export function InvoiceDetailScreen(): JSX.Element | null {
  const { invoice_id } = useParams<{ invoice_id: string }>();
  const invoice = useInvoiceById(invoice_id);

  if (invoice.isError) {
    return (
      <ApiErrorMessage
        error={invoice.error}
        onClick={() => invoice.refetch()}
      />
    );
  }

  if (invoice.isLoading || !invoice.data) {
    return <FullPageSpinner height="50vh" size={32} />;
  }

  return <InvoiceDetail invoice={invoice.data} />;
}
