import { useReducer, Reducer, useCallback } from 'react';
import { Country } from '../../../types/common';
import { useUnsupportedPaymentMethods } from '../useUnsupportedPaymentMethods';
import { FieldEvent } from './usePOSField';

interface FormState {
  expiration: FieldEvent;
  cardNumber: FieldEvent;
  cvv: FieldEvent;
  cardBrand?: string;
  cardHolderName?: string;
  set_as_default?: boolean;
  identityDocument: {
    number?: string;
    type?: string;
  };
}

type Action =
  | { type: 'setFieldData'; payload: Metadata }
  | { type: 'setAsDefault'; payload: boolean }
  | {
      type:
        | 'cardHolderName'
        | 'identityDocumentNumber'
        | 'identityDocumentType';
      payload: string;
    };

interface UsePOSForm {
  formState: FormState;
  setFormValue: (action: Action) => void;
  handlePosFieldUpdate: (event: FieldEvent) => void;
  isValid: boolean;
}

const initialState = {
  cardNumber: {
    type: 'cardNumber' as FieldEvent['type'],
    valid: false,
  },
  expiration: {
    type: 'expiration' as FieldEvent['type'],
    valid: false,
  },
  cvv: {
    type: 'cvv' as FieldEvent['type'],
    valid: false,
  },
  identityDocument: {},
};

const formReducer: Reducer<FormState, Action> = (
  state = initialState,
  action
): FormState => {
  switch (action.type) {
    case 'setFieldData':
      return {
        ...state,
        [action.payload.type]: action.payload,
        cardBrand: action.payload.brand,
      };
    case 'cardHolderName':
      return {
        ...state,
        cardHolderName: action.payload,
      };
    case 'identityDocumentType':
      return {
        ...state,
        identityDocument: {
          ...state.identityDocument,
          type: action.payload,
        },
      };
    case 'identityDocumentNumber':
      return {
        ...state,
        identityDocument: {
          ...state.identityDocument,
          number: action.payload,
        },
      };
    case 'setAsDefault':
      return {
        ...state,
        set_as_default: action.payload,
      };
    default:
      return state;
  }
};

export interface UsePOSFormParams {
  country: Country;
  isUniquePayment?: boolean;
}

export function usePOSForm({
  country,
  isUniquePayment,
}: UsePOSFormParams): UsePOSForm {
  const [formState, dispatchFormState] = useReducer(formReducer, initialState);
  // TODO(eedv): pass the country to get the correct unsupportedCollectMethods
  const { isPaymentMethodSupported } = useUnsupportedPaymentMethods({
    collectMethodType: 'CollectMethods::PaymentsOs',
    country,
    isUniquePayment,
  });

  const handlePosFieldUpdate = useCallback(
    (event: FieldEvent) => {
      const { supported, error } = isPaymentMethodSupported(event.bin);
      if (
        event.type === 'cardNumber' &&
        event.bin &&
        event.bin.length >= 6 &&
        !supported
      ) {
        event = {
          ...event,
          error,
          valid: false,
        };
      }
      dispatchFormState({
        type: 'setFieldData',
        payload: event,
      });
    },
    [isPaymentMethodSupported]
  );

  const setFormValue = dispatchFormState;

  const isValid =
    formState.cardNumber.valid &&
    formState.expiration.valid &&
    formState.cvv.valid &&
    !!formState.cardHolderName;

  return {
    formState,
    setFormValue,
    handlePosFieldUpdate,
    isValid,
  };
}
