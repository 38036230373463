import { Tag as TCTag, TagProps } from '@increasecard/typed-components';
import { CheckoutInvoice } from '../../../types';
import { useTranslation } from 'react-i18next';
import { isInvoicePayable } from './helpers';

export interface InvoiceStatusTagProps {
  invoice: CheckoutInvoice;
  className?: string;
}

export function InvoiceStatusTag({
  invoice,
  className,
}: InvoiceStatusTagProps): JSX.Element {
  const { t } = useTranslation();
  let status = '';
  let colors: TagProps['colors'] = {
    background: 'var(--color-gray-subtleBorder)',
  };
  if (isInvoicePayable(invoice)) {
    status = 'pending';
    colors = {
      background: 'var(--color-pay-light)',
      foreground: 'var(--color-pay-dark)',
      border: 'var(--color-gray-subtleBorder)',
    };
  } else if (invoice.status === 'paid') {
    if (invoice.payments.some((p) => p.refund_status === 'approved')) {
      status = 'refunded';
      colors = {
        background: 'var(--color-gray-grayMedium)',
        foreground: 'var(--color-gray-black)',
        border: 'var(--color-gray-subtleBorder)',
      };
    } else {
      status = 'paid';
      colors = {
        background: 'var(--color-increase-regular)',
      };
    }
  }
  return (
    <TCTag className={className} colors={colors}>
      {t(`screens.collect_portal.invoice_statuses.${status}`)}
    </TCTag>
  );
}
