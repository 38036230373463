import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { PaymentMethodLabel } from '../../../../components/paymentMethods/PaymentMethodLabel';
import { Table, H3 } from '@increasecard/typed-components';
import { Currency as CurrencyType } from '../../../../types/common';
import { CheckoutData } from '../../useCheckout';
import {
  CheckoutSubscription,
  SummaryRow,
} from '../../../../types/Subscription';
import { useSummary } from '../../checkoutQueries';
import { useCheckoutParams } from '../../useCheckoutParams';
import { formatCurrency } from '../../../../utils/currencyUtils';
import { PlanTypes } from '../../../../types/Plan';
import { IconButtonTooltip } from '../../../../components/common/HelpTooltip';
import { getCountryConfig } from '../../../../utils/countryUtils';

export const StyledTable = styled(Table)`
  color: var(--color-gray-blackSLight);
  tbody tr:last-child {
    border-bottom: none;
    td {
      padding: 16px 8px;
    }
  }
  tbody tr:nth-last-child(2) {
    border-bottom: 2px solid var(--color-gray-blackSLight);
  }
`;

export interface SummaryTableProps {
  subscription: CheckoutSubscription;
  checkoutData?: CheckoutData;
  fullWidth?: boolean;
  summaryType: 'subscription' | 'payment';
}

function Row({
  data,
  currency,
  language,
  planType,
}: {
  data: SummaryRow;
  currency: CurrencyType;
  language?: string;
  planType: PlanTypes;
}) {
  const { t } = useTranslation();
  let label;
  let amount;
  const formattedAmount =
    typeof data.amount === 'number'
      ? formatCurrency(data.amount, currency, language)
      : '';

  switch (data.type) {
    case 'subscription':
      label = t('common.amount');
      if (planType === 'variableflat') {
        amount = (
          <>
            {formattedAmount || t('data.plan.types.variableflat')}
            <IconButtonTooltip
              content={t('screens.customer_checkout.variable_plan_disclaimer')}
            />
          </>
        );
      } else {
        amount = formattedAmount;
      }
      break;
    case 'one_time_cost':
      label = (
        <>
          {data.label}
          <IconButtonTooltip
            content={t('screens.customer_checkout.one_time_cost_disclaimer')}
          />
        </>
      );
      amount = formattedAmount;
      break;
    case 'sub_total':
      label = <strong>{data.label}</strong>;
      amount = <strong>{formattedAmount}</strong>;
      break;
    case 'total':
      label = <H3 style={{ color: 'black' }}>{data.label}</H3>;
      amount = <H3 style={{ color: 'black' }}>{formattedAmount}</H3>;
      break;
    default:
      label = data.label;
      amount = formattedAmount;
      break;
  }
  return (
    <tr>
      <td>{label}</td>
      <td>{data.units}</td>
      <td className="rightAligned">{amount}</td>
    </tr>
  );
}

export function SummaryTable({
  checkoutData,
  subscription,
  summaryType,
}: SummaryTableProps): JSX.Element | null {
  const { t } = useTranslation();
  const { accountId } = useCheckoutParams();
  const summaryQuery = useSummary({
    accountId,
    subscriptionId: subscription?.id,
    planId: checkoutData?.plan?.id,
    summaryType,
    quantity: checkoutData?.planQuantity,
    couponName: checkoutData?.coupon,
  });

  const plan = subscription?.plan || checkoutData?.plan;
  if (!plan) return null;

  const language = subscription?.customer?.billing_info?.country
    ? getCountryConfig(subscription.customer.billing_info.country).language
    : undefined;
  const currency = plan.currency;

  const sortedSummary = summaryQuery.data?.sort(
    (a: SummaryRow, b: SummaryRow) =>
      Number(a.item_number) - Number(b.item_number)
  );

  return (
    <StyledTable>
      <tbody>
        {subscription?.payment_method && (
          <tr>
            <td colSpan={2}>
              {t('common.current_payment_method')}
              {!subscription.payment_method.validated && (
                <IconButtonTooltip
                  content="No pudo ser validado"
                  iconType="warning"
                  width="24px"
                  height="24px"
                />
              )}
            </td>
            <td className="rightAligned">
              <PaymentMethodLabel paymentMethod={subscription.payment_method} />
            </td>
          </tr>
        )}
        {plan?.trial_period_days > 0 && (
          <tr>
            <td colSpan={2}>{t('common.trial')}</td>
            <td className="rightAligned">
              {t('common.number_days', {
                days: plan.trial_period_days,
              })}
            </td>
          </tr>
        )}
        {sortedSummary &&
          sortedSummary.map((row: SummaryRow) => (
            <Row
              data={row}
              currency={currency}
              language={language}
              planType={plan.type}
              key={row.item_number}
            />
          ))}
      </tbody>
    </StyledTable>
  );
}
