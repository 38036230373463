import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';
import { Box } from './Box';

const gapMixin = css<{ flexGap?: CSSProperties['marginTop'] }>`
  & > *:not(:first-child) {
    margin-top: ${({ flexGap }) => flexGap};
  }
`;

const flexMixin = css<{ childrenFlex?: CSSProperties['flex'] }>`
  & > * {
    flex: ${({ childrenFlex }) => childrenFlex};
  }
`;

const CustomBox = styled(Box)<{
  flexGap?: CSSProperties['marginTop'];
  childrenFlex?: CSSProperties['flex'];
}>`
  ${({ flexGap }) => flexGap && gapMixin};
  ${({ childrenFlex }) => childrenFlex && flexMixin};
`;

export type ColumnProps = typeof CustomBox.arguments;

export function Column({ children, ...rest }: ColumnProps): JSX.Element {
  return (
    <CustomBox
      display="flex"
      flexDirection="column"
      width="100%"
      margin="1rem 0"
      {...rest}
    >
      {children}
    </CustomBox>
  );
}
