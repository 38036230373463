import { CellText, InputSearch } from '@increasecard/typed-components';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useDiscounts } from './discounts/discountQueries';
import { useOneTimeCosts } from './oneTimeCosts/oneTimeCostQueries';
import { useTaxes } from './taxes/taxQueries';
import { Country, Currency, Extra } from '../../types/common';
import { useAditionalCosts } from './aditionalCosts/aditionalCostQueries';
const StyledDiv = styled.div`
  height: 48px;
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const ListWrapper = styled.div`
  height: 155px;
  margin-top: 1.5rem;
  overflow-x: auto;
  overflow-y: overlay;
`;

interface ExtraModalListProps {
  onSelect: (e: Extra) => void;
  onSearch: (search: string) => void;
  list: Extra[] | undefined;
  titlePath: string;
}

function ExtraModalList({
  onSelect,
  onSearch,
  list,
  titlePath,
}: ExtraModalListProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      <StyledDiv>
        <InputSearch
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onSearch(e.target.value)
          }
          placeholder={t('screens.subscriptions.search_extra', {
            title: t(titlePath),
          })}
        />
      </StyledDiv>
      <ListWrapper>
        {list &&
          list.map((extra, index) => (
            <StyledDiv onClick={() => onSelect(extra)} key={index}>
              <CellText weight="bold">{extra.name}</CellText>
              <CellText>{extra.description}</CellText>
            </StyledDiv>
          ))}
      </ListWrapper>
    </>
  );
}

export interface ExtraListProps {
  onSelect: (e: Extra) => void;
  country: Country;
  currency: Currency;
}

export function DiscountModalList({
  onSelect,
  country,
  currency,
}: ExtraListProps): JSX.Element {
  const [search, setSearch] = useState<string>();
  const discounts = useDiscounts(1, { country, currency, search });

  return (
    <ExtraModalList
      onSelect={onSelect}
      onSearch={setSearch}
      list={discounts.data?.data}
      titlePath="common.discounts"
    />
  );
}

export function TaxModalList({
  onSelect,
  country,
}: ExtraListProps): JSX.Element {
  const [search, setSearch] = useState<string>();
  const taxes = useTaxes(1, { country, search });
  return (
    <ExtraModalList
      onSelect={onSelect}
      onSearch={setSearch}
      list={taxes.data?.data}
      titlePath="common.taxes"
    />
  );
}

export function OTCModalList({
  onSelect,
  country,
  currency,
}: ExtraListProps): JSX.Element {
  const [search, setSearch] = useState<string>();
  const otc = useOneTimeCosts(1, { country, currency, search });
  return (
    <ExtraModalList
      onSelect={onSelect}
      onSearch={setSearch}
      list={otc.data?.data}
      titlePath="common.one_time_cost"
    />
  );
}

export function ACModalList({
  onSelect,
  country,
  currency,
}: ExtraListProps): JSX.Element {
  const [search, setSearch] = useState<string>();
  const otc = useAditionalCosts(1, { country, currency, search });
  return (
    <ExtraModalList
      onSelect={onSelect}
      onSearch={setSearch}
      list={otc.data?.data}
      titlePath="common.additional_cost"
    />
  );
}
