import styled from 'styled-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ModalContent,
  Paragraph,
  DateInput,
} from '@increasecard/typed-components';
import { Subscription } from '../../../types/Subscription';
import { useChangeBillingDate } from '../subscriptionQueries';
import { MassiveActionFooter } from './MassiveActionsFooter';
import { MassiveActionResults } from './MassiveActionResults';
import { parseISO } from 'date-fns/esm';
import { BillingDateInfo } from './BillingDateInfo';

const DateWrapper = styled.div`
  max-width: 218px;
  margin: 0px auto;
`;

export interface BillingDateModalContentProps {
  onClose: VoidFunction;
  onSuccess?: VoidFunction;
  subscription: Subscription[];
}

export function BillingDateModalContent({
  subscription,
  onClose,
  onSuccess,
}: BillingDateModalContentProps): JSX.Element {
  const { t } = useTranslation();
  const { mutate, error, isError, isLoading, isSuccess } =
    useChangeBillingDate();
  const singleSubscription =
    subscription.length === 1 ? subscription[0] : undefined;
  const [billingDate, setBillingDate] = useState(
    singleSubscription
      ? parseISO(singleSubscription.next_billing_date)
      : undefined
  );
  const [errorMessage, setErrorMessage] = useState<string>();

  const handleSubmitChange = () => {
    if (!billingDate) {
      setErrorMessage(t('validation.required'));
      return;
    }
    mutate(
      {
        subscriptions: subscription,
        billingDate,
      },
      { onSuccess }
    );
  };

  const handleBillingnDateChange = (date: Date) => {
    setErrorMessage(undefined);
    setBillingDate(date);
  };

  return (
    <>
      <ModalContent align="center">
        {!isError && !isSuccess && (
          <>
            <Paragraph>
              {t('screens.subscriptions.change_billing_date.subtitle', {
                count: subscription.length,
              })}
            </Paragraph>
            <DateWrapper>
              <DateInput
                datepickerProps={{
                  minDate: new Date(),
                }}
                id="billingDate"
                onChange={handleBillingnDateChange}
                value={billingDate}
                errorMessage={errorMessage}
              />
            </DateWrapper>
            <BillingDateInfo
              subscriptionId={singleSubscription?.id}
              newBillingDate={billingDate}
            />
          </>
        )}
        <MassiveActionResults
          subscriptions={subscription}
          error={error}
          isSuccess={isSuccess}
          actionType="change_billing_date"
        />
      </ModalContent>
      <MassiveActionFooter
        onClose={onClose}
        onConfirm={handleSubmitChange}
        isLoading={isLoading}
        isError={isError}
        isSuccess={isSuccess}
        confirmLabel={t('screens.subscriptions.change_billing_date.confirm', {
          count: subscription.length,
        })}
      />
    </>
  );
}
