import { useRef, useState } from 'react';
import { useTimeout } from '../../hooks/useTimeout';
import Popup from 'reactjs-popup';
import styled from 'styled-components';
import { CellText } from '@increasecard/typed-components';

const StyledPopup = styled(Popup)`
  &-content {
    background: var(--color-gray-black);
    color: var(--color-gray-white);
    width: max-content !important;
    max-width: 300px;
    padding: 6px 10px;
  }
  &-arrow {
    color: var(--color-gray-black);
  }
`;

export interface TooltipProps {
  trigger?: JSX.Element | ((isOpen: boolean) => JSX.Element);
  timeout?: number;
  label?: string;
  content?: string;
  onHide?: () => void;
  on?: ('focus' | 'click' | 'hover')[];
}

export function Tooltip({
  on = ['focus', 'hover'],
  content,
  label,
  timeout,
  onHide,
  trigger,
}: TooltipProps): JSX.Element {
  const popupRef = useRef<
    HTMLDivElement & { close: () => void; open: () => void; toggle: () => void }
  >(null);
  const [isOpen, setIsOpen] = useState(false);
  const closeTooltip = () => popupRef.current?.close();

  useTimeout(
    () => {
      closeTooltip();
      if (onHide) onHide();
    },
    timeout && isOpen ? timeout : null
  );

  return (
    <StyledPopup
      ref={popupRef}
      trigger={trigger}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      on={on}
      position="top center"
      closeOnDocumentClick
    >
      <CellText>{content || label}</CellText>
    </StyledPopup>
  );
}
