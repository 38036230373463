import { MailIcon } from '@increasecard/icons';
import {
  Button,
  H2,
  InputText,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeaderIconBox,
  Paragraph,
  Spinner,
} from '@increasecard/typed-components';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from '@increasecard/increase-core';
import { useSendNotificationSample } from '../../globalQueries/Queries';
import { ApiErrorMessage } from '../../components/ErrorMessage';

interface InfoModal {
  title: string;
  type: string;
}
export interface NotificationSampleModalProps {
  data: InfoModal;
  visible: boolean;
  onClose: () => void;
}

export function NotificationSampleModal({
  data,
  visible,
  onClose,
}: NotificationSampleModalProps): JSX.Element {
  const { t } = useTranslation();
  const user = useUser();
  const [infoModal, setInfo] = useState(user?.email);
  const { mutate, isError, isSuccess, reset, isLoading, error } =
    useSendNotificationSample();
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (isError) reset();
    setInfo(e.target.value);
  };
  const handleConfirmModal = () => {
    mutate({
      email: infoModal || '',
      type: data.type,
    });
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <Modal
      visible={visible}
      width="400"
      onClose={handleClose}
      onCancel={() => {
        return;
      }}
      onOk={() => {
        return;
      }}
    >
      <ModalHeaderIconBox
        colorPalette={'pay'}
        icon={<MailIcon color="white" height="48" width="48" />}
      />
      <ModalContent align="center">
        <H2>{t('screens.notifications.mail_sample_modal_title')}</H2>
        <Paragraph>{data?.title}</Paragraph>
        <br />
        {isSuccess ? (
          <Paragraph>Se ha enviado la notificacion correctamente.</Paragraph>
        ) : isLoading ? (
          <Spinner size={24} />
        ) : (
          <InputText
            value={infoModal}
            className="grid-span-desktop-2"
            label={'Email'}
            onChange={handleChange}
            required
          />
        )}
        {isError && <ApiErrorMessage error={error} />}
      </ModalContent>
      <ModalFooter align="center">
        {isSuccess ? (
          <Button buttonType={'invisible'} onClick={handleClose}>
            {t('common.close')}
          </Button>
        ) : (
          <>
            <Button buttonType={'invisible'} onClick={handleClose}>
              {t('common.cancel')}
            </Button>
            <Button disabled={isLoading} onClick={handleConfirmModal}>
              {t('common.send')}
            </Button>
          </>
        )}
      </ModalFooter>
    </Modal>
  );
}
