import { CellText, H1, Label, Paragraph } from '@increasecard/typed-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExtrasPickerButton } from '../../extras/ExtrasPickerButton';
import { SubscriptionItems } from '../SubscriptionItems';
import { PlanSelector } from '../../../components/FilterSelectors/PlanSelector';
import { ProductSelector } from '../../../components/FilterSelectors/ProductSelector';
import { FrontPlan } from '../../../types/Plan';
import { getSubtotal, getTotal } from '../../../utils/subscriptionUtils';
import { SubscriptionInput } from './SubscriptionInput';
import { PlanWithExtras } from '../SubscriptionForm';
import { Extra } from '../../../types/common';
import { ScreenSeparator } from '../../../components/common/ScreenSeparator';
import { Box } from '../../../components/common/Box';
import { IconButtonTooltip } from '../../../components/common/HelpTooltip';

type ConfigPayload = {
  name: 'amount' | 'quantity';
  value: number;
};

type PlanPayload = {
  name: 'plan';
  value: FrontPlan | undefined;
};

type ExtraPayload = {
  name: 'extra';
  value: Extra;
};

export type PlanConfigPayload = ConfigPayload | PlanPayload | ExtraPayload;

interface PlanSelectionProps {
  plan?: PlanWithExtras;
  subscriptionExtras: Extra[];
  product?: { id: string; name: string };
  amount: number;
  quantity?: number | null;
  isEditing: boolean;
  onChange: (payload: PlanConfigPayload) => void;
  invoicesGeneratedCount: number | undefined;
}

export function PlanSelection({
  isEditing,
  plan,
  product,
  amount,
  quantity,
  onChange,
  subscriptionExtras,
  invoicesGeneratedCount,
}: PlanSelectionProps): JSX.Element {
  const { t } = useTranslation();
  const [productId, setProductId] = useState(product?.id || '*');

  const subscriptionTotal = plan
    ? getTotal(
        plan,
        quantity || 1,
        amount,
        [...subscriptionExtras, ...plan.extras],
        invoicesGeneratedCount
      )
    : 0;

  return (
    <>
      <H1 style={{ marginTop: '20px' }}>{t('common.plan')}</H1>
      {isEditing ? (
        <div className="span-desktop-3">
          <Label>{t('common.product')}</Label>
          <Paragraph>{product?.name}</Paragraph>
        </div>
      ) : (
        <ProductSelector
          className="span-desktop-3"
          value={productId}
          onChange={({ value }) => {
            setProductId(value);
            // Reset plan
            onChange({ name: 'plan', value: undefined });
          }}
        />
      )}
      {isEditing ? (
        <div className="span-desktop-3">
          <Label>{t('common.plan')}</Label>
          <Paragraph>{plan?.name}</Paragraph>
        </div>
      ) : (
        <PlanSelector
          className="span-desktop-3"
          getFullItem
          onChange={(_, plan) => {
            if (plan) {
              setProductId(plan.product.id);
              onChange({ name: 'plan', value: plan });
            }
          }}
          value={plan?.id || ''}
          productId={productId}
          disabled={!productId || productId === '*' || !plan}
        />
      )}
      {plan && (
        <SubscriptionInput
          className="span-desktop-2"
          plan={plan}
          quantity={Number(quantity)}
          onQuantityChange={(value) => onChange({ name: 'quantity', value })}
          amount={amount}
          onAmountChange={(value) => onChange({ name: 'amount', value })}
        />
      )}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-end"
        className="span-desktop-2 grid-start-desktop-10"
      >
        <Label style={{ textAlign: 'center' }}>{t('common.total')}</Label>
        <Box
          as={CellText}
          display="flex"
          alignItems="center"
          justifyItems="flex-end"
          weight="bold"
          height="40px"
        >
          {plan ? getSubtotal(plan, Number(quantity), Number(amount)) : null}
        </Box>
      </Box>
      <div className="span-desktop-12"></div>
      <SubscriptionItems
        name="plan.extras"
        editable={false}
        isEditing={isEditing}
        title={
          <>
            {t('screens.new_subscription.extras_plan_title')}
            <IconButtonTooltip
              content="Los adicionales del plan no pueden ser modificados desde la suscripcion"
              iconType="help"
            />
          </>
        }
        invoicesGeneratedCount={invoicesGeneratedCount}
      />
      <div className="span-desktop-12"></div>
      <SubscriptionItems
        name="subscriptionExtras"
        isEditing={isEditing}
        editable={true}
        title={t('screens.new_subscription.extras_subscription_title')}
        invoicesGeneratedCount={invoicesGeneratedCount}
      />
      {plan && (
        <div className="span-desktop-1 grid-start-desktop-11">
          <ExtrasPickerButton
            onSelect={(value: Extra) => onChange({ name: 'extra', value })}
            country={plan?.country}
            currency={plan?.currency}
          />
        </div>
      )}
      <ScreenSeparator
        className="span-desktop-5 grid-start-desktop-7"
        style={{ margin: '10px 0 0 0' }}
      />
      <CellText className="span-desktop-3 grid-start-desktop-7" weight="bold">
        {`${t('common.total_to_bill')}`}
      </CellText>
      <CellText
        className="span-desktop-1 grid-start-desktop-11 grid-justify-end"
        weight="bold"
        style={{
          whiteSpace: 'nowrap',
        }}
      >
        {subscriptionTotal}
      </CellText>
    </>
  );
}
