import { useCallback, useEffect } from 'react';
import { CheckoutSubscription } from '../../types/Subscription';
import { CheckoutAccount } from '../../types/Account';
import { sendMessage } from '../../utils/utils';
import {
  useAccountById,
  usePaySubscription,
  useSubscriptionById,
} from './checkoutQueries';
import { NewApiError } from '../../types/NewApiError';
import { useCheckoutParams } from './useCheckoutParams';

export const EVENTS = {
  LOAD_SUCCESS: 'LOAD_SUCCESS',
  LOAD_ERROR: 'LOAD_ERROR',
  PAYMENT_SUCCESS: 'PAYMENT_SUCCESS',
  PAYMENT_ERROR: 'PAYMENT_ERROR',
  PAYMENT_IN_PROCESS: 'PAYMENT_IN_PROCESS',
};
export interface UseCheckoutLite {
  paymentError: NewApiError | null;
  subscription: CheckoutSubscription;
  account: CheckoutAccount | undefined;
  isLoading: boolean;
  isPaying: boolean;
  paySubscription: (paymentData: unknown) => void;
}

export function useCheckoutLite(): UseCheckoutLite {
  const { subscriptionId, accountId } = useCheckoutParams();
  const account = useAccountById(accountId);
  const subscription = useSubscriptionById(subscriptionId);
  const payment = usePaySubscription();

  // TODO: use queries callbacks to dispatch these events.
  useEffect(() => {
    if (account.error || subscription.error) {
      return sendMessage(EVENTS.LOAD_ERROR, account.error);
    }
    if (account.data || subscription.data) {
      return sendMessage(EVENTS.LOAD_SUCCESS);
    }
  }, [account.error, account.data, subscription.data, subscription.error]);

  const paySubscription = useCallback(
    (data) => {
      if (subscription.data) {
        sendMessage(EVENTS.PAYMENT_IN_PROCESS, {});
        payment.mutate(
          {
            subscriptionId,
            customerId: subscription.data.customer.id,
            paymentData: data,
          },
          {
            onSuccess: (data) =>
              sendMessage(EVENTS.PAYMENT_SUCCESS, data.subscriptionPaid),
            onError: (error) => sendMessage(EVENTS.PAYMENT_ERROR, error),
          }
        );
      }
    },
    [payment, subscription.data, subscriptionId]
  );

  return {
    paymentError: payment.error,
    subscription: subscription.data as CheckoutSubscription,
    account: account.data,
    isLoading: subscription.isLoading || account.isLoading,
    isPaying: payment.isLoading,
    paySubscription,
  };
}
