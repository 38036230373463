import { PaginatedData, PaginationConfig } from '../types/common';
import { apiFront } from './api';
import { Discount } from '../types/Discount';
import { extrasFromApi } from '../utils/extrasUtils';
import { Tax } from '../types/Tax';
import { OneTimeCost } from '../types/OneTimeCost';

export async function fetchDiscounts(
  params: PaginationConfig & { is_positive_amount: boolean }
): Promise<PaginatedData<Discount>> {
  const response = await apiFront.get('discounts', {
    params,
  });
  const extraType = params.is_positive_amount ? 'additional_cost' : 'discount';
  response.data.data = extrasFromApi(response.data.data, extraType);
  return response.data;
}

export async function fetchDiscountById(id: string): Promise<Discount> {
  const response = await apiFront.get(`discounts/${id}`);
  // const extraType = params.is_positive_amount ? 'additional_cost' : 'discount';
  // response.data.data = extrasFromApi(response.data.data, extraType);
  return response.data;
}

export async function createDiscount(params: Metadata): Promise<Discount> {
  const response = await apiFront.post('discounts', params);
  return response.data;
}

export async function updateDiscount(params: Metadata): Promise<Discount> {
  const response = await apiFront.patch(`discounts/${params.id}`, params);
  return response.data;
}

export async function fetchTaxes(
  params: PaginationConfig
): Promise<PaginatedData<Tax>> {
  const response = await apiFront.get('taxes', {
    params,
  });
  response.data.data = extrasFromApi(response.data.data, 'tax');
  return response.data;
}

export async function fetchTaxById(id: string): Promise<Tax> {
  const response = await apiFront.get(`taxes/${id}`);
  // const extraType = params.is_positive_amount ? 'additional_cost' : 'discount';
  // response.data.data = extrasFromApi(response.data.data, extraType);
  return response.data;
}

export async function createTax(params: Metadata): Promise<Tax> {
  const response = await apiFront.post('taxes', params);
  return response.data;
}

export async function updateTax(params: Metadata): Promise<Tax> {
  const response = await apiFront.patch(`taxes/${params.id}`, params);
  return response.data;
}

export async function fetchOneTimeCosts(
  params: PaginationConfig
): Promise<PaginatedData<OneTimeCost>> {
  const response = await apiFront.get('one_time_costs', {
    params,
  });
  response.data.data = extrasFromApi(response.data.data, 'one_time_cost');
  return response.data;
}

export async function fetchOneTimeCostById(id: string): Promise<OneTimeCost> {
  const response = await apiFront.get(`one_time_costs/${id}`);
  // const extraType = params.is_positive_amount ? 'additional_cost' : 'discount';
  // response.data.data = extrasFromApi(response.data.data, extraType);
  return response.data;
}

export async function createOneTimeCost(
  params: Metadata
): Promise<OneTimeCost> {
  const response = await apiFront.post('one_time_costs', params);
  return response.data;
}

export async function updateOneTimeCost(
  params: Metadata
): Promise<OneTimeCost> {
  const response = await apiFront.patch(`one_time_costs/${params.id}`, params);
  return response.data;
}
