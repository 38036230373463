import { PencilIcon } from '@increasecard/icons';
import { Button, Grid, H1, H2 } from '@increasecard/typed-components';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { GoBackButton } from '../../components/common/GoBackButton';
import { RoundedCard } from '../../components/common/RoundedCard';
import { RouterLink } from '../../components/common/RouterLink';
import { Row } from '../../components/common/Row';
import { ScreenSeparator } from '../../components/common/ScreenSeparator';
import { useCustomerById } from '../../globalQueries/Queries';
import { formatCurrency } from '../../utils/currencyUtils';
import { SubscriptionsList } from '../subscription/SubscriptionsList';
import { CustomerActionsMenu } from './components/CustomerActionsMenu';
import { CustomerPaymentsMethodsList } from './components/CustomerPaymentsMethodsList';
import { BillingInfoDetail } from './BillingInfoDetail';
import { IconButtonTooltip } from '../../components/common/HelpTooltip';
import { InvoiceTable } from '../invoice/InvoiceTable';
import { Constants } from '../../constants';
import { FullPageSpinner } from '../../components/common/FullPageSpinner';
import { useUIEventTracker } from '../../hooks/useUIEventTracker';
import { DetailHeader } from '../../components/common/DetailHeader';

export function CustomerDetailScreen(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const { customer_id } = useParams<{ customer_id: string }>();
  const { isLoading, data: customer } = useCustomerById(customer_id);
  const trackEvent = useUIEventTracker('detail button');

  if (isLoading || !customer) {
    return <FullPageSpinner size={24} />;
  }

  const trackEditEvent = () => {
    trackEvent('edit_customer');
  };

  const hasDebt = !!Number(customer.total_uncollectible_amount);

  const debtInvoiceButton = (
    <Button
      buttonType="invisible"
      disabled={!hasDebt}
      onClick={() => history.push(`/customers/${customer.id}/new-debt-invoice`)}
    >
      {t('common.manage_debt')}
    </Button>
  );

  return (
    <Grid rowGap={3}>
      <Row margin="0" justifyContent="space-between">
        <GoBackButton />
        <Row margin="0" gap="1rem" width="auto">
          <RouterLink
            to={`/customers/${customer_id}/edit`}
            icon={<PencilIcon />}
            onClick={trackEditEvent}
          >
            {t('common.edit')}
          </RouterLink>
          <CustomerActionsMenu text={t('common.actions')} customer={customer} />
        </Row>
      </Row>
      <DetailHeader
        title={`Cliente: ${customer.first_name} ${customer.last_name}`}
        subTitle={customer.email}
      />
      <RoundedCard
        className="grid-span-desktop-5 grid-span-mobile-12"
        variant="green"
        centered
        gap="1.5rem"
      >
        <H2 weight="400">
          {t('data.customer.total_paid_amount')}
          <IconButtonTooltip
            content={t('screens.customers.detail_help_modal_description')}
            timeout={1000}
          />
        </H2>
        <H1>
          {formatCurrency(
            Number(customer.total_paid_amount ?? 0),
            customer.billing_info.currency
          )}
        </H1>
        <ScreenSeparator margin="0" />
        <H2 weight="400">{t('data.customer.total_uncollectible_amount')}</H2>
        <H1>
          {formatCurrency(
            customer.total_uncollectible_amount ?? 0,
            customer.billing_info.currency
          )}
        </H1>
      </RoundedCard>
      <BillingInfoDetail customer={customer} />
      <CustomerPaymentsMethodsList customer={customer} />
      <SubscriptionsList customer_id={customer.id} />
      <InvoiceTable
        filters={{
          customer_id: customer.id,
          per_page: Constants.SMALL_LIST_PAGE_SIZE,
        }}
        tableHeaderButtons={debtInvoiceButton}
        header={t('common.invoices')}
        context="customer detail"
      />
    </Grid>
  );
}
