import { useTranslation } from 'react-i18next';
import { H2, Paragraph, Grid } from '@increasecard/typed-components';
import { ApiErrorMessage } from '../../../components/ErrorMessage';
import { TaxFormValues, TaxForm } from './TaxForm';
import { Tax } from '../../../types/Tax';
import { useUpdateTax } from './taxQueries';

export interface EditTaxScreenProps {
  onClose: () => void;
  tax: Tax;
}

export function EditTaxScreen({
  onClose,
  tax,
}: EditTaxScreenProps): JSX.Element {
  const { t } = useTranslation();
  const { isLoading, error, isSuccess, mutate } = useUpdateTax();

  const handleSubmit = (taxValues: TaxFormValues) => {
    mutate({ id: tax.id, ...taxValues });
  };

  return (
    <Grid rowGap={2}>
      <H2>{t('common.edit_title', { entity: t('common.tax') })}</H2>
      {isSuccess ? (
        <Paragraph>
          {t('common.success_edit', { entity: t('common.tax') })}
        </Paragraph>
      ) : (
        <TaxForm
          initialValues={tax}
          onCancel={onClose}
          onSubmit={handleSubmit}
          editMode={true}
          isLoading={isLoading}
        />
      )}
      <ApiErrorMessage error={error} />
    </Grid>
  );
}
