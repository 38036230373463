import { ChangeEvent, useState } from 'react';
import { useFormik, FormikProvider } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Grid,
  H2,
  InputText,
  NativeSelect,
} from '@increasecard/typed-components';
import {
  CustomerSchema,
  CustomerFormValues as CustomerSchemaType,
} from '../../schemas/CustomerSchema';
import { InputTextField } from '../../components/common/FormikFields';
import { ScreenSeparator } from '../../components/common/ScreenSeparator';
import { InputWithDescription } from '../../components/common/InputWithDescription';
import { CustomerPaymentMethods } from '../../components/paymentMethods/CustomerPaymentMethods';
import { Row } from '../../components/common/Row';
import { RouteLeavingModal } from '../../components/common/RouteLeavingModal';
import { useCountryStates } from '../../hooks/useCountryStates';
import { PaymentMethod } from '../../types/PaymentMethod';
import { useModal } from '../../hooks/useModal';
import { DeletePaymentMethodModal } from './components/DeletePaymentMethodModal';
import { IconButtonTooltip } from '../../components/common/HelpTooltip';
import { CountrySelect } from '../../components/common/CountrySelect';
import { LoadingButton } from '../../components/common/LoadingButton';
import { NewApiError } from '../../types';
import { ApiErrorMessage } from '../../components/ErrorMessage';

export interface CustomerFormValues extends CustomerSchemaType {
  id: string;
  external_id?: string;
  payment_methods: PaymentMethod[];
}

export interface CustomerFormProps {
  initialValue?: CustomerFormValues;
  onSubmit: (customer: CustomerFormValues) => void;
  onCancel: () => void;
  submitText: string;
  isSubmitting: boolean;
  error: NewApiError | null;
}

const INITIAL_VALUES: CustomerFormValues = {
  id: '',
  email: '',
  first_name: '',
  last_name: '',
  external_id: '',
  tax_id: '',
  billing_info: {
    address_line_1: '',
    address_line_2: '',
    city: '',
    state: '',
    state_iso_code: '',
    zip_code: '',
    phone: '',
    country: '',
  },
  payment_methods: [],
};

export function CustomerForm({
  initialValue = INITIAL_VALUES,
  onSubmit,
  onCancel,
  submitText,
  isSubmitting,
  error,
}: CustomerFormProps): JSX.Element {
  const { t } = useTranslation();
  const [validateTaxId, setValidateTaxId] = useState(!initialValue.tax_id);

  const deleteModal = useModal();

  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: onSubmit,
    validationSchema: CustomerSchema(t, validateTaxId),
  });

  const handleDelete = (paymentMethod: PaymentMethod) => {
    deleteModal.open(paymentMethod);
  };

  const deletePaymentMethod = () => {
    // Remove the payment method when success because re fetching
    // the customer could erase other changes
    const newPaymentMethods = formik.values.payment_methods.filter(
      (pm) => pm.id !== deleteModal.data.id
    );
    formik.setFieldValue('payment_methods', newPaymentMethods, false);
    deleteModal.close();
  };

  const handleStateIsoCodeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.options) {
      formik.setFieldValue(
        'billing_info.state_iso_code',
        e.target.options[e.target.selectedIndex].value
      );
      formik.setFieldValue(
        'billing_info.state',
        e.target.options[e.target.selectedIndex].getAttribute('data-state')
      );
    } else {
      formik.setFieldValue('billing_info.state', e.target.value);
    }
  };

  const handleCountryChange = (e: ChangeEvent<HTMLSelectElement>) => {
    formik.handleChange(e);
    formik.setFieldValue('billing_info.state', '');
    formik.setFieldValue('billing_info.state_iso_code', '');
  };

  const handleTaxIdChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setValidateTaxId(true);
    formik.handleChange(e);
  };

  const { values, errors, handleChange, handleSubmit, dirty, submitCount } =
    formik;
  const { countryStates } = useCountryStates(
    formik.values.billing_info.country
  );
  const haveCountryStates = countryStates.length > 0;

  return (
    <FormikProvider value={formik}>
      <Grid as="form" rowGap={2} onSubmit={handleSubmit}>
        <H2 className="grid-span-desktop-3">
          {t('screens.customer_form.user_data')}
        </H2>
        <InputTextField
          className="grid-span-desktop-2"
          id="email"
          label={t('data.customer.email')}
          type="email"
          required
        />
        <InputTextField
          className="grid-span-desktop-2"
          label={t('data.customer.first_name')}
          id="first_name"
          required
        />
        <InputTextField
          className="grid-span-desktop-2"
          label={t('data.customer.last_name')}
          id="last_name"
          required
        />
        <CountrySelect
          id="billing_info.country"
          className="grid-span-desktop-2  grid-start-desktop-6"
          onChange={handleCountryChange}
          defaultValue={values.billing_info.country}
          disabled={values.payment_methods.length !== 0}
          required
        />
        <InputText
          className="grid-span-desktop-2 grid-start-desktop-8"
          label={t('common.tax_or_id')}
          onChange={handleTaxIdChange}
          onBlur={formik.handleBlur}
          value={formik.values.tax_id}
          errorMessage={formik.touched.tax_id && formik.errors.tax_id}
          id="tax_id"
          required
        />
        <ScreenSeparator margin="2.5rem 0 0 0" />
        <InputWithDescription
          title={t('common.external_id')}
          className="grid-span-desktop-3 grid-start-desktop-4"
          value={values.external_id}
          errorMessage={errors.external_id}
          onChange={handleChange}
          description={t('screens.customer_form.reference_id_desc')}
        />
        <ScreenSeparator margin="2.5rem 0 0 0" />
        <H2 className="grid-span-desktop-3">
          {t('screens.customer_form.invoice_data')}
        </H2>
        <InputTextField
          className="grid-span-desktop-3"
          id="billing_info.address_line_1"
          label={t('data.billing.address_line_1')}
          required
        />
        {haveCountryStates ? (
          <NativeSelect
            id="billing_info.state_iso_code"
            name="billing_info.state_iso_code"
            className="grid-span-desktop-3 grid-start-desktop-7"
            onChange={handleStateIsoCodeChange}
            value={values.billing_info.state_iso_code || ''}
            label={[
              t('data.billing.iso_state'),
              <IconButtonTooltip
                key={1}
                content={t('data.billing.iso_state_tooltip_content')}
              />,
            ]}
            required
          >
            <option key="first" value="">
              {t('data.billing.state_placeholder')}
            </option>
            {countryStates.map((opt, i) => (
              <option key={i} value={opt.iso_code} data-state={opt.name}>
                {opt.iso_code} ({opt.name})
              </option>
            ))}
          </NativeSelect>
        ) : null}
        <InputTextField
          className={
            haveCountryStates
              ? 'grid-span-desktop-3 grid-start-desktop-4'
              : 'grid-span-desktop-3 grid-start-desktop-7'
          }
          id="billing_info.state"
          label={t('data.billing.state')}
          onChange={handleStateIsoCodeChange}
          required
        />
        <InputTextField
          className={
            haveCountryStates
              ? 'grid-span-desktop-3 grid-start-desktop-7'
              : 'grid-span-desktop-3 grid-start-desktop-4'
          }
          id="billing_info.city"
          label={t('data.billing.city')}
          required
        />
        <InputTextField
          className={
            haveCountryStates
              ? 'grid-span-desktop-1 grid-start-desktop-4'
              : 'grid-span-desktop-1 grid-start-desktop-7'
          }
          id="billing_info.zip_code"
          label={t('data.billing.zip_code')}
          required
        />
        <InputTextField
          className={
            haveCountryStates
              ? 'grid-span-desktop-2 '
              : 'grid-span-desktop-2 grid-start-desktop-8'
          }
          id="billing_info.phone"
          label={t('data.billing.phone')}
          required
        />
        <ScreenSeparator margin="2.5rem 0 0 0" />
        {values.id && (
          <>
            <H2 className="grid-span-desktop-12">
              {t('common.payment_methods')}
            </H2>
            <CustomerPaymentMethods
              customerId={values.id}
              onDelete={handleDelete}
            />
          </>
        )}
        <ApiErrorMessage error={error} />
        <Row marginTop="4rem" gap="1rem">
          <LoadingButton type="submit" isLoading={isSubmitting}>
            {submitText}
          </LoadingButton>
          <Button
            className="grid-span-desktop-1"
            buttonType="invisible"
            type="button"
            onClick={onCancel}
          >
            {t('common.cancel')}
          </Button>
        </Row>
      </Grid>
      <DeletePaymentMethodModal
        customerId={formik.values.id}
        visible={deleteModal.isOpen}
        paymentMethod={deleteModal.data}
        onCancel={deleteModal.close}
        onDelete={deletePaymentMethod}
      />
      <RouteLeavingModal
        active={dirty && submitCount === 0}
        headerText={t('screens.new_subscription.leaving_title')}
        description={t('screens.new_subscription.leaving_description')}
        cancelLabel={t('screens.new_subscription.leaving_cancel')}
        okLabel={t('screens.new_subscription.leaving_continue')}
      />
    </FormikProvider>
  );
}
