import { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import { InputSearch, CellText } from '@increasecard/typed-components';
import { Box } from './Box';
import { Customer } from '../../types/Customer';

const DropdownWrapper = styled.div`
  position: absolute;
  top: 42px;
  border-radius: 4px;
  left: 0;
  width: 100%;
  background: white;
  z-index: 100;
  overflow-y: overlay;
  max-height: 253px;
  box-shadow: ${({ theme }) => theme.shadows.elevateCast};
  border: 1px solid var(--color-gray-grayMedium);
`;

const DropdownOption = styled(CellText)`
  padding: 10px 2rem;
  cursor: pointer;
  &:hover {
    background: var(--color-increase-light);
  }
`;

export interface InputSearchDropdownProps {
  placeholder: string;
  options: Customer[] | undefined;
  getOptionName: (nm: Metadata) => string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onSelect: (nm: Metadata) => void;
}

export function InputSearchDropdown({
  placeholder = '',
  options = [],
  getOptionName,
  onChange,
  onSelect,
}: InputSearchDropdownProps): JSX.Element {
  const [showDropdown, setShowDropdown] = useState(false);

  const onInputSearchFocus = () => setShowDropdown(true);
  const onInputSearchBlur = () => setShowDropdown(false);

  return (
    <Box marginBottom="8rem" width="335px" position="relative">
      <InputSearch
        defaultValue=""
        width="100%"
        placeholder={placeholder}
        onChange={onChange}
        onFocus={onInputSearchFocus}
        onBlur={onInputSearchBlur}
        autoComplete="off"
        labelHidden
      />
      {showDropdown && options.length ? (
        <DropdownWrapper data-testid="customer-search-dropdown">
          {options.map((option) => (
            <DropdownOption
              key={option.id}
              onMouseDown={() => onSelect(option)}
            >
              {getOptionName(option)}
            </DropdownOption>
          ))}
        </DropdownWrapper>
      ) : null}
    </Box>
  );
}
