import { TFunction } from 'i18next';
import * as Yup from 'yup';
import {
  RequiredString,
  OnlyNumbersRequired,
  CUIT,
  Beneficiary,
  SupervielleCompanyCode,
  ComafiCompanyCode,
  AccountNumber,
} from '../../schemas/common';

export const DlocalSchema = (): Yup.SchemaOf<
  | {
      metadata?: {
        x_login?: string;
        x_trans_key?: string;
        secret?: string;
        api_key?: string;
      };
    }
  | undefined
> => {
  return Yup.object({
    metadata: Yup.object({
      x_login: RequiredString(),
      x_trans_key: RequiredString(),
      secret: RequiredString(),
      api_key: RequiredString(),
    }),
  });
};

export const DecidirSchema = (): Yup.SchemaOf<
  | {
      metadata?: {
        site_id?: string;
        site_id_cvv?: string;
        public_api_key?: string;
        public_api_key_cvv?: string;
        private_api_key?: string;
        private_api_key_cvv?: string;
      };
    }
  | undefined
> => {
  return Yup.object({
    metadata: Yup.object({
      site_id: RequiredString(),
      site_id_cvv: RequiredString(),
      public_api_key: RequiredString(),
      public_api_key_cvv: RequiredString(),
      private_api_key: RequiredString(),
      private_api_key_cvv: RequiredString(),
    }),
  });
};

export const PayuSchema = (
  translations: TFunction
): Yup.SchemaOf<
  | {
      country?: string;
      metadata?: {
        provider_access?: {
          merchant_id?: string;
          account_id?: string;
          api_login?: string;
          api_key?: string;
        };
      };
    }
  | undefined
> => {
  return Yup.object({
    country: RequiredString().matches(
      /[A-Z]/,
      translations('validation.must_select', {
        entity: translations('common.country'),
      })
    ),
    metadata: Yup.object({
      provider_access: Yup.object({
        merchant_id: RequiredString(),
        account_id: RequiredString(),
        api_login: RequiredString(),
        api_key: RequiredString(),
      }),
    }),
  });
};

export const ComafiSchema = (
  translations: TFunction
): Yup.SchemaOf<
  | {
      metadata?: {
        cuit?: string;
        id_size?: string;
        company_code?: string;
        beneficiary?: string;
        account_number?: string;
      };
    }
  | undefined
> => {
  return Yup.object({
    metadata: Yup.object({
      cuit: CUIT(translations),
      id_size: OnlyNumbersRequired(translations),
      beneficiary: Beneficiary(),
      account_number: AccountNumber(translations),
      company_code: ComafiCompanyCode(),
    }),
  });
};

export const SupervielleSchema = (
  translations: TFunction
): Yup.SchemaOf<
  | {
      metadata?: {
        cuit?: string;
        id_size?: string;
        company_code?: string;
      };
    }
  | undefined
> => {
  return Yup.object({
    metadata: Yup.object({
      cuit: CUIT(translations),
      id_size: OnlyNumbersRequired(translations),
      company_code: SupervielleCompanyCode(),
    }),
  });
};

export const MercadopagoSchema = (
  translations: TFunction
): Yup.SchemaOf<
  | {
      metadata?: {
        offline_payments_enabled?: boolean;
        expiration_days?: number;
      };
    }
  | undefined
> => {
  return Yup.object({
    name: Yup.string(),
    country: RequiredString().matches(
      /[A-Z]/,
      translations('validation.must_select', {
        entity: translations('common.country'),
      })
    ),
    metadata: Yup.object({
      offline_payments_enabled: Yup.bool(),
      expiration_days: Yup.number().when('offline_payments_enabled', {
        is: true,
        then: Yup.number()
          .min(
            3,
            translations('validation.greater_than_or_equal_to', { count: 3 })
          )
          .max(
            30,
            translations('validation.less_than_or_equal_to', { count: 30 })
          ),
      }),
    }),
  });
};
