import {
  Button,
  H2,
  InputText,
  Paragraph,
  Switch,
} from '@increasecard/typed-components';
import { FieldArray, useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { Column } from '../../../components/common/Column';
import { CopyButton } from '../../../components/common/CopyButton';
import { DeleteButton } from '../../../components/common/DeleteButton';
import {
  InputTextField,
  SelectField,
} from '../../../components/common/FormikFields';
import { Row } from '../../../components/common/Row';
import { ErrorMessage } from '../../../components/ErrorMessage';
import config from '../../../config';
import {
  CardCollectMethod,
  GroupedCollectMethods,
  SearchableFieldConfig,
} from '../../../types';
import { CollectMethodSelector } from '../../../components/common/CollectMethodSelector';
import { useCollectMethods } from '../../../globalQueries/Queries';

export interface CollectionPortalConfigProps {
  accountId: string;
}

const FIELDS = [
  {
    value: 'invoice_external_id',
    name: 'Identificador de referencia de factura',
  },
  {
    value: 'customer_external_id',
    name: 'Identificador de referencia de cliente',
  },
  { value: 'customer_email', name: 'Correo electrónico del cliente' },
  { value: 'customer_tax_id', name: 'Documento de identidad del cliente' },
];

export function CollectionPortalConfig({
  accountId,
}: CollectionPortalConfigProps): JSX.Element {
  const [, sfMeta, sfHelpers] = useField('searchable_fields');
  const [, cmMeta, cmHelpers] = useField('collect_method_id');
  const portalUrl = `${config.urls.pay}/selfservice-portal/${accountId}`;
  const { t } = useTranslation(undefined, { keyPrefix: 'screens.business' });
  const collectMethods = useCollectMethods({ status: 'enabled' });

  const portalCM = {
    card:
      (collectMethods.data?.filter(
        (cm) => cm.id === cmMeta.value
      ) as CardCollectMethod[]) || [],
    cbu: [],
    ticket: [],
  };
  const getAvailableField = () => {
    const usedFields = sfMeta.value.map(
      ({ field }: SearchableFieldConfig) => field
    );
    const field = FIELDS.find(
      ({ value }) => !usedFields.includes(value)
    )?.value;
    return {
      field,
      label: 'Nombre para mostrar',
    };
  };

  const togglePortalConfig = () => {
    if (sfMeta.value.length === 0) {
      sfHelpers.setValue([getAvailableField()]);
    } else {
      sfHelpers.setValue([]);
    }
  };

  const handleCMChange = (_: string, cm: GroupedCollectMethods) => {
    cmHelpers.setValue(cm.card[0].id);
  };

  const disabled = sfMeta.value.length === 0;

  return (
    <>
      <H2>{t('collection_portal')}</H2>
      <Paragraph>{t('collection_portal_desc')}</Paragraph>
      <Column flexGap="1rem" maxWidth="686px">
        <Switch
          id="portal-switch"
          label="Habilitado"
          checked={sfMeta.value.length > 0}
          onChange={togglePortalConfig}
        />
        <Row gap="8px" alignItems="end">
          <InputText
            label="url del portal"
            value={disabled ? '' : portalUrl}
            readonly
            disabled={disabled}
          />
          <CopyButton value={portalUrl} buttonProps={{ disabled }} />
        </Row>
      </Column>
      <H2>{t('collection_fields')}</H2>
      <Paragraph>{t('collection_fields_desc')}</Paragraph>
      <Column gap="8px" margin="32px 0" maxWidth="686px">
        <FieldArray name="searchable_fields">
          {({ remove, push }) => {
            return (
              <>
                {sfMeta.value.map((f: SearchableFieldConfig, index: number) => (
                  <Row gap="8px" margin="0" alignItems="end" key={index}>
                    <SelectField
                      id={`searchable_fields.${index}.field`}
                      options={FIELDS}
                      label={index === 0 && 'Campo de busqueda'}
                      size="small"
                    />
                    <InputTextField
                      id={`searchable_fields.${index}.label`}
                      label={index === 0 && 'url del portal'}
                      size="small"
                    />
                    <DeleteButton onClick={() => remove(index)} />
                  </Row>
                ))}
                {sfMeta.error && <ErrorMessage>{sfMeta.error}</ErrorMessage>}
                <Button
                  variant="invisible"
                  type="button"
                  onClick={() =>
                    push({
                      field: getAvailableField(),
                      label: 'Nombre para mostrar',
                    })
                  }
                  disabled={disabled || sfMeta.value.length === 4}
                >
                  Agregar
                </Button>
              </>
            );
          }}
        </FieldArray>
      </Column>
      <CollectMethodSelector
        onChange={handleCMChange}
        collectMethods={portalCM}
        description="Este será el método de cobro que se utilizará para todos los cobros realizados a través del Portal de cobranzas"
        selectorsEnabled={['card']}
        disabled={disabled}
      />
    </>
  );
}
