import { useState, useEffect, MouseEvent } from 'react';
import styled, { css } from 'styled-components';
import { DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';
import { CellText, InputLabel, Link } from '@increasecard/typed-components';
import { Trans, useTranslation } from 'react-i18next';
import { CloseIcon } from '@increasecard/icons';
import { Column } from './Column';

const dragActiveStyle = css<{ hasImage: boolean }>`
  border-color: var(--color-primaryAction-regular);
  ${({ theme, hasImage }) =>
    hasImage &&
    `
      border: 2px solid ${theme.colorsNew.gray.blackLight};
    `}
  .first-line-message {
    color: var(--color-primaryAction-regular);
  }
`;

interface WrapperProps {
  width: string;
  height: string;
  hasError: boolean;
  hasImage: boolean;
  isDragActive: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  width: ${({ width }) => width || 'auto'};
  height: ${({ height }) => height || 'auto'};
  position: relative;
  transition: border-color 200ms ease-out;
  border: 2px dashed
    ${({ theme, hasError }) =>
      hasError
        ? theme.colorsNew.coralAlert.dark
        : theme.colorsNew.gray.grayMedium};
  border-radius: 4px;
  margin-top: 6px;
  padding: 0.5rem;
  outline: none;
  cursor: pointer;
  ${({ hasImage }) =>
    hasImage &&
    `
    display: flex;
    align-items: center;
    justify-content: center;
  `}
  ${({ isDragActive }) => isDragActive && dragActiveStyle}
  .first-line-message {
    transition: all 200ms ease-out;
    color: var(--color-gray-grayBold);
  }
  .second-line-message {
    color: var(--color-gray-grayBold);
  }
  .clear-button {
    position: absolute;
    top: -12px;
    right: -12px;
  }
`;

const ErrorMessage = styled(CellText)`
  color: var(--color-coralAlert-dark);
  padding-left: 1rem;
`;

const ImageCanvas = styled.img<{ size: ImageSize }>`
  max-width: ${({ size }) => size.width}px;
  max-height: ${({ size }) => size.height}px;
  margin: 0 auto;
`;

const IconButton = styled.button`
  background-color: #ffffff;
  border: 1px solid var(--color-gray-grayMedium);
  border-radius: 100%;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    background-color: var(--color-pay-light);
    border-color: var(--color-pay-light70);
  }
  :active {
    background-color: var(--color-pay-light70);
  }
`;

function readFile(file: File): Promise<string> {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = (evt) => resolve((evt?.target?.result as string) || '');
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

type ImageSize = {
  width: number;
  height: number;
};

export interface ImageDropZoneProps {
  accept?: DropzoneOptions['accept'];
  label: string;
  errorMessage?: string;
  width: string;
  height: string;
  imageSize: ImageSize;
  imageFile: File | undefined;
  imageUrl: string | undefined;
  onDropAccepted?: (f: File) => void;
  onDropRejected?: (f: FileRejection) => void;
  clearImage: () => void;
}

export function ImageDropZone({
  accept = ['.jpg', 'jpeg', '.png'],
  label,
  width,
  height,
  imageSize,
  errorMessage,
  imageUrl,
  imageFile,
  clearImage,
  onDropAccepted,
  onDropRejected,
  ...rest
}: ImageDropZoneProps): JSX.Element {
  const [imageData, setImageData] = useState('');
  const { t } = useTranslation();
  useEffect(() => {
    if (imageFile) {
      readFile(imageFile).then((data) => setImageData(data));
    } else {
      setImageData('');
    }
  }, [imageFile]);

  const handleDropAccepted = (file: File[]) => {
    onDropAccepted && onDropAccepted(file[0]);
  };

  const handleDropRejected = (rejection: FileRejection[]) => {
    onDropRejected && onDropRejected(rejection[0]);
  };

  const handleClearImage = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    clearImage();
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted: handleDropAccepted,
    onDropRejected: handleDropRejected,
    accept,
    noClick: false,
    multiple: false,
  });

  const hasImage = !!(imageData || imageUrl);
  return (
    <div {...rest}>
      <InputLabel>{label}</InputLabel>
      <Wrapper
        {...getRootProps({ isDragActive })}
        isDragActive={isDragActive}
        hasError={!!errorMessage}
        hasImage={hasImage}
        width={width}
        height={height}
      >
        <input {...getInputProps()} />
        {hasImage ? (
          <>
            <IconButton className="clear-button" onClick={handleClearImage}>
              <CloseIcon />
            </IconButton>
            <ImageCanvas src={imageData || imageUrl} size={imageSize} alt="" />
          </>
        ) : (
          <Column
            justifyContent="space-between"
            marginTop="0"
            marginBottom="0"
            h="100%"
          >
            <div>
              <Trans
                t={t}
                i18nKey="screens.business.drag_files_message"
                components={[
                  <CellText inline key={0} className="first-line-message" />,
                  <Link style={{ fontSize: '13px' }} key={1} />,
                  <CellText inline key={2} className="first-line-message" />,
                ]}
              />
            </div>
            <CellText inline key={0} className="first-line-message">
              {t('screens.business.suggested_size', {
                size: Object.values(imageSize).join('x'),
              })}
            </CellText>
          </Column>
        )}
      </Wrapper>
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </div>
  );
}
