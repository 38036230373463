import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Constants } from '../../constants';
import { useProducts } from '../../features/plan/productQueries';
import useDebounce from '../../hooks/useDebounce';
import { NameValue } from '../../types/common';
import { Product } from '../../types/Product';
import { objectsList2NameValueList } from '../../utils/utils';
import { Selector } from '../common/Selectors';
import { SelectorProps } from './types';

export function ProductSelector({
  onChange,
  value,
  ...rest
}: SelectorProps<Product>): JSX.Element {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 300);
  const { data, isLoading } = useProducts(
    {
      page: 1,
      per_page: Constants.MEDIUM_PAGE_SIZE,
      search: debouncedSearch,
    },
    {
      select: (data) => {
        return objectsList2NameValueList(data.data, 'common.all_products');
      },
    }
  );
  const handleChange = (_: string, nameValue: NameValue) => {
    onChange(nameValue);
  };

  return (
    <Selector
      {...rest}
      id="product_id"
      disabled={isLoading}
      onChange={handleChange}
      onSearch={setSearch}
      options={data || []}
      value={value}
      label={t('common.product')}
    />
  );
}
