import { useState, useCallback, useReducer, useEffect } from 'react';
import { list2NameValue } from '../../../utils/utils';
import { useWithCountryCurrency } from '../../../utils/countryUtils';
import { PLAN_TYPES } from '../../../utils/planUtils';
import { getCountryConfig } from '../../../utils/countryUtils';
import { splitExtrasByType } from '../../../utils/extrasUtils';
import { hasCollectMethods } from '../../../utils/collectMethodsUtils';
import { useDefaultCollectMethods } from '../../../hooks/useCollectMethodsOptions';
//import { usePartners } from '../../../globalQueries/Queries';

const CYCLE_UNITS = ['daily', 'weekly', 'monthly', 'yearly'];

const NEW_PLAN = {
  name: '',
  charge_after_period: false,
  type: PLAN_TYPES.FLAT,
  cycle_unit: CYCLE_UNITS[2],
  cycle_amount: 1,
  amount: 1,
  tiers: [],
  trial_period_days: 0,
  automatic_subscription_request_approval: true,
  external_id: '',
  success_url: '',
  start_day: null,
  start_month: null,
  start_hour: null,
  charge_proportional: null,
  taxes: [],
  discounts: [],
  one_time_costs: [],
  collect_methods: {
    card: [],
    cbu: [],
    ticket: [],
  },
  active_until_all_invoices_paid: false,
  allow_multiple_subscriptions_per_customer: false,
};

const DEFAULT_TIERS = [
  {
    minimum: 1,
    maximum: 10,
    amount: 1,
    amount_type: 'per_unit',
  },
  {
    minimum: 11,
    amount: 1,
    amount_type: 'per_unit',
  },
];

const typeHasTiers = (type) => {
  return [PLAN_TYPES.GRADUATED, PLAN_TYPES.VOLUME].includes(type);
};

const getDerivedTypeState = (type, tiers) => {
  if (typeHasTiers(type)) {
    return {
      tiers: tiers && tiers.length ? [...tiers] : DEFAULT_TIERS,
    };
  } else {
    return {
      tiers: null,
      amount: NEW_PLAN.amount,
    };
  }
};

const planReducer = (state, action) => {
  switch (action.type) {
    case 'initState':
      return {
        ...action.payload,
      };
    case 'type':
      return {
        ...state,
        type: action.payload,
        ...getDerivedTypeState(action.payload, state.tiers),
      };
    case 'charge_after_period':
      return {
        ...state,
        charge_after_period: action.payload,
      };
    case 'cycle_unit':
      return {
        ...state,
        cycle_unit: action.payload,
        cycle_amount:
          action.payload !== 'daily'
            ? NEW_PLAN.cycle_amount
            : state.cycle_amount,
      };
    case 'country':
      return {
        ...state,
        country: action.payload,
        currency: getCountryConfig(action.payload).currencies[0],
        collect_methods: undefined,
      };
    case 'currency':
      return {
        ...state,
        currency: action.payload,
        collect_methods: undefined,
      };
    case 'charge_proportional':
      return {
        ...state,
        charge_proportional: action.payload,
        start_day: action.payload ? state.start_day || 1 : null,
        start_month: state.cycle_unit === 'yearly' && action.payload ? 1 : null,
      };
    case 'extras':
      return {
        ...state,
        ...action.payload,
      };

    default:
      return {
        ...state,
        [action.type]: action.payload,
      };
  }
};

export function usePlan(initValue) {
  const defaultCollectMethods = useDefaultCollectMethods();
  const initPlanState = useWithCountryCurrency(NEW_PLAN);
  const [plan, dispatchPlanValue] = useReducer(
    planReducer,
    initValue || initPlanState
  );
  const [renewForever, setRenewForever] = useState(true);
  //const partners = usePartners();

  const CYCLE_UNIT_OPTIONS = list2NameValue(
    CYCLE_UNITS,
    'data.plan.cycle_units'
  );

  // Disable Colppy until new notice.
  // const hasColppy = partners.list?.data?.find(
  //   (p) => p.type === 'colppy' && p.status === 'enabled'
  // );
  const hasColppy = false;

  const getUpdatedTiers = (tiers) => {
    let lastTier = null;
    return tiers.map((tier, index) => {
      if (index === 0) {
        lastTier = tier;
        return tier;
      } else {
        const minimum = lastTier.maximum + 1;
        const maximum = minimum >= tier.maximum ? minimum + 1 : tier.maximum;
        lastTier = {
          ...tier,
          minimum,
          maximum,
        };
        return lastTier;
      }
    });
  };

  const removeTier = (tierIndex) => {
    // TODO: Move this unmutability logic to specifc action in reducer
    let newTiers = [...plan.tiers];
    newTiers.splice(tierIndex, 1);
    dispatchPlanValue({ type: 'tiers', payload: getUpdatedTiers(newTiers) });
  };

  const updateTiers = () => {
    dispatchPlanValue({ type: 'tiers', payload: getUpdatedTiers(plan.tiers) });
  };

  const addNewTier = () => {
    const newTiers = [...plan.tiers];
    const newTier = {
      minimum: 0,
      maximum: 10,
      amount: 1,
      amount_type: 'per_unit',
    };
    if (newTiers.length === 1) {
      newTier.minimum = newTiers[0].minimum + 10;
    } else {
      let lastTier = newTiers[newTiers.length - 1];
      lastTier.maximum = lastTier.minimum + 9;
      newTier.minimum = lastTier.maximum + 1;
      newTier.maximum = undefined;
    }
    newTiers.push(newTier);
    dispatchPlanValue({ type: 'tiers', payload: newTiers });
  };

  const pricingValuesAreValid = () => {
    const isAmountValid = (amount) =>
      !!(!Number.isNaN(amount) && Number(amount) > 0);

    if (plan.type === PLAN_TYPES.VARIABLEFLAT) return true;
    if (plan.type === PLAN_TYPES.FLAT || plan.type === PLAN_TYPES.USAGE) {
      return isAmountValid(plan.amount);
    }
    if (typeHasTiers(plan.type)) {
      return plan.tiers.every(({ amount }) => isAmountValid(amount));
    }
    return true;
  };

  const setPricingValues = (values) => {
    if (typeHasTiers(plan.type)) {
      dispatchPlanValue({ type: 'tiers', payload: values });
    } else {
      dispatchPlanValue({ type: 'amount', payload: values[0] });
    }
  };

  const toggleRenewForever = () => {
    const renewForeverState = !renewForever;
    setRenewForever(renewForeverState);
    dispatchPlanValue({
      type: 'duration_cycles',
      payload: renewForeverState ? null : 1,
    });
  };

  const setExtras = useCallback((extrasList) => {
    const extras = splitExtrasByType(extrasList);
    dispatchPlanValue({
      type: 'extras',
      payload: extras,
    });
  }, []);

  const isPlanValid = () => {
    return (
      plan.name &&
      plan.cycle_amount &&
      plan.name.trim() !== '' &&
      parseInt(plan.trial_period_days) >= 0 &&
      pricingValuesAreValid() &&
      hasCollectMethods(plan.collect_methods) &&
      (!renewForever ? plan.duration_cycles > 0 : true) &&
      (plan.charge_proportional
        ? plan.start_day >= 1 && plan.start_day <= 31
        : true)
    );
  };

  useEffect(() => {
    // No need for defaults if we already have a plan to updates
    if (!initValue && defaultCollectMethods.data) {
      dispatchPlanValue({
        type: 'collect_methods',
        payload: defaultCollectMethods.data,
      });
    }
  }, [defaultCollectMethods.data, initValue]);

  return {
    plan,
    dispatchPlanValue,
    setPricingValues,
    isPlanValid,
    tiersActions: {
      add: addNewTier,
      remove: removeTier,
      update: updateTiers,
    },
    renewForever,
    toggleRenewForever,
    setExtras,
    CYCLE_UNIT_OPTIONS,
    hasColppy,
    //fetching: false, //partners.isLoading,
  };
}
