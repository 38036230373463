import styled from 'styled-components';
import { H1, H2 } from '@increasecard/typed-components';
import { Trans, useTranslation } from 'react-i18next';
import { HelpButton } from '../../components/common/HelpButton';
import { Skeleton } from '../../components/common/Skeleton';

const MetricBox = styled.div`
  background-color: var(--color-pay-dark30);
  color: var(--color-gray-white);
  padding: 12px;
  border-radius: 9px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const MetricBoxTitle = styled(H2)`
  color: var(--color-pay-light70);
`;

export interface BigNumberBoxProps {
  name: string;
  value: string;
  isLoading: boolean;
}

export function BigNumberBox({
  name,
  value,
  isLoading,
}: BigNumberBoxProps): JSX.Element {
  const { t } = useTranslation();

  const getContent = () => {
    if (isLoading) {
      return <Skeleton speed="1s" width="50%" height="2.5rem" opacity="0.6" />;
    } else if (value) {
      return <H1>{value}</H1>;
    }
  };

  return (
    <MetricBox>
      <MetricBoxTitle>
        <Trans i18nKey={`metrics.payments.${name}`} t={t} />{' '}
        <HelpButton
          iconColor="white"
          width="14px"
          height="14px"
          titleKey={`metrics.payments.${name}`}
          descriptionKey={`metrics.payments.help.${name}`}
        />
      </MetricBoxTitle>
      {getContent()}
    </MetricBox>
  );
}
