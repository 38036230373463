import { ChangeEvent, createRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormikHelpers, useFormik } from 'formik';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';
import {
  InputText,
  NativeSelect,
  Spinner,
} from '@increasecard/typed-components';
import { CreditCardSchema } from '../CreditCardSchema';
import { ResponsiveGrid } from '../../common/ResponsiveGrid';
import { CreditCardInput } from '../CreditCardInput';
import { PaymentMethodInfoBox } from '../PaymentMethodInfoBox';
import { OnlyDesktop, useResponsive } from '../../../hooks/useResponsive';
import { GATEWAYS, useLoadGateway } from '../GatewayHOCs';
import { supportedCardsIcons } from './decidirUtils';
import { Constants } from '../../../constants';
import { PaymentFormFooter } from '../PaymentFormFooter';
import { DecidirCollectMethod, NewApiError } from '../../../types';
import { Row } from '../../common/Row';
import { PaymentFormBase } from '../types';

const { DECIDIR } = Constants.CollectMethods.CARD;

const INITIAL_FORM_VALUES = {
  name: '',
  cardNumber: '',
  cvv: '',
  dni: '',
  document_type: 'DNI',
  expirationDate: '',
  set_as_default: false,
};

type FormValues = typeof INITIAL_FORM_VALUES;

const HiddenInput = styled.input`
  display: none;
`;

export type DecidirFormProps = PaymentFormBase<DecidirCollectMethod, Metadata>;

interface DFormProps extends DecidirFormProps {
  sdkInstance: Decidir;
}

const identificationTypes = [
  { id: 'dni', name: 'DNI' },
  { id: 'other', name: 'Otro' },
];

function DForm({
  onSubmit,
  onCancel,
  processing,
  collectMethods,
  submitText,
  cancelText,
  sdkInstance,
  termsAndConditionsUrl,
  errorObj,
  showSetAsDefaultPM,
}: DFormProps): JSX.Element {
  const { t } = useTranslation();
  const formRef = createRef<HTMLFormElement>();
  const { isMobile } = useResponsive();
  const [decidirError, setDecidirError] = useState<NewApiError | null>(null);
  const [cardType, setCardType] = useState('');

  const handleSubmit = (
    values: FormValues,
    formik: FormikHelpers<FormValues>
  ) => {
    const decidirCollectMethod = collectMethods;
    sdkInstance.createToken(
      formRef.current,
      (status: number, response: Metadata) => {
        formik.setSubmitting(false);
        if (status !== 200 && status !== 201) {
          setDecidirError(
            NewApiError.fromAPI({
              code: 'DECIDIR_ERROR',
              // The 2 structures are possible
              message: response.message || response?.error[0]?.error?.message,
            })
          );
        } else {
          onSubmit({
            type: DECIDIR,
            payment_method_id: cardType,
            bin: response.bin,
            token: response.id,
            last_four_digits: response.last_four_digits,
            expiration_month: response.expiration_month,
            expiration_year: response.expiration_year,
            // TODO: Assert somehow (Elegantly) that the collect method is always found
            collect_method_id: decidirCollectMethod?.id,
            set_as_default: values.set_as_default,
          });
        }
      }
    );
  };

  const formik = useFormik({
    initialValues: INITIAL_FORM_VALUES,
    validate: () => {
      const errors: Metadata = {};
      if (cardType === 'other') {
        errors.cardNumber = t('validation.unsupported_card_brand');
      }
      return errors;
    },
    validationSchema: CreditCardSchema(t),
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (sdkInstance) {
      let cardType = 'other';
      const lengths = [16, 15];

      while (cardType === 'other' && lengths.length) {
        cardType = sdkInstance.cardType(
          formik.values.cardNumber.padEnd(lengths[0], '0')
        );
        lengths.shift();
      }
      setCardType(cardType);
    }
  }, [formik.values.cardNumber, sdkInstance]);

  const infoBoxData = {
    supportedCards: supportedCardsIcons,
    cardNumber: formik.values.cardNumber,
    name: formik.values.name,
    expirationDate: formik.values.expirationDate,
    cardBrandId: cardType,
  };

  const handleNumericChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!/\D/.test(e.target.value)) {
      formik.handleChange(e);
    }
  };

  const handleDniChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (formik.values.document_type !== 'other') {
      if (!/\D/.test(e.target.value)) {
        formik.handleChange(e);
      }
    } else {
      formik.handleChange(e);
    }
  };

  const expirationDateLabel = isMobile
    ? t('data.payment_method.expiration_date_long')
    : t('data.payment_method.expiration_date');
  const securityCodeLabel = isMobile
    ? t('data.payment_method.security_code_cvv_cvc')
    : t('data.payment_method.cvv_cvc');
  // With type="tel" numerical keyboard shos up in mobile devices
  return (
    <Row position="relative" gap="1.5rem" flexWrap="wrap">
      <ResponsiveGrid
        as="form"
        ref={formRef}
        rowGap={2}
        desktopMaxWidth="335px"
      >
        <CreditCardInput
          className="grid-span-desktop-8"
          cardBrand={cardType}
          showMethodIcon={isMobile}
          showHelpIcon={isMobile}
          supportedCards={supportedCardsIcons}
          errorMessage={
            formik.touched.cardNumber ? formik.errors.cardNumber : undefined
          }
          onBlur={formik.handleBlur}
          value={formik.values.cardNumber}
          onChange={(value) => {
            formik.setFieldValue('cardNumber', value);
          }}
          id="cardNumber"
          data-decidir="card_number"
        />
        <NumberFormat
          className="grid-span-desktop-4"
          id="expirationDate"
          type="tel"
          autoComplete="off"
          required
          label={expirationDateLabel}
          placeholder={t('data.payment_method.exp_date_placeholder')}
          errorMessage={
            formik.touched.expirationDate && formik.errors.expirationDate
          }
          marginRight="1rem"
          onBlur={formik.handleBlur}
          value={formik.values.expirationDate}
          customInput={InputText}
          onValueChange={(formattedValues) => {
            formik.setFieldValue(
              'expirationDate',
              formattedValues.formattedValue
            );
          }}
          format="##/##"
        />
        <HiddenInput
          id="expiryMonth"
          autoComplete="off"
          value={formik.values.expirationDate.split('/')[0] || ''}
          readOnly={true}
          data-decidir="card_expiration_month"
        />
        <HiddenInput
          id="expiryYear"
          autoComplete="off"
          value={formik.values.expirationDate.split('/')[1] || ''}
          readOnly={true}
          data-decidir="card_expiration_year"
        />
        <InputText
          className="grid-span-desktop-8"
          errorMessage={formik.touched.name && formik.errors.name}
          id="name"
          autoComplete="off"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          data-decidir="card_holder_name"
          label={t('data.payment_method.name_on_card')}
          placeholder={t('data.payment_method.cardholder_name_placeholder')}
          required
        />
        <InputText
          className="grid-span-desktop-4"
          errorMessage={formik.touched.cvv && formik.errors.cvv}
          id="cvv"
          type="tel"
          maxLength={4}
          autoComplete="off"
          value={formik.values.cvv}
          onChange={handleNumericChange}
          onBlur={formik.handleBlur}
          data-decidir="security_code"
          label={securityCodeLabel}
          placeholder={t('data.payment_method.cvv_cvc_placeholder')}
          required
        />
        <NativeSelect
          id="document_type"
          className="grid-span-desktop-5"
          onChange={formik.handleChange}
          label={t('data.payment_method.document_type')}
        >
          {identificationTypes.map((opt, i) => (
            <option key={i} value={opt.id}>
              {opt.name}
            </option>
          ))}
        </NativeSelect>
        <HiddenInput
          id="docType"
          autoComplete="off"
          value={formik.values.document_type}
          readOnly={true}
          data-decidir="card_holder_doc_type"
        />
        <InputText
          className="grid-span-desktop-7"
          errorMessage={formik.touched.dni && formik.errors.dni}
          id="dni"
          type="tel"
          autoComplete="off"
          value={formik.values.dni}
          onChange={handleDniChange}
          onBlur={formik.handleBlur}
          data-decidir="card_holder_doc_number"
          label={t('data.payment_method.document_number')}
          placeholder={t('data.payment_method.document_number_placeholder')}
          required
        />
        <PaymentFormFooter
          className="grid-span-desktop-12"
          isSubmitting={formik.isSubmitting || processing}
          onSubmit={formik.handleSubmit}
          submitText={submitText}
          onCancel={onCancel}
          cancelText={cancelText}
          termsAndConditionsUrl={termsAndConditionsUrl}
          error={errorObj || decidirError}
          onSetDefaultPaymentMethod={
            showSetAsDefaultPM ? formik.handleChange : undefined
          }
        />
      </ResponsiveGrid>
      <OnlyDesktop>
        <PaymentMethodInfoBox
          type="card"
          data={infoBoxData}
          errorObj={errorObj || decidirError}
        />
      </OnlyDesktop>
    </Row>
  );
}

export function DecidirForm(
  props: PaymentFormBase<DecidirCollectMethod>
): JSX.Element {
  const collectMethod = props.collectMethods;
  const { gatewayLoaded, sdkInstance } = useLoadGateway(GATEWAYS.DECIDIR, {
    apiKey: collectMethod.api_key,
  });
  return gatewayLoaded && sdkInstance ? (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <DForm {...props} sdkInstance={sdkInstance as any} />
  ) : (
    <Spinner size={24} />
  );
}
