import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Grid } from '@increasecard/typed-components';
import { ALL_COUNTRIES } from '../../../localizations';
import { getCountryConfig } from '../../../utils/countryUtils';
import { list2NameValue } from '../../../utils/utils';
import { OneTimeCostSchema } from './OneTimeCostSchema';
import {
  InputTextField,
  SelectField,
} from '../../../components/common/FormikFields';
import { OneTimeCost } from '../../../types/OneTimeCost';
import { Country } from '../../../types/common';
import { AmountTypeConfig, FooterButtons } from '../FormSections';

export type NewOneTimeCostFormValues = Partial<OneTimeCost>;
export interface OneTimeCostFormProps {
  initialValues: NewOneTimeCostFormValues;
  onSubmit: (otc: NewOneTimeCostFormValues) => void;
  onCancel: () => void;
  isLoading: boolean;
  editMode?: boolean;
}

export function OneTimeCostForm({
  initialValues,
  onSubmit,
  onCancel,
  editMode = false,
  isLoading,
}: OneTimeCostFormProps): JSX.Element {
  const { t } = useTranslation();
  const COUNTRY_OPTIONS = list2NameValue(ALL_COUNTRIES, 'data.countries');

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={OneTimeCostSchema(t)}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, setFieldValue }) => (
        <Grid as="form" onSubmit={handleSubmit} rowGap={2}>
          <InputTextField
            className="span-desktop-6"
            id="name"
            autoComplete="off"
            label={t('common.name')}
          />
          <InputTextField
            className="span-desktop-6"
            id="external_id"
            autoComplete="off"
            placeholder={t('common.optional')}
            label={t('common.id')}
          />
          <InputTextField
            as="textarea"
            id="description"
            height="100px"
            autoComplete="off"
            placeholder={t('common.placeholders.description')}
            label={t('common.description')}
          />
          <SelectField
            id="country"
            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
              const value = e.target.value as Country;
              setFieldValue(e.target.name, value);
              const { currencies } = getCountryConfig(value);
              setFieldValue('currency', currencies[0]);
            }}
            options={COUNTRY_OPTIONS}
            label={t('common.country')}
            disabled={editMode}
          />
          <AmountTypeConfig editMode={editMode} />
          <FooterButtons onCancel={onCancel} isLoading={isLoading} />
        </Grid>
      )}
    </Formik>
  );
}
