import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import {
  fetchPaymentById,
  fetchPayments,
  reversePayment,
} from '../../services/PaymentService';
import { PaginatedData, PaginationConfig } from '../../types/common';
import { NewApiError } from '../../types/NewApiError';
import { Payment } from '../../types/Payment';
import {
  getPaginatedQueriesData,
  queryKeyFactory,
  useMutationWithInvalidation,
} from '../../utils/queryUtils';

export const paymentQueryKeys = queryKeyFactory('payment');

export function usePayments(
  params: PaginationConfig
): UseQueryResult<PaginatedData<Payment>, NewApiError> {
  return useQuery({
    queryKey: paymentQueryKeys.list(params),
    queryFn: () => fetchPayments(params),
    enabled: !!params.page,
    keepPreviousData: true,
  });
}

export function usePaymentById(
  id: string
): UseQueryResult<Payment, NewApiError> {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: paymentQueryKeys.detail(id),
    queryFn: () => fetchPaymentById(id),
    enabled: !!id,
    initialData: () => {
      return getPaginatedQueriesData<Payment>(queryClient, [
        'payment',
        'list',
      ])?.find((data) => {
        return data.id === id;
      });
    },
  });
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useReversePayment() {
  return useMutationWithInvalidation(reversePayment, paymentQueryKeys.all);
}
