import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';
import { redirectTo } from '../../utils/utils';
import { getCustomerName } from '../../utils/utils';
import { getCountryConfig } from '../../utils/countryUtils';
import { getShopName } from './checkoutUtils';
import { H1, Paragraph } from '@increasecard/typed-components';
import { PaymentMethods } from '../../components/paymentMethods/PaymentMethods';
import { BrandLogo } from './BrandLogo';
import { CheckoutSummary } from './components/CheckoutSummary';
import { SummaryFooter } from './components/SummaryFooter';
import { CheckoutLayout } from './Layout';
import { ProcessingPaymentModal } from './components/ProcessingPaymentModal';
import { Constants } from '../../constants';
import { usePayInvoice } from './checkoutQueries';
import { CheckoutInvoice } from '../../types/Invoice';
import { CheckoutAccount } from '../../types/Account';
import { formatCurrency } from '../../utils/currencyUtils';

const Container = styled.div`
  & > ${Paragraph} {
    margin-bottom: 10px;
    margin-top: 5px;
  }
`;

export interface InvoicePaymentScreenProps {
  invoice: CheckoutInvoice;
  account: CheckoutAccount;
}

export function InvoicePaymentScreen({
  invoice,
  account,
}: InvoicePaymentScreenProps): JSX.Element {
  const { t } = useTranslation();
  const payment = usePayInvoice();
  const customerCountry = invoice.customer.billing_info.country;

  const handleSubmitPayment = (paymentData: Metadata) => {
    payment.mutate(
      {
        invoiceId: invoice.id,
        paymentData: {
          ...paymentData,
          customer_id: invoice.customer.id,
          reference_id: invoice.id,
          reference_type: Constants.PaymentMethodContext.INVOICE,
        },
      },
      {
        onSuccess: ({ payment }) => {
          // If payment is cash, redirect to ticket url.
          if (payment?.external_resource_url) {
            return redirectTo(payment?.external_resource_url);
          }
        },
      }
    );
  };

  const getTitle = (): JSX.Element => {
    if (invoice.kind === 'debt_recovery') {
      return (
        <Trans
          i18nKey="screens.invoice_payment.debt_title"
          components={[
            getCustomerName(invoice.customer, {
              getEmailWhenNameIsEmpty: false,
            }),
            invoice.items[0].description,
            formatCurrency(
              invoice.items[0].amount,
              invoice.items[0].currency,
              getCountryConfig(customerCountry).language
            ),
          ]}
        />
      );
    }
    return (
      <Trans
        i18nKey="screens.invoice_payment.title"
        components={[
          getCustomerName(invoice.customer, {
            getEmailWhenNameIsEmpty: false,
          }),
          formatCurrency(
            invoice.amount,
            invoice.currency,
            getCountryConfig(customerCountry).language
          ),
        ]}
      />
    );
  };

  const leftContext = (
    <Container>
      <BrandLogo brandingSettings={account.branding_settings} />
      <H1>{getTitle()}</H1>
      <Paragraph>
        {t('screens.invoice_payment.description', {
          company: getShopName(account),
        })}
      </Paragraph>
      <PaymentMethods
        onNext={handleSubmitPayment}
        processing={payment.isLoading}
        submitText={t('screens.invoice_payment.button_payment')}
        customerId={invoice.customer.id}
        country={customerCountry}
        groupedCollectMethods={invoice.collect_methods}
        planCollectMethods={invoice.plan_collect_methods}
        isUniquePayment={true}
        errorObj={payment.error}
      />
    </Container>
  );

  return (
    <>
      <CheckoutLayout
        brandingSettings={account.branding_settings}
        leftContext={leftContext}
        rightContext={
          <CheckoutSummary
            brandingSettings={account.branding_settings}
            invoice={invoice}
            summaryType="invoice"
          />
        }
        footerContext={
          <SummaryFooter invoice={invoice} summaryType="invoice" />
        }
      />
      <ProcessingPaymentModal visible={payment.isLoading} />
    </>
  );
}
