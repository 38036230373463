import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Constants } from '../../../constants';
import { formatCurrencyType } from '../../../utils/currencyUtils';
import { ApiErrorMessage } from '../../../components/ErrorMessage';
import { getShortText } from '../../../utils/utils';
import { getExtraLanguage, getExtraCurrency } from '../../../utils/extrasUtils';
import { EditExtraModal } from '../EditExtraModal';
import { useModal } from '../../../hooks/useModal';
import {
  DataTable,
  ColumnDefinition,
  MenuActionDefinition,
  RowDefinition,
} from '../../../components/common/DataTable/DataTable';
import { usePayAuthorizations } from '../../authorizations/usePayAuthorizations';
import { useTaxes } from './taxQueries';
import { Tax } from '../../../types';

export function TaxesList(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const [page, setPage] = useState(1);
  const { data, isLoading, error, refetch } = useTaxes(page);

  const editModal = useModal();
  const { canCreateEditExtra } = usePayAuthorizations();

  // actions
  const loadPage = setPage;

  const goToDetail = (id: string) => {
    history.push(`/extras/tax/${id}/detail`);
  };

  // UI callbacks
  const onErrorMessageClick = () => refetch();

  // error
  if (error) {
    return <ApiErrorMessage error={error} onClick={onErrorMessageClick} />;
  }

  const hasData = data?.data ? data?.data.length > 0 : false;

  const tableColumns: ColumnDefinition[] = [
    {
      field: 'name',
      headerName: t('common.tax'),
      valueGetter: (row: RowDefinition) => getShortText(row.name),
    },
    {
      field: 'description',
      headerName: t('common.description'),
      valueGetter: (row: RowDefinition) => getShortText(row.description),
    },
    {
      field: 'country',
      headerName: t('common.country'),
      valueGetter: (row: RowDefinition) =>
        row.country && t(`data.countries.${row.country}`),
    },
    {
      field: 'region',
      headerName: t('common.region'),
    },
    {
      field: 'amount',
      headerName: t('common.amount'),
      rightAligned: true,
      valueGetter: (row: RowDefinition) =>
        formatCurrencyType(
          row.type,
          parseFloat(row.amount),
          getExtraCurrency(row),
          getExtraLanguage(row)
        ),
    },
  ];

  const menuActions: MenuActionDefinition[] = [
    {
      label: t('common.edit'),
      cb: (tax: RowDefinition) => editModal.open(tax),
      disabled: () => !canCreateEditExtra(),
    },
  ];

  return (
    <>
      <DataTable
        tableProps={{ zebra: true, hasData }}
        columns={tableColumns}
        pageSize={Constants.SMALL_LIST_PAGE_SIZE}
        isLoading={isLoading}
        rows={data?.data}
        menuActions={menuActions}
        onRowClick={(tax: Tax) => goToDetail(tax.id)}
        paginatorProps={{
          pages: data?.total_pages,
          selected: Number(data?.current_page || 1),
          onSelectPage: loadPage,
        }}
      />
      <EditExtraModal
        onClose={editModal.close}
        visible={editModal.isOpen}
        extra={editModal.data}
      />
    </>
  );
}
