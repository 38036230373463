import { PlusSignIcon } from '@increasecard/icons';
import { Button, Grid, H1 } from '@increasecard/typed-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { GoBackButton } from '../../../components/common/GoBackButton';
import { ScreenSeparator } from '../../../components/common/ScreenSeparator';
import { ProductSelector } from '../../../components/FilterSelectors/ProductSelector';
import { NameValue } from '../../../types/common';

export interface ProductSelectionProps {
  onChange: (productId: string) => void;
  currentProductId: string | undefined;
}

const CustomGrid = styled(Grid)`
  align-items: end;
`;

export function ProductSelection({
  onChange,
  currentProductId,
}: ProductSelectionProps): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const goToNewProduct = () => {
    history.push('/products/new');
  };

  const handleProductChange = (product: NameValue) => onChange(product.value);
  return (
    <CustomGrid rowGap={2}>
      <GoBackButton />
      <H1>{t('screens.new_plan.select_product')}</H1>
      <ProductSelector
        className="span-desktop-4"
        onChange={handleProductChange}
        value={currentProductId}
      />
      <Button
        className="span-desktop-2"
        buttonType="invisible"
        icon={<PlusSignIcon />}
        onClick={goToNewProduct}
      >
        {t('common.create_new')}
      </Button>

      <ScreenSeparator />
    </CustomGrid>
  );
}
