import { PlanSelection } from './components/PlanSelection';
import { EndCheckoutSummary } from './components/EndCheckoutSummary';
import { PaymentMethod } from './components/PaymentMethod';
import { BillingInfo } from './components/BillingInfo';
import { useState } from 'react';

export const STEP_INDEX = {
  plan: 0,
  billing: 1,
  payment: 2,
  end: 3,
};

const STEP_COMPONENTS = [
  PlanSelection,
  BillingInfo,
  PaymentMethod,
  EndCheckoutSummary,
];

export interface UseCheckoutNavigation {
  currentStep: number;
  gotoPlanSelection: VoidFunction;
  gotoBilling: VoidFunction;
  gotoPayment: VoidFunction;
  gotoSummary: VoidFunction;
  gotoNextStep: VoidFunction;
  gotoPrevStep: VoidFunction;
  gotoStep: (number: number) => void;
  StepComponent: (typeof STEP_COMPONENTS)[keyof typeof STEP_COMPONENTS];
}

export function useCheckoutNavigation(startStep = 0): UseCheckoutNavigation {
  const [currentStep, setCurrentStep] = useState(startStep);

  const gotoPlanSelection = () => {
    setCurrentStep(STEP_INDEX.plan);
  };

  const gotoBilling = () => {
    setCurrentStep(STEP_INDEX.billing);
  };

  const gotoPayment = () => {
    setCurrentStep(STEP_INDEX.payment);
  };

  const gotoSummary = () => {
    setCurrentStep(STEP_INDEX.end);
  };

  const gotoNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const gotoPrevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const gotoStep = setCurrentStep;

  const StepComponent = STEP_COMPONENTS[currentStep];

  return {
    currentStep,
    gotoNextStep,
    gotoPrevStep,
    gotoStep,
    gotoPlanSelection,
    gotoBilling,
    gotoPayment,
    gotoSummary,
    StepComponent,
  };
}
