import { Suspense } from 'react';
import { ThemeProvider } from '@increasecard/typed-components';
import { AppRoutes } from './AppRoutes';
import { productName } from './config';
import { FullPageSpinner } from './components/common/FullPageSpinner';

export function App(): JSX.Element {
  return (
    <ThemeProvider productName={productName}>
      <Suspense fallback={<FullPageSpinner />}>
        <AppRoutes />
      </Suspense>
    </ThemeProvider>
  );
}
export default App;
