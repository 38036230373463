/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { FieldInputWrapper } from '../FieldInputWrapper';

export type DlocalFieldEvent = 'ready' | 'change' | 'blur';
export type DlocalFieldPayload = {
  error?: {
    message: string;
  } | null;
};

export type CustomFieldEventPayload = DlocalFieldPayload & {
  type: DlocalFieldType;
  isReady?: boolean;
};

export type DlocalFieldInstance = {
  on: (
    event: DlocalFieldEvent,
    cb: (payload: DlocalFieldPayload) => void
  ) => void;
  mount: (elem: HTMLElement | null) => void;
  unmount: () => void;
};

export type DlocalFieldType = 'pan' | 'expiration' | 'cvv';

export interface DlocalFieldProps {
  field: DlocalFieldInstance;
  type: DlocalFieldType;
  label: string;
  onBlur: (p: CustomFieldEventPayload) => void;
  onChange: (p: CustomFieldEventPayload) => void;
  onReady: (p: CustomFieldEventPayload) => void;
  className: string;
}

export function DlocalField({
  field,
  type,
  label,
  onBlur,
  onReady,
  onChange,
  className,
}: DlocalFieldProps): JSX.Element {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const fieldElemId = `input-${type}`;

  useEffect(() => {
    field.on('ready', () => {
      if (onReady) onReady({ type, isReady: true });
    });
    field.on('change', (event) => {
      onChange && onChange({ ...event, type });
      if (!event.error) {
        setErrorMessage(null);
      }
    });
    field.on('blur', (event) => {
      if (event.error) {
        setErrorMessage(event.error.message);
      }
      if (onBlur) onBlur({ ...event, type });
    });

    field.mount(window.document.getElementById(fieldElemId));

    return () => field.unmount();
  }, [field, fieldElemId, onBlur, onChange, onReady, type]);

  return (
    <FieldInputWrapper
      id={fieldElemId}
      className={className}
      label={label}
      errorMessage={errorMessage}
      internalClassPrefix="DlocalField"
    />
  );
}
