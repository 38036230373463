import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { MenuActionDefinition } from '../../components/common/DataTable/DataTable';
import { ModalControls, useModal } from '../../hooks/useModal';
import { useUIEventTracker } from '../../hooks/useUIEventTracker';
import { Payment, Invoice } from '../../types';
import { usePayAuthorizations } from '../authorizations/usePayAuthorizations';

export interface UsePaymentActions {
  actions: MenuActionDefinition[];
  goToDetails: (paymentId: string, clickContext: string) => void;
  retryModal: ModalControls<Invoice[]>;
  refundModal: ModalControls<Payment>;
}

export type PaymentAction = 'view_details' | 'retry_payment' | 'refund_payment';

const RETRYABLE_STATUSES = ['failed'];

export function usePaymentActions(
  context: string,
  omit?: PaymentAction[]
): UsePaymentActions {
  const { t } = useTranslation();
  const history = useHistory();
  const { canRetryPayment, canRefundPayment } = usePayAuthorizations();
  const trackEvent = useUIEventTracker(context);
  const retryModal = useModal();
  const refundModal = useModal();

  const goToDetails = (paymentId: string, clickContext: string) => {
    trackEvent('view_payment_detail', { context: clickContext });
    history.push(`/payments/${paymentId}/detail`);
  };

  const availableActions = [
    {
      name: 'view_details',
      label: t('screens.invoices.detail'),
      cb: (payment: Payment) => goToDetails(payment.id, 'list row menu'),
      disabled: () => false,
    },
    {
      name: 'retry_payment',
      label: t('common.retry_payment'),
      cb: (payment: Payment) => {
        trackEvent('retry_payment');
        retryModal.open([payment.invoice]);
      },
      disabled: ({ status }: Payment) => {
        // check if this payment can be retried
        if (!canRetryPayment()) {
          // retry denied for this user
          return true;
        }
        if (!RETRYABLE_STATUSES.includes(status)) {
          // denied by status
          return true;
        }
        // payment can be retried
        return false;
      },
    },
    {
      name: 'refund_payment',
      label: t('common.refund_payment'),
      cb: (payment: Payment) => {
        trackEvent('refund_payment');
        refundModal.open(payment);
      },
      disabled: ({ refundable }: Payment) => !refundable || !canRefundPayment(),
    },
  ];

  const actions = omit
    ? availableActions.filter((action) => {
        return !omit.includes(action.name as PaymentAction);
      })
    : availableActions;

  return { actions, goToDetails, retryModal, refundModal };
}
