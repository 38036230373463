import { useTranslation } from 'react-i18next';
import { H2, Paragraph, Grid } from '@increasecard/typed-components';
import { useWithCountryCurrency } from '../../../utils/countryUtils';
import { ApiErrorMessage } from '../../../components/ErrorMessage';
import {
  AdditionalCostForm,
  AdditionalCostFormValues,
} from './AdditionalCostForm';
import { useCreateAditionalCost } from './aditionalCostQueries';

const INITIAL_VALUES: AdditionalCostFormValues = {
  type: 'flat',
  duration: 'periodic',
  cycle_amount: 1,
};

export interface NewAdditionalCostScreenProps {
  onClose: () => void;
}

export function NewAditionalCostScreen({
  onClose,
}: NewAdditionalCostScreenProps): JSX.Element {
  const { t } = useTranslation();
  const initialValues = useWithCountryCurrency(INITIAL_VALUES);
  const { isLoading, error, isSuccess, mutate } = useCreateAditionalCost();

  const handleSubmit = (discount: AdditionalCostFormValues) => {
    mutate(discount);
  };

  const entity = t('common.additional_cost');

  return (
    <Grid rowGap={2}>
      <H2>{t('common.create_title', { entity })}</H2>
      {isSuccess ? (
        <Paragraph>{t('common.success_create', { entity })}</Paragraph>
      ) : (
        <AdditionalCostForm
          initialValues={initialValues}
          onCancel={onClose}
          onSubmit={handleSubmit}
          isLoading={isLoading}
        />
      )}
      <ApiErrorMessage error={error} />
    </Grid>
  );
}
