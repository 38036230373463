import { TFunction } from 'i18next';
import * as Yup from 'yup';
import {
  RequiredEmail,
  RequiredString,
  OnlyNumbersRequired,
  TaxId,
} from './common';

// This schema is used by CustomerForm and checkout BillingInfo step.

type BillingInfo = {
  address_line_1: string;
  address_line_2?: string | null;
  city: string;
  country: string;
  zip_code: string;
  state: string;
  state_iso_code?: string;
  phone: string;
};

// This kind of "creation params" should probably live in the service or the mutation file
export type CustomerFormValues = {
  email: string;
  tax_id: string;
  first_name: string;
  last_name: string;
  billing_info: BillingInfo;
};

function BillingSchema(t: TFunction) {
  return Yup.object().shape({
    address_line_1: RequiredString(),
    address_line_2: Yup.string().nullable(),
    city: RequiredString(),
    country: RequiredString(),
    state: Yup.string().when('country', {
      is: 'ARG',
      then: Yup.string().nullable(),
      otherwise: RequiredString(),
    }),
    state_iso_code: Yup.string().when('country', {
      is: 'ARG',
      then: RequiredString(),
      otherwise: Yup.string().nullable(),
    }),
    zip_code: RequiredString(),
    phone: OnlyNumbersRequired(t),
  });
}

export function CustomerSchema(
  t: TFunction,
  validateTaxId: boolean
): Yup.SchemaOf<CustomerFormValues> {
  return Yup.object({
    email: RequiredEmail().defined(),
    tax_id: TaxId(t, validateTaxId).defined(),
    first_name: RequiredString().defined(),
    last_name: RequiredString().defined(),
    external_id: Yup.string().nullable(),
    billing_info: BillingSchema(t).defined(),
  });
}

// This type and schema might not need to be here. But time will tell.

export type PartialCustomerFormValues = Pick<
  CustomerFormValues,
  'email' | 'tax_id'
> & {
  billing_info: Pick<BillingInfo, 'country'>;
};

export function PartialCustomerSchema(
  t: TFunction
): Yup.SchemaOf<PartialCustomerFormValues> {
  return Yup.object({
    email: RequiredEmail().defined(),
    tax_id: TaxId(t).defined(),
    billing_info: BillingSchema(t).pick(['country']),
  });
}
