import { ReactNode } from 'react';
import {
  TableWrapper,
  Table,
  Checkbox,
  TableHeader,
  H2,
  TableProps,
} from '@increasecard/typed-components';
import { Paginator, PaginatorProps } from '../Paginator';
import { TableSkeleton } from './TableSkeleton';
import { Row } from './Row';
import { Box } from '../Box';
import { useRowSelection } from './useRowSelection';
import {
  RowDef,
  SelectionRowCallback,
  ColumnDef,
  RowClickCallback,
} from './tableDefinitions';
import { MenuAction } from '../Menu';

// Re-export types for convenience.
export type ColumnDefinition = ColumnDef;
export type RowDefinition = RowDef;
export type MenuActionDefinition = MenuAction;

export interface DataTableProps {
  columns: ColumnDef[];
  rows: RowDef[] | null | undefined;
  tableProps: Omit<TableProps, 'children'>;
  isLoading?: boolean;
  pageSize?: number;
  menuActions?: MenuAction[];
  hideDisabledOptions?: boolean;
  paginatorProps?: PaginatorProps;
  rowHeight?: 'default' | 'double';
  rowBorder?: boolean;
  idKey?: string;
  onSelect?: SelectionRowCallback;
  selectedRows?: RowDef[];
  tableHeader?: string;
  tableHeaderButtons?: ReactNode;
  onRowClick?: RowClickCallback;
}

export function DataTable({
  columns,
  rows,
  tableProps,
  isLoading,
  pageSize = 6,
  menuActions,
  hideDisabledOptions,
  paginatorProps,
  rowHeight,
  rowBorder,
  idKey = 'id',
  tableHeader,
  tableHeaderButtons,
  onSelect,
  selectedRows,
  onRowClick,
}: DataTableProps): JSX.Element {
  if (rows && rows.some((row: RowDef) => !row[idKey])) {
    throw new Error(`the key ${idKey} is not present in every row`);
  }
  const isDouble = rowHeight === 'double';
  const isFixedLayout = columns.some(({ width }) => width);
  const { selectionEnabled, handleSelection, isSelected, isAllSelected } =
    useRowSelection(onSelect, rows, selectedRows);
  const skeletonColumnCount =
    columns.length + (menuActions ? 1 : 0) + (selectionEnabled ? 1 : 0);
  return (
    <TableWrapper>
      {tableHeader || tableHeaderButtons ? (
        <TableHeader>
          <H2>{tableHeader}</H2>
          <Box display="flex" gap="16px">
            {tableHeaderButtons}
          </Box>
        </TableHeader>
      ) : null}
      <Box overflowX="auto">
        <Table
          {...tableProps}
          hasData={isLoading ? undefined : tableProps.hasData}
          fixedLayout={isFixedLayout}
        >
          <thead>
            <tr>
              {selectionEnabled && (
                <th style={{ width: '24px' }}>
                  <Checkbox
                    id="selectAll"
                    name="selectAll"
                    label="Seleccionar todos"
                    labelHidden
                    checked={isAllSelected}
                    onChange={handleSelection(rows || [])}
                    disabled={!rows || rows?.length === 0}
                  />
                </th>
              )}
              {columns.map((c: ColumnDef, index) => {
                return (
                  <th
                    key={`${c.field}-${index}`}
                    style={{ width: c.width }}
                    className={`${c.rightAligned ? 'rightAligned' : ''}`}
                    role="columnheader"
                  >
                    {c.headerName}
                  </th>
                );
              })}
              {menuActions && <th key="menu-actions"></th>}
            </tr>
          </thead>
          {isLoading ? (
            <TableSkeleton
              columnsCount={skeletonColumnCount}
              rowsCount={pageSize}
              double={isDouble}
            />
          ) : (
            <tbody>
              {rows &&
                rows.map((row: RowDef, i: number) => {
                  return (
                    <Row
                      key={row[idKey] || i}
                      id={row[idKey] || i}
                      row={row}
                      double={isDouble}
                      border={rowBorder}
                      menuActions={menuActions}
                      hideDisabledOptions={hideDisabledOptions}
                      columns={columns}
                      onSelect={onSelect}
                      isSelected={isSelected(row[idKey])}
                      onClick={onRowClick}
                    />
                  );
                })}
            </tbody>
          )}
        </Table>
      </Box>
      {tableProps.hasData && paginatorProps && (
        <Paginator
          pages={paginatorProps.pages}
          selected={paginatorProps.selected}
          onSelectPage={paginatorProps.onSelectPage}
        />
      )}
    </TableWrapper>
  );
}
