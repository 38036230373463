import { TableRowProps } from '@increasecard/typed-components';
import { Skeleton } from '../Skeleton';

export interface TableSkeletonProps extends TableRowProps {
  rowsCount: number;
  columnsCount: number;
}

export function TableSkeleton({
  rowsCount,
  columnsCount,
  double,
  border,
  ...rest
}: TableSkeletonProps): JSX.Element {
  const rows = [];
  const cells = [];
  for (let i = 0; i < columnsCount; i++) {
    cells.push(
      <td key={i}>
        <Skeleton />
      </td>
    );
  }
  for (let i = 0; i < rowsCount; i++) {
    rows.push(
      <tr
        {...rest}
        className={`${double ? 'double' : ''} ${border ? 'border' : ''}`}
        key={i}
      >
        {cells}
      </tr>
    );
  }
  return <tbody>{rows}</tbody>;
}
