import { useTheme } from 'styled-components';
import { useMediaQuery } from 'react-responsive';

export function useResponsive(): { isMobile: boolean } {
  const themeContext = useTheme();
  const isMobile = useMediaQuery({ maxWidth: themeContext.breakpoints.md });

  return { isMobile };
}

export function OnlyDesktop({
  children,
}: {
  children: JSX.Element | null;
}): JSX.Element | null {
  const { isMobile } = useResponsive();
  return !isMobile ? children : null;
}

export function OnlyMobile({
  children,
}: {
  children: JSX.Element | null;
}): JSX.Element | null {
  const { isMobile } = useResponsive();
  return isMobile ? children : null;
}
