import { useTranslation } from 'react-i18next';
import { H2, Paragraph, Grid } from '@increasecard/typed-components';
import { useWithCountryCurrency } from '../../../utils/countryUtils';
import { ApiErrorMessage } from '../../../components/ErrorMessage';
import { OneTimeCostForm, NewOneTimeCostFormValues } from './OneTimeCostForm';
import { useCreateOneTimeCost } from './oneTimeCostQueries';

const INITIAL_VALUES: NewOneTimeCostFormValues = {
  type: 'flat', // flat, percentage
};

export interface NewOneTimeCostScreenProps {
  onClose: () => void;
}

export function NewOneTimeCostScreen({
  onClose,
}: NewOneTimeCostScreenProps): JSX.Element {
  const { t } = useTranslation();
  const initialValues = useWithCountryCurrency(INITIAL_VALUES);
  const { isLoading, error, isSuccess, mutate } = useCreateOneTimeCost();

  const handleSubmit = (otc: NewOneTimeCostFormValues) => {
    mutate(otc);
  };

  return (
    <Grid rowGap={2}>
      <H2>{t('common.create_title', { entity: t('common.one_time_cost') })}</H2>
      {isSuccess ? (
        <Paragraph>
          {t('common.success_create', { entity: t('common.one_time_cost') })}
        </Paragraph>
      ) : (
        <OneTimeCostForm
          initialValues={initialValues}
          onCancel={onClose}
          onSubmit={handleSubmit}
          isLoading={isLoading}
        />
      )}
      <ApiErrorMessage error={error} />
    </Grid>
  );
}
