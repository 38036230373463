import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NameValue } from '../../types/common';
import { SelectorMultipleProps, SelectorProps } from './types';
import { Customer } from '../../types/Customer';
import { useCustomers } from '../../features/customers/customerQueries';
import useDebounce from '../../hooks/useDebounce';
import { objectsList2NameValueList } from '../../utils/utils';
import { getCustomerName } from '../../utils/utils';
import { Selector, SelectorMultiple } from '../common/Selectors';

export function CustomerSelectorMultiple({
  onChange,
  value,
  ...rest
}: SelectorMultipleProps): JSX.Element {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>();
  const debouncedSearch = useDebounce(search, 300);
  const { data, isLoading } = useCustomers(
    { page: 1, search: debouncedSearch },
    {
      select: (data) => {
        return objectsList2NameValueList(
          data.data,
          undefined,
          undefined,
          'id',
          getCustomerName
        );
      },
    }
  );

  return (
    <SelectorMultiple
      {...rest}
      id="customer_id"
      disabled={isLoading}
      onChange={onChange}
      options={data || []}
      value={value}
      onSearch={setSearch}
      label={t('common.customer')}
      placeholder={t('screens.customers.search_customer')}
    />
  );
}

export function CustomerSelector({
  onChange,
  value,
  ...rest
}: SelectorProps<Customer>): JSX.Element {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>();
  const debouncedSearch = useDebounce(search, 300);
  const { data, isLoading } = useCustomers(
    { page: 1, search: debouncedSearch },
    {
      select: (data) => {
        return objectsList2NameValueList(
          data.data,
          'common.all_customers',
          undefined,
          'id',
          getCustomerName
        );
      },
    }
  );

  const handleChange = (_: string, option: NameValue) => {
    onChange(option);
  };

  return (
    <Selector
      {...rest}
      id="customer_id"
      disabled={isLoading}
      onChange={handleChange}
      options={data || []}
      value={value}
      onSearch={setSearch}
      label={t('common.customer')}
      placeholder={t('screens.customers.search_customer')}
    />
  );
}
