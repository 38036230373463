import { useCreatePaymentMethod } from '../checkoutQueries';
import {
  useCancelGroupedInvoice,
  useCreateGroupedInvoice,
} from './debtPortalQueries';
import { useDebtPortalStore } from './useDebtPortalStore';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function usePortalPayment() {
  const { selectedInvoices, goToList } = useDebtPortalStore();
  const { isLoading, isSuccess, error, mutate } = useCreatePaymentMethod();
  const groupedInvoice = useCreateGroupedInvoice();
  const { mutate: cancelInvoice } = useCancelGroupedInvoice();
  const shouldCreateInvoice =
    selectedInvoices.length > 1 && !groupedInvoice.data;
  const [defaultInvoice] = selectedInvoices;
  const amountToPay = selectedInvoices.reduce(
    (prev, curr) => prev + Number(curr.amount),
    0
  );

  const pay = (paymentData: Metadata) => {
    if (shouldCreateInvoice) {
      groupedInvoice.mutate(
        {
          customer_id: defaultInvoice.customer.id,
          invoice_ids: selectedInvoices.map(({ id }) => id),
          collect_methods: {
            // Esto se debe enviar?
            card: defaultInvoice.collect_methods.card.map(({ id }) => id),
          },
        },
        {
          onSuccess: (groupedInvoice) => {
            mutate({
              ...paymentData,
              customer_id: groupedInvoice.customer.id,
              reference_type: 'invoice',
              reference_id: groupedInvoice.id,
              binary_mode: true,
            });
          },
        }
      );
    } else {
      const invoiceToPay = groupedInvoice.data || defaultInvoice;
      mutate({
        ...paymentData,
        customer_id: invoiceToPay.customer.id,
        reference_type: 'invoice',
        reference_id: invoiceToPay.id,
        binary_mode: true,
      });
    }
  };

  // Cancel invoice if created and goes to list
  const cancel = () => {
    // If a grouped invoice was created, cancel it only when the payment failed;
    if (groupedInvoice.data && error) {
      cancelInvoice(groupedInvoice.data.id);
    }
    goToList();
  };

  // useEffect(() => {
  //   const cancelGroupedInvoice = () => {
  //     if (groupedInvoice.data && error) {
  //       cancelInvoice(groupedInvoice.data.id);
  //     }
  //   };

  //   window.addEventListener('beforeunload', cancelGroupedInvoice);
  //   return () => {
  //     window.removeEventListener('beforeunload', cancelGroupedInvoice);
  //   };
  // });

  return {
    pay,
    cancel,
    isSuccess,
    isLoading: isLoading || groupedInvoice.isLoading,
    error: error || groupedInvoice.error,
    amountToPay,
    defaultInvoice,
  };
}
