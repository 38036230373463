import { CellText, H2 } from '@increasecard/typed-components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CheckoutPlan } from '../../../types';
import { formatCurrency } from '../../../utils/currencyUtils';

const Price = styled(H2)`
  color: var(--color-pay-regular);
`;

function usePlanPriceDetail(plan: CheckoutPlan) {
  const { t } = useTranslation();
  let from, amount, detail, label;
  if (plan.type === 'variableflat') {
    const hasAmount = !!Number(plan.amount || null);
    from = hasAmount ? t('common.from') : '';
    amount = hasAmount ? plan.amount : undefined;
    label = hasAmount ? null : t('data.plan.types.variableflat');
  } else if (plan.type === 'flat') {
    amount = plan.amount;
    detail = t('data.plan.amount_type.flat').toLowerCase();
  } else {
    // usage, volume, graduated
    from = t('common.from');
    amount = plan.amount || plan.tiers[0]?.amount;
    detail = t('data.plan.amount_type.per_unit').toLowerCase();

    if (plan.tiers?.length > 0) {
      amount = plan.tiers[0].amount;
      detail = plan.tiers[0].amount_type === 'flat' ? undefined : detail;
    }
  }
  return { from, amount, detail, label };
}

export function PlanPrice({ plan }: { plan: CheckoutPlan }): JSX.Element {
  const { from, amount, detail, label } = usePlanPriceDetail(plan);
  return (
    <div className="plan-price">
      {from ? <CellText>{from}</CellText> : <br />}
      <Price>{label || formatCurrency(amount, plan.currency)}</Price>
      {detail && <CellText>{detail}</CellText>}
    </div>
  );
}
