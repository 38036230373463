import { useTranslation } from 'react-i18next';
import { CellText } from '@increasecard/typed-components';
import {
  cardBrandsIcons,
  ticketBrandIcons,
  CbuBankIcon,
  getPaymentType,
} from '../../utils/paymentUtils';
import { Row } from '../common/Row';
import { Constants } from '../../constants';
import { capitalize } from '../../utils/utils';
import { PaymentMethod } from '../../types/PaymentMethod';
import { DlocalCardBrand, getCardBrandByCode } from './dlocal/dlocalUtils';
import { PaymentWarningButton } from './PaymentWarningButton';

const { CBU, CARD, TICKET } = Constants.PaymentMethods;

function formatNumber(number: string) {
  return '* ' + number.slice(number.length - 4);
}

export interface MethodIconProps {
  type: string;
  paymentBrand?: string;
}
export function MethodIcon({
  type,
  paymentBrand,
}: MethodIconProps): JSX.Element | null {
  switch (type) {
    case CBU: {
      return <CbuBankIcon />;
    }
    case CARD: {
      if (paymentBrand) {
        paymentBrand = paymentBrand?.toLowerCase();
        if (Object.keys(cardBrandsIcons).includes(paymentBrand)) {
          // we got the icon
          const Icon =
            cardBrandsIcons[paymentBrand as keyof typeof cardBrandsIcons];
          return <Icon />;
        } else {
          // we don't have the icon
          // eslint-disable-next-line no-console
          console.log(`Missing card icon for "${paymentBrand}"`);
        }
      }
      // brand not specified or we don't have the icon
      const IconCardGeneric = cardBrandsIcons['creditcard'];
      return <IconCardGeneric />;
    }
    case TICKET: {
      if (paymentBrand) {
        paymentBrand = paymentBrand?.toLowerCase();
        if (Object.keys(ticketBrandIcons).includes(paymentBrand)) {
          // we got the icon
          const Icon =
            ticketBrandIcons[paymentBrand as keyof typeof ticketBrandIcons];
          return <Icon />;
        } else {
          // we don't have the icon
          // eslint-disable-next-line no-console
          console.log(`Missing ticket icon for "${paymentBrand}"`);
        }
      }
      // brand not specified or we don't have the icon
      const IconTicketGeneric = ticketBrandIcons['cash'];
      return <IconTicketGeneric />;
    }
    default: {
      // eslint-disable-next-line no-console
      console.error('Missing PaymentMethodIcon ', type);
      return null;
    }
  }
}

export interface PaymentMethodLabelProps {
  paymentMethod: PaymentMethod | null;
  showFullDescription?: boolean;
  showValidationStatus?: boolean;
  className?: string;
}

export function PaymentMethodLabel({
  paymentMethod,
  showFullDescription,
  showValidationStatus,
  className,
}: PaymentMethodLabelProps): JSX.Element | null {
  const { t } = useTranslation();
  // this is needed because api not always sends payment method ¯\_(ツ)_/¯
  if (!paymentMethod || !paymentMethod.data) return null;
  const { type, subtype, card_brand_name, issuer_name, vendor, ...rest } =
    paymentMethod.data;
  if (!type) return null; // Why is needed? Is really needed?
  const paymentType = getPaymentType(type);
  if (paymentType === undefined) {
    // eslint-disable-next-line no-console
    console.error(
      `cannot get paymentType for payment method with type ${type}`
    );
    return null;
  }
  // TODO: remove since every PM comes with card_brand_name
  // Each collect method produces its own brand key:
  // subtype => subtype of payment for MP: usually 'ticket'
  // card_brand_name => mercadopago
  // vendor => payments_os/payu
  // issuer_name => dlocal, but dlocal uses 2 digits for brands ¬¬
  // decidir => ???
  const paymentBrand =
    subtype ||
    card_brand_name ||
    vendor ||
    (type === 'dlocal'
      ? getCardBrandByCode(issuer_name as DlocalCardBrand)
      : issuer_name);

  const getCardNumber = () => {
    const numbers = [rest.bin, rest.last_four_digits || rest.number];
    const number = numbers.join('**');

    return showFullDescription
      ? `${capitalize(paymentBrand)}: ${number}`
      : number;
  };

  const getText = () => {
    switch (paymentType) {
      case CBU: {
        return showFullDescription
          ? t('common.cbu_number', {
              number: formatNumber(rest.number),
            })
          : formatNumber(rest.number);
      }
      case CARD: {
        return getCardNumber();
      }
      case TICKET: {
        return capitalize(paymentBrand);
      }
      default: {
        return '';
      }
    }
  };

  return (
    <Row
      gap="0.5rem"
      margin="0"
      width="auto"
      display="inline-flex"
      alignItems="center"
      className={className}
    >
      {/* Hack until payments return an complete payment_method object */}
      {showValidationStatus &&
        'validated' in paymentMethod &&
        'id' in paymentMethod && (
          <PaymentWarningButton paymentMethod={paymentMethod} />
        )}
      <MethodIcon type={paymentType} paymentBrand={paymentBrand} />
      <CellText>{getText()}</CellText>
    </Row>
  );
}
