import { useState } from 'react';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { ImportedFile, ImportType } from '../../types/ImportFile';
import { useImportedFiles, useUploadCsvFile } from './importQueries';

const POLLING_INTERVAL = 2000;

export interface UseImport {
  selectFile: (file: File | null) => void;
  uploadFile: (metadata?: Metadata, onSuccess?: VoidFunction) => void;
  selectedFile: File | null;
  isUploading: boolean;
  isProcessing: boolean;
  isProcessed: boolean;
  showImportNotification: boolean;
  jobInfo: ImportedFile | undefined;
  reset: () => void;
}

const fileIsProcessing = (uploadData: ImportedFile | undefined) =>
  ['upload', 'uploaded', 'processing'].includes(uploadData?.status || '');

const fileIsProcessed = (uploadData: ImportedFile | undefined) =>
  ['processed', 'processed_with_errors', 'failed'].includes(
    uploadData?.status || ''
  );

export function useImport(
  type: ImportType,
  onProcessed?: VoidFunction
): UseImport {
  const uploadCsvFile = useUploadCsvFile();
  const importedFile = useImportedFiles<ImportedFile>(
    { type, page: 1 },
    {
      refetchInterval: (data) =>
        fileIsProcessing(data) ? POLLING_INTERVAL : 0,
      select: (data) => data.data[0],
      // This callback is removed in react-query 5
      // find other solution. A mutation maybe?
      onSuccess: (data) =>
        onProcessed && fileIsProcessed(data) && onProcessed(),
    }
  );
  const [showImportNotification, setImportNotification] = useLocalStorage(
    'showImportNotification',
    true
  );
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const isProcessing = fileIsProcessing(importedFile.data);
  const isProcessed = fileIsProcessed(importedFile.data);

  const reset = () => {
    setImportNotification(false);
    uploadCsvFile.reset();
  };

  const uploadFile = (metadata?: Metadata, unUploaded?: VoidFunction) => {
    if (selectedFile) {
      uploadCsvFile.mutate(
        { file: selectedFile, type, metadata },
        {
          onSuccess: () => {
            setImportNotification(true);
            unUploaded && unUploaded();
          },
          onSettled: () => setSelectedFile(null),
        }
      );
    }
  };

  const selectFile = (file: File | null) => {
    reset();
    setSelectedFile(file);
  };

  return {
    selectFile,
    uploadFile,
    selectedFile,
    isUploading: uploadCsvFile.isLoading,
    isProcessing,
    isProcessed,
    showImportNotification,
    jobInfo: importedFile.data,
    reset,
  };
}
