import { Table } from '@increasecard/typed-components';
import { useTranslation } from 'react-i18next';
import { Currency, PlanTier } from '../../types';
import { formatCurrency } from '../../utils/currencyUtils';

export interface TiersTableProps {
  tiers: PlanTier[] | undefined;
  currency: Currency;
}

export function TiersTable({
  tiers,
  currency,
}: TiersTableProps): JSX.Element | null {
  const { t } = useTranslation();
  if (!tiers || tiers.length === 0) return null;
  return (
    <Table>
      <thead>
        <tr>
          <th>{t('data.plan.from_unit')}</th>
          <th>{t('data.plan.to_unit')}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {tiers.map(({ amount, amount_type, maximum, minimum }) => {
          return (
            <tr key={`${minimum}-${maximum}`} className="border">
              <td>{minimum}</td>
              <td>{maximum}</td>
              <td>
                {formatCurrency(amount, currency)}{' '}
                {t(`data.plan.amount_type.${amount_type}`)}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}
