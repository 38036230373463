import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  H2,
  Switch,
  InputText,
  InputNumber,
  Button,
  Grid,
  Checkbox,
} from '@increasecard/typed-components';
import { PricingModelInput } from '../../../components/PricingModelInput';
import { usePlan } from './usePlan';
import { ScreenSeparator } from '../../../components/common/ScreenSeparator';
import { Row } from '../../../components/common/Row';
import { PlanCalculator } from '../../../components/common/PlanCalculator';
import { filterUnchangedKeys } from '../../../utils/apiUtils';
import { useScrollToTopOnMount } from '../../../hooks/useScrollToTopOnMount';
import { ExtrasAggregator } from '../../extras/ExtrasAggregator';
import { mergeExtras } from '../../../utils/extrasUtils';
import { CountryAndCurrencySelector } from '../../../components/common/CountryAndCurrencySelector';
import { CollectMethodSelector } from '../../../components/common/CollectMethodSelector';
import { Column } from '../../../components/common/Column';
import { ApiErrorMessage } from '../../../components/ErrorMessage';
import { DateOfCharge } from './DateOfCharge';
import { Select } from '../../../components/common/SystemSelect';
import { LoadingButton } from '../../../components/common/LoadingButton';
import { GracePeriod } from './GracePeriod';
import { PlanTypeSelector } from './PlanTypeSelector';

function Separator() {
  return <ScreenSeparator margin="30px 0 0 0" />;
}
export function NewPlanForm({
  onSubmit,
  onCancel,
  submitting,
  plan: planToEdit,
  editMode,
  error,
}) {
  const { t } = useTranslation();

  const {
    plan,
    dispatchPlanValue,
    setPricingValues,
    isPlanValid,
    renewForever,
    toggleRenewForever,
    tiersActions,
    setExtras,
    CYCLE_UNIT_OPTIONS,
  } = usePlan(planToEdit);
  useScrollToTopOnMount();

  // UI callbacks
  const handleInputChange = ({ target }) => {
    const value =
      target.type === 'checkbox'
        ? target.checked
        : target.type === 'number'
          ? parseInt(target.value)
          : target.value;

    dispatchPlanValue({ type: target.name, payload: value });
  };

  const submitHandler = () => {
    if (!isPlanValid()) {
      return;
    }
    onSubmit(editMode ? filterUnchangedKeys(plan, planToEdit) : plan);
  };

  // sub renders
  const renderSubmitButtons = () => {
    const buttonLabel = editMode
      ? submitting
        ? t('common.saving')
        : t('common.save')
      : submitting
        ? t('common.creating')
        : t('common.create');
    return (
      <Row marginTop="3rem" gap="1rem">
        <LoadingButton
          isLoading={submitting}
          disabled={submitting || !isPlanValid()}
          buttonType="primary"
          onClick={submitHandler}
        >
          {buttonLabel}
        </LoadingButton>
        <Button buttonType="invisible" onClick={onCancel}>
          {t('common.cancel')}
        </Button>
      </Row>
    );
  };

  return (
    <Grid rowGap={2}>
      <InputText
        className="span-desktop-3 span-mobile-2"
        label={t('screens.new_plan.name')}
        id="name"
        value={plan.name}
        onChange={handleInputChange}
      />
      <InputText
        className="span-desktop-3 span-mobile-2"
        label={t('common.external_id')}
        id="external_id"
        value={plan.external_id}
        onChange={handleInputChange}
      />
      <Select
        id="cycle_unit"
        onChange={handleInputChange}
        options={CYCLE_UNIT_OPTIONS}
        value={plan.cycle_unit}
        className="span-desktop-3 span-mobile-2"
        label={t('data.plan.cycle_unit')}
        disabled={editMode}
      />

      {plan.cycle_unit === 'daily' ? (
        <InputNumber
          className="span-desktop-2 span-mobile-1"
          label={t('data.plan.how_many_days')}
          id="cycle_amount"
          value={plan.cycle_amount}
          onChange={handleInputChange}
          disabled={editMode}
          min={1}
        />
      ) : null}
      <Row
        className="span-desktop-12"
        gap="1rem"
        margin="0"
        flexWrap="wrap"
        alignItems="center"
      >
        <Column width="auto" gap="0.5rem">
          <Row margin="0">
            <Switch
              checked={renewForever}
              onChange={toggleRenewForever}
              id="renewForever"
              label={t('screens.new_plan.renew')}
              disabled={editMode}
            />
          </Row>
          <InputNumber
            label={t('screens.new_plan.duration_cycles')}
            id="duration_cycles"
            value={plan.duration_cycles || ''}
            onChange={handleInputChange}
            disabled={editMode || renewForever}
            min={0}
          />
          <Checkbox
            id="active_until_all_invoices_paid"
            name="active_until_all_invoices_paid"
            label={t('screens.new_plan.active_until_all_invoices_paid', {
              amount: plan.duration_cycles,
            })}
            onChange={handleInputChange}
            checked={plan.active_until_all_invoices_paid}
            disabled={editMode || renewForever}
            size="small"
          />
        </Column>
      </Row>
      <H2>{t('data.plan.pricing_model')}</H2>
      <PlanTypeSelector
        onChange={handleInputChange}
        value={plan.type}
        disabled={editMode}
      />
      <PricingModelInput
        className="span-desktop-5 grid-start-desktop-1"
        type={plan.type}
        amount={plan.amount}
        values={plan.tiers}
        onChange={setPricingValues}
        onBlur={tiersActions.update}
        onAddTier={tiersActions.add}
        onRemoveTier={tiersActions.remove}
      />
      {plan.tiers?.length > 0 && (
        <PlanCalculator
          plan={plan}
          className="span-desktop-5 grid-start-desktop-8"
        />
      )}
      <CountryAndCurrencySelector
        onChange={handleInputChange}
        country={plan.country}
        currency={plan.currency}
        countrySelectClass="span-desktop-3 grid-start-desktop-1"
        currencySelectClass="span-desktop-3 grid-start-desktop-5"
      />
      <CollectMethodSelector
        country={plan.country}
        currency={plan.currency}
        onChange={(type, payload) => dispatchPlanValue({ type, payload })}
        collectMethods={plan.collect_methods}
      />
      <H2>Adicionales</H2>
      <ExtrasAggregator
        country={plan.country}
        currency={plan.currency}
        onListChange={setExtras}
        confirmRemoveMessage={
          editMode
            ? t('screens.edit_plan.remove_extra_confirmation')
            : undefined
        }
        items={mergeExtras(plan)}
      />
      <Separator />
      <H2>Configuracion de cobro</H2>
      <InputNumber
        className="grid-span-desktop-2"
        label={t('data.plan.trial_period_days')}
        id="trial_period_days"
        value={plan.trial_period_days}
        onChange={handleInputChange}
        min={0}
      />
      <DateOfCharge
        isEditing={editMode}
        plan={plan}
        onInputChange={handleInputChange}
        dispatch={dispatchPlanValue}
      />
      <GracePeriod plan={plan} dispatch={dispatchPlanValue} />
      <Separator />
      <H2>Configuración avanzada</H2>
      <Switch
        checked={plan.automatic_subscription_request_approval}
        onChange={handleInputChange}
        id="auto_approve_subscriptions"
        name="automatic_subscription_request_approval"
        label={t('data.plan.automatically_approve_subscriptions')}
      />
      <Switch
        checked={plan.allow_multiple_subscriptions_per_customer}
        onChange={handleInputChange}
        id="enabled_multiple_subscrtiption_to_plan"
        name="allow_multiple_subscriptions_per_customer"
        label={t('data.plan.enabled_multiple_subscrtiption_to_plan')}
      />
      <InputText
        id="success_url"
        label={t('common.success_page')}
        value={plan.success_url}
        onChange={handleInputChange}
        placeholder={t('common.enter_url')}
      />
      <ApiErrorMessage error={error} />
      {renderSubmitButtons()}
    </Grid>
  );
}

NewPlanForm.propTypes = {
  productName: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  submitting: PropTypes.bool,
  plan: PropTypes.object,
  editMode: PropTypes.bool,
  fetching: PropTypes.bool,
  error: PropTypes.object,
};
