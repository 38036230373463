import { useTranslation } from 'react-i18next';
import { InputNumber } from '@increasecard/typed-components';
import { InputCurrency } from '../../../components/common/InputCurrency';
import { getPlanInputType } from '../../../utils/planUtils';
import { FrontPlan } from '../../../types/Plan';
import { ChangeEvent } from 'react';

export interface SubscriptionInputProps {
  className?: string;
  plan: FrontPlan;
  quantity: number | string | null;
  onQuantityChange: (q: number) => void;
  amount: number | string | null;
  onAmountChange: (q: number) => void;
}

export function SubscriptionInput({
  className,
  plan,
  quantity,
  onQuantityChange,
  amount,
  onAmountChange,
}: SubscriptionInputProps): JSX.Element | null {
  const { t } = useTranslation();
  if (!plan.type) {
    return null;
  }
  const askFor = getPlanInputType(plan);
  if (askFor === 'amount') {
    return (
      <InputCurrency
        className={className}
        id="amount"
        size="small"
        value={amount}
        onChange={(value: number) => onAmountChange(value)}
      />
    );
  } else if (askFor === 'quantity') {
    // ask for quantity
    return (
      <InputNumber
        className={className}
        label={t('common.quantity_units')}
        id="quantity"
        value={quantity}
        size="small"
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          onQuantityChange(Number(e.target.value) || 1)
        }
        min={1}
      />
    );
  }
  return null;
}
