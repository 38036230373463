import { isAfter, isBefore, isSameDay } from 'date-fns';
import { useTranslation } from 'react-i18next';

export interface ValidateFunctionParams {
  subscriptionId?: string;
  startDate?: Date;
  endDate?: Date;
  billingDate?: Date;
  minDate?: Date;
}
const prefix = 'validation.dates';

export type ValidateFunction = (
  params: ValidateFunctionParams
) => Promise<{ isValid: boolean; message: string }>;

export function useValidateSubscriptionDateRange(): ValidateFunction {
  const { t } = useTranslation();

  const validate = async ({
    startDate,
    endDate,
    billingDate,
    minDate = new Date(),
  }: ValidateFunctionParams) => {
    if (startDate && endDate && isAfter(startDate, endDate))
      return {
        isValid: false,
        message: t(`${prefix}.start_end`),
      };
    // We already prevent selecting dates before today
    if (startDate && isBefore(startDate, minDate))
      return {
        isValid: false,
        message: t(`${prefix}.start_before_today`),
      };
    if (endDate && isBefore(endDate, minDate))
      return {
        isValid: false,
        message: t(`${prefix}.end_before_today`),
      };
    if (startDate && endDate && isSameDay(startDate, endDate))
      return {
        isValid: false,
        message: t(`${prefix}.end_same_start`),
      };
    if (billingDate && endDate && isBefore(endDate, billingDate))
      return {
        isValid: false,
        message: t(`${prefix}.billing_after_end`),
      };
    if (billingDate && startDate && isBefore(billingDate, startDate))
      return {
        isValid: false,
        message: t(`${prefix}.billing_before_start`),
      };

    return {
      isValid: true,
      message: '',
    };
  };

  return validate;
}
