import { useState, useRef, ChangeEvent } from 'react';
import styled from 'styled-components';
import { InputLabel, useOnClickOutside } from '@increasecard/typed-components';
import { HexColorPicker } from 'react-colorful';

const PickerButton = styled.button<{ isOpen: boolean }>`
  display: flex;
  gap: 10px;
  align-items: center;
  width: 218px;
  height: 40px;
  border: 1px solid
    ${({ theme, isOpen }) =>
      isOpen
        ? theme.colorsNew.increase.light50
        : theme.colorsNew.gray.grayMedium};

  &:focus {
    box-shadow: 0 0 0 4px var(--color-increase-light);
    outline: 0;
  }
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 4px;
  margin-top: 6px;
  input {
    border: none;
    width: 10ch;
  }
`;

const ColorPill = styled.div<{ currentColor: string }>`
  border: none;
  width: 40px;
  height: 40px;
  background-color: ${({ currentColor }) => currentColor};
`;

const PickerWrapper = styled.div`
  display: inline-block;
  position: absolute;
`;

export interface ColorPickerProps {
  label: string;
  color?: string;
  onChange: (colorHex: string) => void;
}

export function ColorPicker({
  label,
  color = '#000000',
  onChange,
}: ColorPickerProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const pickerRef = useRef(null);
  useOnClickOutside(pickerRef, () => setIsOpen(false));

  const togglePickerHandle = () => setIsOpen(!isOpen);

  const handleManualInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    onChange(value.startsWith('#') || !value ? value : `#${value}`);
  };

  return (
    <div ref={pickerRef}>
      <InputLabel>{label}</InputLabel>
      <PickerButton type="button" onClick={togglePickerHandle} isOpen={isOpen}>
        <ColorPill currentColor={color} />
        <input value={color} onChange={handleManualInput} />
      </PickerButton>
      {isOpen && (
        <PickerWrapper>
          <HexColorPicker color={color} onChange={onChange} />
        </PickerWrapper>
      )}
    </div>
  );
}
