import styled from 'styled-components';
import { Pagination } from '@increasecard/typed-components';

const alignments = {
  center: 'center',
  left: 'flex-start',
  right: 'flex-end',
};

const PaginationStyled = styled(Pagination)<{ align: PaginatorAlign }>`
  display: flex;
  justify-content: ${({ align }) => alignments[align]};
  margin-top: 16px;
`;

export type PaginatorAlign = 'left' | 'center' | 'right';

export interface PaginatorProps {
  selected?: number;
  pages?: number;
  onSelectPage: (page: number) => void;
  align?: PaginatorAlign;
}

export function Paginator({
  selected = 1,
  pages = 1,
  onSelectPage,
  align = 'right',
}: PaginatorProps): JSX.Element {
  return (
    <PaginationStyled
      align={align}
      currentPage={selected}
      totalPages={pages}
      onSelectPage={onSelectPage}
    />
  );
}
