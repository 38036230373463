import styled from 'styled-components';
import { H2 } from '@increasecard/typed-components';
import { ChangeEvent, KeyboardEvent, useState } from 'react';
import { SuggestionInput } from './SuggestionInput';
import { TagList } from '../../../../components/common/TagList';
import { Column } from '../../../../components/common/Column';
import { useSearchTags } from '../../subscriptionQueries';
import useDebounce from '../../../../hooks/useDebounce';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  appearance: none;
  background: var(--color-gray-white);
  border: 1px solid var(--color-gray-grayMedium);
  border-radius: 4px;
  outline: none;
  margin: 0;
  padding: 11px;
  width: 100%;
  resize: none;
`;

export interface TagInputProps {
  tags: string[];
  onListChange: (t: string[]) => void;
}

export function TagInput({
  tags = [],
  onListChange,
}: TagInputProps): JSX.Element {
  const [tag, setTag] = useState('');
  const debouncedSearch = useDebounce(tag, 300);
  const searchQuery = useSearchTags(debouncedSearch);

  const handleTypeTag = (e: ChangeEvent<HTMLInputElement>) => {
    setTag(e.target.value);
  };

  const addTag = (tag: string) => {
    // Some sane validation to prevent copypaste errors
    if (tag.length < 200 && !tags.includes(tag)) {
      onListChange([...tags, tag]);
    }
    setTag('');
  };

  const handleAddTag = (e: KeyboardEvent<HTMLInputElement>) => {
    const cleanTag = tag.trim();
    if (e.key === 'Enter' && cleanTag) {
      addTag(cleanTag);
    }
  };

  const handleRemove = (tag: string) => {
    onListChange(tags.filter((t) => t !== tag));
  };

  return (
    <Column gap="1rem">
      <H2>Tags</H2>
      <Wrapper>
        {tags.length > 0 ? (
          <TagList tags={tags} onClose={handleRemove} />
        ) : null}
        <SuggestionInput
          placeholder="Agregar tag"
          onChange={handleTypeTag}
          onKeyDown={handleAddTag}
          onSelectSuggestion={addTag}
          value={tag}
          suggestions={searchQuery.data || []}
        />
      </Wrapper>
    </Column>
  );
}
