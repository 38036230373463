import { CSSProperties } from 'react';
import styled from 'styled-components';
import { AccountBrandingSettings } from '../../types/Account';

const config = {
  filledAngle: 150,
  outlineAngle: 168,
  big: {
    size: 630,
    containerSize: 747, //Calculated from angle
    radius: 108,
  },
  small: {
    size: 250,
    containerSize: 341, //Calculated from angle
    radius: 40,
  },
} as const;

type Scale = 'big' | 'small';

type Variant = 'inline' | 'float';

const SquareOrnament = styled.div<{
  color?: string;
  scale: Scale;
  type: 'filled' | 'outline';
}>`
  ${({ scale, type }) => {
    return `
  width: ${config[scale].size}px;
  height: ${config[scale].size}px;
  border-radius: ${config[scale].radius}px;
  transform: rotate(${
    type === 'filled' ? config.filledAngle : config.outlineAngle
  }deg);
  top: ${(config[scale].containerSize - config[scale].size) / 2}px;
  left: ${(config[scale].containerSize - config[scale].size) / 2}px;
  position: absolute;
`;
  }};
  ${({ color, type }) => {
    return type === 'filled'
      ? `background: ${color || 'var(--color-pay-dark70)'};`
      : `border: 4.5px solid ${color || 'var(--color-pay-accent)'};`;
  }}
`;

export interface OrnamentProps {
  brandingSettings?:
    | AccountBrandingSettings
    | Partial<Pick<AccountBrandingSettings, 'colors'>>;
  variant?: Variant;
  scale?: Scale;
  position?: 'left' | 'right';
  offsetY?: string;
  offsetX?: string;
  style?: CSSProperties;
}
const baseStyle: CSSProperties = {
  overflow: 'hidden',
  zIndex: -1,
  width: '100%',
};
const inlineStyle: CSSProperties = { ...baseStyle, position: 'relative' };
const floatStyle: CSSProperties = {
  ...baseStyle,
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
};

export function Ornament({
  brandingSettings,
  variant = 'inline',
  scale = 'big',
  position = 'right',
  offsetY = '0px',
  offsetX = '0px',
}: OrnamentProps): JSX.Element {
  const width = config[scale].containerSize;
  const halfY = `calc(${config[scale].containerSize}px / -2 + ${offsetY})`;
  const halfX = `calc(${config[scale].containerSize}px / -2 + ${offsetX})`;
  const pos = {
    left: position === 'left' ? halfX : undefined,
    right: position === 'right' ? halfX : undefined,
    bottom: variant === 'float' ? halfY : undefined,
  };

  return (
    <div style={{ width, height: width, position: 'absolute', ...pos }}>
      <SquareOrnament
        color={brandingSettings?.colors?.primary}
        scale={scale}
        type="filled"
      />
      <SquareOrnament
        color={brandingSettings?.colors?.secondary}
        scale={scale}
        type="outline"
      />
    </div>
  );
}

export function BackgroundOrnament({
  brandingSettings,
  variant = 'inline',
  scale = 'big',
  position = 'right',
  offsetY,
  offsetX,
  style,
}: OrnamentProps): JSX.Element {
  const height = variant === 'inline' ? config[scale].containerSize : '100%';
  const width = '100%';
  const baseStyle = variant === 'float' ? floatStyle : inlineStyle;
  const wrapperStyle = { ...baseStyle, width, height, ...style };
  return (
    <div style={wrapperStyle}>
      <Ornament
        brandingSettings={brandingSettings}
        position={position}
        scale={scale}
        variant={variant}
        offsetY={offsetY}
        offsetX={offsetX}
      />
    </div>
  );
}
