import { TFunction } from 'i18next';
import * as Yup from 'yup';
import {
  RequiredString,
  RequiredPercentage,
  RequiredAmount,
} from '../../../schemas/common';

export type OneTimeCostFormValues = Record<
  | 'country'
  | 'name'
  | 'external_id'
  | 'description'
  | 'amount'
  | 'type'
  | 'currency',
  string | null
>;

export function OneTimeCostSchema(
  t: TFunction
): Yup.SchemaOf<Partial<OneTimeCostFormValues> | undefined> {
  return Yup.object({
    country: RequiredString(),
    name: RequiredString(),
    external_id: Yup.string().nullable(),
    description: Yup.string().nullable(),
    amount: Yup.string().when('type', {
      is: 'percentage',
      then: RequiredPercentage(t),
      otherwise: RequiredAmount(t),
    }),
    type: RequiredString(),
    currency: Yup.string().when('type', {
      is: 'flat',
      then: RequiredString(),
      otherwise: Yup.string().nullable().notRequired(),
    }),
  });
}
