import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentAttempt } from '../../components/PaymentAttempt';
import { Constants } from '../../constants';
import { Row } from '../../components/common/Row';
import { Invoice } from '../../types/Invoice';
import { Payment } from '../../types/Payment';
import { ApiErrorMessage } from '../../components/ErrorMessage';
import {
  ColumnDefinition,
  DataTable,
  DataTableProps,
} from '../../components/common/DataTable/DataTable';
import { useInvoices } from './invoiceQueries';
import { useInvoiceActions } from './useInvoiceActions';
import { useUIEventTracker } from '../../hooks/useUIEventTracker';
import { InvoiceActionsMenu } from './components/InvoiceActionsMenu';
import { InvoiceActionsModal } from './components/InvoiceActionsModal';
import { AddPMToInvoiceModal } from '../../components/paymentMethods/AddPMToInvoice';
import { nativeUniqBy } from '../../utils/utils';

interface InvoiceTableProps {
  header?: string;
  tableHeaderButtons?: DataTableProps['tableHeaderButtons'];
  context: string;
  filters: Metadata;
}

export function InvoiceTable({
  header,
  tableHeaderButtons,
  context,
  filters,
}: InvoiceTableProps): JSX.Element {
  const { t } = useTranslation();
  const trackEvent = useUIEventTracker();
  const [selectedInvoices, setSelected] = useState<Invoice[]>([]);
  const [page, setPage] = useState(1);
  const queryFilters = {
    page: page,
    per_page: Constants.LARGE_PAGE_SIZE,
    ...filters,
  };
  const { data, error, isLoading, isFetching } = useInvoices(queryFilters);

  const loadPage = (pageNumber: number) => setPage(pageNumber);

  const trackPaymentDetail = () =>
    trackEvent('view_payment_detail', {
      context: 'payment attempt icon click',
    });

  const renderPayments = (payments: Payment[]) => {
    const paymentAttempts = payments.map((payment) => {
      return (
        <PaymentAttempt
          key={payment.id}
          payment={payment}
          onClick={trackPaymentDetail}
        />
      );
    });
    return <Row flexWrap="nowrap">{paymentAttempts}</Row>;
  };

  const { actions, goToDetails, actionsModal, paymentMethodModal } =
    useInvoiceActions(context);

  const handleSelect = (rows: Invoice[], checked: boolean) => {
    if (checked) {
      setSelected(nativeUniqBy([...selectedInvoices, ...rows], ({ id }) => id));
    } else {
      const newCoso = selectedInvoices.filter(
        (rs: Invoice) => !rows.find((r) => r.id === rs.id)
      );
      setSelected(newCoso);
    }
  };

  const getTableHeader = () => {
    let selected = '';
    if (selectedInvoices.length > 0) {
      selected = `(${t('common.selected', {
        count: selectedInvoices.length,
      })})`;
    }
    if (header) {
      return `${header} ${selected}`;
    }
    const customHeader = `Resultados: ${
      isFetching ? 'cargando' : data?.total_elements
    }`;

    return `${customHeader} ${selected}`;
  };

  if (error) {
    return <ApiErrorMessage error={error} />;
  }

  const hasData = data?.data ? data?.data.length > 0 : false;

  const tableColumns: ColumnDefinition[] = [
    {
      field: 'status',
      type: 'tag',
      tagType: 'invoice',
      headerName: t('common.status'),
      tagLabel: (invoice) => t(`data.invoice.status.${invoice.status}`),
    },
    {
      field: 'kind',
      type: 'string',
      headerName: t('common.type'),
      valueGetter: (invoice) => t(`data.invoice.kinds.${invoice.kind}`),
    },
    {
      field: 'customer',
      type: 'customer',
      headerName: t('common.customer'),
    },
    {
      field: 'created_at',
      type: 'date',
      headerName: t('common.created'),
    },
    {
      field: 'amount',
      type: 'currency',
      headerName: t('common.amount'),
      rightAligned: true,
    },
    {
      field: 'tries',
      headerName: t('data.invoice.tries'),
      valueGetter: (invoice: Invoice) => renderPayments(invoice.payments),
    },
    {
      field: 'paid_at',
      type: 'date',
      headerName: t('common.payment'),
      defaultText: '-',
    },
  ];

  // render
  return (
    <>
      <DataTable
        tableHeader={getTableHeader()}
        tableHeaderButtons={
          <>
            {tableHeaderButtons}
            <InvoiceActionsMenu
              text="Acciones masivas"
              disabled={!hasData}
              invoices={selectedInvoices}
              onCleanUp={() => setSelected([])}
            />
          </>
        }
        tableProps={{ zebra: true, hasData }}
        rowHeight="double"
        columns={tableColumns}
        pageSize={queryFilters.per_page}
        isLoading={isLoading}
        rows={data?.data}
        menuActions={actions}
        onSelect={handleSelect}
        selectedRows={selectedInvoices}
        paginatorProps={{
          pages: data?.total_pages,
          selected: Number(data?.current_page),
          onSelectPage: loadPage,
        }}
        onRowClick={(invoice: Invoice) =>
          goToDetails(invoice.id, 'list row click')
        }
      />
      <InvoiceActionsModal
        actionPayload={actionsModal.data}
        onClose={actionsModal.close}
        onExited={() => actionsModal.setData(null)}
        visible={actionsModal.isOpen}
      />
      <AddPMToInvoiceModal
        visible={paymentMethodModal.isOpen}
        onClose={paymentMethodModal.close}
        invoice={paymentMethodModal.data}
      />
    </>
  );
}
