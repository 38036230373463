import { PaginatedData, PaginationConfig } from '../types/common';
import { Payment } from '../types/Payment';
import { getQueryString } from '../utils/apiUtils';
import { apiFront } from './api';

export async function fetchPaymentById(id: string): Promise<Payment> {
  const response = await apiFront.get(`payments/${id}`);
  return response.data;
}

export async function fetchPayments(
  params: PaginationConfig
): Promise<PaginatedData<Payment>> {
  const qs = getQueryString(params);
  const response = await apiFront.get(`payments${qs}`);
  return response.data;
}

export async function reversePayment(id: string): Promise<Payment> {
  const response = await apiFront.post(`payments/${id}/reverse`);
  return response.data;
}
