import { ReactNode } from 'react';
import { useCountry } from '../../hooks/useCountry';
import { Country } from '../../types/common';

export interface CountryFilterProps {
  children: ReactNode;
  hideFor?: Country[];
  showFor?: Country[];
  currentCountry: Country;
}

export function CountryFilter({
  children,
  hideFor,
  showFor,
  currentCountry,
}: CountryFilterProps): JSX.Element | null {
  const country = useCountry();

  if (
    (hideFor && hideFor.includes(country || currentCountry)) ||
    (showFor && !showFor.includes(country || currentCountry))
  ) {
    return null;
  }
  return <>{children}</>;
}
