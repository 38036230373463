import { useTranslation } from 'react-i18next';
import { H2, Paragraph, Grid } from '@increasecard/typed-components';
import { ApiErrorMessage } from '../../../components/ErrorMessage';
import {
  AdditionalCostForm,
  AdditionalCostFormValues,
} from './AdditionalCostForm';
import { AdditionalCost } from '../../../types/AdditionalCost';
import { useUpdateAditionalCost } from './aditionalCostQueries';

export interface EditAditionalCostScreenProps {
  onClose: () => void;
  additionalCost: AdditionalCost;
}

export function EditAditionalCostScreen({
  onClose,
  additionalCost,
}: EditAditionalCostScreenProps): JSX.Element {
  const { t } = useTranslation();
  const { isLoading, isSuccess, error, mutate } = useUpdateAditionalCost();

  const handleSubmit = (additionalCost: AdditionalCostFormValues) => {
    mutate({ id: additionalCost.id, ...additionalCost });
  };

  const entity = t('common.additional_cost');

  return (
    <Grid rowGap={2}>
      <H2>{t('common.edit_title', { entity })}</H2>
      {isSuccess ? (
        <Paragraph>{t('common.success_edit', { entity })}</Paragraph>
      ) : (
        <AdditionalCostForm
          initialValues={additionalCost}
          onCancel={onClose}
          onSubmit={handleSubmit}
          isLoading={isLoading}
          editMode={true}
        />
      )}
      <ApiErrorMessage error={error} />
    </Grid>
  );
}
