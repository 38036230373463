import styled from 'styled-components';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Label, Spinner } from '@increasecard/typed-components';
import { SMSLogo, WhatsAppLogo, MailLogo } from '@increasecard/icons';
import { ConfirmPhoneModal } from './ConfirmPhoneModal';
import { useModal } from '../../hooks/useModal';
import { Row } from '../common/Row';
import { Column } from '../common/Column';
import { Subscription } from '../../types/Subscription';
import {
  ShareType,
  useShareCheckoutLink,
  CheckoutType,
} from './useShareCheckoutLink';
import { useCustomerById, usePartners } from '../../globalQueries/Queries';
import { useUIEventTracker } from '../../hooks/useUIEventTracker';

const Wrapper = styled.div`
  background-color: var(--color-pay-light);
  border: 1px solid var(--color-pay-regular);
  border-radius: 9px;
  padding: 1rem;
  display: grid;
  grid-template: 1fr / 1fr;
  grid-template-areas:
    'label'
    'items'
    'message';
  .label {
    grid-area: label;
    justify-self: start;
  }
  .items {
    grid-area: items;
    justify-self: center;
  }
  .message {
    grid-area: message;
    justify-self: start;
    color: var(--color-pay-dark50);
    margin-top: 1rem;
  }
`;

// We dont have a sistemized IconButton :(
const IconButton = styled(Button).attrs({ buttonType: 'invisible' })`
  height: auto;
  padding: 0.5rem;
  svg {
    margin: 0;
    height: inherit;
    width: inherit;
  }
`;

const MailLogoBlack = styled(MailLogo)`
  color: var(--color-gray-black);
`;

const MessageButton = ({
  label,
  onClick,
  children,
}: {
  label: string;
  onClick: () => void;
  children: React.ReactChild;
}): JSX.Element => {
  return (
    <Column margin="0" flexGap="0.5rem" alignItems="center">
      <IconButton onClick={onClick}>{children}</IconButton>
      <Label>{label}</Label>
    </Column>
  );
};

export interface CheckoutLinkSharingWidgetProps {
  customerId: string;
  checkoutLink: string;
  checkoutType: CheckoutType;
  subscription?: Subscription;
}

export function CheckoutLinkSharingWidget({
  customerId,
  checkoutLink,
  checkoutType,
  subscription,
}: CheckoutLinkSharingWidgetProps): JSX.Element | null {
  const { t } = useTranslation();
  const confirmAndSendModal = useModal();
  const customer = useCustomerById(customerId);
  const partners = usePartners();
  const [shareType, setShareType] = useState<ShareType | null>(null);
  const { dispatchPhoneMessage, dispatchMail } = useShareCheckoutLink({
    checkoutLink,
    checkoutType,
    customer: customer.data,
    subscription,
  });
  const trackEvent = useUIEventTracker();

  const hasSMSIntegration = partners.data?.data.some(
    (p) => p.type === 'smstart'
  );

  const onShareIconClick = useCallback(
    (shareType: ShareType) => {
      setShareType(shareType);
      trackEvent('ShareCheckoutLink', {
        type: shareType,
      });
      switch (shareType) {
        case 'sms':
        case 'whatsapp':
          confirmAndSendModal.open(shareType);
          break;
        case 'email':
          dispatchMail();
          break;
      }
    },
    [confirmAndSendModal, dispatchMail, trackEvent]
  );

  const handlePhoneConfirm = (phoneNumber: string) => {
    confirmAndSendModal.close();
    if (!shareType) return;
    dispatchPhoneMessage(phoneNumber, shareType);
  };

  const getButtons = useCallback(() => {
    return (
      <>
        {hasSMSIntegration && (
          <MessageButton onClick={() => onShareIconClick('sms')} label="SMS">
            <SMSLogo width="48px" height="48px" />
          </MessageButton>
        )}
        <MessageButton
          onClick={() => onShareIconClick('whatsapp')}
          label="WhatsApp"
        >
          <WhatsAppLogo width="48px" height="48px" />
        </MessageButton>
        <MessageButton onClick={() => onShareIconClick('email')} label="Email">
          <MailLogoBlack width="48px" height="48px" />
        </MessageButton>
      </>
    );
  }, [hasSMSIntegration, onShareIconClick]);

  return (
    <Wrapper>
      <Label className="label">
        {t('components.checkout_link_sharing_widget.share')}
      </Label>
      <Row flexGap="1rem" width="auto" margin="0" className="items">
        {partners.isLoading || customer.isLoading ? (
          <Spinner size={24} />
        ) : (
          getButtons()
        )}
      </Row>
      {customer.data && (
        <ConfirmPhoneModal
          visible={confirmAndSendModal.isOpen}
          onConfirm={handlePhoneConfirm}
          onCancel={confirmAndSendModal.close}
          checkoutType={checkoutType}
          customer={customer.data}
        />
      )}
    </Wrapper>
  );
}
