import { ExportButton } from '../../../components/common/ExportButton';
import { FilterControl } from '../../../components/FilterTagsButton/Filters';
import {
  FilterableFields,
  FilterFormConfig,
} from '../../../components/FilterTagsButton/types';
import { SubscriptionFilters as FiltersType } from '../subscriptionQueries';

const DEFAULT_FILTER_VALUES = {
  customers: [{ name: 'Todos los clientes', value: '*' }],
  payment_method_status: { name: 'Todos los estados', value: '*' },
  status: [{ name: 'Todos los estados', value: '*' }],
  products: [],
  plans: [],
  tags: [],
  next_billing_date_from: undefined,
  next_billing_date_to: undefined,
};

const filterConfig: FilterFormConfig[] = [
  {
    type: 'custom',
    field: 'products',
  },
  {
    type: 'custom',
    field: 'customers',
  },
  {
    type: 'custom',
    field: 'plans',
  },
  {
    type: 'custom',
    field: 'tags',
  },
  {
    type: 'date_range',
    field: 'next_billing_date_from',
    fieldTo: 'next_billing_date_to',
    label: 'common.next_bill',
  },
  {
    type: 'api_filter',
    field: 'status',
    isMultiple: true,
    filterValuesPath: 'subscriptions.statuses',
    label: 'common.status',
  },

  {
    type: 'api_filter',
    field: 'payment_method_status',
    isMultiple: false,
    filterValuesPath: 'payment_methods.statuses',
    label: 'common.payment_method_status',
  },
];

export interface SubscriptionFiltersProps {
  filters: FiltersType;
}
const DATE_FIELDS: FilterableFields[] = [
  'next_billing_date_to',
  'next_billing_date_from',
];

export function SubscriptionFilters({
  filters,
}: SubscriptionFiltersProps): JSX.Element {
  return (
    <FilterControl
      defaultValues={DEFAULT_FILTER_VALUES}
      config={filterConfig}
      rightSideButton={
        <ExportButton path="subscriptions" pathFilters={filters} />
      }
      storageKey="pay_subscription_filters"
      dateFields={DATE_FIELDS}
    />
  );
}
