import {
  MdiLocalOfferIcon,
  WrenchIcon,
  MdiPieChartIcon,
} from '@increasecard/icons';
import { ExtraType } from '../../types/common';

export interface ExtraModalIconProps {
  extraType: ExtraType | null;
}
export function ExtraModalIcon({
  extraType,
}: ExtraModalIconProps): JSX.Element | null {
  switch (extraType) {
    case 'tax':
      return <MdiPieChartIcon height="48" width="48" />;
    case 'discount':
      return <MdiLocalOfferIcon height="48" width="48" />;
    case 'one_time_cost':
      return <WrenchIcon height="48" width="48" />;
    case 'additional_cost':
      return <MdiPieChartIcon height="48" width="48" />;
    default:
      return null;
  }
}
