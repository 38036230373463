import { useTranslation } from 'react-i18next';
import { NameValue } from '../../types/common';
import { SelectorProps, SelectorMultipleProps } from './types';
import { useApiFilters, FilterValuesPath } from './useApiFilters';
import { Selector, SelectorMultiple } from '../common/Selectors';

export interface ApiFilterSelectorProps extends SelectorProps {
  filterValuesPath: FilterValuesPath;
}

export interface ApiFilterSelectorMultipleProps extends SelectorMultipleProps {
  filterValuesPath: FilterValuesPath;
}

export function ApiFilterSelectorMultiple({
  filterValuesPath,
  label,
  ...rest
}: ApiFilterSelectorMultipleProps): JSX.Element {
  const { t } = useTranslation();
  const { options, isFetching } = useApiFilters(filterValuesPath);

  return (
    <SelectorMultiple
      {...rest}
      id={filterValuesPath}
      options={options}
      label={label || t('common.status')}
      disabled={isFetching}
    />
  );
}

export function ApiFilterSelector({
  onChange,
  filterValuesPath,
  label,
  ...rest
}: ApiFilterSelectorProps): JSX.Element {
  const { t } = useTranslation();
  const { options, isFetching } = useApiFilters(filterValuesPath, true);

  const handleChange = (_: string, selected: NameValue) => {
    onChange(selected);
  };

  return (
    <Selector
      {...rest}
      id={filterValuesPath}
      onChange={handleChange}
      options={options}
      label={label || t('common.status')}
      disabled={isFetching}
    />
  );
}
