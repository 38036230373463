import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { OnlyNumbers } from '../../../schemas/common';

interface CbuFormValues {
  number: string;
}

export function CBUSchema(
  t: TFunction
): Yup.SchemaOf<Partial<CbuFormValues> | undefined> {
  return Yup.object({
    number: OnlyNumbers(t)
      .matches(/^(?!000)/, t('validation.cvu'))
      .length(22)
      .required(),
  });
}
