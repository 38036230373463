import {
  Modal,
  ModalContent,
  ModalHeaderIconBox,
} from '@increasecard/typed-components';
import { EditDiscountScreen } from './discounts/EditDiscountScreen';
import { EditOneTimeCostScreen } from './oneTimeCosts/EditOneTimeCostScreen';
import { Tax } from '../../types/Tax';
import { Discount } from '../../types/Discount';
import { OneTimeCost } from '../../types/OneTimeCost';
import { EditTaxScreen } from './taxes/EditTaxScreen';
import { Extra } from '../../types/common';
import { EditAditionalCostScreen } from './aditionalCosts/EditAditionalCostScreen';
import { ExtraModalIcon } from './ExtraModalIcon';

const MODAL_WIDTH = 600;

export interface EditExtraModalProps {
  onClose: () => void;
  visible?: boolean;
  extra: Extra | null;
  tax?: Tax;
  discount?: Discount;
  oneTimeCost?: OneTimeCost;
}

export function EditExtraModal({
  onClose,
  visible,
  extra,
}: EditExtraModalProps): JSX.Element {
  let content;
  if (extra) {
    switch (extra.extraType) {
      case 'discount':
        content = <EditDiscountScreen onClose={onClose} discount={extra} />;
        break;
      case 'tax':
        content = <EditTaxScreen onClose={onClose} tax={extra} />;
        break;
      case 'one_time_cost':
        content = (
          <EditOneTimeCostScreen onClose={onClose} oneTimeCost={extra} />
        );
        break;
      case 'additional_cost':
        content = (
          <EditAditionalCostScreen onClose={onClose} additionalCost={extra} />
        );
        break;
      default:
        break;
    }
  }

  return (
    <Modal
      shadeClosable={false}
      align="center"
      onClose={onClose}
      visible={visible || false}
      width={MODAL_WIDTH.toString()}
      onCancel={() => {
        return;
      }}
      onOk={() => {
        return;
      }}
    >
      {extra && (
        <ModalHeaderIconBox
          colorPalette="pay"
          icon={<ExtraModalIcon extraType={extra.extraType} />}
        />
      )}

      <ModalContent>{content}</ModalContent>
    </Modal>
  );
}
