import {
  H2,
  Table,
  TableWrapper,
  CellText,
} from '@increasecard/typed-components';
import { formatISODate } from '../../utils/dateUtils';
import { useTranslation } from 'react-i18next';
import { getStatusChangeEvents } from '../../utils/utils';
import { StatusChangeEvent } from '../../types/StatusChangeEvent';

export interface EventListProps {
  events: StatusChangeEvent[];
  className?: string;
}

export function EventList({ events, className }: EventListProps): JSX.Element {
  const { t } = useTranslation();
  const filteredEvents = getStatusChangeEvents(events);

  return (
    <TableWrapper className={className}>
      <H2>{t('common.events')}</H2>
      {!filteredEvents?.length && (
        <CellText>{t('components.event_list.no_events_associated')}</CellText>
      )}
      {filteredEvents?.length >= 1 && (
        <Table>
          <tbody>
            {filteredEvents.map((event, key) => {
              return (
                <tr key={key} className="border">
                  <td>
                    <CellText>{event.message}</CellText>
                  </td>
                  <td className="rightAligned">
                    <CellText>{formatISODate(event.created_at)}</CellText>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </TableWrapper>
  );
}
