import { useTranslation } from 'react-i18next';
import { H2, Paragraph, Grid } from '@increasecard/typed-components';
import { useWithCountry } from '../../../utils/countryUtils';
import { ApiErrorMessage } from '../../../components/ErrorMessage';
import { TaxFormValues, TaxForm } from './TaxForm';
import { useCreateTax } from './taxQueries';

const INITIAL_VALUES: TaxFormValues = {
  type: 'percentage',
};

export interface NewTaxScreenProps {
  onClose: () => void;
}

export function NewTaxScreen({ onClose }: NewTaxScreenProps): JSX.Element {
  const { t } = useTranslation();
  const initialValues = useWithCountry(INITIAL_VALUES);
  const { isLoading, error, isSuccess, mutate } = useCreateTax();

  const handleSubmit = (tax: TaxFormValues) => {
    mutate(tax);
  };

  return (
    <Grid rowGap={2}>
      <H2>{t('common.create_title', { entity: t('common.tax') })}</H2>
      {isSuccess ? (
        <Paragraph>
          {t('common.success_create', { entity: t('common.tax') })}
        </Paragraph>
      ) : (
        <TaxForm
          initialValues={initialValues}
          onCancel={onClose}
          onSubmit={handleSubmit}
          isLoading={isLoading}
        />
      )}
      <ApiErrorMessage error={error} />
    </Grid>
  );
}
