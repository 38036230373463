import { useCallback, useEffect, useState } from 'react';
import { fetchUnsupportedPaymentMethods } from '../../services/PaymentMethodsService';
import { CollectMethodApiType } from '../../types/CollectMethod';
import { Country } from '../../types/common';
import { UnsupportedPaymentMethod } from '../../types/PaymentMethod';

export interface UseUnsupportedPaymentMethods {
  data: UnsupportedPaymentMethod[];
  isPaymentMethodSupported: (bin?: string) => {
    supported: boolean;
    error: string | undefined;
  };
}

interface UseUnsupportedPaymentMethodsParams {
  collectMethodType: CollectMethodApiType;
  country?: Country;
  isUniquePayment?: boolean;
}

export function useUnsupportedPaymentMethods({
  collectMethodType,
  country = 'ARG',
  isUniquePayment = false,
}: UseUnsupportedPaymentMethodsParams): UseUnsupportedPaymentMethods {
  const [data, setData] = useState<UnsupportedPaymentMethod[]>([]);
  useEffect(() => {
    fetchUnsupportedPaymentMethods({
      country,
      collect_method_type: collectMethodType,
    }).then((data) => {
      if (isUniquePayment) {
        setData(data.filter((upm) => !upm.only_allows_unique_payments));
      } else {
        setData(data);
      }
    });
  }, [country, collectMethodType, isUniquePayment]);

  const isPaymentMethodSupported = useCallback(
    (bin?: string) => {
      const usp = data.find((upm) => upm.value === bin);
      let error;
      if (usp) {
        error = usp.only_allows_unique_payments
          ? 'unsupported_recurrence'
          : 'unsupported_card_brand';
      }

      return { supported: !usp, error };
    },
    [data]
  );

  return {
    data,
    isPaymentMethodSupported,
  };
}
