import { useTranslation } from 'react-i18next';
import { Grid, H1, Button } from '@increasecard/typed-components';
import { ScreenSeparator } from '../../components/common/ScreenSeparator';
import { GoBackButton } from '../../components/common/GoBackButton';
import { Header } from '../../components/common/Header';
import { useQueryParams } from '../../hooks/useQueryParams';
import { InvoiceForm } from './InvoiceForm';
import { useEffect, useState } from 'react';
import { PathSwitcher } from '../../components/common/PathSwitcher';
import { Row } from '../../components/common/Row';
import { CustomerSelector } from '../customers/CustomerSelector';
import { CustomerForm, CustomerFormValues } from '../customers/CustomerForm';
import { useHistory } from 'react-router';
import { useUIEventTracker } from '../../hooks/useUIEventTracker';
import { useCreateCustomer } from '../customers/customerQueries';

const WizardSteps = {
  createCustomer: 'createCustomer',
  searchCustomer: 'searchCustomer',
  invoice: 'invoice',
};

const prefix = 'screens.new_subscription';

export function NewInvoiceScreen(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const { customer_id } = useQueryParams();
  const [currentStep, setCurrentStep] = useState(
    customer_id ? WizardSteps.invoice : WizardSteps.createCustomer
  );
  const trackEvent = useUIEventTracker('invoice wizard');
  const newCustomer = useCreateCustomer();

  const handleCustomerSelect = (id: string) => {
    history.replace(`/invoices/new?customer_id=${id}`);
  };

  const handleCreateCustomer = (values: CustomerFormValues) => {
    trackEvent('create_customer');
    newCustomer.mutate(values, {
      onSuccess: (customer) => {
        handleCustomerSelect(customer.id);
        setCurrentStep(WizardSteps.invoice);
      },
    });
  };

  const switchPath = (value: boolean) => {
    const stepToSwitch = value
      ? WizardSteps.searchCustomer
      : WizardSteps.createCustomer;
    setCurrentStep(stepToSwitch);
    if (!value) history.push(`/invoices/new`);
  };

  const goToInvoiceList = () => {
    history.replace('/invoices');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  return (
    <>
      {currentStep === WizardSteps.invoice ? (
        <GoBackButton
          onClick={() => setCurrentStep(WizardSteps.createCustomer)}
        />
      ) : (
        <GoBackButton />
      )}
      <Header
        title={t('screens.new_invoice.title')}
        description={t('screens.new_invoice.description')}
      />
      <ScreenSeparator />
      <Grid rowGap={2}>
        {currentStep !== WizardSteps.invoice && (
          <>
            <H1>{t('common.customer')}</H1>
            <PathSwitcher
              style={{ marginBottom: '24px' }}
              optionATitle={t(`${prefix}.customer_option_a`)}
              optionBTitle={t(`${prefix}.customer_option_b`)}
              optionADescription={t(`${prefix}.customer_option_a_desc`)}
              optionBDescription={t(`${prefix}.customer_option_b_desc`)}
              value={currentStep === WizardSteps.searchCustomer}
              onChange={switchPath}
            />
          </>
        )}
        {currentStep === WizardSteps.searchCustomer && (
          <>
            <CustomerSelector
              titleHidden={true}
              initialCustomerId={customer_id}
              onChange={({ id }) => handleCustomerSelect(id)}
            />
            <Row marginTop="2rem" gap="1rem" alignItems="center">
              <Button
                disabled={!customer_id}
                onClick={() => setCurrentStep(WizardSteps.invoice)}
              >
                {t('common.continue')}
              </Button>
              <Button buttonType="invisible" onClick={goToInvoiceList}>
                {t('common.cancel')}
              </Button>
            </Row>
          </>
        )}
        {currentStep === WizardSteps.createCustomer && (
          <CustomerForm
            submitText={t(`${prefix}.create_customer_and_continue`)}
            onSubmit={handleCreateCustomer}
            onCancel={goToInvoiceList}
            isSubmitting={false}
            error={newCustomer.error}
          />
        )}
        {currentStep === WizardSteps.invoice && (
          <InvoiceForm customerId={customer_id} />
        )}
      </Grid>
    </>
  );
}
