import { AuthorizationList } from '@increasecard/increase-core';

export type PayAuthorizedAction =
  | 'approveSubscription'
  | 'createBulkCustomers'
  | 'createBulkSubscriptions'
  | 'createEditCustomer'
  | 'createEditExtra'
  | 'createEditProductPlan'
  | 'createInvoice'
  | 'createSubscription'
  | 'manageBusiness'
  | 'manageCollectMethods'
  | 'manageIntegrations'
  | 'manageInvoices'
  | 'manageNotifications'
  | 'manageSubscriptions'
  | 'refundPayment'
  | 'retryPayment'
  | 'updateBulkSubscriptions'
  | 'viewDashboard'
  | 'viewInvoices'
  | 'viewPayments'
  | 'viewSubscriptions';

export const PayAuthorizations: Record<PayAuthorizedAction, AuthorizationList> =
  {
    approveSubscription: ['approve_pending_subscription'],
    createBulkCustomers: ['create_bulk_customers'],
    createBulkSubscriptions: ['create_bulk_subscriptions'],
    createEditCustomer: ['create_edit_customers'],
    createEditExtra: ['create_edit_additionals'],
    createInvoice: ['create_invoice'],

    // creating a product is only possible creating a plan too
    createEditProductPlan: ['create_edit_products', 'create_edit_plans'],

    createSubscription: ['create_subscription'],
    manageBusiness: ['manage_business'],
    manageCollectMethods: ['manage_collect_methods'],
    manageIntegrations: ['manage_integrations'],
    manageNotifications: ['manage_notifications'],
    manageSubscriptions: ['manage_subscriptions'],

    // there is no "manage_invoices" authorization
    // so we use "create_invoice" authorization (agreed with the product team)
    manageInvoices: ['create_invoice'],

    refundPayment: ['refund_payment'],
    retryPayment: ['retry_payment'],
    updateBulkSubscriptions: ['update_bulk_subscriptions'],
    viewDashboard: ['view_dashboard'],
    viewInvoices: ['view_invoices'],
    viewPayments: ['view_payment_orders'],
    viewSubscriptions: ['view_subscriptions'],
  };
