import { useTranslation } from 'react-i18next';
import { CellText } from '@increasecard/typed-components';
import { CheckoutData, CheckoutDataSetters } from '../../useCheckout';
import { CheckoutSubscription } from '../../../../types/Subscription';
import { SummaryTable } from './SummaryTable';
import { CouponChecker } from '../CouponChecker';
import SimpleSubscriptionSummary from './SimpleSubscriptionSummary';
import { SubscriptionAmountToCharge } from '../SubscriptionAmountToCharge';

export interface SubscriptionSummaryProps {
  subscription: CheckoutSubscription;
  checkoutData?: CheckoutData;
  dataSetters?: CheckoutDataSetters;
  simpleSummary?: boolean;
}

export function SubscriptionSummary({
  checkoutData,
  dataSetters,
  subscription,
  simpleSummary,
}: SubscriptionSummaryProps): JSX.Element | null {
  const { t } = useTranslation();
  const plan = subscription?.plan || checkoutData?.plan;
  if (!plan) return null;

  const getPlanStart = () => {
    const { start_day, start_month, duration_cycles, cycle_unit } = plan;

    const getDuration = (type: 'complete' | 'partial') => {
      return t(`screens.customer_checkout.plan_duration_${type}`, {
        duration_cycles,
        cycle_unit: t(`data.plan.cycle_units.${cycle_unit}_cycles`),
      });
    };

    let message;
    if (start_day) {
      if (start_month) {
        message = t('screens.customer_checkout.plan_start_month', {
          start_day,
          start_month,
        });
      } else {
        message = t('screens.customer_checkout.plan_start_day', {
          start_day,
        });
      }

      if (duration_cycles) {
        message = `${message} ${getDuration('partial')}`;
      }
    } else if (duration_cycles) {
      message = getDuration('complete');
    }
    return <CellText>{message}</CellText>;
  };

  const handleApplyCoupon = (coupon: string | null) => {
    dataSetters?.setCoupon(coupon);
  };

  return (
    <div style={{ marginTop: '1.5rem', width: '100%' }}>
      {getPlanStart()}

      {simpleSummary ? (
        <SimpleSubscriptionSummary
          checkoutData={checkoutData}
          subscription={subscription}
        />
      ) : (
        <>
          <CellText>
            <SubscriptionAmountToCharge subscriptionId={subscription?.id} />
          </CellText>
          <SummaryTable
            checkoutData={checkoutData}
            subscription={subscription}
            summaryType="subscription"
          />
        </>
      )}
      {plan.type === 'variableflat' && (
        <CellText>
          {t('screens.customer_checkout.variable_plan_disclaimer_2')}
        </CellText>
      )}
      {/* Pass plan from checkoutData, since a subscription cant be updated with a coupon */}
      <CouponChecker
        plan={checkoutData?.plan}
        onCouponChange={handleApplyCoupon}
      />
    </div>
  );
}
