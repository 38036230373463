import { useTranslation } from 'react-i18next';
import {
  H2,
  Button,
  Paragraph,
  UniversalPortal,
} from '@increasecard/typed-components';
import MediaQuery from 'react-responsive';
import { Formik } from 'formik';
import { ApiErrorMessage } from '../../components/ErrorMessage';
import { ScreenSeparator } from '../../components/common/ScreenSeparator';
import { Row } from '../../components/common/Row';
import { Column } from '../../components/common/Column';
import { ColorPicker } from '../../components/common/ColorPicker';
import { ImageDropZone } from '../../components/common/ImageDropZone';
import { BackgroundOrnament } from '../../components/common/BackgroundOrnament';
import { Header } from '../../components/common/Header';
import { HelpButton } from '../../components/common/HelpButton';
import {
  BrandingConfig,
  useAccount,
  useUpdateBrandingSettings,
} from '../../globalQueries/Queries';
import { InputTextField } from '../../components/common/FormikFields';
import { BrandingSettingsPartialSchema } from './BrandingSettingsSchema';
import { LoadingButton } from '../../components/common/LoadingButton';
import { CollectionPortalConfig } from './components/CollectionPortalConfig';

const MAX_IMAGE_SIZES = {
  icon: { width: 50, height: 50 },
  logo: { width: 220, height: 50 },
};

const DEFAULT_VALUES: Omit<BrandingConfig, 'shouldCreate'> = {
  colors: {
    primary: '#006975',
    secondary: '#00F2DE',
  },
  customer_success_url: '',
  terms_and_conditions: '',
  contact_email: '',
  shop_name: '',
  logo_url: '',
  logoFile: undefined,
  icon_url: '',
  iconFile: undefined,
  searchable_fields: [],
};

export function BusinessSettings(): JSX.Element {
  const { t } = useTranslation();
  const translations: Metadata = t('screens.business', {
    returnObjects: true,
  });

  const updateBranding = useUpdateBrandingSettings();
  const { isLoading, data, error } = useAccount();

  const saveDisabled = isLoading || updateBranding.isLoading;

  const handleSubmit = (values: Omit<BrandingConfig, 'shouldCreate'>) => {
    updateBranding.mutate({
      ...values,
      shouldCreate: !data?.branding_settings,
    });
  };

  // TODO: Improve this init object to reduce re-renders
  const initialValues = data?.branding_settings
    ? data.branding_settings
    : DEFAULT_VALUES;

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
        validationSchema={BrandingSettingsPartialSchema(t)}
      >
        {({ values, setFieldValue, handleReset, handleSubmit, isValid }) => (
          <form
            onSubmit={handleSubmit}
            style={{ position: 'relative', zIndex: 1 }}
          >
            <Header title={translations.title} />
            <ScreenSeparator />
            <H2>{translations.account_config}</H2>
            <Column flexGap="1rem" maxWidth="686px">
              <InputTextField id="shop_name" label={translations.shop_name} />
              <InputTextField
                id="customer_success_url"
                label={[
                  translations.customer_success_url,
                  <HelpButton
                    key="1"
                    width="14px"
                    height="14px"
                    titleKey={translations.customer_success_url}
                    descriptionKey={translations.success_url_help}
                  />,
                ]}
              />
              <InputTextField
                id="terms_and_conditions"
                label={[
                  translations.t_and_c,
                  <HelpButton
                    key="1"
                    width="14px"
                    height="14px"
                    titleKey={translations.t_and_c}
                    descriptionKey={translations.t_and_c_help}
                  />,
                ]}
              />
            </Column>
            <ScreenSeparator />
            <H2>Canales de soporte</H2>
            <Column flexGap="1rem" maxWidth="686px">
              <InputTextField
                id="contact_email"
                label={translations.support_email}
              />
              <InputTextField
                id="support_url"
                label={translations.support_url}
              />
            </Column>
            <ScreenSeparator />
            <H2>{translations.brand_image}</H2>
            <Paragraph>{translations.brand_image_description}</Paragraph>

            <Row
              marginBottom="3rem"
              marginTop="1.5rem"
              gap="2rem"
              alignItems="flex-start"
              flexWrap="wrap"
            >
              <ImageDropZone
                label={t('common.logo')}
                imageUrl={values.logo_url}
                imageFile={values.logoFile}
                imageSize={MAX_IMAGE_SIZES.logo}
                width="250px"
                height="118px"
                onDropAccepted={(logo: File) => setFieldValue('logoFile', logo)}
                clearImage={() => setFieldValue('logoFile', undefined)}
              />
              <ImageDropZone
                label={t('common.icon')}
                imageUrl={values.icon_url}
                imageFile={values.iconFile}
                imageSize={MAX_IMAGE_SIZES.icon}
                width="150px"
                height="118px"
                onDropAccepted={(icon: File) => setFieldValue('iconFile', icon)}
                clearImage={() => setFieldValue('iconFile', undefined)}
              />
              <Column width="auto" marginBottom="0" marginTop="0" gap="0.5rem">
                <ColorPicker
                  label={translations.primary_color}
                  color={values.colors?.primary}
                  onChange={(newColor) =>
                    setFieldValue('colors.primary', newColor)
                  }
                />
                <ColorPicker
                  label={translations.secondary_color}
                  color={values.colors?.secondary}
                  onChange={(newColor) =>
                    setFieldValue('colors.secondary', newColor)
                  }
                />
              </Column>
              <MediaQuery minWidth={1200}>
                <UniversalPortal selector="#main-layout">
                  <BackgroundOrnament
                    brandingSettings={{
                      colors: values.colors,
                    }}
                    variant="float"
                    position="right"
                    scale="big"
                    style={{ zIndex: 0, bottom: '50%' }}
                  />
                </UniversalPortal>
              </MediaQuery>
            </Row>
            <ScreenSeparator />
            <CollectionPortalConfig accountId={data?.id || ''} />
            <ApiErrorMessage error={error || updateBranding.error} />
            <Row gap="1rem" marginTop="32px">
              <LoadingButton
                isLoading={updateBranding.isLoading}
                disabled={saveDisabled || !isValid}
                type="submit"
              >
                {t('common.save')}
              </LoadingButton>
              <Button
                buttonType="invisible"
                disabled={saveDisabled}
                onClick={handleReset}
              >
                {t('common.cancel')}
              </Button>
            </Row>
          </form>
        )}
      </Formik>
    </>
  );
}
