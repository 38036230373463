import { Pagination, Paragraph, Table } from '@increasecard/typed-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  ImportLineError,
  ImportProcessingErrors,
  ImportValidationError,
} from '../../types/ImportFile';

const PAGE_SIZE = 10;

export function ProcessingErrorsTable({
  lineErrors,
}: {
  lineErrors: ImportProcessingErrors['line_errors'];
}): JSX.Element {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const currentPageData = lineErrors.slice(
    (page - 1) * PAGE_SIZE,
    page * PAGE_SIZE
  );

  return (
    <>
      <Paragraph style={{ margin: '1rem 0' }}>
        {t('screens.import.error_description')}
      </Paragraph>
      <Table zebra>
        <thead>
          <tr>
            <th style={{ width: '10em' }}>Línea</th>
            <th>
              Campo <strong>[Error]</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          {currentPageData.map((line_error) => (
            <LineErrorRow key={line_error.line} line_error={line_error} />
          ))}
        </tbody>
      </Table>
      <div
        style={{
          paddingTop: '16px',
        }}
      >
        <Pagination
          currentPage={page}
          onSelectPage={setPage}
          style={{
            justifyContent: 'flex-end',
          }}
          totalPages={Math.ceil(lineErrors.length / PAGE_SIZE) || 1}
        />
      </div>
    </>
  );
}

interface LineErrorRowProps {
  line_error: ImportLineError;
}

function LineErrorRow({ line_error }: LineErrorRowProps) {
  const { t } = useTranslation();
  const { line, code, validation_errors } = line_error;
  return (
    <tr style={{ textAlign: 'initial' }}>
      <td>
        <strong>Línea {line + 1}</strong>
      </td>
      <td>
        {code === 'validation_errors' ? (
          <ValidationErrors validation_errors={validation_errors} />
        ) : (
          <strong>
            {t([
              `screens.import.line_or_validation_error.${code}`,
              'common.invalid',
            ])}
          </strong>
        )}
      </td>
    </tr>
  );
}

const ValidationErrorRow = styled.span`
  :not(:first-child) {
    ::before {
      content: ' - ';
    }
  }
`;

interface ValidationErrorsProps {
  validation_errors: Record<string, ImportValidationError[]>;
}

function ValidationErrors({ validation_errors }: ValidationErrorsProps) {
  const { t } = useTranslation();
  return (
    <div>
      {Object.entries(validation_errors).map(([attribute, errors]) => (
        <ValidationErrorRow key={attribute}>
          {attribute}{' '}
          <strong>
            [
            {errors
              .map(({ type, message, ...metadata }: ImportValidationError) =>
                t(
                  [
                    `screens.import.line_or_validation_error.${message || type}`,
                    'common.invalid',
                  ],
                  metadata
                )
              )
              .join(', ')}
            ]
          </strong>
        </ValidationErrorRow>
      ))}
    </div>
  );
}
