import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import { formatISODate } from './utils/dateUtils';
//import { reportError } from './utils/errorTracker';

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: 'es',
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    fallbackLng: 'es',
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // We cant use this until every translation is loaded with useTranslation instead of i18n.t
    //saveMissing: true,
    // missingKeyHandler: (lngs, ns, key) => {
    //   reportError(`Translate key not found: ${key} `, { lngs });
    // },
    react: {
      useSuspense: true,
    },
  });
i18n.services.formatter?.add('lowercase', (value) => value.toLowerCase());
i18n.services.formatter?.add('string_datetime', (value) =>
  formatISODate(value)
);
export default i18n;
