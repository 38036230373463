import {
  UseMutationResult,
  useQueries,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import {
  changePlanStatus,
  createPlan,
  fetchPlanById,
  fetchPlans,
  fetchLastCycleStats,
  simulateNextBillingDate,
  SimulateNextBillingDateParams,
  updatePlan,
  updatePrices,
  lastCycleBulkRetry,
  getActiveSubscriptions,
  getAcumulatedAmount,
  getCollectibility,
} from '../../services/PlanService';
import { PaginatedData } from '../../types/common';
import { NewApiError } from '../../types/NewApiError';
import {
  FrontPlan,
  LastCycleStats,
  PlanCreationParams,
  PlanUpdateParams,
} from '../../types/Plan';
import { formatISODate } from '../../utils/dateUtils';
import {
  getPaginatedQueriesData,
  queryKeyFactory,
  useMutationWithInvalidation,
} from '../../utils/queryUtils';

export const planQueryKeys = queryKeyFactory('plan');

export const usePlans = <T = PaginatedData<FrontPlan>>(
  params: Metadata,
  options?: Pick<
    UseQueryOptions<PaginatedData<FrontPlan>, NewApiError, T>,
    'select'
  >
): UseQueryResult<T, NewApiError> => {
  return useQuery({
    queryKey: planQueryKeys.list(params),
    queryFn: () => fetchPlans(params),
    enabled: !!params.page,
    keepPreviousData: true,
    select: options?.select,
  });
};

export const usePlanById = (
  id?: string
): UseQueryResult<FrontPlan, NewApiError> => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: planQueryKeys.detail(id),
    queryFn: () => fetchPlanById(id),
    enabled: !!id,
    initialData: () => {
      return getPaginatedQueriesData<FrontPlan>(
        queryClient,
        planQueryKeys.lists()
      )?.find((c) => c.id === id);
    },
  });
};

export const useCreatePlan = (): UseMutationResult<
  FrontPlan,
  NewApiError,
  PlanCreationParams
> => {
  return useMutationWithInvalidation(createPlan, planQueryKeys.lists());
};

export const useUpdatePlan = (): UseMutationResult<
  FrontPlan,
  NewApiError,
  PlanUpdateParams
> => {
  return useMutationWithInvalidation(updatePlan, planQueryKeys.all);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useChangePlanStatus = () => {
  const plans = useMutationWithInvalidation(
    changePlanStatus,
    planQueryKeys.all
  );
  const enablePlan = (id: string) => plans.mutate({ id, action: 'enable' });
  const disablePlan = (id: string) => plans.mutate({ id, action: 'disable' });
  return {
    ...plans,
    enablePlan,
    disablePlan,
  };
};

export const useNextPlanBillingDate = ({
  planId,
  startDay,
  startMonth,
}: SimulateNextBillingDateParams): UseQueryResult<
  { date: Date },
  NewApiError
> => {
  return useQuery(
    ['nextPlanBillingDate', planId, startDay, startMonth],
    async () => {
      const response = await simulateNextBillingDate({
        planId,
        startDay,
        startMonth,
      });
      return formatISODate(response.date);
    },
    {
      enabled: !!(planId && startDay),
    }
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useChangePlanPrice = () => {
  return useMutationWithInvalidation(updatePrices, planQueryKeys.all);
};

export const useLastCycleStats = (
  planId: string
): UseQueryResult<LastCycleStats, NewApiError> => {
  return useQuery({
    queryKey: ['plan', 'last_cycle_stats', planId],
    queryFn: () => fetchLastCycleStats(planId),
  });
};

export const usePlanBulkRetry = (): UseMutationResult<
  LastCycleStats,
  NewApiError,
  string
> => {
  return useMutationWithInvalidation(lastCycleBulkRetry, [
    'plan',
    'last_cycle_stats',
  ]);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const usePlanStats = (planId: string) => {
  return useQueries({
    queries: [
      {
        queryKey: ['plan', 'active_subs', planId],
        queryFn: () => getActiveSubscriptions(planId),
      },
      {
        queryKey: ['plan', 'total_amount', planId],
        queryFn: () => getAcumulatedAmount(planId),
      },
      {
        queryKey: ['plan', 'collectivity', planId],
        queryFn: () => getCollectibility(planId),
      },
    ],
  });
};
