import {
  Modal,
  ModalContent,
  ModalHeaderIconBox,
} from '@increasecard/typed-components';
import { NewDiscountScreen } from './discounts/NewDiscountScreen';
import { NewTaxScreen } from './taxes/NewTaxScreen';
import { NewOneTimeCostScreen } from './oneTimeCosts/NewOneTimeCostScreen';
import { ExtraType } from '../../types/common';
import { ExtraModalIcon } from './ExtraModalIcon';
import { NewAditionalCostScreen } from './aditionalCosts/NewAditionalCostScreen';

const MODAL_WIDTH = 600;

export interface NewExtraModalProps {
  onClose: () => void;
  onExited?: () => void;
  visible?: boolean;
  extraType: ExtraType | null;
}

export function NewExtraModal({
  onClose,
  onExited,
  visible,
  extraType,
}: NewExtraModalProps): JSX.Element {
  return (
    <Modal
      shadeClosable={false}
      align="center"
      onClose={onClose}
      transitionProps={{
        onExited,
      }}
      visible={visible || false}
      width={MODAL_WIDTH.toString()}
      onCancel={() => {
        return;
      }}
      onOk={() => {
        return;
      }}
    >
      <ModalHeaderIconBox
        colorPalette="pay"
        icon={<ExtraModalIcon extraType={extraType} />}
      />
      <ModalContent>
        {extraType === 'tax' && <NewTaxScreen onClose={onClose} />}
        {extraType === 'discount' && <NewDiscountScreen onClose={onClose} />}
        {extraType === 'one_time_cost' && (
          <NewOneTimeCostScreen onClose={onClose} />
        )}
        {extraType === 'additional_cost' && (
          <NewAditionalCostScreen onClose={onClose} />
        )}
      </ModalContent>
    </Modal>
  );
}
