import {
  Select,
  SelectBox,
  SelectBoxMultiple,
  SelectMultiple,
  SelectMultipleProps,
  SelectProps,
} from '@increasecard/typed-components';

export type SelectType = 'dropdown' | 'box';

export interface SelectorProps extends SelectProps {
  selectType?: SelectType;
}

export function Selector({
  selectType = 'dropdown',
  ...rest
}: SelectorProps): JSX.Element {
  switch (selectType) {
    case 'dropdown':
      return <Select {...rest} />;
    case 'box':
      return <SelectBox {...rest} />;
  }
}

export interface SelectorMultipleProps extends SelectMultipleProps {
  selectType?: SelectType;
}

export function SelectorMultiple({
  selectType = 'dropdown',
  ...rest
}: SelectorMultipleProps): JSX.Element {
  switch (selectType) {
    case 'dropdown':
      return <SelectMultiple {...rest} />;
    case 'box':
      return <SelectBoxMultiple {...rest} />;
  }
}
