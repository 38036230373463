import { objectsList2NameValueList } from '../utils/utils';
import { capitalize } from '../utils/utils';
import { NameValue } from '../types/common';
import {
  CollectMethodPaymentType,
  CollectMethod,
} from '../types/CollectMethod';
import { useCollectMethods } from '../globalQueries/Queries';
import { groupCollectMethods, isDefault } from '../utils/collectMethodsUtils';

const getCategoryOptions = (collectMethods: CollectMethod[]) => {
  return objectsList2NameValueList(
    collectMethods,
    'common.not_enabled',
    undefined,
    undefined,
    (cm: CollectMethod) => capitalize(cm.name)
  );
};

const buildCmOptions = (cmList: CollectMethod[]) => {
  const groupedCollectMethods = groupCollectMethods(cmList);

  const collectMethodOptions = {
    card: getCategoryOptions(groupedCollectMethods.card),
    cbu: getCategoryOptions(groupedCollectMethods.cbu),
    ticket: getCategoryOptions(groupedCollectMethods.ticket),
  };

  return {
    groupedCollectMethods,
    collectMethodOptions,
  };
};

type GroupedCollectMethodOptions = Record<
  CollectMethodPaymentType,
  NameValue[]
>;

const defaultOptions = {
  card: [],
  cbu: [],
  ticket: [],
};
export interface UseCollectMethodOptions {
  collectMethodOptions: GroupedCollectMethodOptions;
  getSelectedOptions: (
    type: string,
    value: string
  ) => CollectMethod[] | undefined;
}

export function useCollectMethodsOptions(
  country?: string,
  currency?: string
): UseCollectMethodOptions {
  const { data } = useCollectMethods(
    {
      status: 'enabled',
      country,
      currency,
    },
    {
      select: buildCmOptions,
    }
  );

  const getSelectedOptions = (type: string, value: string) => {
    if (value === '*') {
      return [];
    }
    // TODO(ELIAS): improve his type
    return data?.groupedCollectMethods[type as CollectMethodPaymentType].filter(
      (cm) => cm.id === value
    );
  };

  return {
    collectMethodOptions: data?.collectMethodOptions || defaultOptions,
    getSelectedOptions,
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useDefaultCollectMethods() {
  return useCollectMethods(
    {
      status: 'enabled',
    },
    {
      select: (data) => {
        return groupCollectMethods(data.filter(isDefault));
      },
    }
  );
}
