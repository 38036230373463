import { useState } from 'react';
import { NameValue } from '../../types/common';
import { useSearchTags } from '../../features/subscription/subscriptionQueries';
import { SelectorMultiple, SelectorMultipleProps } from '../common/Selectors';

export interface SubscriptionTagSelectorProps {
  onChange: (selection: NameValue[]) => void;
  selection: string[] | undefined;
  id: string;
  selectType: SelectorMultipleProps['selectType'];
}

export function SubscriptionTagSelector({
  id,
  onChange,
  selection,
  selectType,
}: SubscriptionTagSelectorProps): JSX.Element {
  const [search, setSearch] = useState('');
  const { data } = useSearchTags(search);

  const mapToNameValue = (list: string[]) => {
    return list.map((t) => ({ name: t, value: t }));
  };

  const tagOptions = data ? mapToNameValue(data) : [];

  const handleSearch = (value: string) => {
    setSearch(value);
  };
  const handleSelectionChange = (selection: NameValue[]) => {
    onChange(selection);
  };

  return (
    <SelectorMultiple
      id={id}
      selectType={selectType}
      hasSearch
      onSearch={handleSearch}
      onChange={handleSelectionChange}
      label="Tags"
      options={tagOptions}
      value={selection || []}
      size="small"
    />
  );
}
