import { AutorenewIcon } from '@increasecard/icons';
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalHeaderIconBox,
  Spinner,
} from '@increasecard/typed-components';
import { useTranslation } from 'react-i18next';
import { ApiErrorMessage } from '../../components/ErrorMessage';
import { Invoice } from '../../types';
import { useRetryInvoicePayment } from '../invoice/invoiceQueries';
const RETRY_MODAL_WIDTH = '388';

export interface RetryPaymentModalProps {
  onClose: VoidFunction;
  visible: boolean;
  invoices: Invoice[] | null;
}

export function RetryPaymentModal({
  onClose,
  visible,
  invoices,
}: RetryPaymentModalProps): JSX.Element {
  const { t } = useTranslation();
  const { isIdle, isLoading, isSuccess, isError, error, reset, mutate } =
    useRetryInvoicePayment();

  const handleConfirm = () => {
    if (invoices) {
      mutate(invoices[0].id);
    }
  };

  const handleClose = () => {
    onClose();
    reset();
  };

  return (
    <Modal
      align="left"
      onClose={handleClose}
      visible={visible}
      width={RETRY_MODAL_WIDTH}
      onOk={() => {
        return false;
      }}
      onCancel={() => {
        return false;
      }}
      shadeClosable={false}
    >
      <ModalHeaderIconBox
        colorPalette="pay"
        icon={<AutorenewIcon height="48" width="48" />}
      />
      <ModalHeader align="center">{t('common.retry_payment')}</ModalHeader>
      <ModalContent align="center">
        {isIdle && t('screens.payments.confirm_retry')}
        {isLoading && <Spinner size={24} />}
        {isSuccess && t('screens.payments.retry_success')}
        <ApiErrorMessage error={error} centered />
      </ModalContent>
      <ModalFooter align="center">
        {isError || isSuccess ? (
          <Button onClick={handleClose} buttonType="primary">
            {t('common.close')}
          </Button>
        ) : (
          <>
            <Button
              data-testid="cancel-button"
              onClick={handleClose}
              buttonType="invisible"
              disabled={isLoading}
            >
              {t('common.cancel')}
            </Button>
            <Button
              data-testid="confirm-button"
              onClick={handleConfirm}
              buttonType="primary"
              disabled={isLoading}
            >
              {t('screens.payments.retry_label')}
            </Button>
          </>
        )}
      </ModalFooter>
    </Modal>
  );
}
