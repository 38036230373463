import { TFunction } from 'i18next';
import { reportError } from '../utils/errorTracker';

export interface NewApiErrorFields {
  code: string;
  message: string;
  metadata: Metadata;
  source: Metadata;
  entityCreated?: Metadata;
}

export class NewApiError {
  public code: string;
  public message: string;
  public metadata: Metadata;
  public source: Metadata;

  constructor({ code, message, metadata, source }: NewApiErrorFields) {
    this.code = code;
    this.message = message;
    this.metadata = metadata;
    this.source = source;
  }

  public getUserMessage(t: TFunction): string {
    return t(`api:errors.${this.code}`);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static fromAPI(error: unknown): NewApiError {
    /*
    Check error response, expected format is:
    {
      code: INTERNAL_ERROR_NAME, (required, e.g USER_CAN_NOT_EDIT_ANOTHER_USER)
      message: "Nice description" (required)
      metadata: null || { ... (optional keys) }
    }
    */
    if (
      error &&
      typeof error === 'object' &&
      Object.prototype.hasOwnProperty.call(error, 'code')
    ) {
      // expected format
      const { code, message, metadata } = error as NewApiError;
      if (code === 'INTERNAL_ERROR') {
        // report internal server error
        reportError('API - INTERNAL_ERROR', error, 'critical');
      }
      return new NewApiError({
        code,
        message,
        metadata,
        source: error,
      });
    }
    // unexpected erorr format
    return new NewApiError({
      code: 'unexpected_error',
      message: 'Ha ocurrido un error inesperado',
      metadata: undefined,
      source: error,
    });
  }
}
