import {
  DebtInvoiceParams,
  Invoice,
  InvoiceBulkActionPayload,
} from '../types/Invoice';
import { PaginationConfig, PaginatedData } from '../types/common';
import { apiFront } from './api';
import { getQueryString } from '../utils/apiUtils';
import { InvoiceFormValues } from '../features/invoice/InvoiceSchema';

export async function fetchInvoices(
  params: PaginationConfig
): Promise<PaginatedData<Invoice>> {
  const paramsQueryString = getQueryString(params);
  const response = await apiFront.get(`invoices${paramsQueryString}`);
  return response.data;
}

export async function createInvoice(
  invoiceParams: InvoiceFormValues
): Promise<Invoice> {
  const response = await apiFront.post(`invoices/`, invoiceParams);
  return response.data;
}

export async function cancelInvoice(id: string): Promise<undefined> {
  const response = await apiFront.patch(`invoices/${id}/cancel`);
  return response.data;
}

export interface SetAsPaidParams {
  id: string;
  message?: string;
}

export async function setInvoiceAsPaid({
  id,
  message,
}: SetAsPaidParams): Promise<void> {
  const response = await apiFront.post(`invoices/${id}/manual_paid`, {
    message,
  });
  return response.data;
}

export async function retryInvoicePayment(id: string): Promise<Invoice> {
  const response = await apiFront.post(`invoices/${id}/retry`);
  return response.data;
}

export async function fetchInvoiceById(id: string): Promise<Invoice> {
  const response = await apiFront.get(`invoices/${id}`);
  return response.data;
}

export interface InvoiceUpdateParams {
  id: string;
  latest_payment_method_id: string;
}

export async function updateInvoice({
  id,
  ...params
}: InvoiceUpdateParams): Promise<Invoice> {
  const response = await apiFront.put(`invoices/${id}`, params);
  return response.data;
}

export async function bulkActions(
  params: InvoiceBulkActionPayload
): Promise<undefined> {
  const response = await apiFront.post(`invoices/bulk_action`, params);
  return response.data;
}

interface SubDebtParams extends DebtInvoiceParams {
  subscription_id: string;
}

interface CustomerDebtParams extends DebtInvoiceParams {
  customer_id: string;
}
export type DebtInvoiceCreationParams = SubDebtParams | CustomerDebtParams;

export async function createDebtInvoice(
  params: DebtInvoiceCreationParams
): Promise<Invoice> {
  if ('subscription_id' in params) {
    const response = await apiFront.post(
      `subscriptions/${params.subscription_id}/debt_invoice`,
      params
    );
    return response.data;
  }
  // The intention is to just use this endpoint to create debt invoices
  const response = await apiFront.post(`invoices/debt_invoice`, {
    ...params,
  });
  return response.data;
}
