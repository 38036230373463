import {
  ModalContent,
  Paragraph,
  Checkbox,
} from '@increasecard/typed-components';
import { Trans, useTranslation } from 'react-i18next';
import { Subscription } from '../../../types/Subscription';
import { MassiveActionResults } from './MassiveActionResults';
import { useCancelSubscriptions } from '../subscriptionQueries';
import { MassiveActionFooter } from './MassiveActionsFooter';
import { ChangeEvent, useState } from 'react';
import styled from 'styled-components';

export interface CancelModalContentProps {
  onClose: VoidFunction;
  onSuccess?: VoidFunction;
  subscription: Subscription[];
}

interface CancelMessageProps {
  subscription: Subscription[];
}

function CancelMessage({ subscription }: CancelMessageProps) {
  const { t } = useTranslation();
  const isSingleAction = subscription.length === 1;

  return (
    <div style={{ marginBottom: '1rem' }}>
      <Paragraph>
        {t('screens.subscriptions.cancel.subtitle', {
          count: subscription.length,
        })}
      </Paragraph>
      {isSingleAction ? (
        <Trans
          i18nKey="screens.subscriptions.cancel.subtitle2"
          values={{
            first_name: subscription[0].customer.first_name,
            last_name: subscription[0].customer.last_name,
            product: subscription[0].product.name,
            plan: subscription[0].plan.name,
          }}
        />
      ) : null}
    </div>
  );
}

const CustomCheckbox = styled(Checkbox)`
  display: inline-flex;
`;

export function CancelModalContent({
  onClose,
  onSuccess,
  subscription,
}: CancelModalContentProps): JSX.Element | null {
  const { t } = useTranslation();

  const { mutate, error, isError, isSuccess, isLoading } =
    useCancelSubscriptions();

  const [stopRetries, setStopRetries] = useState(false);

  const handlePause = () =>
    mutate(
      {
        subscriptions: subscription,
        stopRetries,
      },
      {
        onSuccess,
      }
    );

  const handleStopRetries = (e: ChangeEvent<HTMLInputElement>) => {
    setStopRetries(e.target.checked);
  };

  if (!subscription) return null;

  return (
    <>
      <ModalContent align="center">
        {!isError && !isSuccess && (
          <>
            <CancelMessage subscription={subscription} />
            <CustomCheckbox
              id="stopRetries"
              label="Detener reintentos pendientes"
              onChange={handleStopRetries}
              checked={stopRetries}
            />
          </>
        )}
        <MassiveActionResults
          subscriptions={subscription}
          error={error}
          isSuccess={isSuccess}
          actionType="cancel"
        />
      </ModalContent>
      <MassiveActionFooter
        onClose={onClose}
        onConfirm={handlePause}
        isLoading={isLoading}
        isError={isError}
        isSuccess={isSuccess}
        confirmLabel={t('screens.subscriptions.cancel.confirm', {
          count: subscription.length,
        })}
      />
    </>
  );
}
