import { useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { H1, InputText, Grid } from '@increasecard/typed-components';
import { GoBackButton } from '../../components/common/GoBackButton';
import { ScreenSeparator } from '../../components/common/ScreenSeparator';
import { NewPlanForm } from './components/NewPlanForm';
import { useCreateProductAndPlan } from './productQueries';
import { MdiCheckCircleIcon } from '@increasecard/icons';
import { ConfirmModal } from '../../components/common/ConfirmModal';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PlanFormValues = any;

export function NewProductScreen(): JSX.Element {
  const { t } = useTranslation();
  const { error, isLoading, isSuccess, mutate } = useCreateProductAndPlan();
  const history = useHistory();

  // state
  const [name, setName] = useState('');

  const onProductNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const isProductValid = () => {
    return name && name.trim() !== '';
  };

  const handleCreate = (plan: PlanFormValues) => {
    if (!isProductValid()) {
      return;
    }
    mutate({ name, plan });
  };

  const goToProducts = () => {
    history.push('/products');
  };

  const goToPlans = () => {
    history.push('/plans');
  };

  return (
    <>
      <Grid rowGap={2}>
        <GoBackButton />
        <H1>{t('screens.new_product.title')}</H1>
        <InputText
          className="span-desktop-4"
          label={t('screens.new_product.name')}
          id="productName"
          value={name}
          onChange={onProductNameChange}
        />
        <ScreenSeparator />
      </Grid>
      <NewPlanForm
        onSubmit={handleCreate}
        onCancel={goToProducts}
        submitting={isLoading}
        error={error}
      />
      <ConfirmModal
        headerIcon={<MdiCheckCircleIcon width="48" height="48" />}
        visible={isSuccess}
        header={t(`screens.new_product.success`)}
        confirmLabel={t('common.accept')}
        onConfirm={goToPlans}
        onClose={goToPlans}
      />
    </>
  );
}
