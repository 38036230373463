import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Paragraph } from '@increasecard/typed-components';
import { useModal } from '../../../hooks/useModal';
import { getCheckoutSummary } from '../checkoutUtils';
import { ModalDrawer } from '../../../components/common/ModalDrawer';
import { CheckoutSummary, CheckoutSummaryProps } from './CheckoutSummary';
import { formatCurrency } from '../../../utils/currencyUtils';
import { useSummary } from '../checkoutQueries';
import { useCheckoutParams } from '../useCheckoutParams';

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 62px;
  width: 100%;
  padding: 0 24px;
  background: white;
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: ${({ theme }) => theme.shadows.elevateCast};
`;

const wrapperStyle = { padding: '16px 16px 8px 16px' };

export function SummaryFooter({
  checkoutData,
  summaryHidden,
  ...rest
}: CheckoutSummaryProps): JSX.Element {
  const { t } = useTranslation();
  const summaryModal = useModal();
  const { accountId } = useCheckoutParams();
  const { data } = useSummary({
    accountId,
    subscriptionId:
      rest.summaryType === 'subscription' || rest.summaryType === 'payment'
        ? rest.subscription.id
        : undefined,
    planId: checkoutData?.plan?.id,
    summaryType: 'subscription',
    quantity: checkoutData?.planQuantity,
    couponName: checkoutData?.coupon,
  });

  const subscriptionTotal =
    data?.find((sr) => sr.type === 'total')?.amount || 0;

  const {
    total: invoiceTotal,
    language,
    currency,
  } = getCheckoutSummary(
    rest.summaryType === 'invoice' ? rest.invoice : undefined
  );

  const total =
    rest.summaryType === 'invoice' ? invoiceTotal : subscriptionTotal;

  const openSummaryModal = () => summaryModal.open();
  const closeSummaryModal = () => summaryModal.close();
  return (
    <>
      <StyledRow onClick={summaryHidden ? undefined : openSummaryModal}>
        {summaryHidden ? null : (
          <>
            <Paragraph>{t('common.total_to_pay')}</Paragraph>
            <strong>{formatCurrency(total, currency, language)}</strong>
          </>
        )}
      </StyledRow>
      <ModalDrawer visible={summaryModal.isOpen} onClose={closeSummaryModal}>
        {rest.summaryType === 'invoice' ? (
          <CheckoutSummary
            checkoutData={checkoutData}
            invoice={rest.invoice}
            wrapperStyle={wrapperStyle}
            summaryType={rest.summaryType}
          />
        ) : rest.summaryType === 'empty' ? (
          <CheckoutSummary
            checkoutData={checkoutData}
            summaryHidden
            wrapperStyle={wrapperStyle}
            summaryType={rest.summaryType}
          />
        ) : (
          <CheckoutSummary
            checkoutData={checkoutData}
            dataSetters={rest.dataSetters}
            subscription={rest.subscription}
            wrapperStyle={wrapperStyle}
            summaryType={rest.summaryType}
          />
        )}
      </ModalDrawer>
    </>
  );
}
