import { useState } from 'react';
import { apiFront } from '../../services/api';
import { NewApiError } from '../../types/NewApiError';

export interface UseSMSDispatcher {
  error: NewApiError | null;
  success: boolean;
  loading: boolean;
  data: Metadata;
  dispatchSMS: (phoneNumnber: string, message: string) => Promise<Metadata>;
}

export function useSMSDispatcher(): UseSMSDispatcher {
  const [error, setError] = useState<NewApiError | null>(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  const sanitizePhoneNumber = (value: string) => {
    // SMStart cant send sms to international numbers.
    return value.replace(/^54/, '');
  };

  const dispatchSMS = async (phoneNumber: string, message: string) => {
    setLoading(true);
    try {
      const response = await apiFront.post('text_messages', {
        destinatary: sanitizePhoneNumber(phoneNumber),
        content: message,
      });
      setSuccess(true);
      setError(null);
      setData(response.data);
    } catch (error) {
      setError(error as NewApiError);
      setSuccess(false);
      setData(undefined);
    } finally {
      setLoading(false);
    }
  };

  return { error, success, loading, data, dispatchSMS };
}
