import config from '../../../config';
import { DlocalFieldInstance } from './DlocalField';

const CARD_BRANDS_CODES = {
  VI: 'visa',
  DVI: 'debvisa',
  MC: 'mastercard',
  DMC: 'debmaster',
  CL: 'cabal',
  DCL: 'debcabal',
  AE: 'amex',
  NJ: 'naranja',
  NT: 'nativa',
  DC: 'diners-club',
  AG: 'argencard',
  MS: 'maestro',
  CB: 'cordobesa',
  CO: 'cordial',
  CS: 'cencosud',
  CM: 'cmr',
  HI: 'hiper',
  EL: 'elo',
  LI: 'lider',
  OA: 'oca',
};

declare global {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  type DlocalFieldsIstance = {
    create: (...params: any) => DlocalFieldInstance;
  };
  type dlocalInstance = {
    fields: (params: any) => DlocalFieldsIstance;
    create: (params: any) => void;
    createToken: (...params: any) => {
      token: string;
    };
    getBinInformation: (params: any) => {
      brand: DlocalCardBrand;
    };
    /* eslint-enable @typescript-eslint/no-explicit-any */
  };
  interface Window {
    dlocal: (key: string) => dlocalInstance;
    dlocalInstance: dlocalInstance;
  }
}

export type DlocalCardBrand = keyof typeof CARD_BRANDS_CODES;

export const loadDlocalSdk = (accountPublicKey: string): Promise<void> => {
  const { URL } = config.collectMethods.dlocal;

  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = URL;
    script.async = true;
    script.onload = () => {
      window.dlocalInstance = window.dlocal(accountPublicKey);
      resolve();
    };
    script.onerror = reject;

    document.body.appendChild(script);
  });
};

export const getCardBrandByCode = (brandCode: DlocalCardBrand): string => {
  return CARD_BRANDS_CODES[brandCode];
};
