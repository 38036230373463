import { apiFront } from './api';
import { Partner } from '../types/Partner';
import { PaginatedData } from '../types/common';

export async function fetchPartners(): Promise<PaginatedData<Partner>> {
  const response = await apiFront.get(`partners`);
  return response.data;
}

export async function createPartner(params: Metadata): Promise<Partner> {
  const response = await apiFront.post(`collect_methods`, params);
  return response.data;
}

export async function updatePartner(params: Metadata): Promise<Partner> {
  const response = await apiFront.patch(`collect_methods/${params.id}`, params);
  return response.data;
}

export async function createOrUpdatePartner(
  params: Metadata
): Promise<Partner> {
  if (params.id) {
    return createPartner(params);
  } else {
    return updatePartner(params);
  }
}
