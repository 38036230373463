import styled from 'styled-components';
import { H1, Paragraph } from '@increasecard/typed-components';
import { CheckIcon, InfoIcon } from '@increasecard/icons';
import { Column } from './Column';

const VARIANTS = {
  success: CheckIcon,
  pending: InfoIcon,
};

const BoxWrapper = styled.div`
  width: 74px;
  height: 44px;
  color: white;
  background: ${({ theme }) => theme.colors.brandGreen.regular};
  opacity: 0.95;
  box-shadow: ${({ theme }) => theme.shadows.elevateCast};
  border-radius: 8px;
  pointer-events: none;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Ornament = styled.div`
  position: absolute;
  width: 170.41px;
  height: 170.41px;
  left: -159.25px;
  bottom: -85.66px;
  background: var(--color-increase-dark50);
  border-radius: 34.3481px;
  transform: rotate(45deg);
  z-index: -1;
`;

const StyledIcon = styled.div`
  & svg {
    width: 24px;
    height: 24px;
  }
`;

export interface LabelBoxProps {
  title: string;
  variant?: 'success' | 'pending';
  description?: string;
}

export function LabelBox({
  title,
  variant = 'success',
  description,
}: LabelBoxProps): JSX.Element {
  const Icon = VARIANTS[variant];

  return (
    <Column flexGap="16px" alignItems="center" textAlign="center">
      <BoxWrapper>
        <Ornament />
        <StyledIcon>{Icon && <Icon />}</StyledIcon>
      </BoxWrapper>
      <H1 weight="bold">{title}</H1>
      <Paragraph>
        <strong>{description}</strong>
      </Paragraph>
    </Column>
  );
}
