import { useEffect } from 'react';
import { useErrorTracker } from '@increasecard/increase-core';

/**
 * Initialices error tracker in window.errorTracker
 */
export function useInitErrorTracker(): void {
  const tracker = useErrorTracker();
  useEffect(() => {
    if (!window.errorTracker && tracker) {
      window.errorTracker = tracker;
    }
  }, [tracker]);
}

/**
 * Reports an error using our errorTracker
 */
export const AVAILABLE_TYPES = [
  'error',
  'critical',
  'warning',
  'info',
  'debug',
];
export const reportError = (message = '', data = {}, type = 'error'): void => {
  if (!AVAILABLE_TYPES.includes(type)) {
    // eslint-disable-next-line no-console
    console.error(`reportError: wrong error type: ${type}`);
    return;
  }
  if (!window.errorTracker) {
    // eslint-disable-next-line no-console
    console.warn(
      `reportError: error tracker not found, couldn't report message:${message} data:${data} type:${type}`
    );
    return;
  }
  window.errorTracker[type](message, data);
};

type UserData = {
  customerId: string;
  customerEmail: string;
};

export const setUserData = ({
  customerId = 'unknown',
  customerEmail = 'error.tracker@increase.app',
}: UserData): void => {
  if (!window.errorTracker) {
    // eslint-disable-next-line no-console
    console.warn(
      `reportError: error tracker not found, couldn't set user data`
    );
    return;
  }
  window.errorTracker.configure({
    scrubFields: ['securityCode'],
    payload: {
      person: {
        id: customerId,
        username: customerEmail,
        email: customerEmail,
      },
    },
  });
};
