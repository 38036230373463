import {
  ButtonMenu as TCButtonMenu,
  ButtonMenuItem,
} from '@increasecard/typed-components';
import { Invoice } from '../../../types/Invoice';
import { useInvoiceActions } from '../useInvoiceActions';
import { callIfFunction } from '../../../utils/utils';
import { InvoiceActionsModal } from './InvoiceActionsModal';
import { AddPMToInvoiceModal } from '../../../components/paymentMethods/AddPMToInvoice';

interface InvoiceActionsMenuProps {
  invoices: Invoice[];
  onCleanUp?: VoidFunction;
  text: string;
  disabled?: boolean;
}

export function InvoiceActionsMenu({
  text,
  invoices,
  disabled,
  onCleanUp,
}: InvoiceActionsMenuProps): JSX.Element {
  const { actions, actionsModal, paymentMethodModal } = useInvoiceActions(
    'invoice detail menu',
    ['view_details', 'copy_checkout_link', 'add_payment_method']
  );

  return (
    <>
      <TCButtonMenu text={text} disabled={disabled}>
        {({ toggleShowMenu }) =>
          actions.map(({ label, cb, disabled }) => {
            const stringLabel = callIfFunction<string>(label);
            const isDisabled =
              invoices.length === 1 && disabled && disabled(invoices[0]);

            return (
              <ButtonMenuItem
                onClick={() => {
                  invoices.length > 0 && cb(invoices);
                  toggleShowMenu();
                }}
                key={stringLabel}
                disabled={isDisabled}
              >
                {stringLabel}
              </ButtonMenuItem>
            );
          })
        }
      </TCButtonMenu>
      <InvoiceActionsModal
        visible={actionsModal.isOpen}
        actionPayload={actionsModal.data}
        onClose={actionsModal.close}
        onExited={() => actionsModal.setData(null)}
        onSuccess={onCleanUp}
      />
      <AddPMToInvoiceModal
        visible={paymentMethodModal.isOpen}
        onClose={paymentMethodModal.close}
        invoice={paymentMethodModal.data}
        onExited={() => actionsModal.setData(null)}
      />
    </>
  );
}
