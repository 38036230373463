import { useTranslation } from 'react-i18next';
import { CollectMethodSelector } from '../../components/common/CollectMethodSelector';
import { ScreenSeparator } from '../../components/common/ScreenSeparator';
import { useUpdateDefaultCollectMethods } from './collectMethodQueries';
import { GroupedCollectMethods } from '../../types';
import { useDefaultCollectMethods } from '../../hooks/useCollectMethodsOptions';
import { useEffect, useState } from 'react';
import { ApiErrorMessage } from '../../components/ErrorMessage';

export interface DefaultCollectMethodsConfigProps {}

export function DefaultCollectMethodsConfig(): JSX.Element {
  const { t } = useTranslation();
  const defaultCMs = useUpdateDefaultCollectMethods();
  const { data: defaultCollectMethods } = useDefaultCollectMethods();
  const [cm, setCM] = useState<GroupedCollectMethods>();

  useEffect(() => {
    if (!cm && defaultCollectMethods) {
      setCM(defaultCollectMethods);
    }
  }, [defaultCollectMethods, cm]);

  const handleChange = (_: string, cms: GroupedCollectMethods) => {
    setCM(cms);
    defaultCMs.mutate({
      card: cms.card[0]?.id,
      cbu: cms.cbu[0]?.id,
      ticket: cms.ticket[0]?.id,
    });
  };
  return (
    <>
      <ScreenSeparator />
      <CollectMethodSelector
        onChange={handleChange}
        collectMethods={cm}
        title={t('common.default_collect_methods')}
        description="Los métodos de cobro que configures en esta sección serán tomados como los predeterminados en tu cuenta a la hora de crear planes, facturas y solicitar métodos de pago a tus clientes."
      />
      <ApiErrorMessage error={defaultCMs.error} />
    </>
  );
}
