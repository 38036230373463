import {
  format,
  parse,
  parseISO,
  isToday,
  intervalToDuration,
  formatDuration,
} from 'date-fns';
import { es } from 'date-fns/locale';

export function parseDate(date: string, formatString = 'yyyy-MM-dd'): Date {
  return parse(date, formatString, new Date());
}

export function formatDate(
  date: Date | undefined,
  formatString = 'yyyy-MM-dd'
): string {
  if (!date) return '';
  return format(date, formatString);
}

export function formatWithLocale(
  date: string,
  formatString = 'yyyy-MM-dd'
): string {
  return format(parseISO(date), formatString, {
    locale: es,
  });
}

export function formatISODate(
  date: string,
  formatString = 'dd/MM/yyyy',
  defaultText = 'No declarada'
): string {
  if (!date) {
    return defaultText;
  }
  return formatDate(parseISO(date), formatString);
}

export { isToday };

export function getNextYear(): Date {
  const today = new Date();
  today.setFullYear(today.getFullYear() + 1);
  return today;
}

export function getDuration(start: string, end: string): string {
  return formatDuration(
    intervalToDuration({
      start: parseISO(start),
      end: parseISO(end),
    }),
    {
      locale: es,
    }
  );
}
