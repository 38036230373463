import { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import {
  InlineText,
  InputNumber,
  CellText,
} from '@increasecard/typed-components';
import { CheckoutPlan } from '../../types/Plan';
import { calculatePlanAmount } from '../../utils/planUtils';
import { formatCurrency } from '../../utils/currencyUtils';
import { TieredPlanBreakdown } from './TieredPlanBreakdown';

const GridWrapper = styled.div`
  border: 1px solid var(--color-pay-regular);
  border-radius: 9px;
  height: max-content;
  padding: 1rem;
  display: grid;
  gap: 0 0.5rem;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: max-content max-content;
  background-color: var(--color-pay-light);
  grid-template-areas:
    'input-title input-title result-title result-title'
    'input input-label result result'
    'breakdown breakdown breakdown breakdown';

  .breakdown {
    grid-area: breakdown;
    align-items: end;
  }

  .input-title {
    grid-area: input-title;
  }

  .result-title {
    grid-area: result-title;
    justify-self: flex-end;
  }

  .input {
    grid-area: input;
  }

  .input-label {
    grid-area: input-label;
    align-self: center;
  }

  .result {
    grid-area: result;
    justify-self: flex-end;
    align-self: center;
  }
`;

export interface PlanCalculatorProps {
  plan: CheckoutPlan;
  className?: string;
}

export function PlanCalculator({
  plan,
  className,
}: PlanCalculatorProps): JSX.Element {
  const [quantity, setQuantity] = useState(1);
  const { result, details } = calculatePlanAmount(plan, quantity);

  return (
    <GridWrapper className={className}>
      <InlineText className="input-title">
        <strong>Si un cliente consume</strong>
      </InlineText>
      <InlineText className="result-title">
        <strong>el monto a pagar será de</strong>
      </InlineText>
      <InputNumber
        min="0"
        step="1"
        value={quantity}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setQuantity(parseInt(e.target.value))
        }
      />
      <CellText className="input-label">unidades</CellText>
      <InlineText className="result">
        {formatCurrency(result, plan.currency)}
      </InlineText>
      <TieredPlanBreakdown
        details={details}
        currency={plan.currency}
        className="breakdown"
      />
    </GridWrapper>
  );
}
