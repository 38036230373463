import { useEffect, useCallback } from 'react';
import { environment } from '../../config';
import {
  createGlobalStyle,
  DefaultTheme,
  GlobalStyleComponent,
} from 'styled-components';
import { Button, Link } from '@increasecard/typed-components';
import { OnTopNotification } from '@increasecard/increase-core/dist/contexts/OnTopNotificationContext';
import { useEventTracker } from '@increasecard/increase-core';
import { formatDate } from '../../utils/dateUtils';

const SandboxHack = createGlobalStyle`
  #root > div {
    background: #00B5B3;
    height: 38px;
    #sandbox > button {
      background: #0f6bff;
      margin-left: 16px;
    }
  }
`;

function NotificationWithModal() {
  // const modal = useModal();
  // const user = usePayUser();
  const tracker = useEventTracker();

  const trackEventWithDate = useCallback(
    (event: string) => {
      tracker.track(event, {
        date: formatDate(new Date(), 'dd/MM/yyyy HH:mm:ss'),
      });
    },
    [tracker]
  );

  // function onGotoProduction() {
  //   trackEventWithDate('SOB: Choose Acquire Pay');
  //   closeModal();
  //   window.open(config.urls.platform + '/pay/billing');
  // }

  // function gotoLandingForm() {
  //   const landingFormUrl = config.urls.landing + '/contacto/enterprise/';
  //   if (!user) {
  //     // open form without preloaded values
  //     window.open(landingFormUrl);
  //     return;
  //   }
  //   // create query string
  //   const params = new URLSearchParams({
  //     from: 'pay-sandbox',
  //     fullname: user.firstName + ' ' + user.lastName,
  //     email: user.email,
  //     phone: user.phone,
  //     // URLSearchParams does not allow undefined values
  //     country: user?.paySubscription?.country || '',
  //   }).toString();
  //   // open form with preloaded values
  //   window.open(landingFormUrl + '?' + params);
  // }

  // function onContactSeller() {
  //   trackEventWithDate('SOB: Choose Contact');
  //   closeModal();
  //   gotoLandingForm();
  // }

  // function closeModal() {
  //   modal.close();
  // }

  // function onStartClick() {
  //   if (
  //     user &&
  //     user.paySubscription &&
  //     user.paySubscription.state === 'usable'
  //   ) {
  //     // go to Production, user has a usable Pay subscription
  //     window.location.replace(productionUrl);
  //     return;
  //   }
  //   trackEventWithDate('SOB: Notification Start Click');
  //   modal.open();
  // }

  function navigateToContact() {
    window.open(
      'https://api.whatsapp.com/send/?phone=541150365046&text&app_absent=0',
      '_blank'
    );
  }

  useEffect(() => {
    trackEventWithDate('SOB: Show Sandbox Notification');
  }, [trackEventWithDate]);

  return (
    <>
      <div id="sandbox">
        Recuerda que estás en un{' '}
        <Link
          href="https://increase-app.notion.site/C-mo-funciona-Sandbox-el-entorno-de-prueba-de-Increase-Pay-8738e91eff0944a8abca7bc678be7dc3"
          target="_blank"
        >
          entorno de prueba
        </Link>
        . Para pasar a Producción contáctanos
        <Button size="small" onClick={navigateToContact}>
          WhatsApp
        </Button>
      </div>
    </>
  );
}

const Notification: OnTopNotification = {
  children: <NotificationWithModal />,
  variant: 'alert',
};

export interface SelfOnboardingConfig {
  showSandboxNotification: boolean;
  SandboxNotification: OnTopNotification;
  SandboxNotificationHack: GlobalStyleComponent<unknown, DefaultTheme>;
}

export function useSelfOnboarding(): SelfOnboardingConfig {
  const showNotification = environment === 'sandbox';

  return {
    showSandboxNotification: showNotification,
    SandboxNotification: Notification,
    SandboxNotificationHack: SandboxHack,
  };
}
