import { ModalContent, Paragraph } from '@increasecard/typed-components';
import { useTranslation } from 'react-i18next';
import { Subscription } from '../../../types/Subscription';
import { MassiveActionResults } from './MassiveActionResults';
import { useRejectSubscriptions } from '../subscriptionQueries';
import { MassiveActionFooter } from './MassiveActionsFooter';

export interface RejectModalProps {
  onClose: VoidFunction;
  onSuccess?: VoidFunction;
  subscription: Subscription[];
}

interface RejectMessageProps {
  subscription: Subscription[];
}

function RejectMessage({ subscription }: RejectMessageProps) {
  const { t } = useTranslation();
  return (
    <Paragraph style={{ marginBottom: '1rem' }}>
      {t('screens.subscriptions.reject.subtitle', {
        count: subscription.length,
      })}
      {subscription.length === 1 ? (
        <Paragraph weight="bold">
          {t('screens.subscriptions.reject.subtitle2', {
            product: subscription[0].product.name,
            plan: subscription[0].plan.name,
          })}
        </Paragraph>
      ) : null}
    </Paragraph>
  );
}

export function RejectModalContent({
  onClose,
  onSuccess,
  subscription,
}: RejectModalProps): JSX.Element | null {
  const { t } = useTranslation();

  const { mutate, error, isError, isSuccess, isLoading } =
    useRejectSubscriptions();

  const handleReject = () =>
    mutate(
      {
        subscriptions: subscription,
      },
      { onSuccess }
    );

  if (!subscription) return null;

  return (
    <>
      <ModalContent align="center">
        {!isError && !isSuccess && (
          <RejectMessage subscription={subscription} />
        )}
        <MassiveActionResults
          subscriptions={subscription}
          error={error}
          isSuccess={isSuccess}
          actionType="reject"
        />
      </ModalContent>
      <MassiveActionFooter
        onClose={onClose}
        onConfirm={handleReject}
        isLoading={isLoading}
        isError={isError}
        isSuccess={isSuccess}
        confirmLabel={t('screens.subscriptions.reject.confirm', {
          count: subscription.length,
        })}
      />
    </>
  );
}
