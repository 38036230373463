import { TFunction } from 'i18next';
import * as Yup from 'yup';
import {
  CreditCardNumber,
  RequiredString,
  CVV,
  CardExpirationDate,
  MinMaxAlphanumericRequiredLength,
} from '../../schemas/common';

type CreditCardFormValues = Record<
  'name' | 'cardNumber' | 'cvv' | 'dni' | 'expirationDate' | 'document_type',
  string
>;

export function CreditCardSchema(
  t: TFunction
): Yup.SchemaOf<Partial<CreditCardFormValues> | undefined> {
  return Yup.object({
    name: RequiredString(),
    cardNumber: CreditCardNumber(t),
    cvv: CVV(t),
    dni: MinMaxAlphanumericRequiredLength(t, 5, 15),
    expirationDate: CardExpirationDate(t),
    document_type: RequiredString(),
  });
}

type MPCreditCardFormValues = Record<
  | 'name'
  | 'cardNumber'
  | 'securityCode'
  | 'dni'
  | 'expirationDate'
  | 'document_type',
  string
>;
export function MPCreditCardSchema(
  t: TFunction
): Yup.SchemaOf<Partial<MPCreditCardFormValues> | undefined> {
  return Yup.object({
    name: RequiredString(),
    cardNumber: CreditCardNumber(t),
    securityCode: CVV(t),
    dni: MinMaxAlphanumericRequiredLength(t, 5, 15),
    expirationDate: CardExpirationDate(t),
    document_type: RequiredString(),
  });
}
