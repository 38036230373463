import { useEffect } from 'react';
import i18n from '../i18n';
import { Country } from '../types/common';
import { getCountryConfig } from '../utils/countryUtils';

export function useLanguage(country?: Country): void {
  const language = country
    ? getCountryConfig(country).language
    : navigator.language;
  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language]);
}
