import {
  ButtonMenu as TCButtonMenu,
  ButtonMenuItem,
} from '@increasecard/typed-components';
import { Subscription } from '../../../types/Subscription';
import { callIfFunction } from '../../../utils/utils';
import { ActionsModal } from './ActionsModal';
import {
  useSubscriptionActions,
  MenuOptionType,
} from './useSubscriptionActions';

const SUBSCRIPTION_ACTIONS: MenuOptionType[] = [
  'approve',
  'reject',
  'pause',
  'cancel',
  'activate',
  'change_billing_date',
  'ask_payment_method',
  'copy_checkout_link',
];

export interface SubscriptionActionsMenuProps {
  label: string;
  selection: Subscription[];
  onCleanUp?: VoidFunction;
  id?: string;
  context: string;
  disabled?: boolean;
}

export function SubscriptionActionsMenu({
  label,
  selection,
  onCleanUp,
  id,
  context,
  disabled,
}: SubscriptionActionsMenuProps): JSX.Element {
  const { actions, actionsModal } = useSubscriptionActions(
    SUBSCRIPTION_ACTIONS,
    context
  );
  return (
    <>
      <TCButtonMenu text={label} id={id} disabled={disabled}>
        {({ toggleShowMenu }) =>
          actions.map(({ label, disabled, cb }) => {
            const stringLabel = callIfFunction<string>(label, selection);
            const isDisabled =
              selection.length === 1 && disabled
                ? disabled(selection[0])
                : undefined;
            if (isDisabled) return null;
            return (
              <ButtonMenuItem
                key={stringLabel}
                disabled={isDisabled}
                onClick={() => {
                  selection.length > 0 && cb(selection[0]);
                  toggleShowMenu();
                }}
              >
                {stringLabel}
              </ButtonMenuItem>
            );
          })
        }
      </TCButtonMenu>
      <ActionsModal
        onSuccess={onCleanUp}
        onClose={() => actionsModal.close()}
        onExited={() => actionsModal.setData(null)}
        visible={actionsModal.isOpen}
        subscription={selection}
        actionType={actionsModal.data?.actionType}
      />
    </>
  );
}
