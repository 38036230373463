import {
  Caption,
  CellText,
  InputNumber,
  RadioButton,
  Switch,
} from '@increasecard/typed-components';
import { useTranslation } from 'react-i18next';
import { Column } from '../../../components/common/Column';
import { Row } from '../../../components/common/Row';
import { FrontPlan } from '../../../types/Plan';
import { Select } from '../../../components/common/SystemSelect';
import { useNextPlanBillingDate } from '../planQueries';
import { ChangeEvent, Dispatch } from 'react';
import { NameValue } from '../../../types';

export interface DateOfChargeProps {
  isEditing: boolean;
  plan: FrontPlan;
  onInputChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  dispatch: Dispatch<{ type: string; payload: string | number | null }>;
}

const START_HOURS = Array(23)
  .fill(0)
  .map((_, index) => index + 1);

const START_HOURS_OPTIONS: NameValue[] = START_HOURS.map((hour) => {
  return { name: `${hour}:00`, value: `${hour}` };
});

const CHARGE_PROPORTIONAL_OPTIONS = {
  BEFORE_FIRST_PERIOD: 'before_first_period',
  AFTER_FIRST_PERIOD: 'after_first_period',
  FREE_PROPORTIONAL: 'free_proportional',
};

export function DateOfCharge({
  isEditing,
  plan,
  onInputChange,
  dispatch,
}: DateOfChargeProps): JSX.Element | null {
  const { t } = useTranslation();
  const nextBillingDate = useNextPlanBillingDate({
    planId: plan.id,
    startDay: plan.start_day,
    startMonth: plan.start_month,
  });

  const handleProportionalChargeChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      dispatch({
        type: 'charge_proportional',
        payload: 'after_first_period',
      });
    } else {
      dispatch({ type: 'charge_proportional', payload: null });
    }
  };

  const handleStartHourChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      dispatch({
        type: 'start_hour',
        payload: START_HOURS_OPTIONS[0].value,
      });
    } else {
      dispatch({ type: 'start_hour', payload: null });
    }
  };

  return (
    <>
      <Column
        gap="16px"
        className="grid-start-desktop-1 span-desktop-5 span-mobile-2"
      >
        <Switch
          checked={!!plan.charge_after_period}
          label={t('screens.new_plan.charge_period_end_title')}
          name="charge_after_period"
          id="charge_after_period"
          onChange={onInputChange}
          disabled={isEditing}
        />
        <Switch
          checked={!!plan.charge_proportional}
          label={t('screens.new_plan.enable_charge_in_date')}
          id="enable-proportional-charge"
          onChange={handleProportionalChargeChange}
          disabled={isEditing}
        />
        <div style={{ marginLeft: '44px' }}>
          <Column margin="0">
            <Row marginBottom="0.5rem">
              <InputNumber
                label={t('screens.new_plan.month_day_start')}
                min={1}
                max={31}
                onChange={onInputChange}
                value={plan.start_day || ''}
                id="start_day"
                disabled={plan.start_day === null}
                width="100px"
              />
              {plan.cycle_unit === 'yearly' && (
                <InputNumber
                  label={t('screens.new_plan.year_month_start')}
                  min={1}
                  max={12}
                  onChange={onInputChange}
                  value={plan.start_month || ''}
                  id="start_month"
                  disabled={plan.start_month === null}
                  width="100px"
                  size={10}
                />
              )}
            </Row>
            {isEditing && (
              <CellText>
                {t('screens.new_plan.next_plan_billing_date', {
                  nextBillingDate: nextBillingDate.data,
                })}
              </CellText>
            )}
          </Column>

          <Column flexGap="1rem">
            <RadioButton
              name="charge_proportional"
              checked={
                plan.charge_proportional ===
                CHARGE_PROPORTIONAL_OPTIONS.FREE_PROPORTIONAL
              }
              label={t('screens.new_plan.free_proportional')}
              value={CHARGE_PROPORTIONAL_OPTIONS.FREE_PROPORTIONAL}
              onChange={onInputChange}
              disabled={plan.charge_proportional === null || isEditing}
            />
            <RadioButton
              name="charge_proportional"
              checked={
                plan.charge_proportional ===
                CHARGE_PROPORTIONAL_OPTIONS.BEFORE_FIRST_PERIOD
              }
              label={t('screens.new_plan.before_first_period')}
              value={CHARGE_PROPORTIONAL_OPTIONS.BEFORE_FIRST_PERIOD}
              onChange={onInputChange}
              disabled={plan.charge_proportional === null || isEditing}
            />
            <RadioButton
              name="charge_proportional"
              checked={
                plan.charge_proportional ===
                CHARGE_PROPORTIONAL_OPTIONS.AFTER_FIRST_PERIOD
              }
              label={t('screens.new_plan.after_first_period')}
              value={CHARGE_PROPORTIONAL_OPTIONS.AFTER_FIRST_PERIOD}
              onChange={onInputChange}
              disabled={plan.charge_proportional === null || isEditing}
            />
          </Column>
        </div>
      </Column>
      <Column
        className="span-desktop-6 span-mobile-2 grid-start-desktop-7"
        flexGap="1rem"
      >
        <Switch
          checked={!!plan.start_hour}
          label={t('screens.new_plan.enable_charge_in_hour')}
          id="enable-start-hour"
          onChange={handleStartHourChange}
          disabled={isEditing}
        />
        <div>
          <Select
            id="start_hour"
            label={t('screens.new_plan.start_hour')}
            options={START_HOURS_OPTIONS}
            onChange={onInputChange}
            value={plan.start_hour}
            disabled={plan.start_hour === null || isEditing}
            style={{ width: '200px' }}
          />
        </div>
        <Caption>{t('data.plan.start_hour_caption')}</Caption>
      </Column>
    </>
  );
}
