import { Form, Formik } from 'formik';
import { Button, Grid } from '@increasecard/typed-components';
import { useTranslation } from 'react-i18next';
import { Product, ProductUpdateParams } from '../../../types/Product';
import { InputTextField } from '../../../components/common/FormikFields';
import { Row } from '../../../components/common/Row';

export interface EditProductFormProps {
  product: Product;
  onCancel: () => void;
  onSubmit: (product: ProductUpdateParams) => void;
}

export function EditProductForm({
  product,
  onCancel,
  onSubmit,
}: EditProductFormProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <Formik
      initialValues={product}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values);
        setSubmitting(false);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid rowGap={2}>
            <InputTextField
              id="name"
              label={t('screens.new_product.name')}
              disabled={isSubmitting}
            />
            <Row justifyContent="center" flexGap="0.5rem" margin="0">
              <Button buttonType="invisible" onClick={onCancel} type="button">
                {t('common.cancel')}
              </Button>
              <Button type="submit" disabled={isSubmitting}>
                {t('common.save')}
              </Button>
            </Row>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
