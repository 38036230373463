import { useTranslation } from 'react-i18next';
import { useSubscriptionAmountToCharge } from '../checkoutQueries';

export interface SubscriptionAmountToChargeProps {
  subscriptionId: string;
}
export function SubscriptionAmountToCharge({
  subscriptionId,
}: SubscriptionAmountToChargeProps): JSX.Element | null {
  const { t } = useTranslation();
  const { data } = useSubscriptionAmountToCharge(subscriptionId);

  // If its invoice, the summary will show it.
  if (!subscriptionId || !data || data?.type?.toLocaleLowerCase() === 'invoice')
    return null;
  const type = data.type.toLowerCase();
  const i18nkey = `data.payment_method.amount_to_charge_${type}`;
  return t(i18nkey, data.object);
}
