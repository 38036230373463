import { useTranslation } from 'react-i18next';
import {
  Button,
  ModalContent,
  ModalFooter,
} from '@increasecard/typed-components';

export interface ErrorStepProps {
  onClose: () => void;
}

export function ErrorStep({ onClose }: ErrorStepProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      <ModalContent>
        {t('screens.export_modal.error_step.description')}
      </ModalContent>
      <ModalFooter align="center">
        <Button buttonType="primary" onClick={onClose}>
          {t('screens.export_modal.error_step.button_text')}
        </Button>
      </ModalFooter>
    </>
  );
}
