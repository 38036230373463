import { useState, useEffect, Dispatch, useCallback } from 'react';

function getStorageValue<T>(key: string, defaultValue: T) {
  const saved = localStorage.getItem(key);
  const initial = saved && JSON.parse(saved);
  // this check allows to save simple boolean values.
  return initial !== undefined && initial !== null
    ? initial
    : defaultValue || undefined;
}

export type UseLocalStorage<T> = [T, Dispatch<T>, () => T];

export function useLocalStorage<T>(
  key: string,
  defaultValue: T
): UseLocalStorage<T> {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue);
  });

  const getValue = useCallback(() => {
    return getStorageValue(key, {});
  }, [key]);

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue, getValue];
}
