import { CellText } from '@increasecard/typed-components';
import { useTranslation } from 'react-i18next';
import { Currency } from '../../types';
import { formatCurrency } from '../../utils/currencyUtils';
import { PlanCalcDetail } from '../../utils/planUtils';
import { Column } from './Column';

export interface TieredPlanBreakdownProps {
  details: PlanCalcDetail[] | undefined;
  currency: Currency;
  className?: string;
}

export function TieredPlanBreakdown({
  details,
  currency,
  className,
}: TieredPlanBreakdownProps): JSX.Element | null {
  const { t } = useTranslation();
  if (!details) return null;

  const format = (amount: number) => formatCurrency(amount, currency);

  const getDescriptionLine = (detail: PlanCalcDetail) => {
    let message = '';
    const unit = t('common.unit', { count: detail.tierQuantity });
    if (detail.amount_type === 'per_unit') {
      message += `${detail.tierQuantity} ${unit} x ${format(
        detail.amount
      )} = ${format(detail.tierTotal)}`;
    } else {
      message += `${detail.tierQuantity} ${unit} = ${format(detail.amount)} ${t(
        'data.plan.amount_type.flat'
      )}`;
    }

    return message;
  };
  return (
    <Column className={className} gap="4px">
      {details?.map((detail, index) => (
        <CellText key={index}>{getDescriptionLine(detail)}</CellText>
      ))}
    </Column>
  );
}
