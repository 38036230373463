import { useTranslation } from 'react-i18next';
import {
  ColumnDefinition,
  DataTable,
} from '../../../components/common/DataTable/DataTable';
import { Constants } from '../../../constants';
import { useCollectMethods } from '../../../globalQueries/Queries';
import { PaymentMethod } from '../../../types';
import { Customer } from '../../../types/Customer';

export interface CustomerPaymentsMethodsListProps {
  customer: Customer;
}

export function CustomerPaymentsMethodsList({
  customer,
}: CustomerPaymentsMethodsListProps): JSX.Element {
  const { t } = useTranslation();
  const { data } = useCollectMethods();

  const getCMName = (collectMethodId: string) => {
    if (data) {
      const cm = data.find((cm) => cm.id === collectMethodId);
      return cm ? cm.name : '';
    }
    return '';
  };

  const tableColumns: ColumnDefinition[] = [
    {
      field: 'name',
      headerName: t('common.collect_method'),
      valueGetter: (row: PaymentMethod) =>
        `${t(`data.payment.methods.${row.data.type}`)} - ${getCMName(
          row.collect_method_id
        )}`,
    },
    {
      field: '', // Uses the whole object instead of a specific field
      type: 'payment_method',
      headerName: t('common.payment_method'),
    },
    {
      field: 'created_at',
      headerName: t('common.created_at'),
      type: 'date',
    },
  ];
  return (
    <DataTable
      tableHeader={t('common.payment_methods')}
      tableProps={{ hasData: !!customer.payment_methods.length }}
      columns={tableColumns}
      pageSize={Constants.SMALL_LIST_PAGE_SIZE}
      rows={customer.payment_methods}
    />
  );
}
