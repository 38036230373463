import { useTranslation } from 'react-i18next';
import { PaymentMethodLabel } from '../../../../components/paymentMethods/PaymentMethodLabel';
import { CheckoutSubscription } from '../../../../types/Subscription';

import { CheckoutData } from '../../useCheckout';
import { StyledTable } from './SummaryTable';

export interface SimpleSubscriptionSummaryProps {
  subscription: CheckoutSubscription;
  checkoutData?: CheckoutData;
}

function SimpleSubscriptionSummary({
  subscription,
  checkoutData,
}: SimpleSubscriptionSummaryProps): JSX.Element | null {
  const { t } = useTranslation();
  const plan = subscription?.plan || checkoutData?.plan;
  const paymentMethod = subscription.payment_method;
  return (
    <StyledTable>
      <tbody>
        <tr>
          <td>{t('common.product')}</td>
          <td></td>
          <td className="rightAligned">{plan.product.name}</td>
        </tr>
        <tr>
          <td>{t('common.plan')}</td>
          <td></td>
          <td className="rightAligned">{plan.name}</td>
        </tr>
        {paymentMethod && (
          <tr>
            <td>{t('common.payment_method')}</td>
            <td></td>
            <td className="rightAligned">
              <PaymentMethodLabel paymentMethod={paymentMethod} />
            </td>
          </tr>
        )}
      </tbody>
    </StyledTable>
  );
}

export default SimpleSubscriptionSummary;
