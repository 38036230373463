import { CellText } from '@increasecard/typed-components';
import { Customer } from '../../types/Customer';

export interface CustomerNameAndMailProps {
  customer: Customer;
}
export function CustomerNameAndMail({
  customer,
}: CustomerNameAndMailProps): JSX.Element {
  return (
    <>
      <CellText weight="bold">
        {`${customer.first_name} ${customer.last_name}`}
      </CellText>
      <CellText>{customer.email}</CellText>
    </>
  );
}
