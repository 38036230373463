import styled from 'styled-components';
import { ChangeEvent, useState } from 'react';
import {
  DateRangeInput,
  DateInput,
  Button,
  InputSearch,
} from '@increasecard/typed-components';
import { useTranslation } from 'react-i18next';
import { SubscriptionTagSelector } from '../FilterSelectors/SubscriptionTagSelector';
import { ProductSelector } from '../FilterSelectors/ProductSelector';
import { PlanSelectorMultiple } from '../FilterSelectors/PlanSelector';
import { NameValue } from '../../types/common';
import {
  CustomerSelector,
  CustomerSelectorMultiple,
} from '../FilterSelectors/CustomerSelector';
import {
  ApiFilterSelector,
  ApiFilterSelectorMultiple,
} from '../FilterSelectors/ApiFilterSelector';
import {
  DateFilterConfig,
  DateRangeFilterConfig,
  FilterableFields,
  FilterFormConfig,
} from './types';

export interface FiltersFormProps {
  value: Metadata;
  onSubmit: (filters: Metadata) => void;
  config: FilterFormConfig[];
}

const Menu = styled.div.attrs({ role: 'menu' })<{ columns: number }>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: var(--color-gray-white);
  box-shadow: ${({ theme }) => theme.shadows.elevateCast};
  border: 1px solid var(--color-gray-grayMedium);
  border-radius: 4px;
  position: absolute;
  top: 4px;
  text-align: left;
  left: 0;
  padding: 8px;
  animation: appear 150ms ease-out;
  .form {
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      grid-template-columns: repeat(${({ columns }) => columns}, 250px);
    }
    display: grid;
    grid-template-columns: 250px;
    gap: 1rem;
    ${({ columns }) => columns > 1 && 'margin: 0.5rem 0.5rem 1rem;'}
  }
  .form-buttons {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-end;
  }
  @keyframes appear {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;

export function FiltersForm({
  value,
  onSubmit,
  config,
}: FiltersFormProps): JSX.Element {
  const [filterValues, setFilterValues] = useState(value);
  const { t } = useTranslation();

  const handleFilterChange = (
    id: FilterableFields,
    newValue: NameValue[] | NameValue | string
  ) => {
    if (id === 'products') {
      setFilterValues({ ...filterValues, [id]: newValue, plans: [] });
    } else {
      setFilterValues({ ...filterValues, [id]: newValue });
    }
  };

  const handleDateChange = (cfg: DateFilterConfig, newDate: Date) => {
    setFilterValues({
      ...filterValues,
      [cfg.field]: newDate,
    });
  };

  const handleDateRange = (cfg: DateRangeFilterConfig, newValue: Date[]) => {
    setFilterValues({
      ...filterValues,
      [cfg.field]: newValue[0],
      [cfg.fieldTo]: newValue[1],
    });
  };

  const sanitizeFilterValues = (key: string) => {
    return (
      filterValues[key]?.filter((item: NameValue) => item.value !== '*') || []
    );
  };

  const selectType = config.length === 1 ? 'box' : 'dropdown';
  const fields = [];
  for (const cfg of config) {
    if (cfg.type === 'custom') {
      switch (cfg.field) {
        case 'products':
          fields.push(
            <ProductSelector
              selectType={selectType}
              key="product"
              value={filterValues[cfg.field]?.[0]?.value}
              onChange={(product) => handleFilterChange(cfg.field, [product])}
              size="small"
            />
          );
          break;
        case 'product_id':
          fields.push(
            <ProductSelector
              selectType={selectType}
              key="product"
              value={filterValues[cfg.field]?.value}
              onChange={(product) => handleFilterChange(cfg.field, product)}
              size="small"
            />
          );
          break;
        case 'plans':
          fields.push(
            <PlanSelectorMultiple
              selectType={selectType}
              key="plans"
              onChange={(plans) => handleFilterChange(cfg.field, plans)}
              value={sanitizeFilterValues(cfg.field)}
              productId={filterValues.products?.[0]?.value}
              size="small"
            />
          );
          break;
        case 'customers':
          fields.push(
            <CustomerSelectorMultiple
              selectType={selectType}
              key="customer"
              onChange={(customers) => handleFilterChange(cfg.field, customers)}
              value={sanitizeFilterValues(cfg.field)}
              size="small"
            />
          );
          break;
        case 'customer_id':
          fields.push(
            <CustomerSelector
              selectType={selectType}
              key="customer"
              onChange={(customer) => handleFilterChange(cfg.field, customer)}
              value={filterValues[cfg.field]?.value}
              size="small"
            />
          );
          break;
        case 'tags':
          fields.push(
            <SubscriptionTagSelector
              selectType={selectType}
              key="tags"
              id="tags"
              selection={filterValues.tags}
              onChange={(value) => handleFilterChange(cfg.field, value)}
            />
          );
          break;
      }
    }
    if (cfg.type === 'api_filter') {
      if (cfg.isMultiple) {
        fields.push(
          <ApiFilterSelectorMultiple
            selectType={selectType}
            key={cfg.filterValuesPath}
            filterValuesPath={cfg.filterValuesPath}
            onChange={(value) => handleFilterChange(cfg.field, value)}
            value={sanitizeFilterValues(cfg.field)}
            label={t(cfg.label)}
            size="small"
          />
        );
      } else {
        fields.push(
          <ApiFilterSelector
            selectType={selectType}
            key={cfg.filterValuesPath}
            filterValuesPath={cfg.filterValuesPath}
            onChange={(value) => handleFilterChange(cfg.field, value)}
            value={filterValues[cfg.field]?.value}
            label={t(cfg.label)}
            size="small"
          />
        );
      }
    }
    if (cfg.type === 'date') {
      fields.push(
        <DateInput
          key="date_range_picker"
          datepickerProps={{ defaultActiveStartDate: new Date() }}
          style={{ display: 'flex' }}
          onChange={(value) => handleDateChange(cfg, value)}
          id="date"
          label={t(cfg.label)}
          labelHidden={false}
          value={filterValues[cfg.field]}
          size="small"
        />
      );
    }
    if (cfg.type === 'date_range') {
      fields.push(
        <DateRangeInput
          key="date_range_picker"
          datepickerProps={{ defaultActiveStartDate: new Date() }}
          style={{ display: 'flex' }}
          onChange={(value) => handleDateRange(cfg, value)}
          id="date"
          label={t(cfg.label)}
          labelHidden={false}
          value={[filterValues[cfg.field], filterValues[cfg.fieldTo]]}
          size="small"
        />
      );
    }
    if (cfg.type === 'search') {
      fields.push(
        <InputSearch
          label={t(cfg.label)}
          key="search"
          id="search"
          size="small"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleFilterChange(cfg.field, e.target.value)
          }
          value={filterValues[cfg.field]?.value}
        />
      );
    }
  }

  return (
    <Menu columns={fields.length > 4 ? 2 : 1}>
      <div className="form-buttons">
        <Button
          type="reset"
          buttonType="invisible"
          onClick={() => {
            setFilterValues(value);
          }}
          size="small"
        >
          Deshacer
        </Button>
        <Button
          size="small"
          type="button"
          onClick={() => {
            onSubmit(filterValues);
          }}
        >
          Aplicar
        </Button>
      </div>
      <div className="form">{fields}</div>
    </Menu>
  );
}
