import styled from 'styled-components';
import {
  VerticalNavigation,
  VerticalNavigationElement,
} from '@increasecard/typed-components';
import { Link, useRouteMatch, Switch, Route } from 'react-router-dom';
import { DualLayout } from '../../layout/Layout';

// Restitute some styles lost by rendering as Link
const StyledLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  transition: all 200ms ease 0s;
`;
export interface CustomLinkProps {
  label: string;
  to: string;
  activeOnlyWhenExact?: boolean;
}

const StickyNavigation = styled(VerticalNavigation)`
  height: auto;
  position: sticky;
  top: 88px;
`;

function CustomLink({
  label,
  to,
  activeOnlyWhenExact,
}: CustomLinkProps): JSX.Element {
  const match = useRouteMatch<{ path?: string; exact?: string }>({
    path: to,
    exact: activeOnlyWhenExact,
  });
  return (
    <VerticalNavigationElement active={match} as={StyledLink} to={to}>
      {label}
    </VerticalNavigationElement>
  );
}

export interface VerticalNavElement {
  title: string;
  path: string;
  absolute?: boolean;
  content: JSX.Element;
  hide?: boolean;
}

export interface VerticalNavProps {
  elements: VerticalNavElement[];
}

export function VerticalNav({ elements }: VerticalNavProps): JSX.Element {
  const { path: basePath, url } = useRouteMatch<{
    path?: string;
    url?: string;
  }>();

  const menuBar = (
    <StickyNavigation>
      {elements.map(({ title, path, absolute, hide }) => {
        if (hide) return null;
        return (
          <CustomLink
            activeOnlyWhenExact={true}
            to={`${absolute ? '' : url}${path}`}
            key={title}
            label={title}
          />
        );
      })}
    </StickyNavigation>
  );

  return (
    <DualLayout
      leftContext={menuBar}
      rightContext={
        <Switch>
          {elements.map(({ path, content, absolute, hide }) => {
            if (hide) return null;
            return (
              <Route
                exact
                key={path}
                path={`${absolute ? '' : basePath}${path}`}
                render={() => content}
              />
            );
          })}
        </Switch>
      }
    />
  );
}
