import { Table, H3, Link } from '@increasecard/typed-components';
import { Customer } from '../../../types/Customer';

export interface CustomerSubscriptionProps {
  customer: Customer | null;
  planId: string | undefined;
}

export function CustomerSubscription({
  customer,
  planId,
}: CustomerSubscriptionProps): JSX.Element | null {
  const planSubscriptions = customer?.subscriptions?.filter(
    (s) => s.plan.id === planId
  );

  if (!planSubscriptions || planSubscriptions.length === 0) return null;

  return (
    <div className="grid-span-desktop-10">
      <H3 weight="normal">Ya cuentas con suscripciones a este plan</H3>
      <Table fixedLayout>
        <tbody>
          {customer &&
            customer.subscriptions
              .filter((s) => s.plan.id === planId)
              .map((subscriptionItem) => {
                return (
                  <tr key={subscriptionItem.id} className="border">
                    <td className="ellipsis">
                      {subscriptionItem.plan.product.name}
                    </td>
                    <td>{subscriptionItem.plan.name}</td>
                    <td className="rightAligned">
                      {(subscriptionItem.status === 'pending' ||
                        subscriptionItem.status === 'pending_payment') && (
                        <Link href={subscriptionItem.checkout_link}>
                          Completar suscripción
                        </Link>
                      )}
                    </td>
                  </tr>
                );
              })}
        </tbody>
      </Table>
      <br />
    </div>
  );
}
