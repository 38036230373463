import { ApiFilters } from '../types/common';
import { apiFront } from './api';

export async function fetchFilters(): Promise<ApiFilters> {
  const response = await apiFront.get<ApiFilters>('filters');
  response.data.countries = response.data.countries.filter(
    (c) => !['SLV', 'BOL', 'DOM'].includes(c)
  );
  // DebtInvoice has its own table so it does not need filtering by kind
  response.data.invoices.kinds = response.data.invoices.kinds.filter(
    (k) => k !== 'debt_recovery'
  );
  return response.data;
}
