import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { NewApiError } from '../../types/NewApiError';
import {
  createCustomer,
  createPaymentMethod,
  deleteCustomer,
  deletePaymentMethod,
  DeletePaymentMethodParams,
  fetchCustomers,
  FetchCustomersParams,
  PaymentMethodCreateParams,
  updateCustomer,
  UpdateCustomerParams,
} from '../../services/CustomersService';
import { Customer } from '../../types/Customer';
import { useMutationWithInvalidation } from '../../utils/queryUtils';
import { PaginatedData } from '../../types/common';
import { CustomerFormValues } from './CustomerForm';
import { PaymentMethod } from '../../types/PaymentMethod';

export const useDeleteCustomerPaymentMethod = (): UseMutationResult<
  void | NewApiError,
  NewApiError,
  DeletePaymentMethodParams
> => {
  return useMutation(deletePaymentMethod);
};

export const useUpdateCustomer = (): UseMutationResult<
  Customer,
  NewApiError,
  UpdateCustomerParams
> => {
  return useMutationWithInvalidation(updateCustomer, ['customer']);
};

export const useCreateCustomer = (): UseMutationResult<
  Customer,
  NewApiError,
  CustomerFormValues
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createCustomer,
    mutationKey: ['createCustomer'],
    onSuccess: (data: Customer) => {
      queryClient.invalidateQueries(['customer', 'list']);
      queryClient.setQueryData(['customer', data.id], data);
    },
  });
};

export const useCustomers = <T = PaginatedData<Customer>>(
  params: FetchCustomersParams,
  options?: Pick<
    UseQueryOptions<PaginatedData<Customer>, NewApiError, T>,
    'select'
  >
): UseQueryResult<T, NewApiError> => {
  return useQuery({
    queryKey: ['customer', 'list', params],
    queryFn: () => fetchCustomers(params),
    enabled: !!params.page,
    keepPreviousData: true,
    select: options?.select,
  });
};

export const useDeleteCustomer = (): UseMutationResult<
  Customer,
  NewApiError,
  Customer
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteCustomer,
    mutationKey: ['deleteCustomer'],
    onSuccess: (data: Customer) => {
      queryClient.invalidateQueries(['customer', 'list']);
      queryClient.removeQueries(['customer', data.id]);
    },
  });
};

export const useCreatePaymentMethod = (): UseMutationResult<
  PaymentMethod,
  NewApiError,
  PaymentMethodCreateParams
> => {
  return useMutationWithInvalidation(
    createPaymentMethod,
    ['customer'],
    ['invoice']
  );
};
