import { getExportHref } from '../utils/apiUtils';
import { apiFront } from './api';
import { Report } from '../types/Report';

export function createReport(
  reportType: string,
  filters: Record<string, unknown> = {}
): Promise<Report> {
  return apiFront
    .get(getExportHref(reportType, { async: true, ...filters }))
    .then((response) => response.data);
}

export function getReport(id: string): Promise<Report> {
  return apiFront.get(`reports/${id}`).then((response) => response.data);
}

export interface CreateDownloadLinkResponse {
  data: string;
}

export function createDownloadLink(
  reportId: string
): Promise<CreateDownloadLinkResponse> {
  return apiFront
    .post(`reports/${reportId}/create_download_link`)
    .then((response) => response.data);
}
