import isDate from 'lodash/isDate';
import { NameValue } from '../../types/common';
import { FilterableFields, FiltersData, FilterUrlState } from './types';

export function buildUrlState(filters: FiltersData): FilterUrlState {
  return Object.fromEntries(
    Object.entries(filters).map(([filterKey, filterValues]) => {
      if (isDate(filterValues)) {
        return [filterKey, filterValues];
      }
      if (Array.isArray(filterValues)) {
        return [filterKey, filterValues.map(({ value }) => value)];
      }
      if (filterValues?.value) {
        return [filterKey, filterValues.value];
      }
      return [filterKey, filterValues];
    })
  );
}

export function buildFilterState(
  urlState: FilterUrlState,
  filtersCache: FiltersData
): FiltersData {
  return Object.fromEntries(
    Object.entries(urlState).map(([filterKey, filterValues]) => {
      let filterValue;
      if (Array.isArray(filterValues)) {
        filterValue = filterValues.map((v) => {
          const metadata = filtersCache[filterKey as FilterableFields];
          let found;
          if (Array.isArray(metadata)) {
            found = metadata.find((fm: NameValue) => fm.value === v);
          }
          return found || { name: v, value: v };
        });
      } else if (filterValues) {
        filterValue = filterValues;
      }
      return [filterKey, filterValue];
    })
  );
}
