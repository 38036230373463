import { useTranslation, TFunction, Trans } from 'react-i18next';

export function useTranslationObject(key: string): {
  // This type is not ideal but it works
  translations: Metadata;
  t: TFunction;
} {
  const { t } = useTranslation();
  const translations: Metadata = t(key, {
    returnObjects: true,
  });
  return {
    translations,
    t,
  };
}

export { Trans };
