import { apiFront } from './api';
import { PaginatedData, PaginationConfig } from '../types/common';
import { uploadToS3 } from './apiStorage';
import { isWindows } from '../utils/utils';
import { ImportedFile, ImportStatus, ImportType } from '../types/ImportFile';

export interface FetchImportParams extends PaginationConfig {
  type?: ImportType;
  status?: ImportStatus;
}

export async function fetchImportedFiles(
  params: Metadata
): Promise<PaginatedData<ImportedFile>> {
  const response = await apiFront.get(`bulk_uploads`, {
    params,
  });
  return response.data;
}

const getMimeType = (type: File['type']) => {
  // In windows, a csv will have a mime type corresponding to the
  // app that opens it or empty string if no app is configured.
  if (isWindows() && (type === 'application/vnd.ms-excel' || !type)) {
    return 'text/csv';
  }
  return type;
};

export interface UploadCsvFileParams {
  file: File;
  type: ImportType;
  metadata?: Metadata;
}

export async function uploadCsvFile({
  file,
  type: uploadType,
  metadata,
}: UploadCsvFileParams): Promise<ImportedFile> {
  const { name, size, type } = file;
  const { data } = await apiFront.post('bulk_uploads', {
    file_data: {
      file_name: encodeURIComponent(name),
      name,
      size,
      type: uploadType,
      content_type: getMimeType(type),
      metadata,
    },
  });
  await uploadToS3(file, data.upload_link);
  await apiFront.patch(`bulk_uploads/${data.id}/upload`);
  return data;
}
