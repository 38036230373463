import { PaymentMethod } from '../../types/PaymentMethod';
import { Paragraph } from '@increasecard/typed-components';
import { IconButtonModal } from '../common/IconButtonModal';
import { formatISODate } from '../../utils/dateUtils';
import { ApiErrorMessage } from '../ErrorMessage';
import { paymentErrorToApiError } from '../../utils/apiUtils';

export interface PaymentWarningButtonProps {
  paymentMethod?: PaymentMethod;
}

export function PaymentWarningButton({
  paymentMethod,
}: PaymentWarningButtonProps): JSX.Element | null {
  if (!paymentMethod || paymentMethod?.validated) return null;

  const error = paymentErrorToApiError(
    paymentMethod.data?.error || {
      rejection_code: 'PAYMENT_METHOD_NOT_VALIDATED',
    }
  );

  return (
    <>
      <IconButtonModal width="24px" height="24px" iconType="warning">
        <Paragraph>
          El medio de pago{' '}
          {paymentMethod.data.card_brand_name &&
            paymentMethod.data.last_four_digits &&
            `${paymentMethod.data.card_brand_name}, terminada en ${paymentMethod.data.last_four_digits} `}
          <br />
          presentó el siguiente error al intentar validarlo:
          <br />
          <ApiErrorMessage error={error} centered={true} />
          <br />
          El medio de pago se creó el: {formatISODate(paymentMethod.created_at)}
        </Paragraph>
      </IconButtonModal>
    </>
  );
}
