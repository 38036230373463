import { useTranslation } from 'react-i18next';
import { GoBackButton } from '../../components/common/GoBackButton';
import { Grid, H1, H2, Link as TCLink } from '@increasecard/typed-components';
import { RoundedCard } from '../../components/common/RoundedCard';
import { EventList } from '../../components/listing/EventList';
import { getPaymentStatus } from '../../utils/paymentUtils';
import { Payment } from '../../types/Payment';
import { DetailsBox, RowDefinition } from '../../components/common/DetailsBox';
import { Column } from '../../components/common/Column';
import { formatCurrency } from '../../utils/currencyUtils';
import { Link } from 'react-router-dom';
import { PaymentMethodDetailBox } from '../../components/paymentMethods/PaymentMethodDetailBox';
import { Row } from '../../components/common/Row';
import { PaymentActionsMenu } from './PaymentActionsMenu';
import { DetailHeader } from '../../components/common/DetailHeader';

export interface PaymentDetailProps {
  payment: Payment;
}

export function PaymentDetail({
  payment,
}: PaymentDetailProps): JSX.Element | null {
  const { t } = useTranslation();

  return (
    <Grid columnGap={1} rowGap={3}>
      <Row margin="0" justifyContent="space-between">
        <GoBackButton />
        <PaymentActionsMenu
          label="Acciones"
          payment={payment}
          context="payment detail"
        />
      </Row>
      <DetailHeader
        title={t('common.payment_order') + ' ID: '}
        titleb={payment.id}
        subTitle={payment.email}
        subtitleHref={`/customers/${payment.invoice.customer.id}/detail`}
      />
      <Column
        margin="0"
        gap="1rem"
        childrenFlex="1"
        className="grid-span-desktop-5 grid-span-mobile-12"
      >
        <RoundedCard variant="green" centered gap="1rem">
          <H2 weight="400">{t('screens.invoice_detail.payment_amount')}</H2>
          <H1>
            {formatCurrency(
              parseFloat(payment.amount) > 0 ? parseFloat(payment.amount) : 0,
              payment.currency
            )}
          </H1>
        </RoundedCard>
        <PaymentMethodDetailBox paymentMethod={payment.payment_method} />
      </Column>
      <PaymentDetailBox payment={payment} />
      <EventList
        events={payment.events}
        className="grid-span-desktop-12 grid-span-mobile-12"
      />
    </Grid>
  );
}

function PaymentDetailBox({ payment }: { payment: Payment }): JSX.Element {
  const { t } = useTranslation();
  const rowsConfig: RowDefinition[] = [
    {
      label: 'Fecha de creación',
      type: 'date',
      field: 'created_at',
    },
    {
      label: 'Ultima Actualización',
      type: 'date',
      field: 'updated_at',
    },
    {
      label: 'Estado',
      type: 'tag',
      tagType: 'payment',
      field: 'status',
      tagLabel: (payment) =>
        t(`data.payment.status.${getPaymentStatus(payment)}`),
    },
    {
      label: 'Divisa',
      type: 'string',
      field: 'currency',
    },

    {
      label: 'Id del Procesador',
      type: 'string',
      field: 'processor_reference_id',
    },
    {
      label: 'Detalle de rechazo',
      type: 'string',
      field: 'description',
    },
    {
      label: 'Factura',
      type: 'custom',
      field: 'invoice.id',
      // eslint-disable-next-line react/display-name
      valueGetter: (payment: Payment) => (
        <TCLink as={Link} to={`/invoices/${payment.invoice.id}/detail`}>
          {payment.invoice.id}
        </TCLink>
      ),
    },
  ];
  return (
    <DetailsBox
      className="grid-span-desktop-7 grid-span-mobile-2"
      data={payment}
      title={t('common.details')}
      rowsConfig={rowsConfig}
    />
  );
}
