import { TFunction } from 'i18next';
import { AnySchema } from 'yup';
import { CollectMethod } from '../../../types/CollectMethod';
import { NameValue } from '../../../types/common';
import { comafiPanelConfig } from './ComafiPanelConfig';
import { decidirPanelConfig } from './DecidirPanelConfig';
import { dlocalCountryPanelConfig } from './DlocalCountryPanelConfig';
import { dlocalCrossPanelConfig } from './DlocalCrossPanelConfig';
import { MPPanelConfig } from './MPPanelConfig';
import { payuPanelConfig } from './PayUPanelConfig';
import { superviellePanelConfig } from './SuperviellePanelConfig';
export interface PanelFormConfig {
  type: CollectMethod['type']; // Narrow this to actual parnter name list
  name: string;
  description: string;
  initialValues: {
    id?: string;
    metadata?: Metadata;
    country: string;
    max_payment_retries: string;
    retry_period_time: string;
    retry_period_unit: string;
  };
  status: CollectMethod['status'] | undefined;
  inputs: PanelFormInput[];
  pendingMessage?: string;
  showPendingMessage?: boolean;
  validationSchema: (() => AnySchema) | undefined;
}

interface BaseInputConfig {
  label: string;
  tooltipContent?: string;
  id: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean; // All fields are required by defult
}
interface TextInputConfig extends BaseInputConfig {
  type: 'text' | 'number';
}

interface SelectInputConfig extends BaseInputConfig {
  type: 'select';
  options: NameValue[];
}

type PanelFormInput = TextInputConfig | SelectInputConfig;

export type PanelConfigParams<CMtype> = {
  translate: TFunction;
  collectMethod?: CMtype;
  options?: Metadata;
};

const RETRIES_DEFAULTS = {
  max_payment_retries: '1',
  retry_period_time: '1',
  retry_period_unit: 'hours',
};

export function getDefaultValues(
  metadata: Metadata,
  collectMethod?: CollectMethod
): PanelFormConfig['initialValues'] {
  return Object.assign(metadata, RETRIES_DEFAULTS, collectMethod || {});
}

export function getConfigByType(
  t: TFunction,
  type: CollectMethod['type'],
  collectMethod?: CollectMethod
): PanelFormConfig {
  switch (type) {
    case 'comafi': {
      const config = comafiPanelConfig;
      return collectMethod?.type === 'comafi'
        ? config(t, collectMethod)
        : config(t, undefined);
    }
    case 'supervielle': {
      const config = superviellePanelConfig;
      return collectMethod?.type === 'supervielle'
        ? config(t, collectMethod)
        : config(t, undefined);
    }
    case 'decidir': {
      const config = decidirPanelConfig;
      return collectMethod?.type === 'decidir'
        ? config(t, collectMethod)
        : config(t, undefined);
    }
    case 'dlocal': {
      const config = dlocalCountryPanelConfig;
      return collectMethod?.type === 'dlocal'
        ? config(t, collectMethod)
        : config(t, undefined);
    }
    case 'dlocal_cross': {
      const config = dlocalCrossPanelConfig;
      return collectMethod?.type === 'dlocal_cross'
        ? config(t, collectMethod)
        : config(t, undefined);
    }
    // case 'mercadopago':
    case 'mercadopago_connect': {
      const config = MPPanelConfig;
      return collectMethod?.type === 'mercadopago_connect'
        ? config(t, collectMethod)
        : config(t, undefined);
    }
    case 'payments_os': {
      const config = payuPanelConfig;
      return collectMethod?.type === 'payments_os'
        ? config(t, collectMethod)
        : config(t, undefined);
    }
  }
}
