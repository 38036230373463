export interface UseClipboard {
  copy: (text?: string) => Promise<void>;
}
// TODO: turn this into a normal utility
export function useClipboard(defaultText = ''): UseClipboard {
  const copy = async (text = '') => {
    try {
      await navigator.clipboard.writeText(text || defaultText);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
  return { copy };
}
