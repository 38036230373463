import styled from 'styled-components';
import { DiscountsList } from './discounts/DiscountsList';
import { useTranslation } from 'react-i18next';
import { ScreenSeparator } from '../../components/common/ScreenSeparator';
import { TaxesList } from './taxes/TaxesList';
import { Header } from '../../components/common/Header';
import { NewExtraModal } from './NewExtraModal';
import { OneTimeCostList } from './oneTimeCosts/OneTimeCostList';
import { PayAuthorizations } from '../authorizations/PayAuthorizations';
import { AditionalCostsList } from './aditionalCosts/AditionalCostsList';
import { ExtraType } from '../../types/common';
import { useModal } from '../../hooks/useModal';

const Spacer = styled.div`
  height: 6rem;
`;

export function ExtrasScreen(): JSX.Element {
  const { t } = useTranslation();
  const newExtraModal = useModal<ExtraType>(false);

  return (
    <>
      <Header
        title={t('screens.extras.discounts_title')}
        description={t('screens.extras.discounts_description')}
        primaryButton={{
          text: t('screens.new_discount.title'),
          onClick: () => {
            newExtraModal.open('discount');
          },
          allowedAuthorization: PayAuthorizations.createEditExtra,
        }}
      />
      <ScreenSeparator />
      <DiscountsList />
      <Spacer />
      <Header
        title={t('common.additional_costs')}
        description={t('screens.extras.aditional_cost_description')}
        primaryButton={{
          text: t('common.new_aditional_cost'),
          onClick: () => {
            newExtraModal.open('additional_cost');
          },
          allowedAuthorization: PayAuthorizations.createEditExtra,
        }}
      />
      <ScreenSeparator />
      <AditionalCostsList />
      <Spacer />
      <Header
        title={t('screens.extras.taxes_title')}
        description={t('screens.extras.taxes_description')}
        primaryButton={{
          text: t('common.new_tax'),
          onClick: () => {
            newExtraModal.open('tax');
          },
          allowedAuthorization: PayAuthorizations.createEditExtra,
        }}
      />
      <ScreenSeparator />
      <TaxesList />
      <Spacer />
      <Header
        title={t('screens.extras.one_time_cost_title')}
        description={t('screens.extras.one_time_cost_description')}
        primaryButton={{
          text: t('common.new_one_time_cost'),
          onClick: () => {
            newExtraModal.open('one_time_cost');
          },
          allowedAuthorization: PayAuthorizations.createEditExtra,
        }}
      />
      <ScreenSeparator />
      <OneTimeCostList />
      <NewExtraModal
        onClose={() => newExtraModal.close()}
        onExited={() => newExtraModal.setData(null)}
        visible={newExtraModal.isOpen}
        extraType={newExtraModal.data}
      />
    </>
  );
}
