import { useParams } from 'react-router';
import { useQueryParams } from '../../hooks/useQueryParams';

export interface UseCheckoutParams {
  accountId: string;
  customerId: string;
  productId: string;
  planId: string;
  subscriptionId: string;
  invoiceId: string;
}
export function useCheckoutParams(): UseCheckoutParams {
  const {
    customer_id: customerId,
    product_id: productId,
    plan_id: planId,
    subscription_id: subscriptionId,
    invoice_id: invoiceId,
  } = useQueryParams();
  const { account_id: accountId } = useParams<{ account_id: string }>();

  return {
    accountId,
    customerId,
    productId,
    planId,
    subscriptionId,
    invoiceId,
  };
}
