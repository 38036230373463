/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useState, useEffect } from 'react';
import { list2NameValue } from '../utils/utils';
import { getCountryConfig } from '../utils/countryUtils';
import { useCollectMethods } from '../globalQueries/Queries';
import { CollectMethod } from '../types/CollectMethod';
import { Country, Currency, NameValue } from '../types/common';

// TODO: This should use collect methods own defined currencies
// Currencies are now fixed for each country. This hook is left just in case...
export function useCurrencyOptions(country: Country, currency?: Currency) {
  const [currencyOptions, setCurrencyOptions] = useState<NameValue[]>([]);
  const { data: collectMethods } = useCollectMethods({
    country,
    currency,
    status: 'enabled',
  });

  useEffect(() => {
    const { currencies: countryCurrencies } = getCountryConfig(country);

    const currencies = addCollectMethodsCurrencies(
      countryCurrencies,
      collectMethods
    );

    const currencyOptions = list2NameValue(currencies, 'data.currencies');

    setCurrencyOptions(currencyOptions);
  }, [country, collectMethods]);

  return currencyOptions;
}

function addCollectMethodsCurrencies(
  currencies: string[],
  collectMethods?: CollectMethod[]
) {
  if (collectMethods) {
    return Array.from(
      new Set(
        collectMethods.map((collectMethod) => collectMethod.currencies).flat(1)
      )
    );
  }
  return currencies;
}
