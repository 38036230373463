import { Trans, useTranslation } from 'react-i18next';
import { Link, Paragraph } from '@increasecard/typed-components';
import { Row } from '../../../components/common/Row';
import { AccountBrandingSettings } from '../../../types/Account';
import { CSSProperties } from 'react';

export interface ContactLabelProps {
  brandingSettings?: AccountBrandingSettings;
  justifyContent?: CSSProperties['justifyContent'];
}

export function ContactLabel({
  brandingSettings,
  justifyContent = 'left',
}: ContactLabelProps): JSX.Element | null {
  const { t } = useTranslation();
  if (
    !brandingSettings ||
    (!brandingSettings.contact_email && !brandingSettings.support_url)
  ) {
    return null;
  }
  const base = 'screens.customer_checkout';
  let i18nkey = '';
  const { contact_email, support_url } = brandingSettings;
  if (contact_email && support_url) {
    i18nkey = `${base}.support_channel_mail_url`;
  } else if (contact_email) {
    i18nkey = `${base}.support_channel_mail`;
  } else if (support_url) {
    i18nkey = `${base}.support_channel_url`;
  } else {
    i18nkey = `${base}.any_question`;
  }

  return (
    <Row margin="0" justifyContent={justifyContent}>
      <Paragraph>
        <Trans
          t={t}
          i18nKey={i18nkey}
          values={brandingSettings}
          components={[
            <Link key="1" href={support_url} target="_blank" />,
            <Link key="2" href={`mailto:${contact_email}`} />,
          ]}
        />
      </Paragraph>
    </Row>
  );
}
