import React from 'react';
import { ErrorBoundary, PublicBaseProvider } from '@increasecard/increase-core';
import { ReactNode } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import config, {
  environment as configEnvironment,
  productName,
} from './config';
import { increaseCoreEnvironmentFromConfigEnvironment } from './AppRoutes';

import { useInitErrorTracker } from './utils/errorTracker';

export interface PublicRouteProps extends RouteProps {
  children: ReactNode;
}

export interface ErrorTrackingInitProps {
  children: ReactNode;
}

function ErrorTrackingInit({ children }: ErrorTrackingInitProps) {
  useInitErrorTracker();
  return <>{children}</>;
}

export function PublicRoute({
  children,
  ...rest
}: PublicRouteProps): JSX.Element | null {
  const { errorTracking } = config;
  const environment =
    increaseCoreEnvironmentFromConfigEnvironment(configEnvironment);
  return (
    <Route {...rest}>
      <PublicBaseProvider
        settings={{
          environment,
          productName,
          errorTracking: {
            ...errorTracking,
            public: true,
          },
        }}
      >
        <ErrorTrackingInit>
          <ErrorBoundary>{children}</ErrorBoundary>
        </ErrorTrackingInit>
      </PublicBaseProvider>
    </Route>
  );
}
