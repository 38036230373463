import { useTranslation } from 'react-i18next';
import { H2, Paragraph, Grid } from '@increasecard/typed-components';
import { ApiErrorMessage } from '../../../components/ErrorMessage';
import { OneTimeCostForm, NewOneTimeCostFormValues } from './OneTimeCostForm';
import { OneTimeCost } from '../../../types/OneTimeCost';
import { useUpdateOneTimeCost } from './oneTimeCostQueries';

export interface EditOneTimeCostScreenProps {
  onClose: () => void;
  oneTimeCost: OneTimeCost;
}

export function EditOneTimeCostScreen({
  onClose,
  oneTimeCost,
}: EditOneTimeCostScreenProps): JSX.Element {
  const { t } = useTranslation();
  const { isLoading, error, isSuccess, mutate } = useUpdateOneTimeCost();

  const handleSubmit = (otc: NewOneTimeCostFormValues) => {
    mutate({ id: oneTimeCost.id, ...otc });
  };

  return (
    <Grid rowGap={2}>
      <H2>{t('common.edit_title', { entity: t('common.one_time_cost') })}</H2>
      {!isLoading && isSuccess ? (
        <Paragraph>
          {t('common.success_edit', { entity: t('common.one_time_cost') })}
        </Paragraph>
      ) : (
        <OneTimeCostForm
          initialValues={oneTimeCost}
          onCancel={onClose}
          onSubmit={handleSubmit}
          isLoading={isLoading}
          editMode={true}
        />
      )}
      <ApiErrorMessage error={error} />
    </Grid>
  );
}
