import { apiFront } from './api';
import { CollectMethod } from '../types/CollectMethod';

export interface CollectMethodsParams {
  country?: string;
  currency?: string;
  status?: string;
}

export async function fetchCollectMethods<T extends CollectMethodsParams>(
  params?: T
): Promise<CollectMethod[]> {
  const response = await apiFront.get(`collect_methods`, {
    params,
  });
  // Filter out mercadopago gateway CMs since they are deprecated and can't be configured.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return response.data.filter((c: any) => c.type !== 'mercadopago');
}

// We dont have the metadata of each collect method typed yet.
// So for now let pass down anything and any error would be reported by the api
export async function createCollectMethod(
  params: Metadata
): Promise<CollectMethod> {
  const response = await apiFront.post(`collect_methods`, params);
  return response.data;
}

export async function updateCollectMethod(
  params: Metadata
): Promise<CollectMethod> {
  const response = await apiFront.patch(`collect_methods/${params.id}`, params);
  return response.data;
}

export async function createOrUpdateCollectMethod(
  params: Metadata
): Promise<CollectMethod> {
  if (params.id) {
    return updateCollectMethod(params);
  } else {
    return createCollectMethod(params);
  }
}

export interface DefaultCMParams {
  card: string;
  cbu: string;
  ticket: string;
}
export async function setDefaultCollectMethods(
  params: DefaultCMParams
): Promise<DefaultCMParams> {
  const response = await apiFront.post(`collect_methods/set_defaults`, params);
  return response.data;
}
