import { CellText, Paragraph } from '@increasecard/typed-components';
import { useTranslation } from 'react-i18next';
import { Box } from '../../../components/common/Box';
import { ApiErrorMessage } from '../../../components/ErrorMessage';
import { NewApiError } from '../../../types/NewApiError';
import { Subscription } from '../../../types/Subscription';
import { getCustomerName } from '../../../utils/utils';
import { SubscriptionActionTypes } from './ActionsModal';

export interface MassiveActionResultsProps {
  subscriptions: Subscription[];
  error: NewApiError | null;
  isSuccess: boolean;
  actionType: SubscriptionActionTypes;
}
export function MassiveActionResults({
  subscriptions,
  error,
  actionType,
  isSuccess,
}: MassiveActionResultsProps): JSX.Element | null {
  const { t } = useTranslation();
  const getSubscriptionList = () => {
    const subscriptionNames = error
      ? error.metadata.subscriptions.map((id: string) => {
          const sub = subscriptions.find((s: Subscription) => s.id === id);
          if (sub) {
            return `${sub.product.name} - ${sub.plan.name} / ${getCustomerName(
              sub.customer
            )}`;
          }
          return id;
        })
      : [];
    return subscriptionNames.map((sn: string, index: number) => (
      <CellText key={index}>{sn}</CellText>
    ));
  };

  const getSuccessMessage = (actionType: SubscriptionActionTypes): string => {
    let successMessage = t('screens.subscriptions.massive_action_success', {
      count: subscriptions.length,
    });
    switch (actionType) {
      case 'ask_payment_method':
        successMessage = t(
          'screens.subscriptions.massive_ask_payment_method_success',
          {
            count: subscriptions.length,
          }
        );
        break;
      case 'pause':
        successMessage +=
          '. ' +
          t('screens.subscriptions.massive_pause_success', {
            count: subscriptions.length,
          });
        break;
      case 'cancel':
        successMessage = t('screens.subscriptions.massive_cancel_success', {
          count: subscriptions.length,
        });
        break;
    }
    return successMessage;
  };

  if (isSuccess) {
    return <Paragraph>{getSuccessMessage(actionType)}</Paragraph>;
  }

  if (error) {
    if (error.code.indexOf('COULD_NOT_') === -1) {
      return <ApiErrorMessage error={error} />;
    }

    let errorTitle = t('screens.subscriptions.massive_action_error', {
      errorCount: error.metadata.subscriptions.length,
      totalCount: subscriptions.length,
      actionType: actionType,
    });
    const partialSuccess =
      subscriptions.length - error.metadata.subscriptions.length;

    if (actionType === 'ask_payment_method') {
      errorTitle = !partialSuccess
        ? t('screens.subscriptions.massive_ask_payment_method_error_all_failed')
        : t('screens.subscriptions.massive_ask_payment_method_error', {
            errorCount: error.metadata.subscriptions.length,
            totalCount: subscriptions.length,
          });
    }
    return (
      <Box maxHeight="150px" overflowY="auto">
        {partialSuccess && (
          <Paragraph>
            {t(
              'screens.subscriptions.massive_ask_payment_method_partial_success',
              {
                partialSuccess: partialSuccess,
                totalCount: subscriptions.length,
              }
            )}
          </Paragraph>
        )}
        <Paragraph>{errorTitle}</Paragraph>
        {getSubscriptionList()}
      </Box>
    );
  }
  return null;
}
