import config from '../../../config';

declare global {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  class Decidir {
    constructor(key: string, fraudDetection: boolean);
    public createToken: (...params: any) => {
      token: string;
    };
    public cardType: (params: string) => string;
    public setPublishableKey: (key: string) => void;
    public setTimeout: (ms: number) => void;
  }
  /* eslint-enable @typescript-eslint/no-explicit-any */
  interface Window {
    Decidir: typeof Decidir;
  }
}

export const loadDecidirSdk = (accountPublicKey: string): Promise<Decidir> => {
  return new Promise((resolve, reject) => {
    const sdkScript = document.createElement('script');
    sdkScript.src = config.collectMethods.decidir.sdkUrl;
    sdkScript.async = true;
    sdkScript.onload = () => {
      const disableFraudDetection = true; // ¬¬
      const decidir = new window.Decidir(
        config.collectMethods.decidir.apiUrl,
        disableFraudDetection
      );
      decidir.setPublishableKey(accountPublicKey);
      decidir.setTimeout(5000);
      resolve(decidir);
    };
    sdkScript.onerror = reject;
    document.body.appendChild(sdkScript);
  });
};
