import { ReactNode } from 'react';
import {
  H1,
  Paragraph,
  Button,
  ButtonProps,
} from '@increasecard/typed-components';
import { Row } from './Row';
import { usePayAuthorizations } from '../../features/authorizations/usePayAuthorizations';
import { AuthorizationList } from '@increasecard/increase-core';

export interface HeaderButtonProps {
  onClick: ButtonProps['onClick'];
  icon?: ButtonProps['icon'];
  text: ReactNode;
  allowedAuthorization?: AuthorizationList;
  disabled?: boolean;
}

export interface HeaderProps {
  title: ReactNode;
  description?: ReactNode;
  primaryButton?: HeaderButtonProps;
  outlineButton?: HeaderButtonProps;
  invisibleButton?: HeaderButtonProps;
}

export function Header({
  title,
  description,
  primaryButton,
  outlineButton,
  invisibleButton,
}: HeaderProps): JSX.Element {
  const { userCan } = usePayAuthorizations();

  function buttonIsAuthorized(button: HeaderButtonProps) {
    if (button.allowedAuthorization) {
      return userCan(button.allowedAuthorization);
    }
    return true;
  }

  return (
    <>
      <Row
        justifyContent="space-between"
        margin="0"
        marginBottom={!description ? '2rem' : 0}
        flexWrap="wrap"
        gap="1rem"
      >
        <H1>{title}</H1>
        <Row gap="1rem" margin="0" width="auto" flexWrap="wrap">
          {invisibleButton ? (
            <Button
              buttonType="invisible"
              onClick={invisibleButton.onClick}
              icon={invisibleButton.icon}
              disabled={
                invisibleButton.disabled || !buttonIsAuthorized(invisibleButton)
              }
            >
              {invisibleButton.text}
            </Button>
          ) : null}
          {outlineButton ? (
            <Button
              buttonType="outline"
              onClick={outlineButton.onClick}
              icon={outlineButton.icon}
              disabled={
                outlineButton.disabled || !buttonIsAuthorized(outlineButton)
              }
            >
              {outlineButton.text}
            </Button>
          ) : null}
          {primaryButton ? (
            <Button
              buttonType="primary"
              onClick={primaryButton.onClick}
              icon={primaryButton.icon}
              disabled={
                primaryButton.disabled || !buttonIsAuthorized(primaryButton)
              }
            >
              {primaryButton.text}
            </Button>
          ) : null}
        </Row>
      </Row>
      {description && (
        <Paragraph style={{ marginTop: '9px' }}>{description}</Paragraph>
      )}
    </>
  );
}
