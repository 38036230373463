import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FullPageSpinner } from '../../components/common/FullPageSpinner';
import { ApiErrorMessage } from '../../components/ErrorMessage';
import { useExtraByType } from './useExtraByType';
import { ExtraType } from '../../types';
import { Grid, H1 } from '@increasecard/typed-components';
import { Row } from '../../components/common/Row';
import { GoBackButton } from '../../components/common/GoBackButton';
import { DetailsBox, RowDefinition } from '../../components/common/DetailsBox';
import { formatCurrencyType } from '../../utils/currencyUtils';

export function ExtraDetailScreen(): JSX.Element | null {
  const { t } = useTranslation();
  const { type, id } = useParams<{ type: ExtraType; id: string }>();
  const extraQuery = useExtraByType(type, id);

  if (extraQuery.isError) {
    return (
      <ApiErrorMessage
        error={extraQuery.error}
        onClick={() => extraQuery.refetch()}
      />
    );
  }

  if (extraQuery.isLoading || !extraQuery.data) {
    return <FullPageSpinner height="50vh" size={32} />;
  }

  const rowsConfig: RowDefinition[] = [
    {
      label: 'ID',
      type: 'string',
      field: 'id',
    },
    {
      label: 'Tipo',
      field: 'type',
      type: 'string',
      valueGetter: (extra) => t(`data.extras.types.${extra.type}`),
    },
    {
      label: 'Fecha de creación',
      type: 'date',
      field: 'created_at',
    },
    {
      label: 'Monto',
      type: 'string',
      field: 'amount',
      valueGetter: (extra) =>
        formatCurrencyType(extra.type, extra.amount, extra.currency),
    },
    {
      label: 'Pais',
      field: 'country',
      type: 'string',
      valueGetter: (extra) => t(`data.countries.${extra.country}`),
    },

    {
      label: 'Duración',
      type: 'string',
      field: 'cycle_amount',
      valueGetter: (extra) => `${extra.cycle_amount} ciclos`,
    },
  ];

  return (
    <Grid columnGap={1} rowGap={3}>
      <Row margin="0" justifyContent="space-between">
        <GoBackButton />
        <Row margin="0" gap="1rem" width="auto"></Row>
      </Row>
      <H1>{`${t(`common.${type}`)}: ${extraQuery.data.name}`}</H1>
      <DetailsBox
        showAsGrid={true}
        data={extraQuery.data}
        title={t('common.details')}
        rowsConfig={rowsConfig}
      />
    </Grid>
  );
}
