import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { MenuAction } from '../../../components/common/Menu';
import { FrontPlan, NewApiError } from '../../../types';
import { useClipboard } from '../../../hooks/useClipboard';
import { ModalControls, useModal } from '../../../hooks/useModal';
import { useUIEventTracker } from '../../../hooks/useUIEventTracker';
import { usePayAuthorizations } from '../../authorizations/usePayAuthorizations';
import { useChangePlanStatus } from '../planQueries';

type PlanActionsConfig = MenuAction<PlanAction>[];
export interface UsePlanActions {
  actions: PlanActionsConfig;
  goToDetails: (planId: string, clickContext: string) => void;
  priceModal: ModalControls<FrontPlan[]>;
  qrModal: ModalControls<FrontPlan>;
  changeStatusError: NewApiError | null;
}

export type PlanAction =
  | 'view_details'
  | 'edit'
  | 'copy_checkout_link'
  | 'generate_qr'
  | 'change_price'
  | 'toggle_plan_status';

export function usePlanActions(
  trackingContext: string,
  omit?: PlanAction[]
): UsePlanActions {
  const { t } = useTranslation();
  const history = useHistory();
  const { canCreateEditProductPlan } = usePayAuthorizations();
  const clipboard = useClipboard();
  const trackEvent = useUIEventTracker(trackingContext);
  const priceModal = useModal<FrontPlan[]>(false);
  const qrModal = useModal<FrontPlan>(false);
  const {
    enablePlan,
    disablePlan,
    error: changeStatusError,
  } = useChangePlanStatus();

  const goToDetails = (planId: string, clickContext: string) => {
    trackEvent('view_plan_detail', { context: clickContext });
    history.push(`/plans/${planId}/detail`);
  };

  const availableActions: PlanActionsConfig = [
    {
      name: 'view_details',
      label: t('common.show_details'),
      cb: (plan: FrontPlan) => goToDetails(plan.id, 'list row menu'),
    },
    {
      name: 'edit',
      label: t('common.edit'),
      cb: (plan: FrontPlan) => history.push(`plans/${plan.id}/edit`),
      disabled: () => !canCreateEditProductPlan(),
    },
    {
      name: 'toggle_plan_status',
      label: (plan: FrontPlan) =>
        plan.status !== 'disabled' ? t('common.disable') : t('common.enable'),
      cb: (plan: FrontPlan) =>
        plan.status === 'disabled' ? enablePlan(plan.id) : disablePlan(plan.id),
      disabled: () => !canCreateEditProductPlan(),
    },
    {
      name: 'change_price',
      label: (plan: FrontPlan) => {
        return t('screens.plans.change_price', {
          count: Array.isArray(plan) ? plan.length : 1,
        });
      },
      cb: (plan: FrontPlan) =>
        priceModal.open(Array.isArray(plan) ? plan : [plan]),
      disabled: () => !canCreateEditProductPlan(),
    },
    {
      name: 'copy_checkout_link',
      tooltipLabel: t('common.copied'),
      label: t('common.copy_subscription_link'),
      cb: (plan: FrontPlan) => clipboard.copy(plan.checkout_link),
    },
    {
      name: 'generate_qr',
      label: t('common.generate_qr'),
      cb: (plan: FrontPlan) => qrModal.open(plan),
    },
  ];

  const actions = omit
    ? availableActions.filter((action) => {
        return !omit.includes(action.name as PlanAction);
      })
    : availableActions;

  return { actions, goToDetails, priceModal, qrModal, changeStatusError };
}
