import { useOnClickOutside } from '@increasecard/typed-components';
import { ChangeEvent, useRef, useState } from 'react';
import styled from 'styled-components';
import { Box } from '../../../../components/common/Box';

const CustomInput = styled.input`
  ${({ theme }) => theme.typography.cell}
  width: 100%;
  ::placeholder {
    color: var(--color-gray-grayBold);
  }
`;

const SuggestionList = styled.div.attrs({ role: 'menu' })`
  position: absolute;
  top: calc(100% + 6px);
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 3px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  min-width: 150px;
  max-width: 100%;
  box-shadow: ${({ theme }) => theme.shadows.rest};
`;

export const ButtonMenuItem = styled.button`
  border: 0;
  border-radius: 4px;
  padding: 0.5rem;
  ${({ theme }) => theme.typography.cell}
  background: var(--color-gray-white);
  cursor: pointer;
  :hover {
    background: var(--color-increase-light);
  }
`;

interface SuggestionInputProps extends React.ComponentPropsWithoutRef<'input'> {
  suggestions: string[];
  onSelectSuggestion: (s: string) => void;
}

export function SuggestionInput({
  suggestions,
  onSelectSuggestion,
  onChange,
  ...rest
}: SuggestionInputProps): JSX.Element {
  const menuRef = useRef<HTMLDivElement>(null);
  const [search, setSearch] = useState('');
  const [isInFocus, setIsInFocus] = useState(false);

  useOnClickOutside([menuRef], () => setIsInFocus(false));

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e);
    setSearch(e.target.value);
  };

  const showSuggestions =
    isInFocus && search.length > 0 && suggestions.length > 0;

  return (
    <Box position="relative" display="inherit">
      <CustomInput
        {...rest}
        onChange={handleSearchChange}
        onFocus={() => setIsInFocus(true)}
        value={search}
      />
      {showSuggestions ? (
        <SuggestionList ref={menuRef}>
          {suggestions.map((s) => (
            <ButtonMenuItem
              key={s}
              onClick={() => {
                onSelectSuggestion(s);
                setIsInFocus(false);
              }}
            >
              {s}
            </ButtonMenuItem>
          ))}
        </SuggestionList>
      ) : null}
    </Box>
  );
}
