import { CopyIcon } from '@increasecard/icons';
import { Button, ButtonProps } from '@increasecard/typed-components';
import { useTranslation } from 'react-i18next';
import { useClipboard } from '../../hooks/useClipboard';
import { Tooltip } from './Tooltip';

export interface CopyButtonProps {
  value: string;
  label?: string;
  buttonProps?: Omit<ButtonProps, 'children'>;
}

export function CopyButton({
  value,
  label,
  buttonProps,
}: CopyButtonProps): JSX.Element {
  const { t } = useTranslation();
  const { copy } = useClipboard();

  return (
    <Tooltip
      on={['click']}
      content={t('common.copied')}
      timeout={1000}
      trigger={
        <div>
          <Button
            onClick={() => copy(value)}
            variant="invisible"
            icon={<CopyIcon />}
            type="button"
            {...buttonProps}
          >
            {label}
          </Button>
        </div>
      }
    />
  );
}
