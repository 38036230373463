import { TFunction } from 'i18next';
import * as Yup from 'yup';
import {
  RequiredString,
  RequiredPercentage,
  RequiredInteger,
  RequiredAmount,
} from '../../../schemas/common';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function AditionalCostSchema(t: TFunction) {
  return Yup.object({
    name: RequiredString(),
    external_id: Yup.string().nullable().notRequired(),
    country: RequiredString(),
    description: Yup.string().nullable().notRequired(),
    amount: Yup.string().when('type', {
      is: 'percentage',
      then: RequiredPercentage(t),
      otherwise: RequiredAmount(t),
    }),
    type: RequiredString(),
    duration: RequiredString(),
    cycle_amount: Yup.number().when('duration', {
      is: 'periodic',
      then: RequiredInteger(t),
      otherwise: Yup.number().nullable().notRequired(),
    }),
    currency: Yup.string().when('type', {
      is: 'flat',
      then: RequiredString(),
      otherwise: Yup.string().nullable().notRequired(),
    }),
  });
}
