import { useState, useEffect } from 'react';
import { apiFront, apiCheckout } from '../services/api';
import { COUNTRIES } from '../localizations';

export interface CountryStateOption {
  iso_code: string;
  name: string;
}
export interface UseStatesInterface {
  countryStates: CountryStateOption[];
  error: unknown;
}

export interface UseCountryStatesHook {
  countryStates: CountryStateOption[];
  error: unknown | null;
}

type CountryKeyType = keyof typeof COUNTRIES;

function useStates(
  country: CountryKeyType | undefined,
  isCheckout: boolean
): UseCountryStatesHook {
  const [countryStates, setCountryStates] = useState<CountryStateOption[]>([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getStates = async () => {
      try {
        const res = await (isCheckout ? apiCheckout : apiFront).get(
          `countries/${country}/states`
        );
        setCountryStates(
          res.data.sort((a: { name: string }, b: { name: string }) =>
            a.name < b.name ? -1 : 1
          )
        );
      } catch (e: Metadata) {
        // TODO: improve error treatment
        setError(e.data);
      }
    };
    if (country) {
      getStates();
    }
  }, [country, isCheckout]);

  return { countryStates, error };
}

export const useCountryStates = (
  country: CountryKeyType
): UseCountryStatesHook => useStates(country, false);

export const useCheckoutCountryStates = (
  country: CountryKeyType
): UseCountryStatesHook => useStates(country, true);
