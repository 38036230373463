import { useCurrency } from '@increasecard/increase-core';
import { startOfMonth } from 'date-fns';
import { useUrlState, UseUrlState } from '../../hooks/useUrlState';
import { Currency } from '../../types/common';

type Filters = {
  createdAtFrom: Date;
  createdAtTo: Date;
  currency: Currency | null;
};

const initialDates = {
  createdAtFrom: startOfMonth(new Date()),
  createdAtTo: new Date(),
};

export function useMetricsFilters(): UseUrlState<Filters> {
  const currency = useCurrency();
  return useUrlState<Filters>({ ...initialDates, currency }, [
    'createdAtFrom',
    'createdAtTo',
  ]);
}
