import { TFunction, useTranslation } from 'react-i18next';
import { useValidateNextBillingDate } from '../subscriptionQueries';
import { NBDValidation } from '../../../services/SubscriptionService';
import { Paragraph } from '@increasecard/typed-components';
import { ErrorMessage } from '../../../components/ErrorMessage';

export interface BillingDateInfoProps {
  subscriptionId?: string;
  newBillingDate?: Date;
}

const buildMessage = (validation: NBDValidation, _t: TFunction) => {
  const { relative_to, days_diff } = validation;
  if (relative_to === 'today') {
    return `La próxima factura se emitirá dentro de ${days_diff} dias.`;
  } else if (relative_to === 'last_invoice') {
    return `La próxima factura tendrá una diferencia de ${days_diff} días respecto al último cobro de esta suscripción.`;
  }
};

export function BillingDateInfo(
  props: BillingDateInfoProps
): JSX.Element | null {
  const { data } = useValidateNextBillingDate(props);
  const { t } = useTranslation();
  if (!data) return null;
  const message = buildMessage(data, t);
  if (data.alert === 'ok') {
    return <Paragraph>{message}</Paragraph>;
  }
  return <ErrorMessage>{message}</ErrorMessage>;
}
