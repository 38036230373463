export interface ImportValidationError {
  type: string;
  message: string;
  attribute: string;
  count: number;
}

export interface ImportLineError {
  line: number;
  code: string;
  validation_errors: Record<string, ImportValidationError[]>;
}

export interface ImportProcessingErrors {
  error: {
    code: string;
  };
  line_errors: ImportLineError[];
}

export type ImportStatus =
  | 'upload'
  | 'uploading'
  | 'uploaded'
  | 'processing'
  | 'processed'
  | 'processed_with_errors'
  | 'failed';

export interface ImportedFile {
  id: string;
  name: string;
  fileable_type: string;
  fileable_id: string;
  type: string;
  storage_ref: string;
  status: ImportStatus;
  created_at: string;
  updated_at: string;
  processing_errors: ImportProcessingErrors;
  total_lines: number;
  processed_lines: number;
}

export enum ImportType {
  Customer = 'customer',
  Subscription = 'subscription',
  Invoice = 'invoice',
  SubscriptionUpdateAmount = 'subscription_update_amount',
}
