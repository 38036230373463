import { getDefaultValues, PanelFormConfig } from './PanelConfig';
import { TFunction } from 'i18next';
import { DlocalCrossCollectMethod } from '../../../types/CollectMethod';
import { DlocalSchema } from '../CollectMethodsSchema';

export function dlocalCrossPanelConfig(
  translate: TFunction,
  collectMethod?: DlocalCrossCollectMethod
): PanelFormConfig {
  const formData = {
    name: 'Dlocal cross border',
    country: 'ARG',
    metadata: {
      x_login: '',
      x_trans_key: '',
      secret: '',
      api_key: '',
    },
  };

  const initialValues = getDefaultValues(formData, collectMethod);
  const transBase = 'screens.collect_methods.dlocal';

  // If collect method is enabled, dont validate it since it cant be modified.
  // This is a hack for when api does not return api_login or api_key.
  const validationSchema = collectMethod ? undefined : () => DlocalSchema();
  return {
    type: 'dlocal_cross',
    name: 'Dlocal cross border',
    description: translate(`${transBase}.description_cross`),
    initialValues,
    status: collectMethod?.status,
    inputs: [
      {
        label: 'x_login',
        id: 'metadata.x_login',
        type: 'text',
      },
      {
        label: 'x_trans_key',
        id: 'metadata.x_trans_key',
        type: 'text',
      },
      {
        label: 'secret_key',
        id: 'metadata.secret',
        type: 'text',
        tooltipContent: translate('screens.collect_methods.secret_fields'),
        required: !collectMethod,
      },
      {
        label: 'api_key',
        id: 'metadata.api_key',
        type: 'text',
        tooltipContent: translate('screens.collect_methods.secret_fields'),
        required: !collectMethod,
      },
    ],
    pendingMessage: translate(`${transBase}.config_pending_message`),
    validationSchema,
  };
}
