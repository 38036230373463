import {
  createContext,
  ReactNode,
  Dispatch,
  useState,
  useContext,
} from 'react';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useUrlState } from '../../hooks/useUrlState';
import { FilterableFields, FilterFormConfig, FiltersData } from './types';
import { buildFilterState, buildUrlState } from './helpers';

interface ContextValue {
  filterState: FiltersData;
  setFilters: Dispatch<FiltersData>;
  deleteFilter: (filterName: FilterableFields) => void;
  config: FilterFormConfig[];
}

const FiltersContext = createContext<ContextValue>({
  filterState: {},
  setFilters: () => ({}),
  deleteFilter: (_fn) => undefined,
  config: [],
});

export interface FilterStateProviderProps {
  children: ReactNode;
  defaultValues: FiltersData;
  config: FilterFormConfig[];
  storageKey: string;
  dateFields?: FilterableFields[];
}

export function FilterStateProvider({
  children,
  defaultValues,
  config,
  storageKey,
  dateFields,
}: FilterStateProviderProps): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setCache, getCache] = useLocalStorage<FiltersData>(storageKey, {});
  // CHeck if datefields are needed here.
  const [urlState, setUrlState] = useUrlState({}, dateFields);
  const [filterState, setFilterState] = useState<FiltersData>(() => {
    // Try to rebuild the filter state with data from cache.
    const filterCache = getCache();
    const state = buildFilterState(urlState, filterCache);

    const newState = {
      ...defaultValues,
      ...state,
    };

    return newState;
  });

  const setFilters = (filters: FiltersData) => {
    // Saves filter state to context value
    const newFilters = { ...filterState, ...filters };
    setFilterState(newFilters);

    // Transforms FilterData to SubscriptionFilters, so it can be represented as an URL;
    const newFilterState = buildUrlState(newFilters);
    setUrlState(newFilterState);

    // Save filters in localStorage. This way we can re-build the filter state
    // with urlState and the localStorage cache
    setCache(newFilters);
  };

  const deleteFilter = (filterKey: FilterableFields) => {
    setFilters({ [filterKey]: undefined });
  };

  return (
    <FiltersContext.Provider
      value={{ filterState, setFilters, deleteFilter, config }}
    >
      {children}
    </FiltersContext.Provider>
  );
}

// This hook is for internal use only.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useFiltersState() {
  return useContext(FiltersContext);
}
