import { useTranslation } from 'react-i18next';
import { H2, Paragraph, Grid, Link } from '@increasecard/typed-components';
import { useWithCountryCurrency } from '../../../utils/countryUtils';
import { ApiErrorMessage } from '../../../components/ErrorMessage';
import { DiscountForm, DiscountFormValues } from './DiscountForm';
import { useCreateDiscount } from './discountQueries';
import { Link as RouterLink } from 'react-router-dom';

const INITIAL_VALUES: DiscountFormValues = {
  type: 'flat',
  duration: 'periodic',
  cycle_amount: 1,
};

export interface NewDiscountScreenProps {
  onClose: () => void;
}

export function NewDiscountScreen({
  onClose,
}: NewDiscountScreenProps): JSX.Element {
  const { t } = useTranslation();
  const initialValues = useWithCountryCurrency(INITIAL_VALUES);
  const { isLoading, error, isSuccess, mutate, data } = useCreateDiscount();

  const handleSubmit = (discount: DiscountFormValues) => {
    mutate(discount);
  };

  const entity = t(`common.${data?.is_coupon ? 'coupon' : 'discount'}`);
  const titlePath = isSuccess ? 'common.success_create' : 'common.create_title';
  return (
    <Grid rowGap={2}>
      <H2>{t(titlePath, { entity })}</H2>
      {isSuccess ? (
        <Paragraph>
          Para que tus clientes accedan al descuento agregalo en tus planes
          haciendo click{' '}
          <Link as={RouterLink} to="/plans">
            aqui
          </Link>
        </Paragraph>
      ) : (
        <DiscountForm
          initialValues={initialValues}
          onCancel={onClose}
          onSubmit={handleSubmit}
          isLoading={isLoading}
        />
      )}
      <ApiErrorMessage error={error} />
    </Grid>
  );
}
