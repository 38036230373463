import styled from 'styled-components';
import { BasicLink, H1, H2 } from '@increasecard/typed-components';
import { Row } from './Row';
import { CopyButton } from './CopyButton';
import { Link as RouterLink } from 'react-router-dom';
import { ReactNode } from 'react';

export interface DetailHeaderProps {
  title: string | ReactNode;
  titleb?: string;
  subTitle: string;
  copyValue?: string;
  subtitleHref?: string;
}
// TODO: add this variant to the library
const BlackLink = styled(BasicLink)`
  color: var(--color-gray-black);
  &:not(:disabled):hover {
    color: var(--color-gray-blackLight);
  }
`;

export function DetailHeader({
  title,
  titleb,
  subTitle,
  copyValue,
  subtitleHref,
}: DetailHeaderProps): JSX.Element {
  const subttile = subtitleHref ? (
    <BlackLink as={RouterLink} to={subtitleHref}>
      <H2 weight="normal">{subTitle}</H2>
    </BlackLink>
  ) : (
    <H2 weight="normal">{subTitle}</H2>
  );
  return (
    <div>
      <H1>
        {title}
        {titleb && (
          <span style={{ color: 'var(--color-pay-dark30)' }}>{titleb}</span>
        )}
      </H1>
      <Row alignItems="center" margin="0" gap="0.5rem">
        {subttile}
        <CopyButton value={copyValue || subTitle} />
      </Row>
    </div>
  );
}
