import { TFunction } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnySchema } from 'yup';
import {
  Email,
  OnlyNumbers,
  OnlyNumbersRequired,
  URL,
} from '../schemas/common';

type schemaType = 'url' | 'email' | 'onlyNumbers' | 'onlyNumbersRequired';

const validators: Record<schemaType, (t: TFunction) => AnySchema> = {
  url: URL,
  email: Email,
  onlyNumbers: OnlyNumbers,
  onlyNumbersRequired: OnlyNumbersRequired,
};

export type UseValidation = {
  value: string | number | undefined | null | Date;
  schema: schemaType | AnySchema;
};

export function useValidation({ value, schema }: UseValidation): {
  isValid: boolean | undefined;
  errorMessage: string;
} {
  const { t } = useTranslation();
  const [isValid, setIsValid] = useState<boolean | undefined>();
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    try {
      let validator;
      if (typeof schema === 'string') {
        validator = validators[schema](t);
      } else {
        validator = schema;
      }
      validator.validateSync(value);
      setIsValid(true);
      setErrorMessage('');
    } catch (error: Metadata) {
      // TODO: improve error treatment
      setIsValid(false);
      setErrorMessage(error.errors[0]);
    }
  }, [value, schema, t]);

  return { isValid, errorMessage };
}
