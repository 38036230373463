import { Dispatch, SetStateAction, useState } from 'react';

export interface ModalControls<Data> {
  isOpen: boolean;
  data: Data | null;
  open: (data?: Data) => void;
  close: () => void;
  setData: Dispatch<SetStateAction<Data | null>>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useModal<Data = any>(
  nullifyOnClose = true
): ModalControls<Data> {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [data, setData] = useState<Data | null>(null);

  const open = (data?: Data) => {
    setIsOpen(true);
    setData(data || null);
  };

  const close = () => {
    if (nullifyOnClose) {
      setData(null);
    }
    setIsOpen(false);
  };

  return { isOpen, data, open, close, setData };
}
