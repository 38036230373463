import { ReactNode } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  ModalHeaderIconBox,
  ModalHeaderIconBoxProps,
  ButtonProps,
} from '@increasecard/typed-components';
import { LoadingButton } from './LoadingButton';

export interface ConfirmModalProps {
  visible: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
  header?: string;
  message?: ReactNode;
  confirmLabel?: string;
  cancelLabel?: string;
  confirmButtonType?: ButtonProps['variant'];
  children?: React.ReactNode;
  headerIcon?: ModalHeaderIconBoxProps['icon'];
  colorPalette?: ModalHeaderIconBoxProps['colorPalette'];
  isLoading?: boolean;
}

const noop = () => {
  return;
};

export function ConfirmModal({
  visible,
  onConfirm,
  onCancel,
  onClose,
  header,
  message,
  confirmLabel = 'Cerrar',
  cancelLabel = 'Cancelar',
  confirmButtonType = 'primary',
  children,
  headerIcon,
  colorPalette = 'pay',
  isLoading = false,
}: ConfirmModalProps): JSX.Element {
  const content = message || children;
  return (
    <Modal
      align="center"
      onClose={onClose || onCancel || noop}
      visible={visible}
      onCancel={noop}
      onOk={noop}
    >
      {headerIcon && (
        <ModalHeaderIconBox colorPalette={colorPalette} icon={headerIcon} />
      )}
      {header && <ModalHeader align="center">{header}</ModalHeader>}
      {content && <ModalContent align="center">{content}</ModalContent>}
      <ModalFooter align="center">
        {onCancel && (
          <Button onClick={onCancel} variant="invisible">
            {cancelLabel}
          </Button>
        )}
        {onConfirm && (
          <LoadingButton
            isLoading={isLoading}
            onClick={onConfirm}
            variant={confirmButtonType}
          >
            {confirmLabel}
          </LoadingButton>
        )}
      </ModalFooter>
    </Modal>
  );
}
