import config from '../../../config';

const isApiLoaded = () => window.Mercadopago && window.Mercadopago.initialized;
const MP_SDK_URL =
  'https://secure.mlstatic.com/sdk/javascript/v1/mercadopago.js';
const MP_SECURITY_URL = 'https://www.mercadopago.com/v2/security.js';

export const loadMercadopagoSdk = (countryCode) => {
  const apiKey = config.collectMethods.mercadopago[countryCode].PK;

  return new Promise((resolve, reject) => {
    const securityScript = document.createElement('script');
    securityScript.src = MP_SECURITY_URL;
    document.body.appendChild(securityScript);

    const sdkScript = document.createElement('script');
    sdkScript.src = MP_SDK_URL;
    sdkScript.async = true;
    sdkScript.onload = () => {
      window.Mercadopago.setPublishableKey(apiKey);
      resolve();
    };
    sdkScript.onerror = reject;
    document.body.appendChild(sdkScript);
  });
};

const callMpApiMethod = (methodName, params) => {
  return new Promise((resolve, reject) => {
    var retryOnce = true;
    const callMethod = () => {
      const callParams = [
        (status, response) => {
          if (status === 200) {
            resolve(response);
          } else {
            response.errorStatus = status;
            reject(response);
          }
        },
      ];
      if (params) {
        callParams.unshift(params);
      }

      const response = window.Mercadopago[methodName].apply(null, callParams);

      // in case the method is not async, resolve de promise with its return
      if (response) {
        resolve(response);
      }
    };
    if (isApiLoaded() && window.Mercadopago[methodName]) {
      callMethod();
    } else {
      if (retryOnce) {
        retryOnce = false;
        setTimeout(() => callMethod(), 1000);
      } else {
        reject({
          error: 'Mercadopago api not found',
        });
      }
    }
  });
};

export const getIdentificationTypes = () => {
  return callMpApiMethod('getIdentificationTypes');
};

export const getPaymentMethod = (bin) => {
  return callMpApiMethod('getPaymentMethod', {
    bin,
  });
};

export const getAllPaymentMethods = () => {
  return callMpApiMethod('getAllPaymentMethods');
};

export const getInstallments = (bin) => {
  return callMpApiMethod('getInstallments', {
    bin,
  });
};

export const createToken = (form) => {
  return callMpApiMethod('createToken', form);
};

export const clearSession = () => {
  return callMpApiMethod('clearSession');
};
