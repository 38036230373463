import { useUser } from '@increasecard/increase-core';
import { useTranslation } from 'react-i18next';
import { Subscription } from '../../types/Subscription';
import { useSMSDispatcher } from './useSMSDispatcher';
import { Customer } from '../../types/Customer';
import { useAccount } from '../../globalQueries/Queries';

export type ShareType = 'whatsapp' | 'sms' | 'email';
export type CheckoutType = 'subscription' | 'invoice';

export interface UseShareCheckoutLinkProps {
  checkoutLink: string;
  checkoutType: CheckoutType;
  customer: Customer | undefined;
  subscription?: Subscription;
}

export interface UseShareCheckoutLink {
  dispatchMail: () => void;
  dispatchPhoneMessage: (phoneNumber: string, shareType: ShareType) => void;
}

export function useShareCheckoutLink({
  checkoutLink,
  checkoutType,
  customer,
  subscription,
}: UseShareCheckoutLinkProps): UseShareCheckoutLink {
  const { t } = useTranslation();
  const { data: account } = useAccount();
  const user = useUser();
  const smsDispatcher = useSMSDispatcher();

  const accountName =
    account?.branding_settings?.shop_name || user?.account.name;

  const shareMessage = t(
    'components.checkout_link_sharing_widget.generic_message',
    {
      link: checkoutLink,
      brand: accountName,
    }
  );

  const dispatchPhoneMessage = async (
    phoneNumber: string,
    shareType: ShareType
  ) => {
    switch (shareType) {
      case 'sms':
        await smsDispatcher.dispatchSMS(phoneNumber, shareMessage);
        break;
      case 'whatsapp':
      default:
        window.open(
          `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
            shareMessage
          )}`
        );
        break;
    }
  };

  const getMailSubject = (): string => {
    if (checkoutType === 'subscription') {
      return t(
        'components.checkout_link_sharing_widget.mail_subject_subscription',
        {
          product: subscription?.product?.name,
          plan: subscription?.plan.name,
        }
      );
    }
    // invoice / default
    return t('components.checkout_link_sharing_widget.mail_subject_invoice', {
      brand: accountName,
    });
  };

  const dispatchMail = () => {
    if (!customer) return;

    const mailToUrl = `mailto: ${customer.email}?subject=${encodeURIComponent(
      getMailSubject()
    )}&body=${encodeURIComponent(shareMessage)}`;

    window.open(mailToUrl);
  };

  return {
    dispatchMail,
    dispatchPhoneMessage,
  };
}
