import { ChangeEvent } from 'react';
import { H1, InputSearch } from '@increasecard/typed-components';
import { useCheckoutAccount } from '../AccountContext';
import { SearchFieldSelector } from './SearchFieldSelector';
import { Column } from '../../../components/common/Column';
import { LoadingButton } from '../../../components/common/LoadingButton';
import { usePublishedDebtPost } from './debtPortalQueries';
import { useDebtPortalStore } from './useDebtPortalStore';

export type DebtPortalPayload = {
  captchaToken: string;
  searchField: string;
  searchValue: string;
};

export function SearchScreen(): JSX.Element {
  const { searchField, searchValue, setSearchField, setSearchValue, goToList } =
    useDebtPortalStore();
  const { branding_settings } = useCheckoutAccount();
  const { mutate: checkDebt, isLoading } = usePublishedDebtPost();

  const isValid = !!(searchField || searchValue);

  const fieldLabel = branding_settings.searchable_fields.find(
    ({ field }) => field === searchField
  )?.label;

  const handleFieldChange = (
    e: ChangeEvent<HTMLSelectElement> | ChangeEvent<HTMLInputElement>
  ) => setSearchField(e.target.value);

  const handleValueChange = (e: ChangeEvent<HTMLInputElement>) =>
    setSearchValue(e.target.value);

  const handleSubmit = async () => {
    if (isValid) {
      checkDebt(
        {
          [searchField]: searchValue,
          page: 1,
        },
        {
          onSuccess: () => goToList(),
        }
      );
    }
  };

  return (
    <Column gap="48px" alignItems="center">
      <H1>Consulta tu facturas en {branding_settings.shop_name}</H1>
      <Column minWidth="304px" maxWidth="420px" gap="16px">
        <SearchFieldSelector onChange={handleFieldChange} value={searchField} />
        <InputSearch
          label={fieldLabel}
          onChange={handleValueChange}
          value={searchValue}
          disabled={!searchField}
        />
        <LoadingButton
          disabled={!isValid}
          onClick={handleSubmit}
          isLoading={isLoading}
          className="mobile-fullWidth"
        >
          Consultar
        </LoadingButton>
      </Column>
    </Column>
  );
}
