import styled from 'styled-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ModalContent,
  Paragraph,
  DateInput,
} from '@increasecard/typed-components';
import { Subscription } from '../../../types/Subscription';
import { useActivateSubscriptions } from '../subscriptionQueries';
import { MassiveActionFooter } from './MassiveActionsFooter';
import { MassiveActionResults } from './MassiveActionResults';

const DateWrapper = styled.div`
  max-width: 218px;
  margin: 0px auto;
`;

export interface ActivationModalContentProps {
  onClose: VoidFunction;
  onSuccess?: VoidFunction;
  subscription: Subscription[];
}

export function ActivationModalContent({
  subscription,
  onClose,
  onSuccess,
}: ActivationModalContentProps): JSX.Element {
  const { t } = useTranslation();
  const { mutate, error, isError, isLoading, isSuccess } =
    useActivateSubscriptions();
  const [activationDate, setActivationDate] = useState(new Date());

  const handleActivate = () => {
    mutate(
      {
        subscriptions: subscription,
        startDate: activationDate,
      },
      { onSuccess }
    );
  };

  const handleActivationDateChange = (date: Date) => {
    setActivationDate(date);
  };

  return (
    <>
      <ModalContent align="center">
        {!isError && !isSuccess && (
          <>
            <Paragraph>
              {t('screens.subscriptions.activate.subtitle', {
                count: subscription.length,
              })}
            </Paragraph>
            <Paragraph>
              {t('screens.subscriptions.activate.subtitle2')}
            </Paragraph>
            <DateWrapper>
              <DateInput
                readOnly
                datepickerProps={{
                  minDate: new Date(),
                }}
                id="startDate"
                onChange={handleActivationDateChange}
                value={activationDate}
              />
            </DateWrapper>
          </>
        )}
        <MassiveActionResults
          subscriptions={subscription}
          error={error}
          isSuccess={isSuccess}
          actionType="activate"
        />
      </ModalContent>
      <MassiveActionFooter
        onClose={onClose}
        onConfirm={handleActivate}
        isLoading={isLoading}
        isError={isError}
        isSuccess={isSuccess}
        confirmLabel={t('screens.subscriptions.activate.confirm', {
          count: subscription.length,
        })}
      />
    </>
  );
}
