import { FullPageSpinner } from '../../components/common/FullPageSpinner';
import { PaymentMethods } from '../../components/paymentMethods/PaymentMethods';
import { useCheckoutLite } from './useCheckoutLite';

export function CheckoutLite(): JSX.Element {
  const {
    account,
    subscription,
    paymentError,
    paySubscription,
    isPaying,
    isLoading,
  } = useCheckoutLite();

  // TODO: Show errors if account or subscription weren't found
  if (isLoading || !account || !subscription) return <FullPageSpinner />;

  return (
    <PaymentMethods
      country={subscription.plan.country}
      customerId={subscription.customer.id}
      groupedCollectMethods={subscription.plan.collect_methods}
      processing={isPaying}
      onNext={paySubscription}
      onCancel={() => null}
      submitText=""
      errorObj={paymentError}
    />
  );
}
