import {
  ButtonMenu as TCButtonMenu,
  ButtonMenuItem,
} from '@increasecard/typed-components';
import { Customer } from '../../../types/Customer';
import { callIfFunction } from '../../../utils/utils';
import { useCustomerActions } from '../useCustomerActions';
import { ConfirmDeleteModal } from './ConfirmDeleteModal';

interface CustomerActionsMenuProps {
  customer: Customer;
  text: string;
}

export function CustomerActionsMenu({
  text,
  customer,
}: CustomerActionsMenuProps): JSX.Element {
  const { actions, deleteModal } = useCustomerActions('customer detail', [
    'view_details',
    'edit',
  ]);

  return (
    <>
      <TCButtonMenu text={text}>
        {({ toggleShowMenu }) =>
          actions.map(({ label, disabled, cb }) => {
            const stringLabel = callIfFunction<string>(label, customer);
            const isDisabled = callIfFunction<boolean>(disabled, customer);
            return (
              <ButtonMenuItem
                onClick={() => {
                  cb(customer);
                  toggleShowMenu();
                }}
                key={stringLabel}
                disabled={isDisabled}
              >
                {stringLabel}
              </ButtonMenuItem>
            );
          })
        }
      </TCButtonMenu>
      <ConfirmDeleteModal
        visible={deleteModal.isOpen}
        customer={deleteModal.data}
        onClose={deleteModal.close}
        redirectOnSuccess={true}
      />
    </>
  );
}
