import { useReducer } from 'react';
import isEqual from 'lodash/isEqual';

export type ListItem = Metadata & {
  id: string;
};

export type ListState = ListItem[];

export type CompareFunction = (a: ListItem, b: ListItem) => number;

type DefaultAction<Type> = {
  compareFunction?: CompareFunction;
  keepSorted: boolean;
  data: ListItem;
  type: Type;
};

type ActionTypes =
  | DefaultAction<'set'>
  | DefaultAction<'add'>
  | (DefaultAction<'update'> & { id: string })
  | { type: 'remove'; id: string }
  | { type: 'sort'; compareFunction: CompareFunction }
  | { type: 'clear' };

function getNewListId(list: ListState) {
  if (list.length === 0) return 1;
  return (
    // eslint-disable-next-line prefer-spread
    Math.max.apply(
      Math,
      list.map(function (i) {
        return i.id || 0;
      })
    ) + 1
  );
}

function addUniqueId(initState: ListState) {
  return initState.map((item) => ({
    ...item,
    id: item.id ? item.id : getNewListId(initState),
  }));
}

function sortList(
  list: ListState,
  compareFunction?: CompareFunction,
  keepSorted?: boolean
) {
  if (compareFunction) {
    return list.sort(compareFunction);
  }
  if (keepSorted) {
    return list.sort();
  }
  return list;
}

export function reducer(list: ListState, action: ActionTypes): ListState {
  switch (action.type) {
    case 'set': {
      const updatedList = addUniqueId(action.data);
      return sortList(updatedList, action.compareFunction, action.keepSorted);
    }
    case 'add': {
      const { data } = action;
      const newItem = { ...data, id: data.id ? data.id : getNewListId(list) };
      const updatedList = list.concat(newItem);
      return sortList(updatedList, action.compareFunction, action.keepSorted);
    }
    case 'update': {
      const idx = list.findIndex((t) => t.id === action.id);
      const updatedList = [...list];
      updatedList.splice(idx, 1, { ...list[idx], ...action.data });
      return sortList(updatedList, action.compareFunction, action.keepSorted);
    }
    case 'remove': {
      return list.filter((i) => i.id !== action.id);
    }
    case 'clear': {
      return [];
    }
    case 'sort': {
      return sortList(list, action.compareFunction);
    }
    default:
      return list;
  }
}

export interface UseListParams {
  initialState?: Metadata[];
  allowDuplicates?: boolean;
  keepSorted?: boolean;
  compareFunction?: CompareFunction;
}

export interface UseList {
  list: ListState;
  set: (list: Metadata) => void;
  add: (item: ListItem) => void;
  update: (id: string, data: Metadata) => void;
  remove: (id: string) => void;
  clear: () => void;
  sort: (cf: CompareFunction) => void;
}

export function useList({
  initialState = [],
  allowDuplicates = true,
  keepSorted = false,
  compareFunction,
}: UseListParams): UseList {
  const [list, dispatch] = useReducer(reducer, initialState, addUniqueId);

  const set = (list: Metadata) =>
    dispatch({ type: 'set', data: list, compareFunction, keepSorted });
  const add = (item: ListItem) => {
    if (!allowDuplicates) {
      const isDuplicate = list.find((i) => isEqual(item, i));
      if (isDuplicate) return;
    }
    dispatch({ type: 'add', data: item, compareFunction, keepSorted });
  };
  const update = (id: string, data: Metadata) =>
    dispatch({
      type: 'update',
      id,
      data,
      compareFunction,
      keepSorted,
    });
  const remove = (id: string) => dispatch({ type: 'remove', id });
  const clear = () => dispatch({ type: 'clear' });
  const sort = (compareFunction: CompareFunction) =>
    dispatch({ type: 'sort', compareFunction });

  return { list, set, add, update, remove, clear, sort };
}
