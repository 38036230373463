import { apiFrontUrl } from '../services/api';
import isEqual from 'lodash/isEqual';
import pickBy from 'lodash/pickBy';
import isDate from 'lodash/isDate';
import { Customer, NewApiError, PaymentMethod } from '../types';

type ParsableValue = string | number | Date | string[];

function isParsable(value: unknown): value is ParsableValue {
  return value !== undefined && value !== null && value !== '*';
}

export function getQueryString(...args: Metadata[]): string {
  if (!args || args.length === 0) return '';

  const searchParams = new URLSearchParams();
  for (let i = 0; i < args.length; i++) {
    const arg = args[i];
    if (arg) {
      Object.entries(arg).forEach(([name, value]) => {
        if (isParsable(value)) {
          if (Array.isArray(value)) {
            if (value[0] === '*') return;
            for (let i = 0; i < value.length; i++) {
              searchParams.append(`${name}[]`, value[i]);
            }
            // Transform to ISODate if is a date for api compatibility
            // TODO: use axios own strification.
          } else if (isDate(value)) {
            searchParams.append(name, value.toISOString());
          } else {
            searchParams.append(name, value.toString());
          }
        }
      });
    }
  }
  const qs = searchParams.toString();
  return qs ? `?${qs}` : '';
}

export function parseQueryString(qs = window.location.search): Metadata {
  const arrayParams = (props: URLSearchParams) => {
    const params: Metadata = {};
    for (const key of props.keys()) {
      if (key.endsWith('[]')) {
        params[key.replace('[]', '')] = props.getAll(key);
      } else {
        params[key] = props.get(key);
      }
    }
    return params;
  };
  return arrayParams(new URLSearchParams(qs));
}

export function removeEmpty(obj: Metadata): Metadata {
  return Object.fromEntries(
    // eslint-disable-next-line no-unused-vars
    Object.entries(obj).filter(([, value]) => {
      return value !== null && value !== undefined;
    })
  );
}

// This param mat be a string if it comes directly from API or was edited in a form,
// or a number, if it was parsed from api but not edited.
export const parsePercentageToAPI = (value: string | number): string => {
  const pctValue =
    typeof value === 'string' ? parseFloat(value.replace(',', '.')) : value;
  return (pctValue / 100).toFixed(4);
};

export const parsePercentageFromAPI = (value: string): string => {
  return (parseFloat(value) * 100).toFixed(2).replace('.', ',');
};

export function getExportHref(path: string, filters: Metadata): string {
  const paramsQueryString = getQueryString(filters);
  return `${apiFrontUrl + path}/report${paramsQueryString}`;
}
// Deprecated. In the future we should use deepDiff
export function filterUnchangedKeys(obj1: Metadata, obj2: Metadata): Metadata {
  return pickBy(obj1, (v, k) => !isEqual(v, obj2[k]));
}

export function isObject(obj: unknown): boolean {
  return typeof obj === 'object' && !Array.isArray(obj) && obj !== null;
}

export function deepDiff(
  objA: Metadata,
  objB: Metadata,
  options?: { keepKeys?: string[] }
): Metadata {
  const { keepKeys } = options || {};
  const result: Metadata = {};
  for (const key in objA) {
    if (Object.hasOwnProperty.call(objA, key)) {
      const valueA = objA[key];
      const valueB = objB[key];

      if (keepKeys && keepKeys.includes(key)) {
        result[key] = valueA;
        continue;
      }
      if (isEqual(valueA, valueB)) {
        continue;
      }
      if (isObject(valueA)) {
        result[key] = deepDiff(valueA, valueB, options);
      } else {
        result[key] = valueA;
      }
    }
  }
  return result;
}

export function filterInvalidPaymentMethods(customer: Customer): Customer {
  customer.payment_methods = customer.payment_methods.filter(
    (pm) => pm.validated
  );
  return customer;
}

export function paymentErrorToApiError({
  rejection_code,
  rejection_description,
}: PaymentMethod['data']['error']): NewApiError {
  return NewApiError.fromAPI({
    code: rejection_code,
    message: rejection_description,
  });
}
