import { useTranslation } from 'react-i18next';
import { Paragraph, InputText } from '@increasecard/typed-components';
import { getCustomerName } from '../../../utils/utils';
import { Row } from '../../../components/common/Row';
import { Invoice } from '../../../types/Invoice';
import { CheckoutLinkSharingWidget } from '../../../components/ShareWidget/CheckoutLinkSharingWidget';
import { CopyButton } from '../../../components/common/CopyButton';

interface SuccessProcessScreenProps {
  invoice: Invoice;
}

export function SuccessProcessScreen({
  invoice,
}: SuccessProcessScreenProps): JSX.Element {
  const { t } = useTranslation();
  const invoiceNeedPayment = !invoice.latest_payment_method;
  return (
    <>
      <Paragraph>
        {invoiceNeedPayment
          ? t('screens.new_invoice.use_link', {
              name: getCustomerName(invoice.customer),
            })
          : t('screens.new_invoice.invoice_paid')}
      </Paragraph>
      {invoiceNeedPayment && (
        <>
          <Row alignItems="center">
            <InputText
              id="link"
              type="text"
              label={t('screens.new_invoice.payment_link')}
              value={invoice.checkout_link}
            />
            <CopyButton
              value={invoice.checkout_link}
              buttonProps={{ style: { margin: '1rem 0.5rem 0 0.5rem' } }}
            />
          </Row>
          <CheckoutLinkSharingWidget
            customerId={invoice.customer.id}
            checkoutLink={invoice.checkout_link}
            checkoutType="invoice"
          />
        </>
      )}
    </>
  );
}
