import styled from 'styled-components';

export const LinkButton = styled.button`
  background: none;
  border: none;
  letter-spacing: -0.003em;
  text-decoration-line: underline;
  font-feature-settings:
    'tnum' on,
    'lnum' on;
  font-size: 0.815rem;
  text-decoration: underline;
  outline: none;
  cursor: pointer;

  :disabled {
    color: #a3a3a3;
  }
`;
