import { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';
import { Paragraph, PathSelector } from '@increasecard/typed-components';
import { ResponsiveGrid } from '../common/ResponsiveGrid';
import { Row } from '../common/Row';
import { ticketBrandIcons } from '../../utils/paymentUtils';
import { PaymentFormFooter } from './PaymentFormFooter';
import { Spinner } from '@increasecard/typed-components';
import { ApiErrorMessage } from '../ErrorMessage';
import { NewApiError } from '../../types/NewApiError';

const IconWrapper = styled.div`
  margin-top: 1rem;
  /* Sized to cut icon corners */
  width: 60px;
  height: 40px;
  border-radius: 8px;
  overflow: hidden;
`;

type LogoId = keyof typeof ticketBrandIcons;

const Logo = ({ id }: { id: LogoId }) => {
  const Icon = ticketBrandIcons[id];
  if (!Icon) {
    return null;
  }
  return (
    <IconWrapper>
      <Icon width="60px" height="40px" />
    </IconWrapper>
  );
};

export interface TicketPaymentSelectorProps {
  onSubmit: (data: string) => void;
  paymentMethods: { id: LogoId; name: string }[];
  onCancel: VoidFunction;
  submitText: string;
  cancelText: string;
  processing: boolean;
  expirationDays: number;
  errorObj: NewApiError | undefined;
  termsAndConditionsUrl: string;
}

export function TicketPaymentSelector({
  paymentMethods,
  onSubmit,
  onCancel,
  submitText,
  cancelText,
  processing,
  expirationDays,
  errorObj,
  termsAndConditionsUrl,
}: TicketPaymentSelectorProps): JSX.Element {
  const { t } = useTranslation();
  const [selectedMethod, setSelectedMethod] = useState<string>();

  const handleMethodSelection = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedMethod(e.target.name);
  };

  const handleOkClick = () => {
    if (selectedMethod) onSubmit(selectedMethod);
  };

  return (
    <ResponsiveGrid desktopMaxWidth="566px" rowGap={2}>
      <Row>
        <Paragraph>
          <Trans
            t={t}
            i18nKey="data.payment.ticket_method_description"
            values={{
              expirationDays: expirationDays,
            }}
          />
        </Paragraph>
      </Row>
      {paymentMethods.length < 1 && <Spinner size={24} />}
      {paymentMethods.map((pm) => (
        <PathSelector
          key={pm.id}
          id={pm.id}
          checked={selectedMethod === pm.id}
          title={pm.name}
          className="grid-span-mobile-1 grid-span-desktop-4"
          onChange={handleMethodSelection}
        >
          <Logo id={pm.id} />
        </PathSelector>
      ))}
      <PaymentFormFooter
        className="grid-span-desktop-12"
        isSubmitting={processing}
        onSubmit={handleOkClick}
        submitText={submitText || t('common.continue')}
        onCancel={onCancel}
        cancelText={cancelText}
        showCardDisclaimer={false}
        termsAndConditionsUrl={termsAndConditionsUrl}
      />
      <ApiErrorMessage error={errorObj} />
    </ResponsiveGrid>
  );
}
