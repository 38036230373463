import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { MenuActionDefinition } from '../../components/common/DataTable/DataTable';
import { featureFlags } from '../../featureFlags';
import { useClipboard } from '../../hooks/useClipboard';
import { ModalControls, useModal } from '../../hooks/useModal';
import { useUIEventTracker } from '../../hooks/useUIEventTracker';
import { Customer } from '../../types/Customer';
import { usePayAuthorizations } from '../authorizations/usePayAuthorizations';

export interface UseCustomerActions {
  actions: MenuActionDefinition[];
  goToDetails: (customerId: string, clickContext: string) => void;
  deleteModal: ModalControls<Customer>;
}

export type CustomerAction =
  | 'view_details'
  | 'edit'
  | 'create_subscription'
  | 'create_invoice'
  | 'copy_checkout_link'
  | 'delete';

export function useCustomerActions(
  trackingContext: string,
  omit?: CustomerAction[]
): UseCustomerActions {
  const { t } = useTranslation();
  const history = useHistory();
  const { canCreateEditCustomer, canCreateSubscription, canCreateInvoice } =
    usePayAuthorizations();
  const clipboard = useClipboard();
  const trackEvent = useUIEventTracker(trackingContext);
  const deleteModal = useModal();

  const goToDetails = (customerId: string, clickContext: string) => {
    trackEvent('view_customer_detail', { context: clickContext });
    history.push(`/customers/${customerId}/detail`);
  };

  const availableActions: MenuActionDefinition[] = [
    {
      name: 'view_details',
      label: t('common.show_details'),
      cb: (customer: Customer) => goToDetails(customer.id, 'list row menu'),
    },
    {
      name: 'edit',
      label: t('common.edit'),
      cb: (customer: Customer) => {
        trackEvent('edit_customer');
        history.push(`/customers/${customer.id}/edit`);
      },
      disabled: () => !canCreateEditCustomer(),
    },
    {
      name: 'create_subscription',
      label: t('common.new_subscription'),
      cb: (customer: Customer) => {
        trackEvent('create_subscription');
        history.push(`/subscriptions/new?customer_id=${customer.id}`);
      },
      disabled: () => !canCreateSubscription(),
    },
    {
      name: 'create_invoice',
      label: t('common.new_invoice'),
      cb: (customer: Customer) => {
        trackEvent('create_invoice');
        history.push(`/invoices/new?customer_id=${customer.id}`);
      },
      disabled: () => !canCreateInvoice(),
    },
    {
      name: 'copy_checkout_link',
      tooltipLabel: t('common.copied'),
      label: t('common.ask_payment_method'),
      cb: (customer: Customer) => {
        trackEvent('copy_customer_checkout_link');
        clipboard.copy(customer.checkout_link);
      },
    },
    {
      name: 'delete',
      label: t('common.delete'),
      variant: 'alert',
      disabled: (customer: Customer) =>
        !customer.may_delete || !canCreateEditCustomer(),
      cb: (customer: Customer) => {
        trackEvent('delete_customer');
        deleteModal.open(customer);
      },
    },
  ];
  const defaultOmit = featureFlags.enableSoftDelete
    ? omit
    : [...(omit || []), 'delete'];
  const actions = defaultOmit
    ? availableActions.filter((action) => {
        return !defaultOmit.includes(action.name as CustomerAction);
      })
    : availableActions;
  return { actions, goToDetails, deleteModal };
}
