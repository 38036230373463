import { apiCheckout } from './api';
import { getQueryString } from '../utils/apiUtils';
import { Country } from '../types/common';
import { CollectMethodApiType } from '../types/CollectMethod';
import { UnsupportedPaymentMethod } from '../types/PaymentMethod';

export interface UnsuportedPaymentMethodsFilters {
  country: Country;
  collect_method_type: CollectMethodApiType;
}

export async function fetchUnsupportedPaymentMethods(
  data: UnsuportedPaymentMethodsFilters
): Promise<UnsupportedPaymentMethod[]> {
  const queryString = getQueryString(data);
  const response = await apiCheckout.get(
    `unsupported_payment_methods.json${queryString}`
  );
  return response.data;
}
