import {
  Currency,
  Discount,
  Extra,
  ExtraType,
  GroupedExtras,
  OneTimeCost,
  Tax,
} from '../types';
import { AdditionalCost } from '../types/AdditionalCost';
import { getCountryConfig } from './countryUtils';

const ExtrasApplyOrder = [
  'aditional_cost-flat',
  'aditional_cost-percentage',
  'one_time_cost-flat',
  'one_time_cost-percentage',
  'discount-flat',
  'discount-percentage',
  'tax-percentage',
];

export function sortExtras(extraA: Extra, extraB: Extra): number {
  const keyA = extraA.extraType + '-' + extraA.type;
  const keyB = extraB.extraType + '-' + extraB.type;
  const res = ExtrasApplyOrder.indexOf(keyA) - ExtrasApplyOrder.indexOf(keyB);
  return res;
}

export function extrasFromApi<T>(extras: T[], extraType: ExtraType): T[] {
  return extras?.map((extra) => ({
    ...extra,
    extraType,
  }));
}
export function isDiscount(extra: Extra): extra is Discount {
  return extra.extraType === 'discount';
}
export function isCoupon(extra: Extra): extra is Discount {
  return isDiscount(extra) && extra.is_coupon;
}
export function isTax(extra: Extra): extra is Tax {
  return extra.extraType === 'tax';
}
export function isOneTimeCost(extra: Extra): extra is OneTimeCost {
  return extra.extraType === 'one_time_cost';
}
export function isAdditionalCost(extra: Extra): extra is AdditionalCost {
  return extra.extraType === 'additional_cost';
}

export const isExtraActive = (extra: Extra, cyclesPast = 0): boolean => {
  switch (extra.extraType) {
    case 'discount':
    case 'additional_cost':
      return (
        extra.duration === 'forever' ||
        (extra.cycle_amounts_left || extra.cycle_amount) > 0
      );
    case 'one_time_cost':
      return cyclesPast === 0;
    case 'tax':
      return true;
  }
};

export function entityExtrasFromApi<T extends GroupedExtras>(entity: T): T {
  entity.discounts = extrasFromApi(entity.discounts, 'discount');
  entity.one_time_costs = extrasFromApi(entity.one_time_costs, 'one_time_cost');
  entity.taxes = extrasFromApi(entity.taxes, 'tax');
  entity.additional_costs = extrasFromApi(
    entity.additional_costs,
    'additional_cost'
  );
  return entity;
}

export function entityExtrasToApi<T extends GroupedExtras>(
  entity: T
): Metadata {
  const extraToApi = (extra: Extra) => {
    const parsedExtra: Metadata = { id: extra.id };
    if (isDiscount(extra) || isAdditionalCost(extra)) {
      parsedExtra.cycles_left = extra.cycle_amount;
    }
    return parsedExtra;
  };
  return {
    ...entity,
    discounts: entity.discounts?.map(extraToApi),
    one_time_costs: entity.one_time_costs?.map(extraToApi),
    taxes: entity.taxes?.map(extraToApi),
    additional_costs: entity.additional_costs?.map(extraToApi),
  };
}

export function splitExtrasByType(extras: Extra[] = []): GroupedExtras {
  return {
    discounts: extras.filter(isDiscount),
    taxes: extras.filter(isTax),
    one_time_costs: extras.filter(isOneTimeCost),
    additional_costs: extras.filter(isAdditionalCost),
  };
}

export function mergeExtras({
  discounts,
  taxes,
  one_time_costs,
  additional_costs,
}: GroupedExtras): Extra[] {
  return [
    ...(discounts ? discounts : []),
    ...(one_time_costs ? one_time_costs : []),
    ...(taxes ? taxes : []),
    ...(additional_costs ? additional_costs : []),
  ].sort(sortExtras);
}

// How extras should be applied: https://increase-app.atlassian.net/wiki/spaces/PAY/pages/1268547646/One+Time+Cost
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function applyExtras(extras: Extra[] = [], amount: number | string = 0) {
  const subAmount = Number(amount);
  const totalBeforeDiscounts = subAmount;
  const totalBeforeOTCs = subAmount;
  let total = totalBeforeDiscounts;
  const { discounts, additional_costs, one_time_costs, taxes } =
    splitExtrasByType(extras);

  // apply costs (OTCs and ACs)
  const totalOTCs = one_time_costs.reduce(
    (sum, oneTimeCost) => sum + applyExtra(oneTimeCost, subAmount),
    0
  );
  const totalAC = additional_costs.reduce(
    (sum, aditionalCost) => sum + applyExtra(aditionalCost, subAmount),
    0
  );
  const totalAfterOTCs = subAmount + totalOTCs + totalAC;
  total += totalOTCs + totalAC;

  // apply all Discounts
  const totalDiscounts = discounts.reduce(
    (sum, discount) => sum + applyExtra(discount, subAmount),
    0
  );
  const totalAfterDiscounts = subAmount - totalDiscounts;
  total -= totalDiscounts;

  // apply Taxes
  const totalBeforeTaxes = total;
  total += taxes.reduce((sum, tax) => sum + applyExtra(tax, total), 0);

  return {
    total,
    totalBeforeDiscounts,
    totalAfterDiscounts,
    totalBeforeOTCs,
    totalAfterOTCs,
    totalBeforeTaxes,
  };
}

export function applyExtra(extra: Extra, baseAmount: number): number {
  if (extra.type === 'percentage') {
    return parseFloat(extra.amount) * baseAmount;
  }
  if (extra.type === 'flat') return parseFloat(extra.amount);
  // Always return a number
  return parseFloat(extra.amount);
}

export function getExtraLanguage(extra: Extra): string {
  if (extra.country) {
    return getCountryConfig(extra.country).language;
  }
  throw new Error('Extras must have either country or currency');
}

export function getExtraCurrency(extra: Extra): Currency | undefined {
  if (extra.currency) return extra.currency;
  if (extra.country) {
    const { currencies } = getCountryConfig(extra.country);
    return currencies[0];
  }
}
