import { createContext, ReactNode, useContext, useEffect, useRef } from 'react';
import config from '../../../config';
import { ReCAPTCHAWrapper } from '../../../utils/recaptchaWrapper';
import { loadScript } from '../../../utils/utils';

declare global {
  interface Window {
    grecaptcha: unknown;
    recaptchaLoaded: VoidFunction;
    captchaInstance: ReCAPTCHAWrapper;
  }
}

const DefaultInstance = new ReCAPTCHAWrapper({
  sitekey: config.reCaptchaSiteKey,
});

const RecaptchaContext = createContext<ReCAPTCHAWrapper>(DefaultInstance);

export interface RecaptchaProviderProps {
  children: ReactNode;
}

export function RecaptchaProvider({
  children,
}: RecaptchaProviderProps): JSX.Element {
  const WrapperInstance = useRef<ReCAPTCHAWrapper>(DefaultInstance);

  useEffect(() => {
    const greCaptchaElem = window.document.getElementById('recaptchascript');
    if (!greCaptchaElem && !window.grecaptcha) {
      window.recaptchaLoaded = () => {
        WrapperInstance.current.explicitRender();
      };
      loadScript(
        'https://www.google.com/recaptcha/api.js?onload=recaptchaLoaded&render=explicit',
        'recaptchascript'
      );
    }
  });

  return (
    <RecaptchaContext.Provider value={WrapperInstance.current}>
      {children}
    </RecaptchaContext.Provider>
  );
}

export function useRecaptcha(): ReCAPTCHAWrapper {
  const recaptchaWrapper = useContext(RecaptchaContext);
  if (!recaptchaWrapper) {
    throw new Error('RecaptchaContext: No value provided');
  }
  return recaptchaWrapper;
}
