import { ReactNode, EventHandler, MouseEvent } from 'react';
import styled from 'styled-components';
import { NewApiError } from '../types/NewApiError';
import { useTranslation } from 'react-i18next';

const StyledDiv = styled.div<{ centered?: boolean }>`
  display: inline-flex;
  cursor: pointer;
  width: 100%;
  margin: 5px 0;
  border-radius: 4px;
  word-break: break-word;
  justify-content: ${({ centered }) => (centered ? 'center' : 'left')};
  span {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.011em;
    color: var(--color-coralAlert-dark);
  }
`;

export interface ErrorMessageProps {
  onClick?: EventHandler<MouseEvent>;
  children: ReactNode;
  centered?: boolean;
  id?: string;
}

export function ErrorMessage({
  onClick,
  children,
  centered,
  id,
}: ErrorMessageProps): JSX.Element {
  return (
    <StyledDiv data-test-id={id} centered={centered} onClick={onClick}>
      <span>{children}</span>
    </StyledDiv>
  );
}

export interface ApiErrorMessageProps {
  error: NewApiError | null | undefined;
  onClick?: EventHandler<MouseEvent>;
  flatMessage?: boolean;
  centered?: boolean;
}
// Some codes are too generic or have sub variants found in its message
const EXCLUDED_CODES = ['payment_method_declined', 'INVALID_RECORD'];

export function ApiErrorMessage({
  error,
  onClick,
  flatMessage,
  centered,
}: ApiErrorMessageProps): JSX.Element | null {
  const { t } = useTranslation();
  if (!error) return null;
  const { code, message } = error;
  const keys = [];
  // Give priority to 'code', if translation does not exist, fallback to message
  // except for payment_method_declined code in which case message will have higher priority;
  // This is useful for for generic error code
  if (!EXCLUDED_CODES.includes(code)) {
    keys.push(`api.errors.${code}`);
  }
  if (message) {
    // Remove period and colon symbols to not clash with i18next separators
    keys.push(`api.errors.${message.replace(/[:.]/g, '').trim()}`);
    // For payment errors the message comes already translated.
    // So we add it as last fallback.
    keys.push(message);
  }
  const translated = t(keys);
  if (flatMessage) return <>{translated}</>;
  return (
    <ErrorMessage id={code} centered={centered} onClick={onClick}>
      {translated}
    </ErrorMessage>
  );
}
