import { ChangeEvent } from 'react';
import { RowDef } from './tableDefinitions';

export interface UseRowSelection {
  selectionEnabled: boolean;
  isSelected: (id: string | number) => boolean;
  isAllSelected: boolean;
  handleSelection: (
    rows: RowDef[]
  ) => (e: ChangeEvent<HTMLInputElement>) => void;
}

export function useRowSelection(
  onSelect?: (rows: RowDef[], checked: boolean) => void,
  totalRows?: RowDef[] | null,
  selectedRows?: RowDef[]
): UseRowSelection {
  const selectionEnabled = !!onSelect;
  const handleSelection = (rows: RowDef[]) => {
    return (e: ChangeEvent<HTMLInputElement>) => {
      onSelect && onSelect(rows, e.target.checked);
    };
  };
  const isSelected = (id: string | number) => {
    return selectedRows ? !!selectedRows.find((sr) => sr.id === id) : false;
  };
  const isAllSelected =
    selectedRows?.length && totalRows?.length
      ? totalRows.every((row) => selectedRows.includes(row))
      : false;

  return { selectionEnabled, handleSelection, isSelected, isAllSelected };
}
