import { useTranslation } from 'react-i18next';
import { LabelBox } from '../../components/common/LabelBox';
import { EndCheckoutLayout } from './Layout';
import { CheckoutAccount } from '../../types/Account';
import { CheckoutInvoice } from '../../types/Invoice';
import { CheckoutPayment } from '../../types/Payment';
import { isCard, isCBU } from '../../utils/paymentUtils';
import { RedirectTimer } from './components/RedirectTimer';
import { CheckoutSummaryTable } from './components/CheckoutSummaryTable';

export interface InvoicePaymentSummaryProps {
  invoice: CheckoutInvoice;
  account: CheckoutAccount;
  payment: CheckoutPayment;
}

export function InvoicePaymentSummary({
  invoice,
  account,
  payment,
}: InvoicePaymentSummaryProps): JSX.Element {
  const { t } = useTranslation();
  const paymentMethodType = payment.payment_method.data.type;
  const isCbu = isCBU(paymentMethodType);
  const isCardNotProcessed =
    isCard(paymentMethodType) && payment.status !== 'approved';
  const successUrl = invoice.success_url;

  const successMessage =
    isCbu || isCardNotProcessed
      ? t('data.invoice.status.processing')
      : t('data.invoice.status.paid');

  return (
    <EndCheckoutLayout brandingSettings={account.branding_settings}>
      <>
        <LabelBox
          variant={isCbu ? 'pending' : 'success'}
          description=""
          title={successMessage}
        />
        {successUrl && <RedirectTimer redirectUrl={successUrl} />}
        <CheckoutSummaryTable invoice={invoice} />
      </>
    </EndCheckoutLayout>
  );
}
