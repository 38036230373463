import { ReactNode } from 'react';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Layout } from '@increasecard/increase-core';
import { Notifications } from './Notifications';
import { SubscriptionRequestNotification } from './features/subscription/SubscriptionRequestNotification';

function getTabs(pathname: string, translate: TFunction) {
  return [
    {
      as: RouterLink,
      to: '/summary',
      name: translate('menu.summary'),
      active: pathname.includes('/summary'),
    },
    {
      as: RouterLink,
      to: '/invoices',
      name: translate('menu.payments'),
      active: pathname.includes('/invoices'),
    },
    {
      as: RouterLink,
      to: '/subscriptions',
      name: translate('menu.subscriptions'),
      active: pathname.includes('/subscriptions'),
    },
    {
      as: RouterLink,
      to: '/plans',
      name: translate('menu.plans'),
      active: pathname.includes('/plans'),
    },
    {
      as: RouterLink,
      to: '/customers',
      name: translate('menu.customers'),
      active: pathname.includes('/customers'),
    },
    {
      as: RouterLink,
      to: '/extras',
      name: translate('menu.extras'),
      active: pathname.includes('/extras'),
    },
    {
      as: RouterLink,
      to: '/settings',
      name: translate('menu.settings'),
      active: pathname.includes('/settings'),
    },
  ];
}

export interface AppHeaderProps {
  children: ReactNode;
}

export function AppHeader({ children }: AppHeaderProps): JSX.Element {
  const match = useRouteMatch();
  const { t } = useTranslation();
  return (
    <>
      <SubscriptionRequestNotification />
      <Notifications />
      <Layout tabs={getTabs(match.url, t)}>{children}</Layout>
    </>
  );
}
