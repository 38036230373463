import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { ConfirmModal } from '../../components/common/ConfirmModal';
import { FullPageSpinner } from '../../components/common/FullPageSpinner';
import { GoBackButton } from '../../components/common/GoBackButton';
import { Header } from '../../components/common/Header';
import { ScreenSeparator } from '../../components/common/ScreenSeparator';
import { ApiErrorMessage } from '../../components/ErrorMessage';
import { useCustomerById } from '../../globalQueries/Queries';
import { useScrollToTopOnMount } from '../../hooks/useScrollToTopOnMount';
import { DebtInvoiceParams } from '../../types';
import { InvoiceProcessModal } from '../invoice/components/InvoiceProcessModal';
import { DebtInvoiceForm } from './DebtInvoiceForm';
import { useCreateDebtInvoice, useDebtItems } from './debtQueries';
import { useDefaultCollectMethods } from '../../hooks/useCollectMethodsOptions';

export function CustomerDebtInvoiceScreen(): JSX.Element {
  useScrollToTopOnMount();
  const { goBack } = useHistory();
  const { customer_id } = useParams<{ customer_id: string }>();
  const debtItems = useDebtItems({ customerId: customer_id });
  const { data: customer, isLoading, error } = useCustomerById(customer_id);
  const defaultCollectMethods = useDefaultCollectMethods();
  const debtInvoice = useCreateDebtInvoice();
  const { t } = useTranslation();

  const handleSubmit = (debtInvoiceValues: DebtInvoiceParams) => {
    debtInvoice.mutate({ ...debtInvoiceValues, customer_id });
  };

  if (
    debtItems.isLoading ||
    defaultCollectMethods.isLoading ||
    !debtItems.data ||
    isLoading ||
    !customer
  )
    return <FullPageSpinner />;

  if (error || debtItems.error)
    return <ApiErrorMessage error={error || debtItems.error} />;

  const noDebt = debtItems.data.length === 0;

  return (
    <>
      <GoBackButton />
      <Header
        title={t('screens.debt_invoice.title')}
        description={t('screens.debt_invoice.customer_desc')}
      />
      <ScreenSeparator />
      <DebtInvoiceForm
        onSubmit={handleSubmit}
        onCancel={goBack}
        customer={customer}
        currency={debtItems.data[0]?.currency || 'ARS'}
        country={customer.billing_info.country}
        debtItems={debtItems.data}
        isSubmitting={debtInvoice.isLoading}
        collectMethods={defaultCollectMethods.data}
      />
      <ConfirmModal
        visible={noDebt}
        message="El cliente buscado no registra deuda en sus facturas emitidas dentro de
        Increase Pay."
        confirmLabel="Volver a la busqueda"
        onConfirm={goBack}
      />
      <InvoiceProcessModal
        onClose={debtInvoice.reset}
        onOk={goBack}
        visible={
          debtInvoice.isLoading || debtInvoice.isSuccess || debtInvoice.isError
        }
        invoice={debtInvoice.data}
        isProcessing={debtInvoice.isLoading}
        error={debtInvoice.error}
      />
    </>
  );
}
