import { StyledHelpIcon } from '../../components/common/HelpIcon';
import { useModal } from '../../hooks/useModal';
import { HelpModal, HelpModalProps } from './HelpModal';

type HelpButtonProps = Omit<HelpModalProps, 'visible' | 'onClose'> & {
  width?: string;
  height?: string;
  iconColor?: 'gray' | 'white';
};

export function HelpButton({
  width,
  height,
  iconColor = 'gray',
  ...rest
}: HelpButtonProps): JSX.Element {
  const helpModal = useModal();
  return (
    <>
      <StyledHelpIcon
        color={iconColor}
        onClick={helpModal.open}
        width={width}
        height={height}
      />
      <HelpModal
        visible={helpModal.isOpen}
        onClose={helpModal.close}
        {...rest}
      />
    </>
  );
}
