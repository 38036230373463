import { Button, H3, Paragraph } from '@increasecard/typed-components';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { GridForm } from '../../components/common/GridForm';
import { LoadingButton } from '../../components/common/LoadingButton';
import { Row } from '../../components/common/Row';
import { ApiErrorMessage } from '../../components/ErrorMessage';
import { CollectMethod, NewApiError } from '../../types';
import { deepDiff } from '../../utils/apiUtils';
import { ConfigFieldsGenerator } from './ConfigFieldsGenerator';
import {
  getConfigByType,
  PanelFormConfig,
} from './collectMethodsFormConfig/PanelConfig';
import { MPConfigPanel } from './MPConfigPanel';
import { RetriesConfig } from './RetriesConfig';

interface CollectMethodFormProps {
  collectMethod?: CollectMethod;
  type: CollectMethod['type'];
  onClose: VoidFunction;
  onSubmit: (values: Partial<PanelFormConfig['initialValues']>) => void;
  error?: NewApiError | null;
  isLoading: boolean;
}

export function CollectMethodForm({
  onClose,
  collectMethod,
  type,
  onSubmit,
  error,
  isLoading,
}: CollectMethodFormProps): JSX.Element | null {
  const { t } = useTranslation();
  if (type) {
    const config = getConfigByType(t, type, collectMethod);
    if (config.showPendingMessage) {
      return <Paragraph>{config.pendingMessage}</Paragraph>;
    }
    if (config.type === 'mercadopago_connect') {
      return <MPConfigPanel config={config} onClose={onClose} />;
    } else {
      const key = collectMethod
        ? `${collectMethod.name}-${collectMethod.id}`
        : type;
      const formikProps = {
        initialValues: config.initialValues,
        enableReinitialize: true,
        validationSchema: config.validationSchema,
        onSubmit: (values: PanelFormConfig['initialValues']) => {
          let newValues: Partial<PanelFormConfig['initialValues']> = values;
          // If values has ID (updating), filter unchanged fields.
          // This is done here becase we need the compueted initialValues
          if (values.id) {
            newValues = deepDiff(values, config.initialValues, {
              keepKeys: ['id'],
            });
          }
          onSubmit(newValues);
        },
      };
      return (
        <Formik {...formikProps}>
          {(formik) => (
            <GridForm>
              <H3>{t('screens.collect_methods.collect_methods_data')}</H3>
              <ConfigFieldsGenerator config={config} key={key} />
              <RetriesConfig />
              <ApiErrorMessage error={error} />
              <Row margin="0" gap="1rem" justifyContent="center">
                <Button onClick={onClose} buttonType="invisible" type="button">
                  Cerrar
                </Button>
                <LoadingButton
                  className="submit"
                  type="submit"
                  disabled={!formik.dirty || !formik.isValid}
                  isLoading={isLoading}
                >
                  {config.status ? t('common.save') : t('common.link_up')}
                </LoadingButton>
              </Row>
            </GridForm>
          )}
        </Formik>
      );
    }
  }
  return null;
}
