import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalContent,
  Button,
  CellText,
  H1,
  ModalHeader,
  ModalFooter,
  ModalHeaderIconBox,
} from '@increasecard/typed-components';
import { WarningTriangleIcon, MonetizationOn } from '@increasecard/icons';
import { Row } from '../../../components/common/Row';
import { useReversePayment } from '../../payments/paymentQueries';
import { Payment } from '../../../types/Payment';
import { formatCurrency } from '../../../utils/currencyUtils';
import { LoadingButton } from '../../../components/common/LoadingButton';

const CREDIT_NOTE_WIDTH = '400';

const StyledH1 = styled(H1)`
  color: var(--color-pay-dark50);
`;

const StyledCellText = styled(CellText)`
  color: var(--color-pay-dark50);
`;

const StyledWarningIcon = styled(WarningTriangleIcon)`
  color: var(--color-pay-light50);
  margin-right: 0.5rem;
`;

export interface CreditNoteModalProps {
  visible: boolean;
  onClose: () => void;
  payment: Payment | undefined | null;
}

export function CreditNoteModal({
  visible,
  onClose,
  payment,
}: CreditNoteModalProps): JSX.Element | null {
  const { t } = useTranslation();
  const { isSuccess, isError, isLoading, mutate, reset } = useReversePayment();

  const handleConfirm = () => {
    if (payment) {
      mutate(payment.id);
    }
  };

  const handleClose = () => {
    onClose();
    reset();
  };

  return (
    <Modal
      align="center"
      onClose={onClose}
      visible={visible}
      width={CREDIT_NOTE_WIDTH}
      onOk={() => null}
      onCancel={() => null}
    >
      <ModalHeaderIconBox
        colorPalette="pay"
        icon={<MonetizationOn height="48" width="48" />}
      />
      <ModalContent align="center">
        <ModalHeader>{t('screens.credit_note.emit')}</ModalHeader>
        <StyledH1>
          {payment ? formatCurrency(payment.amount, payment.currency) : 0}
        </StyledH1>
        <StyledCellText>
          {t('screens.credit_note.invoice_amount')}
        </StyledCellText>
        <Row alignItems="flex-start">
          <StyledWarningIcon />
          <CellText style={{ width: '100%' }}>
            {t('screens.credit_note.disclaimer')}
          </CellText>
        </Row>
        {isSuccess && t('screens.credit_note.success_message')}
      </ModalContent>
      <ModalFooter align="center">
        {isError || isSuccess ? (
          <Button key="close" onClick={handleClose} buttonType="primary">
            {t('common.close')}
          </Button>
        ) : (
          <>
            <Button
              data-testid="cancel-button"
              onClick={handleClose}
              buttonType="invisible"
              disabled={isLoading}
            >
              {t('common.cancel')}
            </Button>
            <LoadingButton
              data-testid="confirm-button"
              onClick={handleConfirm}
              isLoading={isLoading}
            >
              {t('common.refund_payment')}
            </LoadingButton>
          </>
        )}
      </ModalFooter>
    </Modal>
  );
}
