import { apiFront } from './api';
import { Customer } from '../types/Customer';
import { PaginatedData, PaginationConfig } from '../types/common';
import {
  filterInvalidPaymentMethods,
  paymentErrorToApiError,
} from '../utils/apiUtils';
import { CustomerFormValues } from '../features/customers/CustomerForm';
import { PaymentMethod, PaymentMethodContext } from '../types/PaymentMethod';
import { DebtItem } from '../types';

export interface FetchCustomersParams extends PaginationConfig {
  search?: string;
}

export async function fetchCustomers(
  params: FetchCustomersParams
): Promise<PaginatedData<Customer>> {
  const response = await apiFront.get(`customers`, { params });
  response.data.data = response.data.data.map(filterInvalidPaymentMethods);
  return response.data;
}

export async function fetchCustomerById(id: string): Promise<Customer> {
  const response = await apiFront.get(`customers/${id}`);
  return filterInvalidPaymentMethods(response.data);
}

export async function createCustomer(
  customer: CustomerFormValues
): Promise<Customer> {
  const response = await apiFront.post(`customers`, customer);
  return response.data;
}

export type UpdateCustomerParams = Omit<
  Partial<CustomerFormValues>,
  'billing_info'
> & {
  id: string;
  billing_info?: Partial<CustomerFormValues['billing_info']>;
};

export async function updateCustomer({
  id,
  ...data
}: UpdateCustomerParams): Promise<Customer> {
  const response = await apiFront.patch(`customers/${id}`, data);
  return response.data;
}

export async function deleteCustomer({ id }: Customer): Promise<Customer> {
  const response = await apiFront.delete(`customers/${id}`);
  return response.data;
}

export interface DeletePaymentMethodParams {
  customerId: string;
  paymentMethodId: string;
}

export async function deletePaymentMethod({
  customerId,
  paymentMethodId,
}: DeletePaymentMethodParams): Promise<void> {
  const response = await apiFront.delete(
    `customers/${customerId}/payment_methods/${paymentMethodId}`
  );
  return response.data;
}

export interface PaymentMethodCreateParams extends Metadata {
  customer_id: string;
  reference_id: string;
  reference_type: PaymentMethodContext;
}

export async function createPaymentMethod(
  paymentData: PaymentMethodCreateParams
): Promise<PaymentMethod> {
  const response = await apiFront.post('payment_methods', paymentData);
  if (response.data.data.error) {
    return Promise.reject(paymentErrorToApiError(response.data.data.error));
  }
  return response.data;
}

export async function fetchDebtItems(customerId: string): Promise<DebtItem[]> {
  const response = await apiFront.get(
    `customers/${customerId}/uncollectible_invoices`
  );
  return response.data.data;
}
