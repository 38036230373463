import { ReactNode } from 'react';
import { AccessControl, AuthorizationList } from '@increasecard/increase-core';
import { AccessDenied } from './AccessDenied';
import config from '../../config';

export { PayAuthorizations } from './PayAuthorizations';

export interface PayAccessControlProps {
  children: ReactNode;
  allowed: AuthorizationList;
}

export function PayAccessControl({
  children,
  allowed,
}: PayAccessControlProps): JSX.Element {
  // see if authorizations must be checked
  if (config.useAuthorizations === false) {
    // bypass authorizations
    return <>{children}</>;
  }
  return (
    <AccessControl
      allowedAuthorizations={allowed}
      renderNoAccess={<AccessDenied allowed={allowed} />}
    >
      {children}
    </AccessControl>
  );
}
