import { MdiCheckCircleIcon } from '@increasecard/icons';
import {
  Button,
  H2,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeaderIconBox,
  RadioGroup,
} from '@increasecard/typed-components';
import { QRCodeCanvas } from 'qrcode.react';
import { ChangeEvent, useEffect, useState } from 'react';
import { Column } from './Column';
export interface QRGeneratorModalProps {
  value: string;
  visible: boolean;
  title: string;
  onClose: VoidFunction;
}

const noop = () => {
  return;
};

const sizeOptions = [
  { label: 'Chico', value: '128' },
  { label: 'Mediano', value: '256' },
  { label: 'Grande', value: '512' },
];

export function QRGeneratorModal({
  value,
  visible,
  title,
  onClose,
}: QRGeneratorModalProps): JSX.Element {
  const [size, setSize] = useState(256);
  const [imageData, setImageData] = useState('');
  const fileName = `qr-${title}.png`;

  const handleSizeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSize(Number(e.target.value));
  };

  useEffect(() => {
    if (visible && document) {
      const canvas = document.getElementById('qrcode') as HTMLCanvasElement;
      if (canvas) {
        setImageData(canvas.toDataURL());
      }
    }
  }, [size, visible]);

  return (
    <Modal visible={visible} onClose={onClose} onCancel={noop} onOk={noop}>
      <ModalHeaderIconBox
        colorPalette="pay"
        icon={<MdiCheckCircleIcon width={48} height={48} />}
      />
      <ModalContent align="center">
        <Column gap="16px" alignItems="center">
          <H2>{title}</H2>
          <QRCodeCanvas id="qrcode" value={value} size={size} />
          <RadioGroup
            id="qrsize"
            inline
            label="Tamaño"
            name="qrsize"
            onChange={handleSizeChange}
            defaultSelected="256"
            width="auto"
            options={sizeOptions}
          />
        </Column>
      </ModalContent>
      <ModalFooter align="center">
        <Button buttonType="invisible" onClick={onClose}>
          Cerrar
        </Button>
        <Button as="a" download={fileName} href={imageData}>
          Descargar
        </Button>
      </ModalFooter>
    </Modal>
  );
}
