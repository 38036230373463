import axios, { AxiosError } from 'axios';
import config from '../config';
import { NewApiError } from '../types/NewApiError';
import { reportError } from '../utils/errorTracker';

function createClient(
  baseURL: string,
  withCredentials: boolean,
  track404 = true
) {
  const client = axios.create({
    baseURL,
    withCredentials,
  });
  client.interceptors.response.use(
    (response) => response,
    (error) => errorInterceptor(error, track404)
  );
  return client;
}

const apiFrontUrl = `${config.urls.api.pay}/front/v1/`;
const apiFront = createClient(apiFrontUrl, true);

const apiPlatformUrl = `${config.urls.api.platform}/v1/`;
const apiPlatform = createClient(apiPlatformUrl, true);

const apiCheckoutUrl = `${config.urls.api.pay}/checkout/v1/`;
const apiCheckout = createClient(apiCheckoutUrl, false, false);

function errorInterceptor(
  error: AxiosError,
  track404: boolean
): Promise<NewApiError> {
  const { response } = error;
  // check if response is present
  if (response) {
    const { status, data } = response;
    if (status === 401) {
      // Token expired, go to login
      redirectToAuthLogin();
      return Promise.reject(
        NewApiError.fromAPI({
          code: 'unauthorized',
        })
      );
    }
    if (status === 404) {
      // endpoint or object not found, report it
      track404 && reportError('Api error status 404', error, 'warning');
      return Promise.reject(
        NewApiError.fromAPI({
          code: 'UNEXPECTED_ERROR',
        })
      );
    }
    if (status === 403) {
      return Promise.reject(
        NewApiError.fromAPI({
          code: 'unauthorized',
        })
      );
    }
    /*
    Default error response should be:
    {
      errors: [{
        code: INTERNAL_ERROR_NAME, (required, e.g USER_CAN_NOT_EDIT_ANOTHER_USER)
        message: "Nice description" (required)
        metadata: null || { ... (optional keys) }
      }]
    }
    */
    // Legacy errors are errors not yet updated in the backend ¯\_(ツ)_/¯
    const { errors, error: legacyError } = data as {
      errors: Metadata[];
      error: Metadata;
    };
    if ((errors && Array.isArray(errors) && errors.length > 0) || legacyError) {
      // Parse first error
      return Promise.reject(
        NewApiError.fromAPI({
          ...(legacyError ? legacyError : errors[0]),
        })
      );
    }
  }
  // probably a 401 -> https://github.com/axios/axios/issues/383)

  // if (message && message.toLowerCase() === 'network error') {
  //   Promise.reject(NewApiError.fromAPI(error));
  // }

  reportError('Unexpected API error: ', error);
  return Promise.reject(NewApiError.fromAPI(error));
}

function redirectToAuthLogin() {
  window.location.assign(
    `${config.urls.auth}/login?redirect=${window.location.href}`
  );
}

export {
  apiFront,
  apiFrontUrl,
  apiPlatform,
  apiPlatformUrl,
  apiCheckout,
  apiCheckoutUrl,
};
