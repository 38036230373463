import { Grid, H2 } from '@increasecard/typed-components';
import { Select } from './SystemSelect';
import { ALL_COUNTRIES } from '../../localizations';
import { list2NameValue } from '../../utils/utils';
import { useTranslationObject } from '../../hooks/useTranslationObject';
import { ChangeEvent } from 'react';
import { getCountryConfig } from '../../utils/countryUtils';
import { Country } from '../../types/common';

export interface CountryAndCurrencySelectorProps {
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
  country: Country;
  currency: string;
  countrySelectClass: string;
  currencySelectClass: string;
  mode?: 'currencyOnly' | 'countryOnly' | 'both';
}

export function CountryAndCurrencySelector({
  onChange,
  country,
  currency,
  countrySelectClass,
  currencySelectClass,
  mode = 'both',
}: CountryAndCurrencySelectorProps): JSX.Element {
  const { translations } = useTranslationObject('common');
  const { currencies } = getCountryConfig(country);
  const currencyOptions = list2NameValue(currencies, 'data.currencies');
  const countryOptions = list2NameValue(ALL_COUNTRIES, 'data.countries');

  return (
    <Grid rowGap={2}>
      <H2>{translations.region_and_currency}</H2>
      <Select
        id="country"
        onChange={onChange}
        options={countryOptions}
        className={countrySelectClass}
        label={translations.country}
        disabled={mode === 'currencyOnly'}
        value={country}
      />
      <Select
        id="currency"
        onChange={onChange}
        options={currencyOptions}
        className={currencySelectClass}
        label={translations.currency}
        disabled={mode === 'countryOnly'}
        value={currency}
      />
    </Grid>
  );
}
