import { endOfToday, startOfMonth, subMonths } from 'date-fns';
import { ExportButton } from '../../components/common/ExportButton';
import { Row } from '../../components/common/Row';
import { FilterControl } from '../../components/FilterTagsButton/Filters';
import {
  FilterableFields,
  FilterFormConfig,
} from '../../components/FilterTagsButton/types';
import { useUrlState } from '../../hooks/useUrlState';

const DEFAULT_FILTER_VALUES = {
  created_at_from: startOfMonth(subMonths(new Date(), 1)),
  created_at_to: endOfToday(),
  customer_id: { name: 'Todos los clientes', value: '*' },
  status: { name: 'Todos los estados', value: '*' },
};

const DATE_FIELDS: FilterableFields[] = ['created_at_from', 'created_at_to'];

const filterConfig: FilterFormConfig[] = [
  {
    type: 'date_range',
    field: 'created_at_from',
    fieldTo: 'created_at_to',
    label: 'common.created_at',
  },
  {
    type: 'date_range',
    field: 'paid_at_from',
    fieldTo: 'paid_at_to',
    label: 'data.payment.paid_at',
  },
  {
    type: 'custom',
    field: 'customer_id',
  },
  {
    type: 'api_filter',
    field: 'status',
    filterValuesPath: 'payments.statuses',
    label: 'common.status',
  },
  {
    type: 'api_filter',
    field: 'currency',
    filterValuesPath: 'currencies',
    label: 'common.currency',
  },
  {
    type: 'api_filter',
    field: 'collect_method_type',
    filterValuesPath: 'collect_methods.types',
    label: 'common.collect_method',
  },
];

export interface PaymentsFiltersProps {
  filters: Metadata;
}

export function PaymentsFilters({
  filters,
}: PaymentsFiltersProps): JSX.Element {
  return (
    <FilterControl
      defaultValues={DEFAULT_FILTER_VALUES}
      config={filterConfig}
      storageKey="pay_payments_filters"
      dateFields={DATE_FIELDS}
      rightSideButton={
        <Row margin="0" justifySelf="end" width="auto" gap="1rem">
          <ExportButton
            path="payments"
            exportName="common.export_simple"
            pathFilters={filters}
          />
          <ExportButton
            path="payments"
            style={{ gridColumnStart: 4 }}
            exportName="common.export_detailed"
            pathFilters={{
              ...filters,
              report_type: 'payment_with_subscriptions',
            }}
          />
        </Row>
      }
    />
  );
}
// This hook is suposed to be consumed by components outside of FilterProvider
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function usePaymentsFilters<FilterType>() {
  return useUrlState<Partial<FilterType>>({}, DATE_FIELDS);
}
