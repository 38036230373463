import { H1, Paragraph } from '@increasecard/typed-components';
import { Trans, useTranslation } from 'react-i18next';
import {
  PaymentMethods,
  PaymentMethodsProps,
} from '../../../components/paymentMethods/PaymentMethods';
import { CheckoutData } from '../useCheckout';
import { CheckoutAccount } from '../../../types/Account';
import { CheckoutSubscription } from '../../../types/Subscription';
import { getProductPlanNameSummary } from '../checkoutUtils';
import { getCustomerName } from '../../../utils/utils';

export interface PaymentMethodProps
  extends Omit<PaymentMethodsProps, 'country' | 'groupedCollectMethods'> {
  account: CheckoutAccount;
  checkoutData: CheckoutData;
  subscription: CheckoutSubscription;
  customerId: string;
}

export function PaymentMethod({
  checkoutData,
  subscription,
  ...rest
}: PaymentMethodProps): JSX.Element | null {
  const { t } = useTranslation();
  const { plan } = checkoutData;
  const isFirstPaymentMethod = !subscription.payment_method;
  if (plan === null) {
    // eslint-disable-next-line no-console
    console.error(`Plan must be defined`);
    return null;
  }
  const customer = subscription.customer;
  const customerCountry = customer.billing_info.country;
  const customerName = getCustomerName(customer);
  const subscriptionName = getProductPlanNameSummary({
    subscription,
    plan,
  });

  // This seems unnecesary. CMs should come frome the same source.
  const collectMethods =
    plan.collect_methods || subscription.plan.collect_methods;

  const substitleKey = isFirstPaymentMethod
    ? 'screens.customer_checkout.subtitle_payment_method_new'
    : 'screens.customer_checkout.subtitle_payment_method_change';
  return (
    <>
      <H1 style={{ marginBottom: '0.5rem' }}>
        {subscription.payment_method
          ? t('screens.customer_checkout.title_payment_method_change')
          : t('screens.customer_checkout.title_payment_method_new')}
      </H1>
      <Paragraph>
        <Trans
          t={t}
          i18nKey={substitleKey}
          values={{
            subscriptionName,
            customerName,
          }}
        />
      </Paragraph>
      <PaymentMethods
        {...rest}
        country={customerCountry}
        groupedCollectMethods={collectMethods}
      />
    </>
  );
}
