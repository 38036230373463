import { Grid, H2, Caption, Paragraph } from '@increasecard/typed-components';
import { useTranslationObject } from '../../hooks/useTranslationObject';
import { useCollectMethodsOptions } from '../../hooks/useCollectMethodsOptions';
import { Select } from './SystemSelect';
import {
  CollectMethodPaymentType,
  GroupedCollectMethods,
} from '../../types/CollectMethod';
import { ChangeEvent } from 'react';
import { useCountry } from '../../hooks/useCountry';

export interface CollectmethodSelectorProps {
  onChange: (key: string, collect_methods: GroupedCollectMethods) => void;
  country?: string;
  currency?: string;
  collectMethods?: GroupedCollectMethods;
  title?: string;
  description?: string;
  selectorsEnabled?: CollectMethodPaymentType[];
  disabled?: boolean;
}

const cbuSelectClass = 'span-desktop-3 grid-start-desktop-5';
const cardSelectClass = 'span-desktop-3 grid-start-desktop-1';
const ticketSelectClass = 'span-desktop-3 grid-start-desktop-9';

export function CollectMethodSelector({
  onChange,
  country,
  currency,
  collectMethods = { card: [], ticket: [], cbu: [] },
  title,
  description,
  selectorsEnabled = ['card', 'cbu', 'ticket'],
  disabled,
}: CollectmethodSelectorProps): JSX.Element {
  const { translations, t } = useTranslationObject('data.payment.methods');
  const { collectMethodOptions, getSelectedOptions } = useCollectMethodsOptions(
    country,
    currency
  );
  const accountCountry = useCountry();
  const countryWithoutCBU =
    (accountCountry !== 'ARG' && accountCountry) ||
    (country !== 'ARG' && country);

  const handleCollectMethodChange = ({
    currentTarget: { id, value },
  }: ChangeEvent<HTMLSelectElement>) => {
    onChange('collect_methods', {
      ...collectMethods,
      [id]: getSelectedOptions(id, value),
    });
  };
  return (
    <Grid rowGap={2}>
      <div>
        <H2>{title || t('common.collect_methods')}</H2>
        {description && <Paragraph>{description}</Paragraph>}
      </div>
      {selectorsEnabled.includes('card') && (
        <Select
          id="card"
          onChange={handleCollectMethodChange}
          options={collectMethodOptions.card}
          value={collectMethods.card[0]?.id || '*'}
          className={cardSelectClass}
          label={translations.card}
          disabled={disabled}
        />
      )}
      {selectorsEnabled.includes('cbu') && (
        <div className={cbuSelectClass}>
          <Select
            id="cbu"
            onChange={handleCollectMethodChange}
            options={collectMethodOptions.cbu}
            value={collectMethods.cbu[0]?.id || '*'}
            label={translations.cbu}
            disabled={!!countryWithoutCBU || disabled}
          />
          {countryWithoutCBU && (
            <Caption>
              {t('validation.not_enabled_in_country', {
                country: t(`data.countries.${countryWithoutCBU}`),
              })}
            </Caption>
          )}
        </div>
      )}
      {selectorsEnabled.includes('ticket') && (
        <Select
          id="ticket"
          onChange={handleCollectMethodChange}
          options={collectMethodOptions.ticket}
          value={collectMethods.ticket[0]?.id || '*'}
          className={ticketSelectClass}
          label={translations.ticket}
          disabled={disabled}
        />
      )}
    </Grid>
  );
}
