import { CellText } from '@increasecard/typed-components';
import { Row } from '../../../components/common/Row';
import { NameValue } from '../../../types';

export interface BreadcrumbsProps {
  stepActive: string;
  steps: NameValue[];
}
// TODO: usar zustand para compartir el estado del wizard
export function Breadcrumbs({
  steps,
  stepActive,
}: BreadcrumbsProps): JSX.Element {
  return (
    <Row gap="8px">
      {steps.map(({ name, value }) => {
        return (
          <CellText
            key={value}
            weight={value === stepActive ? 'bold' : 'normal'}
          >
            {name} &gt;
          </CellText>
        );
      })}
    </Row>
  );
}
