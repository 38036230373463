import config, { environment } from '../../../config';
// eslint-disable-next-line @typescript-eslint/no-explicit-any

declare global {
  interface Window {
    POS: {
      Fields: (publicKey: string, config?: Metadata) => FieldsInstance;
      createToken: (
        elementField: POSField,
        config?: Metadata
      ) => Promise<Metadata>;
    };
  }
}
export type FieldType = 'cardNumber' | 'creditCardExpiry' | 'cvv';

export type POSField = {
  on: (e?: string, callback?: (event: Metadata) => void) => void;
  mount: (e?: string) => void;
  destroy: () => void;
  container: HTMLElement;
};

export type FieldsInstance = {
  create: (type: FieldType, options: Metadata) => POSField;
};

export class POSWrapper {
  constructor(publicKey: string) {
    this.publicKey = publicKey;
  }
  publicKey = '';
  POS = window.POS;
  createFields = (config: Metadata): FieldsInstance => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return new (this.POS.Fields as any)(this.publicKey, config);
  };
  createToken = async (
    elementType: POSField,
    additionalData: Metadata,
    options = {}
  ): Metadata => {
    const result = await this.POS.createToken(elementType, {
      additionalData,
      environment: environment === 'production' ? 'live' : 'test',
      ...options,
    });
    return typeof result === 'string' ? JSON.parse(result) : result;
  };
}

export const loadPaymentsOSSDK = (publicKey: string): Promise<POSWrapper> => {
  return new Promise((resolve, reject) => {
    const sdkScript = document.createElement('script');
    sdkScript.src = config.collectMethods.payments_os.sdkUrl;
    sdkScript.async = true;
    sdkScript.onload = () => {
      const paymentsOSWrapper = new POSWrapper(publicKey);
      resolve(paymentsOSWrapper);
    };
    sdkScript.onerror = reject;
    document.body.appendChild(sdkScript);
  });
};
