import { getCountryConfig } from '../../utils/countryUtils';
import {
  CheckoutAccount,
  CheckoutInvoice,
  CheckoutPayment,
  CheckoutPlan,
  CheckoutSubscription,
  InvoiceItem,
  ProductReference,
} from '../../types';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getCheckoutSummary(invoice?: CheckoutInvoice) {
  // eslint-disable-next-line prefer-const
  let lineItems: InvoiceItem[] = [];
  let total = 0;
  let language = 'es';
  let customerCountry;
  let currency;

  if (invoice) {
    total = Number(invoice.amount);
    lineItems = invoice.items;
    customerCountry = invoice.customer.billing_info?.country;
    currency = invoice.currency;
    language = getCountryConfig(customerCountry).language;
  }

  return {
    total,
    lineItems,
    customerCountry,
    currency,
    language,
  };
}

export function getProductPlanNameSummary({
  subscription,
  product,
  plan,
}: {
  subscription?: CheckoutSubscription;
  product?: ProductReference;
  plan?: CheckoutPlan | null;
}): string {
  const getTitle = (productName: string, planName?: string) => {
    if (!productName && !planName) return '';
    if (productName && !planName) return productName;
    return `${productName} • ${planName}`;
  };
  if (subscription) {
    const { product, plan } = subscription;
    return getTitle(product.name, plan.name);
  }
  if (plan) {
    getTitle(plan.product.name, plan.name);
  }
  if (product) {
    getTitle(product.name);
  }
  return '';
}

export function changeFaviconUrl(url: string): void {
  const currentFaviconLink = document.getElementById(
    'favicon'
  ) as HTMLLinkElement | null;
  if (currentFaviconLink) {
    currentFaviconLink.href = url;
  }
}

export function getShopName(account: CheckoutAccount): string {
  const shop_name = account?.branding_settings?.shop_name;
  return shop_name || account.name;
}

export function findPayment(
  { invoices }: CheckoutSubscription,
  paymentMethodId: string | undefined
): CheckoutPayment | undefined {
  for (let i = 0; i < invoices.length; i++) {
    const { payments } = invoices[i];
    const payment = payments.find(
      (p) => p.payment_method_id === paymentMethodId
    );
    if (payment) {
      return payment;
    }
  }
}

export function canChangePaymentMethod(
  subscription: CheckoutSubscription
): boolean {
  return ![
    'pending_canceled',
    'pending_finished',
    'canceled',
    'finished',
  ].includes(subscription.status);
}
