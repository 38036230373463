import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import {
  createBrandingSettings,
  fetchAccount,
  generateApiKey,
  NotificationSampleParam,
  sendNotificationSample,
  updateAccountSettings,
  UpdateAccountSettingsParam,
  updateBrandingSettings,
  UpdateBrandingSettingsParams,
  updateHooks,
  UpdateHooksParams,
  uploadBrandingImages,
} from '../services/AccountService';
import {
  CollectMethodsParams,
  fetchCollectMethods,
} from '../services/CollectMethodService';
import { fetchCustomerById } from '../services/CustomersService';
import { fetchFilters } from '../services/FilterService';
import {
  createOrUpdatePartner,
  fetchPartners,
} from '../services/PartnersService';
import { Account, AccountBrandingSettings } from '../types/Account';
import { CollectMethod } from '../types/CollectMethod';
import { ApiFilters } from '../types/common';
import { Customer } from '../types/Customer';
import { NewApiError } from '../types/NewApiError';
import { Partner } from '../types/Partner';
import {
  getPaginatedQueriesData,
  useMutationWithInvalidation,
} from '../utils/queryUtils';

const QUERY_KEYS = {
  ACCOUNT: 'accounts/me',
  COLLECT_METHODS: 'collect_methods',
};

export const useAccount = (
  options?: UseQueryOptions<Account, NewApiError, Account, string[]>
): UseQueryResult<Account, NewApiError> => {
  return useQuery([QUERY_KEYS.ACCOUNT], fetchAccount, options);
};

export const useUpdateAccountSetting = (): UseMutationResult<
  Account,
  NewApiError,
  UpdateAccountSettingsParam
> => {
  const queryClient = useQueryClient();
  return useMutation(updateAccountSettings, {
    onSuccess: () => queryClient.invalidateQueries([QUERY_KEYS.ACCOUNT]),
  });
};

// This query should be replaced with useUpdateAccountSettings
export const useUpdateHooks = (): UseMutationResult<
  void,
  NewApiError,
  UpdateHooksParams
> => {
  const queryClient = useQueryClient();
  return useMutation(updateHooks, {
    onSuccess: () => queryClient.invalidateQueries([QUERY_KEYS.ACCOUNT]),
  });
};

export const useGenerateApiKey = (): UseMutationResult<
  Pick<Account, 'secret_api_key'>,
  NewApiError,
  void | null
> => {
  return useMutation(generateApiKey);
};

export type BrandingConfig = Omit<
  UpdateBrandingSettingsParams,
  'logo_path' | 'icon_path'
> & {
  logoFile?: File;
  iconFile?: File;
  logo_url?: string;
  icon_url?: string;
  shouldCreate: boolean;
};

export const useUpdateBrandingSettings = (): UseMutationResult<
  AccountBrandingSettings,
  NewApiError,
  BrandingConfig
> => {
  const queryClient = useQueryClient();
  return useMutation(
    async (brandingConfig: BrandingConfig) => {
      const { logoFile, logo_url, iconFile, icon_url, shouldCreate, ...rest } =
        brandingConfig;
      const brandSettings: UpdateBrandingSettingsParams = rest;
      // If logoUrl was set to a falsy value set the image path to null to remove the image.
      if (logoFile) {
        brandSettings.logo_path = await uploadBrandingImages(logoFile, 'logo');
      } else if (!logo_url) {
        brandSettings.logo_path = null;
      }
      if (iconFile) {
        brandSettings.icon_path = await uploadBrandingImages(iconFile, 'icon');
      } else if (!icon_url) {
        brandSettings.icon_path = null;
      }
      return shouldCreate
        ? await createBrandingSettings(brandSettings)
        : await updateBrandingSettings(brandSettings);
    },
    {
      onSuccess: () => queryClient.invalidateQueries([QUERY_KEYS.ACCOUNT]),
    }
  );
};

export const useSendNotificationSample = (): UseMutationResult<
  void,
  NewApiError,
  NotificationSampleParam
> => {
  return useMutation(sendNotificationSample);
};

export const useCollectMethods = <T = CollectMethod[]>(
  params?: CollectMethodsParams,
  options?: Pick<
    UseQueryOptions<CollectMethod[], NewApiError, T>,
    'select' | 'initialData' | 'enabled'
  >
): UseQueryResult<T, NewApiError> => {
  return useQuery(
    [QUERY_KEYS.COLLECT_METHODS, params],
    () => {
      return fetchCollectMethods(params);
    },
    options
  );
};

export const useCollectMethodById = (
  id?: string
): UseQueryResult<CollectMethod, NewApiError> => {
  return useCollectMethods(
    {},
    // Since CMs cant be deleted, this will always return something
    {
      enabled: !!id,
      select: (cms) => cms.find((cm) => cm.id === id) as CollectMethod,
    }
  );
};

export const useFilters = (): UseQueryResult<ApiFilters, NewApiError> => {
  return useQuery(['filters'], () => fetchFilters(), {
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};

export const useCustomerById = (
  id?: string
): UseQueryResult<Customer, NewApiError> => {
  const queryClient = useQueryClient();
  // id is not really optional for the service. But the query will be disabled
  // if id is undefined.
  return useQuery(['customer', id], () => fetchCustomerById(id || ''), {
    enabled: !!id,
    placeholderData: () => {
      return getPaginatedQueriesData<Customer>(queryClient, [
        'customer',
        'list',
      ])?.find((c) => c.id === id);
    },
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const usePartners = () => {
  return useQuery(['partners'], () => fetchPartners());
};

export const useCreateOrUpdatePartner = (): UseMutationResult<
  Partner,
  NewApiError,
  Metadata
> => {
  return useMutationWithInvalidation(createOrUpdatePartner, ['partners']);
};
