import { useTranslation } from 'react-i18next';
import { Grid } from '@increasecard/typed-components';
import {
  InputTextField,
  SelectField,
} from '../../components/common/FormikFields';
import { PanelFormConfig } from './collectMethodsFormConfig/PanelConfig';
import { IconButtonTooltip } from '../../components/common/HelpTooltip';

export interface ConfigFieldsGeneratorProps {
  config: PanelFormConfig;
}

export function ConfigFieldsGenerator({
  config,
}: ConfigFieldsGeneratorProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <Grid rowGap={2}>
      <InputTextField
        label={[
          t('common.name'),
          <IconButtonTooltip
            key="1"
            content={t('screens.collect_methods.name')}
          />,
        ]}
        id="name"
        className="grid-span-desktop-4"
        required={true}
      />
      {config.inputs.map((input, index) => {
        const label = input.tooltipContent
          ? [
              input.label,
              <IconButtonTooltip key="1" content={input.tooltipContent} />,
            ]
          : input.label;
        const isRequired = 'required' in input ? input.required : true;
        return (
          <div className="grid-span-desktop-4" key={`${input.id}-${index}`}>
            {input.type === 'text' && (
              <InputTextField
                id={input.id}
                label={label}
                autocomplete="off"
                placeholder={input.placeholder}
                required={isRequired}
                disabled={input.disabled}
              />
            )}
            {input.type === 'select' && (
              <SelectField
                id={input.id}
                label={input.label}
                placeholder={input.placeholder}
                options={input.options}
                required={isRequired}
                disabled={input.disabled}
              />
            )}
          </div>
        );
      })}
    </Grid>
  );
}
