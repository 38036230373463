import { ReactNode } from 'react';
import { Header } from '../common/Header';
import { useImport } from './useImport';
import { ImportInput } from './ImportInput';
import { ImportsTable } from './ImportsTable';
import { FileProcessing } from './FileProcessing';
import { ImportType } from '../../types/ImportFile';
import { ImportNotificationStatus } from './ImportNotificationStatus';
import { LoadingButton } from '../common/LoadingButton';

export interface ImportProps {
  type: ImportType;
  title?: string;
  subtitle?: string;
  importButtonText: string;
  description?: string | ReactNode;
  templateUrl?: string;
  helpCenterUrl?: string;
}

export function Import({
  type,
  title,
  subtitle,
  importButtonText,
  description,
  templateUrl,
  helpCenterUrl,
}: ImportProps): JSX.Element {
  const {
    selectFile,
    uploadFile,
    selectedFile,
    isUploading,
    isProcessing,
    jobInfo,
  } = useImport(type);

  const handleImport = () => {
    uploadFile();
  };

  const handleFile = (file: File | null) => {
    selectFile(file);
  };

  return (
    <>
      <Header title={title} />
      <ImportInput
        isLoading={isUploading || isProcessing}
        onChange={handleFile}
        title={subtitle}
        importType={type}
        description={description}
        templateUrl={templateUrl}
        helpCenterUrl={helpCenterUrl}
        selectedFile={selectedFile}
      />

      <LoadingButton
        isLoading={isUploading || isProcessing}
        onClick={handleImport}
        style={{ marginTop: '3rem' }}
      >
        {importButtonText}
      </LoadingButton>

      {jobInfo && <ImportNotificationStatus importData={jobInfo} type={type} />}
      <ImportsTable type={type} />
      {isProcessing && jobInfo && <FileProcessing jobInfo={jobInfo} />}
    </>
  );
}
