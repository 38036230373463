import { useCheckoutBootUp } from './useCheckoutBootUp';
import { useLanguage } from '../../hooks/useLanguage';
import { CustomerPaymentMethod } from './components/CustomerPaymentMethod';
import { useCheckoutParams } from './useCheckoutParams';
import {
  useAccountById,
  useCollectMethods,
  useCustomerById,
} from './checkoutQueries';
import { useErrorUserData } from './useErrorUserData';
import { FullPageSpinner } from '../../components/common/FullPageSpinner';
import { ApiErrorMessage } from '../../components/ErrorMessage';

export function CustomerPaymentMethodScreen(): JSX.Element {
  useCheckoutBootUp();
  const { accountId, customerId } = useCheckoutParams();
  const customer = useCustomerById(customerId);
  const account = useAccountById(accountId);
  const collectMethods = useCollectMethods(accountId, customerId);
  useLanguage(customer.data?.billing_info?.country);
  useErrorUserData({ customer: customer.data });

  if (!account.data || !customer.data || !collectMethods.data) {
    return <FullPageSpinner />;
  }
  const error = account.error || customer.error || collectMethods.error;
  if (error) {
    return <ApiErrorMessage error={error} />;
  }

  return (
    <CustomerPaymentMethod
      account={account.data}
      customer={customer.data}
      groupedCollectMethods={collectMethods.data}
    />
  );
}
