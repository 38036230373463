import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Header } from '../../components/common/Header';
import { PayAuthorizations } from '../authorizations/usePayAuthorizations';
import { InvoiceTable } from './InvoiceTable';
import { InvoiceFilters, useInvoiceFilters } from './components/InvoiceFilters';

interface InvoiceListProps {
  context: string;
}

const defaultKindFilter = ['subscription', 'custom'];

export function InvoicesList({ context }: InvoiceListProps): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const [filters] = useInvoiceFilters();
  const defaultFilters = { ...filters };

  if (!defaultFilters.kind) {
    defaultFilters.kind = defaultKindFilter;
  }

  const onNewInvoiceClick = () => {
    history.push('/invoices/new');
  };

  // render
  return (
    <>
      <Header
        title={t('screens.invoices.title')}
        primaryButton={{
          onClick: onNewInvoiceClick,
          text: t('common.new_invoice'),
          allowedAuthorization: PayAuthorizations.createInvoice,
        }}
      />
      <InvoiceFilters />
      <InvoiceTable filters={defaultFilters} context={context} />
    </>
  );
}
