import { Checkbox, Grid } from '@increasecard/typed-components';
import { ChangeEvent, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CollectMethodSelector } from '../../components/common/CollectMethodSelector';
import { Column } from '../../components/common/Column';
import { CountryAndCurrencySelector } from '../../components/common/CountryAndCurrencySelector';
import { Header } from '../../components/common/Header';
import { LoadingButton } from '../../components/common/LoadingButton';
import { FileProcessing } from '../../components/imports/FileProcessing';
import { ImportInput } from '../../components/imports/ImportInput';
import { ImportNotificationStatus } from '../../components/imports/ImportNotificationStatus';
import { ImportsTable } from '../../components/imports/ImportsTable';
import { useImport } from '../../components/imports/useImport';
import { Country, Currency, GroupedCollectMethods } from '../../types';
import { ImportType } from '../../types/ImportFile';
import { hasCollectMethods } from '../../utils/collectMethodsUtils';
import { useDefaultCollectMethods } from '../../hooks/useCollectMethodsOptions';

const type = ImportType.Invoice;

const templateUrl = '/upload_templates/invoices_template.csv';

type UploadInvoiceConfig = {
  collect_methods?: GroupedCollectMethods;
  currency: Currency;
  country: Country;
  notify_invoices_with_payment_method: boolean;
  notify_invoices_without_payment_method: boolean;
};

const INITIAL_VALUES: UploadInvoiceConfig = {
  country: 'ARG',
  currency: 'ARS',
  notify_invoices_without_payment_method: false,
  notify_invoices_with_payment_method: false,
};

export function ImportInvoicesScreen(): JSX.Element {
  const { t } = useTranslation();
  const defaultCollectMethods = useDefaultCollectMethods();
  const [uploadConfig, setUploadConfig] =
    useState<UploadInvoiceConfig>(INITIAL_VALUES);
  const {
    selectFile,
    uploadFile,
    selectedFile,
    isUploading,
    isProcessing,
    isProcessed,
    jobInfo,
  } = useImport(type);

  useEffect(() => {
    if (jobInfo && isProcessed) {
      window.scrollTo({
        left: 0,
        top: document.body.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [isProcessed, jobInfo]);

  useEffect(() => {
    if (defaultCollectMethods.data && !uploadConfig.collect_methods) {
      setUploadConfig((oldConfig) => ({
        ...oldConfig,
        collect_methods: defaultCollectMethods.data,
      }));
    }
  }, [defaultCollectMethods.data, uploadConfig.collect_methods]);

  const handleImport = () => {
    uploadFile(uploadConfig, () => {
      setUploadConfig(INITIAL_VALUES);
    });
  };

  const handleFile = (file: File | null) => {
    selectFile(file);
  };

  const handleCMConfig = (_: string, value: Metadata) => {
    setUploadConfig({
      ...uploadConfig,
      collect_methods: value,
    });
  };

  const handleConfigChange = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>
  ) => {
    const value = 'checked' in e.target ? e.target.checked : e.target.value;
    const collect_methods =
      e.target.name === 'country' || e.target.name === 'currency'
        ? { card: [], ticket: [], cbu: [] }
        : uploadConfig.collect_methods;
    setUploadConfig((oldConfig) => ({
      ...oldConfig,
      collect_methods,
      [e.target.name]: value,
    }));
  };

  const isValid = () => {
    return (
      hasCollectMethods(uploadConfig.collect_methods) &&
      uploadConfig.country &&
      uploadConfig.currency
    );
  };
  const name = {
    name: t('common.invoices'),
  };

  const description = (
    <Trans t={t} i18nKey="screens.import_invoices.description" />
  );

  return (
    <>
      <Header title={t('screens.import.title', name)} />
      <ImportInput
        isLoading={isUploading || isProcessing}
        onChange={handleFile}
        description={description}
        importType={type}
        templateUrl={templateUrl}
        selectedFile={selectedFile}
      />
      <Grid rowGap={2} style={{ margin: '32px 0' }}>
        <CountryAndCurrencySelector
          onChange={handleConfigChange}
          country={uploadConfig?.country}
          currency={uploadConfig?.currency}
          countrySelectClass="span-desktop-3 grid-start-desktop-1"
          currencySelectClass="span-desktop-3 grid-start-desktop-5"
        />
        <CollectMethodSelector
          country={uploadConfig?.country}
          currency={uploadConfig?.currency}
          onChange={handleCMConfig}
          collectMethods={uploadConfig?.collect_methods}
        />
      </Grid>
      <Column gap="1rem">
        <Checkbox
          id="notify_invoices_with_payment_method"
          name="notify_invoices_with_payment_method"
          label={t('screens.import.notify_invoices_with_payment_method')}
          onChange={handleConfigChange}
          checked={uploadConfig.notify_invoices_with_payment_method}
          disabled={isUploading || isProcessing}
        />
        <Checkbox
          id="notify_invoices_without_payment_method"
          name="notify_invoices_without_payment_method"
          label={t('screens.import.notify_invoices_without_payment_method')}
          onChange={handleConfigChange}
          checked={uploadConfig.notify_invoices_without_payment_method}
          disabled={isUploading || isProcessing}
        />
      </Column>
      <LoadingButton
        disabled={!isValid()}
        isLoading={isUploading || isProcessing}
        onClick={handleImport}
      >
        {t('screens.import.import', name)}
      </LoadingButton>

      {jobInfo && <ImportNotificationStatus importData={jobInfo} type={type} />}
      <ImportsTable type={type} />
      {isProcessing && jobInfo && <FileProcessing jobInfo={jobInfo} />}
    </>
  );
}
