import { useUser } from '@increasecard/increase-core';
import { useTranslation } from 'react-i18next';
import { PaymentMethods } from './PaymentMethods';
import { countryFromPlatform } from '../../utils/countryUtils';
import { GroupedCollectMethods } from '../../types/CollectMethod';
import { PaymentMethod, PaymentReference } from '../../types/PaymentMethod';
import { useCreatePaymentMethod } from '../../features/customers/customerQueries';
import { NewApiError } from '../../types';

export interface PaymentMethodCreatorProps {
  customerId: string;
  groupedCollectMethods: GroupedCollectMethods;
  paymentReference: PaymentReference;
  onSuccess?: (paymentMethod: PaymentMethod) => void;
  onError?: (error: NewApiError) => void;
  onCancel?: VoidFunction;
}

export function PaymentMethodCreator({
  customerId,
  groupedCollectMethods,
  paymentReference,
  onSuccess,
  onError,
  onCancel,
}: PaymentMethodCreatorProps): JSX.Element | null {
  const { t } = useTranslation();
  const user = useUser();
  const { mutate, isLoading, error } = useCreatePaymentMethod();

  const handleCreatePaymentMethod = (data: Metadata) => {
    mutate(
      {
        ...data,
        ...paymentReference,
        customer_id: customerId,
      },
      {
        onSuccess,
        onError,
      }
    );
  };

  if (!user) return null;

  return (
    <div data-testid="payment-method-form">
      <PaymentMethods
        country={countryFromPlatform(user.defaultCountry)}
        processing={isLoading}
        onNext={handleCreatePaymentMethod}
        onCancel={onCancel}
        submitText={t('common.add')}
        customerId={customerId}
        groupedCollectMethods={groupedCollectMethods}
        isUniquePayment={paymentReference.reference_type === 'invoice'}
        errorObj={error}
      />
    </div>
  );
}
