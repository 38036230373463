import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalHeaderIconBox,
  H2,
} from '@increasecard/typed-components';
import { PencilIcon } from '@increasecard/icons';
import { Constants } from '../../constants';
import { useClipboard } from '../../hooks/useClipboard';
import { useModal } from '../../hooks/useModal';
import { EditProductForm } from '../../features/plan/components/EditProductForm';
import { Product } from '../../types/Product';
import { DataTable, RowDefinition } from '../common/DataTable/DataTable';
import { usePayAuthorizations } from '../../features/authorizations/usePayAuthorizations';
import { ApiErrorMessage } from '../ErrorMessage';
import {
  useProducts,
  useUpdateProduct,
} from '../../features/plan/productQueries';
import { QRGeneratorModal } from '../common/QRGeneratorModal';

export function ProductsList(): JSX.Element {
  const changeNameModal = useModal<Product>();
  const { t } = useTranslation();
  const clipboard = useClipboard();
  const history = useHistory();
  const { canCreateEditProductPlan } = usePayAuthorizations();
  const [page, setPage] = useState(1);
  const updater = useUpdateProduct();
  const { data, error, isLoading, refetch } = useProducts({
    page,
    per_page: Constants.MEDIUM_PAGE_SIZE,
  });
  const qrModal = useModal();

  const menuActions = [
    {
      label: t('screens.products.change_name'),
      cb: (product: RowDefinition) => changeNameModal.open(product as Product),
      disabled: () => !canCreateEditProductPlan(),
    },
    {
      label: t('screens.products.add_plan'),
      cb: (product: RowDefinition) =>
        history.push(
          `/plans/new?product_id=${product.id}&name=${encodeURIComponent(
            product.name
          )}`
        ),
      disabled: () => !canCreateEditProductPlan(),
    },
    {
      tooltipLabel: t('common.copied'),
      label: t('common.copy_subscription_link'),
      cb: (product: RowDefinition) => clipboard.copy(product.checkout_link),
    },
    {
      label: t('common.generate_qr'),
      cb: (product: RowDefinition) => qrModal.open(product),
    },
  ];

  // error
  if (error) {
    return <ApiErrorMessage error={error} onClick={() => refetch()} />;
  }
  const hasData = data?.data ? data?.data.length > 0 : false;
  const tableColumns = [
    {
      field: 'name',
      headerName: t('common.product'),
    },
  ];
  // render
  return (
    <>
      <DataTable
        tableProps={{ zebra: true, hasData }}
        columns={tableColumns}
        pageSize={Constants.MEDIUM_PAGE_SIZE}
        isLoading={!hasData && isLoading}
        rows={data?.data}
        menuActions={menuActions}
        paginatorProps={{
          pages: data?.total_pages,
          selected: Number(data?.current_page),
          onSelectPage: setPage,
        }}
      />
      <Modal
        align="center"
        visible={changeNameModal.isOpen}
        onClose={changeNameModal.close}
        width="400"
        onOk={() => {
          return;
        }}
        onCancel={changeNameModal.close}
      >
        <ModalHeaderIconBox icon={<PencilIcon height="48" width="48" />} />
        <ModalHeader align="center">
          <H2>{t('screens.products.edit_product')}</H2>
        </ModalHeader>
        <ModalContent>
          {changeNameModal.data !== null && (
            <EditProductForm
              product={changeNameModal.data}
              onCancel={changeNameModal.close}
              onSubmit={async (values) => {
                await updater.mutateAsync(values);
                changeNameModal.close();
              }}
            />
          )}
        </ModalContent>
      </Modal>
      <QRGeneratorModal
        visible={qrModal.isOpen}
        onClose={qrModal.close}
        value={qrModal.data?.checkout_link}
        title={qrModal.data?.name}
      />
    </>
  );
}
