import { useEventTracker } from '@increasecard/increase-core';

export type trackEvent = (
  eventName: string,
  extraProps?: Record<string, string>
) => void;

export function useUIEventTracker(context?: string): trackEvent {
  const eventTracker = useEventTracker();

  const trackEvent = (
    eventName: string,
    extraProps?: Record<string, string>
  ) => {
    const section = window.location.pathname
      .replace(
        /\/[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}\//,
        '/'
      )
      .replace(/s\//, '/')
      .split('/')
      .join(' ')
      .trim();
    eventTracker.track(eventName, {
      context,
      ...extraProps,
      section,
    });
  };

  return trackEvent;
}
