import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Grid, H1, H2, Link as TCLink } from '@increasecard/typed-components';
import { GoBackButton } from '../../components/common/GoBackButton';
import { Row } from '../../components/common/Row';
import { Column } from '../../components/common/Column';
import { RoundedCard } from '../../components/common/RoundedCard';
import { formatCurrency } from '../../utils/currencyUtils';
import { Invoice } from '../../types/Invoice';
import { DetailsBox, RowDefinition } from '../../components/common/DetailsBox';
import { PaymentMethodDetailBox } from '../../components/paymentMethods/PaymentMethodDetailBox';
import { InvoiceActionsMenu } from './components/InvoiceActionsMenu';
import { InvoiceDetail as DetailInvoice } from '../../components/InvoiceDetail';
import { getCollectMethodsNames } from '../../utils/collectMethodsUtils';
import { EventList } from '../../components/listing/EventList';
import { PaymentsTable } from '../payments/PaymentsTable';
import { Constants } from '../../constants';
import { useScrollToTopOnMount } from '../../hooks/useScrollToTopOnMount';
import { DetailHeader } from '../../components/common/DetailHeader';
import { useModal } from '../../hooks/useModal';
import { AddPMToInvoiceModal } from '../../components/paymentMethods/AddPMToInvoice';
export interface InvoiceDetailProps {
  invoice: Invoice;
}

export function InvoiceDetail({
  invoice,
}: InvoiceDetailProps): JSX.Element | null {
  useScrollToTopOnMount();
  const paymentMethodModal = useModal();
  const { t } = useTranslation();

  const { latest_payment_method } = invoice;
  const paymentsFilter = {
    invoice_id: invoice.id,
    per_page: Constants.MEDIUM_PAGE_SIZE,
  };
  const paymentsHeader = `${t('common.payment_orders')} (${
    invoice.payments.length
  } intentos procesados)`;

  return (
    <Grid columnGap={1} rowGap={3}>
      <Row margin="0" justifyContent="space-between">
        <GoBackButton />
        <Row margin="0" gap="1rem" width="auto">
          <InvoiceActionsMenu text={t('common.actions')} invoices={[invoice]} />
        </Row>
      </Row>
      <DetailHeader
        title={t('common.invoice') + ' ID: '}
        titleb={invoice.id}
        subTitle={invoice.customer.email}
        subtitleHref={`/customers/${invoice.customer.id}/detail`}
      />
      <Column
        margin="0"
        gap="1rem"
        className="grid-span-desktop-5 grid-span-mobile-12"
        childrenFlex="1"
      >
        <RoundedCard variant="green" centered gap="1rem">
          <H2 weight="400">{t('screens.invoice_detail.payment_amount')}</H2>
          <H1>
            {formatCurrency(
              parseFloat(invoice.amount) > 0 ? parseFloat(invoice.amount) : 0,
              invoice.currency
            )}
          </H1>
        </RoundedCard>
        <PaymentMethodDetailBox
          paymentMethod={latest_payment_method}
          onChangeClick={() => paymentMethodModal.open(invoice)}
          buttonDisabled={!invoice.may_use_checkout_link}
        />
      </Column>
      <InvoiceDetailBox invoice={invoice} />
      <DetailInvoice invoice={invoice} />
      <PaymentsTable
        filters={paymentsFilter}
        header={paymentsHeader}
        omitFields={['invoice.customer']}
        trackingContext="invoice detail"
      />
      <EventList events={invoice.events} />
      <AddPMToInvoiceModal
        visible={paymentMethodModal.isOpen}
        invoice={paymentMethodModal.data}
        onClose={paymentMethodModal.close}
      />
    </Grid>
  );
}

function InvoiceDetailBox({ invoice }: { invoice: Invoice }): JSX.Element {
  const { t } = useTranslation();

  const rowsConfig: RowDefinition[] = [
    {
      label: 'Fecha de creación',
      type: 'date',
      field: 'created_at',
    },
    {
      label: 'Origen',
      type: 'string',
      field: 'origin',
      valueGetter: (invoice) => t(`data.invoice_origins.${invoice.origin}`),
    },
    {
      label: 'Ultima Actualización',
      type: 'date',
      field: 'updated_at',
    },
    {
      label: 'Estado',
      field: 'status',
      type: 'tag',
      tagType: 'invoice',
      tagLabel: (invoice) => t(`data.invoice.status.${invoice.status}`),
    },
    {
      label: 'Divisa',
      type: 'string',
      field: 'currency',
    },
    {
      field: 'kind',
      type: 'string',
      label: 'Tipo',
      valueGetter: (invoice) => t(`data.invoice.kinds.${invoice.kind}`),
    },
    {
      field: 'external_id',
      type: 'string',
      label: 'ID de Referencia',
    },
    {
      field: 'external_id',
      type: 'custom',
      label: 'Metodo de cobro',
      valueGetter: (invoice) => {
        return getCollectMethodsNames(invoice.collect_methods);
      },
    },
  ];
  if (invoice.kind === 'subscription') {
    const subscriptionItems = invoice.invoice_items.filter(
      ({ invoiceable_type }) => invoiceable_type === 'Subscription'
    );
    rowsConfig.push({
      field: 'subscription_id',
      type: 'string',
      label: 'Id de suscripcion',
      valueGetter: () =>
        subscriptionItems.map(({ invoiceable_id }) => (
          <TCLink
            key={invoiceable_id}
            as={Link}
            to={`/subscriptions/${invoiceable_id}/detail`}
          >
            {invoiceable_id}
          </TCLink>
        )),
    });
  }

  return (
    <DetailsBox
      className="grid-span-desktop-7 grid-span-mobile-2"
      data={invoice}
      title={t('common.details')}
      rowsConfig={rowsConfig}
    />
  );
}
