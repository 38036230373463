import {
  QueryClient,
  QueryKey,
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';
import { PaginatedData } from '../types/common';
import { NewApiError } from '../types/NewApiError';

export const useMutationWithInvalidation = <
  ServiceParams,
  QueryResult = void | NewApiError,
>(
  service: (params: ServiceParams) => Promise<QueryResult>,
  ...queryKeys: QueryKey[]
): UseMutationResult<QueryResult, NewApiError, ServiceParams> => {
  const queryClient = useQueryClient();
  const invalidateQueries = () => {
    queryKeys.forEach((key) => {
      queryClient.invalidateQueries(key);
    });
  };
  return useMutation(service, {
    onSuccess: () => invalidateQueries(),
  });
};

export const getPaginatedQueriesData = <TData>(
  queryClient: QueryClient,
  queryKey: QueryKey
): TData[] | undefined => {
  return (
    queryClient
      .getQueriesData<PaginatedData<TData>>(queryKey)
      // TODO: investigate why sometimes queryData is undefined.
      .flatMap(([, queryData]) => queryData?.data || [])
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const queryKeyFactory = (entity: string) => {
  const queryKeys = {
    all: [entity] as const,
    lists: () => [...queryKeys.all, 'list'] as const,
    list: (params: Metadata) => [...queryKeys.lists(), params] as const,
    details: () => [...queryKeys.all, 'detail'] as const,
    detail: (id: string | undefined) => [...queryKeys.details(), id] as const,
  };
  return queryKeys;
};
