import { useContext } from 'react';
import { CellText, H2, TableWrapper } from '@increasecard/typed-components';
import {
  Bar,
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import { ErrorMessage } from '../../components/ErrorMessage';
import { useTranslation } from 'react-i18next';
import { DefaultTheme, ThemeContext } from 'styled-components';
import { FullPageSpinner } from '../../components/common/FullPageSpinner';
import { HelpButton } from '../../components/common/HelpButton';
import { useSubscriptionsByStatus } from './summaryQueries';
import { useMetricsFilters } from './useMetricsFilters';
import { Row } from '../../components/common/Row';
import { CHART_TOOLTIP_STYLE, getYAxisLabel } from './helpers';
import { SubscriptionStatus } from '../../types/Subscription';

function getStatusColors({ colorsNew }: DefaultTheme) {
  const statusColors: Record<SubscriptionStatus, string> = {
    pending_approval: colorsNew.count.light30,
    pending: colorsNew.count.regular,
    pending_payment: colorsNew.count.dark30,
    active: colorsNew.increase.regular,
    trial: colorsNew.increase.dark50,
    grace_period: colorsNew.coral.regular,
    scheduled: colorsNew.pay.light30,
    paused: colorsNew.pay.dark30,
    request_rejected: colorsNew.gray.grayMedium,
    canceled: colorsNew.gray.blackLight,
    finished: colorsNew.gray.blackLight,
    pending_canceled: colorsNew.gray.blackLight,
    pending_finished: colorsNew.gray.blackLight,
  };
  return statusColors;
}

const status: SubscriptionStatus[] = [
  'active',
  'pending',
  'trial',
  'pending_payment',
  'scheduled',
  'grace_period',
  'paused',
  'pending_approval',
  'request_rejected',
  'canceled',
  'pending_canceled',
  'finished',
  'pending_finished',
];

interface TotalAmountStackChartProps {
  className?: string;
}

// The ticks are specified because recharts renders 99.9999% instead of 100% as the last tick.
const yAxisTicks = [0, 25, 50, 75, 100];
const yAxisDomain = [0, 100];

export function SubscriptionsByStatus({
  className,
}: TotalAmountStackChartProps): JSX.Element {
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);
  const [filters] = useMetricsFilters();
  const {
    data: formattedData,
    error,
    isSuccess,
    isLoading,
    refetch,
  } = useSubscriptionsByStatus(filters);
  const translationKey = 'metrics.subscriptionsByStatus';

  const yAxisLabel = getYAxisLabel(t(`${translationKey}.yAxis`));

  const colors = getStatusColors(themeContext);

  return (
    <div>
      <Row alignItems="center" gap="0.5rem" margin="0 0 0.5rem">
        <H2>{t(`${translationKey}.title`)}</H2>
        <HelpButton
          titleKey={`${translationKey}.title`}
          descriptionKey={`${translationKey}.help.description`}
          noteKey={`${translationKey}.help.note`}
        />
      </Row>
      <TableWrapper className={className}>
        {error && (
          <ErrorMessage onClick={() => refetch()}>
            {t('api.errors.unexpected_error')}
          </ErrorMessage>
        )}
        {isLoading && <FullPageSpinner height="350px" />}
        {isSuccess && (
          <ResponsiveContainer height={350}>
            <BarChart data={formattedData}>
              <XAxis dataKey="time" tick={{ dy: 8, fontSize: 13 }} />
              <YAxis
                type="number"
                tickFormatter={(t) => `${t} %`}
                tick={{ fontSize: 13 }}
                ticks={yAxisTicks}
                domain={yAxisDomain}
                label={yAxisLabel}
              />
              <Tooltip
                {...CHART_TOOLTIP_STYLE}
                formatter={(value: string, name: string) => [
                  `${value} %`,
                  t(`data.subscription.status.${name}`) as string,
                ]}
              />
              {status.map((status) => {
                return (
                  <Bar
                    isAnimationActive={false}
                    key={status}
                    dataKey={status}
                    stackId="a"
                    fill={colors[status]}
                  />
                );
              })}
              <Legend
                iconType="circle"
                wrapperStyle={{ paddingTop: '1rem' }}
                formatter={(value) => (
                  <CellText inline style={{ color: 'black' }}>
                    {t(`data.subscription.status.${value}`)}
                  </CellText>
                )}
              />
            </BarChart>
          </ResponsiveContainer>
        )}
      </TableWrapper>
    </div>
  );
}
