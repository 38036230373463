import { useTranslation } from 'react-i18next';
import { Import } from '../../components/imports/Import';
import { ImportType } from '../../types/ImportFile';

export function ImportCustomersScreen(): JSX.Element {
  const { t } = useTranslation();

  const name = {
    name: t('common.customers'),
  };
  return (
    <Import
      type={ImportType.Customer}
      title={t('screens.import.title', name)}
      importButtonText={t('screens.import.import', name)}
      templateUrl="/upload_templates/customers_template.csv"
      helpCenterUrl="https://www.notion.so/increase-app/C-mo-crear-clientes-en-forma-masiva-6a0603ff0dd84d62b07a973cbd8f2b0b"
    />
  );
}
