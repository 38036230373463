import { useCurrency } from '@increasecard/increase-core';
import { useCountry } from '../hooks/useCountry';
import {
  COUNTRIES,
  COUNTRIES_CONFIG,
  CountryConfig,
  PLATFORM_COUNTRIES,
} from '../localizations';
import { Country, Currency } from '../types/common';
import { reportError } from './errorTracker';

// eslint-disable-next-line @typescript-eslint/ban-types
export function useWithCountryCurrency<T extends object>(
  data: T
): T & { country: Country; currency: Currency } {
  return useWithCountry(useWithCurrency(data));
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function useWithCountry<T extends object>(
  data: T
): T & { country: Country } {
  return {
    ...data,
    country: useCountry() as Country,
  };
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function useWithCurrency<T extends object>(
  data: T
): T & { currency: Currency } {
  return {
    ...data,
    currency: useCurrency() as Currency,
  };
}

export function getCountryConfig(country = COUNTRIES.ARGENTINA): CountryConfig {
  return COUNTRIES_CONFIG[country];
}

export function countryFromPlatform(platformCountry: string): Country {
  const country = PLATFORM_COUNTRIES[platformCountry];
  if (!country) {
    reportError('Platform country does not match current country mapping');
    // TODO(santi698): This should raise or return null here
    return COUNTRIES.ARGENTINA; // fallback in case of errors
  }

  return country;
}
