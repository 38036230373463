import { TFunction } from 'i18next';
import * as Yup from 'yup';
import i18n from '../i18n';
import { COUNTRIES } from '../localizations';

Yup.setLocale({
  mixed: {
    required: () => i18n.t('validation.required'),
  },
  string: {
    min: ({ min }: Metadata) => i18n.t('validation.len_min', { len: min }),
    max: ({ max }: Metadata) => i18n.t('validation.len_max', { len: max }),
    length: ({ length }: Metadata) =>
      i18n.t('validation.len_exact', { len: length }),
    email: () => i18n.t('validation.email'),
  },
  number: {
    min: ({ min }: Metadata) =>
      i18n.t('validation.greater_than_or_equal_to', { len: min }),
    max: ({ max }: Metadata) =>
      i18n.t('validation.less_than_or_equal_to', { len: max }),
  },
});

export const RequiredString = (): Yup.StringSchema => {
  return Yup.string().required();
};

export const Email = (): Yup.StringSchema => {
  return Yup.string().email();
};

export const RequiredEmail = (): Yup.StringSchema => {
  return Email().required();
};

export const URL = (t: TFunction): Yup.StringSchema => {
  return Yup.string()
    .matches(/^(http|https):/, {
      message: t('validation.url_protocol'),
      excludeEmptyString: true,
    })
    .url(t('validation.invalid_url'));
};

export const RequiredPercentage = (t: TFunction): Yup.StringSchema => {
  return Yup.string()
    .matches(/^[\d.]*$/, t('validation.only_percentages'))
    .required();
};

export const OnlyNumbers = (t: TFunction): Yup.StringSchema => {
  return Yup.string().matches(/^\d*$/, t('validation.only_numbers'));
};

export const OnlyAlphanumeric = (t: TFunction): Yup.StringSchema => {
  return Yup.string()
    .matches(/^[A-Za-z0-9]+$/, {
      message: t('validation.only_alphanumerics'),
      excludeEmptyString: true,
    })
    .required();
};

export const OnlyNumbersRequired = (t: TFunction): Yup.StringSchema => {
  return OnlyNumbers(t).required();
};

export const RequiredNumber = (): Yup.NumberSchema => {
  return Yup.number().required();
};

export const RequiredAmount = (t: TFunction): Yup.StringSchema => {
  return Yup.string()
    .matches(/^[\d.,]*$/, t('validation.only_numbers'))
    .required();
};

export const RequiredInteger = (t: TFunction): Yup.NumberSchema => {
  return RequiredNumber().integer(t('validation.only_integers'));
};

export const RequiredSelect = (t: TFunction): Yup.StringSchema => {
  return (
    Yup.string()
      //eslint-disable-next-line
    .matches(/^[^\*]+$/, t('validation.required'))
      .required()
  );
};

export const CUIT = (t: TFunction): Yup.StringSchema => {
  return OnlyNumbers(t).length(11).required();
};

export const Beneficiary = (): Yup.StringSchema => {
  return Yup.string().required();
};

export const CreditCardNumber = (t: TFunction): Yup.StringSchema => {
  return OnlyNumbers(t).max(19).min(12).required();
};

export const CVV = (t: TFunction): Yup.StringSchema => {
  return OnlyNumbers(t).min(3).max(4).required();
};

export const CardExpirationDate = (t: TFunction): Yup.StringSchema => {
  return Yup.string()
    .typeError(t('validation.expiration_date'))
    .matches(/([0-1][0-9])\/([0-9]{2})/, t('validation.expiration_date'))
    .required();
};

export const ComafiCompanyCode = (): Yup.StringSchema => {
  return RequiredString().length(5);
};

export const SupervielleCompanyCode = (): Yup.StringSchema => {
  return RequiredString().max(10);
};

export const AccountNumber = (t: TFunction): Yup.StringSchema => {
  return OnlyNumbers(t).length(15).required();
};

export const MinDateAllowed = (
  t: TFunction,
  minDate: Date = new Date()
): Yup.DateSchema => {
  return Yup.date().min(minDate, t('validation.dates.activate_date'));
};

export const MinMaxRequiredLength = (
  t: TFunction,
  min: number,
  max: number
): Yup.StringSchema => {
  return OnlyNumbersRequired(t).min(min).max(max);
};

export const MinMaxAlphanumericRequiredLength = (
  t: TFunction,
  min: number,
  max: number
): Yup.StringSchema => {
  return OnlyAlphanumeric(t).min(min).max(max);
};

export const TaxId = (t: TFunction, validateTaxId = true): Yup.StringSchema => {
  return Yup.string().when('billing_info.country', (country: string) => {
    if (!validateTaxId) return OnlyNumbersRequired(t);
    switch (country) {
      case COUNTRIES.ARGENTINA:
        return OnlyNumbersRequired(t).matches(/^\d{7,9}$|^(\d{11})$/, {
          message: 'DNI: 7 a 9 caracteres, CUIT: 11',
          excludeEmptyString: true,
        });
      case COUNTRIES.PERU:
        return MinMaxAlphanumericRequiredLength(t, 8, 9);
      case COUNTRIES.CHILE:
        return Yup.string().matches(/^\d{7,8}[kK]?$/, {
          message: 'Solo de 8 a 9 caracteres',
          excludeEmptyString: true,
        });
      case COUNTRIES.COLOMBIA:
        return MinMaxAlphanumericRequiredLength(t, 6, 10);
      case COUNTRIES.URUGUAY:
        return OnlyNumbersRequired(t).matches(/^\d{6,8}$|^(\d{12})$/, {
          message: 'CI: 6 a 8 caracteres, RUT: 12',
          excludeEmptyString: true,
        });
      case COUNTRIES.ECUADOR:
      case COUNTRIES.PARAGUAY:
        return MinMaxAlphanumericRequiredLength(t, 5, 20);
      case COUNTRIES.COSTA_RICA:
        return OnlyAlphanumeric(t).length(11);
      case COUNTRIES.MEXICO:
        return MinMaxAlphanumericRequiredLength(t, 10, 18);
      case COUNTRIES.BRASIL:
        return MinMaxAlphanumericRequiredLength(t, 11, 14);
      default:
        return OnlyAlphanumeric(t);
    }
  });
};
