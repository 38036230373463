import config, { environment } from '../config';
export interface ReCaptchaOptions {
  sitekey: string;
  /**
   * The function to be called when the user successfully completes the normal
   * or the compact captcha. It will also be called with null, when captcha expires.
   * @param token string or null
   */
  onChange?: ((token: string | null) => void) | undefined;
  /**
   * The tabindex of the element.
   * @default 0
   */
  tabindex?: number | undefined;

  /**
   * Callback called when a challenge expires and has to be redone by the user.
   */
  onExpired?: (() => void) | undefined;

  /**
   * Callback called when google script is loaded
   */
  asyncScriptOnLoad?: (() => void) | undefined;
  /**
   *  Optional callback, called when reCAPTCHA encounters an error (usually network connectivity)
   *  and cannot continue until connectivity is restored. If you specify a function here, you are
   *  responsible for informing the user that they should retry.
   */
  onErrored?: (() => void) | undefined;
  /**
   * This allows you to change the size or do an invisible captcha.
   * @default "normal"
   */
  size?: 'compact' | 'normal' | 'invisible';
  /**
   * The badge location for g-recaptcha with size of "invisible".
   * @default "bottomright"
   */
  badge?: 'bottomright' | 'bottomleft' | 'inline';
}

export class ReCAPTCHAWrapper {
  _widgetId!: string;
  executionResolve?: (token: string) => void;
  executionReject?: VoidFunction;
  _executeRequested!: boolean;
  options: ReCaptchaOptions;
  constructor(options: ReCaptchaOptions) {
    this.options = options;
    this.handleExpired = this.handleExpired.bind(this);
    this.handleErrored = this.handleErrored.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  private getGrecaptcha() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return window.grecaptcha as any;
  }

  private execute() {
    const execute = this.getGrecaptcha()?.execute;
    if (execute && this._widgetId !== undefined) {
      return execute(this._widgetId);
    } else {
      this._executeRequested = true;
    }
  }

  private resetResolves() {
    delete this.executionResolve;
    delete this.executionReject;
  }

  executeAsync(): Promise<string> {
    return new Promise((resolve, reject) => {
      if (environment === 'staging') {
        return resolve(config.reCaptchaFakeToken);
      }
      this.executionResolve = resolve;
      this.executionReject = reject;
      this.execute();
    });
  }

  reset(): void {
    const resetter = this.getGrecaptcha()?.reset;
    if (resetter && this._widgetId !== undefined) {
      resetter(this._widgetId);
    }
  }

  forceReset(): void {
    const resetter = this.getGrecaptcha()?.reset;
    if (resetter) {
      resetter();
    }
  }

  handleExpired(): void {
    if (this.options.onExpired) {
      this.options.onExpired();
    } else {
      this.handleChange('');
    }
    this.reset();
  }

  handleErrored(): void {
    if (this.options.onErrored) {
      this.options.onErrored();
    }
    if (this.executionReject) {
      this.executionReject();
      this.resetResolves();
    }
  }

  handleChange(token: string): void {
    if (this.options.onChange) {
      this.options.onChange(token);
    }
    if (this.executionResolve) {
      this.executionResolve(token);
      this.resetResolves();
    }
    this.reset();
  }

  explicitRender(): void {
    const render = this.getGrecaptcha()?.render;
    if (render && this._widgetId === undefined) {
      const wrapper = document.createElement('div');
      wrapper.id = 'recaptcha-container';
      this._widgetId = render(wrapper, {
        callback: this.handleChange,
        'expired-callback': this.handleExpired,
        'error-callback': this.handleErrored,
        size: 'invisible',
        type: 'image',
        ...this.options,
      });
      window.document.body.appendChild(wrapper);
    }
    if (
      this._executeRequested &&
      this.getGrecaptcha() &&
      this._widgetId !== undefined
    ) {
      this._executeRequested = false;
      this.execute();
    }
  }
}
