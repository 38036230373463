import { useTranslation } from 'react-i18next';
import {
  H2,
  InputText,
  CellText,
  Grid,
  InputTextProps,
} from '@increasecard/typed-components';
import { Box } from './Box';

export interface InputWithDescriptionProps
  extends Omit<
    InputTextProps,
    'type' | 'width' | 'autocomplete' | 'className'
  > {
  inputClassName?: string;
  description: string;
  descriptionClassName?: string;
  title: string;
}

export function InputWithDescription({
  description = '',
  errorMessage = '',
  value,
  onChange,
  title,
  inputClassName = 'grid-span-desktop-6',
  descriptionClassName = 'grid-span-desktop-3',
  ...rest
}: InputWithDescriptionProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Grid rowGap={2}>
      <H2>{title}</H2>
      <InputText
        className={inputClassName}
        id="external_id"
        type="text"
        width="100%"
        placeholder={t('common.enter_id')}
        autoComplete="off"
        errorMessage={errorMessage}
        onChange={onChange}
        value={value}
        {...rest}
      />
      <Box
        as={CellText}
        alignSelf="center"
        color="colorsNew.gray.blackSLight"
        className={descriptionClassName}
      >
        {description}
      </Box>
    </Grid>
  );
}
