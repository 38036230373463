import { CheckoutInvoice, GroupedCollectMethods } from '../../../types';

export function isInvoicePayable(invoice: CheckoutInvoice): boolean {
  return ['open', 'retries_pending', 'uncollectible'].includes(invoice.status);
}

// Deprecated
export function areCollectMethodsCompat(
  gcm1: GroupedCollectMethods,
  gcm2: GroupedCollectMethods
): boolean {
  const {
    card: [cm1],
  } = gcm1;
  const {
    card: [cm2],
  } = gcm2;
  return cm1.type === cm2.type;
}
