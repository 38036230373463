import { useTranslation } from 'react-i18next';
import { FullPageSpinner } from '../../components/common/FullPageSpinner';
import { ErrorMessage } from '../../components/ErrorMessage';
import { useLanguage } from '../../hooks/useLanguage';
import { CheckoutInvoice } from '../../types/Invoice';
import { isCBU } from '../../utils/paymentUtils';
import { useAccountById, useInvoiceById } from './checkoutQueries';
import { InvoicePaymentScreen } from './InvoicePaymentScreen';
import { InvoicePaymentSummary } from './InvoicePaymentSummary';
import { useCheckoutBootUp } from './useCheckoutBootUp';
import { useCheckoutParams } from './useCheckoutParams';
import { useErrorUserData } from './useErrorUserData';
import { InvalidCheckoutLink } from './InvalidCheckoutLink';

const isPaidByCBU = (invoice?: CheckoutInvoice) => {
  return invoice
    ? invoice.status === 'open' &&
        invoice.payments?.length > 0 &&
        isCBU(invoice.payments[0].payment_method.data.type) &&
        invoice.payments[0].status === 'open'
    : undefined;
};

export function InvoiceCheckoutScreen(): JSX.Element {
  useCheckoutBootUp();
  const { t } = useTranslation();
  const { invoiceId, accountId } = useCheckoutParams();
  const account = useAccountById(accountId);
  const invoice = useInvoiceById(invoiceId);

  useLanguage(invoice.data?.customer.billing_info.country);
  useErrorUserData({ invoice: invoice.data });

  if (!invoiceId) {
    return (
      <ErrorMessage>
        {t('screens.invoice_payment.errors.missing_invoice')}
      </ErrorMessage>
    );
  }

  if (account.isLoading || invoice.isLoading) {
    return <FullPageSpinner />;
  }

  const invoiceIsPayable = invoice.data?.may_use_checkout_link;

  // An invoice can be considered paid if its status is open and
  // its payment method type is cbu
  const invoiceIsPaid =
    invoice.data?.status === 'paid' || isPaidByCBU(invoice.data);

  // TODO: checking the payment is preventing show an invoice as paid
  // if was "set_as_paid" externally
  const payment = invoice.data?.payments[0];
  if (invoiceIsPaid && payment && invoice.isSuccess && account.isSuccess) {
    return (
      <InvoicePaymentSummary
        invoice={invoice.data}
        account={account.data}
        payment={payment}
      />
    );
  }

  if (!invoiceIsPayable && account.data) {
    return <InvalidCheckoutLink account={account.data} />;
  }

  if (invoice.isSuccess && account.isSuccess && invoiceIsPayable) {
    return (
      <InvoicePaymentScreen invoice={invoice.data} account={account.data} />
    );
  }

  return <></>;
}
