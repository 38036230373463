import { MouseEvent, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import { ArrowLeftIcon } from '@increasecard/icons';
import { RouterLink } from './RouterLink';

export interface GoBackButtonProps {
  children?: ReactNode;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  hideIcon?: boolean;
}

export function GoBackButton({
  children = 'Volver',
  onClick,
  hideIcon,
}: GoBackButtonProps): JSX.Element {
  const history = useHistory();
  return (
    <RouterLink
      icon={
        hideIcon ? undefined : (
          <ArrowLeftIcon height="20" viewBox="11 4 2 16" width="14" />
        )
      }
      onClick={onClick || history.goBack}
    >
      {children}
    </RouterLink>
  );
}
