import { useState } from 'react';
import { CheckoutPlan } from '../../types/Plan';
import { CheckoutSubscription } from '../../types/Subscription';
import { Customer } from '../../types/Customer';
import { useErrorUserData } from './useErrorUserData';
import { UseCheckoutParams } from './useCheckoutParams';
import {
  useCheckoutNavigation,
  UseCheckoutNavigation,
  STEP_INDEX,
} from './useCheckoutNavigation';
import { useCheckoutParams } from './useCheckoutParams';
export interface CheckoutData {
  plan: CheckoutPlan | null;
  planQuantity: number | null;
  customer: Customer | null;
  coupon: string | null;
}

export interface CheckoutDataSetters {
  setPlan: (values: CheckoutPlan | null) => void;
  setPlanQuantity: (values: number | null) => void;
  setSubscription: (subscription: CheckoutSubscription) => void;
  setCustomer: (customer: Customer) => void;
  setCoupon: (coupon: string | null) => void;
}

export interface CheckoutController {
  dataSetters: CheckoutDataSetters;
  checkoutData: CheckoutData;
  urlParams: Omit<UseCheckoutParams, 'invoiceId'>;
  navigation: UseCheckoutNavigation;
  STEP_INDEX: typeof STEP_INDEX;
}

export function useCheckout(): CheckoutController {
  const { customerId, productId, planId, subscriptionId, accountId } =
    useCheckoutParams();
  const [plan, setPlan] = useState<CheckoutPlan | null>(null);
  const [planQuantity, setPlanQuantity] = useState<number | null>(null);
  const [subscription, setSubscription] = useState<CheckoutSubscription | null>(
    null
  );
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [coupon, setCoupon] = useState<string | null>(null);

  useErrorUserData({
    customerId,
    subscription,
    customer,
  });
  const navigation = useCheckoutNavigation(STEP_INDEX.plan);

  return {
    dataSetters: {
      setPlan,
      setPlanQuantity,
      setSubscription,
      setCustomer,
      setCoupon,
    },
    checkoutData: {
      plan,
      planQuantity,
      customer,
      coupon,
    },
    navigation,
    STEP_INDEX,
    urlParams: {
      accountId,
      customerId,
      productId,
      planId,
      subscriptionId,
    },
  };
}
