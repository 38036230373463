import {
  AuthorizationList,
  useProductAuthorizations,
} from '@increasecard/increase-core';
import { PayAuthorizations } from './PayAuthorizations';
import config from '../../config';

export { PayAuthorizations } from './PayAuthorizations';

type UserCanFunctionType = () => boolean;

export function usePayAuthorizations(): {
  userCan: (authorizations: AuthorizationList) => boolean;
  canViewDashboard: UserCanFunctionType;
  canRetryPayment: UserCanFunctionType;
  canRefundPayment: UserCanFunctionType;
  canCreateInvoice: UserCanFunctionType;
  canCreateEditCustomer: UserCanFunctionType;
  canCreateSubscription: UserCanFunctionType;
  canApproveSubscription: UserCanFunctionType;
  canManageSubscriptions: UserCanFunctionType;
  canCreateEditProductPlan: UserCanFunctionType;
  canCreateEditExtra: UserCanFunctionType;
  canManageInvoices: UserCanFunctionType;
} {
  const { userHasAuthorization } = useProductAuthorizations();
  const userCan = (authorizations: AuthorizationList) => {
    // see if authorizations must be checked
    if (config.useAuthorizations === false) {
      // bypass authorizations
      return true;
    }
    // check if user has at least one authorization of the list
    return authorizations.some((action: string) => {
      return userHasAuthorization(action);
    });
  };
  return {
    userCan,
    canViewDashboard: () => userCan(PayAuthorizations.viewDashboard),
    canRetryPayment: () => userCan(PayAuthorizations.retryPayment),
    canRefundPayment: () => userCan(PayAuthorizations.refundPayment),
    canCreateInvoice: () => userCan(PayAuthorizations.createInvoice),
    canCreateEditCustomer: () => userCan(PayAuthorizations.createEditCustomer),
    canCreateSubscription: () => userCan(PayAuthorizations.createSubscription),
    canApproveSubscription: () =>
      userCan(PayAuthorizations.approveSubscription),
    canManageSubscriptions: () =>
      userCan(PayAuthorizations.manageSubscriptions),
    canCreateEditProductPlan: () =>
      userCan(PayAuthorizations.createEditProductPlan),
    canCreateEditExtra: () => userCan(PayAuthorizations.createEditExtra),
    canManageInvoices: () => userCan(PayAuthorizations.manageInvoices),
  };
}
