import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom';
import { BaseProvider, Environment } from '@increasecard/increase-core';
import config, {
  environment as configEnvironment,
  productName,
} from './config';
import { SubscriptionsScreen } from './features/subscription/SubscriptionsScreen';
import { PaymentsScreen } from './features/payments/PaymentsScreen';
import { SubscriptionDetailScreen } from './features/subscription/SubscriptionDetailScreen';
import { ExtrasScreen } from './features/extras/ExtrasScreen';
import { CustomersScreen } from './features/customers/CustomersScreen';
import { ProductsPlansScreen } from './features/plan/ProductsPlansScreen';
import { NewProductScreen } from './features/plan/NewProductScreen';
import { CheckoutScreen } from './features/checkout/CheckoutScreen';
import { InvoiceCheckoutScreen } from './features/checkout/InvoiceCheckoutScreen';
import { CustomerPaymentMethodScreen } from './features/checkout/CustomerPaymentMethodScreen';
import { NewCustomerScreen } from './features/customers/NewCustomerScreen';
import { ImportCustomersScreen } from './features/customers/ImportCustomersScreen';
import { NewInvoiceScreen } from './features/invoice/NewInvoiceScreen';
import { NetworkError } from './components/NetworkError';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import { NewPlanScreen } from './features/plan/NewPlanScreen';
import { NewSubscriptionScreen } from './features/subscription/NewSubscriptionScreen';
import { SettingsScreen } from './features/settings/SettingsScreen';
import { PaymentDetailScreen } from './features/payments/PaymentDetailScreen';
import { SummaryScreen } from './features/summary/SummaryScreen';
import { RedirectToSandbox } from './components/common/RedirectToSandbox';
import { EditCustomerScreen } from './features/customers/EditCustomerScreen';
import {
  PayAccessControl,
  PayAuthorizations,
} from './features/authorizations/PayAccessControl';
import { PlanDetailScreen } from './features/plan/PlanDetailScreen';
import { CheckoutLite } from './features/checkout/CheckoutLite';
import { PublicQueryProvider } from './services/QueryProvider';
import { EditSubscriptionScreen } from './features/subscription/EditSubscriptionScreen';
import { AppHeader } from './AppHeader';
import { useNavigationTracking } from './hooks/useNavigationTracking';
import { CustomerDetailScreen } from './features/customers/CustomerDetailScreen';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { DebtInvoiceScreen } from './features/debtInvoice/DebtInvoiceScreen';
import { CustomerDebtInvoiceScreen } from './features/debtInvoice/CustomerDebtInvoiceScreen';
import { InvoiceDetailScreen } from './features/invoice/InvoiceDetailScreen';
import { featureFlags } from './featureFlags';
import { NewDebtInvoiceScreen } from './features/invoice/NewDebtInvoiceScreen';
import { DebtPortalScreen } from './features/checkout/debtPortal/DebtPortalScreen';
import { ExtraDetailScreen } from './features/extras/ExtraDetailScreen';

export function increaseCoreEnvironmentFromConfigEnvironment(
  configEnvironment: string
): Environment {
  switch (configEnvironment) {
    case 'sandbox': {
      return 'production';
    }
    case 'production': {
      return 'production';
    }
    case 'staging': {
      return 'staging';
    }
    case 'playground': {
      return 'playground';
    }
    default: {
      throw new Error(`Invalid config environment ${configEnvironment}`);
    }
  }
}
interface PublicRoutesProps {
  NoMatchRoutes: () => JSX.Element;
}

function PublicRoutes({ NoMatchRoutes }: PublicRoutesProps): JSX.Element {
  return (
    <PublicQueryProvider>
      <ReactQueryDevtools />
      <Switch>
        <PublicRoute exact path="/checkout/:account_id">
          <CheckoutScreen />
        </PublicRoute>
        <PublicRoute exact path="/invoice/:account_id">
          <InvoiceCheckoutScreen />
        </PublicRoute>
        <PublicRoute exact path="/payment-method/:account_id">
          <CustomerPaymentMethodScreen />
        </PublicRoute>
        <PublicRoute exact path="/checkout-lite/:account_id">
          <CheckoutLite />
        </PublicRoute>
        {/* Deprecated route */}
        <PublicRoute exact path="/debt-portal/:account_id">
          <DebtPortalScreen />
        </PublicRoute>
        <PublicRoute exact path="/selfservice-portal/:account_id">
          <DebtPortalScreen />
        </PublicRoute>
        <Route path="*">
          <NoMatchRoutes />
        </Route>
      </Switch>
    </PublicQueryProvider>
  );
}

function PrivateRoutes() {
  useNavigationTracking();
  return (
    <AppHeader>
      <Switch>
        <Redirect exact from="/" to="/subscriptions" />
        <PrivateRoute exact path="/summary">
          <PayAccessControl allowed={PayAuthorizations.viewDashboard}>
            <SummaryScreen />
          </PayAccessControl>
        </PrivateRoute>
        <PrivateRoute exact path="/subscriptions/new">
          <PayAccessControl allowed={PayAuthorizations.createSubscription}>
            <NewSubscriptionScreen />
          </PayAccessControl>
        </PrivateRoute>
        <PrivateRoute exact path="/subscriptions/:subscription_id/edit">
          <PayAccessControl allowed={PayAuthorizations.manageSubscriptions}>
            <EditSubscriptionScreen />
          </PayAccessControl>
        </PrivateRoute>
        <PrivateRoute exact path="/subscriptions/:subscription_id/detail">
          <PayAccessControl allowed={PayAuthorizations.viewSubscriptions}>
            <SubscriptionDetailScreen />
          </PayAccessControl>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/subscriptions/:subscription_id/new-debt-invoice"
        >
          <PayAccessControl allowed={PayAuthorizations.createInvoice}>
            <DebtInvoiceScreen />
          </PayAccessControl>
        </PrivateRoute>
        <PrivateRoute path="/subscriptions" layoutHidden>
          <PayAccessControl allowed={PayAuthorizations.viewSubscriptions}>
            <SubscriptionsScreen />
          </PayAccessControl>
        </PrivateRoute>
        <PrivateRoute exact path="/payments/:payment_id/detail">
          <PayAccessControl allowed={PayAuthorizations.viewPayments}>
            <PaymentDetailScreen />
          </PayAccessControl>
        </PrivateRoute>
        <PrivateRoute exact path="/invoices/debt-invoices/new">
          <PayAccessControl allowed={PayAuthorizations.createInvoice}>
            <NewDebtInvoiceScreen />
          </PayAccessControl>
        </PrivateRoute>
        <PrivateRoute exact path="/invoices/new">
          <PayAccessControl allowed={PayAuthorizations.createInvoice}>
            <NewInvoiceScreen />
          </PayAccessControl>
        </PrivateRoute>
        <PrivateRoute exact path="/invoices/:invoice_id/detail">
          <PayAccessControl allowed={PayAuthorizations.createInvoice}>
            <InvoiceDetailScreen />
          </PayAccessControl>
        </PrivateRoute>
        <PrivateRoute path="/invoices" layoutHidden>
          <PaymentsScreen />
        </PrivateRoute>
        <PrivateRoute exact path="/customers">
          <CustomersScreen />
        </PrivateRoute>
        <PrivateRoute exact path="/customers/:customer_id/new-debt-invoice">
          <PayAccessControl allowed={PayAuthorizations.createInvoice}>
            <CustomerDebtInvoiceScreen />
          </PayAccessControl>
        </PrivateRoute>
        <PrivateRoute exact path="/customers/:customer_id/detail">
          <CustomerDetailScreen />
        </PrivateRoute>
        <PrivateRoute exact path={'/customers/new'}>
          <PayAccessControl allowed={PayAuthorizations.createEditCustomer}>
            <NewCustomerScreen />
          </PayAccessControl>
        </PrivateRoute>
        <PrivateRoute exact path={'/customers/:customer_id/edit'}>
          <PayAccessControl allowed={PayAuthorizations.createEditCustomer}>
            <EditCustomerScreen />
          </PayAccessControl>
        </PrivateRoute>
        <PrivateRoute exact path="/customers/import">
          <PayAccessControl allowed={PayAuthorizations.createBulkCustomers}>
            <ImportCustomersScreen />
          </PayAccessControl>
        </PrivateRoute>
        <PrivateRoute exact path={['/plans', '/products']} layoutHidden>
          <ProductsPlansScreen />
        </PrivateRoute>
        <PrivateRoute exact path="/products/new">
          <PayAccessControl allowed={PayAuthorizations.createEditProductPlan}>
            <NewProductScreen />
          </PayAccessControl>
        </PrivateRoute>
        <PrivateRoute exact path={['/plans/new', '/plans/:plan_id/edit']}>
          <PayAccessControl allowed={PayAuthorizations.createEditProductPlan}>
            <NewPlanScreen />
          </PayAccessControl>
        </PrivateRoute>
        <PrivateRoute exact path={'/plans/:plan_id/detail'}>
          <PlanDetailScreen />
        </PrivateRoute>
        <PrivateRoute exact path="/extras">
          <ExtrasScreen />
        </PrivateRoute>
        <PrivateRoute exact path="/extras/:type/:id/detail">
          <ExtraDetailScreen />
        </PrivateRoute>
        <PrivateRoute path="/settings" layoutHidden>
          <SettingsScreen />
        </PrivateRoute>
        <PrivateRoute exact path="/network_error">
          <NetworkError />
        </PrivateRoute>
      </Switch>
    </AppHeader>
  );
}

function PrivateRoutesContainer() {
  const { eventTracking, errorTracking } = config;

  const environment =
    increaseCoreEnvironmentFromConfigEnvironment(configEnvironment);

  const trackingCfg = {
    ...eventTracking,
    enabled: featureFlags.enableTracking,
  };

  return (
    <BaseProvider
      settings={{
        environment,
        productName,
        eventTracking: trackingCfg,
        errorTracking,
      }}
    >
      <RedirectToSandbox />
      <PrivateRoutes />
    </BaseProvider>
  );
}

export function AppRoutes(): JSX.Element {
  return (
    <BrowserRouter>
      <PublicRoutes NoMatchRoutes={PrivateRoutesContainer} />
    </BrowserRouter>
  );
}
